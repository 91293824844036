import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { TextareaAutosize } from "@mui/material";

const EditGreetingFromBase = ({ setOpen, target, requestId }) => {
  const [baseInfo, setBaseInfo] = useState("");
  useEffect(() => {
    const init = async () => {
      const docRef = doc(db, "base", requestId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setBaseInfo({ id: docSnap.id, ...docSnap.data() });
      }
    };
    requestId && init();
  }, [requestId]);

//   console.log("baseInfo =>", baseInfo);

  // お店掲載申請コンテナ
  const [requestShopData, setRequestShopData] = useState({
    greeting: "",
    RequestShopError: "",
    RequestShopLoading: "",
  });
  const { greeting, RequestShopError, RequestShopLoading } = requestShopData;

  // お店掲載申請値変更
  const handleChangeShop = (e) => {
    setRequestShopData({
      ...requestShopData,
      [e.target.name]: e.target.value,
    });
  };

  // お店掲載申請処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setRequestShopData({
      ...requestShopData,
      RequestShopError: "",
      RequestShopLoading: true,
    });
    try {
      // 登録
      const docRef = await updateDoc(doc(db, "base", requestId), {
        greeting,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        updator: auth.currentUser.uid,
      });
      setRequestShopData({
        ...requestShopData,
        RequestShopError: null,
      });
      window.location.reload();
      setOpen(false);
    } catch (err) {
      setRequestShopData({
        ...requestShopData,
        RequestShopError: err.message,
        RequestShopLoading: false,
      });
    }
  };
  // お店掲載申請関連
  const [fromProvider, setFromProvider] = useState(false);

  return (
    <>
      <div className=" border rounded-md p-3 hover:cursor-pointer hover:border-blue-500 shadow-md hover:shadow-2xl">
        <div className="mb-3 text-xl font-bold ">ごあいさつ</div>
        <form onSubmit={setRequestDataShop}>
          {/* 経度 */}
          <div className="my-4">
            <TextField
              name="greeting"
              label="ごあいさつ"
              multiline
              placeholder={
                baseInfo?.greeting
                  ? baseInfo?.greeting
                  : "例）○○県拠点の■■（拠点の名前）です。耕作可能な田畑がたくさんあり、補修すると住める小屋が２棟あります。お茶のできるスペースもあるのでご家族をお連れになって遊びに来て下さい＾＾。スイカや梅干しの収穫、筍採りや焚き火焼き芋などたくさんイベントを行います。みんなで楽しめたらうれしいです＾＾。"
              }
              size="small"
              className="w-full "
              onChange={handleChangeShop}
            ></TextField>
          </div>

          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={RequestShopLoading}
          >
            {RequestShopLoading ? "送信しています..." : "変更"}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditGreetingFromBase;
