import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ListSubheader from "@mui/material/ListSubheader";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import {
  useCollectionData,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import EditIcon from "@mui/icons-material/Edit";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import PhotoIcon from "@mui/icons-material/Photo";

export default function EditFlexContent({
  target,
  secondTarget,
  id,
  secondId,
  contentId,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // targetContentを取得
  const [FlexContentTarget] = useDocument(
    doc(db, target, id, "content", contentId)
  );

  // 文字サイズ処理
  const handleEditHandlineSize = async (e, text) => {
    let size = !e.target.innerText ? text : e.target.innerText;

    await updateDoc(doc(db, target, id, "content", contentId), {
      lastUpdatedAt: serverTimestamp(),
      updator: auth.currentUser.uid,
      fontSize: size,
    });
  };

  // 太字処理
  const handleEditHandlineBold = async (e,text) => {
    let bold = !e.target.innerText ? text:e.target.innerText
    await updateDoc(doc(db, target, id, "content", contentId), {
      lastUpdatedAt: serverTimestamp(),
      updator: auth.currentUser.uid,
      fontBold: bold,
    });
  };

  // イメージサイズ処理
  const handleEditImageSize = async (e, text) => {
    let size = !e.target.innerText ? text : e.target.innerText;
    await updateDoc(doc(db, target, id, "content", contentId), {
      lastUpdatedAt: serverTimestamp(),
      updator: auth.currentUser.uid,
      imageSize: size,
    });
  };

  // イメージ角丸処理
  const handleEditImageRound = async (e, text) => {
    let round = !e.target.innerText ? text : e.target.innerText;
    await updateDoc(doc(db, target, id, "content", contentId), {
      lastUpdatedAt: serverTimestamp(),
      updator: auth.currentUser.uid,
      rounded: round,
    });
  };

  // 削除処理
  const handleContentDelete = () => {
    // deleteDoc(doc(db, target, id, "content", contentId))
    // =>削除すると、落ちる
    updateDoc(doc(db, target, id, "content", contentId), {
      isPublish: false,
    });
  };

  const fontSizeArray = [
    <ListSubheader key="listFontSize">文字サイズ</ListSubheader>,
    <Divider key="divider1"></Divider>,
    <MenuItem key="fontSizeBig" onClick={(e) => handleEditHandlineSize(e)}>
      <ListItemIcon onClick={(e) => handleEditHandlineSize(e, "")}>
        <TextFormatIcon fontSize="small" />
      </ListItemIcon>
      小
    </MenuItem>,
    <MenuItem key="fontSizeBig" onClick={(e) => handleEditHandlineSize(e)}>
      <ListItemIcon onClick={(e) => handleEditHandlineSize(e, "中")}>
        <TextFormatIcon fontSize="medium" />
      </ListItemIcon>
      中
    </MenuItem>,

    <MenuItem key="fontSizeBig" onClick={(e) => handleEditHandlineSize(e)}>
      <ListItemIcon onClick={(e) => handleEditHandlineSize(e, "大")}>
        <TextFormatIcon fontSize="medium" />
      </ListItemIcon>
      大
    </MenuItem>,
    <MenuItem key="fontSizeMoreBig" onClick={(e) => handleEditHandlineSize(e)}>
      <ListItemIcon
        onClick={(e) => handleEditHandlineSize(e, "特大")}
        className="flex justify-center"
      >
        <TextIncreaseIcon fontSize="large" />
      </ListItemIcon>
      特大
    </MenuItem>,
    <Divider key="divider2"></Divider>,
    <ListSubheader key="listFontRounded">太字</ListSubheader>,
    <Divider key="divider3"></Divider>,
    <MenuItem key="roundedNone" onClick={(e) => handleEditHandlineBold(e)}>
      <ListItemIcon onClick={(e) => handleEditHandlineBold(e,"太字なし")} className="flex justify-center">
        <FormatBoldIcon fontSize="small" />
      </ListItemIcon>
      太字なし
    </MenuItem>,
    <MenuItem key="roundedOn" onClick={(e) => handleEditHandlineBold(e)}>
      <ListItemIcon onClick={(e) => handleEditHandlineBold(e,"太字")} className="flex justify-center">
        <FormatBoldIcon fontSize="large" />
      </ListItemIcon>
      太字
    </MenuItem>,
    <Divider key="divider4"></Divider>,
    <MenuItem key="deleteHandline" onClick={handleContentDelete}>
      <ListItemIcon className="flex justify-center">
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      削除
    </MenuItem>,
  ];

  const textAreaArray = [
    <MenuItem key="textareaDelete" onClick={handleContentDelete}>
      <ListItemIcon className="flex justify-center">
        <DeleteIcon fontSize="small" />
      </ListItemIcon>
      削除
    </MenuItem>,
  ];

  const imageArray = [
    <ListSubheader key="imageSize">サイズ</ListSubheader>,
    <Divider key="divider21"></Divider>,
    <MenuItem key="imageSmall" onClick={(e) => handleEditImageSize(e)}>
      <ListItemIcon onClick={(e) => handleEditImageSize(e, "小")}>
        <ImageIcon fontSize="small" />
      </ListItemIcon>
      小
    </MenuItem>,
    <MenuItem key="imageMedium" onClick={(e) => handleEditImageSize(e)}>
      <ListItemIcon onClick={(e) => handleEditImageSize(e, "中")}>
        <ImageIcon fontSize="medium" />
      </ListItemIcon>
      中
    </MenuItem>,
    <MenuItem key="imageLarge" onClick={(e) => handleEditImageSize(e)}>
      <ListItemIcon onClick={(e) => handleEditImageSize(e, "大")}>
        <ImageIcon fontSize="large" />
      </ListItemIcon>
      大
    </MenuItem>,
    <Divider key="divider22"></Divider>,
    <ListSubheader key="imageRounded">角丸</ListSubheader>,
    <Divider key="divider23"></Divider>,
    <MenuItem key="imageRoundedNone" onClick={(e) => handleEditImageRound(e)}>
      <ListItemIcon onClick={(e) => handleEditImageRound(e, "角丸なし")}>
        <AspectRatioIcon fontSize="medium" />
      </ListItemIcon>
      角丸なし
    </MenuItem>,
    <MenuItem key="imageRoundedOn" onClick={(e) => handleEditImageRound(e)}>
      <ListItemIcon onClick={(e) => handleEditImageRound(e, "角丸")}>
        <PhotoIcon fontSize="medium" />
      </ListItemIcon>
      角丸
    </MenuItem>,
    <Divider key="divider24"></Divider>,
    <MenuItem key="deleteImage" onClick={handleContentDelete}>
      <ListItemIcon>
        <DeleteIcon fontSize="medium" />
      </ListItemIcon>
      削除
    </MenuItem>,
  ];
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="">
          <EditIcon
            onClick={handleClick}
            fontSize="large"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            className="border m-2 p-2 rounded-full shadow-lg hover:cursor-pointer hove:shadow-2xl"
          ></EditIcon>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {/* 見出しのMENU */}
        {FlexContentTarget?.data().contentType === "handline" && fontSizeArray}
        {FlexContentTarget?.data().contentType === "textarea" && textAreaArray}
        {FlexContentTarget?.data().contentType === "image" && imageArray}
        {/* {FlexContentTarget?.data().contentType === "handline" && (
          <>
            [<ListSubheader key="listFontSize">文字サイズ</ListSubheader>,
            <Divider key="divider1"></Divider>,
            <MenuItem
              key="fontSizeBig"
              onClick={(e) => handleEditHandlineSize(e)}
            >
              <ListItemIcon>
                <TextFormatIcon fontSize="medium" />
              </ListItemIcon>
              大
            </MenuItem>
            ,
            <MenuItem
              key="fontSizeMoreBig"
              onClick={(e) => handleEditHandlineSize(e)}
            >
              <ListItemIcon className="flex justify-center">
                <TextIncreaseIcon fontSize="large" />
              </ListItemIcon>
              特大
            </MenuItem>
            ,<Divider key="divider2"></Divider>,
            <ListSubheader key="listFontSize">太字</ListSubheader>,
            <Divider key="divider3"></Divider>,
            <MenuItem
              key="roundedNone"
              onClick={(e) => handleEditHandlineBold(e)}
            >
              <ListItemIcon className="flex justify-center">
                <FormatBoldIcon fontSize="small" />
              </ListItemIcon>
              太字なし
            </MenuItem>
            ,
            <MenuItem
              key="roundedOn"
              onClick={(e) => handleEditHandlineBold(e)}
            >
              <ListItemIcon className="flex justify-center">
                <FormatBoldIcon fontSize="large" />
              </ListItemIcon>
              太字
            </MenuItem>
            ,<Divider key="divider4"></Divider>,
            <MenuItem key="deleteHandline" onClick={handleContentDelete}>
              <ListItemIcon className="flex justify-center">
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              削除
            </MenuItem>
            , ]
          </>
        )} */}

        {/* 見出しのMENU */}
        {/* {FlexContentTarget?.data().contentType === "textarea" && (
          <>
            <MenuItem onClick={handleContentDelete}>
              <ListItemIcon className="flex justify-center">
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              削除
            </MenuItem>
          </>
        )} */}

        {/* イメージのMENU */}
        {/* {FlexContentTarget?.data().contentType === "image" && (
          <>
            <ListSubheader sx={{ my: 0 }}>サイズ</ListSubheader>
            <Divider></Divider>
            <MenuItem onClick={(e) => handleEditImageSize(e)}>
              <ListItemIcon>
                <ImageIcon fontSize="small" />
              </ListItemIcon>
              小
            </MenuItem>
            <MenuItem onClick={(e) => handleEditImageSize(e)}>
              <ListItemIcon>
                <ImageIcon fontSize="medium" />
              </ListItemIcon>
              中
            </MenuItem>
            <MenuItem onClick={(e) => handleEditImageSize(e)}>
              <ListItemIcon>
                <ImageIcon fontSize="large" />
              </ListItemIcon>
              大
            </MenuItem>
            <Divider></Divider>
            <ListSubheader>角丸</ListSubheader>
            <Divider></Divider>
            <MenuItem onClick={(e) => handleEditImageRound(e)}>
              <ListItemIcon>
                <AspectRatioIcon fontSize="medium" />
              </ListItemIcon>
              角丸なし
            </MenuItem>
            <MenuItem onClick={(e) => handleEditImageRound(e)}>
              <ListItemIcon>
                <PhotoIcon fontSize="medium" />
              </ListItemIcon>
              角丸
            </MenuItem>
            <Divider></Divider>
            <MenuItem onClick={handleContentDelete}>
              <ListItemIcon>
                <DeleteIcon fontSize="medium" />
              </ListItemIcon>
              削除
            </MenuItem>
          </>
        )} */}
      </Menu>
    </React.Fragment>
  );
}
