import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { db, auth } from "../api/firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { useParams } from "react-router-dom";

export default function Options({ itemId, uid, targetCollection }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id } = useParams();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    console.log(itemId);
    console.log("targetCollection =>", targetCollection);
    console.log(`${targetCollection}/${id}/images/`);
    deleteDoc(doc(db, `${targetCollection}/${id}/images/`, itemId));
  };

  return (
    <div className="absolute top-0 right-0">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar>
          <MoreVertIcon />
        </Avatar>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {uid === auth.currentUser.uid && (
          <MenuItem onClick={handleDelete} dense>消去</MenuItem>
        )}
      </Menu>
    </div>
  );
}
