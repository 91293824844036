import React from "react";
import DisplayPostImageCarousel from "./DisplayPostImageCarousel";

const EditPostImage = ({ requestId }) => {
  return (
    <>
      <DisplayPostImageCarousel requestId={requestId} author />
    </>
  );
};

export default EditPostImage;
