import React from "react";
import InputInPost from "./InputPost";
import TopPosts from "./TopPosts";
import InputBox from "./InputBox";
import TestFeed from "./TestFeed";

const Feeds = ({target,id}) => {
  return (
    <>
      {/* <InputBox /> */}
      <InputInPost/>
      {/* <TestFeed /> */}
      {target ? <TopPosts target={target} targetId={id} /> : <TopPosts />}
    </>
  );
};

export default Feeds;
