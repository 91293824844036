import React, { memo, useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../api/firebase";
import DisplayRecommendShop from "./DisplayRecommendShop";
import SearchBar from "./SearchBar";
import FilterPanelOnAdminSettingUsersShop from "./FilterPanelOnAdminSettingUsersShop";

const RecommendedShops = ({type}) => {
  // 貸畑取得
  const [shops, setShops] = useState("");
  const shopsQuery = query(
    collection(db, "recommendedSpot"),
    where("type", "==", type),
    orderBy("createdAt", "desc"),
    limit(12)
  );
  useEffect(() => {
    let arr = [];
    const init = async () => {
      await getDocs(shopsQuery).then((docs) =>
        docs.forEach((elm) => {
          arr.push({ id: elm.id, ...elm.data() });
        })
      );
      setShops(arr);
    };
    init();
  }, []);

  // 貸畑全部取得
  const [allShops, setAllShops] = useState("");
  const AllShopsQuery = query(
    collection(db, "recommendedSpot"),
    where("type", "==", type),
    orderBy("createdAt", "desc")
  );
  useEffect(() => {
    let arr = [];
    const init = async () => {
      await getDocs(AllShopsQuery).then((docs) =>
        docs.forEach((elm) => {
          arr.push({ id: elm.id, ...elm.data() });
        })
      );
      setAllShops(arr);
    };
    init();
  }, []);



  // filter関連
  const [searchInput, setSearchInput] = useState("");
  const [list, setList] = useState();
  const [researchCount, setResearchCount] = useState(0);
  const researchCountIncrement = () => {
    setResearchCount((prev) => prev + 1);
  };
  const [resultsFound, setResultsFound] = useState(true);

  const [values, setValues] = useState([
    { id: 1, checked: false, label: "未許可" },
  ]);
  const handleChangeChecked = (id) => {
    const valuesList = values;
    const changeCheckedValues = valuesList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setValues(changeCheckedValues);
  };

  // フィルタ実行
  const applyFilters = () => {
    let updatedList = allShops;

    const valuesChecked = values.filter((item) => item.checked);

    if (!searchInput && !valuesChecked.length) {
      updatedList = shops;
    }

    if (valuesChecked.length) {
      updatedList = updatedList.filter((item) => item.isPermission === false);
    }

    // Search Filter
    if (searchInput) {
      updatedList = updatedList.filter(
        (item) =>
          (item.name + item.ownerName + item.address)
            .toLowerCase()
            .search(searchInput.toLowerCase().trim()) !== -1
      );
    }

    if (updatedList?.length) researchCountIncrement();
    setList(updatedList);

    !updatedList?.length ? setResultsFound(false) : setResultsFound(true);
  };

  // フィルタがある場合発動
  useEffect(() => {
    applyFilters();
  }, [searchInput, values]);

  return (
    <>
      {/* サーチボックス */}
      <div className="mb-4">
        <SearchBar
          value={searchInput}
          changeInput={(e) => setSearchInput(e.target.value)}
          placeholder="ショップ名、ユーザ名、都道府県名..."
        />
      </div>
      <FilterPanelOnAdminSettingUsersShop
        values={values}
        changeChecked={handleChangeChecked}
      />

      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-3">
        {/* filter前 */}
        {!researchCount &&
          shops &&
          shops?.map((shop) => (
            <div className=" col-span-1 " key={Math.random()}>
              <div className=" col-span-1" key={Math.random()}>
                <DisplayRecommendShop shop={shop} key={Math.random()} />
              </div>
            </div>
          ))}
        {/* フィルター後 */}
        {/* フィルターかけて何もなかったら */}
        {researchCount > 0 && (!list || !list.length) && (
          <div>検索のイベントはありません。</div>
        )}
        {/* listになにかあるなら */}
        {researchCount > 0 &&
          list?.length > 0 &&
          list?.map((shop) => (
            <div className=" col-span-1" key={Math.random()}>
              <DisplayRecommendShop shop={shop} key={Math.random()} />
            </div>
          ))}

        {/* {lendFields &&
          lendFields?.map((elm) => (
            <div className=" col-span-1">
              <DisplayRecommendShop shop={elm} key={Math.random()} />
            </div>
          ))} */}
      </div>
    </>
  );
};

export default memo(RecommendedShops);
