import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Link,
} from '@mui/material';
import getBalanceEx from './getBalanceEx';

const BalanceDisplay = ({ user, runru, deals }) => {
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const calculatedBalance = getBalanceEx(user, runru, deals);
    setBalance(calculatedBalance.balance);
    setTransactions(calculatedBalance.detailedEntries);
  }, [user, runru, deals]);

  // トランザクションを年月で区切る
  const groupedTransactions = transactions.reduce((groups, transaction) => {
    const date = new Date(transaction.date);
    const yearMonth = `${date.getFullYear()}年${(date.getMonth() + 1).toString().padStart(2, '0')}月`;

    if (!groups[yearMonth]) {
      groups[yearMonth] = [];
    }
    groups[yearMonth].push(transaction);
    return groups;
  }, {});



  return (
    <div className="p-6">
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            現在のルンルバランス
          </Typography>
          <Typography variant="h4" color="primary" gutterBottom>
            {balance}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" component="h3" gutterBottom>
            トランザクション履歴
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>日付</TableCell>
                  <TableCell>勘定</TableCell>
                  <TableCell align="right">前回残高</TableCell>
                  <TableCell align="right">金額</TableCell>
                  <TableCell align="right">残高</TableCell>
                  <TableCell>商品名</TableCell>
                  <TableCell>相手</TableCell>
                  <TableCell>取引</TableCell> {/* 取引フィールド */}
                  <TableCell>発生元</TableCell> {/* 発生元を表示 */}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(groupedTransactions).map((yearMonth, idx) => (
                  <React.Fragment key={idx}>
                    <TableRow>
                      <TableCell colSpan={11} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                        {yearMonth}
                      </TableCell>
                    </TableRow>
                    {groupedTransactions[yearMonth].map((transaction, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor: transaction.systemGenerated ? '#f0f4f8' : 'inherit',
                        }}
                      >
                        <TableCell>{transaction.no}</TableCell>
                        <TableCell>{transaction.id}</TableCell>
                        <TableCell>{new Date(transaction.date).toLocaleString()}</TableCell>
                        <TableCell>{transaction.type}</TableCell>
                        <TableCell align="right">{transaction.previousBalance}</TableCell>
                        <TableCell align="right">{transaction.amount}</TableCell>
                        <TableCell
                          align="right"
                          style={{ color: transaction.balance > transaction.previousBalance ? 'blue' : 'red' }}
                        >
                          {transaction.balance}
                        </TableCell>
                        <TableCell>{transaction.product}</TableCell>
                        <TableCell>
                          {transaction.type.includes('ルンル使用') ? transaction.to : transaction.party}
                        </TableCell>
                        <TableCell>
                          {transaction.trade && (
                            <Link
                              href={`/deal/${transaction.trade}`}
                              target="_blank"
                              rel="noopener"
                              color="primary"
                            >
                              {transaction.trade}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>{transaction.source}</TableCell> {/* 発生元を表示 */}
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default BalanceDisplay;
