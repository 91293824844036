import React, { useState } from "react";
import { TextField, MenuItem } from "@mui/material";

import {
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../api/firebase";
import {
  useCollection,
} from "react-firebase-hooks/firestore";

const AddDdpThing = ({ setOpen }) => {
  const [matchCollection] = useCollection(collection(db, "ddp"));
  const matchingMenu = matchCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  // console.log(matchingMenu);
  // 提供する形コンテナ
  const [product, setProduct] = useState({
    list: "",
    thing: "",
    error: "",
    loading: false,
  });
  const { list, thing, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    console.log(e.target)
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    try {
      // 変更処理
      const docRef = await updateDoc(doc(db, "ddp", list), {
        targets: list[thing],
      });
      setProduct({
        offering: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">「提供するもの」を登録</div>
        <form onSubmit={setRequestDataShop}>
          {/* マッチング */}
          <TextField
            id="outlined-required"
            name="list"
            label="提供する対象"
            defaultValue=""
            size="small"
            placeholder="ex) 人、全般、心"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            {matchingMenu?.map((item) =>
              item?.targets?.map((list) => {
                return (
                  <MenuItem key={Math.random()} value={item.id} dense>
                    {item.offering} - {item.recieve} - {list}
                  </MenuItem>
                );
              })
            )}
          </TextField>
          <div className="my-4"></div>

          {/*  */}
          <TextField
            id="outlined-required"
            name="thing"
            label="提供するもの"
            defaultValue=""
            size="small"
            placeholder="ex) パン、笑える話、幸福論"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
          />

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
            >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AddDdpThing;
