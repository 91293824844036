import React from "react";
import Img from "../data/img/defaultImage.png";

import "react-responsive-carousel/lib/styles/carousel.css";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../api/firebase";
import { useEffect } from "react";
import { useState } from "react";

import { memo } from "react";
import GetUserInfo from "./GetUserInfo";

const DisplayEvents = memo(({ eventId, event }) => {
  // console.log("event =>", event);
  const navigate = useNavigate();

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  return (
    <>
      <div
        className="flex flex-col rounded-lg border  hover:cursor-pointer hover:shadow-2xl duration-150"
        onClick={() => navigate(`/event/${eventId}`)}
      >
        {/* イベント画像 */}
        {event?.background ? (
          <div className="relative">
            <img
              src={event?.background}
              alt=""
              className=" h-56 w-full rounded-t-md"
            />
            <div className=" absolute right-2 bottom-2 rounded-xl px-1 text-gray-300 border">
              {event?.target === "group" ? "グループ" : "セカイムラ"}
            </div>
          </div>
        ) : (
          <div className="relative">
            <img src={Img} alt="" className=" h-56 w-full rounded-t-md" />
            <div className=" absolute right-2 bottom-2 rounded-full bg-green-700 text-white px-2">
              {event?.target === "group" ? "グループ" : "セカイムラ"}
            </div>
          </div>
        )}

        <div className="flex justify-center space-x-2 px-5">
          {/* イベント日時 */}



          <div className="flex flex-col justify-center items-center">
            <div className=" text-red-500  text-2xl">
              {months[new Date(event?.publishDate.toDate()).getMonth()]}
            </div>
            <div className="text-3xl text-gray-800">
              {new Date(event?.publishDate.toDate()).getDate()}
            </div>
          </div>

          <div className="flex flex-col justify-center items-start w-full">
            {/* タイトル */}
            <div className="flex w-[calc(100%-25px)]">
              <div className="font-bold  truncate hover:text-green-500">
                {event?.title}
              </div>
            </div>

            {/* 作成者 */}
            <div className="flex items-center space-x-3">
              <div className="flex font-bold text-2xl space-x-2">
                <GetUserInfo uid={event?.author} target="avatar" size={30} />
                <GetUserInfo uid={event?.author} target="name" />
              </div>
            </div>
          </div>
        </div>

        {/* 開催日
        <div className="flex text-gray-400 text-xs items-center justify-center  border rounded-md p-1 w-fit my-1">
          {new Date(event?.publishDate.toDate()).toLocaleDateString()}
        </div> */}
        {/* <GetCountInGroupMembers id={groupId} /> */}
      </div>
    </>
  );
});

export default DisplayEvents;
