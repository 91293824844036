import React from "react";
import { auth } from "../api/firebase";

import MyMessage from "./MyMessage";
import TheOtherMessage from "./TheOtherMessage";
import { memo } from "react";

const DisplayMessage = memo(({ messages }) => {

  // useEffect(() => {
  //   let arr = [];
  //   const q = query(collection(db, `deal/${id}/messages`), orderBy("sentAt"));

  //   onSnapshot(q, (docs) => {
  //     docs.forEach((doc) => {
  //       arr.push({ id: doc.id, ...doc.data() });
  //     });
  //     setMessage(arr);
  //   });
  //   // getDocs(q).then((docs) => {
  //   //   docs.forEach((doc) => {
  //   //     arr.push({ id: doc.id, ...doc.data() });
  //   //   });
  //   //   setMessage(arr);
  //   // });
  // }, []);

  return (
    <>
      {messages?.map((msg) => {
        return (
          <div key={Math.random()}>
            {msg?.sender === auth.currentUser.uid && <MyMessage msg={msg} />}
            {msg?.sender !== auth.currentUser.uid && (
              <TheOtherMessage msg={msg} />
              // => msgというpropsをDisplayMessagecomponent(親)から受け取っている
            )}
          </div>
        );
      })}
    </>
  );
});

export default DisplayMessage;
