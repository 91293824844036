import React, { useEffect } from "react";

import "react-responsive-carousel/lib/styles/carousel.css";
import { query, collection, where, doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import Img from "../data/img/defaultImage.png";

import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";

import { useLocation, useNavigate } from "react-router-dom";
import UserCard from "./UserCard";
import SwitchComponent from "./SwitchComponent";
import { Typography } from "@mui/material";
import moment from "moment";

const MyShop = ({ shop, requestId, userPage }) => {
  // console.log("userPage =>",userPage)
  // 自分で上げた画像
  const queryRequestShopImages = query(
    collection(db, `requestShop/${requestId}/images`),
    where("uid", "==", auth.currentUser.uid)
  );
  const [images] = useCollection(queryRequestShopImages);
  const requestShopImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const [owner] = useDocumentData(doc(db, "users", shop.owner));
  const location = useLocation();
  // console.log("location.state.tab =>",location.state.tab)

  const navigate = useNavigate();
  const handleMoveShop = () => {
    navigate("/mapPoint/" + shop?.id, { state: { url: "mapPoint" } });
  };


  return (
    <>
      <div className="flex-col  rounded-md shadow-md hover:shadow-2xl  duration-150 border hover:border-blue-500">
        {/* オーナー情報 */}
        <div className="relative" onClick={handleMoveShop}>
          {/* イメージ */}
          <img
            src={shop.background || Img}
            className="w-full h-48 rounded-t-md"
            alt=""
          />
          {/* オーナー */}
          <div className=" absolute bottom-2 right-2">
            <div className="bg-white  opacity-80 text-opacity-0 font-bold text-black rounded-md">
              <UserCard id={shop?.owner} />
            </div>
            {/* <Avatar src={owner?.avatar} /> */}
          </div>
          <Typography
            variant="body2"
            component="span"
            sx={{
              position: "absolute",
              bottom: 6,
              left: 6,
              color: "white",
              background: "rgba(0,0,0, .3)",
              p: "5px",
              borderRadius: 2,
            }}
          >
            {moment(shop?.createdAt?.toDate()).fromNow()}
          </Typography>
          <div className=" absolute top-2 left-2  rounded-md bg-teal-700 text-white p-2 w-fit">
            {shop.name}
          </div>
        </div>
        {/* userPageは　userSHopsコンポーネントで使用 */}
        {/* 許可・ロケーション情報など */}
        {!userPage && (
          <div className=" relative p-3">
            {/* 許可・不許可 */}
            <div className="flex flex-col border rounded-md p-2 mt-5 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
              <div className="font-bold">公開・非公開情報</div>
              {/* 公開 */}
              <div className="flex space-x-3 items-center">
                <div>公開</div>
                <div>
                  <SwitchComponent
                    id={shop.id}
                    target="isPublish"
                    collection="requestShop"
                    text="公開"
                  />
                </div>
              </div>
              {/* 許可 disabled */}
              <div className="flex space-x-3 items-center">
                <div>許可</div>
                <div>
                  <SwitchComponent
                    id={shop.id}
                    target="isPermission"
                    collection="requestShop"
                    text="許可"
                    disabled
                  />
                </div>
              </div>
              <div className="text-xs text-gray-500">
                [公開]・[許可] 共にオンの場合、マップに載ります
              </div>
            </div>
            {/* 編集ボタン */}
            <div
              className=" absolute top-4 right-4 text-xs text-blue-600 hover:font-bold duration-150 hover:cursor-pointer"
              onClick={() =>
                navigate(`/mapPoint/${requestId}`, { state: { tab: 4 } })
              }
            >
              編集
            </div>
            {/* ロケーション情報 */}
            <div className="flex flex-col my-5 border rounded-md p-2 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
              <div className="font-bold">ロケーション情報</div>
              <div>住所：{shop.address}</div>
              <div className={` ${!shop.lat && "text-red-600"}`}>
                緯度：{shop.lat}
              </div>
              <div className={` ${!shop.lng && "text-red-600"}`}>
                経度：{shop.lng}
              </div>
              {(!shop.lat || !shop.lng) && (
                <div className="text-xs text-red-600">
                  経度、又は緯度が未設定です。編集から経度と緯度を設定してください。設定後、マップに載ります。
                </div>
              )}
            </div>
            <div className="flex flex-col my-5 border rounded-md p-2 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
              <div className="font-bold">WEB・問い合わせ関連</div>
              <div>TEL:{shop.tel}</div>
              <div>URL:{shop.url}</div>
            </div>
            <div className="text-xs text-red-600">
              上記の情報は全世界に公開されます。公開されたくない情報でないか、ご確認ください。
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyShop;
