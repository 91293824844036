import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";

const EditDdpMatching = ({ setOpen, requestId }) => {
  const [ddp] = useDocumentData(doc(db, "ddp", requestId));

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    explain: "",
    message: "",
    error: null,
    loading: false,
  });
  const { explain, message, error, loading } = data;

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "ddp", requestId), {
        explain,
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
    }
  };

  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>

        <div className="mb-3">説明</div>
        <TextField
          id="outlined-required"
          name="explain"
          label="説明"
          defaultValue=""
          size="small"
          placeholder={ddp?.explain}
          className=" w-full "
          onChange={handleChange}
          required
          disabled={loading}
        />

        <Button
          className="btn"
          name="explain"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditDdpMatching;
