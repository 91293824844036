import React, { useState, useEffect } from "react";
import {
  getDocs,
  collection,
  query,
  orderBy,
  doc,
  updateDoc,
  limit,
} from "firebase/firestore";
import { db } from "../api/firebase";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import debounce from "lodash.debounce";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import UsersList from "./UsersList";

const OperateCustomersInfo = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [excludeVillagers, setExcludeVillagers] = useState(false);
  const [showStoppedAccounts, setShowStoppedAccounts] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      console.log("Fetching users...");
      let usersQuery;

      usersQuery = query(
        collection(db, "users"),
        orderBy("createdAt", "desc"),
        limit(100)
      );

      const userDocs = await getDocs(usersQuery);
      const userData = userDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUsers(userData);
      setLoading(false);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      console.log("Fetching users...");
      const searchTermLower = searchTerm.toLowerCase();

      const queries = [
        query(collection(db, "users"), orderBy("firstName")),
        query(collection(db, "users"), orderBy("lastName")),
        query(collection(db, "users"), orderBy("email")),
      ];

      try {
        const [firstNameDocs, lastNameDocs, emailDocs] = await Promise.all(
          queries.map(q => getDocs(q))
        );

        const firstNameUsers = firstNameDocs.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        const lastNameUsers = lastNameDocs.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        const emailUsers = emailDocs.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        console.log("FirstName Users:", firstNameUsers);
        console.log("LastName Users:", lastNameUsers);
        console.log("Email Users:", emailUsers);

        const combinedUsers = [
          ...firstNameUsers,
          ...lastNameUsers,
          ...emailUsers,
        ];

        // フィルタリングを追加：小文字で比較
        const filteredUsers = combinedUsers.filter((user) => {
          const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
          const email = user.email.toLowerCase();
          const term = searchTermLower.toLowerCase();

          return (
            fullName.includes(term) ||
            email.includes(term)
          );
        });

        const uniqueUsers = filteredUsers.filter(
          (user, index, self) =>
            index === self.findIndex((u) => u.id === user.id)
        );
        setUsers(uniqueUsers);
        console.log("Unique Users:", uniqueUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    if (searchTerm) {
      fetchUsers();
    }
  }, [searchTerm]);

  const handleSearchChange = debounce((e) => {
    setSearchTerm(e.target.value);
  }, 500);

  const handlePermissionChange = async (userId, currentPermission) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      isVillager: !currentPermission,
    });
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, isVillager: !currentPermission } : user
      )
    );
  };

  const handleStopChange = async (userId, currentStatus) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      isStop: !currentStatus,
    });
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, isStop: !currentStatus } : user
      )
    );
  };

  const handleVillagerCheckboxChange = (e) => {
    setExcludeVillagers(e.target.checked);
  };

  const handleStoppedCheckboxChange = (e) => {
    setShowStoppedAccounts(e.target.checked);
  };

  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopiedEmail(email);
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div className="text-gray-300">ユーザ情報</div>      
      <div className="w-full">
        <TextField
          label="名前、苗字、メールで検索"
          variant="outlined"
          onChange={handleSearchChange}
          fullWidth
          margin="normal"
        />
        <div className="flex items-center space-x-4">
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeVillagers}
                onChange={handleVillagerCheckboxChange}
                color="primary"
              />
            }
            label="村民権限のある人を除く"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showStoppedAccounts}
                onChange={handleStoppedCheckboxChange}
                color="primary"
              />
            }
            label="アカウント停止者"
          />
        </div>
        {loading ? (
          <div className="flex justify-center items-center my-4">
            <CircularProgress />
            <span className="ml-2">検索中...</span>
          </div>
        ) : (
          <UsersList
            users={users}
            handlePermissionChange={handlePermissionChange}
            handleStopChange={handleStopChange}
            excludeVillagers={excludeVillagers}
            showStoppedAccounts={showStoppedAccounts}
            handleCopyEmail={handleCopyEmail}
          />
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={`「${copiedEmail}」をコピーしました。`}
        />
      </div>
    </>
  );
};

export default OperateCustomersInfo;
