import React, { useState, useEffect } from "react";

import {
  getDocs,
  collection,
  query,
  where,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { useParams } from "react-router-dom";

import { db, auth } from "../api/firebase";

import GetUserInfo from "./GetUserInfo";

import Rate from "./Rate";

import TablePagination from "@mui/material/TablePagination";
import { memo } from "react";
import ModalForm from "./ModalForm";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import DealThoughts from "./DealThoughts";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";

const ProductCusutomerEvalutions = () => {
  const { id } = useParams();

  const [boughts, setBoughts] = useState([]);
  const [thoughts, setThoughts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "deal"), where("product", "==", id));
      const snapshot = await getDocs(q);
      const newBoughts = snapshot.docs.map((doc) => doc.data().buy);
      setBoughts(newBoughts);
    };

    fetchData();
  }, []);

  // console.log("boughts =>", boughts);

  useEffect(() => {
    const fetchThoughts = async () => {
      const newThoughts = [];

      const fetchEvaluations = async (bought) => {
        const q = query(
          collection(db, "users", bought, "dealEvalution"),
          orderBy("createdAt", "desc")
        );
        const snapshot = await getDocs(q);
        snapshot.forEach((evaluation) => {
          if (evaluation.data().product === id) {
            // console.log("evaluation =>", evaluation.data());
            newThoughts.push({
              id: evaluation.id,
              bought: bought,
              ...evaluation.data(),
            });
          }
        });
        newThoughts.sort((a, b) => {
          if (a.createdAt < b.createdAt) return 1;
          if (a.createdAt > b.createdAt) return -1;
        });
      };

      const fetchAllEvaluations = async () => {
        const promises = boughts.map((bought) => fetchEvaluations(bought));
        await Promise.all(promises);
        setThoughts(newThoughts);
      };

      if (boughts.length > 0) {
        fetchAllEvaluations();
      }
    };

    fetchThoughts();
  }, [boughts]);

  // console.log("thoughts =>", thoughts);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   消去で呼び出すModalのプロパティたち
  // const [open, setOpen] = useState(false);
  // const [form, setForm] = useState("");
  // const [requestId, setRequestId] = useState("");
  // const deleteEvalute = (e, doc) => {
  //   setOpen(true);
  //   setForm("deleteEvalute");
  //   setRequestId(doc.id);
  // };

  // 日付のInvalidDateを防ぐ関数
  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  return (
    <>
      {/* {boughts?.map((bought) => (
        <DealThoughts bought={bought} key={Math.random()}/>
      ))} */}
      {/* <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      /> */}
      {thoughts ? (
        (rowsPerPage > 0
          ? thoughts?.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : thoughts
        )?.map((doc) => (
          <div
            key={Math.random()}
            className="flex flex-col border w-fit rounded-md p-2 my-1 space-y-2"
          >
            <div className="flex space-x-1">
              <div className=" text-blue-500 text-xs">
                {isInvalidDate(new Date(doc.createdAt?.toDate()))
                  ? "読み込み中"
                  : formatDistanceToNow(doc.createdAt?.toDate(), {
                      locale: ja,
                    })}
                前
              </div>
              <div className="text-gray-300 text-xs">
                {new Date(doc.createdAt?.toDate()).toLocaleString()}
              </div>
            </div>

            <div className="flex space-x-2 items-center">
              <GetUserInfo uid={doc.bought} target="avatar" size={36} />
              <GetUserInfo uid={doc.bought} target="name" />
            </div>
            <Rate rate={doc.rate} />
            <div>{doc.evalute}</div>
          </div>
        ))
      ) : (
        <div>評価はまだありません。</div>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 100]}
        component="div"
        count={thoughts?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        showFirstButton
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default memo(ProductCusutomerEvalutions);
