import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// 写真
import { useAuth } from "../context/auth";
import {
  doc,
  getDoc,
  updateDoc,
  query,
  collection,
  orderBy,
  limit,
  setDoc,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function LatestTerm({ setOpen }) {
  // ユーザ取得
  const { user } = useAuth();
  const [userInfo, setUserInfo] = React.useState();
  React.useEffect(() => {
    const initUser = () => {
      getDoc(doc(db, "users", user.uid)).then((doc) => {
        setUserInfo(doc.data());
      });
    };
    initUser();
  }, [user]);

  // 最新の規約取得
  const termsQuery = query(
    collection(db, "terms"),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const [latestTermCollection] = useCollectionData(termsQuery);
  const latestTerm = latestTermCollection?.map((doc) => {
    return doc;
  });

  // 同意した、最新の規約
  const latestAllowedQuery = query(
    collection(db, "users", auth.currentUser.uid, "allowedTerm"),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const [latestAllowedCollection] = useCollectionData(latestAllowedQuery)
  const latestAllowd = latestAllowedCollection?.map((doc) => {
    return doc;
  });

  console.log("latestTerm =>",latestTerm)
  console.log("latestAllowd =>",latestAllowd)

  const allowedTerm = async () => {
    const allowedTermsRef = collection(
      db,
      "users",
      userInfo?.uid,
      "allowedTerm"
    );
    await addDoc(allowedTermsRef, {
      createdAt: serverTimestamp(),
      title: latestTerm[0].title,
      content: latestTerm[0].content,
    });
    setOpen(false);
  };

  // チェックボックス関連
  const [loading, setLoading] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const handleCheckChange = async () => {
    setLoading(true);
    setCheck(!check);
    setLoading(false);
  };

  return (
    <>
      {latestTerm && (
        <div className="flex flex-col space-y-2">
          <div className="font-bold text-xl">利用規約の同意</div>
          <div>規約ID：{latestTerm[0]?.title}</div>
          <TextField
            id="outlined-multiline-static"
            label=""
            multiline
            rows={10}
            defaultValue={latestTerm[0]?.content}
            disabled
          />
          <FormControlLabel
            control={<Checkbox />}
            label="上記利用規約を読み内容を承諾しました。"
            onClick={handleCheckChange}
            checked={check}
          />
          <Button
            variant="contained"
            onClick={allowedTerm}
            disabled={!check && !loading}
          >
            同意する
          </Button>
        </div>
      )}
    </>
  );
}
