import React, { useState } from "react";
import TextField from "@mui/material/TextField";

import {
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";

const AddOffering = ({ setOpen }) => {
  const [offersCollection] = useCollectionData(collection(db, "ddp"));
  const offers = offersCollection?.map((item) => {
    return item.offering;
  });

  const recieves = offersCollection?.map((item) => {
    return item.recieve;
  });

  // 提供する形コンテナ
  const [product, setProduct] = useState({
    offering: "",
    recieve: "",
    explain: "",
    error: "",
    loading: false,
  });
  const { offering, recieve, explain, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    if (offers.includes(e.target.value)) {
      setProduct({
        ...product,
        offering: "",
        error: "「" + [e.target.value] + "」は、すでに登録されています",
      });
      return;
    }

    if (recieves.includes(e.target.value)) {
      setProduct({
        ...product,
        recieve: "",
        error: "「" + [e.target.value] + "」は、すでに登録されています",
      });
      return;
    }

    setProduct({
      ...product,
      [e.target.name]: e.target.value,
      error: "",
    });
  };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    try {
      // 変更処理
      const docRef = await addDoc(collection(db, "ddp"), {
        offering,
        recieve,
        explain,
        isPublish: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      setProduct({
        offering: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">「マッチングさせる提供の形」を登録</div>
        <form onSubmit={setRequestDataShop}>
          {/* 提供する形 */}
          <TextField
            id="outlined-required"
            name="offering"
            label="提供する形"
            defaultValue=""
            value={offering}
            size="small"
            placeholder="ex) 話す、あげる、パフォーマンスする"
            className=" w-full "
            onChange={handleChange}
            required
          />
          <div className="my-4"></div>
          <TextField
            id="outlined-required"
            name="recieve"
            label="対になる「提供を受ける形」"
            defaultValue=""
            value={recieve}
            size="small"
            placeholder="ex) 知り合いたい、鑑賞したい、聞きたい、ほしい"
            className=" w-full "
            onChange={handleChange}
            required
          />

          <div className="my-4"></div>
          <TextField
            id="outlined-required"
            name="explain"
            label="説明"
            defaultValue=""
            size="small"
            placeholder=""
            className=" w-full "
            onChange={handleChange}
            required
          />

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
          >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? <div className="text-red-500">{error}</div> : ""}
        </form>
      </div>
    </>
  );
};

export default AddOffering;
