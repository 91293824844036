import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Avatar } from "@mui/material";
import InputPostComment from "./InputPostComment";
import PostComments from "./PostComments";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import Linkify from "linkify-react";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import DisplayPostImages from "./DisplayPostImages";
import DotClickMenuAtPost from "./DotClickMenuAtPost";
import ModalForm from "./ModalForm";
import DisplayPost from "./DisplayPost";

const InPost = () => {
  const { id } = useParams();
  const linkifyOptions = {
    className: "text-blue-400",
    target: "blank",
  };

  const [post, setPost] = useState("");
  useEffect(() => {
    const init = () => {
      getDoc(doc(db, "post", id)).then((elm) =>
        setPost({ id: elm.id, ...elm.data() })
      );
    };

    id && init();
  }, []);

  // ModalFormで使用
  const [openModal, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  if (!post) <div>投稿はありません</div>;

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={openModal}
        requestId={requestId}
        target={post}
      />
      {post && <DisplayPost post={post} />}
    </>
  );
};

export default InPost;
