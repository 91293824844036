import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Switch, TextField, Typography } from "@mui/material";
import { db, storage } from "../api/firebase";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

const OperateMusic = ({ userRole }) => {
  const [tracks, setTracks] = useState([]); // これは状態管理システムやAPIから取得した音楽トラックの配列です
  useEffect(() => {
    const fetchTracks = async () => {
      const tracksCol = collection(db, "tracks");
      const trackSnapshot = await getDocs(tracksCol);
      const tracksList = trackSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTracks(tracksList);
    };

    fetchTracks();
  }, []);

  // 再生中のトラックを追跡するためのステート
  const [playing, setPlaying] = useState(null);
  // 各トラックのaudio要素に対するrefを保持するためのオブジェクト
  const audioRefs = useRef(new Map()); // refを格納するためのMapオブジェクトを使用
  const playTrack = (id) => {
    // 現在再生中のトラックがあれば停止する
    if (playing && playing !== id && audioRefs.current[playing]) {
      audioRefs.current[playing].pause();
      audioRefs.current[playing].currentTime = 0;
    }

    // 新しく再生するトラックのIDをセットする
    setPlaying(id);
    // 新しいトラックを再生
    audioRefs.current[id].play();
  };

  const stopAllTracksExcept = (exceptId) => {
    audioRefs.current.forEach((audio, id) => {
      if (id !== exceptId) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
  };

  const handlePlay = (id) => {
    stopAllTracksExcept(id);
    setPlaying(id);
  };

  useEffect(() => {
    // 再生中のトラックが停止された場合の処理
    const stopHandler = () => {
      setPlaying(null);
    };

    if (playing) {
      const audio = audioRefs.current.get(playing);
      audio.addEventListener("ended", stopHandler);
      return () => {
        audio.removeEventListener("ended", stopHandler);
      };
    }
  }, [playing]);

  // モーダルの開閉状態と新規トラックの情報を保持するステート
  const [modalOpen, setModalOpen] = useState(false);
  const [newTrack, setNewTrack] = useState({
    creator: "",
    name: "",
    file: null,
  });

  const [openCompletionDialog, setOpenCompletionDialog] = useState(false);
  const [completionMessage, setCompletionMessage] = useState("");

  const handleCompletionClose = () => {
    setOpenCompletionDialog(false);
  };

  const [audioPreview, setAudioPreview] = useState(null); // ファイルプレビューのURL
  const [selectedFile, setSelectedFile] = useState(null); // 選択されたファイル名

  // Firebaseでトラックの表示/非表示を更新する関数
  const updateTrackVisibility = async (trackId, visible) => {
    const trackRef = doc(db, "tracks", trackId);
    await updateDoc(trackRef, { visible });
  };


  const handleVisibilityChange = async (trackId, currentVisibility) => {
    const newVisibility = !currentVisibility;
    await updateTrackVisibility(trackId, newVisibility);
    setTracks((prevTracks) =>
      prevTracks.map((track) =>
        track.id === trackId ? { ...track, visible: newVisibility } : track
      )
    );
  };


  const handleUpload = async () => {
    if (!newTrack.file) return;

    try {
      // ストレージにファイルをアップロード
      const storageRef = ref(storage, `tracks/${newTrack.file.name}`);
      const uploadResult = await uploadBytes(storageRef, newTrack.file);

      // アップロードしたファイルのURLを取得
      const fileURL = await getDownloadURL(uploadResult.ref);

      // Firestoreにトラック情報を保存し、新しいトラックのIDを取得
      const tracksCol = collection(db, "tracks");
      const docRef = await addDoc(tracksCol, {
        creator: newTrack.creator,
        name: newTrack.name,
        url: fileURL,
        fileName: newTrack.file.name,
        createdAt: new Date(),
      });

      // 新しいトラックをトラックリストに追加
      const newTrackData = {
        id: docRef.id,
        creator: newTrack.creator,
        name: newTrack.name,
        url: fileURL,
        fileName: newTrack.file.name,
        createdAt: new Date(),
      };
      setTracks((prevTracks) => [...prevTracks, newTrackData]);

      // 完了ダイアログを表示
      setCompletionMessage(
        `'${newTrack.name}' by '${newTrack.creator}' アップロード完了しました。`
      );
      setOpenCompletionDialog(true);
    } catch (error) {
      console.error("Upload failed:", error);
      // エラーダイアログを表示するなどのエラーハンドリングをここに追加
    }
    // モーダルを閉じる
    setModalOpen(false);
    // ステートをリセット
    setNewTrack({ creator: "", name: "", file: null });
    setSelectedFile(null);
    setAudioPreview(null);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [trackToDelete, setTrackToDelete] = useState(null);

  const openDeleteDialog = (track) => {
    setTrackToDelete(track);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setTrackToDelete(null);
  };

  const handleDelete = async (track) => {
    try {
      // Firebase Storageからの音楽ファイルの削除
      const storageRef = ref(storage, `tracks/${track.fileName}`);
      await deleteObject(storageRef);

      // Firestoreからのトラック情報の削除
      const trackRef = doc(db, "tracks", track.id);
      await deleteDoc(trackRef);

      // UIからのトラックの削除
      setTracks((prevTracks) => prevTracks.filter((t) => t.id !== track.id));
      // ダイアログを閉じる
      closeDeleteDialog();
      setCompletionMessage(
        `'${track.name}' by '${track.creator}' 削除完了しました。`
      );
      setOpenCompletionDialog(true);
    } catch (error) {
      console.log("error =>", error);
      console.error("Failed to delete the track:", error);
      alert("There was an error deleting the track. Please try again.");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewTrack({ ...newTrack, file: file });
      setSelectedFile(file.name);
      setAudioPreview(URL.createObjectURL(file)); // プレビュー用のURLを作成
    }
  };

  // console.log("file =>", selectedFile);
  // モーダルで新規曲情報を入力するためのフォーム
  const renderModal = () => (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
        setAudioPreview(null); // プレビューをクリア
        setSelectedFile(null); // 選択されたファイル名をクリア
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "16px",
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" component="h2">
          新しい音楽を登録
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label="作曲者"
          type="text"
          fullWidth
          required
          variant="standard"
          value={newTrack.creator}
          onChange={(e) =>
            setNewTrack({ ...newTrack, creator: e.target.value })
          }
        />
        <TextField
          margin="dense"
          label="曲名"
          type="text"
          fullWidth
          required
          variant="standard"
          value={newTrack.name}
          onChange={(e) => setNewTrack({ ...newTrack, name: e.target.value })}
        />
        <Button variant="contained" component="label">
          Upload File
          <input
            type="file"
            hidden
            // onChange={(e) =>
            //   setNewTrack({ ...newTrack, file: e.target.files[0] })
            // }
            onChange={handleFileChange} // この関数がファイル選択時に呼ばれるように設定
          />
        </Button>

        {selectedFile && (
          <>
            <Typography variant="subtitle1" component="p">
              選択されたファイル: {selectedFile}
            </Typography>
            <audio controls src={audioPreview} />
          </>
        )}

        <div>
          <Button onClick={handleUpload} color="primary">
            アップロード
          </Button>
          <Button onClick={() => setModalOpen(false)} color="secondary">
            キャンセル
          </Button>
        </div>
      </div>
    </Modal>
  );

  const renderDeleteDialog = () => (
    <Dialog
      open={deleteDialogOpen}
      onClose={closeDeleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"消去"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          次の曲を削除します。よろしいですか？ <br />
          <br />'{trackToDelete?.name}' by '{trackToDelete?.creator}'
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDeleteDialog} color="primary">
          キャンセル
        </Button>
        <Button
          onClick={() => handleDelete(trackToDelete)}
          color="primary"
          autoFocus
        >
          消去
        </Button>
      </DialogActions>
    </Dialog>
  );

  // 完了ダイアログのレンダリング
  const renderCompletionDialog = () => (
    <Dialog
      open={openCompletionDialog}
      onClose={handleCompletionClose}
      aria-labelledby="completion-dialog-title"
      aria-describedby="completion-dialog-description"
    >
      <DialogTitle id="completion-dialog-title">{"Completed"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="completion-dialog-description">
          {completionMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCompletionClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );

  const handleRoleChange = (event, track) => {
    // チェックボックスの値を更新
    // const newRoles = event.target.checked
    //   ? [...track.accessibleBy, event.target.name]
    //   : track.accessibleBy.filter((role) => role !== event.target.name);
    const newRoles = event.target.checked
    ? [...(track.accessibleBy || []), event.target.name] // もし track.accessibleBy が未定義なら空の配列を使用
    : (track.accessibleBy || []).filter(role => role !== event.target.name); // 同上



    // トラックのアクセス可能ロールを更新
    const trackIndex = tracks.findIndex((t) => t.id === track.id);
    const newTracks = [...tracks];
    newTracks[trackIndex] = { ...track, accessibleBy: newRoles };
    setTracks(newTracks);

    // Firestoreに保存（この処理は非同期であり、適切なエラーハンドリングが必要です）
    const trackRef = doc(db, "tracks", track.id);
    updateDoc(trackRef, {
      accessibleBy: newRoles,
    });
  };

  // UIのレンダリング
  return (
    <>
      <Button onClick={() => setModalOpen(true)}>音楽を追加</Button>
      {renderModal()}
      {renderDeleteDialog()}
      {renderCompletionDialog()}
      <div>
        {tracks.map((track) => (
          <div
            key={track.id}
            className="border rounded-md  p-2 w-fit my-2 cursor-pointer shadow-md hover:shadow-xl flex flex-col"
          >
            <Typography variant="body1">
              {track.name} by {track.creator}
            </Typography>

            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      track.accessibleBy
                        ? track.accessibleBy.includes("Free")
                        : false
                    }
                    onChange={(e) => handleRoleChange(e, track)}
                    name="Free"
                  />
                }
                label="Freeが再生可能"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      track.accessibleBy
                        ? track.accessibleBy.includes("User")
                        : false
                    }
                    onChange={(e) => handleRoleChange(e, track)}
                    name="User"
                  />
                }
                label="Userが再生可能"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      track.accessibleBy
                        ? track.accessibleBy.includes("Villager")
                        : false
                    }
                    onChange={(e) => handleRoleChange(e, track)}
                    name="Villager"
                  />
                }
                label="Villagerが再生可能"
              />
            </FormGroup>

            <div className="flex space-x-2 justify-center items-center">
              <Button onClick={() => openDeleteDialog(track)}>消去</Button>
              <audio
                ref={(el) => el && audioRefs.current.set(track.id, el)}
                onPlay={() => handlePlay(track.id)}
                src={track.url}
                controls
              />
              <Typography variant="body1">元データ{track.fileName}</Typography>
            </div>

            {/* <Button onClick={() => handleDelete(track)}>Delete</Button> */}
          {/* 表示/非表示を切り替えるスイッチ */}
          <FormControlLabel
            control={
              <Switch
                checked={track.visible ?? true}
                onChange={() =>
                  handleVisibilityChange(track.id, track.visible)
                }
              />
            }
            label="表示"
          />

          </div>
        ))}
      </div>
    </>
  );
};

export default OperateMusic;
