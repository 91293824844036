import React from "react";

import HeaderContainerMixIN from "../components/HeaderContainerMixIN";
import Movies from "./Movies";

const MoviesContainer = () => {
  return (
    <>
      <HeaderContainerMixIN outlet={<Movies /> } />

    </>
  );
};

export default MoviesContainer;
