import { createSlice } from "@reduxjs/toolkit";

export const snackbarInitialState = {
  open: false,
  // type: "info",
  message: "",
  timeout: 5000,
};

export const SnackbarSlice = createSlice({
  name: "snackbar",
  initialState: snackbarInitialState,
  reducers: {
    addSnackbar: (_state, action) => ({
      ...snackbarInitialState,
      ...action.payload,
      open: true,
    }),
    clearSnackbar: (state) => ({ ...state, open: false }),
  },
});

export const SnackbarActions = SnackbarSlice.actions;
export const SnackbarReducer = SnackbarSlice.reducer;
