import React from "react";

const AboutDDP = () => {
  const RegularSentence = (content) => {
    return <>{content}</>;
  };

  return (
    <>
      <div className="font-bold text-xl">DDPについて</div>
      <div className="flex flex-col text-lg text-gray-500 p-2">
        <div>夢を叶えている人の共通点は</div>
        <div>その願いが『明確』であり、かつ</div>
        <div className="flex">
          <div>『</div>
          <div className="font-bold text-black">端的にまとまっている</div>
          』ことだそうです。
        </div>
        <div className="my-7"></div>
        <div className="text-red-500 font-bold text-xl">Definition（定義）</div>
        <div className="text-red-500 font-bold text-xl">Direction（方向）</div>
        <div className="text-red-500 font-bold text-xl">Purpose（目的）</div>
        <div className="my-7"></div>
        <div className="flex">
          <div className="flex text-red-500 items-end ">
            『<div className="text-red-500 font-bold">DDP</div>』
          </div>
          <div>とは、</div>
        </div>
        <div className="flex">
          <div className="flex text-red-500 items-end">
            『
            <div className="text-red-500 font-bold">明確な意図・端的な願い</div>
            』
          </div>
          <div>のことです。</div>
        </div>
        <div className="my-7"></div>
        <div>昨今、世界は何度も同じことを繰り返して前に進みません。</div>
        <div>それを国家や政府のせいにするのは簡単ですが、</div>
        <div className="flex items-end">
          その真の原因は、実は
          <div className="font-bold text-black">『あなた』</div>
          にあります。
        </div>
        <div className="my-7"></div>
        <div>例えば、</div>
        <div> 自分の意図が明確じゃない人は</div>
        <div>政府がどのような施策を出しても</div>
        <div>必ず文句を言います。</div>
        <div className="my-7"></div>
        <div>その理由は、</div>
        <div>自分自身の願いがそもそも</div>
        <div>DDPに沿っておらず不明瞭だからです。</div>
        <div className="my-7"></div>
        <div>他人に何をされても、</div>
        <div>満たされていない感覚に陥ります。</div>
        <div className="my-7"></div>
        <div>あなたの世界を変えるためには、</div>
        <div className="flex ">
          <div>まずは</div>
          <div className=" text-red-500 font-bold">
            あなたが『明確な意図DDP』
          </div>
          <div>を持ち</div>
        </div>
        <div className="flex ">
          そして、
          <div className="text-red-500 font-bold">
            あなたの周囲にも『明確なDDP』
          </div>
          を
        </div>
        <div className="flex">
          はっきりと宣言できる
          <div className="text-red-500 font-bold">ナカマ</div>を
        </div>
        <div>
          増やす必要があります。
          <div className="my-7"></div>
          <div>例えば</div>
        </div>
        <div>DDPに沿ったあなたの夢が</div>
        <div>『タワーマンションの最上階で幸せに暮らすこと』</div>
        <div> だったとします。</div>
        <div className="my-7"></div>
        <div>その夢は別に贅沢でも悪いことでもありません。</div>
        <div>ところが、資金を集めるために大手ゼネコンは</div>
        <div>300部屋を全て埋める必要があります。</div>
        <div className="my-7"></div>
        <div>そのマンションの１Ｆ部分はグラグラ、</div>
        <div>３Ｆ部分はフニャフニャ、１１Ｆはポロポロ、</div>
        <div>要するに、他の人の夢が不明確だと</div>
        <div>３６Ｆのあなたの夢までグラついてしまい、</div>
        <div>あなたの夢の現実化も遠のいてしまうのです。</div>
        <div className="my-7"></div>
        <div>いま、世界ではそのようなことが常態化しています。</div>
        <div>大多数の『ＤＤＰを持たない人たち』によって、</div>
        <div>少数の『明確な意図を持っている人』も</div>
        <div>引きずられているのです。</div>
        <div className="my-7"></div>
        <div>そこで、</div>
        <div>皆の夢を明確に「現実化」させるために、</div>
        <div>それぞれの身の回りに、</div>
        <div>『明確な夢ＤＤＰ』を持つナカマを </div>
        <div>増やす必要があります。</div>
        <div className="my-7"></div>
        <div>もしもあなたが</div>
        <div>10人の人にＤＤＰのことをシラセると、</div>
        <div>あなた自身のＤＤＰも</div>
        <div>10倍現実になりやすくなります。</div>
        <div className="my-7"></div>
        <div className="font-bold text-3xl">DDP作成TIPS</div>
        <div className="my-7"></div>
        <div className="font-bold text-2xl text-red-500">
          ①脳は否定形をイメージできない
        </div>
        <div className="my-7"></div>
        <div>否定語を願いに入れないでください。</div>
        <div>例えば「ワクチンが無くなればいいのに」</div>
        <div>という夢を考えている間、</div>
        <div>その人の大脳は「ワクチン」を</div>
        <div>イメージし続けています。</div>
        <div className="my-7"></div>
        <div>「紫色のゾウさんを、</div>
        <div>絶対に今は考えないでくださいね」</div>
        <div> と僕に言われて、</div>
        <div className="my-7"></div>
        <div>あなたの脳内をパオーンと占拠したのは、</div>
        <div>紫色のゾウさんでしょう。</div>
        <div className="my-7"></div>
        <div>「会社の嫌いな上司とは別々の部署になりたい」</div>
        <div>と願っている人はずっと、</div>
        <div>「嫌な上司」に脳のエネルギーを費やしています。</div>
        <div className="my-7"></div>
        <div>ＤＤＰには否定語を使わず、</div>
        <div>「目指したい方向性」に向けて</div>
        <div>エネルギーや大脳のシナプスを集中させるのです。</div>
        <div className="my-7"></div>
        <div className="font-bold text-2xl text-red-500">
          ②現状から方向転換する
        </div>
        <div className="my-7"></div>
        <div>そのためのキーワードとして、</div>
        <div> 「じゃあ、どうしたいの？」と</div>
        <div>自分に質問する方法があります。</div>
        <div className="my-7"></div>
        <div>どうしても否定的なことを</div>
        <div>人間は考えてしまいますので、</div>
        <div>「じゃあ、どうしたいの？」と</div>
        <div>自分へ質問するだけで</div>
        <div>方向性を本来のＤＤＰに</div>
        <div>一致させることができます。</div>
        <div className="my-7"></div>
        <div className="font-bold text-2xl text-red-500">
          ③メタ認知領域を使う
        </div>
        <div className="my-7"></div>
        <div>次に、ＤＤＰを描く自分自身は俯瞰して</div>
        <div>メタ認識される必要があります。</div>
        <div className="my-7"></div>
        <div>「私が結婚して幸せになりたい」</div>
        <div>という夢と、</div>
        <div className="my-7"></div>
        <div>「地球に住む私が、結婚して幸せになりたい」</div>
        <div> という夢では、</div>
        <div className="my-7"></div>
        <div>イメージの質がかなり変わります。</div>
        <div className="my-7"></div>
        <div>人間は「空間の中」に存在する生き物なので、</div>
        <div>いきなり主語が「私が」から始まると、</div>
        <div>周囲との繋がりがイメージ出来ないのです。</div>
        <div className="my-7"></div>
        <div>どこに住む、私なのか。</div>
        <div>出来れば、宇宙レベルから始めます。</div>
        <div className="my-7"></div>
        <div>宇宙の中の、天の川銀河系の中の、</div>
        <div>太陽系の中の、第三惑星地球の中の、</div>
        <div>北半球の、東北地方の、秋田県の、</div>
        <div>由利本荘市の、どこどこに住む私。</div>
        <div className="my-7"></div>
        <div>提供するＤＤＰの中に、</div>
        <div>上記の文章を入れる必要はありませんが、</div>
        <div> 頭のどこかで、そのイメージを</div>
        <div>保持しておくことが大切です。</div>
        <div className="my-7"></div>
        <div className="font-bold text-2xl text-red-500">④夢の巻き添え</div>
        <div className="my-7"></div>
        <div>これも「関係性」の領域から来る問題ですが、</div>
        <div>あなたの夢が「独りよがり」であればあるほど、</div>
        <div>現実化が遅くなります。</div>
        <div className="my-7"></div>
        <div>先ほどのタワーマンションの例にもあるように、</div>
        <div>多くの人と「共通する部分」が多い夢こそ、</div>
        <div>スピードを持って現実になります。</div>
        <div className="my-7"></div>
        <div>あなたのＤＤＰが、</div>
        <div>誰を幸せにするのかを考えてみましょう。</div>
        <div className="my-7"></div>
        <div>あなたの夢が叶ったせいで、</div>
        <div>幸せになってしまう人たちです。</div>
        <div className="my-7"></div>
        <div className="font-bold text-2xl text-red-500">⑤思考を支える思考</div>
        <div className="my-7"></div>
        <div>先ほどの、</div>
        <div>「結婚して幸せになりたい」という夢を持つ人に、</div>
        <div>「どうして幸せになりたいの？」と聞くと、</div>
        <div>「今は、幸せじゃないから」と答えるでしょう。</div>
        <div className="my-7"></div>
        <div>これは、【思考を支える思考】と呼ばれる</div>
        <div>脳内の前提条件で、</div>
        <div>この部分が変わらないと、</div>
        <div>明確な夢を意図できません。</div>
        <div className="my-7"></div>
        <div>些細な表現の違いですが、</div>
        <div>「結婚して幸せになりたい」よりも、</div>
        <div>「結婚して幸せに暮らしている」のほうが、</div>
        <div>【思考を支える思考】が立ち上がりにくくなります</div>
        <div className="my-7"></div>
        <div>DDP作成にはもしかしたら、</div>
        <div>他者の協力が必要になる人も</div>
        <div>いるかも知れません。</div>
        <div className="my-7"></div>
        <div>そんなときは、</div>
        <div>セカイムラのチャット機能で</div>
        <div>事務局に相談して組みてください。</div>
        <div className="my-7"></div>
        <div>また、</div>
        <div>セカイムラのDDP広場には、</div>
        <div>「DDP作成のお手伝い」を自分のDDPとして</div>
        <div>登録している人も</div>
        <div>いるかも知れません。</div>
        <div className="my-7"></div>
        <div>あなたのDDPが実りますように。</div>
        
      </div>
    </>
  );
};

export default AboutDDP;
