import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import ModalForm from "./ModalForm";

const AddComponent = ({ value, target, requestId }) => {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleModalOpen = (e) => {
    setOpen(true);
    setForm(e.target);
  };
  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />
      <div
        className="flex items-center h-12 w-fit shadow-md border rounded-xl bg-white p-3  hover:shadow-lg mb-2 hover:cursor-pointer duration-200"
        onClick={() => handleModalOpen({ target })}
      >
        <PlusIcon className="h-4 w-4 text-red-500 rounded-full " />
        <div>{value}</div>
      </div>
    </>
  );
};

export default AddComponent;
