import React, { useState } from "react";
import {  doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";
import { useDocumentData } from "react-firebase-hooks/firestore";

const EditNickName = ({ setOpen }) => {

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    nickName: "",
    message: "",
    error: null,
    loading: false,
  });
  const { nickName, message, error, loading } = data;

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄か、変更がないようです" });
        // handleEditOff()
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "users", user.uid), {
        [e.target.name]: data[e.target.name],
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      setOpen(false)
      // window.location.reload();
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>

        <div className="font-bold text-xl">ニックネーム</div>
        <input
          type="text"
          name="nickName"
          className=" border w-full p-1"
          placeholder={user?.nickName}
          defaultValue={user?.nickName}
          onChange={handleChange}
        ></input>
        <div className="text-red-500">{message ? message : ""}</div>
        <Button
          className="btn"
          name="nickName"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditNickName;
