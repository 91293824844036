import React, { memo, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";

// YouTubeの動画IDを抽出する関数
const extractYouTubeId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

// サムネイル画像をクリックしてYouTube動画をロードするコンポーネント
const YouTubeVideo = ({ videoId, title }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoadVideo = () => {
    setIsLoaded(true);
  };

  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
      {!isLoaded ? (
        <img
          src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
          alt={title}
          onClick={handleLoadVideo}
          style={{
            cursor: 'pointer',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: '4px',
          }}
        />
      ) : (
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title={title}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '4px',
          }}
        ></iframe>
      )}
    </div>
  );
};

const PlayMovie = ({ movieDoc }) => {
  return (
    <>
      {/* Vimeoの場合 */}
      {movieDoc.platform === "Vimeo" && (
        <>
          <div className="rounded-md">
            <Vimeo
              key={Math.random()}
              video={movieDoc.url}
              responsive
            />
          </div>
          <div className="my-5"></div>
          <div className="font-bold line-clamp-2 text-4xl whitespace-pre-wrap break-words ">
            {movieDoc.title}
          </div>
          <div className=" line-clamp-2 text-xl text-gray-400 mb-2 ">
            {movieDoc.explain}
          </div>
        </>
      )}

      {/* YouTubeの場合 */}
      {movieDoc.platform === "YouTube" && (
        <>
          <div className="rounded-md">
            <YouTubeVideo
              videoId={extractYouTubeId(movieDoc.url)}
              title={movieDoc.title}
            />
          </div>
          <div className="my-5"></div>
          <div className="font-bold line-clamp-2 text-4xl whitespace-pre-wrap break-words ">
            {movieDoc.title}
          </div>
          <div className=" line-clamp-2 text-xl text-gray-400 mb-2 ">
            {movieDoc.explain}
          </div>
        </>
      )}
    </>
  );
};

export default memo(PlayMovie);
