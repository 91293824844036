import React, { useCallback, useContext } from "react";
import { ImageDropzone, FileUploadButton } from "react-file-utils";
import {
  ChatAutoComplete,
  EmojiPicker,
  UploadsPreview,
  useChannelStateContext,
  useMessageInputContext,
  FileUploadIcon,
} from "stream-chat-react";

import { GiphyContext } from "../DealChat";

import { EmojiIcon, LightningBoltSmall, SendIcon } from "../../assets";

import "./MessagingInput.css";

const GiphyIcon = () => (
  <div className="giphy-icon__wrapper">
    <LightningBoltSmall />
    <p className="giphy-icon__text">GIPHY</p>
  </div>
);

const MessagingInputOnDDPExchange = () => {
  const { giphyState, setGiphyState } = useContext(GiphyContext);
  const { acceptedFiles, maxNumberOfFiles, multipleUploads } =
    useChannelStateContext();

  const messageInput = useMessageInputContext();

  const onChange = useCallback(
    (event) => {
      const { value } = event.target;
      const deletePressed =
        event.nativeEvent?.inputType === "deleteContentBackward";

      if (messageInput.text.length === 1 && deletePressed) {
        setGiphyState(false);
      }

      if (
        !giphyState &&
        messageInput.text.startsWith("/giphy") &&
        !messageInput.numberOfUploads
      ) {
        event.target.value = value.replace("/giphy", "");
        setGiphyState(true);
      }

      messageInput.handleChange(event);
    },
    [giphyState, messageInput.numberOfUploads, messageInput.text] // eslint-disable-line
  );

  return (
    <div className="str-chat__messaging-input">
      <ImageDropzone
        accept={acceptedFiles}
        handleFiles={messageInput.uploadNewFiles}
        multiple={multipleUploads}
        disabled={
          (maxNumberOfFiles !== undefined &&
            messageInput.numberOfUploads >= maxNumberOfFiles) ||
          giphyState
        }
      >
        <UploadsPreview />
        <div className="messaging-input__container">
          <FileUploadButton
            accept={acceptedFiles}
            handleFiles={messageInput.uploadNewFiles}
            multiple={multipleUploads}
            disabled={
              (maxNumberOfFiles !== undefined &&
                messageInput.numberOfUploads >= maxNumberOfFiles) ||
              giphyState
            }
          />
          {/* <div onClick={console.log("hoge")}>
            <ControlPointOutlinedIcon />
          </div> */}
          {/* <FileUploadIcon /> */}

          {/* メッセージ入力欄 */}
          <div className="messaging-input__input-wrapper">
            {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}

            <ChatAutoComplete
              onChange={onChange}
              rows={1}
              placeholder="メッセージを入力"
            />
            {/* 絵文字ピッカー */}
            <div
              className="messaging-input__button emoji-button mr-2"
              role="button"
              aria-roledescription="button"
              onClick={messageInput.openEmojiPicker}
              ref={messageInput.emojiPickerRef}
            >
              <EmojiIcon />
            </div>
          </div>

          {/* 送るボタン */}
          <div
            className="messaging-input__button"
            role="button"
            aria-roledescription="button"
            onClick={messageInput.handleSubmit}
          >
            <SendIcon />
          </div>
        </div>
      </ImageDropzone>
      <EmojiPicker />
    </div>
  );
};

export default React.memo(MessagingInputOnDDPExchange);
