import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import defaultBackImg from "../data/img/defaultBack.jpg";
import LoaderBackImg from "./LoaderBackImg";
import Camera from "../data/svg/Camera";
import Loading from "./Loading";
import Blind from "./Blind";
import { useDocumentData } from "react-firebase-hooks/firestore";

const BackImage = ({ noEdit, id }) => {
  // const [user] = useDocumentData(doc(db, "users", id));

  const [stickyClass, setStickyClass] = useState("top-16");


  const [user, setUser] = useState("");
  useEffect(() => {
    const init = () => {
      getDoc(doc(db, "users", id)).then((elm) =>
        setUser({ id: elm.id, ...elm.data() })
      );
    };
    if (id) {
      init();
    }

    // ウィンドウのリサイズイベントを監視
    window.addEventListener("resize", handleWindowResize);
    return () => {
      // コンポーネントがアンマウントされるときにイベントリスナーをクリーンアップ
      window.removeEventListener("resize", handleWindowResize);
    };

  }, []);


  // ウィンドウのリサイズ時にクラスを更新
  const handleWindowResize = () => {
    if (window.innerWidth >= 600) {
      setStickyClass("rounded-t-md ");
    } else {
      setStickyClass(" ");
    }
  };

  //   背景画像コンテナ
  const [backImg, setBackImg] = useState("");
  //   セットコンテナ
  const [isLoading, setIsLoading] = useState("");
  //   背景画像Ref
  const backImgRef = useRef(null);

  // プロフィール画像変更処理
  useEffect(() => {
    if (backImg) {
      setIsLoading(true);

      const uploadBackImg = async () => {
        const backImgRef = ref(
          storage,
          `background/${new Date().getTime()} - ${backImg.name}`
        );
        try {
          if (user?.backgroundPath) {
            await deleteObject(ref(storage, user?.backgroundPath));
          }

          const snap = await uploadBytes(backImgRef, backImg);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await updateDoc(doc(db, "users", auth.currentUser.uid), {
            background: url,
            backgroundPath: snap.ref.fullPath,
          });
          // console.log(backImg)
          setBackImg("");
          // window.location.reload();
          setIsLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      };
      uploadBackImg();
    }
  }, [backImg]);

  if (isLoading) {
    return (
      <>
        <Blind blind={true} />
        <Loading title="プロフィール" action="更新中" />;
      </>
    );
  }
  return (
    <>
      <div
        className={`${
          noEdit
            ? "pointer-events-none "
            : "hover:opacity-50  cursor-pointer   "
        }   " relative  flex justify-center items-center w-full h-4/5  group"`}
      >
        <div className={`" absolute bottom-0 left-0 w-full h-20 bg-gradient-to-t from-white to-transparent "`} />
        {user?.background ? (
          <img
            src={user?.background || defaultBackImg}
            object-fit="cover"
            className={` ${
              noEdit && "pointer-events-none"
            }   ${stickyClass}  " w-full h-72 rounded-t-md"`}
            alt=""
            onClick={() => backImgRef.current.click()}
          />
        ) : defaultBackImg ? (
          <img
            src={defaultBackImg}
            alt="バックイメージ"
            className="w-full h-72 rounded-t-md"
          />
        ) : (
          <LoaderBackImg />
        )}
        {!noEdit && (
          <div
            className={` ${
              noEdit && "pointer-events-none "
            }  " absolute top-2   sm:hidden group-hover:flex text-gray-200 sm:text-black opacity-80 hover:opacity-100 duration-300"`}
            onClick={() => backImgRef.current.click()}
          >
            <Camera className="" />
            <input
              ref={backImgRef}
              onChange={(e) => setBackImg(e.target.files[0])}
              type="file"
              hidden
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BackImage;
