import { Divider } from "@mui/material";
import React from "react";
import { auth } from "../api/firebase";
import { useAuth } from "../context/auth";
import AvatarAndName from "./AvatarAndName";
import DisplayRunruBalance from "./DisplayRunruBalance";
import DisplaySignUp from "./DisplaySignUp";
import DrawerList from "./DrawerList";

const HeaderDrawerMenuLists = ({ open, setOpen }) => {
  const { user } = useAuth();

  if (!user && !open) return;
  if (!user && open)
    return (
      <div className="flex justify-center">
        <DisplaySignUp />
      </div>
    );

//  console.log("setOpen =>",setOpen)

  return (
    <>
      {/* {open && <AvatarAndName />} */}
      <Divider></Divider>
      <DrawerList open={open} setOpen={setOpen}/>

    </>
  );
};

export default HeaderDrawerMenuLists;
