import React, { useState, useEffect, memo, useMemo } from "react";
import {
  Avatar,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Badge,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline"; // 職業のアイコン
import CakeIcon from "@mui/icons-material/Cake"; // 誕生日のアイコン
import FavoriteIcon from "@mui/icons-material/Favorite"; // 血液型のアイコン

// Firestore v9のmodular APIをインポート
import {
  collection,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  collectionGroup, // collectionGroup を追加
} from "firebase/firestore";
import { db } from "../api/firebase"; // 既にカプセル化されたFirestoreのインスタンスをインポート
import { styled } from "@mui/material/styles";

// StyledBadgeの定義
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const DisplayUserCard = ({ user }) => {
  const [isOnline, setIsOnline] = useState(false);
  const [ddp, setDDP] = useState(null);

  useEffect(() => {
    if (!user?.uid) return;

    // ユーザのデバイスコレクションへの参照を作成
    const devicesRef = collection(db, "users", user.uid, "devices");
    // isOnlineがtrueのデバイスを検索するクエリを作成
    const onlineQuery = query(devicesRef, where("isOnline", "==", true));

    const unsubscribe = onSnapshot(onlineQuery, (snapshot) => {
      // スナップショット内のドキュメントが存在する（isOnlineがtrueのもの）場合、ユーザをオンラインとみなす
      setIsOnline(!snapshot.empty);
    });

    // コンポーネントのアンマウント時にリスナーを解除
    return () => unsubscribe();
  }, [user?.uid]);

  useEffect(() => {
    if (!user?.uid) return;

    // ユーザのDDPsコレクションへの参照を作成
    const ddpsRef = collection(db, "users", user.uid, "ddps");
    // ddpsコレクション内のドキュメントをupdatedAtの降順で並び替え、最初の1件のみを取得するクエリを作成
    const ddpsQuery = query(ddpsRef, orderBy("updatedAt", "desc"), limit(1));

    const unsubscribe = onSnapshot(ddpsQuery, (snapshot) => {
      if (!snapshot.empty) {
        // 最新のDDPが見つかった場合は、そのドキュメントを取得してセットする
        snapshot.forEach((doc) => {
          const ddpData = doc.data();
          if (ddpData.display) {
            setDDP(ddpData);
          } else {
            setDDP(null); // displayがfalseの場合はDDPを表示しない
          }
        });
      } else {
        setDDP(null); // DDPが見つからない場合はnullをセット
      }
    });

    return () => unsubscribe();
  }, [user?.uid]);

  // console.log("ddp =>",ddp)
  // 誕生日までの日数を計算する関数
  const calculateDaysUntilBirthday = (birthMonth, birthDay) => {
    if (!birthMonth || !birthDay) return "";

    const today = new Date();
    const currentYear = today.getFullYear();
    let nextBirthday = new Date(currentYear, birthMonth - 1, birthDay);

    if (today > nextBirthday) {
      nextBirthday = new Date(currentYear + 1, birthMonth - 1, birthDay);
    }

    const diffTime = Math.abs(nextBirthday - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 1 ? "明日" : `${diffDays} 日`;
  };

  // 誕生日までの日数をメモ化
  const daysUntilBirthday = useMemo(
    () => calculateDaysUntilBirthday(user?.birthmonth, user?.birthday),
    [user?.birthmonth, user?.birthday]
  );

  // 表示する情報があるかどうかをチェック
  const hasUserInfo =
    user?.job ||
    (user?.displayBirthDay && user?.birthmonth && user?.birthday) ||
    (user?.displayBloodType && user?.bloodtype);

  return (
    <Accordion
      style={{
        background: isOnline ? "#e8f5e9" : "#faf9f7",
        boxShadow: "none",
        position: "static",
        borderRadius: "0.375rem",
      }}
      className="my-2 border  rounded-md duration-150"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="flex items-center space-x-2 ">
          {isOnline ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar src={user?.avatar} />
            </StyledBadge>
          ) : (
            <Avatar src={user?.avatar} />
          )}
          <div className="flex flex-col">
            <Typography
              sx={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal", // これにより、テキストは必要に応じて折り返されます
              }}
              className="font-bold text-lg text-gray-400"
            >
              {user?.lastName}
              {user?.firstName}
            </Typography>
            <Typography className="text-xs">@{user?.prefecture}</Typography>
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="rounded-lg">
        <div className="flex flex-col justify-between w-full">
          {user?.selfIntroduction && (
            <div className="flex-1 p-4 bg-white rounded-lg shadow text-gray-800 border border-gray-300 mb-4">
              <h3 className="text-lg font-semibold mb-2">自己紹介</h3>
              <p className="text-sm break-words whitespace-normal">
                {user?.selfIntroduction}
              </p>
            </div>
          )}

          {hasUserInfo && (
            <div className="bg-white p-4 rounded-lg shadow-md mb-4 text-gray-800">
              {user?.job && (
                <div className="flex items-center mb-3">
                  <WorkOutlineIcon
                    style={{ color: "#1976d2", fontSize: "24px" }}
                  />
                  <span className="ml-2 text-sm font-medium">{user?.job}</span>
                </div>
              )}
              {user?.displayBirthDay && user?.birthmonth && user?.birthday && (
                <div className="flex items-center mb-3">
                  <CakeIcon style={{ color: "#e91e63", fontSize: "24px" }} />
                  <span className="ml-2 text-sm font-medium">
                    {user?.birthmonth}月{user?.birthday}日 (あと{" "}
                    {daysUntilBirthday})
                  </span>
                </div>
              )}
              {user?.displayBloodType && user?.bloodtype && (
                <div className="flex items-center">
                  <FavoriteIcon
                    style={{ color: "#f44336", fontSize: "24px" }}
                  />
                  <span className="ml-2 text-sm font-medium">
                    {user?.bloodtype}型
                  </span>
                </div>
              )}
            </div>
          )}

          {ddp && (
            <div className="flex flex-col p-4 rounded-lg shadow-md mb-4 text-sm font-medium text-gray-600 bg-white hover:shadow-lg transition-shadow duration-300">
              <div className="flex items-center justify-between mb-2">
                <span className="text-lg text-blue-500 font-semibold">DDP</span>
              </div>
              <div className="text-base text-gray-700 break-words whitespace-normal">
                <p>{ddp.ddp}</p>
              </div>
            </div>
          )}
        </div>

        <Button
          size="small"
          variant="outlined"
          onClick={() => window.open("/user/" + user?.uid)}
        >
          プロフィールを見る
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(DisplayUserCard);
