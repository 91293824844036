import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Img from "../data/img/logo.png";
import GetUserInfo from "./GetUserInfo";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import GetBaseName from "./GetBaseName";
import EventLikeAction from "./EventLikeAction";

const DisplayEventFromGroupForAll = ({ event }) => {
  const navigate = useNavigate();

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const [daysDifference, setDaysDifference] = useState(null);
  const [timesDifference, setTimesDifference] = useState(null);
  useEffect(() => {
    const currentDate = new Date();
    const publishDate = event?.publishDate.toDate();
    const timeDifference = publishDate - currentDate;
    const timesDifference = Math.floor(timeDifference / (1000 * 3600));
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    setDaysDifference(daysDifference);
    setTimesDifference(timesDifference);
  }, [event]);

  return (
    <>
      <div className="group border rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer">
        <div
          className="group border rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300"
          onClick={(e) => {
            e.stopPropagation();
            navigate("/event/" + event?.id);
          }}
        >
          {/* イメージ */}
          <div className="relative">
            <img
              className={`w-full h-48 object-cover transition-transform duration-300 hover:scale-105 ${
                daysDifference < 0 ? "opacity-50" : ""
              }`}
              src={event?.background || Img}
              alt=""
            />

            <div
              className={`absolute top-2 right-2 flex items-center space-x-2 px-3 py-1 rounded-full text-sm font-bold ${
                event?.target === "group"
                  ? "bg-blue-500 text-white"
                  : event?.target === "operation"
                  ? "bg-green-500 text-white"
                  : "bg-orange-500 text-white"
              }`}
            >
              {event?.target === "group" && "グループ"}
              {event?.target === "operation" && "セカイムラ"}
              {event?.target === "base" && (
                <GetBaseName baseId={event?.targetId} />
              )}
            </div>
          </div>

          {/* 詳細 */}
          <div className="flex items-start p-4 space-x-4">
            {/* 月・日 */}
            <div className="flex flex-col items-center text-center">
              <div className="text-lg text-red-500">
                {months[new Date(event?.publishDate.toDate()).getMonth()]}
              </div>
              <div className="text-2xl font-bold">
                {new Date(event?.publishDate.toDate()).getDate()}
              </div>
            </div>

            {/* 右側の詳細 */}
            <div className="flex flex-col justify-between flex-1">
              <div>
                {/* 開催日時 */}
                <div className="text-lg text-gray-500">
                  {new Date(event?.publishDate.toDate()).getMonth() + 1}月
                  {new Date(event?.publishDate.toDate()).getDate()}日 
                  {new Date(event?.publishDate.toDate()).getHours()}:
                  {new Date(event?.publishDate.toDate())
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}〜
                </div>

                {/* イベントタイトル */}
                <div className="text-xl font-bold line-clamp-1 group-hover:text-green-500">
                  {event?.title}
                </div>
              </div>

              {/* 参加費と残日数 */}
              <div className="flex justify-between items-center text-sm mt-2">
                <div className="flex items-center space-x-1">
                  <CurrencyYenIcon
                    className="text-gray-400"
                    sx={{ fontSize: 16 }}
                  />
                  <span className="text-gray-600 font-medium">
                    {event?.price}
                  </span>
                </div>
                <div className="text-xs text-gray-500">
                  {daysDifference > 0
                    ? `残り${daysDifference}日`
                    : daysDifference === 0
                    ? `残り${timesDifference}時間`
                    : "開催済み"}
                </div>
              </div>
            </div>
          </div>
          <div className="relative z-10">
            <EventLikeAction className="w-full h-full" event={event} />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(DisplayEventFromGroupForAll);
