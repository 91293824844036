import React, { useState } from "react";
import CheckboxProton from "./CheckBoxPronton";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";

const FilterBase = ({ values, changeChecked, clearFilter, researchCount }) => {
  // ドロワー
  const [state, setState] = useState(false);
  const toggleDrawer = () => {
    setState(!state);
  };
  const closeDrawer = () => {
    setState(false);
  };


  return (
    <>
      <div className=" p-4 hidden lg:block">
        {/* チェックボックス */}
        {/* <div className="mb-2">
          <p className="mb-2 text-xl font-bold">所在</p>
          {values?.map((value) => (
            <CheckboxProton
              key={value.id}
              values={value}
              changeChecked={changeChecked}
            />
          ))}
        </div> */}
        {/* 特産物 */}
        <div className="mb-2">
          <p className="mb-2 text-xl font-bold">特産物</p>
          {/* {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))} */}
        </div>
        {/* 設備 */}
        <div className="mb-2">
          <p className="mb-2 text-xl font-bold">設備</p>
          {/* {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))} */}
        </div>
        {/* 土地 */}
        <div className="mb-2">
          <p className="mb-2 text-xl font-bold">土地</p>
          {/* {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))} */}
        </div>
        {/* 空き家 */}
        <div className="mb-2">
          <p className="mb-2 text-xl font-bold">空き家</p>
          {/* {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))} */}
        </div>
      </div>

      <div className="">
        <div className="flex justify-end lg:hidden">
          <Button onClick={toggleDrawer}>絞り込み</Button>
        </div>
        <SwipeableDrawer anchor="bottom" open={state} onClose={closeDrawer}>
          <div className=" h-[calc(100vh-160px)]">
            <div className=" flex flex-col p-4 lg:hidden">
              <Button
                disabled={!researchCount || researchCount === 1}
                onClick={clearFilter}
              >
                フィルター解除
              </Button>
              {/* 所在 */}
              <div className="mb-2">
                <p className="mb-2 text-xl font-bold">所在</p>
                {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))}
              </div>
              {/* 特産物 */}
              <div className="mb-2">
                <p className="mb-2 text-xl font-bold">特産物</p>
                {/* {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))} */}
              </div>
              {/* 設備 */}
              <div className="mb-2">
                <p className="mb-2 text-xl font-bold">設備</p>
                {/* {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))} */}
              </div>
              {/* 土地 */}
              <div className="mb-2">
                <p className="mb-2 text-xl font-bold">土地</p>
                {/* {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))} */}
              </div>
              {/* 空き家 */}
              <div className="mb-2">
                <p className="mb-2 text-xl font-bold">空き家</p>
                {/* {values?.map((value) => (
                  <CheckboxProton
                    key={value.id}
                    values={value}
                    changeChecked={changeChecked}
                  />
                ))} */}
              </div>

              <div className="flex justify-end ">
                <Button
                  onClick={closeDrawer}
                  variant="contained"
                  disabled={!researchCount || researchCount === 1}
                >
                  決定
                </Button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    </>
  );
};

export default FilterBase;
