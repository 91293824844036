import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import ModalForm from "./ModalForm";

const AddComponentMini = ({ value, target, requestId }) => {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleModalOpen = (e) => {
    setOpen(true);
    setForm(e.target);
  };
  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />
      <div
        className="flex items-center h-5 w-5 border rounded-full bg-white p-1 shadow-md  hover:shadow-lg  hover:cursor-pointer"
        onClick={() => handleModalOpen({ target })}
      >
        <PlusIcon className=" w-2 h-2 text-red-500 rounded-full " />
        <div>{value}</div>
      </div>
    </>
  );
};

export default AddComponentMini;
