import React, { useState, useEffect } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Avatar, Button, IconButton as MuiIconButton, ListItemSecondaryAction } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { motion, useAnimation } from "framer-motion";
import { addDoc, collection, doc, getDoc, getDocs, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import GetUserInfo from "./GetUserInfo";
import GetUserPrefecture from "./GetUserPrefecture"; // 新しいコンポーネントをインポート
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";

const LikeAction = ({ post }) => {
  const [liked, setLiked] = useState(false);
  const heartControls = useAnimation();
  const [myReaction, setMyReaction] = useState("");
  const [postReactions, setPostReactions] = useState(0);
  const [postReactionUsers, setPostReactionUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchReactionData = async () => {
      if (post && auth.currentUser) {
        const userDocRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "postReactions",
          post.id
        );
        const userSnapshot = await getDoc(userDocRef);
        const postReactionsCollectionRef = collection(
          db,
          "post",
          post.id,
          "postReactions"
        );
        const postReactionsSnapshot = await getDocs(postReactionsCollectionRef);

        if (userSnapshot.exists()) {
          const userReaction = userSnapshot.data().reaction;
          if (userReaction !== "noReaction") {
            setMyReaction("liked");
          } else {
            setMyReaction("");
          }
        } else {
          setMyReaction("");
        }

        // likeの数カウント
        let count = 0;
        postReactionsSnapshot.forEach((doc) => {
          const reaction = doc.data().reaction;
          if (reaction !== "noReaction") {
            count++;
          }
        });
        setPostReactions(count);

        // likeしてくれた人取得
        let arr = [];
        postReactionsSnapshot.forEach((elm) => {
          const reaction = elm.data().reaction;
          if (reaction !== "noReaction") {
            arr.push({ id: elm.id, ...elm.data() });
          }
        });
        setPostReactionUsers(arr);
      }
    };

    fetchReactionData();
  }, [post, auth.currentUser]);

  const animateHeart = async () => {
    await heartControls.start({ scale: 1.75 });
    await heartControls.start({ scale: 1 });
  };

  const handleLike = async () => {
    if (myReaction === "liked") {
      try {
        await updateReaction("noReaction");
        setMyReaction("");
        setLiked(false);
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      try {
        await updateReaction("liked");
        setMyReaction("liked");
        setLiked(true);
        animateHeart();
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const updateReaction = async (reaction) => {
    const userDocRef = doc(
      db,
      "users",
      auth.currentUser.uid,
      "postReactions",
      post.id
    );
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      await updateDoc(userDocRef, {
        updatedAt: serverTimestamp(),
        reaction: reaction,
        creator: auth.currentUser.uid,
      });
      await updateDoc(
        doc(db, "post", post.id, "postReactions", auth.currentUser.uid),
        {
          updatedAt: serverTimestamp(),
          reaction: reaction,
          creator: auth.currentUser.uid,
        }
      );
    } else {
      await setDoc(userDocRef, {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        reaction: reaction,
        creator: auth.currentUser.uid,
      });
      await setDoc(
        doc(db, "post", post.id, "postReactions", auth.currentUser.uid),
        {
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          reaction: reaction,
          creator: auth.currentUser.uid,
        }
      );
    }

    const postReactionsCollectionRef = collection(
      db,
      "post",
      post.id,
      "postReactions"
    );
    const postReactionsSnapshot = await getDocs(postReactionsCollectionRef);
    setPostReactions(
      postReactionsSnapshot.docs.filter(
        (doc) => doc.data().reaction !== "noReaction"
      ).length
    );
  };

  const navigate = useNavigate();
  const handleMoveUserPage = (id) => {
    navigate("/user/" + id);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "rgba(0, 0, 0, 0.40)",
      maxWidth: 440,
      fontSize: theme.typography.pxToRem(12),
    },
  }));

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className={` ${myReaction && " bg-pink-100 "} " flex justify-center  rounded-lg h-10 bg-back  w-full items-baseline hover:cursor-pointer border " `}
        onClick={handleLike}
      >
        <IconButton className="h-10">
          <motion.div animate={heartControls}>
            <FavoriteIcon color={myReaction ? "error" : "inherit"} />
          </motion.div>
        </IconButton>
        <div>{postReactions}</div>
      </div>

      <div className="flex w-full">
        {postReactionUsers?.slice(0, 2).map((elm, idx) => (
          <div
            key={elm.id}
            className="flex whitespace-nowrap text-blue-500 hover:cursor-pointer hover:underline"
            onClick={handleOpenDialog}
          >
            <GetUserInfo uid={elm.id} target="lineName" key={elm.id}/>
            さん、
          </div>
        ))}
        {postReactionUsers.length > 2 && (
          <HtmlTooltip
            title={
              <div className="w-fit max-h-40 overflow-y-auto scrollbar-hide">
                {postReactionUsers?.map((elm) => (
                  <div className="flex items-center space-y-1 space-x-1 text-white " key={elm.id}>
                    <GetUserInfo uid={elm.id} target="avatar" size={20} />
                    <GetUserInfo uid={elm.id} target="name" />
                    <div>さん、</div>
                  </div>
                ))}
              </div>
            }
            followCursor
            arrow
            placement="top"
          >
            <div className="text-blue-500 hover:cursor-pointer" onClick={handleOpenDialog}>他</div>
          </HtmlTooltip>
        )}
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>
          いいねした人 ({postReactionUsers.length}人)
          <MuiIconButton aria-label="close" onClick={handleCloseDialog} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            {postReactionUsers.map((user) => (
              <ListItem key={user.id} button>
                <ListItemAvatar>
                  <Avatar>
                    <GetUserInfo uid={user.id} target="avatar" size={40} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<GetUserInfo uid={user.id} target="name" />}
                  secondary={
                    <>
                      <GetUserPrefecture userId={user.id} />{" "}
                      {formatDistanceToNow(user.updatedAt.toDate(), {
                        locale: ja,
                      })}{" "}
                      前
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Button onClick={() => handleMoveUserPage(user.id)}>
                    もっと見る
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Button onClick={handleCloseDialog} color="primary">
            閉じる
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LikeAction;
