import { useDispatch } from "react-redux";
import { SnackbarActions } from "./snackbarSlice";

export const useSnackbar = () => {
  const dispatch = useDispatch();

  const displaySnackbar = (snackbar) => {
    dispatch(SnackbarActions.addSnackbar(snackbar));
  };

  const clearSnackbar = () => {
    dispatch(SnackbarActions.clearSnackbar());
  };

  return { displaySnackbar, clearSnackbar };
};
