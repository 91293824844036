import { AvatarGroup, Avatar, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

const BaseMembersAvatar = ({ members }) => {
  return (
    <>
      {members && (
        <AvatarGroup total={members?.length}>
          {members?.map((elm) => (
            <Tooltip title={elm.lastName + elm.firstName}>
              <Avatar alt={elm?.lastName + elm?.firstName} src={elm?.avatar} />
            </Tooltip>
          ))}
        </AvatarGroup>
      )}
    </>
  );
};

export default BaseMembersAvatar;
