import { doc, getDoc } from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import { db } from "../api/firebase";

const GetUserField = ({ userId, target }) => {
  const [targetField, setTargetField] = useState("");
  useEffect(() => {
    const init = async () => {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists) {
        setTargetField(docSnap.data()[target]);
      }
    };
    userId && init();
  }, [userId]);

  return targetField;
};

export default memo(GetUserField);
