import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { auth, db } from "../api/firebase";

import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "stream-chat-react";
import ModalForm from "./ModalForm";
import { useAuth } from "../context/auth";

const EventTitle = ({ id }) => {
  const { user } = useAuth();
  // ModalForm用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleModalOpen = (e) => {
    setOpen(true);
    setForm(e);
  };

  // イベントの作成者かどうか
  const [eventDoc] = useDocumentData(doc(db, "event", id));
  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const initSetAuthor = async () => {
      if (eventDoc?.author === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    user && initSetAuthor();
  }, [eventDoc]);

  // ユーザ情報
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initGetUser = async () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && initGetUser();
  }, []);

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} requestId={id} />

      <div className="flex text-gray-600 font-bold text-xl md:text-5xl inline-block align-middle line-clamp-3">
        {/* {(userInfo?.isAdmin || isAuthor) && (
          <div
            className="mb-4"
            onClick={() => handleModalOpen("editEventTitle")}
          >
            <Tooltip title="クリックで編集">
              <div className="hover:shadow-xl hover:cursor-pointer hover:bg-gray-200 rounded-full p-2 w-10 h-10">
                <EditIcon />
              </div>
            </Tooltip>
          </div>
        )} */}
        {eventDoc?.title}
      </div>
    </>
  );
};

export default EventTitle;

// 後学者へ
// MUI TextFieldの Validation
// https://zenn.dev/enish/articles/5cc332d3eeb1a7

// 「aaaaaaaaaa・・・・」のようなすべて半角英数の場合、
// whitespace-normalは発動されない。

// 一文字でも「あ」のような文字が入ればうまくいく
