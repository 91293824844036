import React, { useState, useEffect } from "react";

// コンポーネント
import Sidebar from "./Sidebar";
import HeaderRightMenus from "./HeaderRightMenus";
import HeaderLeftMenus from "./HeaderLeftMenus";

// MaterialUi
import { Drawer, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderContainer from "./HeaderContainer";
import HeaderDrawerMenu from "./HeaderDrawerMenu";
import { useAuth } from "../context/auth";

function Header() {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const {user} = useAuth()

  // リサイズ対応
  useEffect(() => {
    // const onResize = () => {
    //   setSidebarOpen(false);
    // };
    // window.addEventListener("resize", onResize);
    // return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <>
      {/* ドロワー */}
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleOpen}
        // className="md:hidden"
      >
        {user?<HeaderDrawerMenu toggleOpen={toggleOpen} /> : ""}
      </Drawer>

      {/* メインメニュー */}
      <Toolbar sx={{ background: "white" }} className=" md:hidden">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleOpen}
          edge="start"
          sx={{
            display: { xs: "display", sm: "block" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <HeaderLeftMenus />
        <HeaderRightMenus />
      </Toolbar>
    </>
  );
}

export default Header;
