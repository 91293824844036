import React from "react";

function Loading({ title, action }) {
  return (
    <>
      <div className="fixed flex  top-0 left-0 z-50 opacity-30 bg-gray-300 h-screen w-screen items-center justify-center font-bold font-mono text-3xl">
        <div className="animate-bounce">
          {title}、{action}
        </div>
      </div>
    </>
  );
}

export default Loading;
