import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { Button, Drawer } from "@mui/material";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import GetUserPrefecture from "./GetUserPrefecture";
import { useStreamChatClient } from "../context/StreamChatContext";

const GroupChatSetting = ({ group, user }) => {
  const [channel, setChannel] = useState("");
  const chatClient = useStreamChatClient();

  // 初期処理
  useEffect(() => {
    const initChat = async () => {
      if (chatClient) {
        const channel = await chatClient.channel(
          "messaging",
          "groupChat-" + group?.id,
          {}
        );
        setChannel(channel);
        channel.watch();
      }
    };

    initChat();
  }, []);

  //   member取得
  const [channelMember, setChannelMember] = useState([]);
  useEffect(() => {
    const getChannelMembers = async () => {
      let sort = { created_at: 1 };
      let arr = [];
      const alreadyChannelMember = await channel.queryMembers({}, sort, {});
      alreadyChannelMember?.members?.forEach((member) => {
        arr.push(member);
      });
      setChannelMember(arr);
    };

    channel && getChannelMembers();
  }, [channel]);

  const [isMute, setIsMute] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [targetUser, setTargetUser] = useState();
  const handleClickOpenDialog = ({ user }) => {
    setOpenDialog(true);
    setTargetUser(user);
  };
  const handleDiaogClose = () => {
    setOpenDialog(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // ミュート
  // ミュート設定取得
  useEffect(() => {
    const init = async () => {
      await setIsMute(channel.muteStatus().muted);
    };
    init();
  }, []);

  const handleMute = async () => {
    setIsLoading(!isLoading);
    // console.log("chnannel =>")
    isMute ? await channel.unmute() : await channel.mute();
    await setIsMute(channel.muteStatus().muted);
    window.location.reload();
  };
  // muteモーダル
  const MuteComponent = () => {
    return (
      <>
        <Box sx={style}>
          <div className="rounded-md p-2 border text-center text-white bg-black ">
            チャンネルミュート
          </div>
          <div className="mt-2">
            このチャンネルのミュート設定を変更しますか？
          </div>
          <div className="flex mt-3">
            現在の設定：
            {isMute ? (
              <div className="text-red-500">オン</div>
            ) : (
              <div className="text-blue-500">オフ</div>
            )}
          </div>
          <div className="text-xs text-gray-500 mt-3">
            ミュート設定がオンになっていると、このチャンネルが更新されたときの通知が来なくなります
          </div>
          <div className="flex justify-around mt-2">
            <Button
              variant="outlined"
              onClick={handleMute}
              color={!isMute ? "error" : "primary"}
              disabled={isLoading}
            >
              {isMute ? "オフ" : "オン"}
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              disabled={isLoading}
            >
              キャンセル
            </Button>
          </div>
        </Box>
        ;
      </>
    );
  };

  // ユーザーがVillagerかどうか判定する関数
  const isVillager = async (userId) => {
    // console.log("userId =>", userId);
    try {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("docSnap.data().isVillager =>", docSnap.data().isVillager);
        return docSnap.data().isVillager === true;
      } else {
        console.log("No such document!");
        return false;
      }
    } catch (error) {
      console.error("Error getting document:", error);
      return false;
    }
  };

  // メンバーが村民かどうかあらかじめチェックしisMemberを追加し、
  // channelMemberWithVillagerStatusに格納する
  const [
    channelMemberWithVillagerStatus,
    setChannelMemberWithVillagerStatus,
  ] = useState();
  useEffect(() => {
    const fetchVillagerStatus = async () => {
      const memberStatuses = await Promise.all(
        channelMember.map(async (member) => {
          const isVillagerStatus = await isVillager(member.user.id);
          return { ...member, isVillager: isVillagerStatus };
        })
      );

      // ここで状態を更新
      setChannelMemberWithVillagerStatus(memberStatuses);
    };

    if (channelMember && channelMember.length > 0) {
      fetchVillagerStatus();
    }
  }, [channelMember]);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <MuteComponent />
      </Modal>

      <div className="flex flex-col space-y-2 my-2">
        <Button onClick={() => handleOpen()} variant="outlined">
          {isMute ? "ミュートをやめる" : "このチャンネルをミュート"}
        </Button>
      </div>

      <div className="font-bold text-lg">メンバー</div>
      <div>
        {channelMemberWithVillagerStatus && user?.isAdmin && (
          <>
            {channelMemberWithVillagerStatus?.map(
              (member) =>
                member?.isVillager && (
                  <>
                    <div className="flex justify-between space-x-1 items-center rounded-md border w-full my-1 py-2 px-4 shadow-md hover:cursor-pointer hover:shadow-xl duration-150 hover:bg-gray-50 group">
                      <div
                        className="flex  space-x-2 items-center"
                        onClick={() =>
                          window.open("/user/" + member?.user.id, "_blank")
                        }
                      >
                        <div className="flex flex-col justify-center items-center space-y-2 ">
                          {member?.user.online ? (
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              variant="dot"
                            >
                              <Avatar
                                alt={member?.user.name}
                                src={member?.user.image}
                              />
                            </StyledBadge>
                          ) : (
                            <Avatar src={member?.user.image} />
                          )}
                        </div>
                        <div className="">
                          <div className="flex space-x-1 items-center">
                            <div className="font-bold">{member?.user.name}</div>
                            <div className="text-xs text-gray-500">
                              @<GetUserPrefecture userId={member?.user.id} />
                            </div>
                          </div>
                          <div>
                            {new Date(member?.updated_at).toLocaleString()}
                          </div>
                        </div>
                        <div className="group-hover:block hidden duration-150">
                          <Button size="small" variant="outlined">
                            プロフィール
                          </Button>
                        </div>
                      </div>

                      {member?.role === "member" && <div>メンバー</div>}
                      {member?.role === "owner" && <div>オーナー</div>}
                    </div>
                  </>
                )
            )}
          </>
        )}
      </div>
    </>
  );
};

export default GroupChatSetting;
