import React, { useState } from "react";

import {
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { TextareaAutosize } from "@mui/material";

import { useEffect } from "react";
import { useParams } from "react-router-dom";

const AnswerProduct = ({ setOpen, requestId }) => {
  //   question取得
  const { id } = useParams();
  const [question, setQuestion] = useState([]);
  useEffect(() => {
    getDoc(doc(db, "products", id, "question", requestId)).then((doc) => {
      setQuestion(doc.data());
    });
  }, []);

  // 提供する形コンテナ
  const [product, setProduct] = useState({
    answer: "",
    error: "",
    loading: false,
  });
  const { answer, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    try {
      // 変更処理
      const docRef = await updateDoc(
        doc(db, "products", id, "question", requestId),
        {
          answer: answer,
          answerDate: serverTimestamp(),
        }
      );
      setProduct({
        answer: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">質問に回答する</div>
        <form onSubmit={setRequestDataShop}>
          {/* 回答 */}
          <div className="flex flex-col">
            <TextareaAutosize
              name="answer"
              aria-label="minimum height"
              minRows={3}
              maxRows={5}
              className="border mb-4 p-2"
              placeholder="回答"
              onChange={handleChange}
            ></TextareaAutosize>

            {/* 登録ボタン */}
            <button
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              disabled={loading}
              >
              {loading ? "送信しています..." : "回答する"}
            </button>
          </div>
        {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AnswerProduct;
