import React, { memo } from "react";

const MapTypeIcon = ({ icon, text, onClick, type }) => {
  // console.log("type =>", type);
  return (
    <div
      className={` ${
        type && " border-amber-700 "
      }" flex flex-col group space-y-2  items-center hover:cursor-pointer duration-200 w-max mx-2 border-b-2 border-[#faf9f7] hover:border-amber-700 "`}
      onClick={onClick}
    >
      <div
        className={` ${
          type && " text-gray-900 font-bold "
        } " flex justify-center w-6 h-6  text-gray-500 group-hover:text-gray-700 group-hover:font-bold duration-200"`}
      >
        {icon}
      </div>
      <div
        className={` ${
          type && " text-gray-900 font-bold "
        } " flex justify-center text-gray-500 group-hover:text-gray-700 group-hover:font-bold duration-200 text-sm  w-max"`}
      >
        {text}
      </div>
    </div>
  );
};

export default memo(MapTypeIcon);
