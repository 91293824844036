import React, { useState, useEffect } from "react";
import { doc, serverTimestamp, updateDoc, getDoc,addDoc,collection } from "firebase/firestore";
import { auth, db } from "../api/firebase";
// redux - use
import { useNotification } from "../redux/useNotification";
import { useDocumentData } from "react-firebase-hooks/firestore";

// 買われた人が買った人に配送連絡
// 買われた人が auth.currentUser.uid buyer
// 買った人が deal.buy
const DealShipped = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // 取引を取得
  const [deal, setDeal] = useState();
  useEffect(() => {
    const initGetDeal = async () => {
      getDoc(doc(db, "deal", requestId)).then((doc) => {
        setDeal(doc.data());
      });
    };
    requestId && initGetDeal();
  }, []);

  // 買った人を取得
  const [buy, setBuy] = useState();
  useEffect(() => {
    const initGetBuyUser = async () => {
      getDoc(doc(db, "users", deal?.buy)).then((doc) => {
        setBuy(doc.data());
      });
    };
    deal && initGetBuyUser();
  }, [deal]);

  // 買われた人を取得 - 自分
  const [buyer] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const setRequestDataShop = () => {};
  const [requestShopLoading, setRequestShopLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = async (e) => {
    e.preventDefault();
    setRequestShopLoading(true);
    try {
      //   await deleteDoc(doc(db, "requestShop", requestId));
      await updateDoc(doc(db, "deal", requestId), {
        status: "shipping",
        lastUpdated: buyer?.uid,
        lastUpdatedAt:serverTimestamp(),

      });

      // 通知情報登録 - 買われた人が配送連絡・・・なので
      // 買った人に「買われた人が送りましたよ！」って通知する
      // 買った人 deal.buy 買われた人 auth.currentUser.uid
      // fromは買われた人
      await addDoc(collection(db, "users",deal?.buy, "notification"), {
        createdAt: serverTimestamp(),
        content: "DDP配送完了",
        fromAvatar: buyer?.avatar,
        fromId: buyer?.uid,
        fromName: buyer?.lastName + buyer?.firstName,
        isRecognition: false,
        target: "deal",
        targetId: requestId,
      });



      setOpen(false);
      displayNotification({
        message: "配送連絡完了しました。",
        type: "success",
      });
    } catch (err) {
      setRequestShopLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        配送連絡
      </div>
      <form onSubmit={setRequestDataShop}>
        <div className="font-bold text-2xl">
          配送完了を交換希望者に連絡しますか？
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={requestShopLoading}
          onClick={handleDelete}
        >
          {requestShopLoading ? "送信しています..." : "はい"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DealShipped;
