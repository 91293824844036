import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { db, auth } from "../api/firebase";
import DisplayProduct from "./DisplayProducts";
import TablePagination from "@mui/material/TablePagination";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

const ProductsList = ({ searchList, searchVal }) => {
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [originalProduct, setOriginalProduct] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    // クエリ
    if (user) {
      const queryPublishProducts = query(
        collection(db, "products"),
        where("isPublish", "==", true),
        where("uid", "!=", auth.currentUser.uid)
      );
      // 取得して、productsにセット
      const getProducts = async () => {
        const arr = [];
        const productsDocs = await getDocs(queryPublishProducts);
        productsDocs.docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setProducts(arr);
        setOriginalProduct(arr);
        setDataLoading(false);
      };
      getProducts();
    } else {
      const queryPublishProducts = query(
        collection(db, "products"),
        where("isPublish", "==", true)
      );
      // 取得して、productsにセット
      const getProducts = async () => {
        const arr = [];
        const productsDocs = await getDocs(queryPublishProducts);
        productsDocs.docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setProducts(arr);
        setOriginalProduct(arr);
        setDataLoading(false);
      };
      getProducts();
    }
  }, [dataLoading]);


  // useEffect(() => {
  //   if (!originalProduct.length) {
  //     navigate("/ddp");
  //   }
  // });

  // サーチ
  useEffect(() => {
    if (searchVal) {
      // console.log("呼ばれた", searchVal);
      // console.log("originalProduct =>", originalProduct);
      const filteredRows = originalProduct.filter((row) => {
        return (
          row.title +
          row.offering +
          row.explain +
          row.name +
          row.runru
        ).includes(searchVal);
      });
      setProducts(filteredRows);
      // window.location.reload()
    } else {
      setProducts(originalProduct);
    }
  }, [searchVal]);

  // useEffect(() => {
  //   if (searchList.length) {
  //     const filteredRows = originalProduct.filter((row) => {
  //       return searchList.includes(row.offering);
  //     });
  //     setProducts(filteredRows);
  //   } else {
  //     setProducts(originalProduct);
  //   }
  // }, [searchList]);

  // console.log(searchList)
  
  // pagenation
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // take1 => 機能せず
  // window.onpageshow = function(event) {
  //  if (event.persisted) {
  //   console.log("戻るボタンで戻ってきた")
  //   // なんらかの処理
  //  }
  // };

  // take2 =>おそすぎて使えない！
  // window.addEventListener('popstate', function(e) {
  //   navigate("/ddp")
  //   window.location.reload()
  //   // alert('ブラウザバックを検知しました。');
  // });
  // console.log(window.PopStateEvent)

  return (
    <>
      {/* 戻るボタンで戻られると、originalProductがなくて、検索がうまくできない */}
      {/* ので、戻るボタンを押した場合はddp広場へ矯正遷移 */}
      {searchVal && <div>"{searchVal}"の検索結果</div>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4   gap-2  my-1">
        {products?.size === 0 && <div>登録されたDDPはまだありません</div>}
        {(rowsPerPage > 0
          ? products?.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : products
        )?.map((product, index) => {
          return (
            <DisplayProduct product={product} key={index} id={product.id} />
          );
        })}
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 100]}
        component="div"
        count={products.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        showFirstButton
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ProductsList;
