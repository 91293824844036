import { v4 as uuidv4 } from 'uuid';

const getBalanceEx = (user, runru, deals) => {
  const currentDate = new Date();

  runru.sort((a, b) => {
    const dateA = new Date(a.createdAt.seconds * 1000 + a.createdAt.nanoseconds / 1e6);
    const dateB = new Date(b.createdAt.seconds * 1000 + b.createdAt.nanoseconds / 1e6);
    return dateA - dateB;
  });

  let runruCollection = [];
  let totalAvailableRunru = 0;
  const transactions = [];

  let previousBalance = 0;

  // Runruの処理
  runru.forEach(transaction => {
    const createdAtDate = new Date(transaction.createdAt.seconds * 1000 + transaction.createdAt.nanoseconds / 1e6);
    const expirationDate = new Date(createdAtDate);
    expirationDate.setDate(createdAtDate.getDate() + 90);

    // ルンル失効の処理
    runruCollection.forEach(runru => {
      if (runru.expirationDate <= createdAtDate && !runru.expired) {
        runru.expired = true;
        totalAvailableRunru -= runru.amount;

        transactions.push({
          id: uuidv4(),
          type: `ルンル失効（受領ID: ${runru.id}）`,
          amount: -runru.amount,
          date: runru.expirationDate.toISOString(),
          to: user.uid,
          from: '', // Leave empty
          cause: `ルンル失効 (受領ID: ${runru.id})`,
          balance: totalAvailableRunru,
          previousBalance: previousBalance,
          source: 'runru',
          systemGenerated: true,
        });

        previousBalance = totalAvailableRunru;
      }
    });

    // ルンル受領の処理
    if (transaction.to === user.uid) {
      const newTransaction = {
        id: transaction.id,
        amount: transaction.amount,
        expirationDate,
        createdAt: createdAtDate,
        to: transaction.to,
        from: transaction.from,
        cause: transaction.cause,
        expired: false,
        used: false,
      };

      runruCollection.push(newTransaction);
      totalAvailableRunru += transaction.amount;

      let type = 'ルンル移動';
      if (transaction.cause === '村民さん全体付与') {
        type = 'ルンル受領（村民さん全体付与）';
      } else if (transaction.cause === 'DDP交換') {
        type = 'ルンル受領（DDP交換）';
      }

      transactions.push({
        id: newTransaction.id,
        type: type,
        amount: `+${transaction.amount}`,
        date: createdAtDate.toISOString(),
        to: transaction.to,
        from: transaction.from,
        cause: transaction.cause,
        balance: totalAvailableRunru,
        previousBalance: previousBalance,
        source: 'runru',
        systemGenerated: false,
      });

      previousBalance = totalAvailableRunru;
    } else if (transaction.from === user.uid) {
      let remainingAmount = transaction.amount;

      runruCollection = runruCollection.map(runru => {
        if (remainingAmount > 0 && runru.expirationDate > createdAtDate) {
          const deduction = Math.min(runru.amount, remainingAmount);
          runru.amount -= deduction;
          remainingAmount -= deduction;
          totalAvailableRunru -= deduction;

          let type = 'ルンル使用';
          let party = transaction.to;

          if (transaction.cause === 'DDP交換') {
            type = 'ルンル使用（DDP交換）';
            party = transaction.to;
          }

          transactions.push({
            id: runru.id,
            type: type,
            amount: `-${deduction}`,
            date: createdAtDate.toISOString(),
            to: transaction.to,
            from: user.uid,
            cause: transaction.cause,
            balance: totalAvailableRunru,
            previousBalance: previousBalance,
            source: 'runru',
            systemGenerated: false,
            party: party,
          });

          previousBalance = totalAvailableRunru;

          if (runru.amount === 0) {
            runru.used = true;
          }
        }
        return runru;
      });
    }
  });

  // Dealsの処理 - statusがrequestingで90日以内のものでbuyが自分の場合のみ処理
  deals.forEach(deal => {
    if (deal.status === 'requesting' && deal.buy === user.uid) {
      const dealDate = new Date(deal.createdAt.seconds * 1000 + deal.createdAt.nanoseconds / 1e6);
      const expirationDate = new Date(dealDate);
      expirationDate.setDate(dealDate.getDate() + 90);

      if (expirationDate > currentDate) {
        transactions.push({
          id: uuidv4(),
          type: 'DDP交換（保留：交換希望中）',
          amount: `-${deal.amount}`,
          date: dealDate.toISOString(),
          to: deal.to,
          from: deal.buyer,
          product: deal.productName,
          cause: deal.cause,
          balance: totalAvailableRunru - deal.amount,
          previousBalance: totalAvailableRunru,
          trade: deal.ddpExchangeId,
          source: 'deal',
          systemGenerated: false,
        });

        totalAvailableRunru -= deal.amount;
        previousBalance = totalAvailableRunru;
      }
    }
  });

  transactions.sort((a, b) => new Date(a.date) - new Date(b.date));

  // 最終的な残高を計算 - 最後のトランザクションの残高
  const balance = transactions.length > 0 ? transactions[transactions.length - 1].balance : 0;

  return {
    balance,
    detailedEntries: transactions.map((transaction, index) => ({
      no: index + 1,
      id: transaction.id,
      type: transaction.type,
      date: transaction.date,
      amount: transaction.amount,
      product: transaction.product || '',
      cause: transaction.cause,
      party: transaction.type.includes('ルンル使用（DDP交換）') ? transaction.to : transaction.cause === 'ルンル失効' ? '' : transaction.from,
      balance: transaction.balance,
      previousBalance: transaction.previousBalance,
      trade: transaction.trade || '',
      source: transaction.source,
      systemGenerated: transaction.systemGenerated,
    })),
  };
};

export default getBalanceEx;
