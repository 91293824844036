// Firebaseの初期化とメッセージングのインポートを行います。
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  
} from "firebase/firestore";
import { auth, db, messaging } from "../api/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { updateDeviceToken } from "../utils/updateDeciceToken";

// console.log("##=>",process.env.REACT_APP_VAPI_KEY)
// console.log("firebaseConfig =>",firebaseConfig)
// console.log("messaging =>",messaging)

// 通知を許可しますか？を出力する関数
// export const requestNotificationPermission = () => {
//   Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       console.log("Notification permission granted.");
//       // ここでユーザーに通知を送信するなど、追加のアクションを実行できます。
//     } else {
//       console.log("Notification permission denied.");
//       // 許可が得られなかった場合の処理をここに記述します。
//     }
//   });
// };



// トークンをリクエストする関数です。
export const requestForToken = () => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, now request the token
      getToken(messaging, {
        vapidKey:
          "BOB-VUkcaxAkF96vnq-lVppGF4m_6VA0h3OqqxgTN2s8RR0bLC1GYyomWsL-XvjSfP86om-Q1x4A3xX5JpZ5rqk",
      })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("current token for client: ", currentToken);

            // setDoc(doc(db, "notificationCM", currentToken), {
            //   token: currentToken,
            //   userId: user.uid,
            //   createdAt: serverTimestamp(),
            //   email: user.email,
            //   displayName: user.displayName,
            // });

            // return currentToken;

            updateDeviceToken(user.uid,currentToken)

          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      // User is signed out
      console.log("User is not authenticated. Can't fetch token.");
    }
  });
};
// メッセージを受信したときのリスナーです。
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      resolve(payload);
    });
  });
