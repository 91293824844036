import React, { useEffect, useState } from "react";
import {
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { StreamChat } from "stream-chat";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

// redux - use
import { useNotification } from "../redux/useNotification";

const PermissionJoinGroup = ({ setOpen, requestId, target }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // ユーザ取得関連
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // リクエスト取得
  useEffect(() => {
    if (requestId) {
      getDoc(doc(db, "group", target)).then((doSnap) => {
        if (doSnap.exists) {
          setGroup(doSnap.data());
        }
      });
    }
  }, [requestId]);
  const { id } = useParams();
  // リクエストユーザ取得
  const [requestUser] = useDocumentData(doc(db, "users", requestId));

  const [adminUser] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // console.log("requestUser =>",requestUser)
  // console.log("adminUser =>",adminUser)
  //   更新処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // 更新
      await updateDoc(doc(db, "group", target, "members", requestId), {
        isMember: true,
        isApplication: false,
      });

      // chatのメンバーにも加える
      const userToConnect = {
        id: adminUser.uid,
        name: adminUser.lastName + adminUser.firstName,
        image: adminUser.avatar,
        langage: "jp",
      };
      const apiKey = process.env.REACT_APP_STREAM_API_KEY;
      const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
      // //   トークン取得
      const token = await fetch(
        process.env.REACT_APP_STREAM_GET_TOKEN_URL,
        // "https://us-central1-sekaimura-test.cloudfunctions.net/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: adminUser.uid,
          }),
        }
      ).then((r) => r.json());
      await client.connectUser(userToConnect, token);
      const channel = client.channel("messaging", "groupChat-" + id);
      await channel.addMembers([requestUser.uid], {
        text:
          requestUser?.lastName +
          requestUser?.firstName +
          "さんがグループに参加しました",
      });

      // グループのupdates作成
      await addDoc(collection(db, "group", target, "updates"), {
        createdAt: serverTimestamp(),
        title: "グループ参加",
        information:
          requestUser?.lastName +
          requestUser?.firstName +
          "さんがグループに参加しました。",
        creator: requestUser?.uid,
        creatorAvatar: requestUser?.avatar,
        authorizer: auth.currentUser.uid,
      });

      // 通知
      await addDoc(collection(db, "users", requestUser?.uid, "notification"), {
        createdAt: serverTimestamp(),
        content: "グループ参加許可",
        fromAvatar: adminUser?.avatar,
        fromId: adminUser?.uid,
        fromName: adminUser?.lastName + adminUser?.firstName,
        isRecognition: false,
        target: "group",
        targetId: target,
      });

      setOpen(false);
      displayNotification({
        message:
          requestUser?.lastName +
          requestUser?.firstName +
          "さんがグループに参加しました。",
        type: "success",
      });

      window.location.reload();
    } catch (err) {
      setLoading(false);
      setError(err);
      console.log("error =>",err)
      const errorData = {
        message: err.message,
        stack: err.stack,
        // カスタムエラーオブジェクトのプロパティを追加することができます
      };
      await addDoc(collection(db, "error"), {
        
        error: errorData,
        createdAt: serverTimestamp(),
        type: "permissionJoinGroup",
        requestUser,
        adminUser,
      });
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        申請許可
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="font-bold text-2xl">参加申請を許可しますか？</div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "許可処理中..." : "許可する"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default PermissionJoinGroup;
