import React, { useState } from "react";
import { doc, deleteDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Avatar } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
const DeleteComment = ({ setOpen, requestId, target }) => {
  const [data, setData] = useState({
    error: "",
    loading: false,
  });
  const { error, loading } = data;

  const [deleteOK, setDeleteOK] = useState(false);
  const handleCheckBoxChange = () => {
    setDeleteOK(!deleteOK);
  };

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  //   削除処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setData({ ...data, loading: true });
    try {
      // コメント削除
      await updateDoc(doc(db, "comment", target.id), {
        isPublish: false,
        updatedAt: serverTimestamp(),
        updator: user,
        updateReason: "commentDelete",
      });
      // コメントに付随する返信も削除
      //   コメントのデータ（写真や動画など）も削除
      // コメントの返信のデータ（写真や動画など）も削除
      setOpen(false);
      setData({ ...data, loading: false });

      window.location.reload();
    } catch (err) {}
  };

  if (!target) {
    return <div>この投稿は表示できません。</div>;
  }

  return (
    <>
      <div className="">コメント削除</div>
      <form onSubmit={handleDelete}>
        <div className="flex flex-col space-y-2 ">
          {/* 内容 */}
          <div className="flex space-x-2 my-3">
            <div>
              <Avatar
                src={target.avatar}
                sx={{ width: "24px", height: "24px" }}
              />
            </div>
            <div className="rounded-md w-full whitespace-pre-wrap break-words line-clamp-3 bg-gray-100 text-gray-500 p-2">
              {target.message}
            </div>
          </div>
          {/* 注意書き */}
          <div className=" mb-3 flex flex-col">
            <div className="text-xs text-red-600">
              ・コメント削除処理を実行すると、元に戻せません。
            </div>
            {/* <div className="text-xs text-red-600">
              ・コメントへの返信も削除され、元に戻せません。
            </div>
            <div className="text-xs text-red-600">
              ・コメントや、コメントに付随していたデータ（写真・動画など）も削除され、もとに戻せません。
            </div> */}
          </div>
          <div>
            <FormControlLabel
              required
              control={<Checkbox />}
              label="上記内容を読み理解して削除します。"
              onChange={handleCheckBoxChange}
              checked={deleteOK}
            />
          </div>
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className={` ${
              deleteOK ? "bg-blue-500 " : " bg-gray-300 "
            }  " inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md w-fit hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out "`}
            onClick={handleDelete}
            disabled={!deleteOK}
          >
            {loading ? "削除しています..." : "削除"}
          </button>
        </div>
      </form>
    </>
  );
};

export default DeleteComment;
