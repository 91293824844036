import React, { useState, useEffect } from "react";
import { getDocs, collection, doc, query, where } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db, auth } from "../api/firebase";
import GetUserInfo from "./GetUserInfo";
import Rate from "./Rate";
import TablePagination from "@mui/material/TablePagination";
import { memo } from "react";

import ModalForm from "./ModalForm";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";

const ProductCusutomerReviews = memo(() => {
  const { id,user } = useParams();

  // レビュー取得 評価:Evalution
  const [reviews, setReviews] = useState([]);

  const q = query(
    collection(db, "products", id, "review"),
    where("display", "!=", false)
  );

  useEffect(() => {
    let arr = [];
    getDocs(q).then((docs) => {
      docs?.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setReviews(arr);
    });
  }, []);
  // rate取得
  // const [average, setAverage] = useState();
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   const getRate = () => {
  //     const count = reviews?.length;
  //     let total = 0;
  //     reviews?.forEach((doc) => {
  //       total += doc?.rate;
  //     });
  //     setAverage(total / count);
  //     setLoading(false);
  //   };
  //   getRate();
  // }, [reviews]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   消去で呼び出すModalのプロパティたち
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const deleteEvalute = (e, doc) => {
    // console.log(e);
    // console.log(id);
    // console.log(target);
    setOpen(true);
    setForm("deleteReview");
    setRequestId(doc.id);
  };

  // const [user] = useDocumentDa ta(doc(db, "users", auth.currentUser.uid));

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />

      {reviews.length ? (
        (rowsPerPage > 0
          ? reviews?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : reviews
        )?.map((doc) => (
          <div
            key={Math.random()}
            className="flex flex-col border rounded-md p-2 my-1 space-y-2"
          >
            <div className="flex space-x-2 items-center">
              <GetUserInfo uid={doc.reviewer} target="avatar" size={36} />
              <GetUserInfo uid={doc.reviewer} target="name" />
            </div>
            <Rate rate={doc.rate} />
            <div>{doc.review}</div>
            {/* {(doc.valuer === auth.currentUser.uid || user?.isAdmin) && (
              <div
                className=" text-xs text-blue-400"
                onClick={(e) => deleteEvalute(e, doc)}
              >
                削除する
              </div>
            )} */}
          </div>
        ))
      ) : (
        <div>評価はまだありません。</div>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 100]}
        component="div"
        count={reviews?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        showFirstButton
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
});

export default ProductCusutomerReviews;
