import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App.js";
import "tw-elements";

// redux
import { store } from "./redux/store.js";
import { Provider } from "react-redux";
import { Notification } from "./components/Notification.js";
import { SnackBar } from "./components/SnackBar.js";

// fcm
import { StreamChatProvider } from "./context/StreamChatContext.js";
// import NotificationFMC from "./components/NotificationFMC.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <StreamChatProvider>
      <App />
    </StreamChatProvider>
    <Notification />
    <SnackBar />
  </Provider>
  // </React.StrictMode>
);
