import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import { doc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../api/firebase";

import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";

// redux - use
import { useNotification } from "../redux/useNotification";

const AddProduct = ({ setOpen }) => {
  const targetRef = useRef("");

  // redux - Notification
  const { displayNotification } = useNotification();

  // ユーザ取得関連
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  const [ddpTypeCollection] = useCollection(collection(db, "ddp"));
  const offers = ddpTypeCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const targetList = [];
  offers?.forEach((offer) => {
    const list = [];
    offer?.targets.forEach((target) => {
      list.push(target);
    });
    targetList[offer.offering] = list;
  });

  // console.log(targetList);

  // お店掲載申請コンテナ
  const [product, setProduct] = useState({
    title: "",
    offering: "",
    target: "",
    delivery: "",
    runru: "",
    explain: "",
    error: "",
    loading: false,
  });
  const {
    title,
    runru,
    offering,
    target,
    delivery,
    explain,
    error,
    loading,
  } = product;

  useEffect(() => {
    targetRef.current.value = "";
    setProduct({ ...product, target: "" });
  }, [offering]);

  // 値変更
  const handleChange = (e) => {
    if (e.target.name === "offering") {
      setProduct({ ...product, target: "" });
    }
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  // console.table(product);
  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    try {
      // 変更処理
      const docRef = await addDoc(collection(db, "products"), {
        title,
        runru,
        offering,
        target,
        delivery,
        explain,
        isPublish: true,
        isActive: true,
        uid: user.uid,
        createdAt: serverTimestamp(),
        name: user.lastName + user.firstName,
        avatar: user.avatar,
      });

      displayNotification({
        message: "DDP登録完了しました。",
        type: "success",
      });

      await addDoc(collection(db, "users", user.uid, "notification"), {
        createdAt: serverTimestamp(),
        content: "DDP登録完了",
        fromAvatar: user?.avatar,
        fromId: user?.uid,
        fromName: user?.lastName + user?.firstName,
        isRecognition: false,
        target: "product",
        targetId: docRef.id,
      });

      setProduct({
        title: "",
        runru: "",
        offering: "",
        target: "",
        delivery: "",
        explain: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  // console.log(product)
  return (
    <>
      <div>
        <div className="mb-3">DDP登録</div>
        <form onSubmit={setRequestDataShop}>
          {/* 名 */}
          <TextField
            id="outlined-required"
            name="title"
            label="DDP名"
            defaultValue=""
            size="small"
            placeholder="ex) 歴史、話します。 はちみつパン etc..."
            className=" w-full"
            onChange={handleChange}
            required
            disabled={loading}
          />

          <div className="my-4"></div>
          <TextField
            id="outlined-required"
            name="offering"
            label="タイプ"
            defaultValue=""
            size="small"
            className=" w-full"
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            {offers?.map((offer) => (
              <MenuItem value={offer.offering} key={Math.random()} dense>
                {offer.offering}
              </MenuItem>
            ))}
          </TextField>

          <div className="my-4"></div>
          <TextField
            id="outlined-required"
            name="target"
            ref={targetRef}
            label="対象"
            defaultValue=""
            size="small"
            className=" w-full"
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            {targetList[offering]?.map((offer) => (
              <MenuItem value={offer} key={Math.random()} dense>
                {offer}
              </MenuItem>
            ))}
          </TextField>

          {/* 価格（ルンル） */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="runru"
              type="number"
              label="価格(ルンル)"
              defaultValue=""
              placeholder="100"
              size="small"
              inputProps={{
                min: 10,
                max: 999999,
              }}
              onChange={handleChange}
              required
              className="w-full my-4"
              disabled={loading}
            ></TextField>
          </div>

          <div className="my-4"></div>
          <TextField
            id="outlined-required"
            name="delivery"
            label="受け渡し方法"
            defaultValue=""
            size="small"
            className=" w-full"
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            <MenuItem value="配送(着払い)" key={Math.random()} dense>
              配送(着払い)
            </MenuItem>
            <MenuItem value="配送(元払い)" key={Math.random()} dense>
              配送(元払い)
            </MenuItem>
            <MenuItem value="オンラインを介して提供" key={Math.random()} dense>
              オンラインを介して提供
            </MenuItem>
            <MenuItem value="その他" key={Math.random()} dense>
              その他
            </MenuItem>
          </TextField>

          {/* DDP説明 */}
          <div className="my-4"></div>
          <textarea
            style={{ resize: "none", fontSize: "16px" }}
            type="text"
            name="explain"
            className="textarea textarea-bordered w-full h-24  "
            placeholder="DDP説明　例）教科書には載らない面白い歴史の話、お話します＾＾"
            defaultValue=""
            onChange={handleChange}
          ></textarea>
          {/* 注意書き */}
          <div className="my-4 text-sm text-gray-600">
            ※一度登録したDDPの内容は変更できません。
            <br />
            変更したい場合は新たにDDPを作成し直す必要があります。
          </div>

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
          >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AddProduct;
