import React, { useState, useRef, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/auth";

import {
  getDoc,
  getDocs,
  collection,
  addDoc,
  serverTimestamp,
  doc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";

// MaterialUI
import { Avatar, Button, TextareaAutosize, TextField } from "@mui/material";

import DisplaySignUp from "./DisplaySignUp";
import GetUserInfo from "./GetUserInfo";
import addError from "../components/addError";

// redux - use
import { useNotification } from "../redux/useNotification";
import RegisterVillager from "./RegisterVillager";
import { useDocumentData } from "react-firebase-hooks/firestore";

const ProductQuestion = () => {
  const { id } = useParams();
  // redux - Notification
  const { displayNotification } = useNotification();

  const { user } = useAuth();

  // ユーザ情報取得
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initUser = () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) =>
        setUserInfo(doc.data())
      );
    };
    user && initUser();
  }, [user]);

  // 質問関連
  const [question, setQuestion] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  const questionRef = useRef();

  const [product, setProduct] = useState("");
  useEffect(() => {
    getDoc(doc(db, "products", id)).then((doc) => {
      setProduct({ id: doc.id, ...doc.data() });
    });
  }, []);

  const handleSetQuestion = (e) => {
    setQuestion(e.target.value);
  };

  // console.log("product =>", product);
  const [buyer, setBuyer] = useState("");
  useEffect(() => {
    const initSetBuyer = () => {
      getDoc(doc(db, "users", product?.uid)).then((doc) => {
        setBuyer({ id: doc.id, ...doc.data() });
      });
    };
    product?.uid && initSetBuyer();
  }, [product]);

  const handleQuestion = async (e) => {
    e.preventDefault();
    const func = handleQuestion;
    setQuestionLoading(true);

    // 質問欄が空白
    if (!questionRef.current.value) {
      displayNotification({
        message: "質問内容が空白です",
        type: "error",
      });

      setQuestionLoading(false);
      return;
    }

    // 自分のDDP
    if (product?.uid === auth.currentUser.uid) {
      displayNotification({
        message: "自分のDDPに質問操作はできません",
        type: "error",
      });

      setQuestionLoading(false);
      questionRef.current.value = "";
      return;
    }

    try {
      await addDoc(collection(db, "products", id, "question"), {
        createdAt: serverTimestamp(),
        question,
        answer: "",
        questioner: auth.currentUser.uid,
        answerDate: "",
        buyer: product?.uid,
      });
      setQuestionLoading(false);
      setQuestion("");
      questionRef.current.value = "";

      await addDoc(collection(db, "users", buyer?.id, "notification"), {
        createdAt: serverTimestamp(),
        content: "DDPに質問",
        fromAvatar: userInfo?.avatar,
        fromId: userInfo?.uid,
        fromName: userInfo?.lastName + userInfo?.firstName,
        isRecognition: false,
        target: "product",
        targetId: id,
      });

      displayNotification({
        message: "質問を送信しました。",
        type: "success",
      });
    } catch (error) {
      addError(error, user, func);
      setQuestionLoading(false);
      displayNotification({
        message: "失敗しました。エラーは管理者に送信されました",
        type: "error",
      });
    }
  };

  if (!user)
    return (
      <>
        <div className="flex items-center">
          <DisplaySignUp />
          して質問する
        </div>
      </>
    );

  if (!userInfo?.isVillager)
    return (
      <>
        <div>質問は・・・</div>
        <RegisterVillager />
      </>
    );
  return (
    <>
      <div className="flex flex-col">
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          maxRows={5}
          className="border mb-4 p-2"
          placeholder="質問"
          onChange={handleSetQuestion}
          ref={questionRef}
        ></TextareaAutosize>
        <Button variant="contained" className="w-32" onClick={handleQuestion}>
          {questionLoading ? "送信しています" : "送信する"}
        </Button>
      </div>
    </>
  );
};

export default memo(ProductQuestion);
