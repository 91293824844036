import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import DisplayNeighbors from "./DisplayNeighbors";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const DrawerListItem = ({ text, icon, open, onClick,setOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAccordionToggle = () => {
    if (text === "近隣の村民さん") {
      // console.log("setOpen =>",setOpen)

      // console.log("open =>",open)
      if (!open) {
        setOpen(true);  // open が false の場合、true に設定
        setIsOpen(true); // isOpen も true に設定
      } else {
        setIsOpen(!isOpen);
      }
    } else {
      onClick();
    }
  };


  return (
    <>
      <ListItem disablePadding sx={{ display: "block" }} onClick={handleAccordionToggle}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
          {/* ドロワーが開いている時のみ矢印アイコンを表示 */}
          {text === "近隣の村民さん" && open && (isOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>

      </ListItem>
      {text === "近隣の村民さん" && (
        <Collapse in={isOpen && open} timeout={!isOpen || !open ? 0 : 'auto'} unmountOnExit>
          <div className="px-2">
            <DisplayNeighbors />
          </div>
        </Collapse>
      )}
    </>
  );
};

export default DrawerListItem;
