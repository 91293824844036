import React, { useEffect } from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
import HowToRegisterDDP from "./HowToRegisterDDP";
import AboutDDP from "./AboutDDP";

const Manuals = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RegisterDDP = () => {
    return (
      <>
        <div className="font-bold text-xl">DDP登録</div>
        <HowToRegisterDDP />
      </>
    );
  };

  return (
    <>
      <div className="font-bold text-xl">使い方マニュアル</div>

      {/* lg以上表示 */}
      <div className="hidden lg:block">
        <div className="flex w-full">
          <TabContext value={value}>
            {/* 左リスト */}
            <Box sx={{ width: "10%" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                orientation="vertical"
              >
                <Tab label="DDP登録" value="1" />
                <Tab label="DDPとは？" value="2" />
              </TabList>
            </Box>
            {/* DDP登録 */}
            <TabPanel sx={{ width: "100%" }} value="1">
              <RegisterDDP />
            </TabPanel>
            <TabPanel sx={{ width: "100%" }} value="2">
              <AboutDDP />
            </TabPanel>
          </TabContext>
        </div>
      </div>

      {/* lg以下表示 */}
      <div className="xl:px-28 lg:hidden">
        <RegisterDDP />
        <AboutDDP />
      </div>
    </>
  );
};

export default Manuals;
