import React from "react";
import { useAuth } from "../context/auth";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import Img from "../data/img/defaultImage.png";
import { Avatar } from "@mui/material";
import { collection, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import Rate from "./Rate";
import DisplayProductUnder640 from "./DisplayProductUnder640";
import ProductRate from "./ProductRate";

const MyProduct = ({ product, id }) => {
  const { user } = useAuth();
  // 自分で上げた画像
  const queryMyProductImages = query(
    collection(db, `products/${id}/images`)
    // where("uid", "==", auth.currentUser.uid)
  );
  const [images] = useCollection(queryMyProductImages);
  const myProductImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  // console.log(myProductImages)

  const navigate = useNavigate();
  return (
    <>
      {/*　sm(640)以下表示 */}
      <DisplayProductUnder640
        product={product}
        myProductImages={myProductImages}
        id={id}
      />

      {/* sm(640)以上表示 */}
      <div className="hidden sm:block">
        <div
          className={`${
            product.isPublish && product.isActive ? "" : " bg-gray-300"
          }   flex-col h-96   border rounded-md cursor-pointer hover:bg-gray-50 hover:shadow-lg `}
          aria-disabled={product.isPublish && product.isActive}
        >
          {/* 停止stop or 活動中active */}
          {product.isPublish && product.isActive ? (
            ""
          ) : (
            <div className="p-1 text-red-500">停止中</div>
          )}

          {/* 画像 */}
          <div className="flex relative">
            {!myProductImages?.length && (
              <img
                src={Img}
                alt=""
                object-fit="cover"
                className="w-full h-56 rounded-t-md"
              />
            )}
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              className="w-full"
            >
              {myProductImages?.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image.imageURL}
                      alt=""
                      object-fit="cover"
                      className="w-full h-56 rounded-t-md"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>

          <div
            className="flex flex-col  justify-between hover:cursor-pointer p-2 space-y-1"
            onClick={() => navigate(`/product/${id}`)}
          >
            {/* タイトルと名前 */}
            <div className="flex flex-col ">
              {/* タイトル */}
              <div className="font-bold truncate">{product.title}</div>
              <div className="flex items-center space-x-1  ">
                {/* 名前とアバター */}
                <div>
                  <Avatar src={product.avatar} sx={{ width: 24, height: 24 }} />
                </div>
                <div className="text-xs font-sans font-bold">
                  {product.name}
                </div>
              </div>

            </div>

            {/* 説明 */}
            <div className=" text-gray-400 text-xs truncate whitespace-pre-wrap h-3/5">
              {product.explain}
            </div>

            {/* レートと価格 */}
            <div className="flex justify-between items-center h-1/5">
              <div>
                <ProductRate productId={product.id} />
                {/* <Rate rate={2.5} /> */}
              </div>
              <div className="flex text-blue-400 font-bold text-md items-center space-x-1">
                <div>{product.runru}</div>
                <div className="text-xs">ルンル</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProduct;
