import { doc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { auth, db } from "../api/firebase";

import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "stream-chat-react";
import ModalForm from "./ModalForm";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useAuth } from "../context/auth";

import { getDoc } from "firebase/firestore";

const EventTime = ({ id }) => {
  const { user } = useAuth();

  // ModalForm用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleModalOpen = (e) => {
    setOpen(true);
    setForm(e);
  };
  const [eventDoc] = useDocumentData(doc(db, "event", id));

  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const initSetAuthor = async () => {
      if (eventDoc?.author === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    user && initSetAuthor();
  }, [eventDoc]);

  // ユーザ情報
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initGetUser = async () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && initGetUser();
  }, []);

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} requestId={id} />

      <div className="flex items-center text-gray-400 align-middle pl-20 mb-4">
        {/* {(userInfo?.isAdmin || isAuthor) && (
          <div
            className="mb-4"
            onClick={() => handleModalOpen("editEventTime")}
          >
            <Tooltip title="クリックで編集">
              <div className="hover:shadow-xl hover:cursor-pointer hover:bg-gray-200 rounded-full p-2 w-10 h-10">
                <EditIcon />
              </div>
            </Tooltip>
          </div>
        )} */}
        <AccessTimeIcon />
        {new Date(eventDoc?.publishDate.toDate()).toLocaleDateString()}{" "}
        {new Date(eventDoc?.publishDate.toDate()).getHours()}:
        {Number(new Date(eventDoc?.publishDate.toDate()).getMinutes()) < 9
          ? "0" + new Date(eventDoc?.publishDate.toDate()).getMinutes()
          : new Date(eventDoc?.publishDate.toDate()).getMinutes()}
        {" ～"}
      </div>
    </>
  );
};

export default EventTime;
