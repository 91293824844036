import React, { useEffect, useState } from "react";
import Img from "../data/img/defaultImage.png";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { Carousel } from "react-responsive-carousel";
import Rate from "./Rate";
import { Avatar, Tooltip } from "@mui/material";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";

import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

const DisplayDDP = ({ ddp }) => {
  // image取得
  const [images, setImages] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      await getDocs(collection(db, "products", ddp.id, "images")).then((docs) =>
        docs.forEach((elm) => arr.push({ id: elm.id, ...elm.data() }))
      );
      setImages(arr);
    };
    ddp && init();
  }, [ddp]);

  // rate
  const [rate, setRate] = useState([]);

  useEffect(() => {
    const fetchEvaluationData = async () => {
      if (ddp) {
        const evaluationCollection = collection(
          db,
          "products",
          ddp.id,
          "evalution"
        );
        const evaluationSnapshot = await getDocs(evaluationCollection);

        const evaluationData = evaluationSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setRate(evaluationData);
      }
    };

    fetchEvaluationData();
  }, [ddp]);

  //   rateのaverage
  const [average, setAverage] = useState(0);
  useEffect(() => {
    const init = () => {
      const count = rate?.length;
      let sum = 0;
      rate?.forEach((doc) => (sum += doc.rate));
      setAverage(sum / count);
    };
    rate && init();
  }, []);

  //   buyer取得
  const [buyer, setBuyer] = useState("");
  useEffect(() => {
    const init = async () => {
      getDoc(doc(db, "users", ddp?.uid)).then((elm) =>
        setBuyer({ id: elm.id, ...elm.data() })
      );
    };
    ddp && init();
  }, [ddp]);

  //   交換成立回数
  const [buyCount, setBuyCount] = useState(0);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      const q = query(
        collection(db, "deal"),
        where("product", "==", ddp.id),
        where("status", "not-in", [
          "buyCanceled",
          "requesting",
          "buyerCanceled",
        ])
      );
      await getDocs(q).then((docs) =>
        docs.forEach((elm) => arr.push({ id: elm.id, ...elm.data() }))
      );
      setBuyCount(arr.length);
    };
    ddp && init();
  }, []);

  // console.log("ddp =>", ddp?.title);
  // console.log("buyCount =>", buyCount);
  // console.log("rate =>", rate);
  // console.log("average =>", average);

  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor: "#000000",
      color: "rgba(0, 0, 0, 0.40)",
      maxWidth: 440,
      fontSize: theme.typography.pxToRem(12),
      // border: "1px solid #dadde9",
    },
  }));

  const navigate = useNavigate();
  const handleMoveDDP = () => {
    navigate("/product/" + ddp?.id);
  };

  return (
    <div className="flex flex-col  border rounded-md  hover:border-blue-500 cursor-pointer p-2    hover:shadow-2xl duration-150  group">
      {/* DDP名称 name */}
      <div
        className="my-2 font-bold text-black  text-xl break-words whitespace-pre-wrap line-clamp-1  group-hover:text-blue-500 duration-150 "
        onClick={() => window.open("/product/" + ddp?.id)}
        // onClick={handleMoveDDP}
      >
        {ddp?.title}
      </div>
      {/* tag offeringとtarget */}
      <div className="flex space-x-2 ">
        <div className="text-xs border px-2 py-1 rounded-md border-gray-800 bg-white text-gray-800 shadow-xl">
          {ddp?.offering}
        </div>
        <div className="text-xs border px-2 py-1 rounded-md border-gray-800 bg-white text-gray-800 shadow-xl">
          {ddp?.target}
        </div>
      </div>
      {/* 左：写真と右：詳細 */}
      <div className="flex space-x-2 my-2">
        {/* 左：写真 */}
        <div className="flex w-1/2 max-h-32 ">
          {!images?.length ? (
            <img
              src={Img}
              alt=""
              object-fit="cover"
              className="w-full  rounded-tl-md"
            />
          ) : (
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              className="w-full  rounded-tl-md"
            >
              {images?.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image.imageURL}
                      alt=""
                      object-fit="cover"
                      className="w-full h-32"
                    />
                  </div>
                );
              })}
            </Carousel>
          )}
        </div>
        {/* 右：詳細 */}
        <div className="flex flex-col justify-end w-1/2 text-gray-500 ">
          {/* 交換成立回数 */}
          <div className="flex space-x-1 items-center rounded-md px-2 py-1 border border-gray-800 text-gray-800 text-xs w-fit">
            <div className="text-xs">交換回数</div>
            <div className="flex items-center rounded-full px-2 py-1 border border-blue-800 w-fit bg-blue-50 text-blue-500">
              {buyCount}
            </div>
          </div>

          {/* runru */}
          <div className="flex space-x-1 items-baseline">
            <div className="text-2xl text-blue-500 font-bold">{ddp?.runru}</div>
            <div className="text-xs text-gray-400">runru</div>
          </div>
          {/* 評価 */}
          {average > 0 ? (
            <div className="flex space-x-1">
              <div>
                <Rate rate={average} />
              </div>
              <div className="text-xs text-gray-600">({rate?.length})</div>
            </div>
          ) : (
            <div className="text-xs text-gray-600">評価はまだありません</div>
          )}
          {/* 提供方法 */}
          <div className="border mt-2 rounded-md px-2 py-1 w-fit text-xs text-gray-800 border-gray-800 shadow-md">
            {ddp?.delivery}
          </div>
          {/* 登録日 */}
          <div className="flex space-x-1 mt-2">
            <div className="text-xs">登録</div>
            <div className="text-gray-700 text-xs">
              {new Date(ddp.createdAt?.toDate()).toLocaleDateString()}
            </div>
            <div className="text-xs">(</div>
            <div className=" text-xs">
              {isInvalidDate(new Date(ddp.createdAt?.toDate()))
                ? "読み込み中"
                : formatDistanceToNow(ddp.createdAt?.toDate(), {
                    locale: ja,
                  })}
              前
            </div>
            <div className="text-xs">)</div>
          </div>
        </div>
      </div>
      {/* User */}
      <div className="flex space-x-2 items-center mb-3">
        <div>
          <Avatar src={buyer?.avatar} sx={{ width: 24, height: 24 }} />
        </div>
        <div className="flex space-x-1  text-xl whitespace-pre-wrap break-words line-clamp-1">
          {buyer?.lastName}
          {buyer?.firstName}
        </div>
      </div>

      {/* 説明 */}
      <HtmlTooltip
        title={
          ddp?.explain && (
            <>
              <div className="flex flex-col space-y-2 p-2">
                <Avatar src={buyer?.avatar} sizes="small" />
                <div className=" bg-opacity-40 text-gray-100">
                  {ddp?.explain}
                </div>
              </div>
            </>
          )
        }
        followCursor
        arrow
        placement="top"
      >
        <div className=" break-words whitespace-pre-wrap line-clamp-3 text-sm group-hover:text-green-700 duration-150 group-hover:font-bold text-gray-500">
          {ddp?.explain}
        </div>
      </HtmlTooltip>
    </div>
  );
};

export default DisplayDDP;
