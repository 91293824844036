import React, { useState, useEffect } from "react";
import { auth, db } from "../api/firebase";
import Img from "../data/img/defaultImage.png";
import { getDocs, collection, query, where } from "firebase/firestore";

import Myshops from "./Myshops";

import ProfileCard from "./ProfileCard";
import SelfIntroduction from "./SelfIntroduction";
import Blind from "./Blind";
import EditSelfIntroduction from "./EditSelfIntroduction";
import RequestShop from "./RequestShop";
import BackImage from "./BackImage";
import ProfilePicture from "./ProfilePicture";
import ProfileName from "./ProfileName";
import MyProducts from "./MyProducts";
import DealInformation from "./DealInformation";
import DealsList from "./DealsList";
import DistributedRunru from "../components/DistributedRunru";
import AvailableRunru from "./AvailableRunru";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";
import ProfileRunruHistory from "./ProfileRunruHistory";
import { Tooltip } from "@mui/material";
import { memo } from "react";
import UserSetting from "./UserSetting";
import { useLocation, useNavigate } from "react-router-dom";
import UsersQuestionList from "./UsersQuestionList";
import DDPSetting from "./DDPSetting";
import RunruHistory from "./RunruHistory";
import BalanceContainer from "./BalanceContainer";

const Profile = memo(() => {
  // 編集画面関連
  const [blind, setBlind] = useState(false);
  const [edits, setEdits] = useState({
    editSelfIntroduction: false,
    requestShop: false,
  });
  const { editSelfIntroduction, requestShop } = edits;

  // 自分が受け取った(to)ルンルを取得
  const [runruToCollection, setToRunruCollection] = useState([]);
  const [toRunrus, setToRunrus] = useState([]);
  const [loadingR, setLoadingR] = useState(true);

  useEffect(() => {
    const q = query(
      collection(db, "runru"),
      where("to", "==", auth.currentUser.uid)
    );
    const unscribe = () => {
      getDocs(q).then((snapshot) => {
        setToRunruCollection(snapshot.docs);
        setLoadingR(false);
      });
    };
    return unscribe();
  }, [loadingR]);
  useEffect(() => {
    if (runruToCollection.length) {
      const arr = runruToCollection?.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setToRunrus(arr);
    }
  }, [runruToCollection]);

  const [activeTab, setActiveTab] = useState(1); // アクティブなタブを追跡するためのstateを追加

  // 自分が送った(from)ルンルを取得
  const [runruFromCollection, setFromRunruCollection] = useState([]);
  const [fromRunrus, setFromRunrus] = useState([]);
  const [loadingL, setLoadingL] = useState(true);
  useEffect(() => {
    const q = query(
      collection(db, "runru"),
      where("from", "==", auth.currentUser.uid)
    );
    const unscribe = () => {
      getDocs(q).then((snapshot) => {
        setFromRunruCollection(snapshot.docs);
        setLoadingL(false);
      });
    };
    return unscribe();
  }, [loadingR]);
  useEffect(() => {
    if (runruFromCollection.length) {
      const arr = runruFromCollection?.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFromRunrus(arr);
    }
  }, [runruFromCollection]);
  //
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = React.useState("1");
  // useEffect(() => {
  //   const initValue = () => {
  //     setValue(String(location?.state.tab));
  //   };
  //   location.state && initValue();
  // }, [location?.state]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue); // タブが変更されたときにアクティブなタブを更新
    // navigate(`/${newValue}`, { replace: true });
  };

  const [ddpValue, setDdpValue] = React.useState("1");
  const handleChangeDdp = (event, newValue) => {
    setDdpValue(newValue);
    setActiveTab(newValue); // タブが変更されたときにアクティブなタブを更新
  };

  // DDPコンテナ
  const DDP = () => {
    const DdpInfo = ({ type, content, color }) => {
      return (
        <div
          className={`${color && "bg-back"} flex pl-5 my-1 h-8 items-center`}
        >
          {type}：{content}
        </div>
      );
    };

    // テーブルデータかっこいいやつ
    // https://www.youtube.com/watch?v=S4MhQ6peq8A

    return (
      <div className=" flex flex-col border rounded-md mb-3 p-3 bg-white shadow-md hover:shadow-xl duration-150 hover:cursor-pointer">
        <div className="font-bold ">DDPマッチング</div>
        <div>制作中です、以下は例です</div>
        <div className="flex font-bold bg-gray-100 items-center pl-3 h-10 rounded-xl">
          自分の叶えたいこと！
        </div>

        <DdpInfo type="会いたい" content="〇〇さん" color />
        <DdpInfo type="欲しい" content="健康" />
        <DdpInfo type="身につけたい" content="ピアノ技術" color />
        <DdpInfo type="聞きたい" content="歴史（日本）" />
        <DdpInfo type="行きたい" content="弓月城" color />
        <div className="flex font-bold bg-gray-100 items-center pl-3 h-10 rounded-xl">
          誰かのコレなら叶えられる！
        </div>
        <DdpInfo type="身につけたい" content="Web開発" />
        <DdpInfo type="聞きたい" content="歴史（日本）" />
        <DdpInfo type="聞きたい" content="歴史（世界）" />
      </div>
    );
  };

  // 変更画面関連
  const handleEditOff = () => {
    setBlind(false);
    setEdits({
      editSelfIntroduction: false,
      editBirthDate: false,
      editJob: false,
      editlastName: false,
      editristName: false,
    });
  };

  const handleEdit = (target) => {
    setBlind(true);
    setEdits({ ...edits, [target]: true });
  };

  // console.log("auth.curretUser.uid =>", auth.currentUser.uid);

  return (
    <>
      <div className="flex flex-col w-full ">
        {/* blind ↓↓↓↓↓ */}
        <Blind blind={blind} onclick={handleEditOff} />

        {/* editSelfIntroduction ↓↓↓↓↓ */}
        <EditSelfIntroduction editSelfIntroduction={editSelfIntroduction} />

        {/* lg以上表示 */}
        <div className="hidden lg:block">
          <div className="flex w-full">
            {/* <TabContext value={activeTab}>  */}
            <TabContext value={value}>
              {/* 左リスト */}
              <Box sx={{ width: "10%" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  orientation="vertical"
                >
                  <Tab label="基本情報" value="1" />
                  <Tab label="DDP" value="2" />
                  <Tab label="取引情報" value="3" />
                  <Tab label="自分のお店" value="4" />
                  <Tab label="統計" value="5" />
                  <Tab label="アニマルロック" value="6" />
                  <Tab label="設定" value="7" />
                  <Tab label="お問い合わせ" value="8" />
                </TabList>
              </Box>
              {/* 基本情報 */}
              <TabPanel sx={{ width: "100%" }} value="1">
                {auth.currentUser.uid && (
                  <div className=" relative">
                    <BackImage id={auth.currentUser.uid} />
                    <ProfilePicture id={auth.currentUser.uid} />
                  </div>
                )}
                <ProfileName />
                <ProfileCard />
                <SelfIntroduction onclick={handleEdit} />
                <DDPSetting />
              </TabPanel>
              {/* DDP */}
              <TabPanel sx={{ width: "100%" }} value="2">
                <div className="bg-white p-4 rounded-md border shadow-sm">
                  <div className="font-bold text-xl">DDP</div>

                  <TabContext value={ddpValue}>
                    <Box
                      sx={{
                        // maxWidth: { xs: 320, sm: 480 },
                        width: "100%",
                      }}
                    >
                      <TabList
                        onChange={handleChangeDdp}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                      >
                        <Tab label="DDPマッチング" value="1" />
                        <Tab label="ルンル" value="2" />
                        <Tab label="提供するもの" value="3" />
                      </TabList>
                      <TabPanel value="1">
                        <DDP />
                      </TabPanel>
                      <TabPanel value="2">
                        <table className=" p-2 w-fit">
                          <tbody>
                            {/* 現在所持ルンル */}
                            <tr>
                              <td className=" bg-blue-50 p-5 font-bold">
                                現在所持ルンル
                              </td>
                              <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                                <div className="flex flex-col space-y-2">
                                  <div className="flex items-center  space-x-2 ">
                                    <div className="font-bold ">
                                      <AvailableRunru
                                        uid={auth.currentUser.uid} from="profile"
                                      />
                                       {/* <AvailableRunru uid="rPOfmeb7Q0atz58BlCSSALnXAVV2" from="profile"/> */}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            {/* 流通させたルンル */}
                            <tr>
                              <td className=" bg-blue-50 p-5 font-bold">
                                <div>流通させたルンル</div>
                                <div className="text-xs text-gray-300">
                                  定期配布されたルンルも含みます
                                </div>
                              </td>
                              <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                                <div className="flex flex-col space-y-2">
                                  <div className="flex items-center  space-x-2 ">
                                    <div className="font-bold ">
                                      <DistributedRunru />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {/* <BalanceContainer uid={auth.currentUser.uid}/> */}
                        {/* <BalanceContainer uid="8xwQWi7UM4dBmZuToveETcfgqxn1"/> */}

                        {/* ルンル履歴 */}
                        <ProfileRunruHistory uid={auth.currentUser.uid} />
                        <RunruHistory uid={auth.currentUser.uid} />
                      </TabPanel>
                      <TabPanel value="3">
                        <MyProducts />
                      </TabPanel>
                    </Box>
                  </TabContext>
                </div>
              </TabPanel>
              {/* 取引リスト */}
              <TabPanel sx={{ width: "100%" }} value="3">
                <div className="border bg-white rounded-md p-4 my-4 ">
                  <div className="font-bold text-xl">取引情報</div>
                  <DealsList uid={auth.currentUser.uid} tab={value} />
                </div>
              </TabPanel>
              {/* 自分のお店 */}
              <TabPanel sx={{ width: "100%" }} value="4">
                <div className="border bg-white rounded-md p-4 my-4 ">
                  <div className="font-bold text-xl">自分のお店</div>
                  <Myshops />
                </div>
              </TabPanel>
              {/* 統計 */}
              <TabPanel sx={{ width: "100%" }} value="5">
                <div className="border bg-white rounded-md p-4 my-4 ">
                  <div className="font-bold text-xl">統計</div>
                  <div>実装中です</div>
                  <div>DDPの移動量やコメント数など、グラフで表示します</div>
                </div>
              </TabPanel>
              {/* アニマルロック */}
              <TabPanel sx={{ width: "100%" }} value="6">
                <div className="border bg-white rounded-md p-4 my-4 ">
                  <div className="font-bold text-xl">アニマルロック</div>
                  <div>実装中です</div>
                </div>
              </TabPanel>
              {/* 設定 */}
              <TabPanel sx={{ width: "100%" }} value="7">
                <UserSetting id={auth.currentUser.uid} />
              </TabPanel>
              {/* お問い合わせ */}
              <TabPanel sx={{ width: "100%" }} value="8">
                <div className="border bg-white rounded-md p-4 my-4 ">
                  <UsersQuestionList id={auth.currentUser.uid} />
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </div>

        {/* lg以下表示 */}
        <div className="xl:px-28 lg:hidden">
          <div className=" relative">
            <BackImage id={auth.currentUser.uid} />
            <ProfilePicture id={auth.currentUser.uid} />
          </div>
          <ProfileName />
          {/* プロフィール、自己紹介、DDP */}
          <ProfileCard />
          <SelfIntroduction onclick={handleEdit} />
          <DDPSetting />

          {/* 取引情報 */}
          <div className="col-start-1 col-end-4 ">
            <UserSetting id={auth.currentUser.uid} />
            {/* <DealInformation /> */}
          </div>

          {/* DDP DDPマッチング ルンル履歴　提供するもの */}
          <div className="my-5 border rounded-md bg-white p-4 shadow-sm">
            <div className="font-bold text-xl">DDP</div>
            <TabContext value={ddpValue}>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <TabList
                  onChange={handleChangeDdp}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="DDPマッチング" value="1" />
                  <Tab label="ルンル" value="2" />
                  <Tab label="提供するもの" value="3" />
                </TabList>
                {/* DDP */}
                <TabPanel value="1" sx={{ padding: 0 }}>
                  <DDP />
                </TabPanel>
                {/* ルンル */}
                <TabPanel value="2" sx={{ padding: 0 }}>
                  {/* <Runru /> */}
                  {/* 現在所持ルンルと流通させたルンル */}
                  <table className=" p-2 w-fit">
                    <tbody>
                      {/* 現在所持ルンル */}
                      <tr>
                        <td className=" bg-blue-50 p-5 font-bold">
                          現在所持ルンル
                        </td>
                        <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                          <div className="flex flex-col space-y-2">
                            <div className="flex items-center  space-x-2 ">
                              <div className="font-bold ">
                                <AvailableRunru uid={auth.currentUser.uid} from="profile lg以下"/>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/* 流通させたルンル */}
                      <tr>
                        <td className=" bg-blue-50 p-5 font-bold">
                          <div>流通させたルンル</div>
                          <div className="text-xs text-gray-300">
                            定期配布されたルンルも含みます
                          </div>
                        </td>
                        <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                          <div className="flex flex-col space-y-2">
                            <div className="flex items-center  space-x-2 ">
                              <div className="font-bold ">
                                <DistributedRunru />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ProfileRunruHistory />
                  <div className="my-7"></div>
                  <RunruHistory uid={auth.currentUser.uid} />
                </TabPanel>

                <TabPanel value="3" sx={{ padding: 0 }}>
                  <MyProducts />
                </TabPanel>
              </Box>
            </TabContext>
          </div>

          {/* 取引情報 */}
          <div className="col-start-1 col-end-4">
            <div className="border bg-white rounded-md p-4 my-4 ">
              <div className="font-bold text-xl">取引情報</div>

              <DealsList uid={auth.currentUser.uid} />
            </div>

            {/* <DealInformation /> */}
          </div>

          {/* お問い合わせ */}
          <div className="col-start-1 col-end-4">
            <div className="border bg-white rounded-md p-4 my-4 ">
              <UsersQuestionList id={auth.currentUser.uid} />
            </div>
          </div>

          {/* 自分のショップ */}
          <div className="col-start-1 col-end-4">
            <div className="border bg-white rounded-md p-4 my-4 ">
              <div className="font-bold text-xl">自分のお店関連</div>
              <Myshops />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Profile;

// hover時切り替えぷるつく
// https://www.youtube.com/watch?v=NRY-DwNm5iU
