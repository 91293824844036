import { Button } from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {
  useDocument,
  useDocumentData,
  useCollection,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { auth, db } from "../api/firebase";
import AddComponent from "./AddComponent";
import AddFlexContentButton from "./AddFlexContentButton";
import EditableContent from "./EditableContent";
import FlexCotent from "./FlexCotent";

const Announce = () => {
  const { id } = useParams();

  // ユーザ情報
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initGetUser = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    initGetUser();
  }, []);

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   flexContent取得
  const collectionQuery = query(
    collection(db, "notificationOfTop", id, "content"),
    where("isPublish", "==", true)
  );
  const [collectionRef] = useCollection(collectionQuery);
  const list = collectionRef?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  useEffect(() => {
    list?.sort((a, b) => {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
    });
  }, [list]);


  return (
    <>
      {list && (
        <>
          <FlexCotent
            list={list}
            collection="notificationOfTop"
            handleChange={handleChange}
            value={value}
          />
        </>
      )}
      {userInfo?.isAdmin && value === "1" && (
        <AddFlexContentButton target="notificationOfTop" id={id} />
      )}
    </>
  );
};

export default Announce;
