import React from "react";
import SwitchComponent from "./SwitchComponent";

const OperateFeed = () => {
  const feedPriority = [
    { title: "みつろうさんの投稿", target: "mitsulow" },
    { title: "事務局の投稿", target: "admin" },
    { title: "フォローした人の投稿", target: "follow" },
    { title: "フォローした人がいいねした投稿", target: "followGood" },
    { title: "宣伝", target: "propaganda" },
  ];

  return (
    <>
    <div className="font-bold text-2xl my-2">Feed表示</div>
      <table>
        <tbody>
          {feedPriority?.map((item, idx) => (
            <tr key={idx}>
              <td className=" p-2">{item.title}</td>
              <td className=" p-2">
                <SwitchComponent
                  id="feedPriority"
                  target={item?.target}
                  collection="env"
                  text="適用"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default OperateFeed;
