import React, { useState } from "react";
import { TextField } from "@mui/material";

import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import SelectUsers from "./SelectUsers";

import { StreamChat } from "stream-chat";
import GetUserInfo from "./GetUserInfo";

const AddBaseChief = ({ setOpen, requestId }) => {
  const [product, setProduct] = useState({
    chief: "",
    error: "",
    loading: false,
  });
  const { chief, error, loading } = product;

  // 値変更
  const handleChange = (e, value) => {
    setProduct({
      ...product,
      chief: value.id,
    });
  };

  const [adminUser] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // chiefはIDのみ取得
  // console.log("chief =>",chief)

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });
    try {
      // 変更処理
      const docRef = doc(db, "base", requestId);
      await updateDoc(docRef, {
        chief,
        lastUpdatedAt: serverTimestamp(),
      });

      // const docRef = await addDoc(collection(db, "ddp", match, "targets"), {
      //   [target]: {},
      // });
      await setDoc(doc(db, "base", requestId, "members",chief), {
        createdAt: serverTimestamp(),
        isApplication: false,
        isMember: true,
        isChief: true,
        uid: chief,
      });

      // affiliation・・・所属
      await addDoc(collection(db, "users", chief, "affiliationBase"), {
        baseId: requestId,
        createdAt: serverTimestamp(),
        type: "chief",
        registered: auth.currentUser.uid,
      });

      // chatのメンバーにも加える
      const userToConnect = {
        id: adminUser.uid,
        name: adminUser.lastName + adminUser.firstName,
        image: adminUser.avatar,
        langage: "jp",
      };
      const apiKey = process.env.REACT_APP_STREAM_API_KEY;
      const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
      // //   トークン取得
      const token = await fetch(
        process.env.REACT_APP_STREAM_GET_TOKEN_URL,
        // "https://us-central1-sekaimura-test.cloudfunctions.net/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: adminUser.uid,
          }),
        }
      ).then((r) => r.json());
      await client.connectUser(userToConnect, token);
      const channel = client.channel("messaging", "baseChat-" + requestId);
      await channel.addMembers([chief], {});

      setProduct({
        chief: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      const errorData = {
        message: error.message,
        stack: error.stack,
      };

      addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: errorData,
      });

      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">拠点のリーダーを追加</div>
        <form onSubmit={setRequestDataShop}>
          {/* <TextField
            id="outlined-required"
            name="chief"
            label="グループタイプ"
            defaultValue=""
            size="small"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
          ></TextField> */}

          <SelectUsers handleChange={handleChange} />

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
          >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AddBaseChief;
