import React, { useState } from "react";

// MaterialUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// redux - use
import { useNotification } from "../redux/useNotification";

// firebase
import { doc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

// date関連
import { Box, Divider, ListSubheader } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { format } from "date-fns";

const CreateAnnounce = ({ setOpen }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // 拠点入力データコンテナ
  const [data, setData] = useState({
    title: "",
    content: "",
    limit: "",
    publishDate: new Date(),
    error: "",
    loading: "",
  });
  const { title, content, limit, publishDate, error, loading } = data;

  // 入力データ処理
  const handleChange = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // 作成処理
  const createBase = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });

    try {
      // 変更処理
      const docRef = await addDoc(collection(db, "notificationOfTop"), {
        title,
        content,
        isPublish: false,
        limit,
        publishDate,
        creator: auth.currentUser.uid,
        createdAt: serverTimestamp(),
        lastUpdatedAt: serverTimestamp(),
      });

      setData({
        ...data,
        error: null,
      });
      displayNotification({
        message: "アナウンス作成、完了しました。",
        type: "success",
      });

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err.message);
      setData({
        ...data,
        error: err.message,
        loading: false,
      });
    }
  };

  const limitList = [
    {
      id: 0,
      value: false,
      display: "期限あり",
      tip: "任意の日付まで「NEWS」に表示します",
    },
    {
      id: 1,
      value: true,
      display: "期限なし",
      tip: "掲載「オフ」にしない限り「固定」に表示します",
    },
  ];

  // console.log("publishDate =>", publishDate);
  // console.log("limit =>", limit);
  console.table("data =>", data);

  //   イベント日　関連
  const limitedDatePublishDate = new Date();
  const [publishDay, setPubilshDay] = useState(new Date());
  const publishDateChange = async (e) => {
    if (limit === true) {
      setData({
        ...data,
        publishDate: "",
      });
      console.log("hoge");
      return;
    }
    if (!isNaN(e)) {
      setPubilshDay(e);
      const year = format(e, "yyyy");
      const month = format(e, "M");
      const day = format(e, "d");
      // console.log("yera =>",year)
      // console.log("month =>",month)
      // console.log("day =>",day)
      setData({
        ...data,
        publishDate: new Date(year, month - 1, day),
      });
      return false;
    }
  };

  return (
    <>
      <div className="mb-3 border p-2 bg-gray-500 text-white font-bold flex justify-center rounded-md">
        アナウンス作成
      </div>
      <form onSubmit={createBase}>
        {/* 名前 */}
        <TextField
          id="outlined-required"
          name="title"
          label="タイトル"
          defaultValue=""
          size="small"
          placeholder="セカイムラ〇〇を開催します"
          className=" w-full"
          onChange={handleChange}
          required
        />

        {/* 内容 */}
        <div className="my-4" />
        <TextField
          id="outlined-required"
          name="content"
          label="概要"
          defaultValue=""
          size="small"
          placeholder="〇月×日セカイムラ〇〇を開催します。"
          className=" w-full"
          onChange={handleChange}
          required
        />

        <TextField
          id="demo-simple-select"
          name="limit"
          label="期限"
          defaultValue=""
          className="w-full"
          sx={{ marginTop: 2 }}
          size="small"
          onChange={handleChange}
          select
        >
          {limitList?.map((row) => (
            <MenuItem dense key={row.display} value={row.value}>
              {row.display} -{" "}
              <div className=" text-gray-500 text-xs">{row.tip}</div>
            </MenuItem>
          ))}
        </TextField>

        {/* 表示期限 */}
        <div className="my-5"></div>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <Box sx={{ width: "100%" }}>
            <DatePicker
              label="表示期限"
              onChange={publishDateChange}
              inputFormat="yyyy年MM月dd日"
              mask="____年__月__日"
              value={publishDay}
              minDate={limitedDatePublishDate}
              //   maxDate={limitedDatePublishDate}
              leftArrowButtonText="前月"
              rightArrowButtonText="次月"
              toolbarTitle="日付選択"
              cancelText="キャンセル"
              okText="選択"
              size="small"
              renderInput={(params) => <TextField {...params} />}
              disabled={limit}
            />
          </Box>
        </LocalizationProvider>

        {/* 送信ボタン */}
        <div className="my-4" />
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
        >
          {loading ? "送信しています..." : "作成"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default CreateAnnounce;
