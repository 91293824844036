import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import defaultBackImg from "../data/img/defaultImage.png";
import LoaderBackImg from "./LoaderBackImg";
import Camera from "../data/svg/Camera";
import Loading from "./Loading";
import Blind from "./Blind";
import { useDocumentData } from "react-firebase-hooks/firestore";
// redux - use
import { useNotification } from "../redux/useNotification";

const BaceBackImage = ({ base, isLeader }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const [baseDoc] = useDocumentData(doc(db, "base", base?.id));

  //   背景画像コンテナ
  const [backImg, setBackImg] = useState("");
  //   セットコンテナ
  const [isLoading, setIsLoading] = useState("");
  //   背景画像Ref
  const backImgRef = useRef(null);

  // プロフィール画像変更処理
  useEffect(() => {
    if (backImg) {
      setIsLoading(true);
      const uploadBackImg = async () => {
        const backImgRef = ref(
          storage,
          `baseBackImage/${new Date().getTime()} - ${backImg.name}`
        );
        try {
          if (baseDoc?.backgroundPath) {
            await deleteObject(ref(storage, baseDoc?.backgroundPath));
          }
          const snap = await uploadBytes(backImgRef, backImg);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));
          await updateDoc(doc(db, "base", base?.id), {
            background: url,
            backgroundPath: snap.ref.fullPath,
          });
          setBackImg("");
          setIsLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      };
      uploadBackImg();
    }
  }, [backImg]);

  const checkPicture = (e) => {
    if (e.target.files[0].type !== "image/jpeg") {
      displayNotification({
        message: "画像ファイルを指定してください",
        type: "error",
      });

      return;
    }
    setBackImg(e.target.files[0]);
  };

  if (isLoading) {
    return (
      <>
        <Blind blind={true} />
        <Loading title="拠点バックイメージ" action="更新中" />;
      </>
    );
  }

  return (
    <>
      <div
        className={` ${isLeader && "hover:opacity-50 cursor-pointer group"}
         " relative flex justify-center items-center w-full "`}
      >
        {baseDoc?.background ? (
          <img
            src={baseDoc?.background || defaultBackImg}
            object-fit="cover"
            className="w-full h-64 sm:h-96  rounded-t-md"
            alt=""
            onClick={() => backImgRef.current.click()}
          />
        ) : defaultBackImg ? (
          <img
            src={defaultBackImg}
            alt="バックイメージ"
            className="w-full h-64 sm:h-96  rounded-t-md"
          />
        ) : (
          <LoaderBackImg />
        )}
        <div className="absolute bottom-2 right-2 bg-white text-green-500 rounded-full font-bold p-2 ">
          <div>{base?.prefecture}</div>
        </div>
        {isLeader && (
          <div
            className="absolute top-2   sm:hidden group-hover:flex text-gray-200 sm:text-black opacity-80 hover:opacity-100 duration-300"
            onClick={() => backImgRef.current.click()}
          >
            <Camera className="" />
            <input
              ref={backImgRef}
              onChange={(e) => checkPicture(e)}
              // onChange={(e) => setBackImg(e.target.files[0])}
              type="file"
              hidden
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BaceBackImage;
