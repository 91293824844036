import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { db, auth } from "../api/firebase";
import { addDoc, collection, serverTimestamp, doc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useNotification } from "../redux/useNotification";
import { StreamChat } from "stream-chat";
import { useDocumentData } from "react-firebase-hooks/firestore";

const AddMovie = ({ setOpen, target }) => {
  const { id } = useParams();
  const { displayNotification } = useNotification();
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const [data, setData] = useState({
    title: "",
    url: "",
    platform: "",
    explain: "",
  });
  const { title, url, explain, platform, error, loading } = data;

  const handleChange = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const doRegist = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });

    const create = async () => {
      try {
        const docRef = await addDoc(collection(db, "movie"), {
          title,
          url,
          explain,
          platform,
          target,
          targetId: id,
          creator: auth.currentUser.uid,
          viewCount: 0,
          isPublish: false,
          createdAt: serverTimestamp(),
        });

        const userToConnect = {
          id: user.uid,
          name: user.lastName + user.firstName,
          image: user.avatar,
          langage: "jp",
        };
        const apiKey = process.env.REACT_APP_STREAM_API_KEY;
        const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
        const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: user.uid,
          }),
        }).then((r) => r.json());
        await client.connectUser(userToConnect, token);
        const channel = client.channel("messaging", "movie-" + docRef.id, {
          name: title,
        });
        await channel.create();
        channel.addMembers([user.uid]);
        await channel.mute();

        displayNotification({
          message: "動画を追加しました。",
          type: "success",
        });

        setData({
          ...data,
          error: null,
        });
        window.location.reload();

        setOpen(false);
      } catch (err) {
        console.log(err.message);
        setData({
          ...data,
          error: err.message,
          loading: false,
        });
      }
    };
    create();
  };

  return (
    <>
      <div className="mb-3">動画追加</div>
      <form onSubmit={doRegist}>
        <div className="my-4"></div>
        <TextField
          id="outlined-required"
          name="platform"
          label="プラットフォーム"
          defaultValue=""
          size="small"
          className="w-full"
          onChange={handleChange}
          required
          disabled={loading}
          select
        >
          <MenuItem value="YouTube" key="YouTube" dense>
            YouTube
          </MenuItem>
          <MenuItem value="Vimeo" key="Vimeo" dense>
            Vimeo
          </MenuItem>
        </TextField>

        <div className="my-4"></div>
        <TextField
          id="outlined-required"
          name="title"
          label="動画名"
          defaultValue=""
          size="small"
          placeholder="〇月×日△△△ワークショップ①"
          className="w-full"
          onChange={handleChange}
          required
        />

        <div className="my-4"></div>
        <TextField
          id="outlined-required"
          name="url"
          label="URL"
          defaultValue=""
          size="small"
          placeholder="https://example.com/xxxxxxx"
          className="w-full"
          onChange={handleChange}
          required
        />

        <div className="my-3"></div>
        <textarea
          style={{ resize: "none", fontSize: "16px" }}
          type="text"
          label="動画の説明"
          name="explain"
          className="textarea textarea-bordered w-full h-24 bg-white"
          placeholder="△△△ワークショップの様子です。"
          defaultValue=""
          onChange={handleChange}
        ></textarea>

        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
        >
          {loading ? "送信しています..." : "追加"}
        </button>
      </form>
    </>
  );
};

export default AddMovie;
