import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import React, { memo, useState, useEffect } from "react";
import { db } from "../api/firebase";
import DisplayEventFromGroupForAll from "./DisplayEventFromGroupForAll";
import TablePagination from "@mui/material/TablePagination";
import SearchBar from "./SearchBar";
import FilterPanel from "./FilterPanel";
import { Button } from "@mui/material";

const Events = () => {
  const targetDate = new Date();

  // 未開催イベント取得
  const [notHoldEvents, setNotHoldEvents] = useState([]);
  const q = query(
    collection(db, "event"),
    where("publish", "==", true),
    where("publishTarget", "==", "all"),
    where("publishDate", ">=", targetDate),
    orderBy("publishDate", "asc") // 現在日付以降のイベントを昇順で取得
  );

  useEffect(() => {
    let arr = [];
    getDocs(q).then((docs) => {
      docs.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setNotHoldEvents(arr);
    });
  }, []);

  // ページネーション 未開催
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // フィルター関連
  const [searchInput, setSearchInput] = useState("");

  // カテゴリ関連
  const [selectedCategory, setSelectedCategory] = useState();
  const handleSelectCategory = (event, value) =>
    !value ? null : setSelectedCategory(value);

  // 集合関連
  const [selectedCategoryMeetingPlace, setSelectedCategoryMeetingPlace] =
    useState(null);
  const handleSelectCategoryMeetingPlace = (event, value) =>
    !value ? null : setSelectedCategoryMeetingPlace(value);

  // 費用関連
  const [selectedPrice, setSelectedPrice] = useState([0, 99999]);
  const handleChangePrice = (event, value) => {
    setSelectedPrice(value);
  };

  const [values, setValues] = useState([
    { id: 1, checked: false, label: "終了を含めない" },
  ]);

  const handleChangeChecked = (id) => {
    const valuesList = values;
    const changeCheckedValues = valuesList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setValues(changeCheckedValues);
  };

  const [list, setList] = useState();
  const [resultsFound, setResultsFound] = useState(true);

  const [researchCount, setResearchCount] = useState(0);
  const researchCountIncrement = () => {
    setResearchCount((prev) => prev + 1);
  };

  // フィルター実行
  const applyFilters = () => {
    let updatedList = notHoldEvents;

    // カテゴリフィルター
    if (selectedCategory) {
      updatedList = updatedList.filter(
        (item) => item.target === selectedCategory
      );
    }

    if (selectedCategoryMeetingPlace) {
      updatedList = updatedList.filter((item) =>
        selectedCategoryMeetingPlace === "オンライン"
          ? item.meetingPlace === selectedCategoryMeetingPlace
          : item.meetingPlace !== "オンライン"
      );
    }

    const valuesChecked = values
      .filter((item) => item.checked)
      .map((item) => item.publishDate);

    if (valuesChecked.length) {
      updatedList = updatedList.filter(
        (item) => item.publishDate.toDate() >= targetDate
      );
    }

    // 検索フィルター
    if (searchInput) {
      updatedList = updatedList.filter(
        (item) =>
          (item.title + item.explain)
            .toLowerCase()
            .search(searchInput.toLowerCase().trim()) !== -1
      );
    }

    // 価格フィルター
    const minPrice = selectedPrice[0];
    const maxPrice = selectedPrice[1];

    updatedList = updatedList.filter(
      (item) => item.price >= minPrice && item.price <= maxPrice
    );

    if (updatedList.length) researchCountIncrement();
    setList(updatedList);
    !updatedList.length ? setResultsFound(false) : setResultsFound(true);
  };

  // フィルターがある場合発動
  useEffect(() => {
    applyFilters();
  }, [
    selectedCategory,
    selectedCategoryMeetingPlace,
    searchInput,
    selectedPrice,
    values,
  ]);

  // リスト表示
  const displayList = (list) => {
    return (
      <>
        {(rowsPerPage > 0
          ? list?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : list
        )?.map((doc) => {
          return (
            <DisplayEventFromGroupForAll event={doc} key={Math.random()} />
          );
        })}
      </>
    );
  };

  // フィルター解除
  const clearFilter = () => {
    setResearchCount(0);
    setSearchInput("");
    setSelectedCategoryMeetingPlace("");
    setSelectedCategory("");
    setSelectedPrice([0, 99999]);
    setValues([{ id: 1, checked: false, label: "終了を含めない" }]);
    setList("");
  };

  return (
    <>
      <div className="">
        <div className="font-bold text-xl my-4">Event</div>

        {/* サーチボックス */}
        <div className="mb-4">
          <SearchBar
            value={searchInput}
            changeInput={(e) => setSearchInput(e.target.value)}
            placeholder="イベント名・説明など"
          />
        </div>

        {/* lg未満で表示 */}
        <div className="lg:hidden">
          <FilterPanel
            selectedCategory={selectedCategory}
            selectCategory={handleSelectCategory}
            selectedCategoryMeetingPlace={selectedCategoryMeetingPlace}
            selectCategoryMeetingPlace={handleSelectCategoryMeetingPlace}
            selectedPrice={selectedPrice}
            changePrice={handleChangePrice}
            clearFilter={clearFilter}
            researchCount={researchCount}
            values={values}
            changeChecked={handleChangeChecked}
          />
        </div>

        <div className=" grid grid-cols-7 gap-10">
          {/* lg以上で表示 */}
          {/* filter */}
          <div className="border col-span-2 rounded-md mb-4 hidden lg:block">
            <Button
              disabled={!researchCount || researchCount === 1}
              onClick={clearFilter}
            >
              フィルター解除
            </Button>
            <FilterPanel
              selectedCategory={selectedCategory}
              selectCategory={handleSelectCategory}
              selectedCategoryMeetingPlace={selectedCategoryMeetingPlace}
              selectCategoryMeetingPlace={handleSelectCategoryMeetingPlace}
              selectedPrice={selectedPrice}
              changePrice={handleChangePrice}
              clearFilter={clearFilter}
              researchCount={researchCount}
              values={values}
              changeChecked={handleChangeChecked}
            />
          </div>

          {/* イベント */}
          <div className=" col-span-7 lg:col-span-5">
            {/* 未開催イベント */}
            <div className=" border bg-white p-8 rounded-md mb-4">
              {notHoldEvents?.size === 0 && <div>イベントはありません</div>}
              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
                {/* filter前 */}
                {!researchCount && displayList(notHoldEvents)}

                {/* フィルター後 */}
                {researchCount > 0 && (!list || !list.length) && (
                  <div>検索のイベントはありません。</div>
                )}
                {researchCount > 0 && list?.length > 0 && displayList(list)}
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={list?.length ? list.length : notHoldEvents.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                showFirstButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Events);
