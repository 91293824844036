import { Avatar } from "@mui/material";
import { collection, orderBy, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../api/firebase";
import TablePagination from "@mui/material/TablePagination";

const GroupUpdates = ({ id }) => {
  const updatesRef = collection(db, "group", id, "updates");
  const q = query(updatesRef, orderBy("createdAt", "desc"));
  const [updates] = useCollectionData(q);

//   console.log("updates =>", updates);
  const navigate = useNavigate();

  const moveEvent = (doc) => {
    if (doc.eventId) {
      navigate("/event/" + doc.eventId);
    }
  };

  // pagenation
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="flex flex-col space-y-2 p-2 border rounded-md bg-white">
        <div className=" text-lg font-bold p-5">グループ最新情報</div>
        {(rowsPerPage > 0
          ? updates?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : updates
        )?.map((doc) => {
          return (
            <div key={Math.random()}>
              <div
                className={`${
                  doc.title === "イベント作成" && "hover:cursor-pointer"
                } " flex p-2 space-x-2 items-center hover:shadow-lg rounded-md  hover:bg-gray-100 "`}
                onClick={() => moveEvent(doc)}
              >
                {doc.title === "グループ許可" ? (
                  <Avatar>運営</Avatar>
                ) : (
                  <Avatar src={doc.creatorAvatar} />
                )}
                <div className="flex flex-col">
                  <div className="font-bold">{doc.title}</div>
                  <div className=" text-gray-600">{doc.information}</div>
                </div>
              </div>
            </div>
          );
        })}
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={updates?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ width: "fit" }}
        />
      </div>
    </>
  );
};

export default GroupUpdates;

