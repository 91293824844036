import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";

export default function SelectUsers({handleChange}) {
  const [usersRef] = useCollection(collection(db, "users"));
  const users = usersRef?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300 }}
      options={users}
      autoHighlight
      onChange={(e,value) =>handleChange(e,value)}
      getOptionLabel={(option) => option.lastName + option.firstName}
      renderOption={(props, option) => (
        <Box
          key={option.uid}
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={option.avatar}
            srcSet={option.avatar}
            // src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.lastName + " " + option.firstName}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="リーダーを選択"
          onChange={handleChange}

          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

