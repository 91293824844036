import React, { memo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getDoc,
  getDocs,
  doc,
  collection,
  where,
  query,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import UserCard from "./UserCard";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalForm from "./ModalForm";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import Box from "@mui/material/Box";
import GroupMembers from "./GroupMembers";
import GroupSetting from "./GroupSetting";
import ActivityFeedInGroup from "./ActivityFeedInGroup";
import GroupBackImage from "./GroupBackImage";
import GroupMainImage from "./GroupMainImage";
import GroupEvents from "./GroupEvents";
import { TabList } from "@mui/lab";
import StreamChatOnGroup from "./StreamChatOnGroup";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import GroupUpdates from "./GroupUpdates";
import GroupMembersList from "./GroupMembersList";
import Loading from "./Loading";

import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

// redux - use
import { useNotification } from "../redux/useNotification";
import Feeds from "./Feeds";
import GroupChatSetting from "./GroupChatSetting";
import DisplayMemberAndStatus from "./DisplayMemberAndStatus";

const Group = memo(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  // redux - Notification
  const { displayNotification } = useNotification();

  // ユーザ情報取得
  const [user, setUser] = useState("");
  useEffect(() => {
    const getUserInfo = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    getUserInfo();
  }, []);

  // グループ情報取得
  const [group, setGroup] = useState();
  useEffect(() => {
    getDoc(doc(db, "group", id)).then((doc) => {
      setGroup({ id: doc.id, ...doc.data() });
    });
  }, [id]);

  // グループのメンバーを取得
  const [members, setMembers] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      getDocs(collection(db, "group", id, "members")).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setMembers(arr);
      });
    };
    init();
  }, [id]);

  // グループの開設者かどうか
  const [isFounder, setIsFounder] = useState(false);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "group", id, "members"),
        where("uid", "==", auth.currentUser.uid),
        where("isOwner", "==", true)
      );
      const docSnap = await getDocs(q);
      if (docSnap.size > 0) {
        setIsFounder(true);
      }
    };
    init();
  }, []);

  // グループに参加しているかどうか
  const [isMember, setIsMember] = useState(false);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "group", id, "members"),
        where("uid", "==", auth.currentUser.uid),
        where("isMember", "==", true)
      );
      const docSnap = await getDocs(q);
      // console.log("docSnap.size =>", docSnap.size);
      if (docSnap.size > 0) {
        setIsMember(true);
      }
    };
    init();
  }, []);

  // console.log("isMember =>", isMember);
  // グループに参加申請しているかどうか
  const [isApplication, setIsApplication] = useState(false);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "group", id, "members"),
        where("uid", "==", auth.currentUser.uid),
        where("isMember", "==", false),
        where("isApplication", "==", true)
      );
      const docSnap = await getDocs(q);
      if (docSnap.size > 0) {
        setIsApplication(true);
      }
    };
    init();
  }, []);

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
  };

  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // パスのコピークリック
  var currentURL = window.location.pathname; // 現在のURLのパス部分を取得
  // let path = "https://sekaimura-add3c.firebaseapp.com" + currentURL;
  let path = "https://sekaimura.net" + currentURL;

  const [resultText, setResultText] = useState(path);
  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(resultText);
    displayNotification({
      message: "URLをコピーしました",
    });
  };

  if (!group) {
    return <Loading />;
  }
  // console.log("group.isPublish =>", group?.isPublish);
  if (!group?.isPublish) {
    return <div>グループの使用が一時停止されています。</div>;
  }

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} requestId={id} />
      <div>
        {/* 全体へ戻るボタン */}
        <div className="absolute  mb-2" onClick={() => navigate("/groups")}>
          <div className="rounded-full p-2 border w-10">
            <ArrowBackIcon />
          </div>
        </div>

        {/* タイトルなど */}
        <div className="flex space-x-5  ">
          {/* 左 */}
          <div className="grow w-9/12">
            <div className="w-full  space-y-3 ">
              {/* タイトルやイメージ、入退会ボタンなど */}
              <div className="relative flex flex-col pb-2 rounded-md border bg-white ">
                {/* 上　backImage */}
                <div className="h-3/5 ">
                  {group && <GroupBackImage group={group} />}
                </div>
                {/* 下　mainImageやタイトルなど */}
                <div className="flex flex-col sm:flex-row h-2/5 ">
                  {/* mainImage */}
                  <div className="absolute right-0 left-0 mx-auto sm:mx-0  sm:left-5   top-32 sm:top-72 border-white border-4 rounded-md bg-white z-20 w-52 h-56 ">
                    {group && <GroupMainImage group={group} />}
                  </div>

                  {/* タイトルなど */}
                  <div className="flex flex-col sm:ml-60 sm:mt-5 mt-28 p-4  space-y-4 justify-center items-center sm:items-start">
                    {/* タイトル */}
                    <div className="flex items-center space-x-2">
                      <div className="font-bold line-clamp-3 text-3xl">
                        {group?.title}
                      </div>
                    </div>
                    {/* 説明 */}
                    <div className="text-gray-400 text-xs line-clamp-3">
                      {group?.explain}
                    </div>

                    {/* 代表・タイプ・受け入れ・ボタン */}
                    <div className="flex space-x-2 items-center text-gray-400">
                      代表：
                      <UserCard id={group?.representative} />
                      {/* タイプ */}
                      <div className="flex border min-w-fit rounded-md p-2 text-gray-400 text-xs items-center justify-center truncate whitespace-pre-wrap">
                        {group?.type}
                      </div>
                      {/*　表示 */}
                      {group?.accept === "private" ? (
                        <div className="flex border rounded-md p-2 text-gray-400 text-xs items-center justify-center truncate whitespace-pre-wrap">
                          <LockOutlinedIcon />
                          {group?.accept}
                        </div>
                      ) : (
                        <div className="flex border rounded-md p-2 text-gray-400 text-xs items-center justify-center truncate whitespace-pre-wrap">
                          {group?.accept}
                        </div>
                      )}
                    </div>
                    {/* 入会退会ボタン */}
                    <div className="flex space-x-2">
                      {isFounder ? (
                        <div className="flex space-x-2 items-center">
                          <div className="flex border rounded-md p-2 bg-yellow-500 text-white text-xs items-center justify-center truncate whitespace-pre-wrap">
                            代表
                          </div>
                          <div>
                            <div
                              className="hover:cursor-pointer"
                              onClick={() => copyToClipboard()}
                            >
                              <Tooltip title="クリックしてURLをコピー" arrow>
                                <LinkIcon className="hover:opacity-40 duration-150" />
                              </Tooltip>
                            </div>
                          </div>{" "}
                        </div>
                      ) : isApplication ? (
                        <div className="flex border rounded-md p-2 bg-green-500 text-white text-xs items-center justify-center truncate whitespace-pre-wrap">
                          申請中
                        </div>
                      ) : isMember ? (
                        <Button
                          variant="contained"
                          onClick={() => handleEdit("quitGroup", id)}
                        >
                          グループを辞める
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => handleEdit("addGroup", id)}
                        >
                          参加する
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* コンテンツ */}
              <div className="  rounded-md w-[calc(100%)]">
                <TabContext value={value}>
                  {/* 左リスト */}

                  <Box
                    sx={{
                      maxWidth: { xs: 375, sm: 1080 },
                    }}
                  >
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                    >
                      <Tab label="Feed" value="1" />
                      <Tab label="Members" value="2" />
                      <Tab label="Event" value="3" />
                      <Tab label="Chat" value="4" />
                      {(isFounder || user?.isAdmin) && (
                        <Tab label="設定" value="5" />
                      )}
                      {(isMember || user?.isAdmin) && (
                        <Tab label="チャット設定" value="6" />
                      )}
                    </TabList>
                  </Box>

                  {/* Feed */}
                  <TabPanel sx={{ width: "100%" }} value="1">
                    <div className=" font-bold text-3xl">Feed</div>
                    {isMember || user?.isAdmin ? (
                      <Feeds target="group" id={id} />
                    ) : (
                      <div>グループのメンバーのみに公開されています。</div>
                    )}
                  </TabPanel>

                  {/* Members */}
                  <TabPanel sx={{ width: "100%" }} value="2">
                    <div className=" font-bold text-3xl">Members</div>
                    <GroupMembers members={members} />
                  </TabPanel>

                  {/* Events */}
                  <TabPanel sx={{ width: "100%" }} value="3">
                    <div className=" font-bold text-3xl">Events</div>
                    {/* <div>制作中</div> */}
                    <GroupEvents isFounder={isFounder} isMember={isMember} />
                  </TabPanel>

                  {/* Chat */}
                  <TabPanel
                    sx={{ width: "100%", minHeight: "720px" }}
                    value="4"
                  >
                    <div className=" font-bold text-3xl">Chat</div>
                    {isMember ? (
                      <StreamChatOnGroup
                        user={user}
                        group={group}
                        isMember={isMember}
                      />
                    ) : (
                      <div>グループメンバーの機能です</div>
                    )}
                  </TabPanel>

                  {/* 設定 */}
                  <TabPanel sx={{ width: "100%" }} value="5">
                    <div className=" font-bold text-3xl">設定</div>
                    <GroupSetting group={group} />
                  </TabPanel>
                  {/* チャット設定 */}
                  <TabPanel sx={{ width: "100%" }} value="6">
                    <div className=" font-bold text-3xl">設定</div>
                    {(isMember || user?.isAdmin) && (
                      <GroupChatSetting group={group} user={user} />
                    )}
                  </TabPanel>
                </TabContext>
              </div>
            </div>
            {/* タブ */}

            {/* 　Feed */}
            {/* 　メッセンジャー */}
            {/* 　イベント */}
          </div>

          {/* 右 */}
          <div className="grid-0 w-3/12   hidden xl:block rounded-md">
            <div className="w-full scrollbar-hide space-y-5">
              <GroupUpdates id={id} />
              {isMember && <DisplayMemberAndStatus group={group} user={user} />}
            </div>
          </div>
        </div>
        {/* メンバー一覧 */}
      </div>
    </>
  );
});

export default Group;
