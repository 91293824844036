import { collection } from "firebase/firestore";
import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import AddComponent from "./AddComponent";
import EditIcon from "@mui/icons-material/Edit";
import ModalForm from "./ModalForm";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const OperateQuestions = () => {

  // 質問タイプ取得
  const q = collection(db, "questionType");
  const [questionTypeCollection] = useCollection(q);
  const questionsType = questionTypeCollection?.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });

// 結果取得
  const qq = collection(db, "inquiryResultType");
  const [inquiryResultTypeCollection] = useCollection(qq);
  const inquiryResultType = inquiryResultTypeCollection?.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });




  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");
  const handleEdit = (e, id) => {
    // console.log("e =>", e);
    // console.log("url =>", url);
    setOpen(true);
    setTarget(id);
    setForm(e);
  };

  console.log("inquiryResultType =>",inquiryResultType)
  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} target={target} />
      {/* questionsTypeを追加 */}
      <div className="font-bold text-xl">お問い合わせ種類</div>
      <AddComponent value="お問い合わせ種類を追加" target="addQuestionType" />
      <div className=" max-h-52 overflow-y-auto ">
        {questionsType?.map((doc) => (
          <div
            className="flex space-x-2 rounded-md border my-2 p-2"
            key={Math.random()}
          >
            <EditIcon
              className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
              onClick={() => handleEdit("editQuestionType", doc.id)}
            />
            <div>{doc.reason}</div>
            <RemoveCircleIcon
              className="text-xs hover:cursor-pointer text-red-500 duration-200 rounded-full"
              onClick={() => handleEdit("deleteQuestionType", doc.id)}
            />
          </div>
        ))}
      </div>

      {/* inquiryResultTypeを追加 */}
      <div className="font-bold text-xl">結果の種類</div>
      <AddComponent value="結果を追加" target="addResultType" />
      <div className=" max-h-52 overflow-y-auto ">
        {inquiryResultType?.map((doc) => (
          <div
            className="flex space-x-2 rounded-md border my-2 p-2"
            key={Math.random()}
          >
            <EditIcon
              className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
              onClick={() => handleEdit("editResultType", doc.id)}
            />
            <div>{doc.result}</div>
            <RemoveCircleIcon
              className="text-xs hover:cursor-pointer text-red-500 duration-200 rounded-full"
              onClick={() => handleEdit("deleteResultType", doc.id)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default OperateQuestions;
