import React, { useEffect, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";

import "./MessagingChannelList.css";
import { SkeletonLoader } from "./SkeletonLoader";

import { CreateChannelIcon } from "../../assets";
import streamLogo from "../../assets/stream.png";

const MessagingChannelListInNotification = ({
  children,
  error = false,
  loading,
  onCreateChannel,
  pro,
  // toggelMobile,
}) => {
  const { client, setActiveChannel } = useChatContext();
  const { id, image = streamLogo, name = "Example User" } = client.user || {};
  const [list, setList] = useState([]);

  // console.log("client =>",client)
  // useEffect(() => {
  //   const removeGroupChat = async () => {
  //     let arr;
  //     arr = children.props.children?.filter((channel) => {
  //       if (channel.key.indexOf("groupChat-") === -1) {
  //         return channel;
  //       }
  //     });
  //     setList(arr);
  //     if (pro) {
  //     }
  //     setActiveChannel(arr[0].props.channel);
  //   };
  //   removeGroupChat();
  // }, [children, setActiveChannel]);

  const ListHeaderWrapper = ({ children }) => {
    return (
      <div className="messaging__channel-list overflow-auto ">
        <div>{children}</div>
      </div>
    );
  };

  if (error) {
    return (
      <ListHeaderWrapper>
        <div className="messaging__channel-list__message">
          エラーが起きました。時間をあけて試してください。
        </div>
      </ListHeaderWrapper>
    );
  }

  if (loading) {
    return (
      <ListHeaderWrapper>
        <div className="messaging__channel-list__message">
          <SkeletonLoader />
        </div>
      </ListHeaderWrapper>
    );
  }

  if (children) {
    return <ListHeaderWrapper>{children}</ListHeaderWrapper>;
  }
};

export default React.memo(MessagingChannelListInNotification);
