import React, { useState, useEffect } from "react";
import Select from "react-select";

import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";

// アイコン
import AgricultureIcon from "@mui/icons-material/Agriculture";
import SailingOutlinedIcon from "@mui/icons-material/SailingOutlined";
import HardwareIcon from "@mui/icons-material/Hardware";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import FactoryIcon from "@mui/icons-material/Factory";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import ComputerIcon from "@mui/icons-material/Computer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import HotelIcon from "@mui/icons-material/Hotel";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import SchoolIcon from "@mui/icons-material/School";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import ChurchIcon from "@mui/icons-material/Church";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import { useDocumentData } from "react-firebase-hooks/firestore";

const IndustryMenu = ({ handleChange ,indust}) => {
  const [industries, setindustries] = useState([]);
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  useEffect(() => {
    
    const setindustry = async () => {
      let object = {};
      let count;
      const queryshot = await getDoc(doc(db, "env", "IndustryClassification"));
      count = queryshot.data()["IndustryClassificationBig"].length;
      if (industries.push() < count) {
        queryshot.data()["IndustryClassificationBig"].forEach((opt, index) => {
          // object = { value: opt, label: opt, name: "job" };
          object = {
            value: opt,
            label: (
              <>
                <IndustryLabel opt={opt} />
              </>
            ),
            name: "job",
          };
          industries.push(object);
        });
      }
    };
    setindustry();
  }, []);

  const IndustryLabel = ({ opt }) => {
    const industryIcon = {
      "農業，林業": AgricultureIcon,
      漁業: SailingOutlinedIcon,
      "鉱業，採石業，砂利採取業": HardwareIcon,
      建設業: SquareFootIcon,
      製造業: FactoryIcon,
      "電気・ガス・熱供給・水道業": EnergySavingsLeafIcon,
      情報通信業: ComputerIcon,
      "運輸業，郵便業": LocalShippingIcon,
      "卸売業，小売業": StoreIcon,
      "金融業，保険業": MonetizationOnIcon,
      "不動産業，物品賃貸業": HolidayVillageIcon,
      "学術研究，専門・技術サービス業": LibraryBooksIcon,
      "宿泊業，飲食サービス業": HotelIcon,
      "生活関連サービス業，娯楽業": ContentCutIcon,
      "教育，学習支援業": SchoolIcon,
      "医療，福祉": LocalHospitalIcon,
      複合サービス事業: LocalPostOfficeIcon,
      "サービス業（他に分類されないもの）": ChurchIcon,
      "公務（他に分類されるものを除く）": CorporateFareIcon,
      分類不能の産業: DevicesOtherIcon,
    };

    const Icon = ({ Icon }) => {
      return <>{Icon && <Icon className="h-4 w-4" />}</>;
    };

    return (
      <>
        <div className="flex items-center space-x-2 rounded-md">
          <Icon Icon={industryIcon[opt]} />
          <span>{opt}</span>
        </div>
      </>
    );
  };

  return (
    <>
      <Select
        options={industries}
        // isMulti
        value={indust}
        placeholder={user?.job || "主な職業を選択してください"}
        onChange={handleChange}
      />
    </>
  );
};

export default IndustryMenu;
