import React, { useState, useEffect, useRef } from "react";
import { getDoc, doc, updateDoc } from "firebase/firestore";

import {
  ref as storageRef, // storageのrefをstorageRefとしてインポート
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage"; // storage関連の関数はこちらからインポート

import { db, auth, storage } from "../api/firebase";
import PicLoaderW40 from "./PicLoaderW40";
import Camera from "../data/svg/Camera";
import heic2any from "heic2any";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert"; // Alertを利用してSnackbarの見た目を向上させる
import CircularProgress from "@mui/material/CircularProgress"; // ローディングスピナーのために追加
// import { CelebrationAnimation } from './CelebrationAnimation';

const ProfilePicture = ({ noEdit, id }) => {
  const [user, setUser] = useState(null);
  const [img, setImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const imgPickerRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbarの表示状態管理

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = await getDoc(doc(db, "users", id));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchUser();
    }
  }, [id]);

  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbarのメッセージを動的に管理
  const [conversionLoading, setConversionLoading] = useState(false); // HEIC変換のローディング状態

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected");
      return;
    }
    // console.log(
    //   `File selected: ${file.name}, size: ${file.size}, type: ${file.type}`
    // );

    const allowedTypes = ["image/jpeg", "image/png", "image/heic"];
    if (!allowedTypes.includes(file.type)) {
      setSnackbarMessage("許可されていないファイルタイプです。");
      setOpenSnackbar(true);
      return;
    }

    let processedFile = file;
    if (file.type === "image/heic") {
      setConversionLoading(true);
      try {
        console.log("Converting HEIC to JPEG...");
        processedFile = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
        });
        console.log("Conversion successful");
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        setSnackbarMessage("HEICファイルの変換に失敗しました。");
        setOpenSnackbar(true);
        setConversionLoading(false);
        return;
      }
      setConversionLoading(false);
    }

    // Proceed with resized image
    try {
      if (processedFile.size > 1024 * 1024) {
        processedFile = await resizeImage(processedFile);
      }
      setImg(processedFile);
      setSnackbarMessage("プロフィール画像が変更されました。");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error processing file:", error);
      setSnackbarMessage("画像のリサイズに失敗しました。");
      setOpenSnackbar(true);
    }
  };
  const resizeImage = async (file) => {
    console.log("Starting resize process...");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const max_width = 800;
          const scaleSize = max_width / img.width;
          canvas.width = max_width;
          canvas.height = img.height * scaleSize;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          ctx.canvas.toBlob(
            (blob) => {
              const resizedFile = new File([blob], file.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              console.log("Resize process completed.");
              resolve(resizedFile);
            },
            "image/jpeg",
            0.7
          );
        };
        img.onerror = (error) => {
          console.error("Error loading image for processing:", error);
          reject("Failed to load image for resizing.");
        };
      };
      reader.onerror = (error) => {
        console.error("Error reading file for resizing:", error);
        reject(error);
      };
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState('');

  const handleEvent = async (eventType) => {
    // eventTypeに基づいた処理...
    // 県の登録、DDPの登録など
    setCelebrationMessage("プロフィール画像が変更されました！"); // メッセージの例
    setIsOpen(true);
    // setTimeout(() => setIsOpen(false), 3000); // 3秒後にアニメーションを非表示にする
  };


  useEffect(() => {
    if (img instanceof Blob) {
      setIsLoading(true);
      const uploadImg = async () => {
        const imgRef = storageRef(
          storage,
          `avatar/${new Date().getTime()} - ${img.name}`
        );
        try {
          const snap = await uploadBytes(imgRef, img);
          const url = await getDownloadURL(snap.ref);

          const userDoc = doc(db, "users", auth.currentUser.uid);
          const docSnap = await getDoc(userDoc);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (!userData.isRegisterAvatar) {
              await updateDoc(userDoc, {
                isRegisterAvatar: true,
                avatar: url,
                avatarPath: snap.ref.fullPath,
              });
              // ここでお祝いのメッセージを表示
              // alert("おめでとうございます！居住県が登録されました！");
              handleEvent("prefectureRegistration");
            } else {
              await updateDoc(doc(db, "users", auth.currentUser.uid), {
                avatar: url,
                avatarPath: snap.ref.fullPath,
              });
            }
          }

        //   await updateDoc(doc(db, "users", auth.currentUser.uid), {
        //     avatar: url,
        //     avatarPath: snap.ref.fullPath,
        //   });

          // 画像アップロードが成功した後で古い画像を削除
          if (user && user.avatarPath) {
            const oldRef = storageRef(storage, user.avatarPath);
            await deleteObject(oldRef).catch((err) => {
              console.error("Error deleting old image: ", err.message);
              // 古い画像の削除に失敗しても、新しい画像のURLを保存
            });
          }

          setUser((prevUser) => ({
            ...prevUser,
            avatar: url,
            avatarPath: snap.ref.fullPath,
          }));

          setOpenSnackbar(true); // 画像アップロード成功時にSnackbarを表示
          setImg("");
          setIsLoading(false);
        } catch (err) {
          console.error("Error uploading image: ", err.message);
          setIsLoading(false);
        }
      };
      uploadImg();
    }
  }, [img, user]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // Snackbarを閉じる
  };

  if (isLoading || conversionLoading) {
    // 変換中またはアップロード中のローディングをチェック
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="text-white flex flex-col items-center">
          <CircularProgress color="inherit" />
          <p>
            {conversionLoading ? "画像を変換中..." : "プロフィール更新中..."}
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`${
          noEdit ? "pointer-events-none" : "cursor-pointer hover:opacity-50"
        } flex justify-center items-center w-full h-4/5 group mt-[-60px] sm:mt-[-120px]`}
      >
        <div className="relative">
          <img
            src={user?.avatar || <PicLoaderW40 />}
            alt=""
            className="w-40 h-40 sm:w-60 sm:h-60 rounded-full border-4 items-center object-cover"
            onClick={() => imgPickerRef.current.click()}
          />
          <div
            className="absolute inset-0 hidden group-hover:flex items-center justify-center text-black opacity-80 hover:opacity-100 duration-300"
            onClick={() => imgPickerRef.current.click()}
          >
            <Camera />
          </div>
        </div>
        <input
        className=" z-50"
          ref={imgPickerRef}
          onChange={handleFileChange}
          accept=".jpg,.png,.heic"
          type="file"
          hidden
        />
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning" // 成功ではなく警告として表示
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* <CelebrationAnimation isOpen={isOpen} setIsOpen={setIsOpen} message={celebrationMessage} /> */}

    </>
  );
};

export default ProfilePicture;
