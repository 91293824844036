import { query, collection, where, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../api/firebase";
import DisplayResidentUser from "./DisplayResidentUser";

const ResidentList = ({ base }) => {
  //   console.log("base =>", base);

  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    // usersコレクションから条件に合致するドキュメントをクエリ
    const q = query(
      collection(db, "users"),
      // where("displayPrefecture", "==", true),
      where("prefecture", "==", base?.prefecture)
    );

    getDocs(q)
      .then((querySnapshot) => {
        const userList = [];
        querySnapshot.forEach((doc) => {
          // ドキュメントからデータを取得
          const userData = doc.data();
          // console.log("userData =>",userData)
          if (userData?.isVillager) {
            userList.push(userData);
          }
        });
        setUsersList(userList);
      })
      .catch((error) => {
        console.error("ユーザリストの取得に失敗しました。", error);
      });
  }, []);

  return (
    <>
      {usersList?.map((elm, key) => (
        <DisplayResidentUser user={elm} key={key} />
      ))}
    </>
  );
};

export default ResidentList;
