import React from "react";
import TestNotification from "./TestNotification";
import FirebaseNotification from "./FirebaseNotification";

const OperatePushNotification = () => {
  return (
    <>
      <FirebaseNotification />

      <TestNotification />
    </>
  );
};

export default OperatePushNotification;
