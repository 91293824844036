import React from "react";
import HeaderContainerMixIN from "../components/HeaderContainerMixIN";
import Movie from "./Movie";

const MovieContainer = () => {
  return (
    <>
      <HeaderContainerMixIN outlet={<Movie /> } />

    </>
  );
};

export default MovieContainer;
