import { AvatarGroup, Avatar, Tooltip } from "@mui/material";
import React from "react";
import getAvatarUrl from "./getAvatarUrl";

const EventLikeActionMembersAvatar = ({ members }) => {
  return (
    <>

      {members && (
        <AvatarGroup total={members?.length} max={5} key={Math.random()}>
          {members?.map((elm) => (
            <div key={Math.random()}>
              <Tooltip title={elm.lastName + elm.firstName} >
                <Avatar
                  sx={{ width: 28, height: 28 }}
                  alt={elm?.lastName + elm?.firstName}
                  src={elm?.avatar}
                />
              </Tooltip>
            </div>
          ))}
        </AvatarGroup>
      )}
    </>
  );
};

export default EventLikeActionMembersAvatar;
