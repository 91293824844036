import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../api/firebase";
import { InterfaceMap, intefaceM } from "../components/GoogleMapStyle";

import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import { Button } from "@mui/material";
import HorizontalMenuAtMap from "../components/HorizontalMenuAtMap";
import RequestShopDrawer from "../components/RequestShopDrawer";
import CheckSize from "../components/CheckSize";

import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
const Map = ({ type }) => {
  // shopリスト
  const [shops, setShops] = useState([]);

  useEffect(() => {
    const initShops = () => {
      let arr = [];
      let q = "";
      switch (type) {
        case "korakuen":
          q = query(
            collection(db, "requestShop"),
            where("type", "==", type),
            where("isPublish", "==", true),
            where("isPermission", "==", true)
          );
          getDocs(q).then((shops) => {
            shops.forEach((shop) => {
              arr.push({ id: shop.id, ...shop.data() });
            });
            setShops(arr);
          });
          break;
        case "userShop":
          q = query(
            collection(db, "requestShop"),
            where("type", "==", type),
            where("isPublish", "==", true),
            where("isPermission", "==", true)
          );
          getDocs(q).then((shops) => {
            shops.forEach((shop) => {
              arr.push({ id: shop.id, ...shop.data() });
            });
            setShops(arr);
          });
          break;
        case "lendField":
          q = query(
            collection(db, "recommendedSpot"),
            where("type", "==", type),
            where("isPublish", "==", true),
            where("isPermission", "==", true)
          );
          getDocs(q).then((shops) => {
            shops.forEach((shop) => {
              arr.push({ id: shop.id, ...shop.data() });
            });
            setShops(arr);
          });
          break;
        case "lendOldHouse":
          q = query(
            collection(db, "recommendedSpot"),
            where("type", "==", type),
            where("isPublish", "==", true),
            where("isPermission", "==", true)
          );
          getDocs(q).then((shops) => {
            shops.forEach((shop) => {
              arr.push({ id: shop.id, ...shop.data() });
            });
            setShops(arr);
          });
          break;
        case "powerSpot":
          q = query(
            collection(db, "recommendedSpot"),
            where("type", "==", type),
            where("isPublish", "==", true),
            where("isPermission", "==", true)
          );
          getDocs(q).then((shops) => {
            shops.forEach((shop) => {
              arr.push({ id: shop.id, ...shop.data() });
            });
            setShops(arr);
          });
          break;
        case "naturalFood":
          q = query(
            collection(db, "recommendedSpot"),
            where("type", "==", type),
            where("isPublish", "==", true),
            where("isPermission", "==", true)
          );
          getDocs(q).then((shops) => {
            shops.forEach((shop) => {
              arr.push({ id: shop.id, ...shop.data() });
            });
            setShops(arr);
          });
          break;
        case "sekaimuraBase":
          q = query(
            collection(db, "base"),
            where("releaseAddress", "==", true)
          );
          getDocs(q).then((shops) => {
            shops.forEach((shop) => {
              const data = shop.data();
              if (
                data?.releaseAddress
                // data.lat !== null &&
                // data.lng !== null &&
                // "lat" in data &&
                // "lng" in data
              ) {
                arr.push({ id: shop.id, type: "sekaimuraBase", ...data });
              }
            });
            setShops(arr);
          });

          break;
        case "alternativeMedicine":
          q = query(
            collection(db, "recommendedSpot"),
            where("type", "==", type),
            where("isPublish", "==", true),
            where("isPermission", "==", true)
          );
          getDocs(q).then((shops) => {
            shops.forEach((shop) => {
              arr.push({ id: shop.id, ...shop.data() });
            });
            setShops(arr);
          });
          break;

        default:
          break;
      }
      q = query(
        collection(db, "requestShop"),
        where("industry", "==", type),
        where("isPublish", "==", true),
        where("isPermission", "==", true)
      );

      getDocs(q).then((shops) => {
        shops.forEach((shop) => {
          arr.push({ id: shop.id, ...shop.data() });
        });
        setShops(arr);
      });
    };
    type && initShops();
  }, [type]);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // マーカーがクリックされたときのハンドラ
  const handleMarkerClick = (marker) => {
    setOpen(true);
    setSelectedMarker(marker);
  };

  // インフォウィンドウが閉じられたときのハンドラ
  const handleInfoWindowClose = () => {
    setOpen(!open);
    setSelectedMarker(null);
  };

  const options = {
    disableDefaultUI: true,
    styles: intefaceM,
    gestureHandling: "greedy",
  };

  const iconList = {
    lendField: { icon: "貸田畑" },
    lendOldHouse: { icon: "貸古民家" },
    powerSpot: { icon: "パワースポット" },
    naturalFood: { icon: "自然食品" },
    alternativeMedicine: { icon: "代替医療" },
  };

  const navigate = useNavigate();
  // フローティングボタンがクリックされたときのハンドラ
  const handleFloatingButtonClick = () => {
    navigate("/shopApplication");
  };

  // console.log("shop =>", shops);

  // console.log("shops =>", shops)

  return (
    <>
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{ lat: 35.681848125631326, lng: 139.76718648196314 }}
        options={options}
      >
        {/* {shops?.map((shop) => (
          <Marker
            key={shop.id}
            position={{
              // lat:shop?.lat,
              // lng:shop?.lng,
              lat: shop?.useDummyGeoPoint ? shop?.dummyLat : shop?.lat,
              lng: shop?.useDummyGeoPoint ? shop?.dummyLng : shop?.lng,
            }}
            onClick={() => handleMarkerClick(shop)}
          // icon={Img}
          />
        ))} */}

        {shops?.map((shop) => {
          const lat = parseFloat(
            shop?.useDummyGeoPoint ? shop?.dummyLat : shop?.lat
          );
          const lng = parseFloat(
            shop?.useDummyGeoPoint ? shop?.dummyLng : shop?.lng
          );

          // lat と lng が有効な数値であるかチェック
          if (!isNaN(lat) && !isNaN(lng)) {
            return (
              <Marker
                key={shop.id}
                position={{ lat, lng }}
                onClick={() => handleMarkerClick(shop)}
                // icon={Img} // 必要に応じてアイコンを追加
              />
            );
          } else {
            // lat または lng が無効な場合は、何もレンダリングしない（またはエラーログを出力）
            console.error("Invalid lat or lng:", shop);
            return null;
          }
        })}

        {/* 選択されたマーカーの情報を表示 */}
        {selectedMarker && (
          <RequestShopDrawer
            key={selectedMarker}
            value={open}
            handleClose={handleClose}
            shop={selectedMarker}
          />
        )}

        {/* お店の申請・紹介ボタン */}
        <div className="fixed bottom-20 right-5 cursor-pointer ">
          {/* ボタンのアイコンやテキストを追加 */}
          <Button
            onClick={handleFloatingButtonClick}
            variant="contained"
            endIcon={<SendIcon />}
          >
            お店の紹介はこちら
          </Button>
        </div>
      </GoogleMap>
    </>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default function SekaimuraMap() {
  const [type, setType] = useState("");
  const handleTypeClick = (type) => {
    setType(type);
  };

  return (
    <>
      {/* <CheckSize /> */}
      <div className="grid grid-cols-1 w-full bg-white mb-5">
        <HorizontalMenuAtMap onClick={handleTypeClick} type={type} />
      </div>
      <div className="w-full h-[60vh] sm:h-[78vh] p-3 border bg-white">
        <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          type={type}
        />
      </div>
    </>
  );
}

// https://tomchentw.github.io/react-google-maps/
