import React from "react";
import LexicalEditor from "./LexicalEditor";

const EditorRoom = () => {
  return (
    <>
      <LexicalEditor />
    </>
  );
};

export default EditorRoom;
