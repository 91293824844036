import React, { useState } from "react";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Avatar, Button } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";

// redux - use
import { useNotification } from "../redux/useNotification";
import { useSnackbar } from "../redux/useSnackBar";

const ReplyPostComment = ({ setOpen, requestId, target }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // console.log("target =>", target);
  // console.log("target =>", target?.post.id);

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    message: "",
    errorMessage: "",
    error: null,
    loading: false,
  });
  const { message, errorMessage, error, loading } = data;

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // console.log("message =>", message);
  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, errorMessage: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, errorMessage: "空欄なようです" });
        return false;
      }

      // 変更処理
      await addDoc(collection(db, "replyPostComment"), {
        message,
        avatar: user?.avatar,
        name: user?.lastName + user?.firstName,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        creator: user,
        creatorId: auth.currentUser.uid,
        isPublish: true,
        commentId: target?.id,
        comment: target,
      });

      // postのupdateも更新
      await updateDoc(doc(db, "post", target?.post.id), {
        updatedAt: serverTimestamp(),
        reason: "",
      });



      // コメントのupdateも更新
      await updateDoc(doc(db, "comment", target?.id), {
        updatedAt: serverTimestamp(),
        reason: "",
      });


      // await addDoc(collection(db, "replyPostComment"), {
      //   message,
      //   avatar: user?.avatar,
      //   name: user?.lastName + user?.firstName,
      //   createdAt: serverTimestamp(),
      //   updatedAt: serverTimestamp(),
      //   creator: user,
      //   creatorId: auth.currentUser.uid,
      //   isPublish: true,
      //   commentId: target?.id,
      //   comment: target,
      // });

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      // window.location.reload();
      setOpen(false);

      displayNotification({
        message: "返信しました。",
        type: "success",
      });
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
      const errorData = {
        message: err.message,
        stack: err.stack,
      };
      addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: errorData,
        component: "ReplyPostComment.js",
      });
    }
  };

  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };
  // console.log("target =>", target);

  if (!target) {
    return <div>この投稿は表示できません。</div>;
  }

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">返信</div>
        <div className="flex space-x-2 mb-2">
          <div>
            <Avatar
              src={target.avatar}
              sx={{ width: "24px", height: "24px" }}
            />
          </div>
          <div className="flex flex-col bg-gray-100 p-2 rounded-md  ">
            <div className="text-xs text-blue-500 font-bold">{target.name}</div>
            <div className="">{target.message}</div>
          </div>
        </div>
        <textarea
          style={{ resize: "none", fontSize: "16px", background: "white" }}
          type="text"
          name="message"
          className="textarea textarea-bordered w-full h-60 p-1"
          onChange={handleChange}
        ></textarea>

        <Button
          className="btn"
          name="message"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "返信"}
        </Button>
        {errorMessage ? (
          <div className=" text-red-500">{errorMessage}</div>
        ) : (
          ""
        )}
        {error ? error : ""}
      </form>
    </>
  );
};

export default ReplyPostComment;
