import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// 写真
import friends from "../data/img/frends.jpg";
import smilewWork from "../data/img/smileWork.jpg";
import { useAuth } from "../context/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import RegisterVillager from "./RegisterVillager";

export default function FirstGuide({ setOpen }) {
  // ユーザ取得
  const { user } = useAuth();
  const [userInfo, setUserInfo] = React.useState();
  React.useEffect(() => {
    const initUser = () => {
      getDoc(doc(db, "users", user.uid)).then((doc) => {
        setUserInfo(doc.data());
      });
    };
    initUser();
  }, [user]);

  const createStep = (label, explain, img, link) => {
    return {
      label: <div className="font-bold text-2xl">{label}</div>,
      description: (
        <div className="flex flex-col space-y-2 h-full">
          <img className="rounded-md" src={img} alt="" />
          <div>
            {explain}
            {link}
          </div>
        </div>
      ),
    };
  };

  const steps = [
    createStep(
      "ようこそ、セカイムラへ",
      "セカイムラユーザ登録ありがとうございます。",
      friends
    ),
    createStep(
      "ようこそ、セカイムラへ",
      "セカイムラはDDP（明確な夢）をナカマとシェアし合うコミュニティです。",
      friends
    ),

    createStep(
      "DDPの交換",
      "セカイムラは自分の好きな事をするだけで、ナカマの願いを叶えることができる空間です。",
      smilewWork
    ),

    createStep(
      "DDPの交換",
      "セカイムラではそれをDDPの交換と呼んでいます。",
      smilewWork
    ),

    createStep(
      "DDPの交換",
      "セカイムラの村民になるとDDPの交換が可能になります。",
      smilewWork
    ),
    createStep(
      "セカイムラ村民は",
      "セカイムラの村民はその他グループを作って情報をやり取りしたり、イベントを作成して広報したり、",
      smilewWork
    ),
    createStep(
      "セカイムラ村民は",
      "チャット機能でワイワイと会話ができたり、セカイムラ独自の動画を通して専門知識を習得したり、",
      smilewWork
    ),
    createStep(
      "セカイムラ村民は",
      "一定の畑・住居・有益な情報を近辺在住のナカマや他の拠点とナカマとシェアしたりすることができます。（整備中）",
      smilewWork
    ),
    createStep(
      "セカイムラ村民は",
      "セカイムラの機能をフルでご使用頂くために、ぜひ村民登録の申込みをお願い申し上げます。",
      smilewWork,
      <RegisterVillager />,
      ),
  ];

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const updateFirstLoginInfoComplete = async () => {
    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      firstLoginInfoComplete: true,
    });
    setOpen(false);
  };

  const moveToSekaimura = () => {
    window.open("https://www.sekaimura.com/", "_blank");
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <div className="w-full">{steps[activeStep].label}</div>
      </Paper>
      <Box sx={{ height: 255, maxWidth: 400, width: "100%", p: 2 }}>
        {steps[activeStep].description}
      </Box>

      <MobileStepper
        sx={{ marginTop: "20px" }}
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            // disabled={activeStep === maxSteps - 1}
          >
            {activeStep !== maxSteps - 1 ? (
              "次へ"
            ) : (
              <div>
                <Button
                  onClick={updateFirstLoginInfoComplete}
                  variant="contained"
                >
                  閉じる
                </Button>
              </div>
            )}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : activeStep !== maxSteps - 1 ? (
              <KeyboardArrowRight />
            ) : (
              ""
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            前へ
          </Button>
        }
      />
    </Box>
  );
}
