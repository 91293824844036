import React from "react";
import { doc, collection } from "firebase/firestore";
import { db } from "../api/firebase";

import {
  useCollection,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { Avatar } from "@mui/material";

const DisplayInquiryHistory = ({ setOpen, target }) => {
  // history取得
  const [inquiryHistory] = useCollection(
    collection(db, "questionForAdmin", target, "history")
  );
  const history = inquiryHistory?.docs?.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  history?.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  });

  // 結果
  const [questionForAdminDoc] = useDocumentData(
    doc(db, "questionForAdmin", target)
  );
  // const history = inquiryHistory?.docs?.map((doc) => {
  //   return { id: doc.id, ...doc.data() };
  // });
  // history?.sort((a, b) => {
  //   if (a.createdAt > b.createdAt) return -1;
  //   if (a.createdAt < b.createdAt) return 1;
  //   return 0;
  // });

  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  return (
    <>
      <div className=" my-2 bg-green-600 p-1 text-white w-fit">結果</div>
      <div className=" text-blue-500 text-xs">
        {isInvalidDate(new Date(questionForAdminDoc?.respondentAt.toDate()))
          ? "読み込み中"
          : formatDistanceToNow(questionForAdminDoc?.respondentAt?.toDate(), {
              locale: ja,
            })}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div>
          <Avatar src={questionForAdminDoc?.respondentAvtar} sizes="small" />
        </div>
        <div>{questionForAdminDoc?.respondentName}</div>
      </div>
      <div className="border bg-blue-500 text-white rounded-md p-1">
        {questionForAdminDoc?.result}
      </div>

      <div className=" my-2 bg-green-600 p-1 text-white w-fit">履歴</div>
      <div className=" h-80 overflow-y-auto">
        {history?.map((elm) => (
          <>
            <div className="border rounded-md p-2 my-2">
              <div className=" text-blue-500 text-xs">
                {isInvalidDate(new Date(elm?.createdAt?.toDate()))
                  ? "読み込み中"
                  : formatDistanceToNow(elm?.createdAt?.toDate(), {
                      locale: ja,
                    })}
              </div>

              <div className="flex flex-col items-center justify-center">
                <div>
                  <Avatar src={elm.updatorAvatar} sizes="small" />
                </div>
                <div>{elm.updatorName}</div>
              </div>
              <div className="border bg-blue-500 text-white rounded-md p-1">
                {elm.content}
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default DisplayInquiryHistory;
