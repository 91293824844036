import React, { useState } from "react";
import { TextField } from "@mui/material";

import { addDoc, collection, doc, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
// redux - use
import { useNotification } from "../redux/useNotification";

const AddResultType = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const [product, setProduct] = useState({
    result: "",
    error: "",
    loading: false,
  });
  const { result, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });
    try {
      // 変更処理
      const docRef = collection(db, "inquiryResultType");
      await addDoc(docRef, {
        registered: user?.uid,
        registeredName: user?.lastName + user?.firstName,
        registeredAvatar: user?.avatar,
        isPublish: true,
        result,
        createdAt: serverTimestamp(),
      });

      displayNotification({
        message: "結果の種類を追加しました。",
        type:"success"
      });
      // const docRef = await addDoc(collection(db, "ddp", match, "targets"), {
      //   [target]: {},
      // });

      setProduct({
        groupType: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">結果の追加</div>
        <form onSubmit={setRequestDataShop}>
          {/* 提供する対象 */}
          <TextField
            id="outlined-required"
            name="result"
            label="結果"
            defaultValue=""
            size="small"
            placeholder="チャットで話し合いの結果、解決しました。"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
          ></TextField>

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
          >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AddResultType;
