import React, { useEffect, useState } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { Avatar, Divider, Tooltip } from "@mui/material";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import Box from "@mui/material/Box";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import WebIcon from "@mui/icons-material/Web";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";
import defaultBackImg from "../data/img/defaultImage.png";
import { Image } from "@mui/icons-material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import Images from "./Images";
import UserCard from "./UserCard";
import { Typography } from "@mui/material";
import moment from "moment";
import ImagesOnMap from "./ImagesOnMap";
import { useAuth } from "../context/auth";
import DisplaySignUp from "./DisplaySignUp";
import GetUserInfo from "./GetUserInfo";

const DisplayMyShopOnMap = ({ shop }) => {
  const { user } = useAuth();

  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const handleMove = () => {
    navigate("/mapPoint/" + shop?.id);
  };

  const typeList = {
    lendField: { name: "貸田畑" },
    lendOldHouse: { name: "貸古民家" },
    powerSpot: { name: "パワースポット" },
    naturalFood: { name: "自然食品" },
    alternativeMedicine: { name: "代替医療" },
    sekaimuraBase: { name: "拠点" },
  };

  console.log("shop =>", shop);

  return (
    <>
      <div className=" overflow-y-auto scrollbar-hide">
        {/* ショップ名、バックグラウンド */}
        <div className="relative  md:mt-20">
          {/* イメージ */}
          <img
            src={shop.background || defaultBackImg}
            className="w-full h-60"
            alt=""
          />
          {/* オーナー */}
          {shop?.owner && (
            <div className=" absolute bottom-2 right-2">
              <div className="bg-white  opacity-80 text-opacity-0 font-bold text-black rounded-md">
                <UserCard id={shop?.owner} />
              </div>
              {/* <Avatar src={owner?.avatar} /> */}
            </div>
          )}
          {/* 何時間前に作成したか */}
          <Typography
            variant="body2"
            component="span"
            sx={{
              position: "absolute",
              bottom: 6,
              left: 6,
              color: "white",
              background: "rgba(0,0,0, .3)",
              p: "5px",
              borderRadius: 2,
            }}
          >
            {moment(shop?.createdAt?.toDate()).fromNow()}
          </Typography>
          {/* 名前 */}
          <div className=" absolute top-2 left-2  rounded-md bg-teal-700 text-white p-2 w-fit">
            {shop.name ? shop.name : shop.title}
          </div>
          {/* 種類 */}
          {shop.type !== "userShop" && shop.type !== "korakuen" && (
            <div className=" absolute top-2 right-4 opacity-75 rounded-lg bg-black text-white p-2 w-fit">
              {typeList[shop?.type].name}
            </div>
          )}
        </div>
        {/* コンテンツ */}
        <div className="grid grid-cols-1 ">
          <div className=" col-span-1 w-full">
            <TabContext value={value}>
              <div className="sticky top-0 bg-white z-50">
                <Box
                  sx={{
                    maxWidth: { xs: 375, sm: 1080 },
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={true}
                    allowScrollButtonsMobile
                    sx={{ backgroud: "#FFFFFF" }}
                  >
                    <Tab label="概要" value="1" />
                    <Tab label="写真" value="2" />
                    {/* <Tab label="営業時間" value="4" />
                    <Tab label="口コミ" value="5" /> */}
                  </TabList>
                </Box>
              </div>

              {/* 概要 */}
              <TabPanel sx={{ width: "100%", padding: 0 }} value="1">
                <div>
                  {/* 住所 */}
                  {shop?.useDummyGeoPoint ? <a
                      href={`https://www.google.com/maps/place/${shop?.dummyLat},${shop?.dummyLng}`}
                    target="_blank"
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <HomeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={shop?.dummyAddress}
                        secondary=
                        "ポイント(住所)はダミーで近隣です。"

                      />
                    </ListItem>
                  </a>
                    : <a
                      href={"https://www.google.com/maps?q=" + shop?.address}
                      target="_blank"
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <HomeIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={shop?.address}
                        />
                      </ListItem>
                    </a>
                  }
                  {/* 電話 */}
                  {shop?.tel && (
                    <a href={"tel:" + shop?.tel}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <CallIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={shop?.tel} secondary="" />
                      </ListItem>
                    </a>
                  )}
                  {/* URL */}
                  {shop?.url && (
                    <a href={shop?.url} target="_blank">
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <LinkIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={shop?.url} secondary="" />
                      </ListItem>
                    </a>
                  )}
                  <Divider></Divider>
                  {/* ひとこと */}
                  {shop?.type === "sekaimuraBase" && shop?.greeting && (
                    <>
                      <div className="flex flex-col space-y-1 ml-5 mt-1 pr-5">
                        <div className="flex space-x-1 ">
                          {/* アバターと「推薦者」 */}
                          <div className="flex flex-col space-y-1 items-center">
                            <div>
                              <GetUserInfo
                                uid={shop?.chief}
                                target="avatar"
                                size={40}
                              />
                            </div>
                            <div className="text-xs text-gray-500">
                              村長
                            </div>
                          </div>
                          {/* 名前とメッセージ */}
                          <div className="flex flex-col space-y-1">
                            <div className="font-bold">
                              <GetUserInfo uid={shop?.chief} target="name" />
                            </div>
                            <div className="bg-gray-200 p-3 rounded-md w-fit">
                              {shop?.greeting}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}


                  {/* ひとこと */}
                  {shop?.isPublishOnething && shop?.onething && (
                    <>
                      <div className="flex flex-col space-y-1 ml-5 mt-1">
                        <div className="flex space-x-1 ">
                          {/* アバターと「推薦者」 */}
                          <div className="flex flex-col space-y-1 items-center">
                            <div>
                              <GetUserInfo
                                uid={shop?.owner}
                                target="avatar"
                                size={40}
                              />
                            </div>
                            <div className="text-xs text-gray-500">
                              オーナー
                            </div>
                          </div>
                          {/* 名前とメッセージ */}
                          <div className="flex flex-col space-y-1">
                            <div className="font-bold">
                              <GetUserInfo uid={shop?.owner} target="name" />
                            </div>
                            <div className="bg-gray-200 p-3 rounded-md w-fit">
                              {shop?.onething}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* ひとこと */}
                  {shop?.isPublishRecomendedPoint && shop?.recomendedPoint && (
                    <>
                      <div className="flex flex-col space-y-1 ml-5 mt-1">
                        <div className="flex space-x-1 ">
                          {/* アバターと「推薦者」 */}
                          <div className="flex flex-col space-y-1 items-center">
                            <div>
                              <GetUserInfo
                                uid={shop?.creator}
                                target="avatar"
                                size={40}
                              />
                            </div>
                            <div className="text-xs text-gray-500">
                              推薦者
                            </div>
                          </div>
                          {/* 名前とメッセージ */}
                          <div className="flex flex-col space-y-1">
                            <div className="font-bold">
                              <GetUserInfo uid={shop?.creator} target="name" />
                            </div>
                            <div className="bg-gray-200 p-3 rounded-md w-fit">
                              {shop?.recomendedPoint}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="h-96"></div>
                </div>
              </TabPanel>

              {/* 写真 */}
              <TabPanel sx={{ width: "100%", padding: 0 }} value="2">
                {shop?.type === "userShop" && (
                  <ImagesOnMap
                    targetCollection="requestShop"
                    shopId={shop?.id}
                    noOption
                  />
                )}
                {shop?.type in typeList && (
                  <ImagesOnMap
                    targetCollection="recommendedSpot"
                    shopId={shop?.id}
                    noOption
                  />
                )}
              </TabPanel>

              {/* 営業時間 */}
              <TabPanel sx={{ width: "100%", padding: 0 }} value="4"></TabPanel>

              {/* 口コミ */}
              <TabPanel sx={{ width: "100%", padding: 0 }} value="5"></TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayMyShopOnMap;
