import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../api/firebase";
import DisplayDDP from "./DisplayDDP";

const UserDDPs = ({ id }) => {
  // ddpたち取得
  const [ddps, setDdps] = useState();
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "products"),
        where("uid", "==", id),
        where("isActive", "==", true),
        where("isPublish", "==", true),
        orderBy("createdAt", "desc")
        // limit(10)
      );

      let arr = [];
      await getDocs(q).then((docs) =>
        docs?.forEach((elm) => arr.push({ id: elm.id, ...elm.data() }))
      );
      setDdps(arr);
    };
    init();
  }, []);

//   console.log("ddps =>", ddps);
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2   gap-4 bg-white">
        {ddps?.map((elm) => (
          <DisplayDDP ddp={elm} key={Math.random()} />
        ))}
      </div>
      {ddps?.length === 0 && <div>登録されたDDPはまだありません。</div>}
    </>
  );
};

export default UserDDPs;
