import { collection, limit, orderBy, query } from "firebase/firestore";
import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import AddComponent from "./AddComponent";

const OperateTerms = () => {
  const termsQuery = query(
    collection(db, "terms"),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const [termsCollection] = useCollectionData(termsQuery)
  const terms = termsCollection?.map(doc =>{
    return doc
  })

  // console.log("terms =>", terms);
  return (
    <>
      <div className="text-gray-300">利用規約</div>
      <AddComponent value="利用規約を追加" target="addTerm" />
      {terms?.length ? (
        <div className="flex flex-col">
          <div className="font-bold text-xl">最新の利用規約</div>
          <div className="grid grid-col-7">
            {terms && (
              <>
                <div>{terms[0]?.title}</div>
                <div>{terms[0]?.content}</div>
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default OperateTerms;
