import React from 'react';
import { Avatar, DialogContent, Skeleton } from "@mui/material";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import Linkify from "linkify-react";
import DotClickMenuAtPost from "./DotClickMenuAtPost";
import LikeAction from "./LikeAction";
import PostComments from "./PostComments";
import ImageRenderer from "./ImageRenderer";
import InputPostComment from "./InputPostComment";
import GetUserPrefecture from "./GetUserPrefecture";

const DialogContentComponent = ({ post, postor, isAuthor, likeLoading, commentsLoading, handleMoveToUser }) => {
  const linkifyOptions = { className: "text-blue-400", target: "blank" };

  return (
    <DialogContent dividers style={{ overflowY: 'auto', padding: 0 }}>
      <div className="w-full">
        <div className="flex justify-between border-x border-t rounded-t-md p-3 bg-white">
          <div className="flex space-x-2 items-center">
            <Avatar src={postor?.avatar} />
            <div>
              <div className="flex space-x-1 items-center">
                <div className="font-bold whitespace-pre-wrap break-words line-clamp-1 cursor-pointer hover:underline" onClick={handleMoveToUser}>
                  {postor?.lastName + postor?.firstName}
                </div>
                <div className="text-xs text-gray-500">
                  @<GetUserPrefecture userId={postor?.id} />
                </div>
              </div>
              {post?.updatedAt && (
                <div className="text-gray-300 text-xs">
                  {formatDistanceToNow(post?.updatedAt.toDate(), { locale: ja })} 前
                </div>
              )}
            </div>
          </div>
          <div className="p-2">
            {isAuthor && <DotClickMenuAtPost post={post} />}
          </div>
        </div>

        <div className="relative px-2 pt-3 border-x duration-150 whitespace-pre-wrap break-all leading-normal text-gray-600 bg-white">
          <Linkify options={linkifyOptions}>
            {post.message.replace(/[ \t]+/g, "").replace(/　/g, "")}
          </Linkify>
        </div>

        <div className="duration-150 border-x whitespace-pre-wrap break-words bg-white">
          <ImageRenderer images={post.photos} />
        </div>

        <div className="p-2 border-x bg-white">
          {likeLoading ? <Skeleton variant="rectangular" width="100%" height={30} /> : <LikeAction post={post} />}
        </div>

        <div className="border-x whitespace-pre-wrap break-words bg-white">
          {commentsLoading ? <Skeleton variant="rectangular" width="100%" height={100} /> : <PostComments post={post} />}
        </div>

        <div className="p-5 border-t rounded-b-md border-x border-b whitespace-pre-wrap break-words bg-white">
          <InputPostComment post={post} />
        </div>
      </div>
    </DialogContent>
  );
};

export default DialogContentComponent;
