import React, { useEffect, useState } from "react";
import {
  getDoc,
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";

// redux - use
import { useNotification } from "../redux/useNotification";

const AddBase = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // ユーザ取得関連
  const [base, setbase] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // リクエスト取得
  useEffect(() => {
    if (requestId) {
      getDoc(doc(db, "base", requestId)).then((doSnap) => {
        if (doSnap.exists) {
          setbase(doSnap.data());
        }
      });
    }
  }, [requestId]);

  // オーナーがいる場合は、オーナーに、それ以外はセカイムラ事務局に 2023.05.18
  const [owner, setOwner] = useState("");
  useEffect(() => {
    const initSetOwner = () => {
      if (base?.chief) {
        getDoc(doc(db, "users", base?.chief)).then((doc) => {
          setOwner({ id: doc.id, ...doc.data() });
        });
      } else {
        getDoc(doc(db, "users", "oRSoeN1rlVSiZmt6px68GyhgP6j1")).then((doc) => {
          setOwner({ id: doc.id, ...doc.data() });
        });
      }
    };

    base && initSetOwner();
  }, [base]);


  const [user, setUser] = useState("");
  useEffect(() => {
    const initSetUser = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    initSetUser();
  }, []);

  // console.log("group =>", group);
  // console.log("owner =>", owner);
  // console.log("user =>", user);

  //   更新処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await setDoc(
        doc(db, "base", requestId, "members", auth.currentUser.uid),
        {
          isMember: false,
          isOwner: false,
          createdAt: serverTimestamp(),
          uid: auth.currentUser.uid,
          isApplication: true,
        }
      );
      // 通知
      await addDoc(collection(db, "users", owner?.uid, "notification"), {
        createdAt: serverTimestamp(),
        content: "拠点参加希望",
        fromAvatar: user?.avatar,
        fromId: user?.uid,
        fromName: user?.lastName + user?.firstName,
        isRecognition: false,
        target: "base",
        targetId: requestId,
      });

      displayNotification({
        message: "拠点参参加申請受け付けました。",
        type: "success",
      });
      setOpen(false);
      window.location.reload();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        拠点参加
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="font-bold text-2xl">拠点に参加申請を送ります</div>
        <div className="border-2 p-2 rounded-md mb-2">
          <div className="text-gray-300">拠点</div>
          <div>{base?.title}</div>
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "参加申請中..." : "参加申請する"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default AddBase;
