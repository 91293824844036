import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { db, auth } from "../api/firebase";

import EventBackImage from "./EventBackImage";
import { Avatar, Button, Divider, Tooltip } from "@mui/material";
import EventExplain from "./EventExplain";
import EventTitle from "./EventTitle";
import EventTime from "./EventTime";

import { useAuth } from "../context/auth";

import { memo } from "react";
import SNSList from "./SNSList";
import { TextareaAutosize } from "@mui/material";

// icon
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import UndoIcon from "@mui/icons-material/Undo";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CampaignIcon from "@mui/icons-material/Campaign";
import ComputerIcon from "@mui/icons-material/Computer";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import InfoIcon from "@mui/icons-material/Info";
import BuildIcon from "@mui/icons-material/Build";

import DisplaySignUp from "./DisplaySignUp";
import RegisterVillager from "./RegisterVillager";

import ModalForm from "./ModalForm";

// TAB
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import Box from "@mui/material/Box";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import StreamChatOnEvent from "./StreamChatOnEvent";

// scheduleIcon
import YouTubeIcon from "@mui/icons-material/YouTube";
import DraggableScheduleListForEvet from "./DraggableSchedulListForEvent";

import Vimeo from "@u-wave/react-vimeo";
import PlayMovie from "./PlayMovie";

import SwitchComponent from "../components/SwitchComponent";

import Linkify from "linkify-react";
import RenderUserItem from "./RenderUserItem";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

const EventContainer = () => {
  const { id } = useParams();
  const { user } = useAuth();

  const [event] = useDocumentData(doc(db, "event", id));
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // イベントの作成者かどうか
  const [eventDoc] = useDocumentData(doc(db, "event", id));
  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const initSetAuthor = async () => {
      if (eventDoc?.author === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    user && initSetAuthor();
  }, [eventDoc, user]);

  // イベント作成者情報
  const [author, setAuthor] = useState("");
  useEffect(() => {
    const initGetAuthor = async () => {
      getDoc(doc(db, "users", event?.author)).then((doc) => {
        setAuthor({ id: doc.id, ...doc.data() });
      });
    };
    event && initGetAuthor();
  }, [event]);
  // console.log("author =>", author);

  // ユーザ情報
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initGetUser = async () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && initGetUser();
  }, [user]);

  // 　通知を受け取る設定しているか？
  const [isGetNotified, setIsGetNotified] = useState(false);
  useEffect(() => {
    const initIsGetNotified = async () => {
      let arr = [];
      getDocs(collection(db, "users", user?.uid, "getNotificationList")).then(
        (docs) => {
          docs.forEach((doc) => {
            if (doc.data().targetId === id) {
              arr.push({ id: doc.id, ...doc.data() });
            }
          });
          if (arr.length) {
            setIsGetNotified(true);
          }
        }
      );
    };
    user && initIsGetNotified();
  }, [user, id]);

  const [interestedPeople, setInterestedPeople] = useState([]);
  useEffect(() => {
    const initGetInterestedPeople = async () => {
      const interestedMembersSnapshot = await getDocs(
        collection(db, "event", id, "members")
      );
      const interestedPeople = []; // 興味を持っている人々の情報を格納する配列

      for (const memberDoc of interestedMembersSnapshot.docs) {
        if (memberDoc.data().status === "getNotified") {
          // ここでドキュメント参照の取得を別の方法で行う
          const uid = memberDoc.data().uid; // UIDを取得
          const userDocRef = doc(db, "users", uid); // ドキュメントの参照を正しく取得
          const userDoc = await getDoc(userDocRef); // 参照からドキュメントを取得
          if (userDoc.exists()) {
            interestedPeople.push(userDoc.data()); // 興味を持っている人の情報を配列に追加
          }
        }
      }

      // 興味を持っている人々の情報を状態にセット
      setInterestedPeople(interestedPeople); // 関数名を適切なものに変更する
    };

    if (id) {
      initGetInterestedPeople();
    }
  }, [id]);

  // console.log("interestedPeople =>", interestedPeople);

  // イベントのQ＆A取得
  const [qAndA, setQandA] = useState([]);
  useEffect(() => {
    const initGetQandA = async () => {
      let arr = [];
      await getDocs(collection(db, "event", id, "question")).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
      });
      setQandA(
        arr.sort((a, b) => {
          if (a.lastUpdated > b.lastUpdated) return -1;
          if (a.lastUpdated < b.lastUpdated) return 1;
          return 0;
        })
      );
    };
    id && initGetQandA();
  }, [id]);

  // イベントのスケジュール取得
  // const [schedules, setSchedules] = useState([]);
  const [schedulesCollection] = useCollection(
    query(collection(db, "event", id, "schedule"), orderBy("order", "asc"))
  );
  const schedules = schedulesCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const navigate = useNavigate();

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");
  const handleEdit = (e, target) => {
    // console.log("e =>", e);
    // console.log("e =>",e)
    // console.log("target =>",target)
    setOpen(true);
    setTarget(target);
    setForm(e);
  };

  const handleAnswer = (e, id) => {
    // console.log("e =>", e);
    // console.log("id =>", id);

    setOpen(true);
    setTarget(id);
    setForm(e);
  };

  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const subject = "【問合】イベント：" + event?.title + "について";
  const body = "";

  // イベントの許可されたムービー取得
  const [movies, setMovies] = useState([]);
  useEffect(() => {
    const init = async () => {
      const arr = [];
      await getDocs(
        query(
          collection(db, "movie"),
          where("target", "==", "event"),
          where("targetId", "==", id),
          where("isPublish", "==", true)
        )
      ).then((moviesCollection) => {
        moviesCollection.forEach((movie) => {
          arr.push({ id: movie.id, ...movie.data() });
        });
      });
      setMovies(arr);
    };
    init();
  }, [id]);

  // イベントのすべてのムービー取得
  const [allMovies, setAllMovies] = useState([]);
  useEffect(() => {
    const init = async () => {
      const arr = [];
      await getDocs(
        query(
          collection(db, "movie"),
          where("target", "==", "event"),
          where("targetId", "==", id)
        )
      ).then((moviesCollection) => {
        moviesCollection.forEach((movie) => {
          arr.push({ id: movie.id, ...movie.data() });
        });
      });
      setAllMovies(arr);
    };
    init();
  }, [id]);

  const docRef = doc(db, "event", id);
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(null);
  // 編集フォームの変更を処理する処理

  const handleEditContent = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };
  // データの編集を保存する処理
  const saveData = async (e) => {
    // console.log("editedData =>", editedData);
    if (!editedData) {
      setEditMode(false);
      return;
    }

    if (editedData[e.target.name].length === 0) {
      setEditMode(false);
      return;
    }

    await updateDoc(docRef, {
      [e.target.name]: editedData[e.target.name],
    });
    // setEditMode(false);
    window.location.reload();
  };

  // 編集モードに入る処理
  const enterEditMode = () => {
    setEditMode(true);
    // setEditedData(data);
  };

  if (userInfo?.isAdmin || isAuthor) {
  } else {
    if (!event?.publish) {
      return <div>表示できるものがありません。</div>;
    }
  }

  const linkifyOptions = {
    className: "text-blue-400",
    target: "blank",
  };

  return (
    <>
      {/* 管理者・作成者に見える文面 */}
      {/* {(userInfo?.isAdmin || isAuthor) && (
        <>
          <div className="text-gray-400">
            <div>
              ※この文面は、イベントの開設者、又は管理者のみに見えています
            </div>
            <div>イベントのタイトルや日次の変更などが行えるのは、</div>
            <div>イベントの開設者と管理者のみです。</div>
          </div>
        </>
      )} */}
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={id}
        target={target}
      />

      <div className="flex flex-col bg-back p-4 md:px-20 ">
        {/* snsリスト */}
        {user && (
          <Button
            variant="contained"
            startIcon={<UndoIcon />}
            onClick={() => navigate("/events")}
            className="w-fit mb-3"
          >
            イベント一覧
          </Button>
        )}

        <SNSList title={event?.title} />

        {/* 日付やイベントタイトル */}
        <div className="flex items-center">
          {/* 月・日 */}
          <div className="flex flex-col justify-center items-center mr-8">
            <div className=" text-red-500  text-2xl">
              {months[new Date(event?.publishDate.toDate()).getMonth()]}
            </div>
            <div className="text-3xl text-gray-800">
              {new Date(event?.publishDate.toDate()).getDate()}
            </div>
            {/* <div>{event?.publishDate}日付</div> */}
          </div>
          {/* イベントタイトル */}
          <EventTitle id={id} />
        </div>

        {/* 開始時刻 */}
        <div className="flex">
          <EventTime id={id} />
        </div>

        {/* 画像やイベント参加ボタン */}
        <div className="w-full bg-white border-t border-l border-r rounded-t-md">
          {/* バック画像 */}
          {event && <EventBackImage id={id} />}

          {/* イベントの説明 */}
          <EventExplain id={id} />
          {/* イベント参加ボタン */}
          <div className="p-5">
            {!user ? (
              <>
                <div className="flex ">
                  <div>
                    セカイムラに登録するとイベント情報の詳細がご覧いただけます。
                  </div>
                  <DisplaySignUp />
                </div>
              </>
            ) : (
              !userInfo?.isVillager && <RegisterVillager />
            )}
            {userInfo?.isVillager && !isGetNotified && (
              <Button
                variant="outlined"
                onClick={() => handleEdit("joinEvent")}
                disabled={isAuthor}
              >
                興味ある
              </Button>
            )}
            {userInfo?.isVillager && isGetNotified && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleEdit("exitEvent")}
                disabled={isAuthor}
              >
                通知オフ
              </Button>
            )}
          </div>
        </div>

        <div className="rounded-b-md border mb-96">
          {/* コンテンツ */}
          <div className="  rounded-md w-[calc(100%)]">
            <TabContext value={value}>
              <div className="grid grid-cols-1">
                <div className=" col-span-1">
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                  >
                    <Tab icon={<InfoIcon />} label="概要" value="1" />
                    {event?.target !== "operation" && (
                      <Tab
                        icon={<RecordVoiceOverIcon />}
                        label="主催"
                        value="8"
                      />
                    )}
                    <Tab icon={<InfoIcon />} label="基本情報" value="2" />
                    {(userInfo?.isAdmin || isGetNotified) && (
                      <Tab
                        icon={<RemoveRedEyeIcon />}
                        label="興味あり"
                        value="9"
                      />
                    )}

                    <Tab icon={<CalendarMonthIcon />} label="予定" value="3" />
                    <Tab icon={<ContactSupportIcon />} label="Q&A" value="4" />
                    <Tab
                      icon={<QuestionAnswerIcon />}
                      label="掲示板"
                      value="5"
                    />
                    <Tab icon={<YouTubeIcon />} label="動画" value="6" />
                    {(userInfo?.isAdmin || isAuthor) && (
                      <Tab icon={<BuildIcon />} label="設定" value="7" />
                    )}
                  </TabList>
                </div>
              </div>

              {/* 概要 */}
              <TabPanel sx={{ width: "100%", padding: 0 }} value="1">
                {!event?.overview && (userInfo?.isAdmin || isAuthor) && (
                  <div className="m-4">
                    <Button
                      variant="outlined"
                      className=""
                      onClick={() => handleEdit("addEventOverView")}
                    >
                      概要を追加
                    </Button>
                  </div>
                )}{" "}
                {event?.overview && (
                  <>
                    <div className="p-2 whitespace-pre-wrap break-all leading-normal">
                      <Linkify options={linkifyOptions}>
                        {event?.overview
                          .replace(/[ \t]+/g, "")
                          .replace(/　/g, "")}{" "}
                        {/* スペースと全角スペースを取り除く */}
                      </Linkify>
                      {/* <div className="m-4 whitespace-pre-wrap break-words">
                    {event?.overview}
                  </div> */}
                      {event?.overview && (userInfo?.isAdmin || isAuthor) && (
                        <EditIcon
                          className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                          onClick={() => handleEdit("editEventOverview")}
                        />
                      )}
                    </div>
                  </>
                )}
              </TabPanel>

              {/* 基本情報 */}
              <TabPanel sx={{ width: "100%" }} value="2">
                <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                  {/* 主催 */}
                  {event?.target === "operation" && (
                    <div className=" shadow-sm hover:shadow-lg duration-150 rounded-md p-2 flex space-x-2 items-center">
                      <div className="border rounded-full text-xs   p-1 text-gray-400">
                        <CampaignIcon />
                      </div>
                      <div className=" text-sm font-bold text-gray-500">
                        セカイムラ
                      </div>
                    </div>
                  )}
                  {/* 日付 */}
                  <div className=" shadow-sm hover:shadow-lg duration-150 rounded-md p-2 flex space-x-2 items-center">
                    <div className="border rounded-full text-xs  p-1 text-gray-400">
                      <CalendarMonthIcon />
                    </div>
                    <div className=" text-sm font-bold text-gray-500">
                      {new Date(event?.publishDate.toDate()).getMonth() + 1}月
                      {new Date(event?.publishDate.toDate()).getDate()}日{"@"}
                      {new Date(event?.publishDate.toDate()).getHours() >= 12
                        ? "PM"
                        : "AM"}
                      {new Date(event?.publishDate.toDate()).getHours() % 12}:
                      {Number(
                        new Date(event?.publishDate.toDate()).getMinutes()
                      ) < 9
                        ? "0" +
                          new Date(event?.publishDate.toDate()).getMinutes()
                        : new Date(event?.publishDate.toDate()).getMinutes()}
                      {" -"}
                    </div>
                  </div>
                  {/* Mail */}
                  <div className=" shadow-sm hover:shadow-lg duration-150 rounded-md p-2 flex space-x-2 items-center ">
                    <div className="border rounded-full text-xs  p-1 text-gray-400">
                      <LocalPostOfficeIcon />
                    </div>

                    <a
                      href={`mailto:${
                        event?.eMail
                          ? event.eMail
                          : event?.target === "operation"
                          ? "sekaimura2022@gmail.com"
                          : author?.email
                      }?subject=${subject || ""}&body=${body || ""}`}
                    >
                      {/* <a
                      href={`mailto:${
                        event?.target === "operation"
                          ? "sekaimura2022@gmail.com"
                          : author?.email
                      }?subject=${subject || ""}&body=${body || ""}`}
                    > */}
                      <div className=" text-sm  underline font-bold text-blue-500 hover:cursor-pointer">
                        {event?.eMail
                          ? event.eMail
                          : event?.target === "operation"
                          ? "sekaimura2022@gmail.com"
                          : author?.email}
                      </div>
                    </a>

                    {(userInfo?.isAdmin || isAuthor) && (
                      <EditIcon
                        className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                        onClick={() => handleEdit("editEventEmail")}
                      />
                    )}
                  </div>
                  {/* 場所 */}
                  <div className=" shadow-sm hover:shadow-lg duration-150 rounded-md p-2 flex space-x-2 items-center">
                    <div className="border rounded-full text-xs  p-1 text-gray-400">
                      <LocationOnIcon />
                    </div>
                    <div className=" text-sm font-bold text-gray-500">
                      {event?.place || "未定"}
                    </div>
                    {(userInfo?.isAdmin || isAuthor) && (
                      <EditIcon
                        className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                        onClick={() => handleEdit("editEventPlace")}
                      />
                    )}
                  </div>
                  {/* 集合 */}
                  <div className=" shadow-sm hover:shadow-lg duration-150 rounded-md p-2 flex space-x-2 items-center">
                    <div className="border rounded-full text-xs  p-1 text-gray-400">
                      <AssistantPhotoIcon />
                    </div>
                    <div className=" text-sm font-bold text-gray-500">
                      {event?.meetingPlace || "未定"}
                    </div>
                    {(userInfo?.isAdmin || isAuthor) && (
                      <EditIcon
                        className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                        onClick={() => handleEdit("editEventMeetingPlace")}
                      />
                    )}
                  </div>
                  {/* URL */}
                  <div className=" shadow-sm hover:shadow-lg duration-150 rounded-md p-2 flex space-x-2 items-center">
                    <div className="border rounded-full text-xs  p-1 text-gray-400">
                      <ComputerIcon />
                    </div>
                    {/* <a href={event?.url} target="_blank">
                      <div className=" text-sm  font-bold underline text-blue-500 truncate">
                        {event?.url}
                      </div>
                    </a> */}
                    <div
                      className=" text-sm hover:cursor-pointer  font-bold underline text-blue-500 truncate"
                      onClick={() => handleEdit("reallyJumpToURL", event?.url)}
                    >
                      {event?.url}
                    </div>
                    {(userInfo?.isAdmin || isAuthor) && (
                      <EditIcon
                        className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                        onClick={() => handleEdit("editEventURL")}
                      />
                    )}
                  </div>
                  {/* 費用 */}
                  <div className=" shadow-sm hover:shadow-lg duration-150 rounded-md p-2 flex space-x-2 items-center">
                    <div className="border rounded-full text-xs  p-1 text-gray-400">
                      <CurrencyYenIcon />
                    </div>
                    <div className=" text-sm font-bold text-gray-500">
                      {event?.price} 円
                    </div>
                    {(userInfo?.isAdmin || isAuthor) && (
                      <EditIcon
                        className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                        onClick={() => handleEdit("editEventPrice")}
                      />
                    )}
                  </div>
                  {/* ウォッチ */}
                  {!isGetNotified && (
                    <>
                      <div className=" shadow-sm hover:shadow-lg duration-150 rounded-md p-2 flex space-x-2 items-center">
                        <div className="border rounded-full text-xs  p-1 text-gray-400">
                          <RemoveRedEyeIcon />
                        </div>
                        <div className=" text-sm font-bold text-gray-500">
                          {interestedPeople?.length}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </TabPanel>

              {/* 予定 */}
              <TabPanel sx={{ width: "100%", padding: 0 }} value="3">
                {(userInfo?.isAdmin || isAuthor) && (
                  <div className="m-4">
                    <Button
                      variant="outlined"
                      className=""
                      onClick={() => handleEdit("addScheduleForEvent")}
                    >
                      スケジュールを追加
                    </Button>
                  </div>
                )}
                <DraggableScheduleListForEvet list={schedules} />
              </TabPanel>

              {/* Q&A    */}
              <TabPanel sx={{ width: "100%" }} value="4">
                {/* 質問するボタン */}
                {!user ? (
                  <DisplaySignUp />
                ) : userInfo?.isVillager ? (
                  <Button
                    variant="outlined"
                    onClick={() => handleEdit("addQuestionForEvent")}
                  >
                    質問する
                  </Button>
                ) : (
                  <RegisterVillager />
                )}

                <div className="w-[300px] sm:w-[100%] mt-4">
                  {qAndA?.map((qa) => (
                    <div key={Math.random()} className="mb-4">
                      {/* 質問 */}
                      <div className="flex  space-x-4 mb-4 items-center">
                        <div className="flex flex-col justify-center ">
                          {/* Avatar */}
                          <div className="flex justify-center mb-1">
                            <Avatar src={qa.questionerAvatar} />
                          </div>
                          <div className="flex justify-center font-bold w-20 whitespace-normal">
                            {qa.questionerName}
                          </div>
                        </div>

                        <div className="w-[100%] sm:w-fit balloon-left rounded-xl flex items-center justify-center p-2 h-fit whitespace-pre-wrap">
                          {qa.question}
                        </div>
                      </div>

                      {/* 回答 */}
                      {qa.answer && (
                        <div className="flex space-x-4 mb-4 items-center">
                          <div className="flex flex-col justify-center ">
                            <div className="flex justify-center mb-1">
                              <Avatar src={qa.respondentAvatar} />
                            </div>
                            <div className="flex justify-center font-bold w-20 whitespace-normal">
                              {qa.respondentName}
                            </div>
                          </div>

                          <div className="w-[100%] sm:w-fit balloon-left-answer rounded-xl flex items-center justify-center p-2 h-fit whitespace-pre-wrap break-words">
                            {qa.answer}
                          </div>
                        </div>
                      )}

                      {/* 回答するボタン */}
                      {(userInfo?.isAdmin || isAuthor) && !qa.answer && (
                        <Button
                          variant="outlined"
                          color="success"
                          sx={{ marginBottom: 2 }}
                          onClick={() =>
                            handleAnswer("addAnswerForEvent", qa.id)
                          }
                        >
                          回答する
                        </Button>
                      )}
                      {/* 　更新時間 */}
                      {
                        <div className=" text-blue-500 text-xs">
                          {formatDistanceToNow(qa.lastUpdated.toDate(), {
                            locale: ja,
                          })}
                          前
                        </div>
                      }
                      <Divider></Divider>
                    </div>
                  ))}
                </div>
              </TabPanel>

              {/* CHAT */}
              <TabPanel sx={{ width: "100%" }} value="5">
                {!user ? (
                  <DisplaySignUp />
                ) : userInfo?.isVillager ? (
                  <StreamChatOnEvent
                    user={userInfo}
                    event={id}
                    // isMember={isMember}
                  />
                ) : (
                  <RegisterVillager />
                )}
              </TabPanel>

              {/* 動画 */}
              <TabPanel sx={{ width: "100%" }} value="6">
                {!user ? (
                  <DisplaySignUp />
                ) : userInfo?.isVillager ? (
                  <>
                    {movies?.map((movie) => (
                      <PlayMovie
                        movieDoc={movie}
                        user={userInfo}
                        isAuthor={isAuthor}
                        target="event"
                        id={id}
                        key={id}
                      />
                    ))}
                  </>
                ) : (
                  <RegisterVillager />
                )}
              </TabPanel>
              {/* 設定 */}
              <TabPanel sx={{ width: "100%" }} value="7">
                <div className="grid grid-cols-12">
                  <div className="col-span-2 flex justify-center items-center font-bold text-xl">
                    表示
                  </div>
                  <div className="col-span-10">
                    <SwitchComponent
                      id={id}
                      target="publish"
                      collection="event"
                      text="表示"
                    />
                  </div>
                </div>

                {/* title */}
                <EditableContent
                  target={event?.title}
                  name="title"
                  editMode={editMode}
                  user={user}
                  creator={event?.author}
                  handleEdit={handleEditContent}
                  saveData={saveData}
                  enterEditMode={enterEditMode}
                />
                <div className="my-3"></div>
                {/* title */}
                <EditableContent
                  target={event?.explain}
                  name="explain"
                  editMode={editMode}
                  user={user}
                  creator={event?.author}
                  handleEdit={handleEditContent}
                  saveData={saveData}
                  enterEditMode={enterEditMode}
                />
                <div className="my-3"></div>
                {/* 開始時刻 */}
                <div className="grid grid-cols-12">
                  <div className="col-span-2 flex justify-center items-center font-bold text-xl">
                    開始時刻
                  </div>
                  <div className="col-span-10">
                    <div
                      className="mb-4 flex border-2 rounded-md"
                      onClick={() => handleEdit("editEventTime")}
                    >
                      <Tooltip title="クリックで編集">
                        <div className=" hover:shadow-xl hover:cursor-pointer hover:bg-gray-200 p-2 ">
                          {new Date(
                            eventDoc?.publishDate.toDate()
                          ).toLocaleDateString()}{" "}
                          {new Date(eventDoc?.publishDate.toDate()).getHours()}:
                          {Number(
                            new Date(
                              eventDoc?.publishDate.toDate()
                            ).getMinutes()
                          ) < 9
                            ? "0" +
                              new Date(
                                eventDoc?.publishDate.toDate()
                              ).getMinutes()
                            : new Date(
                                eventDoc?.publishDate.toDate()
                              ).getMinutes()}
                          {" ～"}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="my-3"></div>
                {/* 動画 */}
                <div className="grid grid-cols-12">
                  <div className="col-span-2 flex justify-center items-center font-bold text-xl">
                    動画
                  </div>
                  <div className="col-span-10">
                    <div className="m-4">
                      <Button
                        variant="outlined"
                        className=""
                        onClick={() => handleEdit("addMovie", "event")}
                      >
                        動画追加
                      </Button>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      {allMovies?.map((movie, idx) => (
                        <div key={movie.id} className=" col-span-1 border">
                          <div className="  rounded-md p-2 space-x-2">
                            <SwitchComponent
                              id={movie?.id}
                              target="isPublish"
                              collection="movie"
                              text="表示"
                            />
                            <Vimeo
                              key={Math.random()}
                              video={movie.url.split("/")[3]}
                              //   autoplay
                              responsive
                              controls={false}
                            />
                            <div className="">{movie.title}</div>
                            {/* 〇〇か月前 */}
                            <div className=" flex justify-items-end text-gray-500 text-xs hover:bg-white hover:shadow-2xl">
                              {formatDistanceToNow(movie?.createdAt.toDate(), {
                                locale: ja,
                              })}
                              前
                            </div>

                            {/* <div className="">{movie.url}</div>] */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* 主催 */}
              <TabPanel sx={{ width: "100%" }} value="8">
                {!user ? (
                  <DisplaySignUp />
                ) : userInfo?.isVillager ? (
                  <>
                    <div className="rounded-md p-2 flex space-x-2 items-center max-w-sm">
                      <div className=" text-sm font-bold text-gray-500 w-full">
                        {event?.target === "operation" ? (
                          "セカイムラ"
                        ) : (
                          // : author?.lastName + author?.firstName}
                          <div className="w-full">
                            <RenderUserItem user={author} highlight="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <RegisterVillager />
                )}
              </TabPanel>

              {/* 興味あり */}
              <TabPanel sx={{ width: "100%" }} value="9">
                {!user ? (
                  <DisplaySignUp />
                ) : userInfo?.isVillager ? (
                  <>
                    {interestedPeople.map((user, index) => {
                      // 現在のユーザーとイベントの作成者以外を表示
                      if (
                        user.uid !== auth.currentUser.uid &&
                        user.uid !== author.id
                      ) {
                        return (
                          <div className="max-w-sm" key={index}>
                            <RenderUserItem user={user} highlight="" />
                          </div>
                        );
                      }
                      // 条件に一致しない場合は何も表示しない
                      return null;
                    })}
                  </>
                ) : (
                  <RegisterVillager />
                )}
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(EventContainer);

const EditableContent = (props) => {
  const {
    target,
    name,
    editMode,
    user,
    creator,
    handleEdit,
    saveData,
    enterEditMode,
  } = props;

  return (
    <>
      {editMode && (user?.isAdmin || creator === auth.currentUser.uid) ? (
        <>
          <div>{name === "explain" ? "概要" : "タイトル"}</div>

          <div className="grid grid-cols-1 w-full space-x-2 items-center border-2 p-2 rounded-md ">
            <TextareaAutosize
              name={name}
              style={{ resize: "none", fontSize: "16px" }}
              onChange={handleEdit}
              placeholder={target}
              defaultValue={target}
              className=" p-1 w-full scrollbar-hide mb-5"
              minRows={3}
              maxRows={5}
            />

            <Button
              name={name}
              onClick={saveData}
              className="w-fit"
              variant="outlined"
            >
              決定
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="grid grid-cols-12">
            <div className="col-span-2 flex justify-center items-center font-bold text-xl">
              {name === "explain" ? "概要" : name}
            </div>
            <div className="col-span-10">
              <Tooltip title="クリックで編集">
                <div
                  onClick={enterEditMode}
                  className=" border-2 p-2 rounded-md  whitespace-pre-wrap break-words line-clamp-3"
                >
                  {target}
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </>
  );
};
