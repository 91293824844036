import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import { getDoc, doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../api/firebase";

const SwitchComponent = ({ id, target, collection, text, tableCell,disabled }) => {
  // console.log("id =>",id)
  // console.log("target =>",target)
  // console.log("collection =>",collection)
  // console.log("text =>",text)

  // ドキュメント取得
  const [document, setDocument] = useState([]);
  useEffect(() => {
    const docRef = doc(db, collection, id);
    onSnapshot(docRef, (doc) => {
      setDocument({ ...doc.data(), id: doc.id });
    });
  }, []);

  //   権限変更関連handle
  const publishChange = async (type) => {
    const pub = await getDoc(doc(db, collection, id));
    await updateDoc(doc(db, collection, id), {
      [type]: !pub.data()[type],
    });
  };
  const handleChange = async (e) => {
    await publishChange(e.target.name);
  };

  if (tableCell)
    return (
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={document?.[target] ? document?.[target] : false}
              onChange={handleChange}
              name={target}
              value={document?.[id] ? document?.[id] : false}
              disabled={disabled}
            />
          }
          label={document?.[target] ? text : ""}
        />
      </TableCell>
    );

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={document?.[target] ? document?.[target] : false}
            onChange={handleChange}
            name={target}
            value={document?.[id] ? document?.[id] : false}
            disabled={disabled}
            />
        }
        label={document?.[target] ? text : ""}
      />
    </>
  );
};

export default SwitchComponent;
