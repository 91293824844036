import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { useState, useEffect, memo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db, auth } from "../api/firebase";
import MyShop from "./MyShop";

const UserShops = ({ id }) => {
  const ownerId = id || auth.currentUser.uid;
  const userShopQuery = query(
    collection(db, "requestShop"),
    where("owner", "==", ownerId),
    where("isPermission", "==", true),
    where("isPublish", "==", true),
    orderBy("createdAt", "desc")
  );

  const [userShops, setUserShops] = useState([]);
  useEffect(() => {
    const init = async () => {
      const userShopsRef = await getDocs(userShopQuery);
      if (userShopsRef.size) {
        let arr = [];
        userShopsRef.docs.forEach((elm) =>
          arr.push({ id: elm.id, ...elm.data() })
        );
        setUserShops(arr);
      }
    };
    init();
  }, []);

  console.log("userShops =>", userShops);

  if (!userShops?.length) {
    return (
      <>
        <div>表示できるお店がありません。</div>
      </>
    );
  }
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  gap-2  my-1">
        {userShops?.map((shop, index) => {
          return <MyShop shop={shop} key={index} userPage />;
        })}
      </div>
    </>
  );
};

export default UserShops;
