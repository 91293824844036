import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../api/firebase";

const GetBaseName = ({ baseId }) => {
  const [base, setBase] = useState("");
  useEffect(() => {
    const init = async () => {
      const docRef = await getDoc(doc(db, "base", baseId));

      if (docRef.exists) {
        setBase(docRef.data());
      }
    };
    baseId && init();
  }, []);


  return <div>{base?.prefecture}</div>;
};

export default GetBaseName;
