import React from "react";
import HeaderContainerMixIN from "./HeaderContainerMixIN";
import EventContainer from "./EventContailner";

import { memo } from "react";

const Event = () => {

  return (
    <>
      <HeaderContainerMixIN outlet={<EventContainer />} />
    </>
  );
};

export default memo(Event);
