import React, { useState, useRef, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { Button } from "@mui/material";
import {
  doc,
  updateDoc,
  serverTimestamp,
  addDoc,
  collection,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import addError from "./addError";

const FormComment = ({ id, collectionName, setHeightChange, addItems }) => {
  const [input, setInput] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [sending, setSending] = useState("");

  const [text, setText] = useState("");
  const commentRef = useRef("");

  // ユーザ情報
  const [user] = useDocumentData(doc(db, `users/${auth.currentUser.uid}`));

  const handleChange = (e) => {
    setText(e.target.value);
    if (commentRef.current.value) {
      setInput(true);
    } else {
      setInput(false);
    }
  };

  useEffect(() => {
    commentRef.current.focus();
    // window.onkeydown = (e) => {
    //   if (e.keyCode === 13 && e.ctrlKey === true) {
    //     handleSubmit(e);
    //   }
    // };
  }, []);

  // 送信
  const handleSubmit = async (e) => {
    commentRef.current.value = "";
    e.preventDefault();
    if (!text) return;
    setSending(true);
    const func = "handleSubmit";
    try {
      await addDoc(collection(db, `${collectionName}/${id}/messages`), {
        name: user?.lastName + user?.firstName,
        sender: user?.uid,
        text: text.replace(/^\n/gm, ""),
        sentAt: serverTimestamp(),
        avatar:user?.avatar
      });
      await updateDoc(doc(db, `${collectionName}/${id}/`), {
        lastUpdatedAt: serverTimestamp(),
        lastUpdated: user.lastName + user.firstName,
        lastText: text.replace(/^\n/gm, ""),
      });
      setText("");
      setSending(false);
      commentRef.current.focus();
    } catch (error) {
      await addError(error, user, func);
      setSending(false);
    }
  };

  const onHeightChange = (e) => {
    setHeightChange(true);
  };

  return (
    <>
      {/* コメントエリア */}
      <form className="flex h-full justify-center items-center">
        {/* コメント欄左のアイテム達エリア */}
        {addItems && (
          <div
            className="flex-none h-6 w-6 text-blue-500 hover:cursor-pointer hover:shadow-lg rounded-full"
            htmlFor="img"
          >
            <PlusCircleIcon />
            <input type="file" id="img" className="hidden" />
          </div>
        )}

        {/* コメント欄 */}
        <div className="grow flex h-full mx-2 pl-2 rounded-2xl mt-2 bg-white border items-center">
          <TextareaAutosize
            className="overflow-hidden h-full pl-2 bg-white  w-full  rounded-lg  bg-transparent outline-none "
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            disabled={!id || sending}
            style={{ resize: "none", fontSize: "16px" }}
            type="text"
            ref={commentRef}
            placeholder="メッセージを入力"
            onChange={handleChange}
            onHeightChange={onHeightChange}
            maxRows={3}
          />
        </div>

        {/* 送信ボタン */}
        <div className="flex-none">
          <Button
            className="flex-none"
            size="small"
            variant="outlined"
            type="submit"
            onClick={handleSubmit}
          >
            送信
          </Button>
        </div>
      </form>
    </>
  );
};

export default FormComment;
