import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { auth } from "../api/firebase";
import DisplayGroupMember from "./DisplayGroupMember";
import GetUserInfo from "./GetUserInfo";

const GroupMembers = ({ members }) => {
  // 代表者抜き出し
  const [founder, setFounder] = useState("");
  useEffect(() => {
    const init = async () => {
      const result = members.find((u) => u.isOwner === true);
      if (result) {
        setFounder(result);
      } else {
        console.log("代表者不在");
      }
    };
    init();
  }, [members]);

  // メンバー抜き出し
  const [ments, setMents] = useState("");
  useEffect(() => {
    const init = async () => {
      const result = members.filter(
        (u) =>
          u.isOwner === false &&
          u.isApplication === false &&
          u.isMember === true
      );
      if (result) {
        setMents(result);
      } else {
        console.log("メンバー不在");
      }
    };
    init();
  }, [members]);

  // 申請中のメンバー抜き出し
  const [isApplies, setIsApplies] = useState("");
  useEffect(() => {
    const init = async () => {
      const result = members.filter(
        (u) =>
          u.isOwner === false &&
          u.isApplication === true &&
          u.isMember === false
      );
      if (result) {
        setIsApplies(result);
      } else {
        // console.log("メンバー不在");
      }
    };
    init();
  }, [members]);

  return (
    <>
      {/* 代表 */}
      <div>
        <div className="my-8 text-xl">代表</div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
          <DisplayGroupMember member={founder} />
        </div>
      </div>

      {/* メンバー */}
      <div>
        <div className="my-8 text-xl">メンバー</div>
        {ments?.length === 0 ? (
          <div>メンバーはまだいません</div>
        ) : (
          <div
            className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8"
            key={Math.random()}
          >
            {ments?.map((member) => (
              <div key={Math.random()}>
                <DisplayGroupMember member={member} />
              </div>
            ))}
          </div>
        )}
      </div>

      {/* 代表だけが見える申請状況 */}
      {founder?.id === auth.currentUser.uid && (
        <div>
          <div className="my-8 text-xl">申請中</div>
          {isApplies?.length === 0 ? (
            <div>申請中のメンバーはいません</div>
          ) : (
            <div
              className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
              key={Math.random()}
            >
              {isApplies?.map((member) => (
                <DisplayGroupMember member={member} />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GroupMembers;
