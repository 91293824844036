import {
  doc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import React, { memo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../api/firebase";
import Img from "../data/img/logo.png";
import AvatarAndName from "./AvatarAndName";
import GetUserInfo from "./GetUserInfo";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import BaseMembersAvatar from "./BaseMembersAvatar";

const DisplayEventFromGroupForAll = ({ base }) => {
  const navigate = useNavigate();

  const [baseMembers, setBaseMembers] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Firebaseのデータベースクエリを実行して拠点参加状態を確認
    const checkMembership = async () => {
      try {
        const q = query(
          collection(db, `base/${base?.id}/members`),
          where("isMember", "==", true)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // baseMembersを更新
          setBaseMembers(querySnapshot.docs);

          // ユーザー情報を取得するためのuidのリストを作成
          const uidList = querySnapshot.docs.map((doc) => doc.data().uid);

          // ユーザー情報を取得
          const userPromises = uidList.map(async (uid) => {
            const userDoc = await getDoc(doc(db, "users", uid));
            return userDoc.data();
          });

          // ユーザー情報をまとめて取得し、usersを更新
          const userData = await Promise.all(userPromises);
          setUsers(userData);
        }
      } catch (error) {
        console.error("Firebaseクエリエラー:", error);
      }
    };

    checkMembership();
  }, [base?.id]);

  // useEffect(() => {
  //   // Firebaseのデータベースクエリを実行して拠点参加状態を確認
  //   const checkMembership = async () => {
  //     try {
  //       const q = query(
  //         collection(db, `base/${base?.id}/members`),
  //         where("isMember", "==", true)
  //       );
  //       const querySnapshot = await getDocs(q);

  //       if (!querySnapshot.empty) {
  //         setBaseMembers(querySnapshot);
  //       }
  //     } catch (error) {
  //       console.error("Firebaseクエリエラー:", error);
  //     }
  //   };

  //   checkMembership();
  // }, [base?.id]);

  // console.log("baseMembers =>",baseMembers.docs)

  return (
    <>
      <div
        className="flex flex-col relative border rounded-md h-80 md:h-80  bg-white hover:shadow-xl duration-150 hover:cursor-pointer "
        onClick={() => window.open("/base/" + base?.id)}
        // )  navigate("/base/" + base?.id)}
      >
        {/* イメージ */}
        <div className="relative">
          {/* グループ or セカイムラ？ */}

          <img
            className="w-full h-48 rounded-t-md"
            src={base?.background || Img}
            alt=""
          />
          <div className="absolute bottom-2 left-2  p-2 ">
            <BaseMembersAvatar members={users} />
          </div>

          <div className="absolute bottom-2 right-2 bg-black opacity-60 text-white rounded-md font-bold p-2 ">
            <div>{base?.prefecture}</div>
          </div>
        </div>
        <div className="flex h-full py-4 px-1 ">
          {/* 左 */}
          <div className="flex flex-col  items-center mx-2"></div>
          {/* 右 */}
          <div className="flex flex-col truncate line-clamp-1">
            {/* name */}
            <div className="line-clamp-2 whitespace-pre-wrap break-words  font-bold text-xl hover:text-green-500 truncate  duration-100">
              {base?.title}
            </div>
            {/* <div className="flex  space-x-1 items-center rounded-md border px-2 py-1">
              <GetUserInfo uid={base?.chief} target="avatar" size={30} />
              <GetUserInfo uid={base?.chief} target="name" />
            </div> */}
          </div>
        </div>
      </div>
    </>

    // <>
    //   <div className="flex space-x-2 relative items-center  border rounded-md justify-start shadow-md hover:shadow-xl duration-150 hover:cursor-pointer bg-white">
    //     {/* 月・日 */}
    //     <div className="flex flex-col justify-center items-center mx-3">
    //       <div className=" text-red-500  text-2xl">
    //         {months[new Date(event?.publishDate.toDate()).getMonth()]}
    //       </div>
    //       <div className="text-3xl text-gray-800">
    //         {new Date(event?.publishDate.toDate()).getDate()}
    //       </div>
    //       {/* <div>{event?.publishDate}日付</div> */}
    //     </div>

    //     {/* タイトルと説明 */}
    //     <div className="flex flex-col">
    //       <div className="border">{event?.title}</div>
    //       <div className="border">{event?.explain}</div>
    //     </div>
    //     <div className="border">グループ名</div>
    //     {/* イメージ */}
    //     <img
    //       className="w-fit h-20 absolute right-0 rounded-r-md"
    //       src={event?.background || Img}
    //       alt=""
    //     />
    //   </div>
    // </>
  );
};

export default memo(DisplayEventFromGroupForAll);
