import React, { useState, useEffect } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";
// redux - use
import { useNotification } from "../redux/useNotification";

const EditAnnounceTitle = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();


  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    title: "",
    message: "",
    error: null,
    loading: false,
  });
  const { title, message, error, loading } = data;

  // 自分の情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const getUser = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    getUser();
  }, []);
  const [announce] = useDocumentData(doc(db, "notificationOfTop", requestId));

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      // console.log("data[e.target.name] =>", data[e.target.name]);
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "notificationOfTop", requestId), {
        title,
      });
      displayNotification({
        message: "お知らせタイトルを変更しました。",
        type: "success",
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };
  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">お知らせタイトル</div>
        <TextField
          id="outlined-required"
          name="title"
          label="お知らせタイトル"
          defaultValue={announce?.tite}
          size="small"
          placeholder={announce?.tite}
          className=" w-full "
          onChange={handleChange}
          required
          disabled={loading}
          // inputProps={{maxLength:18}}
        />

        <Button
          className="btn"
          name="title"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditAnnounceTitle;
