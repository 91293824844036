import React, { useState } from "react";

import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { TextareaAutosize } from "@mui/material";

import { useParams } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";

import RatingComponent from "./RatingComponent";
// redux - use
import { useNotification } from "../redux/useNotification";

const WriteReview = ({ setOpen, requestId }) => {
  //   question取得
  const { id } = useParams();
  // redux - Notification
  const { displayNotification } = useNotification();

  // const [question, setQuestion] = useState([]);
  // useEffect(() => {
  //   getDoc(doc(db, "products", id, "question", requestId)).then((doc) => {
  //     setQuestion(doc.data());
  //   });
  // }, []);

  const [product_] = useDocument(doc(db, "products", id));

  // 提供する形コンテナ
  const [product, setProduct] = useState({
    review: "",
    rate: 5,
    error: "",
    loading: false,
  });
  const { review, rate, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    setProduct({
      ...product,
      error: "",
      [e.target.name]: e.target.value,
    });
  };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    if (!review.length) {
      setProduct({
        ...product,
        error: "レビューが空です。",
      });
      return;
    }

    if(product_?.data().uid === auth.currentUser.uid){
      setProduct({
        ...product,
        error: "自分のDDPにレビューはできません。",
      });
      return;

    }

    try {
      // レビュー追加処理
      const docRef = await addDoc(collection(db, "products", id, "review"), {
        rate: Number(rate),
        review,
        createdAd: serverTimestamp(),
        reviewer: auth.currentUser.uid,
        reviewerd: product_?.data().uid,
        product: product_?.id,
        display:true
      });
      setProduct({
        review: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
      displayNotification({
        message: "レビューを送信しました。",
        type:"success"
      });
  
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">レビューを書く</div>
        <form onSubmit={setRequestDataShop}>
          <RatingComponent rate={rate} handleChange={handleChange} />

          {/* レビュー */}
          <div className="flex flex-col">
            <TextareaAutosize
              name="review"
              aria-label="minimum height"
              minRows={3}
              maxRows={5}
              className="border mb-4 p-2"
              placeholder="レビュー"
              onChange={handleChange}
            ></TextareaAutosize>

            {/* 登録ボタン */}
            <button
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              disabled={loading}>
              >
              {loading ? "送信しています..." : "送信する"}
            </button>
            {error ? <div className=" text-red-500">{error}</div> : ""}
          </div>
        </form>
      </div>
    </>
  );
};

export default WriteReview;
