import React, { useState } from "react";
import CheckboxProton from "./CheckBoxPronton";
import SelectProton from "./SelectProton";

const FilterPanelOnDDPs = ({
  selects,
  handleChangeSelect,
}) => {
  // ドロワー
  const [state, setState] = useState(false);
  const toggleDrawer = () => {
    setState(!state);
  };
  const closeDrawer = () => {
    setState(false);
  };

  return (
    <>
      <SelectProton
        selects={selects}
        value={selects}
        title="並び順"
        handleChangeSelect={handleChangeSelect}
      />
    </>
  );
};

export default FilterPanelOnDDPs;
