import React, { useState, useEffect } from "react";
import {
  CalendarIcon,
  DesktopComputerIcon,
  UserGroupIcon,
  ChatAlt2Icon,
  GiftIcon,
  SparklesIcon,
  MenuIcon,
  InboxInIcon,
} from "@heroicons/react/solid";
import SidebarRow from "./SidebarRow";
import SidebarBigTitle from "./SidebarBigTitle";
import Separator from "./Separator";
import { auth, db } from "../api/firebase";
import {  doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getDoc } from "firebase/firestore";

function Sidebar({ sidebarOpen, HandleSidebar,toggleOpen }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  // ユーザ取得
  const [user, setUser] = useState("");
  useEffect(() => {
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
      if (doSnap.exists) {
        setUser(doSnap.data());
      }
    });
  },[]);


  const HandleSidebarOpen = () => {
    return (
      <div className=" sticky top-0 z-40 bg-white pt-3 sm:pt-6 pl-3  border-b px-1 h-14 sm:h-20 items-center cursor-pointer hover:bg-back"
      onClick={sidebarOpen ? HandleSidebar : () => setOpen(!open)}
      >
        <div
          className={` h-7 w-7  text-gray-500
          ${sidebarOpen ? "" : open ? "rotate-90" : "-rotate-90"}`}
        >
          {sidebarOpen ? <MenuIcon /> : <InboxInIcon />}
        </div>
      </div>
    );
  };


  const handleMoveProfile = () => {
    if (sidebarOpen) {
      HandleSidebar(false);
    }
    navigate("/profile");
  };

  // リサイズ対応
  useEffect(() => {
    const onResize = () => {
      setOpen(true);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <>
      <div
        className={`${
          sidebarOpen
            ? "fixed w-full top-0 left-0 "
            : open
            ? "w-72 hidden lg:block sticky top-0 left-0"
            : "w-24 hidden lg:block sticky top-0 left-0"
        }
           duration-200   border-r border-gray-200 z-40 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.5)] bg-white h-screen overflow-y-auto scrollbar-hide `}
      >
        <HandleSidebarOpen />

        {/* プロフィール */}
        {open ? (
          <div className="flex flex-col justify-center  hover:bg-back cursor-pointer ">
            <div className="py-4 mx-auto" onClick={handleMoveProfile}>
              {/* <img
                className="rounded-full h-20 w-20"
                src={user.avatar || Img}
                alt=""

              /> */}
            </div>
            <div className=" text-center font-bold text-gray-700 ">
              {user.name}
            </div>
            <div className=" text-center font-bold text-gray-700 ">
              神奈川支部 / システム担当
            </div>
            <div className=" text-center text-gray-400 hover:underline font-bold">
              1200ルンル
            </div>
          </div>
        ) : (
          // <img
          //   className="rounded-full mx-auto my-3 h-10 w-10  "
          //   src={user.avatar || Img}
          //   alt=""
          // />
          ""
        )}

        <div className="mx-3">
          {open ? <SidebarBigTitle title="交流" /> : <Separator title="交流" />}
          <SidebarRow
            Icon={ChatAlt2Icon}
            title={`${open ? "メッセージ" : ""}`}
            count="8"
          />
          <SidebarRow Icon={GiftIcon} title={`${open ? "贈る" : ""}`} />
          <SidebarRow Icon={SparklesIcon} title={`${open ? "叶える" : ""}`} />

          {open ? <SidebarBigTitle title="拠点" /> : <Separator title="拠点" />}
          <SidebarRow Icon={UserGroupIcon} title={`${open ? "拠点" : ""}`} />
          <SidebarRow
            Icon={CalendarIcon}
            title={`${open ? "イベント" : ""}`}
            count="2"
          />
          <div className="my-5">
            {open ? (
              <SidebarBigTitle title="学ぶ" />
            ) : (
              <Separator title="学ぶ" />
            )}
            <SidebarRow
              Icon={DesktopComputerIcon}
              title={`${open ? "動画" : ""}`}
            />
            <SidebarRow
              Icon={DesktopComputerIcon}
              title={`${open ? "動画" : ""}`}
            />
            <SidebarRow
              Icon={DesktopComputerIcon}
              title={`${open ? "動画" : ""}`}
            />
            <SidebarRow
              Icon={DesktopComputerIcon}
              title={`${open ? "動画" : ""}`}
            />
          </div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center whitespace-pre"> </div>
        </div>
        {/* <div
          className={`${
            sidebarOpen ? "w-screen" : open ? " w-68" : "w-24"
          } sticky   bottom-0 flex bg-white h-12 items-center pl-3 border-t cursor-pointer hover:bg-back-hover`}
          onClick={handleSignout}
        >
          <div className=" h-7 w-7 mr-2 ">
            <LogoutIcon />
          </div>
          {`${open ? "ログアウト" : ""}`}
        </div> */}
      </div>
    </>
  );
}

export default Sidebar;
