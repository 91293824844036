import React, { useState } from "react";

import {
  doc,
  addDoc,
  collection,
  query,
  serverTimestamp,
  where,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";

import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import getBalance from "./getBalance";
import DisplayProductUnder640 from "./DisplayProductUnder640";
import addError from "../components/addError";
import DisplayProductOver640 from "./DisplayProductOver640";
import { useEffect } from "react";

// redux - use
import { useNotification } from "../redux/useNotification";

// streamChat
import { StreamChat } from "stream-chat";
import getBalanceEx from "./getBalanceEx";

const apiKey = process.env.REACT_APP_STREAM_API_KEY;

const ReallyBuyDDP = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // product取得関連
  const productRef = doc(db, "products", requestId);
  const [product] = useDocumentData(productRef);
  const queryMyProductImages = query(
    collection(db, `products/${requestId}/images`)
  );
  const [images] = useCollection(queryMyProductImages);
  const myProductImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // 自分情報取得
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // 自分のルンル取引取得
  const toRunru = query(
    collection(db, "runru"),
    where("to", "==", auth.currentUser.uid)
  );
  const fromRunru = query(
    collection(db, "runru"),
    where("from", "==", auth.currentUser.uid)
  );
  const [runru, setRunru] = useState([]);
  useEffect(() => {
    let arr = [];
    const getRunru = async () => {
      await getDocs(toRunru).then((docs) => {
        docs.forEach((runru) => arr.push({ id: runru.id, ...runru.data() }));
      });
      await getDocs(fromRunru).then((docs) => {
        docs.forEach((runru) => arr.push({ id: runru.id, ...runru.data() }));
      });
      setRunru(arr);
    };
    getRunru();
  }, []);

  // 自分の取引取得
  const buyQuery = query(
    collection(db, "deal"),
    where("buy", "==", auth.currentUser.uid)
  );
  const buyerQuery = query(
    collection(db, "deal"),
    where("buyer", "==", auth.currentUser.uid)
  );
  const [dealHistory, setDealHistory] = useState([]);
  useEffect(() => {
    let arr = [];
    const getDeal = async () => {
      await getDocs(buyQuery).then((docs) => {
        docs.forEach((deal) => arr.push({ id: deal.id, ...deal.data() }));
      });
      await getDocs(buyerQuery).then((docs) => {
        docs.forEach((deal) => arr.push({ id: deal.id, ...deal.data() }));
      });
      setDealHistory(arr);
    };
    getDeal();
  }, []);

  //   使用期限取得
  let now = new Date();
  const [runruPeriod] = useDocumentData(doc(db, "env", "runruPeriod"));
  const period = now.setDate(now.getDate() - runruPeriod?.period);

  // 残高取得
  // const balance = getBalance(user, runru, dealHistory, period);
  const [balance, setBalance] = useState(0); // balanceの宣言

  useEffect(() => {
    const calculatedBalance = getBalanceEx(user, runru, dealHistory);
    setBalance(calculatedBalance.balance);
    // setTransactions(calculatedBalance.detailedEntries);
  }, [user, runru, dealHistory]);


  const [data, setData] = useState({
    error: "",
    loading: false,
  });
  const { error, loading } = data;

  // 登録処理
  const setDeal = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });
    const func = setDeal;
    // 自分のDDPを買おうとした場合
    if (user?.uid === product?.uid) {
      displayNotification({
        message: "自分のDDPは交換できません。",
        type: "error",
      });

      setOpen(false);
      return;
    }

    // 登録処理
    const addDeal = async () => {
      try {
        // buy 買った人
        // buyer 買われた人
        // 取引情報登録
        const docRef = doc(collection(db, "deal"));
        await setDoc(docRef, {
          createdAt: serverTimestamp(),
          buy: user?.uid,
          buyer: product?.uid,
          amount: Number(product?.runru),
          cause: "DDP交換",
          product: requestId,
          productName: product.title,
          productOffering: product.offering,
          productTarget: product.target,
          delivery: product.delivery,
          status: "requesting",
          cancelReason: "",
          lastUpdated: user?.uid,
          lastUpdatedAt: serverTimestamp(),
          ddpExchangeId: docRef.id,
        });
        // 通知情報登録 - 買われた人・・・なのでfrom～には買った人（自分）が入る
        await addDoc(collection(db, "users", product?.uid, "notification"), {
          createdAt: serverTimestamp(),
          content: "DDP交換希望リクエスト",
          fromAvatar: user?.avatar,
          fromId: user?.uid,
          fromName: user?.lastName + user?.firstName,
          isRecognition: false,
          target: "deal",
          targetId: docRef.id,
        });
        setData({
          ...data,
          error: "",
          loading: false,
        });
        displayNotification({
          message: "交換希望リクエスト送信しました",
          type: "success",
        });

        const userToConnect = {
          id: user.uid,
          name: user.lastName + user.firstName,
          image: user.avatar,
          langage: "jp",
        };

        // chat作成
        const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
        const token = await fetch(
          process.env.REACT_APP_STREAM_GET_TOKEN_URL,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              input: user.uid,
            }),
          }
        ).then((r) => r.json());

        await client.connectUser(userToConnect, token);
        const chatTitle = "ddpExchange-" + docRef.id;
        const channel = client.channel("messaging", chatTitle, {
          name: product.title,
          members: [user.uid, product?.uid],
        });

        await channel.watch();

        setOpen(false);
      } catch (err) {
        const errorData = {
          message: err.message,
          stack: err.stack,
        };
        await addDoc(collection(db, "error"), {
          error: errorData,
          createdAt: serverTimestamp(),
          type: "reallyBuyDDP",
          buy: user?.uid,
          buyer: product?.uid,
          amount: Number(product?.runru),
          cause: "DDP交換",
          product: requestId,
          productName: product.title,
        });

        // addError(err, user, func);
        setData({
          ...data,
          error: err.message,
          loading: false,
        });
      }
    };

    const result = Math.sign(balance - product?.runru);

    if (result !== -1) {
      // 足りる時
      addDeal();
    } else {
      // 足りない時の処理
      setData({
        ...data,
        error: "残高が足りないようです",
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between mb-3">
          <div>DDP交換</div>
          <div className="flex items-baseline">
            <div>現在の残高:</div>
            <div className="font-bold text-blue-400">{balance}</div>
            <div className="text-xs text-blue-400">ルンル</div>
          </div>
        </div>

        <form onSubmit={setDeal}>
          <div>以下のDDPを交換します</div>
          <div>よろしいですか？</div>
          <div className="my-2"></div>
          {product && myProductImages ? (
            <>
              <DisplayProductUnder640
                product={product}
                myProductImages={myProductImages}
                id={requestId}
              />
              <DisplayProductOver640
                product={product}
                myProductImages={myProductImages}
                id={requestId}
              />
            </>
          ) : (
            ""
          )}
          {/* 登録ボタン */}
          <div className="my-4" />
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
          >
            {loading ? "送信しています..." : "交換する"}
          </button>
          {error ? <div className="text-red-500">{error}</div> : ""}
        </form>
      </div>
    </>
  );
};

export default ReallyBuyDDP;
