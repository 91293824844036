import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../api/firebase";
import MessageIcon from "@mui/icons-material/Message";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const PostCommentsCount = ({ post, onClick }) => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const init = async () => {
      let arr = [];
      const q = query(
        collection(db, "comment"),
        where("postId", "==", post?.id),
        where("isPublish", "==", true),
        orderBy("createdAt", "desc")
      );
      await getDocs(q).then((docs) =>
        docs.forEach((elm) => arr.push({ id: elm.id, ...elm.data() }))
      );
      setComments(arr);
    };
    post && init();
  }, [post]);

  return (
    <>
      <div
        className="flex space-x-3 h-10 rounded-lg w-full bg-back items-center justify-center hover:cursor-pointer border"
        onClick={onClick}
      >
        <div
          className={`${comments?.length ? "text-blue-400" : "text-gray-400"}`}
        >
          <MessageIcon />
        </div>
        <div className="text-blue-400">
          {comments?.length > 0 ? comments?.length : null}
        </div>
      </div>
    </>
  );
};

export default PostCommentsCount;
