import React, { useContext } from "react";
import { logChatPromiseExecution } from "stream-chat";
import {
  MessageList,
  MessageInput,
  Thread,
  Window,
  useChannelActionContext,
} from "stream-chat-react";

import MessagingChannelHeader from "../MessagingChannelHeader/MessagingChannelHeader";
import MessagingInput from "../MessagingInput/MessagingInputOnMovieChannel";
import { TypingIndicator } from "../TypingIndicator/TypingIndicator";

import { GiphyContext } from "../StreamChatOnMovieChannel";

export const ChannelInnerOnMovieChannel = (props) => {
  const {
    theme,
    toggleMobile,
    setIsUpdateMember,
    isUpdateMember,
    toggleMembers,
    headerNo,
  } = props;

  const { giphyState, setGiphyState } = useContext(GiphyContext);
  const { sendMessage } = useChannelActionContext();

  const overrideSubmitHandler = (message) => {
    let updatedMessage;
    // メッセージ内容が　giphy / 動画・画像　の時
    if (message.attachments?.length && message.text?.startsWith("/giphy")) {
      const updatedText = message.text.replace("/giphy", "");
      updatedMessage = { ...message, text: updatedText };
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...message, text: updatedText };
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };

      const sendMessagePromise = sendMessage(messageToSend);
      logChatPromiseExecution(sendMessagePromise, "send message");
    }

    setGiphyState(false);
  };

  const actions = ["delete", "reply"];

  const userClick = (e) => {
    console.log(e);
  };

  return (
    <>
      <Window>
        {/* チャンネルヘッダー */}
        {headerNo ? (
          ""
        ) : (
          <div>
            <MessagingChannelHeader
              theme={theme}
              toggleMobile={toggleMobile}
              setIsUpdateMember={setIsUpdateMember}
              isUpdateMember={isUpdateMember}
              toggleMembers={toggleMembers}
            />
          </div>
        )}

        <MessageList messageActions={actions} />
        <div className="pl-5 py-2">
          <TypingIndicator />
        </div>

        <MessageInput focus overrideSubmitHandler={overrideSubmitHandler} />
        <Thread Input={MessagingInput} fullWidth={true} />
      </Window>
    </>
  );
};
