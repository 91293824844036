import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import React, { useState, useEffect, memo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import Img from "../data/img/defaultImage.png";
import UserCard from "./UserCard";
import SwitchComponent from "./SwitchComponent";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import moment from "moment";
import SearchBar from "./SearchBar";
import FilterPanelOnAdminSettingUsersShop from "./FilterPanelOnAdminSettingUsersShop";

const UserShopListOnAdminSetting = () => {
  const q = query(
    collection(db, "requestShop"),
    orderBy("createdAt", "desc"),
    limit(12)
  );
  const [userShopsCollection] = useCollection(q);
  const usersShops = userShopsCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  const allQ = query(
    collection(db, "requestShop"),
    orderBy("createdAt", "desc")
  );
  const [userShopsAllCollection] = useCollection(allQ);
  const allUsersShops = userShopsAllCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const navigate = useNavigate();

  // filter関連
  const [searchInput, setSearchInput] = useState("");
  const [list, setList] = useState();
  const [researchCount, setResearchCount] = useState(0);
  const researchCountIncrement = () => {
    setResearchCount((prev) => prev + 1);
  };
  const [resultsFound, setResultsFound] = useState(true);

  const [values, setValues] = useState([
    { id: 1, checked: false, label: "未許可" },
  ]);
  const handleChangeChecked = (id) => {
    const valuesList = values;
    const changeCheckedValues = valuesList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setValues(changeCheckedValues);
  };

  // フィルタ実行
  const applyFilters = () => {
    let updatedList = allUsersShops;

    const valuesChecked = values.filter((item) => item.checked);

    if (!searchInput && !valuesChecked.length) {
      updatedList = usersShops;
    }

    if (valuesChecked.length) {
      updatedList = updatedList.filter((item) => item.isPermission === false);
    }

    // Search Filter
    if (searchInput) {
      updatedList = updatedList.filter(
        (item) =>
          (item.name + item.ownerName + item.address)
            .toLowerCase()
            .search(searchInput.toLowerCase().trim()) !== -1
      );
    }

    if (updatedList?.length) researchCountIncrement();
    setList(updatedList);

    !updatedList?.length ? setResultsFound(false) : setResultsFound(true);
  };

  // フィルタがある場合発動
  useEffect(() => {
    applyFilters();
  }, [searchInput, values]);

  return (
    <>
      <div className="h-[calc(100vh-20vh)] overflow-y-auto scrollbar-hide">
        {/* サーチボックス */}
        <div className=" sticky top-0 bg-back z-10 mb-4 pb-2">
          <SearchBar
            value={searchInput}
            changeInput={(e) => setSearchInput(e.target.value)}
            placeholder="ショップ名、ユーザ名、都道府県名..."
          />
        </div>

        <div className=" sticky top-12 z-10 bg-back w-full">
          <FilterPanelOnAdminSettingUsersShop
            values={values}
            changeChecked={handleChangeChecked}
          />
        </div>

        <div className="sticky top-20 z-10 bg-back w-full mb-2">{list?.length} 件</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
          {/* filter前 */}
          {!researchCount &&
            usersShops?.map((shop) => (
              <div className=" col-span-1 " key={Math.random()}>
                <div className="flex-col  rounded-md shadow-md hover:shadow-2xl  duration-150 border hover:border-blue-500">
                  {/* オーナー情報 */}
                  <div className="relative">
                    {/* イメージ */}
                    <img
                      src={shop.background || Img}
                      className="w-full h-48 rounded-t-md"
                      alt=""
                    />
                    {/* オーナー */}
                    <div className=" absolute bottom-2 right-2">
                      <div className="bg-white  opacity-80 text-opacity-0 font-bold text-black rounded-md">
                        <UserCard id={shop?.owner} />
                      </div>
                      {/* <Avatar src={owner?.avatar} /> */}
                    </div>
                    <div className=" absolute top-2 left-2  rounded-md bg-teal-700 text-white p-2 w-fit">
                      {shop.name}
                    </div>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        position: "absolute",
                        bottom: 6,
                        left: 6,
                        color: "white",
                        background: "rgba(0,0,0, .3)",
                        p: "5px",
                        borderRadius: 2,
                      }}
                    >
                      {moment(shop?.createdAt?.toDate()).fromNow()}
                    </Typography>
                  </div>
                  <div className=" relative p-3">
                    {/* 許可・不許可 */}
                    <div className="flex flex-col border rounded-md p-2 mt-5 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
                      <div className="font-bold">公開・非公開情報</div>
                      {/* 公開 */}
                      <div className="flex space-x-3 items-center">
                        <div>公開</div>
                        <div>
                          <SwitchComponent
                            id={shop.id}
                            target="isPublish"
                            collection="requestShop"
                            text="公開"
                          />
                        </div>
                      </div>
                      {/* 許可 disabled */}
                      <div className="flex space-x-3 items-center">
                        <div>許可</div>
                        <div>
                          <SwitchComponent
                            id={shop.id}
                            target="isPermission"
                            collection="requestShop"
                            text="許可"
                          />
                        </div>
                      </div>
                      <div className="text-xs text-gray-500">
                        [公開]・[許可] 共にオンの場合、マップに載ります
                      </div>
                    </div>
                    {/* 編集ボタン */}
                    <div
                      className=" absolute top-4 right-4 text-xs text-blue-600 hover:font-bold duration-150 hover:cursor-pointer"
                      onClick={() => navigate(`/mapPoint/${shop.id}`)}
                    >
                      編集
                    </div>
                    {/* ロケーション情報 */}
                    <div className="flex flex-col my-5 border rounded-md p-2 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
                      <div className="font-bold">ロケーション情報</div>
                      <div>住所：{shop.address}</div>
                      <div className={` ${!shop.lat && "text-red-600"}`}>
                        緯度：{shop.lat}
                      </div>
                      <div className={` ${!shop.lng && "text-red-600"}`}>
                        経度：{shop.lng}
                      </div>
                    </div>
                    <div className="flex flex-col my-5 border rounded-md p-2 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
                      <div className="font-bold">WEB・問い合わせ関連</div>
                      <div>TEL:{shop.tel}</div>
                      <div className=" whitespace-pre-wrap break-words">
                        URL:{shop.url}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* フィルター後 */}
          {/* フィルターかけて何もなかったら */}
          {researchCount > 0 && (!list || !list.length) && (
            <div>検索のイベントはありません。</div>
          )}
          {/* listになにかあるなら */}
          {researchCount > 0 &&
            list?.length > 0 &&
            list?.map((shop) => (
              <div className=" col-span-1" key={Math.random()}>
                <div className="flex-col  rounded-md shadow-md hover:shadow-2xl  duration-150 border hover:border-blue-500">
                  {/* オーナー情報 */}
                  <div className="relative">
                    {/* イメージ */}
                    <img
                      src={shop.background || Img}
                      className="w-full h-48 rounded-t-md"
                      alt=""
                    />
                    {/* オーナー */}
                    <div className=" absolute bottom-2 right-2">
                      <div className="bg-white  opacity-80 text-opacity-0 font-bold text-black rounded-md">
                        <UserCard id={shop?.owner} />
                      </div>
                      {/* <Avatar src={owner?.avatar} /> */}
                    </div>
                    <div className=" absolute top-2 left-2  rounded-md bg-teal-700 text-white p-2 w-fit">
                      {shop.name}
                    </div>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        position: "absolute",
                        bottom: 6,
                        left: 6,
                        color: "white",
                        background: "rgba(0,0,0, .3)",
                        p: "5px",
                        borderRadius: 2,
                      }}
                    >
                      {moment(shop?.createdAt?.toDate()).fromNow()}
                    </Typography>
                  </div>
                  <div className=" relative p-3">
                    {/* 許可・不許可 */}
                    <div className="flex flex-col border rounded-md p-2 mt-5 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
                      <div className="font-bold">公開・非公開情報</div>
                      {/* 公開 */}
                      <div className="flex space-x-3 items-center">
                        <div>公開</div>
                        <div>
                          <SwitchComponent
                            id={shop.id}
                            target="isPublish"
                            collection="requestShop"
                            text="公開"
                          />
                        </div>
                      </div>
                      {/* 許可 disabled */}
                      <div className="flex space-x-3 items-center">
                        <div>許可</div>
                        <div>
                          <SwitchComponent
                            id={shop.id}
                            target="isPermission"
                            collection="requestShop"
                            text="許可"
                          />
                        </div>
                      </div>
                      <div className="text-xs text-gray-500">
                        [公開]・[許可] 共にオンの場合、マップに載ります
                      </div>
                    </div>
                    {/* 編集ボタン */}
                    <div
                      className=" absolute top-4 right-4 text-xs text-blue-600 hover:font-bold duration-150 hover:cursor-pointer"
                      onClick={() => navigate(`/mapPoint/${shop.id}`)}
                    >
                      編集
                    </div>
                    {/* ロケーション情報 */}
                    <div className="flex flex-col my-5 border rounded-md p-2 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
                      <div className="font-bold">ロケーション情報</div>
                      <div>住所：{shop.address}</div>
                      <div className={` ${!shop.lat && "text-red-600"}`}>
                        緯度：{shop.lat}
                      </div>
                      <div className={` ${!shop.lng && "text-red-600"}`}>
                        経度：{shop.lng}
                      </div>
                    </div>
                    <div className="flex flex-col my-5 border rounded-md p-2 shadow-md hover:shadow-2xl duration-150 hover:border-blue-500 hover:cursor-pointer">
                      <div className="font-bold">WEB・問い合わせ関連</div>
                      <div>TEL:{shop.tel}</div>
                      <div className=" whitespace-pre-wrap break-words">
                        URL:{shop.url}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default memo(UserShopListOnAdminSetting);
