import { ImageList } from "@mui/material";
import React from "react";
import ProgressItems from "./ProgressItems";

const ProgressList = ({ files, targetCollection }) => {
  return (
    <>
      <ImageList rowHeight={250} cols={4}>
        {files.map((file, index) => (
          <ProgressItems file={file} targetCollection={targetCollection} key={index} />
        ))}
      </ImageList>
    </>
  );
};

export default ProgressList;
