import React, { useEffect, useState } from "react";
import { getDocs, collection, query, where, orderBy, doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const RunrusHistory = ({ uid }) => {
  const [runrus, setRunrus] = useState([]);
  const [users, setUsers] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedUID, setSelectedUID] = useState('');

  useEffect(() => {
    const fetchUser = async (uid) => {
      const userDoc = await getDoc(doc(db, "users", uid));
      return userDoc.exists() ? userDoc.data() : null;
    };

    const fetchUsersData = async (runrus) => {
      const userPromises = [];
      const userMap = {};

      runrus.forEach((runru) => {
        if (!users[runru.to]) {
          userPromises.push(fetchUser(runru.to).then((data) => {
            userMap[runru.to] = data || { firstName: "運営", lastName: "", avatar: "", uid: runru.to };
          }));
        }
        if (!users[runru.from]) {
          userPromises.push(fetchUser(runru.from).then((data) => {
            userMap[runru.from] = data || { firstName: "運営", lastName: "", avatar: "", uid: runru.from };
          }));
        }
      });

      await Promise.all(userPromises);
      setUsers((prevUsers) => ({ ...prevUsers, ...userMap }));
    };

    const fetchRunrus = async () => {
      const toQuery = query(
        collection(db, "runru"),
        where("to", "==", uid),
        orderBy("createdAt", "desc")
      );
      const fromQuery = query(
        collection(db, "runru"),
        where("from", "==", uid),
        orderBy("createdAt", "desc")
      );

      const [toDocs, fromDocs] = await Promise.all([
        getDocs(toQuery),
        getDocs(fromQuery),
      ]);

      const toRunrus = toDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const fromRunrus = fromDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const combinedRunrus = [...toRunrus, ...fromRunrus];
      const uniqueRunrus = combinedRunrus.filter(
        (runru, index, self) => index === self.findIndex((r) => r.id === runru.id)
      );

      await fetchUsersData(uniqueRunrus);
      setRunrus(uniqueRunrus);
    };

    fetchRunrus();
  }, [uid]);

  const truncateName = (name) => {
    return name.length > 10 ? name.substring(0, 10) + '...' : name;
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月`;
  };

  const handleClickOpen = (uid) => {
    setSelectedUID(uid);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderRunrus = () => {
    let currentMonth = '';
    let monthCount = 0;

    return runrus.map((runru, index) => {
      const runruDate = runru.createdAt.toDate();
      const runruMonth = formatDate(runruDate);

      const isDifferentMonth = currentMonth !== runruMonth;
      if (isDifferentMonth) {
        monthCount = 0;
        for (let i = index; i < runrus.length; i++) {
          if (formatDate(runrus[i].createdAt.toDate()) === runruMonth) {
            monthCount++;
          } else {
            break;
          }
        }
      }

      currentMonth = runruMonth;

      const monthHeader = isDifferentMonth ? (
        <Typography variant="h6" key={runruMonth} style={{ marginTop: '1rem' }}>
          {runruMonth} ({monthCount} 件)
        </Typography>
      ) : null;

      return (
        <React.Fragment key={runru.id}>
          {monthHeader}
          <Paper
            elevation={3}
            style={{
              marginBottom: "1rem",
              padding: "0.5rem",
              border: "1px solid #ccc",
              transition: "background-color 1.5s, box-shadow 1.5s",
              cursor: "pointer"
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#f5f5f5";
              e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "#fff";
              e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2}>
                <Typography variant="body2">
                  <strong>日時:</strong> {runruDate.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {users[runru.from] ? (
                  <Box display="flex" alignItems="center">
                    <Avatar src={users[runru.from].avatar} alt={users[runru.from].firstName} style={{ marginRight: "4px" }} />
                    <Box>
                      <Typography variant="body2">
                        {truncateName(`${users[runru.from].lastName} ${users[runru.from].firstName}`)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        送信者
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <CircularProgress size={20} />
                )}
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ArrowForwardIcon color="primary" />
                  <Typography variant="body2">
                    {runru.amount}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                {users[runru.to] ? (
                  <Box display="flex" alignItems="center" onClick={() => handleClickOpen(users[runru.to].uid)}>
                    <Avatar src={users[runru.to].avatar} alt={users[runru.to].firstName} style={{ marginRight: "4px" }} />
                    <Box>
                      <Typography variant="body2">
                        {truncateName(`${users[runru.to].lastName} ${users[runru.to].firstName}`)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        受信者
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <CircularProgress size={20} />
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  <strong>理由:</strong> {runru.cause}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            <strong>Runru履歴:</strong> ({runrus.length} 件)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {runrus.length ? (
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {renderRunrus()}
            </ul>
          ) : (
            <Typography variant="body2">
              まだ履歴がありません
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>受信者のUID</DialogTitle>
        <DialogContent>
          <DialogContentText>
            UID: {selectedUID}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RunrusHistory;
