import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../api/firebase";

const DisplayQuestionHistoryCount = ({ doc }) => {
  const [historyCount, setHistoryCount] = useState("");
  useEffect(() => {
    const init = async () => {
      const histories = await getDocs(
        collection(db, "questionForAdmin", doc.id, "history")
      );

      setHistoryCount(histories.size);
      //   console.log("histories =>", histories.size);
    };

    doc && init();
  }, [doc]);

  return (
    <>
      {historyCount === 0 ? (
        ""
      ) : (
        <div className="bg-blue-500 rounded-full text-white px-2 items-baseline">
          {historyCount}
        </div>
      )}
    </>
  );
};

export default DisplayQuestionHistoryCount;
