import React ,{useEffect}from "react";
import { useNavigate } from "react-router-dom";

import { ArrowLeftIcon } from "@heroicons/react/solid";
import HeaderDrawerMenuLists from "./HeaderDrawerMenuLists";

const HeaderDrawerMenu = ({ toggleOpen, open, setOpen }) => {

  //   遷移系
  const navigate = useNavigate();
  const handleMoveProfile = () => {
    navigate("/profile");
    toggleOpen(false);
  };


  if (typeof setOpen !== "function") {
    return null; // setOpen が関数でない場合は、コンポーネントをレンダリングしない
  }


  return (
    <>
      <div className="w-96 bg-back">
        <div className="flex flex-col p-2 m-5  ">
          {/* トグルボタン */}
          <div
            className="h-10 w-10 hover:cursor-pointer hover:bg-gray-200 rounded-full"
            onClick={toggleOpen}
          >
            <ArrowLeftIcon />
          </div>
          {/* ドロワーコンテンツ */}
          <HeaderDrawerMenuLists open={open}setOpen={setOpen}/>
        </div>
      </div>
    </>
  );
};

export default HeaderDrawerMenu;
