import React from "react";

const ComparisonMembers = () => {
  return (
    <>
      <div className="flex flex-col h-[calc(100vh-17vh)] ">
        <div className="flex-grow overflow-auto scrollbar-hide ">
          <table className="relative w-full border-collapse border border-slate-200 ">
            <thead>
              <tr className="">
                <th className="sticky top-0 py-10 bg-back"></th>
                <th className="sticky top-0 py-10 bg-back">Free</th>
                <th className="sticky top-0 py-10 bg-back">Users</th>
                <th className="sticky top-0 py-10 bg-back">Members</th>
              </tr>
            </thead>

            {/* 動画 */}
            <thead>
              <tr>
                <th
                  colSpan={4}
                  className="sticky top-[88px] px-6 py-3 bg-gray-200"
                >
                  動画
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">視聴</td>
                <td className="px-6 py-4 text-center bg-gray-50">
                  <div className="flex flex-col">
                    <div>×</div>
                    <div className="text-xs text-gray-400">一部視聴可</div>
                  </div>
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">
                  <div className="flex flex-col">
                    <div>×</div>
                    <div className="text-xs text-gray-400">一部視聴可</div>
                  </div>
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">コメント</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">
                  お気に入り
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
            </tbody>

            {/* DDP */}
            <thead>
              <tr>
                <th
                  colSpan={4}
                  className="sticky top-[88px] px-6 py-3 bg-gray-200"
                >
                  DDP
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">閲覧</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">交換</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">
                  ルンル配布
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
            </tbody>

            {/* マップ */}
            <thead>
              <tr>
                <th
                  colSpan={4}
                  className="sticky top-[88px] px-6 py-3 bg-gray-200"
                >
                  マップ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">閲覧</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">詳細</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">
                  自分のお店登録
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
            </tbody>

            {/* 拠点 */}
            <thead>
              <tr>
                <th
                  colSpan={4}
                  className="sticky top-[88px] px-6 py-3 bg-gray-200"
                >
                  拠点
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">閲覧</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">参加</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">
                  イベント作成
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">
                  イベント参加
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">コメント</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
            </tbody>

            {/* グループ */}
            <thead>
              <tr>
                <th
                  colSpan={4}
                  className="sticky top-[88px] px-6 py-3 bg-gray-200"
                >
                  グループ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">閲覧</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">作成</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">参加</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>

              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">
                  イベント作成
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">
                  イベント参加
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>

              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">コメント</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
            </tbody>

            {/* イベント */}
            <thead>
              <tr>
                <th
                  colSpan={4}
                  className="sticky top-[88px] px-6 py-3 bg-gray-200"
                >
                  イベント
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">閲覧</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">参加</td>
                <td className="px-6 py-4 text-center bg-gray-50">
                  <div className="flex flex-col">
                    <div>〇</div>
                    <div className="text-xs text-gray-400">有料の場合あり</div>
                  </div>
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">
                  <div className="flex flex-col">
                    <div>〇</div>
                    <div className="text-xs text-gray-400">有料の場合あり</div>
                  </div>
                </td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">コメント</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
            </tbody>

            {/* チャット */}
            <thead>
              <tr>
                <th
                  colSpan={4}
                  className="sticky top-[88px] px-6 py-3 bg-gray-200 "
                >
                  チャット
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">閲覧</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">グループ作成</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
              <tr>
                <td className="px-6 py-4 text-center bg-gray-100">書き込み</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">×</td>
                <td className="px-6 py-4 text-center bg-gray-50">〇</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* <table className=" relative w-full">
        <thead classNameName="">
          <tr>
            <th classNameName=" sticky top-0"></th>
            <th classNameName=" sticky top-0 py-5">
              <div classNameName="flex border border-red-500 bg-red-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit text-red-700">
                Free
              </div>
            </th>
            <th classNameName=" sticky top-0 py-5">
              <div classNameName="flex border border-blue-500 bg-blue-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit text-blue-700">
                Users
              </div>
            </th>
            <th classNameName=" sticky top-0 py-5">
              <div classNameName="flex border border-green-500 bg-green-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit text-green-700">
                Members
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1961</td>
            <td>1961</td>
            <td>1961</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1961</td>
            <td>1961</td>
            <td>1961</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
          <tr>
            <td classNameName="p-4">DDP取引</td>
            <td>1975</td>
            <td>1975</td>
            <td>1975</td>
          </tr>
        </tbody>
      </table> */}
    </>
  );
};

export default ComparisonMembers;
