import React, { useEffect, useState } from "react";
import {
  doc,
  updateDoc,
  serverTimestamp,
  getDocs,
  collection,
  where,
  query,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

import { addNotification } from "./addNotification";

import { useDocumentData } from "react-firebase-hooks/firestore";

// MaterilUI
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/auth";

const EditAnnouncePublishDate = ({ setOpen, requestId }) => {

    // 値格納コンテナ
  const [data, setData] = useState({
    publishDate: "",
    message: "",
    error: null,
    loading: false,
  });
  const { publishDate, message, error, loading } = data;

  const [value, setValue] = React.useState(dayjs());

  const handleChange = (newValue) => {
    setValue(newValue);
    setData({ ...data, publishDate: value, message: "" });
  };


  // 変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "notificationOfTop", requestId), {
        publishDate: new Date(value),
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">表示期限変更</div>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              label="表示期限"
              value={value}
              disablePast
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>

        <Button
          className="btn"
          name="publishDate"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditAnnouncePublishDate;
