import { db } from "../api/firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { getOrCreateDeviceId } from "./deviceId";

const parseDeviceDetails = async (userAgent) => {
  let deviceType = "desktop";
  let os = "unknown";
  let deviceDetail = "unknown";
  let model = "unknown";
  let platformVersion = "unknown";

  if (/mobile/i.test(userAgent)) {
    deviceType = "mobile";
    if (/Android/i.test(userAgent)) {
      os = "Android";
      const matchVersion = userAgent.match(/Android\s(\d+(?:\.\d+)?)/i);
      const version = matchVersion ? matchVersion[1] : "unknown";
      // Androidデバイスのモデルとプラットフォームバージョンを取得する試み
      if (navigator.userAgentData) {
        try {
          const uaDataValues = await navigator.userAgentData.getHighEntropyValues(
            ["model", "platformVersion"]
          );
          model = uaDataValues.model || "unknown";
          platformVersion = uaDataValues.platformVersion || "unknown";
          deviceDetail = `${model} ${platformVersion}`;
        } catch (error) {
          console.error("Error getting high entropy values:", error);
        }
      }
    }
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      os = "iOS";
      const iosDevice = /iPad/i.test(userAgent) ? "iPad" : "iPhone";
      const matchVersion = userAgent.match(/OS\s([0-9_]+)(?=\s)/i);
      let version = matchVersion
        ? matchVersion[1].replace(/_/g, ".")
        : "unknown";
      // Split the version to limit to one decimal place if necessary
      const versionComponents = version.split(".");
      if (versionComponents.length > 2) {
        version = `${versionComponents[0]}.${versionComponents[1]}`; // Keep only up to the first decimal place
      }
      deviceDetail = `${iosDevice} ${version}`;
      model = iosDevice; // iOSではUserAgentから直接モデルを判定
    }
  }

  return { deviceType, os, deviceDetail, model, platformVersion };
};

export async function registerDevice(userId) {
  const deviceId = getOrCreateDeviceId();
  const userAgent = navigator.userAgent;
  const {
    deviceType,
    os,
    deviceDetail,
    model,
    platformVersion,
  } = await parseDeviceDetails(userAgent);

  const deviceRef = doc(db, "users", userId, "devices", deviceId);

  await setDoc(
    deviceRef,
    {
      deviceId,
      lastActive: serverTimestamp(),
      isOnline: true,
      userId,
      userAgent,
      deviceType,
      os,
      deviceDetail,
      model,
      platformVersion,
    },
    { merge: true }
  );
}
