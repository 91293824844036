import {
  collection,
  getDocs,
  limit,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../api/firebase";
import DisplayEventFromGroupForAll from "./DisplayEventFromGroupForAll";
import HorizontalEventMenuOfHomeTop from "./HorizontalEventMenuOfHomeTop";

const NotificationFeed = () => {
  const navigate = useNavigate();

  // 現在の日付を取得し、1日加算
  const targetDate = new Date();
  targetDate.setDate(targetDate.getDate() - 1);

  // イベント取得、5件取得
  const q = query(
    collection(db, "event"),
    where("publish", "==", true),
    where("publishTarget", "==", "all"),
    where("publishDate", ">=", targetDate),
    orderBy("publishDate", "asc"),
    limit(5)
  );
  const [groupEventForAll, setGroupEventForAll] = useState([]);

  // 全体向けのグループイベント取得
  useEffect(() => {
    let arr = [];
    getDocs(q).then((docs) => {
      docs?.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setGroupEventForAll(arr);
    });
  }, []);

  // スマホ用、2件取得
  const q_min = query(
    collection(db, "event"),
    where("publish", "==", true),
    where("publishTarget", "==", "all"),
    where("publishDate", ">=", targetDate),
    orderBy("publishDate", "asc"),
    limit(2)
  );
  const [groupEventForTwo, setGroupEventForTwo] = useState([]);
  useEffect(() => {
    let arr = [];
    getDocs(q_min).then((docs) => {
      docs?.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setGroupEventForTwo(arr);
    });
  }, []);

  return (
    <div className="bg-white border shadow-sm rounded-md py-1 mx-2">
      {!groupEventForAll.length && <div>イベント情報はありません。</div>}
      {groupEventForAll.length && (
        <HorizontalEventMenuOfHomeTop
          contents={groupEventForAll}
          key={Math.random()}
        />
      )}
      {groupEventForAll.length >= 3 && (
        <div
          className="flex text-blue-600 justify-end px-3 hover:font-bold hover:cursor-pointer hover:underline"
          onClick={() => navigate("/events")}
        >
          もっと見る
        </div>
      )}
    </div>
  );
};

export default memo(NotificationFeed);
