import React, { useState, useEffect } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { Avatar } from "@mui/material";
import ModalForm from "./ModalForm";
import { useNavigate } from "react-router-dom";
import DisplayQuestionHistoryCount from "./DisplayQuestionHistoryCount";

const DisplayQuestionForAdmin = ({ arr }) => {
  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");
  const handleEdit = (e, id) => {
    // console.log("e =>", e);
    // console.log("url =>", url);
    setOpen(true);
    setTarget(id);
    setForm(e);
  };

  const navigate = useNavigate();

  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} target={target} />
      {arr?.map((doc) => (
        <div
          key={Math.random()}
          className="flex flex-col space-y-2 rounded-md border p-2 my-2 shadow-sm hover:shadow-lg duration-200 hover:cursor-pointer "
          onClick={
            !doc.isResolution
              ? () => handleEdit("updateQuestionForAdmin", doc.id)
              : () => handleEdit("displayInquiryHistory", doc.id)
          }
        >
          <div className="flex space-x-2 items-center">
            <div className=" text-blue-500 text-xs">
              {isInvalidDate(new Date(doc?.lastUpdatedAt?.toDate()))
                ? "読み込み中"
                : formatDistanceToNow(doc?.lastUpdatedAt?.toDate(), {
                    locale: ja,
                  })}
            </div>
            <div
              className={`${
                doc.isResolution ? "bg-blue-500 " : "bg-red-500"
              } " rounded-md text-white p-1 text-xs "`}
            >
              {doc.isResolution === false ? "未解決" : "解決"}
            </div>
            <div className=" bg-gray-700 text-white w-fit px-1">
              {doc.questionType}
            </div>
            <DisplayQuestionHistoryCount doc={doc} />
          </div>
          <div className="flex space-x-2 items-center ">
            <div
              className="flex flex-col items-center justify-center"
              onClick={() => navigate("/user/" + doc.questionerId)}
            >
              <div>
                <Avatar src={doc.questionerAvatar} sizes="small" />
              </div>
              <div>{doc.questionerName}</div>
            </div>
            <div>{doc.question}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DisplayQuestionForAdmin;
