import React from "react";
import AddComponent from "./AddComponent";
import SupportTable from "./SupportTable";


const OperateSupport = () => {
  return (
    <>
      <div className="font-bold text-xl">サポート</div>
      <AddComponent value="サポート作成" target="createSupport" />
      <SupportTable />
    </>
  );
};

export default OperateSupport;
