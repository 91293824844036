import React, { useEffect, useState } from "react";
import {
  doc,
  collection,
  query,
  updateDoc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";

import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import LoaderHeaderPic from "./LoaderHeaderPic";
import Loader1Row from "./Loader1Row";
import { PencilIcon } from "@heroicons/react/solid";
import ModalForm from "./ModalForm";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import GetUserInfo from "./GetUserInfo";

import EditIcon from "@mui/icons-material/Edit";

const AnnounceTable = () => {
  const [basesCount, loading] = useCollection(collection(db, "bases"));

  const [dataloading, setDataLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [d_loding, setD_loading] = useState(false);
  const [originalRows, setOriginalRows] = useState([]);

  // 表示・非表示
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e, id) => {
    // console.log(e);
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  const queryBases = query(
    collection(db, "notificationOfTop"),
    orderBy("createdAt", "desc")
  );
  const [announceData] = useCollection(queryBases);
  const announces = announceData?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  useEffect(() => {
    setOriginalRows(announces);
    setRows(announces);
  }, [announceData]);

  // console.log(rows[0]?.chiefLastName)
  // bases?.forEach((doc)=>{
  //   console.log(doc?.chiefLastName)
  //   const d = getDoc(doc(db,'doc.chiefLastName'))
  //   console.log(d)
  // })

  // テーブル使用コンテナ
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // テーブル使用handle
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   権限変更関連handle
  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "notificationOfTop", id));
    await updateDoc(doc(db, "notificationOfTop", id), {
      [type]: !pub.data()[type],
    });
    setDataLoading(true);
    setD_loading(false);
  };
  const handleChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  // 検索関連
  const requestSearch = (searchVal) => {
    const filteredRows = originalRows.filter((row) => {
      return row.title
        .toLowerCase()
        .includes(searchVal.target.value.toLowerCase());
    });
    setRows(filteredRows);
  };

  // 日付のInvalidDateを防ぐ関数
  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  const navigate = useNavigate();

  const handleMove = (id) => {
    navigate("/announce/" + id);
    // console.log("content =>", content);
  };

  if (loading) {
    return <Loader1Row />;
  }

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        target="base"
        requestId={requestId}
      />
      {/* <DeleteColumn display={bases} requestId={requestId} target="bases" /> */}
      {/* <HereIsThisOnMap
        display={editGeocode}
        requestId={requestId}
        target="bases"
      /> */}
      <TextField
        id="search-bar"
        className="my-2"
        label="アナウンス検索"
        variant="standard"
        placeholder="拠点"
        size="small"
        onChange={(searchVal) => requestSearch(searchVal)}
        sx={{ m: 1.5 }}
      ></TextField>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 240, borderRadius: 1 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            sx={{ minWidth: 650 }}
          >
            <TableHead>
              {/* ヘッダー情報 */}
              <TableRow>
                <TableCell>掲載</TableCell>
                <TableCell></TableCell>
                <TableCell>タイトル</TableCell>
                <TableCell>概要</TableCell>
                <TableCell>固定</TableCell>
                <TableCell>表示期限</TableCell>
                <TableCell>作成</TableCell>
                <TableCell>作成日</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  className="flex group"

                >
                  {/* 掲載 */}
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.isPublish}
                          onChange={handleChange}
                          name="isPublish"
                          disabled={d_loding}
                          value={row.id}
                        />
                      }
                      label={row.isPublish ? "掲載" : ""}
                    />
                  </TableCell>

                  {/* 編集 */}
                  <TableCell align="left">
                    <div
                      className="text-blue-500 hover:cursor-pointer hover:underline hover:font-bold"
                      onClick={() => handleMove(row.id)}
                    >
                      編集
                    </div>
                  </TableCell>

                  {/* title */}
                  <TableCell align="left">
                    <div className="flex space-x-2">
                      <div>{row.title}</div>
                      <EditIcon
                        className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                        onClick={() => handleEdit("editAnnounceTitle", row.id)}
                      />
                    </div>
                  </TableCell>

                  {/* title */}
                  <TableCell align="left">
                    <div className="flex space-x-2">
                      <div>{row.content}</div>
                      <EditIcon
                        className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                        onClick={() => handleEdit("editAnnounceContent", row.id)}
                      />
                    </div>
                  </TableCell>



                  {/* limit */}
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.limit}
                          onChange={handleChange}
                          name="limit"
                          disabled={d_loding}
                          value={row.id}
                        />
                      }
                      label={row.limit ? "固定" : ""}
                    />
                  </TableCell>

                  {/* <TableCell align="center">{row.limit ? "〇" : "×"}</TableCell> */}

                  {/* 表示期限 */}
                  <TableCell align="left">
                    <div>
                      {new Date(row.publishDate?.toDate()).toLocaleString()}
                    </div>

                    <div className="flex space-x-2">
                      <div
                        className="mb-4"
                        onClick={() => handleEdit("editAnnouncePublishDate",row.id)}
                      >
                        <div className="hover:shadow-xl hover:cursor-pointer hover:bg-gray-200 rounded-full p-2 w-10 h-10">
                          <EditIcon />
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  {/* 作成者 */}
                  <TableCell component="th" scope="row">
                    {/* 村長のアバター */}
                    <div className="flex space-x-2 items-center">
                      <GetUserInfo
                        uid={row.creator}
                        target="avatar"
                        size={40}
                      />
                      <GetUserInfo uid={row.creator} target="name" />
                    </div>
                  </TableCell>

                  {/* 登録日 */}
                  <TableCell align="left">
                    <Grid className="">
                      <div className="flex space-x-1">
                        <div className=" text-blue-500 text-xs">
                          {isInvalidDate(new Date(row.createdAt?.toDate()))
                            ? "読み込み中"
                            : formatDistanceToNow(row.createdAt?.toDate(), {
                                locale: ja,
                              })}
                          前
                        </div>
                        <div className="text-gray-300 text-xs">
                          {new Date(row.createdAt?.toDate()).toLocaleString()}
                        </div>
                      </div>
                    </Grid>
                  </TableCell>

                  {/* 消去 */}
                  <TableCell align="left">
                    <DeleteIcon
                      name={row.id}
                      className=" sm:invisible sm:group-hover:visible cursor-pointer"
                      // onClick={() => handleRequestShop(row.id)}
                      onClick={() => handleEdit("deleteAnnounce", row.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="ml-2 mt-2">
          {/* 掲載店舗：{publish.docs.length} / {realtimeRequestShop.docs.length} */}
        </div>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={basesCount.docs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default AnnounceTable;

// テーブル検索
// https://smartdevpreneur.com/the-easiest-way-to-implement-material-ui-table-search/
