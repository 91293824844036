// src/hooks/useAuthListener.js
import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../api/firebase';
import { registerDevice } from '../utils/registerDevice';

export function useAuthListener() {
  useEffect(() => {
    // 認証状態の変更を監視するリスナーを設定
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // ユーザーがログインしていれば、そのユーザーのUIDを使ってデバイス登録
        registerDevice(user.uid);
      }
    });

    // コンポーネントのクリーンアップ時にリスナーを解除
    return () => unsubscribe();
  }, []);

  // このフックでは、特に返す値はない
}
