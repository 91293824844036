import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../api/firebase";
import CarouselProductImages from "./CarouselProductImages";
import DealMessages from "./DealMessages";
import { Divider } from "@mui/material";
import BuyDealAction from "./BuyDealAction";
import BuyerDealAction from "./BuyerDealAction";
import { Avatar } from "@mui/material";
// redux - use
import { useNotification } from "../redux/useNotification";
import Loader1Row from "./Loader1Row";
import { useLocation } from "react-router-dom";
import DealChat from "./DealChat";
import CompulsionDealStop from "./CompulsionDealStop";

const Deal = () => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();

  // 取引(deal)取得
  const [deal] = useDocumentData(doc(db, "deal", id));
  // DDP(product)取得
  const [product, setProduct] = useState("");
  useEffect(() => {
    if (deal) {
      getDoc(doc(db, "products", deal?.product)).then((doc) => {
        setProduct(doc.data());
      });
    }
  }, [deal]);

  useEffect(() => {
    window.addEventListener("popstate", function () {
      navigate("/profile", { state: location.state });
    });
  }, []);

  //   買った人(buy),買われた人(buyer)取得
  const [buy, setBuy] = useState("");
  const [buyer, setBuyer] = useState("");
  useEffect(() => {
    if (deal) {
      getDoc(doc(db, "users", deal?.buy)).then((doc) => {
        setBuy(doc.data());
      });
      getDoc(doc(db, "users", deal?.buyer)).then((doc) => {
        setBuyer(doc.data());
      });
    }
  }, [deal]);

  // 自分の取引じゃないのに、URLで直接この取引に入ってきた場合
  if (
    !buy?.uid === auth.currentUser.uid &&
    !buyer?.uid === auth.currentUser.uid
  ) {
    navigate("/home");
    displayNotification({
      message: "アクセスできません。",
      type: "error",
    });
  }

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const init = async () => {
      const userRef = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userRef.exists()) {
        setUserInfo({ id: userRef.id, ...userRef.data() });
      }
    };
    init();
  }, []);

  // console.log("deal.status =>", deal?.status)

  const handleToMove = (buy) => {
    navigate("/user/" + buy?.uid);
  };

  if (!deal) {
    return (
      <>
        <div>このページは存在しません。</div>
      </>
    );
  }

  if (
    buy?.uid !== auth.currentUser.uid &&
    buyer?.uid !== auth.currentUser.uid &&
    !userInfo?.isAdmin
  )
    return (
      <>
        <div>このページの閲覧権限がありません。</div>
      </>
    );

  // console.log("deal =>", deal);

  return (
    <>
      {/* スマホ表示 */}
      <div className=" sm:hidden">
        <div className="flex flex-col space-y-2">
          {/* 種類・対象 */}
          <div className="text-gray-300">
            {deal?.productOffering} / {deal?.productTarget}
          </div>
          {/* 画像 */}
          <CarouselProductImages productId={deal?.product} size="middle" />
          {/* タイトル */}
          <div className="text-3xl font-bold">{deal?.productName}</div>
          {/* 価格 */}
          <div className="flex items-baseline justify-between">
            <div className="flex items-baseline">
              <div className="text-blue-400 text-2xl">{deal?.amount}</div>
              <div className=" text-xs text-gray-500">ルンル</div>
            </div>
            <div>配送方法:{deal?.delivery}</div>
          </div>
          {/* 提供者情報 */}
          <div className="flex flex-col m">
            <div className="font-bold text-xl">DDP提供者</div>
            <div className="flex items-center rounded-md border mx-2  space-x-3 p-2">
              <Avatar src={buyer?.avatar}></Avatar>
              <div>
                {buyer.lastName}
                {buyer.firstName}
              </div>
            </div>
          </div>
          {/* 交換希望者情報 */}
          <div className="flex flex-col">
            <div className="font-bold text-xl">交換希望者</div>
            <div className="flex items-center rounded-md border mx-2  space-x-3 p-2">
              <Avatar src={buy?.avatar}></Avatar>
              {/* <div
                className="hover:underline hover:cursor-pointer"
                onClick={() => handleToMove(buy)}
              > */}
              <div>
                {buy.lastName}
                {buy.firstName}
                <div
                  className="text-xs text-blue-500"
                  onClick={() => handleToMove(buy)}
                >
                  プロフィールを見る
                </div>
              </div>
            </div>
          </div>

          <div>取引状況</div>
          {/* 自分が買った側(buy)だった場合 */}
          {buy?.uid === auth.currentUser.uid && (
            <BuyDealAction status={deal?.status} />
          )}
          {/* 自分が売った側(buyer)だった場合 */}
          {buyer?.uid === auth.currentUser.uid && (
            <BuyerDealAction status={deal?.status} />
          )}
          {userInfo?.isAdmin && (
            <>
              <div className="border w-full rounded-md p-4 mt-2">
                <div className=" font-bold">
                  管理者閲覧用（ユーザから見えません。）
                </div>
                <div>
                  交換希望者から見た状況：
                  <BuyDealAction status={deal?.status} />
                </div>
                <div>
                  DDP提供者から見た状況
                  <BuyerDealAction status={deal?.status} />
                </div>
                {deal?.status !== "adminCompulsionDealStop" && (
                  <div>
                    管理者専用
                    <CompulsionDealStop />
                  </div>
                )}
              </div>
            </>
          )}

          {/* 管理者チェック用 */}
          {/* {userInfo?.isAdmin && <DealMessages dealId={id} />} */}

          {/* 取引メッセージメッセージ欄 */}
          {deal?.status === "buyCanceled" ||
          deal?.status === "buyerCanceled" ||
          deal?.status === "buyEvaluted" ||
          deal?.status === "buyerEvaluted" ||
          deal?.status === "finished" ? (
            ""
          ) : (
            <div>
              <Divider>取引連絡</Divider>
              <div className="text-xs text-red-400">
                ※2023/12/16以降に交換したDDPでのチャットは通知が届きます。
              </div>

              {userInfo && <DealChat dealId={id} user={userInfo} />}

              <div className="text-xs text-red-400">
                ※以下のDDPチャットは今後ご使用不能となります。上記チャットのご使用をお願い申し上げます。
              </div>

              <DealMessages dealId={id} />
            </div>
          )}
        </div>
      </div>

      {/* スマホ以上（640以上表示） */}
      <div className="hidden sm:block mx-20">
        <div className="p-2">
          {/* タイプとレート */}
          <div className="flex text-gray-300">
            <div>
              {product?.offering}/{product?.target}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2">
            {/*　【左】 画像　カルーセル */}
            <CarouselProductImages productId={id} size="xl" />
            {/*　【右】 タイトルなど */}
            <div>
              <div className="flex flex-col space-y-3">
                {/* タイトルとQRコード */}
                <div className="flex justify-between my-2 ">
                  <div className="text-3xl">{product?.title}</div>
                </div>
                <Divider></Divider>

                {/* 価格（ルンル） */}
                <div className="flex items-baseline">
                  <div>{/* <AttachMoneyIcon /> */}</div>
                  <div className=" text-7xl">{product?.runru}</div>
                  <div>ルンル</div>
                </div>
                {/* 配送情報 */}
                <div className="flex">
                  <div className="font-bold text-xl">配送方法：</div>
                  <div>{product?.delivery}</div>
                </div>

                {/* 提供者情報 */}
                <div className="flex flex-col">
                  <div className="font-bold text-xl">DDP提供者</div>
                  <div className="flex items-center rounded-md border space-x-3 p-2">
                    <Avatar src={buyer?.avatar}></Avatar>
                    <div>
                      {buyer.lastName}
                      {buyer.firstName}
                    </div>
                  </div>
                </div>

                {/* 交換希望者情報 */}
                <div className="flex flex-col">
                  <div className="font-bold text-xl">交換希望者</div>
                  <div className="flex items-center rounded-m border space-x-3 p-2">
                    <Avatar src={buy?.avatar}></Avatar>
                    <div
                      className="hover:underline hover:cursor-pointer"
                      onClick={() => handleToMove(buy)}
                    >
                      {buy.lastName}
                      {buy.firstName}
                    </div>
                  </div>
                </div>

                {/* DDPの説明 */}
                <div className="flex flex-col">
                  <div className="font-bold text-xl">提供者によるDDPの説明</div>
                  <div>{product?.explain}</div>
                </div>

                {/* みんな評価 */}
                {/* <div className="flex flex-col">
                  <div className="font-bold text-xl">みんな評価</div>
                  <div>評価はまだありません</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div>取引状況</div>
        {/* 自分が買った側(buy)だった場合 */}
        {buy?.uid === auth.currentUser.uid && (
          <BuyDealAction status={deal?.status} />
        )}
        {/* 自分が売った側(buyer)だった場合 */}
        {buyer?.uid === auth.currentUser.uid && (
          <BuyerDealAction status={deal?.status} />
        )}

        {userInfo?.isAdmin && (
          <>
            <div className="border w-full rounded-md p-4 mt-2">
              <div className=" font-bold">
                管理者閲覧用（ユーザから見えません。）
              </div>
              <div>
                交換希望者から見た状況：
                <BuyDealAction status={deal?.status} />
              </div>
              <div>
                DDP提供者から見た状況
                <BuyerDealAction status={deal?.status} />
              </div>
              {deal?.status !== "adminCompulsionDealStop" && (
                <div>
                  管理者専用
                  <CompulsionDealStop />
                </div>
              )}
            </div>
          </>
        )}

        {/* 管理者チェック用 */}
        {/* {userInfo?.isAdmin && <DealMessages dealId={id} />} */}

        {/* 取引メッセージメッセージ欄 */}
        {deal?.status === "buyCanceled" ||
        deal?.status === "buyerCanceled" ||
        deal?.status === "buyEvaluted" ||
        deal?.status === "buyerEvaluted" ||
        deal?.status === "finished" ? (
          ""
        ) : (
          <div>
            <Divider>取引連絡</Divider>
            <div className="text-xs text-red-400">
              ※2023/12/16以降に交換したDDPでのチャットは通知が届きます。
            </div>

            {userInfo && <DealChat dealId={id} user={userInfo} />}

            <div className="text-xs text-red-400">
              ※以下のDDPチャットは今後ご使用不能となります。上記チャットのご使用をお願い申し上げます。
            </div>

            <DealMessages dealId={id} />
          </div>
        )}
      </div>
    </>
  );
};

export default Deal;
