import React, { useState } from "react";
// import './styles.css';
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({ value, changeInput, placeholder }) => {
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
  };
  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <>
      <div className={` ${focus && "border-blue-500"}  " flex  items-center rounded-md bg-white border p-2 w-full"`}>
        <SearchIcon className="h-6 text-gray-600" />
        <input
          style={{ fontSize: "16px" }}
          className=" md:inline-flex ml-2 w-full items-center bg-transparent outline-none  "
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={changeInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </>
  );
};

export default SearchBar;
