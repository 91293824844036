import React, { useState } from "react";
import {
  TwitterIcon,
  TwitterShareButton,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
} from "react-share";
import ModalForm from "./ModalForm";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Tooltip } from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import LinkIcon from '@mui/icons-material/Link';
// redux - use
import { useNotification } from "../redux/useNotification";

const SNSList = ({ title }) => {
  // redux - Notification
  const { displayNotification } = useNotification();
  // ModalForm
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const handleModalOpen = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  var currentURL = window.location.pathname; // 現在のURLのパス部分を取得
  // let path = "https://sekaimura-add3c.firebaseapp.com" + currentURL;
  let path = "https://sekaimura.net" + currentURL;

  const [resultText, setResultText] = useState(path);

  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(resultText);
    displayNotification({
      message: "URLをコピーしました",
    });
  };

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />

      <div className="flex space-x-6 justify-end items-center">
        <TwitterShareButton url={window.location.href} title={title}>
          <TwitterIcon
            size={32}
            round={true}
            className="hover:opacity-40 duration-150"
          />
        </TwitterShareButton>
        <LineShareButton url={window.location.href} quote={title}>
          <LineIcon
            size={32}
            round={true}
            className="hover:opacity-40 duration-150"
          />
        </LineShareButton>
        <FacebookShareButton url={window.location.href} quote={title}>
          <FacebookIcon
            size={32}
            round={true}
            className="hover:opacity-40 duration-150"
          />
        </FacebookShareButton>
        {/* QRコード */}
        <div
          className="hover:cursor-pointer"
          onClick={(e) => handleModalOpen("displayQRCode")}
        >
          <QrCodeScannerIcon className="hover:opacity-40 duration-150" />
        </div>
        <div>
          <div
            className="hover:cursor-pointer"
            onClick={() => copyToClipboard()}
          >
            <Tooltip title="クリックしてURLをコピー" arrow>
              <LinkIcon className="hover:opacity-40 duration-150" />
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default SNSList;
