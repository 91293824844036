import React, { useState, useRef, useEffect } from "react";

import { CameraIcon } from "@heroicons/react/solid";

import Img from "../data/img/defaultImage.png";

// Setting firebase App
import { db, auth, storage } from "../api/firebase";
import {
  collection,
  setDoc,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
} from "firebase/firestore";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import InputInPosts from "./InputInPosts";
import LoaderHeaderPic from "./LoaderHeaderPic";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

//  setDocは作成・更新が可能なため、意図しない更新をしてしまう
// 作成のみの場合はadd
// 更新のみはupdate
// 作成・更新があればsetDocを利用

function InputBox() {
  const inputRef = useRef(null);
  const filePickerRef = useRef(null);
  const [imageToPost, setImageToPost] = useState(null);

  // ユーザ取得
  const [user, setUser] = useState("");
  useEffect(() => {
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
      if (doSnap.exists) {
        setUser(doSnap.data());
      }
    });
  }, []);

  const sendPost = (e) => {
    e.preventDefault();
    if (!inputRef.current.value) return; // input内がなにもないなら、なにもしない
    // firestoreにデータ入力
    const messagesDoc = collection(db, "posts"); // Collection"posts"に追加
    const docData = {
      // 追加内容
      uid: auth.currentUser.uid,
      firstname: user.firstName,
      lastname: user.lastName,
      message: inputRef.current.value,
      avatar: user.avatar,
      timeStamp: serverTimestamp(),
      deleteFlag: false,
    };
    addDoc(messagesDoc, docData).then((doc) => {
      if (imageToPost) {
        const storageRef = ref(storage, `posts/${doc.id}.jpg`);
        const uploadTask = uploadString(storageRef, imageToPost, "data_url");

        removeImage();

        uploadTask.then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            setDoc(doc, { postImage: downloadURL }, { merge: true });
          });
        });
      }
    });
    inputRef.current.value = ""; // input内を空っぽにしておく
  };

  const addImageToPost = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    const limitFileSize = 10 * 1024 * 1024; // 3メガ
    // const isValidImage = validateImage(e);

    if (file.size > limitFileSize) {
      alert("ファイルサイズが大きすぎて送信できませんでした。");
      return false;
    }
    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onload = (readerEvent) => {
      setImageToPost(readerEvent.target.result);
    };
  };

  const removeImage = () => {
    setImageToPost(null);
  };

  // 待っててねメッセージ関連
  const [open, setOpen] = useState(false);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    removeImage();
  };

  const hoge = (e) => {
    return <></>;
  };

  return (
    <div className=" bg-white  rounded-md border text-gray-500 font-medium ">
      {/* 投稿者情報と投稿内容 */}
      <div className="flex space-x-4 p-4 items-center   ">
        <img
          className=" rounded-full w-10 h-10"
          src={user?.avatar || <LoaderHeaderPic />}
          layout="fixed"
          alt=""
        />

        {/* <InputInPosts /> */}

        <input
          className=" rounded-xl h-12   hover:bg-back-hover  px-5 focus:outline-none w-full "
          type="text"
          ref={inputRef}
          placeholder="その気持ち、シェアしてね"
          onClick={handleClickOpen}
          htmlFor="my-modal-4"
        ></input>

        {/* 投稿　modal */}
        {/* <label
            htmlFor="my-modal-4"
            className="bg-gray-100 text-gray-400 rounded-xl w-full pl-2 cursor-pointer"
          >
            その気持ち、シェアしてみてね
          </label>
          <button hidden type="submit" onClick={sendPost}>
            送信
          </button> */}
        {/* 
        <input type="checkbox" id="my-modal-4" className="modal-toggle" />
          <label htmlFor="my-modal-4" className="modal cursor-pointer">
            <label className="modal-box relative">
              <div className="flex flex-col">
                <div></div>
                <img
                  src={user.avatar || Img}
                  className="rounded-full h-16 w-16"
                  alt="avatar"
                ></img>
                <div className="divider" />
                <textarea
                  className=" w-full  border rounded-md p-2 "
                  placeholder="その気持、シェアしてみてね"
                />
                <div className="divider" />
              </div>
            </label>
          </label> */}

        {imageToPost && (
          <div
            onClick={removeImage}
            className=" flex flex-col filter hover:brightness-110 transition duration-150 transform hover:scale-105 cursor-pointer"
          >
            <img className=" h-10 object-contain" src={imageToPost} alt="" />
            <p className=" text-xs text-red-500 text-center">Remove</p>
          </div>
        )}
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Feed投稿機能は現在開発中です</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            素敵なメディアをありがとうございます
            メンバーさんの思い出や今の気持ち、イベントの告知やアンケートなど
            メンバーさんの人生を楽しく盛り上げられるよう鋭意開発中です
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose}>わかりました</Button>
        </DialogActions>
      </Dialog>

      {/* イメージ関連 */}
      <div className="flex justify-evenly p-3 border-t">
        <div
          onClick={() => filePickerRef.current.click()}
          className="inputIcon"
        >
          <CameraIcon className="h-7 text-green-400" />
          <p className="text-xs sm:text-sm ">写真/動画</p>
          <input
            ref={filePickerRef}
            onChange={addImageToPost}
            type="file"
            hidden
          />
        </div>
      </div>
    </div>
  );
}

export default InputBox;
