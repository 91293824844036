import React, { useEffect, useState } from "react";
import {
  getDoc,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { StreamChat } from "stream-chat";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

// redux - use
import { useNotification } from "../redux/useNotification";

const PermissionJoinBase = ({ setOpen, requestId, target }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // console.log("requestId =>",requestId)
  // console.log("target =>",target)

  // ユーザ取得関連
  const [group, setGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // リクエスト取得
  useEffect(() => {
    if (requestId) {
      getDoc(doc(db, "base", target)).then((doSnap) => {
        if (doSnap.exists) {
          setGroup(doSnap.data());
        }
      });
    }
  }, [requestId]);
  const { id } = useParams();
  // リクエストユーザ取得
  const [requestUser] = useDocumentData(doc(db, "users", requestId));

  const [adminUser] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // console.log("requestUser =>",requestUser)
  // console.log("adminUser =>",adminUser)
  //   更新処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // 更新
      await updateDoc(doc(db, "base", target, "members", requestId), {
        isMember: true,
        isApplication: false,
      });

      // 拠点参加希望の"users"コレクションにも情報追加
      // affiliation・・・所属
      await setDoc(doc(db, "users", requestId, "affiliationBase", target), {
        baseId: id,
        createdAt: serverTimestamp(),
        type: "member",
        registered: auth.currentUser.uid,
      });

      // chatのメンバーにも加える
      // => memberにない人はchatタブを開いても「メンバーだけです」と出るので、加える必要がない。
      // => ので、廃止
      // 20231215追記=>
      // 拠点のチャットの通知も来てほしいのでメンバーには追加するようにする

      const userToConnect = {
        id: adminUser.uid,
        name: adminUser.lastName + adminUser.firstName,
        image: adminUser.avatar,
        langage: "jp",
      };
      const apiKey = process.env.REACT_APP_STREAM_API_KEY;
      const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
      const token = await fetch(
        process.env.REACT_APP_STREAM_GET_TOKEN_URL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: adminUser.uid,
          }),
        }
      ).then((r) => r.json());
      await client.connectUser(userToConnect, token);
      const channel = client.channel("messaging", "baseChat-" + id);
      await channel.addMembers([requestUser.uid], {
        text:
          requestUser?.lastName +
          requestUser?.firstName +
          "さんがグループに参加しました",
      });

      // グループのupdates作成
      await addDoc(collection(db, "base", target, "updates"), {
        createdAt: serverTimestamp(),
        title: "拠点参加",
        information:
          requestUser?.lastName +
          requestUser?.firstName +
          "さんが拠点に参加しました。",
        creator: requestUser?.uid,
        creatorAvatar: requestUser?.avatar,
        authorizer: auth.currentUser.uid,
      });

      // 通知
      await addDoc(collection(db, "users", requestUser?.uid, "notification"), {
        createdAt: serverTimestamp(),
        content: "拠点参加許可",
        fromAvatar: adminUser?.avatar,
        fromId: adminUser?.uid,
        fromName: adminUser?.lastName + adminUser?.firstName,
        isRecognition: false,
        target: "base",
        targetId: target,
      });

      setOpen(false);
      displayNotification({
        message:
          requestUser?.lastName +
          requestUser?.firstName +
          "さんが拠点に参加しました。",
        type: "success",
      });

      window.location.reload();
    } catch (err) {
      setLoading(false);
      setError(err);
      const errorData = {
        message: err.message,
        stack: err.stack,
      };
      addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: errorData,
        component: "PermissionJoinBase",
        user: requestUser || auth.currentUser.uid,
      });
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        申請許可
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="font-bold text-2xl">参加申請を許可しますか？</div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "許可処理中..." : "許可する"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default PermissionJoinBase;
