import React from "react";

const Blind = ({ blind, onclick ,name}) => {
  return (
    <div
      className={` ${blind ? " visible" : " invisible"}
     fixed z-30 duration-150 h-screen w-screen top-0 left-0 bg-stone-500 opacity-80`}
      onClick={onclick}
    ></div>
  );
};

export default Blind;
