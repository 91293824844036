import { useEffect, useState, useRef } from 'react';
import { db } from '../api/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth } from '../api/firebase';

export const useAppLifecycle = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const unreadCountRef = useRef(unreadCount); // useRefを使用して値を追跡

  // FirebaseからのunreadCountを購読し、状態を更新する
  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const docRef = doc(db, 'users', userId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setUnreadCount(data.unreadCount || 0); // Firestoreから取得したunreadCountをセット
      }
    });

    return () => unsubscribe(); // クリーンアップ関数で購読を解除
  }, []);

  // アプリの可視性が変わったときにバッジを更新する
  useEffect(() => {
    const handleVisibilityChange = async () => {
      try {
        if (document.visibilityState === 'hidden') {
          if ('setAppBadge' in navigator && unreadCountRef.current > 0) {
            // await navigator.setAppBadge(unreadCountRef.current);
            await navigator.setAppBadge(10);
            // console.log("非表示になったunreadCountRef.current =>",unreadCountRef.current)
          }
        } else if (document.visibilityState === 'visible') {
          if ('clearAppBadge' in navigator) {
            await navigator.clearAppBadge();
            // console.log("見えた！unreadCountRef.current =>",unreadCountRef.current)

          }
        }
      } catch (error) {
        console.error('Badge failed:', error);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // unreadCountが更新されたらその値をrefにも同期する
  useEffect(() => {
    unreadCountRef.current = unreadCount;
  }, [unreadCount]);

  // ...
};
