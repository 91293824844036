import React, { useState, useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db, auth } from "../api/firebase";
import EditIcon from "@mui/icons-material/Edit";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "../context/auth";
import ModalForm from "./ModalForm";
import EditFlexContent from "./EditFlexContent";
import EditableContent from "./EditableContent";
import FlexImage from "./FlexImage";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import reactStringReplace from "react-string-replace";
import { textToLink } from "./textToLink";

const FlexCotent = ({ list, collection, handleChange, value }) => {
  const { id } = useParams();
  const { user } = useAuth();
  const [targetDoc] = useDocumentData(doc(db, collection, id));

  // Authorかどうか？
  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const initSetAuthor = async () => {
      if (targetDoc?.creator === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    user && initSetAuthor();
  }, [targetDoc, user]);

  // ユーザ情報
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initGetUser = async () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && initGetUser();
  }, [user]);

  //   ドラッグ処理
  const reorder = (startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((row, index) => (row.order = index));
    return result;
  };
  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const update = reorder(source.index, destination.index);
    update?.forEach((document) => {
      updateDoc(doc(db, collection, id, "content", document.id), {
        order: document.order,
        index: document.index,
      });
    });
    setRows(update);
  };

  const [rows, setRows] = useState(list);

  useEffect(() => {
    setRows(list);
  }, [list]);

  // console.log("list =>", list);

  const [editMode, setEditMode] = useState(false);
  const handleSwitchEditMode = () => {
    setEditMode(!editMode);
  };
  // console.log("editMode =>", editMode);

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");
  const handleEdit = (e, url) => {
    // console.log("e =>", e);
    // console.log("url =>", url);
    // setOpen(true);
    // setTarget(url);
    // setForm(e);
  };

  // TAB用
  // const [value, setValue] = React.useState("1");
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  //   権限変更関連handle
  const [d_loding, setD_loading] = useState(false);
  const [dataloading, setDataLoading] = useState(true);

  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, collection, id));
    await updateDoc(doc(db, collection, id), {
      [type]: !pub.data()[type],
    });
    setDataLoading(true);
    setD_loading(false);
  };
  const handleChangePublish = async (e) => {
    setD_loading(true);
    await publishChange(id, e.target.name);
  };

  const regularExpressions = [
    /(https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+)/g,
    /([a-z0-9]|\.)+\.(com|net|jp|org|io|info|me|ma|gl|ly|gd|cc|nu|tt).*/gm,
  ];

  const Display = ({ list }) => {
    return (
      <>
        <div className="font-bold text-2xl grid grid-cols-1">
          {targetDoc?.title}
        </div>

        {list?.map((row) => (
          <>
            <div className="grid grid-cols-1 w-full bg-white rounded-md">
              {row?.title}
            </div>
            {row.contentType === "handline" && (
              <div className=" col-span-1 rounded-md bg-white py-1 px-2">
                <div
                  className={`
              ${row?.fontBold === "太字" && "font-bold"}
              ${row?.fontBold === "太字" && "font-bold"}
              ${row?.fontSize === "小" && " text-xl "}
              ${row?.fontSize === "中" && " text-2xl "}
              ${row?.fontSize === "大" && " text-4xl "}
              ${row?.fontSize === "特大" && " text-5xl "}
              
              " whitespace-pre-wrap my-4 w-full grid break-words"`}
                >
                  {row?.content}
                </div>
              </div>
            )}
            {row.contentType === "textarea" && (
              <div className="grid grid-cols-1  rounded-md bg-white py-1 px-2">
                <div className=" whitespace-pre-wrap my-2 break-words col-span-1 ">
                  {/* <div>{textToLink(row.content)}</div> */}
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: textToLink(comment),
                    }}
                    style={{
                      whiteSpace: "pre-wrap",
                      lineHeight: "176%",
                      fontSize: ".9rem",
                    }}
                  /> */}

                  {reactStringReplace(
                    row?.content,
                    /(https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+)/g,
                    (match, i) => (
                      <a key={i} href={match} className="text-blue-400">
                        {match}
                      </a>
                    )
                  )}

                  {/* {row.content} */}
                </div>
              </div>
            )}
            {row.contentType === "image" && (
              <div className="my-2 py-2 grid gri-dcols-1">
                <FlexImage
                  collection={collection}
                  id={id}
                  target="content"
                  contentId={row?.id}
                />
              </div>
            )}
          </>
        ))}
      </>
    );
  };

  if (userInfo?.isAdmin || isAuthor) {
    return (
      <>
        <FormControlLabel
          control={
            <Switch
              checked={targetDoc?.isPublish}
              onChange={handleChangePublish}
              name="isPublish"
              disabled={d_loding}
              value={targetDoc?.id}
            />
          }
          label={targetDoc?.isPublish ? "掲載" : ""}
        />

        <TabContext value={value}>
          <Tabs
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={value}
          >
            <Tab label="編集" value="1" />
            <Tab label="表示" value="2" />
          </Tabs>
          <TabPanel sx={{ p: 1 }} value="1">
            <div className="font-bold text-2xl ">{targetDoc?.title}</div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={"dndTableBody"}>
                {(provided) => (
                  <div
                    position="right"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {rows?.map((row, index) => (
                      <Draggable
                        draggableId={row.id}
                        index={index}
                        key={row.id}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {row?.contentType === "handline" && (
                              <div
                                className="grid grid-cols-12  space-x-2 items-center my-2 border p-2 rounded-md bg-white"
                                // onClick={handleSwitchEditMode}
                              >
                                <div
                                  className={` 
                              ${
                                row?.fontBold === "太字" && "font-bold"
                              }                              
                                "  col-span-11 w-full "`}
                                >
                                  <EditableContent
                                    collection={collection}
                                    documentId={id}
                                    target="content"
                                    moreCollection="content"
                                    contentId={row?.id}
                                    fontSize={row?.fontSize}
                                  />
                                  {/* {row?.id} */}
                                </div>
                                <div className=" col-span-1">
                                  <EditFlexContent
                                    target={collection}
                                    id={id}
                                    contentId={row?.id}
                                  />
                                </div>
                              </div>
                            )}
                            {row?.contentType === "textarea" && (
                              <div className=" grid grid-cols-12 space-x-2 items-center my-2 whitespace-pre-wrap break-words w-full border rounded-md p-2 bg-white">
                                {/* <div>{row?.content}</div> */}
                                <div className=" col-span-11">
                                  <EditableContent
                                    collection={collection}
                                    documentId={id}
                                    target="content"
                                    moreCollection="content"
                                    contentId={row?.id}
                                    fontSize={row?.fontSize}
                                  />
                                </div>
                                <div className="col-span-1">
                                  <EditFlexContent
                                    target={collection}
                                    id={id}
                                    contentId={row?.id}
                                  />
                                </div>
                              </div>
                            )}
                            {row?.contentType === "image" && (
                              <div className="flex space-x-2 items-center my-2 ">
                                <FlexImage
                                  collection={collection}
                                  id={id}
                                  target="content"
                                  contentId={row?.id}
                                  value={value}
                                />
                                <EditFlexContent
                                  target={collection}
                                  id={id}
                                  contentId={row?.id}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </TabPanel>
          <TabPanel sx={{ p: 1 }} value="2">
            <Display key="1" list={rows} />
          </TabPanel>
        </TabContext>
      </>
    );
  } else if (!targetDoc?.isPublish) {
    return <div>表示できる内容がありません。</div>;
  } else return <Display key="2" list={rows} />;
};

export default FlexCotent;
