import React from "react";
import AddComponent from "./AddComponent";
import GroupTable from "./GroupTable";
import GroupTypeTable from "./GroupTypeTable";
import MovieChannelTable from "./MovieChannelTable";

const OperateMovie = () => {
  return (
    <>
      <div className="flex flex-col">
      {/* <Button variant="outlined" onClick={() => handleEdit("addMovieChannel")}>
          チャンネル追加
        </Button> */}

        <AddComponent value="チャンネルを追加" target="addMovieChannel" />
        <MovieChannelTable />
        {/* <GroupTypeTable /> */}
        {/* <GroupTable /> */}
      </div>
    </>
  );
};

export default OperateMovie;
