import React, { useState, useEffect } from "react";
import "firebase/database";
import { auth, db } from "../api/firebase";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import Loader1Row from "./Loader1Row";
import { Button, TextareaAutosize } from "@mui/material";

const EditableContent = ({
  collection,
  documentId,
  target,
  moreCollection,
  contentId,
  fontSize,
}) => {
  //   const [data, setData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(null);

  //   ユーザ情報取得
  const [user, setUser] = useState("");
  useEffect(() => {
    const getUser = async () => {
      onSnapshot(doc(db, "users", auth.currentUser.uid), (doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    getUser();
  }, []);

  // Firebaseからデータを読み込む処理
  const docRef = doc(db, collection, documentId, moreCollection, contentId);
  const [dataDoc] = useDocument(docRef);
  const data = dataDoc?.data()[target];

  // データの編集を保存する処理
  const saveData = async () => {
    // const targetDoc = doc(db, collection, documentId);
    // console.log("editedData =>",editedData)
    if (!editedData) {
      setEditMode(false);
      return;
    }

    if (editedData[target].length === 0) {
      setEditMode(false);
      return;
    }


    await updateDoc(docRef, {
      [target]: editedData[target],
    });
    setEditMode(false);
  };

  // 編集フォームの変更を処理する処理
  const handleEdit = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };

  //   console.log("editedData =>", editedData);

  // 編集モードに入る処理
  const enterEditMode = () => {
    setEditMode(true);
    // setEditedData(data);
  };

  // 編集モードから抜ける処理
  const cancelEdit = () => {
    setEditMode(false);
    setEditedData(null);
  };

  return (
    <div>
      {!data && <Loader1Row />}
      {editMode && user?.isAdmin ? (
        <>
          <div className="grid grid-cols-1 w-full space-x-2 items-center ">
            <TextareaAutosize
              name={target}
              style={{ resize: "none", fontSize: "16px" }}
              onChange={handleEdit}
              placeholder={data}
              defaultValue={data}
              className=" p-1 w-full scrollbar-hide"
              minRows={3}
              maxRows={5}
            />
            <Button onClick={saveData}>✓</Button>
          </div>
        </>
      ) : (
        <div
          className={` 
          ${fontSize === "小" && " text-xl "}
          ${fontSize === "中" && " text-2xl "}
          ${fontSize === "大" && " text-4xl "}
          ${fontSize === "特大" && " text-5xl "}
          
          " whitespace-pre-wrap break-words   "`}
          onClick={enterEditMode}
        >
          {data}
        </div>
      )}
    </div>
  );
};

export default EditableContent;
