import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, orderBy, query, getDoc, doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import Options from "./Options";

export default function Images({ targetCollection, shopId,noOption }) {
  const [documents, loading, error] = useCollection(
    query(
      collection(db, `${targetCollection}/${shopId}/images/`),
      orderBy("createdAt", "desc")
    )
  );
  const imagelist = documents?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const isMobile = useMediaQuery("(min-width:769px)");

  const [user, setUser] = useState("");
  const [shop, setShop] = useState("");

  useEffect(() => {
    // ショップ情報取得
    const getShopInfo = async () => {
      await getDoc(doc(db, "requestShop", shopId)).then((doSnap) => {
        if (doSnap.exists) {
          setShop(doSnap.data());
        }
      });
    };
    getShopInfo();

    // ユーザ情報取得
    const getUserInfo = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
        if (doSnap.exists) {
          setUser(doSnap.data());
        }
      });
    };
    getUserInfo();
  }, []);

  const [isOwner, setIsOwner] = useState(false);
  useEffect(() => {
    if (shop?.owner === auth.currentUser.uid) {
      setIsOwner(true);
    }
  }, []);

  return (
    <>
      <ImageList variant="masonry" cols={isMobile ? 2 : 1} gap={8}>
        {imagelist?.map((image, idx) => (
          <ImageListItem>
            <img src={image?.imageURL} alt="" />
            {(user?.isAdmin || isOwner) && !noOption && (
              <Options
                itemId={image.id}
                uid={image.uid}
                targetCollection={targetCollection}
              />
            )}
            <Typography
              variant="body2"
              component="span"
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                color: "white",
                background: "rgba(0,0,0, .3)",
                p: "5px",
                borderTopRightRadius: 8,
              }}
            >
              {moment(image?.createdAt?.toDate()).fromNow()}
            </Typography>
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}
