import React, { useState, useEffect } from "react";
import { Box, ImageListItem } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import UploadFileProgress from "./uploadFileProgress";
import { db, auth } from "../api/firebase";
import { v4 as uuuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { addDoc, arrayUnion, doc, updateDoc, getDoc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import addDocument from "./addDocument";

const ProgressItems = ({ file, targetCollection }) => {
  const [progress, setProgress] = useState(100);
  const [imageURL, setImageURL] = useState(null);
  const { id } = useParams();

  // アップロード処理
  // React18の仕様で
  // 開発者モードだとindex.jsにあるstrictModeの関係で
  // 処理が2回走る
  // ので、2回アップロードされてしまう
  // けど、本番環境ではstrictModeは発動しないので
  // 2回アップロードの心配はない
  useEffect(() => {
    const uploadImage = async () => {
      const user = await getDoc(doc(db, "users", auth.currentUser.uid));
      const imageName = uuuidv4() + "." + file.name.split(".").pop();
      try {
        // console.log(user.data().avatar);
        const url = await UploadFileProgress(
          file,
          `${targetCollection}/${id}`,
          imageName,
          setProgress
        );
        const imageDoc = {
          imageURL: url,
          uid: user.data().uid,
          name: user.data().lastName + user.data().firstName,
          avatar: user.data().avatar,
        };
        setImageURL(null);
        await addDocument(
          `${targetCollection}/${id}/images`,
          imageDoc,
          imageName
        );
      } catch (error) {
        alert(error.message);
        console.log(error);
      }
    };

    setImageURL(URL.createObjectURL(file));
    uploadImage();
  }, [file]);

  return (
    imageURL && (
      <ImageListItem cols={1} rows={1}>
        <img src={imageURL} alt="" loading="lazy" className=" opacity-40" />
        <Box sx={backDrop}>
          {progress < 100 ? (
            <CircularProgressWithLabel value={progress} />
          ) : (
            <CheckCircleOutline
              sx={{ width: 60, height: 60, color: "lightgreen" }}
            />
          )}
        </Box>
      </ImageListItem>
    )
  );
};

export default ProgressItems;

const backDrop = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rbga(0,0,0, .5)",
};
