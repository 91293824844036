import React from "react";
import Rating from "@mui/material/Rating";

const RatingComponent = ({ rate, handleChange }) => {
  return (
    <Rating
      name="rate"
      value={Number(rate)}
      onChange={handleChange}
      defaultValue={Number(rate)}
      size="large"
    />
  );
};

export default RatingComponent;
