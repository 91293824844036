import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../api/firebase";

export const addNotification = async (
  userId,
  fromAvatar,
  fromId,
  fromName,
  target,
  targetId,
  content
) => {
  addDoc(collection(db, "users", userId, "notification"), {
    createdAt: serverTimestamp(),
    content,
    fromAvatar,
    fromId,
    fromName,
    isRecognition: false,
    target,
    targetId,
  });
};
