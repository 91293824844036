import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { Button, TextareaAutosize } from "@mui/material";
import { TextField } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";

// redux - use
import { useNotification } from "../redux/useNotification";


const Inquiry = ({ setOpen }) => {
  // redux - Notification
  const { displayNotification } = useNotification();


  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    questionType: "",
    question: "",
    message: "",
    error: null,
    loading: false,
  });
  const { questionType, question, message, error, loading } = data;

  // 質問者情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
      setUserInfo({ ...doc.data() });
    });
  }, []);

  // const reasons = [
  //   { id: 1, reason: "オンライン" },
  //   { id: 99, reason: "divider" },
  //   { id: 2, reason: "沖縄県" },
  // ];

  const [questionTypeCollection] = useCollection(
    collection(db, "questionType")
  );
  const reasons = questionTypeCollection?.docs?.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });

  // console.log("reqsons =>", reasons);

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 処理
      addDoc(collection(db, "questionForAdmin"), {
        createdAt: serverTimestamp(),
        lastUpdatedAt: serverTimestamp(),
        isResolution: false,
        questionerAvatar: userInfo?.avatar,
        questionerId: userInfo?.uid,
        questionerName: userInfo?.lastName + userInfo?.firstName,
        question,
        questionType,
        respondentAt: "",
        respondentAvtar: "",
        respondentId: "",
        respondentName: "",
        target: "",
        targetId: "",
      });

      

      displayNotification({
        message: "お問い合わせを送信しました。",
        type:"success"
      });

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      // window.location.reload();


      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };
  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">お問い合わせ種類</div>
        <TextField
          id="outlined-required"
          name="questionType"
          label="お問い合わせ種類"
          defaultValue=""
          size="small"
          className=" w-full"
          onChange={handleChange}
          required
          disabled={loading}
          select
        >
          {reasons?.map((option) =>
            option.reason === "divider" ? (
              <Divider>または</Divider>
            ) : (
              <MenuItem value={option.reason} key={Math.random()} dense>
                {option.reason}
              </MenuItem>
            )
          )}
        </TextField>

        <div className="my-3">お問い合わせ内容</div>
        <TextareaAutosize
          id="outlined-required"
          name="question"
          label="セカイムラ事務局へのお問い合わせ"
          defaultValue=""
          minRows={3}
          maxRows={5}
          placeholder="例）イベントを作成してみたいのですが、どのように作成するのでしょうか？"
          className=" w-full border border-blue-500 rounded-sm p-2"
          onChange={handleChange}
          required
          disabled={loading}
        />

        <Button
          className="btn"
          name="question"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default Inquiry;
