import React, { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, getDoc, query, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";

// MaterialUI
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
export default function SelectComponent({
  title,
  collection,
  id,
  documentName,
  array,
  reload
}) {
  const [product, setProduct] = useState("");

  useEffect(() => {
    getDoc(doc(db, collection, id)).then((elm) =>
      setProduct({ id: elm.id, ...elm.data() })
    );
  }, []);

  // console.log("product =>", product);

  const handleUpdateValue = async(e) => {
    await setProduct({ ...product, [documentName]: e.target.value });
    await updateDoc(doc(db, collection, id), {
      [documentName]: e.target.value,
    });
    reload && window.location.reload()
  };
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">{title}</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          label={title}
          onChange={handleUpdateValue}
          value={product?.[documentName] || ""}
        >
          {array?.map((type) => (
            <MenuItem key={Math.random()} value={type.value} dense>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
