import React, { useEffect } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Button } from "@mui/material";

// Undoボタンコンポーネント
const UndoButton = () => {
    const [editor] = useLexicalComposerContext();
  
    const handleUndo = () => {
      // Undo操作を実行
      editor.dispatchCommand('HISTORY_UNDO_COMMAND', undefined);
      console.log("editor =>",editor)
    };
  
    return (
      <Button variant="outlined" onClick={handleUndo} className="undo-button">
        戻す
      </Button>
    );
};

// Lexicalエディタの設定
const editorConfig = {
  // エディタの初期設定を追加
  theme: {
    // テキストのスタイル
    text: {
      color: "#000",
      fontSize: "16px",
      fontWeight: "400",
      fontFamily: "Arial, sans-serif",
    },
    // 背景のスタイル
    background: {
      color: "#fff",
    },
    // プレースホルダーのスタイル
    placeholder: {
      color: "#a9a9a9",
      fontSize: "16px",
      fontStyle: "italic",
    },
    // その他の要素のスタイルをここに定義
  },
};

function LexicalEditor() {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container border border-gray-300 p-2">
        <UndoButton />
        <PlainTextPlugin
          contentEditable={
            <ContentEditable className="editor-contenteditable min-h-[150px]" />
          }
          placeholder={
            <div className="editor-placeholder text-gray-400">
              ここにテキストを入力...
            </div>
          }
        />
        <HistoryPlugin />
      </div>
    </LexicalComposer>
  );
}

export default LexicalEditor;
