import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
// redux - use
import { useNotification } from "../redux/useNotification";

const DeleteEvalute = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const { id } = useParams();
  // 評価取得関連
  const [evalution] = useDocumentData(
    doc(db, "products", id, "evalution", requestId)
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  //   削除処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // 削除
      await updateDoc(doc(db, "products", id, "evalution", requestId), {
        display: false,
      });
      setOpen(false);
      displayNotification({
        message: "評価を非表示にしました。",
        type: "success",
      });
    } catch (err) {
      setLoading(false);
      setError(err);
      console.log(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        評価非表示
      </div>
      <form onSubmit={(e) => e.preventDefault()}>

        <div className="font-bold text-2xl">以下の評価を非表示にします</div>
        <div className="border-2 p-2 rounded-md mb-2">
          <div className="text-gray-300">評価</div>
          <div>{evalution?.evalute}</div>
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "非表示にしています..." : "非表示にする"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DeleteEvalute;
