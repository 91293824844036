import React, { useEffect, useState } from "react";
import { devToken, StreamChat } from "stream-chat";
import { Chat, Channel, ChannelList } from "stream-chat-react";
import { useChecklist } from "../ChecklistTasks";

import "stream-chat-react/dist/css/v2/index.css";

import "../streamLayout.css";

import CreateChannel from "./CreateChannel/CreateChannel";
import UpdateMember from "./UpdateMember/UpdateMember";
import CustomMessage from "./CustomMessage/CustomMessage";
import MessagingChannelList from "./MessagingChannelList/MessagingChannelList";
import MessagingChannelPreview from "./MessagingChannelPreview/MessagingChannelPreview";
import MessagingInput from "./MessagingInput/MessagingInput";
import MessagingThreadHeader from "./MessagingThread/MessagingThread";

import { ChannelInner } from "./ChannelInner/ChannelInner";
import DisplayMembers from "./DisplayMembers/DisplayMembers";
import MessagingChannelListPRO from "./MessagingChannelList/MessagingChannelListPRO";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useStreamChatClient } from "../context/StreamChatContext";

const urlParams = new URLSearchParams(window.location.search);

const targetOrigin =
  urlParams.get("target_origin") || process.env.REACT_APP_TARGET_ORIGIN;

export const GiphyContext = React.createContext({});


const StreamChatPRO = ({ user }) => {
  const filters = { type: "messaging", members: { $in: [user.id] } };

  const options = { state: true, watch: true, presence: true };

  const sort = {
    last_message_at: -1,
    updated_at: -1,
  };

  const chatClient = useStreamChatClient()
  const [giphyState, setGiphyState] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [openMembers, setOpenMembers] = useState(false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [theme, setTheme] = useState("dark");

  const [isUpdateMember, setIsUpdateMember] = useState(false);


  // 初期処理
  useEffect(() => {
    const initChat = async () => {
      try {
 
        const channel = chatClient.channel("messaging", {
          name: "事務局",
          members: [user.id, "YmX93fqfCQee9IdgqOBLD58rakt1"],
        });

        await channel.watch();

        if (
          channel.lastMessage()?.text ===
          "セカイムラのチャットへようこそ。 ここではセカイムラメンバーのみんなと好きなようにチャットをすることができます。尚、事務局へのお問合せは、メニュー欄「お問合せ」よりお問い合せくださいませ。チャットからのお問合せの場合、抜け漏れが発生する場合がありますのでお問合せ機能をお使いいただけますと助かります。どうぞよろしくお願いいたします。"
        ) {
        } else {
          const message = await channel.sendMessage({
            text: "セカイムラのチャットへようこそ。 ここではセカイムラメンバーのみんなと好きなようにチャットをすることができます。尚、事務局へのお問合せは、メニュー欄「お問合せ」よりお問い合せくださいませ。チャットからのお問合せの場合、抜け漏れが発生する場合がありますのでお問合せ機能をお使いいただけますと助かります。どうぞよろしくお願いいたします。",
          });
        }
      } catch (err) {
        const errorData = {
          message: err.message,
          stack: err.stack,
        };
        addDoc(collection(db, "error"), {
          createdAt: serverTimestamp(),
          error: errorData,
          component: "StreamChatPRO.js",
          user:user ||  auth.currentUser.uid,
        });
      }

      // setChannel(channel);
    };

    initChat();
  }, []);

  // console.log("chatClient =>",chatClient)


  // テーマ変更
  useEffect(() => {
    const handleThemeChange = ({ data, origin }) => {
      // handle events only from trusted origin
      if (origin === targetOrigin) {
        if (data === "light" || data === "dark") {
          setTheme(data);
        }
      }
    };

    window.addEventListener("message", handleThemeChange);
    return () => window.removeEventListener("message", handleThemeChange);
  }, []);

  // モバイルモード
  useEffect(() => {
    const mobileChannelList = document.querySelector("#mobile-channel-list");
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add("show");
      // document.body.style.overflow = "hidden";
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove("show");
      // document.body.style.overflow = "auto";
    }
  }, [isMobileNavVisible]);

  // 高さ設定
  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    setAppHeight();

    window.addEventListener("resize", setAppHeight);
    return () => window.removeEventListener("resize", setAppHeight);
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // ウィンドウの幅が変更されたらstateを更新
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // コンポーネントがアンマウントされるときにイベントリスナーを削除
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMobile = () => setMobileNav(!isMobileNavVisible);
  const toggleMembers = () => setOpenMembers(!openMembers);
  const toggleCreating = () => setIsCreating(!isCreating);
  const giphyContextValue = { giphyState, setGiphyState };


  const toggleUpdateMember = () => {
    setIsUpdateMember(!isUpdateMember);
  };

  if (!chatClient)
    return (
      <>
        <div>読み込中...</div>
      </>
    );

  return (
    <Chat client={chatClient} className="w-[99%]  mx-0  ">
      <div
        className={` ${
          windowWidth < 600
            ? " h-[calc(100vh-184px)] "
            : " h-[calc(100vh-135px)] "
        }  " flex  w-[99%]   rounded-lg  overflow-auto border box-content "`}
      >
        {/* モバイル時（width 640以下）で表示 */}
        <div
          id="mobile-channel-list"
          onClick={toggleMobile}
          className="mt-12 md:m-0 sm:hidden border"
        >
          <div>
            <ChannelList
              filters={filters}
              sort={sort}
              options={options}
              List={(props) => (
                <MessagingChannelList
                  {...props}
                  pro={true}
                  onCreateChannel={() => setIsCreating(!isCreating)}
                  // toggleMobile={toggleMobile}
                />
              )}
              Preview={(props) => (
                <MessagingChannelPreview {...props} {...{ setIsCreating }} />
              )}
            />
          </div>
        </div>

        <div className="hidden sm:block">
          <ChannelList
            filters={filters}
            sort={sort}
            options={options}
            List={(props) => (
              <MessagingChannelList
                {...props}
                onCreateChannel={() => setIsCreating(!isCreating)}
              />
            )}
            Preview={(props) => (
              <MessagingChannelPreview {...props} {...{ setIsCreating }} />
            )}
          />
        </div>

        {/* チャンネル本体 */}
        <div className=" w-[99%] rouded-r-xl ">
          <Channel
            Input={MessagingInput}
            maxNumberOfFiles={10}
            Message={CustomMessage}
            multipleUploads={true}
            ThreadHeader={MessagingThreadHeader}
            TypingIndicator={() => null}
          >
            {isCreating && (
              <CreateChannel onClose={() => setIsCreating(false)} />
            )}
            {isUpdateMember && (
              <UpdateMember
                onClose={() => setIsUpdateMember(false)}
                toggleUpdateMember={toggleUpdateMember}
                isUpdateMember={isUpdateMember}
              />
            )}
            {openMembers && (
              <DisplayMembers
                onClose={() => setIsUpdateMember(false)}
                toggleMembers={toggleMembers}
                openMembers={openMembers}
              />
            )}

            <GiphyContext.Provider value={giphyContextValue}>
              <ChannelInner
                theme={theme}
                toggleMobile={toggleMobile}
                isUpdateMember={isUpdateMember}
                setIsUpdateMember={setIsUpdateMember}
                toggleMembers={toggleMembers}
              />
            </GiphyContext.Provider>
          </Channel>
        </div>
      </div>
    </Chat>
  );
};

export default StreamChatPRO;
