import React, { useState } from "react";
// import { SearchIcon } from "@heroicons/react/solid";
import NavigationBarSearch from "./NavigationBarSearch";

import SearchIcon from '@mui/icons-material/Search';

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DropdownItem from "./DropdownItem";
import CreateHeaderMenu from "./CreateHeaderMenu";

import PageviewIcon from "@mui/icons-material/Pageview";

const DDPetcSearch = () => {
  // モーダルを制御するための状態
  const [isModalOpen, setIsModalOpen] = useState(false);

  // テキストフィールドがクリックされたらモーダルを表示する関数
  const handleClick = () => {
    setIsModalOpen(true);
  };

  // モーダルを閉じる関数
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className=" sm:hidden">
        <CreateHeaderMenu
          text="検索"
          icon={SearchIcon}
          onclick={handleClick}
          complete
        />
      </div>

      <div className="hidden sm:block w-full">
        <div className=" ">
          <div className="flex items-center rounded-full bg-white border p-2 z">
            <SearchIcon className="h-6 text-gray-600" />
            <input
              style={{ fontSize: "16px" }}
              className="md:inline-flex ml-2  w-full items-center bg-transparent outline-none"
              type="text"
              placeholder="DDP、村民さん、拠点"
              onClick={handleClick} // テキストフィールドがクリックされたらモーダルを表示
              readOnly // テキストフィールドがフォーカスされないようにする
              autoComplete="nope" // 自動補完をオフにする
            />
          </div>
        </div>
        {/* NavigationBarSearch モーダルを条件付きで表示 */}
        <NavigationBarSearch isOpen={isModalOpen} closeModal={closeModal} />
      </div>
    </>
  );
};

export default DDPetcSearch;
