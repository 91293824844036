import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import DisplayQuestionForAdmin from "./DisplayQuestionForAdmin";

const QuestionForAdmin = () => {




  //   未解決質問
  const qq = query(
    collection(db, "questionForAdmin"),
    where("isResolution", "==", false),
    orderBy("createdAt", "desc")
  );
  const [questionsCollection] = useCollection(qq);
  const questions = questionsCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  


  //   解決済
  const rq = query(
    collection(db, "questionForAdmin"),
    where("isResolution", "==", true),
    orderBy("createdAt", "desc")
  );
  const [resolutionQuestionsCollection] = useCollection(rq);
  const resolutionQuestions = resolutionQuestionsCollection?.docs.map(
    (doc) => ({
      id: doc.id,
      ...doc.data(),
    })
  );

  resolutionQuestions?.sort((a, b) => {
    if (a.lastUpdatedAt > b.lastUpdatedAt) return -1;
    if (a.lastUpdatedAt < b.lastUpdatedAt) return 1;
    return 0;
  });

  return (
    <>
      <div className="mx-2">
        <div className="font-bold text-xl mb-4">問い合わせ</div>
        <div className="font-bold text-xl mb-4">未解決</div>

        <DisplayQuestionForAdmin arr={questions} />
        <div className="font-bold text-xl my-4">解決</div>
        <DisplayQuestionForAdmin arr={resolutionQuestions} />
      </div>
    </>
  );
};

export default QuestionForAdmin;
