import React, { useState, useEffect } from "react";
import { useAuth } from "../context/auth";

import ModalForm from "./ModalForm";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../api/firebase";

const DisplayFirstLoginInfomation = () => {
  const { user } = useAuth();

  // 表示・非表示
  const [open, setOpen] = useState(true);
  const [form, setForm] = useState("forFirstLoginUser");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
  };

  // ユーザ情報取得
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initUser = async () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo({ ...doc.data() });
      });
    };
    user && initUser();
  }, []);

  // はじめましてチュートリアル
  const [displayGuide, setDisplayGuide] = useState(false);
  useEffect(() => {
    const initDisplayGuide = async () => {
      if (
        userInfo?.loginCount === 1 && // ログイン回数が1回
        !userInfo?.isVillager && // 村民じゃなくて
        !userInfo?.firstLoginInfoComplete // まだ初回チュートリアルを見てない
      ) {
        setDisplayGuide(true);
      }
    };
    userInfo && initDisplayGuide();
  }, [userInfo]);

//   console.log("displayGuide =>", displayGuide);

  return (
    <>
      {displayGuide && <ModalForm form={form} setOpen={setOpen} open={open} />}
    </>
  );
};

export default DisplayFirstLoginInfomation;
