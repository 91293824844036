import { useCallback, useRef, useState } from "react";
import { Button, Fab, Input } from "@mui/material";
import Resizer from "react-image-file-resizer";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import HorizontalMenuAtAddPostMedia from "./HorizontalMenuAtAddPostMedia";

const InputMediaOnFeed = ({ photos, setPhotos }) => {
  const filePickerRef = useRef(null);

  const handlePhotoUpload = async (event) => {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // ファイルサイズが500KB以上の場合は圧縮
      if (file.size > 500 * 1024) {
        await resizeImage(file, 500 * 1024, (compressedFile) => {
          handlePhotoPreview(compressedFile);
        });
      } else {
        handlePhotoPreview(file);
      }
    }
  };

  const handlePhotoPreview = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const photoData = {
        file,
        dataURL: event.target.result,
        isPosted: false,
      };

      setPhotos((prevPhotos) => [...prevPhotos, photoData]);
    };

    reader.readAsDataURL(file);
  };

  const resizeImage = (file, maxSize, callback) => {
    Resizer.imageFileResizer(
      file,
      maxSize,
      maxSize,
      "JPEG",
      80,
      0,
      (compressedFile) => {
        callback(compressedFile);
      },
      "file"
    );
  };

  const handleDeletePhoto = useCallback((index) => {
    setPhotos((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos.splice(index, 1);
      return updatedPhotos;
    });
  }, [setPhotos]);

  const handlePost = async () => {
    setPhotos([]);
  };

  return (
    <>
      <div className="flex flex-col">
        <div
          onClick={() => filePickerRef.current.click()}
          className="flex space-x-2 justify-center p-2 border cursor-pointer  items-center rounded-md mb-2 w-fit"
        >
          <CameraAltIcon className="h-7 text-green-400" />
          <p className="text-xs sm:text-sm">写真</p>
          <input
            ref={filePickerRef}
            onChange={handlePhotoUpload}
            type="file"
            accept="image/*"
            hidden
            multiple
          />
        </div>
        <div>
          {photos?.length > 0 && (
            <HorizontalMenuAtAddPostMedia
              photos={photos}
              handleDeletePhoto={handleDeletePhoto}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InputMediaOnFeed;
