import React, { useEffect, useState } from "react";
import {
  doc,
  collection,
  query,
  updateDoc,
  getDoc,
  orderBy,
  addDoc,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";

import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Grid } from "@mui/material";
import Loader1Row from "./Loader1Row";
import ModalForm from "./ModalForm";
import GetUserInfo from "./GetUserInfo";
// redux - use
import { useNotification } from "../redux/useNotification";

const GroupTable = ({ pending }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const [basesCount, loading, error] = useCollection(collection(db, "group"));

  const [dataloading, setDataLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [d_loding, setD_loading] = useState(false);
  const [originalRows, setOriginalRows] = useState([]);

  // 表示・非表示
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");

  const handleEdit = (e, id, target) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
    setTarget(target);
  };

  const q = query(collection(db, "group"), orderBy("createdAt", "desc"));
  const [collectionData] = useCollection(q);
  const data = collectionData?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const q_pending = query(
    collection(db, "group"),
    where("isPublish", "==", false),
    orderBy("createdAt", "desc")
  );
  const [collectionDataPending] = useCollection(q_pending);
  const pendingData = collectionDataPending?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  useEffect(() => {
    setOriginalRows(pending || data);
    setRows(pending || data);
  }, [collectionData, pending]);
  // console.log("pending =>", pending);
  // console.log("rows =>", rows);
  // console.log("originalRows =>", originalRows);

  // console.log(rows[0]?.chiefLastName)
  // bases?.forEach((doc)=>{
  //   console.log(doc?.chiefLastName)
  //   const d = getDoc(doc(db,'doc.chiefLastName'))
  //   console.log(d)
  // })

  // テーブル使用コンテナ
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // テーブル使用handle
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   権限変更関連handle
  const publishChange = async (id, type) => {
    // console.log("id =>", id);
    // console.log("type =>", type);
    // 権限変更
    const pub = await getDoc(doc(db, "group", id));
    await updateDoc(doc(db, "group", id), {
      [type]: !pub.data()[type],
    });
    // console.log(" [pub.data()[type]] =>", pub.data()[type]);
    // group updateに追加
    await addDoc(collection(db, "group", id, "updates"), {
      createdAt: serverTimestamp(),
      title: "グループ許可",
      information: pub.data()[type]
        ? "グループ使用許可が一時停止されました。"
        : "グループ使用許可が降りました。",
      creator: auth.currentUser.uid,
    });
    const changedPropertyValue = pub.data()[type] === true ? "オフ" : "オン";

    setDataLoading(true);
    setD_loading(false);

    displayNotification({
      message:
        "グループ[" +
        pub.data()["title"] +
        "]の掲載が" +
        changedPropertyValue +
        "に変更されました。",
      type: "success",
    });
  };

  const handleChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  // 検索関連
  const requestSearch = (searchVal) => {
    const filteredRows = originalRows.filter((row) => {
      return (row.title + row.founder + row.representative)
        .toLowerCase()
        .includes(searchVal.target.value.toLowerCase());
    });
    setRows(filteredRows);
  };

  if (loading) {
    return <Loader1Row />;
  }

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
        target={target}
      />
      <TextField
        id="search-bar"
        className="my-2"
        label="グループ検索"
        variant="standard"
        placeholder="グループ名"
        size="small"
        onChange={(searchVal) => requestSearch(searchVal)}
        sx={{ m: 1.5 }}
      ></TextField>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 240, borderRadius: 1 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            sx={{ minWidth: 650 }}
          >
            <TableHead>
              {/* ヘッダー情報 */}
              <TableRow>
                <TableCell>掲載</TableCell>
                <TableCell>創設者</TableCell>
                <TableCell>グループ名</TableCell>
                <TableCell>リーダー</TableCell>
                <TableCell>登録日</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  className="flex group"
                >
                  {/* 掲載 */}
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.isPublish}
                          onChange={handleChange}
                          name="isPublish"
                          disabled={d_loding}
                          value={row.id}
                        />
                      }
                      label={row.isPublish ? "掲載" : ""}
                    />
                  </TableCell>

                  {/* 創設者 */}
                  <TableCell component="th" scope="row">
                    <div className="flex flex-row items-center">
                      <div className="hidden lg:block">
                        <GetUserInfo
                          uid={row?.founder}
                          target="avatar"
                          size={24}
                        />
                      </div>
                      <GetUserInfo uid={row?.founder} target="name" />
                    </div>
                  </TableCell>

                  {/* グループ名 */}
                  <TableCell component="th" scope="row">
                    <div className="flex flex-row items-center">
                      <div>{row?.title}</div>
                    </div>
                  </TableCell>

                  {/* リーダー */}
                  <TableCell component="th" scope="row">
                    <div className="flex flex-row items-center">
                      <div className="hidden lg:block">
                        <GetUserInfo
                          uid={row?.founder}
                          target="avatar"
                          size={24}
                        />
                      </div>
                      <GetUserInfo uid={row?.founder} target="name" />
                    </div>
                  </TableCell>
                  {/* 登録日 */}

                  {/* 登録日 */}
                  <TableCell align="left">
                    <Grid className="">
                      {new Date(row.createdAt?.toDate()).toLocaleString()}
                    </Grid>
                  </TableCell>

                  {/* 消去 */}
                  <TableCell align="left">
                    <DeleteIcon
                      name={row.id}
                      className=" sm:invisible sm:group-hover:visible cursor-pointer"
                      onClick={() => handleEdit("deleteGroup", row.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="ml-2 mt-2">
          {/* 掲載店舗：{publish.docs.length} / {realtimeRequestShop.docs.length} */}
        </div>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={pending?.length || data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default GroupTable;

// テーブル検索
// https://smartdevpreneur.com/the-easiest-way-to-implement-material-ui-table-search/
