import React from "react";
import GetUserInfo from "./GetUserInfo";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Avatar } from "@mui/material";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import { doc } from "firebase/firestore";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";

const DisplayRunruHistory = ({ runru, arrow }) => {
  let now = new Date();

  // 使用期限
  const [runruPeriod] = useDocumentData(doc(db, "env", "runruPeriod"));
  const period = now.setDate(now.getDate() - runruPeriod?.period);

  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  return (
    <div className="flex flex-col space-y-2 items-start sm:flex-row sm:space-x-2 sm:items-center px-4 py-2 border rounded-lg shadow-lg hover:shadow-xl hover:border-orange-500 duration-300 ease-in-out transform hover:scale-105 cursor-pointer group bg-white">
      <div className="flex flex-col">
        <div className="text-orange-600 text-xs">
          {isInvalidDate(new Date(runru?.createdAt?.toDate()))
            ? "読み込み中"
            : `${formatDistanceToNow(runru?.createdAt.toDate(), {
                locale: ja,
              })} 前`}
        </div>
        <div className="text-gray-400 text-xs group-hover:text-orange-500 duration-300">
          {new Date(runru.createdAt?.toDate()).toLocaleString()}
        </div>
      </div>

      <div className="border border-orange-500 bg-orange-50 rounded-lg bg-opacity-80 h-fit px-3 py-1 m-2">
        <div className="text-gray-700">{runru?.cause}</div>
      </div>

      <div className="flex items-center space-x-4">
        <GetUserInfo uid={runru?.from} target="avatar" size={24} />
        <GetUserInfo uid={runru?.from} target="name" />
        <KeyboardDoubleArrowRightIcon sx={{ color: 'orange' }} />
        <GetUserInfo uid={runru?.to} target="avatar" size={24} />
        <GetUserInfo uid={runru?.to} target="name" />
      </div>

      <div className="flex items-baseline space-x-1">
        <div className="font-bold text-3xl text-orange-500">{runru?.amount}</div>
        <div className="text-xs text-gray-300 font-bold">runru</div>
      </div>
    </div>
  );
};

export default DisplayRunruHistory;
