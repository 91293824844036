import React, { useEffect } from "react";
import { requestForToken } from "./firebase"; // 正しく関数をimportします
import { Button } from "@mui/material";

const FirebaseNotification = () => {
  // useEffect(() => {
  //   // requestForTokenを直接呼び出します
  //   requestForTokenHandler();
  // }, []);

  const requestForTokenHandler = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // requestForTokenを呼び出して、トークンを取得します
        requestForToken().then((token) => {
          console.log("Notification token:", token);
          // トークンをサーバーに送信して保存する処理など
        });
      } else {
        console.log("Unable to get permission to notify.");
      }
    } catch (error) {
      console.error("An error occurred while retrieving token. ", error);
    }
  };

  // return null; // このコンポーネントはUIをレンダーしないのでnullを返します

  return (
    <>
      <Button className="w-fit" onClick={requestForTokenHandler}>通知を許可する</Button>
    </>
  );
};

export default FirebaseNotification;
