import React, { useState } from "react";
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";
import IndustryMenu from "../components/IndustryMenu";

const EditJob = ({ setOpen }) => {
  // job選択関連
  const [indust, setIndust] = useState("");
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    job: "",
    message: "",
    error: null,
    loading: false,
  });
  const { job, message, error, loading } = data;

  // プロフィール変更
  const handleChange = (e) => {
    // jobのnameが取れないので無理やり取る
    if (!e.target) {
      // 表示用
      setIndust(e);
      setData({ ...data, [e.name]: e.value });
    } else {
      setData({ ...data, [e.target.name]: e.target.value, message: "" });
    }
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄か、変更がないようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "users", user.uid), {
        [e.target.name]: data[e.target.name],
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      const errorData = {
        message: err.message,
        stack: err.stack,
      };

      addDoc(collection(db, "error"), {
        error: "jobChangeError",
        createdAt: serverTimestamp(),
        component: "EditJob",
        user,
        errorD: errorData,
      });

      setData({ ...data, error: err.message, loading: false });
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div>職業</div>
        <IndustryMenu handleChange={handleChange} indust={indust} />
        {/* <IndustryMenu /> */}
        <Button
          className="btn"
          name="job"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditJob;
