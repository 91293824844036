import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import DrawerListItem from "./DrawerListItem";
import ChatIcon from "@mui/icons-material/Chat";
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useNotification } from "../redux/useNotification";

const DrawerList = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState("");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const getInitUser = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((docSnap) => {
        setUserInfo({ id: docSnap.id, ...docSnap.data() });
      });
    };
    getInitUser();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { displayNotification } = useNotification();

  const handleToChat = () => {
    navigate("/chat");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleDrawerMenu = () => {
    setOpen(!open)
    console.log("hoge")
  }


  const drawerList = [
    { text: "チャット", icon: <ChatIcon />, onClick: handleToChat },
    { text: "近隣の村民さん", icon: <GroupsIcon />, onCick: handleDrawerMenu },
    // 他のリストアイテム...
  ];

  const filteredDrawerList = isDesktop
    ? drawerList.filter(item => item.text !== "近隣の村民さん")
    : drawerList;

  if (!userInfo?.isVillager) return null;


  return (
    <List>
      {filteredDrawerList.map((arr, index) => (
        <DrawerListItem
          key={index}
          text={arr.text}
          icon={arr.icon}
          open={open}
          onClick={arr.onClick}
          setOpen={setOpen}
        />
      ))}
    </List>
  );
};

export default DrawerList;
