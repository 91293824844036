import React, { useState, useEffect, memo } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { IconButton, Tooltip } from "@mui/material";
import { motion, useAnimation } from "framer-motion";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import GetUserInfo from "./GetUserInfo";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import EventLikeActionMembersAvatar from "./EventLikeActionMembersAvatar";

const EventLikeAction = ({ event }) => {
  const [liked, setLiked] = useState(false);
  const [stars, setStars] = useState([]);
  const heartControls = useAnimation();

  const [myReaction, setMyReaction] = useState("");
  const [postReactions, setPostReactions] = useState(0);
  const [postReactionUsers, setPostReactionUsers] = useState([]);

  useEffect(() => {
    const fetchReactionData = async () => {
      if (event && auth.currentUser) {
        const userDocRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "eventReactions",
          event.id
        );
        const userSnapshot = await getDoc(userDocRef);
        const postReactionsCollectionRef = collection(
          db,
          "event",
          event.id,
          "eventReactions"
        );
        const postReactionsSnapshot = await getDocs(postReactionsCollectionRef);

        if (userSnapshot.exists()) {
          const userReaction = userSnapshot.data().reaction;
          if (userReaction !== "noReaction") {
            setMyReaction("liked");
          } else {
            setMyReaction("");
          }
        } else {
          setMyReaction("");
        }

        // likeの数カウント
        let count = 0;
        postReactionsSnapshot.forEach((doc) => {
          const reaction = doc.data().reaction;
          if (reaction !== "noReaction") {
            count++;
          }
        });
        setPostReactions(count);

        // likeしてくれた人取得
        let arr = [];
        postReactionsSnapshot.forEach((elm) => {
          const reaction = elm.data().reaction;
          if (reaction !== "noReaction") {
            arr.push({ id: elm.id, ...elm.data() });
          }
        });
        setPostReactionUsers(arr);
      }
    };

    fetchReactionData();
  }, [event, auth.currentUser]);

  const animateHeart = async () => {
    await heartControls.start({ scale: 1.75 });
    await heartControls.start({ scale: 1 });
  };

  const handleLike = async (e) => {
    e.stopPropagation(); // 最初にイベント伝播を止める
    if (myReaction === "liked") {
      try {
        await updateReaction("noReaction");
        setMyReaction("");
        setLiked(false);
        setStars([]);
        e.stopPropagation();
      } catch (error) {
        console.log("Error:", error);
      }
    } else {
      try {
        await updateReaction("liked");
        setMyReaction("liked");
        setLiked(true);
        animateHeart();
        e.stopPropagation();
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  const [user, setUser] = useState("");
  useEffect(() => {
    const init = async () => {
      const userRef = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userRef.exists) {
        setUser(userRef.data());
      }
    };
    init();
  }, []);

  const updateReaction = async (reaction) => {
    const userDocRef = doc(
      db,
      "users",
      auth.currentUser.uid,
      "eventReactions",
      event.id
    );
    const userSnapshot = await getDoc(userDocRef);

    if (userSnapshot.exists()) {
      // ユーザーのリアクションが既に存在する場合は更新
      await updateDoc(userDocRef, {
        updatedAt: serverTimestamp(),
        reaction: reaction,
        creator: auth.currentUser.uid,
        avatar: user?.avatar,
        lastName: user?.lastName,
        firstName: user?.firstName,
      });
      await updateDoc(
        doc(db, "event", event.id, "eventReactions", auth.currentUser.uid),
        {
          updatedAt: serverTimestamp(),
          reaction: reaction,
          creator: auth.currentUser.uid,
          avatar: user?.avatar,
          lastName: user?.lastName,
          firstName: user?.firstName,
        }
      );
    } else {
      // ユーザーのリアクションが存在しない場合は新規作成
      await setDoc(userDocRef, {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        reaction: reaction,
        creator: auth.currentUser.uid,
        avatar: user?.avatar,
        lastName: user?.lastName,
        firstName: user?.firstName,
      });
      await setDoc(
        doc(db, "event", event.id, "eventReactions", auth.currentUser.uid),
        {
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          reaction: reaction,
          creator: auth.currentUser.uid,
          avatar: user?.avatar,
          lastName: user?.lastName,
          firstName: user?.firstName,
        }
      );
    }

    const postReactionsCollectionRef = collection(
      db,
      "event",
      event.id,
      "eventReactions"
    );
    const postReactionsSnapshot = await getDocs(postReactionsCollectionRef);
    setPostReactions(
      postReactionsSnapshot.docs.filter(
        (doc) => doc.data().reaction !== "noReaction"
      ).length
    );
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor: "#000000",
      color: "rgba(0, 0, 0, 0.40)",
      maxWidth: 440,
      fontSize: theme.typography.pxToRem(12),
      // border: "1px solid #dadde9",
    },
  }));

  // console.log("postReactionUsers =>", postReactionUsers.length);

  <motion.div
    animate={heartControls}
    whileHover={{ scale: 1.2 }}
    whileTap={{ scale: 0.8, rotate: 360 }}
  >
    <FavoriteIcon color={myReaction ? "error" : "disabled"} />
  </motion.div>;

  return (
    <div style={{ position: "relative" }}>
      <div
        className={` ${
          myReaction && " "
        } " flex  justify-start px-4   w-full hover:cursor-pointer  " `}
        onClick={handleLike}
      >
        <div className="flex ">
          <IconButton className="h-10">
            <motion.div animate={heartControls}>
              <FavoriteIcon color={myReaction ? "error" : "inherit"} />
            </motion.div>
          </IconButton>

          {/* <div className="flex items-center">{postReactions}</div> */}
        </div>
        {postReactionUsers && (
          <>
            <div className="flex items-center">
              <EventLikeActionMembersAvatar members={postReactionUsers} />
            </div>
          </>
        )}
        {/* <div className="flex w-full">
          {postReactionUsers?.slice(0, 2).map((elm, idx) => (
            <div
              key={elm.id}
              className="flex whitespace-nowrap text-blue-500 hover:cursor-pointer hover:underline"
            >
              <GetUserInfo
                uid={elm.id}
                target="avatar"
                size={30}
                key={elm.id}
              />
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default memo(EventLikeAction);
