import React from "react";
import { useLocation } from "react-router-dom";

const SendComplete = () => {

  const location = useLocation();

  console.log(location.state)
  return (
    <>
      <div className="flex flex-col  p-5 bg-back w-screen h-screen">
        <div>メンバー登録受け付けました。</div>
        <div>ご入力頂いたメールより、メンバー登録お願いします。</div>
        <div>セカイムラ事務局</div>
        <div
          tabIndex="0"
          className="collapse collapse-plus border border-base-300 my-2 bg-base-100 rounded-box"
        >
           <input type="checkbox" /> 
          <div className="collapse-title text-xl font-medium">
            メンバーサイトでできること
          </div>
          <div className="collapse-content">
            <div>
              ■セカイムラメンバーサイトは、さとうみつろう氏が中心となって作り上げるオンラインコミュニティです。
            </div>
            <div>
              ■メンバー同士でのメッセージのやり取りや、セカイムラ独自通貨でメンバーの提供するするDDPを交換できます。
            </div>
          </div>
        </div>
        <div
          tabIndex="1"
          className=" collapse collapse-plus border border-base-300 my-2 bg-base-100 rounded-box"
        >
           <input type="checkbox" /> 
          <div className="collapse-title text-xl font-medium">
            よくあるご質問
          </div>
          <div className="collapse-content">
            <QAA q="質問" a="答" />
            <QAA q="質問" a="答" />
            <QAA q="質問" a="答" />
          </div>
        </div>

        <div
          tabIndex="2"
          className="collapse collapse-plus border border-base-300 my-2 bg-base-100 rounded-box"
        >
           <input type="checkbox" /> 
          <div className="collapse-title text-xl font-medium">お問い合わせ</div>
          <div className="collapse-content">
            <div>■セカイムラ事務局</div>
            <div>■FamunityLink</div>
            <div>■TEL:000-0000-0000 平日9:00-1700</div>
            <div>■Mail xxxx@xxxx.com</div>

          </div>
        </div>
      </div>
    </>
  );
};

export default SendComplete;

const QAA = ({ q, a }) => {
  return (
    <>
      <div className="mb-4">
        <div className="flex items-start ">
          <div>
            <span className="inline-flex justify-center items-center w-6 h-6 rounded bg-green-500 text-white font-medium text-sm">
              Q
            </span>
          </div>
          <p className="ml-4 md:ml-6">{q}</p>
        </div>
        <div className="flex items-start mt-3">
          <div>
            <span className="inline-flex justify-center items-center w-6 h-6 rounded bg-gray-200 text-gray-800 font-medium text-sm">
              A
            </span>
          </div>
          <p className="ml-4 md:ml-6 text-gray-800">{a}</p>
        </div>
      </div>
      <div className="divider"></div>
    </>
  );
};
