import React, { useEffect, useState } from "react";
import { getDoc, doc, deleteDoc } from "firebase/firestore";
import { auth, db } from "../api/firebase";
// redux - use
import { useNotification } from "../redux/useNotification";

const QuitBase = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // ユーザ取得関連
  const [base, setBase] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // リクエスト取得
  useEffect(() => {
    if (requestId) {
      getDoc(doc(db, "base", requestId)).then((doSnap) => {
        if (doSnap.exists) {
            setBase(doSnap.data());
        }
      });
    }
  }, [requestId]);
  //   削除処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // 削除
      await deleteDoc(
        doc(db, "base", requestId, "members", auth.currentUser.uid)
      );
      // usersのaffiriationBaseからも削除
      await deleteDoc(
        doc(db, "users", auth.currentUser.uid, "affiliationBase",requestId )
      );


      setOpen(false);
      displayNotification({
        message: "拠点を脱退しました。",
        type: "success",
      });

      window.location.reload();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        拠点脱退
      </div>
      <form onSubmit={(e) => e.preventDefault()}>

        <div className="font-bold text-2xl">この拠点を脱退します</div>
        <div className="border-2 p-2 rounded-md mb-2">
          <div className="text-gray-300">拠点</div>
          <div>{base?.title}</div>
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "脱退処理中..." : "脱退する"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default QuitBase;
