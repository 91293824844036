import React, { useEffect, useState } from "react";
import {
  doc,
  collection,
  query,
  updateDoc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";

import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Grid, Tooltip } from "@mui/material";
import Loader1Row from "./Loader1Row";
import ModalForm from "./ModalForm";
import GetUserInfo from "./GetUserInfo";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";

// redux - use
import { useNotification } from "../redux/useNotification";

const EventTable = ({ pending }) => {
  const navigate = useNavigate();
  // redux - Notification
  const { displayNotification } = useNotification();

  //   const [basesCount, loading, error] = useCollection(collection(db, "bases"));

  const [dataloading, setDataLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [d_loding, setD_loading] = useState(false);
  const [originalRows, setOriginalRows] = useState([]);

  // 表示・非表示
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");

  const handleEdit = (e, id, target) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
    setTarget(target);
  };

  const q = query(collection(db, "event"), orderBy("publishDate", "desc"));
  const [collectionData] = useCollection(q);
  const data = collectionData?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  useEffect(() => {
    setOriginalRows(pending || data);
    setRows(pending || data);
    setDataLoading(true);
  }, [collectionData, pending]);

  // console.log("data =>", data);
  // console.log(rows[0]?.chiefLastName)
  // bases?.forEach((doc)=>{
  //   console.log(doc?.chiefLastName)
  //   const d = getDoc(doc(db,'doc.chiefLastName'))
  //   console.log(d)
  // })

  // テーブル使用コンテナ
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // テーブル使用handle
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   権限変更関連handle
  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "event", id));
    await updateDoc(doc(db, "event", id), {
      [type]: !pub.data()[type],
    });
    const changedPropertyValue = pub.data()[type] === true ? "オフ" : "オン";
    setDataLoading(true);
    setD_loading(false);

    displayNotification({
      message:
        "イベント[" +
        pub.data()["title"] +
        "]の掲載が" +
        changedPropertyValue +
        "に変更されました。",
    });
  };
  const handleChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  // 検索関連
  const requestSearch = (searchVal) => {
    const filteredRows = originalRows.filter((row) => {
      return (row.title + row.founder + row.representative)
        .toLowerCase()
        .includes(searchVal.target.value.toLowerCase());
    });
    setRows(filteredRows);
  };

  if (!dataloading) {
    return <Loader1Row />;
  }

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
        target={target}
      />
      <TextField
        id="search-bar"
        className="my-2"
        label="イベント検索"
        variant="standard"
        placeholder="イベント名"
        size="small"
        onChange={(searchVal) => requestSearch(searchVal)}
        sx={{ m: 1.5 }}
      ></TextField>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 240, borderRadius: 1 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            sx={{ minWidth: 650 }}
          >
            <TableHead>
              {/* ヘッダー情報 */}
              <TableRow>
                <TableCell>掲載</TableCell>
                <TableCell>イベント日</TableCell>
                <TableCell>作成</TableCell>
                <TableCell>イベント名</TableCell>
                <TableCell>対象</TableCell>
                <TableCell>運営</TableCell>
                <TableCell>登録日</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  className="flex group"
                >
                  {/* 掲載 */}
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.publish}
                          onChange={handleChange}
                          name="publish"
                          disabled={d_loding}
                          value={row.id}
                        />
                      }
                      label={row.publish ? "掲載" : ""}
                    />
                  </TableCell>

                  {/* イベント日 */}
                  <TableCell align="left">
                    <Grid className="">
                      {new Date(row.publishDate?.toDate()).toLocaleString()}
                    </Grid>
                  </TableCell>

                  {/* 創設者 */}
                  <TableCell component="th" scope="row">
                    <div className="flex flex-row items-center">
                      <div className="hidden lg:block">
                        <GetUserInfo
                          uid={row?.author}
                          target="avatar"
                          size={24}
                        />
                      </div>
                      <GetUserInfo uid={row?.author} target="name" />
                    </div>
                  </TableCell>

                  {/* グループ名 */}
                  <TableCell component="th" scope="row">
                    <Tooltip title="イベントページへ">
                      <div className="flex flex-row items-center hover:cursor-pointer hover:underline">
                        <div onClick={() => navigate(`/event/${row?.id}`)}>
                          {row?.title}
                        </div>
                      </div>
                    </Tooltip>
                  </TableCell>

                  {/* 対象 */}
                  <TableCell component="th" scope="row">
                    <div className="flex flex-row items-center">
                      <div>{row?.publishTarget === "group" && "グループ"}</div>
                      <div>{row?.publishTarget === "all" && "セカイムラ"}</div>
                    </div>
                  </TableCell>

                  {/* 運営発信？ */}
                  <TableCell component="th" scope="row">
                    <div className="flex flex-row items-center">
                      <div>{row?.target === "operation" && "○"}</div>
                    </div>
                  </TableCell>

                  {/* 登録日 */}
                  <TableCell align="left">
                    <Grid className="">
                      {new Date(row.createdAt?.toDate()).toLocaleString()}
                    </Grid>
                  </TableCell>

                  {/* 消去 */}
                  <TableCell align="left">
                    <DeleteIcon
                      name={row.id}
                      className=" sm:invisible sm:group-hover:visible cursor-pointer"
                      onClick={() => handleEdit("deleteEvent", row.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="ml-2 mt-2">
          {/* 掲載店舗：{publish.docs.length} / {realtimeRequestShop.docs.length} */}
        </div>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={pending?.length || data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default EventTable;

// テーブル検索
// https://smartdevpreneur.com/the-easiest-way-to-implement-material-ui-table-search/
