import { Add } from "@mui/icons-material";
import { Button, Fab, Input } from "@mui/material";
import React, { useRef } from "react";
import getCompressImageFile from "./imageCompress";

const Form = ({ setFiles }) => {
  const fileRef = useRef();
  const handleClick = () => {
    fileRef.current.click();
  };
  const handleChange = async (e) => {
    // console.log(e.target.files);
    const compressedFile = await getCompressImageFile(e.target.files[0]);
    setFiles([compressedFile]);
    // setFiles([...e.target.files])
    fileRef.current.value = null;
  };
  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <Input
          type="file"
          // inputProps={{ multiple: true }}
          sx={{ display: "none" }}
          inputRef={fileRef}
          onChange={handleChange}
        />
        <Button onClick={handleClick}>画像を追加</Button>
        {/* <Fab color="primary" aria-label="add" onClick={handleClick}>
          <Add fontSize="large" />
        </Fab> */}
      </form>
    </>
  );
};

export default Form;
