import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { getDoc, doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import DirectMessangeChatPro from "./DirectMessageChatPRO";
import ChatIcon from "@mui/icons-material/Chat";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DirectMessage({ memberId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [user, setUser] = useState("");
  useEffect(() => {
    const initSetUser = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    initSetUser();
  }, []);


  return (
    <div>
      <Button onClick={handleOpen} variant="contained">
        <ChatIcon />
        <div className="ml-2">話す</div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className="flex justify-between">
              <div className="font-bold text-xl">CHAT</div>
              <div className="font-bold text-2xl" onClick={handleClose}>
                ☓
              </div>
            </div>
            {user && <DirectMessangeChatPro user={user} memberId={memberId} />}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
