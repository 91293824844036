import React, { useState } from "react";

import { doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";

import { TextField, MenuItem } from "@mui/material";
import OperateVillagersShop from "../components/OperateVillagersShop";
import OperateCustomersInfo from "../components/OperateCustomersInfo";
import OperateBase from "../components/OperateBase";
import OperateRUNRU from "../components/OperateRUNRU.js";
import OperateFeed from "../components/OperateFeed";

// コンテンツ選択アイコン
import StoreIcon from "@mui/icons-material/Store";
import PaidIcon from "@mui/icons-material/Paid";
import ParkIcon from "@mui/icons-material/Park";
import ChatIcon from "@mui/icons-material/Chat";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import GroupsIcon from "@mui/icons-material/Groups";
import EventIcon from "@mui/icons-material/Event";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CampaignIcon from "@mui/icons-material/Campaign";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
// import LendField from "../data/svg/LendFileld.svg";
import { ReactComponent as LendfieldIcon } from "../data/svg/LendFieldKUWA.svg";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';


import { useDocumentData } from "react-firebase-hooks/firestore";
import OperateDDP from "../components/OperateDDP";
import Analyze from "../components/Analyze";
import OperateGroup from "../components/OperateGroup";
import OperateEvent from "../components/OperateEvent";
import SegmentIcon from "@mui/icons-material/Segment";
import OperateTerms from "../components/OperateTerms";
import Loading from "../components/Loading";
import OperateQuestions from "../components/OperateQuestions";
import OperateAnnounce from "../components/OperateAnnounce";
import OperateMovie from "../components/OperateMovie";
import OperateLendField from "../components/OperateLendField";
import OperateRecommendedSopt from "../components/OperateRecommendedSopt";
import OperateSupport from "../components/OperateSupport.js";
import OperatePushNotification from "../components/OperatePushNotification.js";
import OperateMusic from "../components/OperateMusic.js";



const AdminSetting = () => {
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  // 選択メニュー関連
  const AdminMenus = () => {
    const Icon = ({ Icon }) => {
      return <>{Icon && <Icon className="h-4 w-4" />}</>;
    };

    const MenuLabel = ({ value }) => {
      return (
        <>
          <div className="flex items-center space-x-2 rounded-md">
            <Icon Icon={storesIcon[value]} />
            <span>{value}</span>
          </div>
        </>
      );
    };

    const storesIcon = {
      村民さんのお店関連: StoreIcon,
      ユーザ権限関連: ManageAccountsIcon,
      拠点関連: ParkIcon,
      ルンル関連: PaidIcon,
      動画関連: OndemandVideoIcon,
      投稿関連: ChatIcon,
      DDP関連: JoinInnerIcon,
      統計: EqualizerIcon,
      グループ関連: GroupsIcon,
      イベント関連: EventIcon,
      利用規約関連: SegmentIcon,
      お問い合わせ関連: ContactMailIcon,
      お知らせ関連: CampaignIcon,
      おすすめスポット関連: LendfieldIcon,
      サポート関連:SupportAgentIcon,
      プッシュ通知関連:CallToActionIcon,
      音楽配信関連:LibraryMusicIcon,
    };

    const createMenus = (value) => {
      return {
        label: (
          <>
            <MenuLabel value={value} />
          </>
        ),
        name: value,
        value: value,
      };
    };
    const menus = [
      createMenus("村民さんのお店関連"),
      createMenus("ユーザ権限関連"),
      createMenus("拠点関連"),
      createMenus("ルンル関連"),
      createMenus("動画関連"),
      createMenus("投稿関連"),
      createMenus("DDP関連"),
      createMenus("統計"),
      createMenus("グループ関連"),
      createMenus("イベント関連"),
      createMenus("利用規約関連"),
      createMenus("お問い合わせ関連"),
      createMenus("お知らせ関連"),
      createMenus("おすすめスポット関連"),
      createMenus("サポート関連"),
      createMenus("プッシュ通知関連"),
      createMenus("音楽配信関連"),
    ];
    return menus;
  };

  // コンテンツ関連
  const [content, setContent] = useState();
  const ContentComponent = ({ value }) => {
    const conmponentMap = {
      村民さんのお店関連: <OperateVillagersShop />,
      ユーザ権限関連: <OperateCustomersInfo />,
      拠点関連: <OperateBase />,
      ルンル関連: <OperateRUNRU />,
      動画関連: <OperateMovie />,
      投稿関連: <OperateFeed />,
      DDP関連: <OperateDDP />,
      統計: <Analyze />,
      グループ関連: <OperateGroup />,
      イベント関連: <OperateEvent />,
      利用規約関連: <OperateTerms />,
      お問い合わせ関連: <OperateQuestions />,
      お知らせ関連: <OperateAnnounce />,
      おすすめスポット関連: <OperateRecommendedSopt />,
      サポート関連: <OperateSupport />,
      プッシュ通知関連: <OperatePushNotification />,
      音楽配信関連: <OperateMusic />,
    };
    return conmponentMap[value];
  };

  const handlechange = async (e) => {
    setContent(e.target.value);
  };

  if (!user) {
    <Loading />;
  }

  if (!user?.isAdmin) {
    return <div>不正アクセスです。</div>;
  }
  return (
    <>
      <div className="mx-2">
        <div className="font-bold text-3xl text-center">管理者設定画面</div>
        {/* 選択フィールド */}
        <div className="flex my-4 ml-2">
          <TextField
            id="outlined-select-currency"
            name="RequestShopIndustry"
            label="操作を選択してください"
            defaultValue=""
            className="w-full sm:w-1/2 "
            size="small"
            onChange={handlechange}
            select
            required
          >
            {AdminMenus().map((option) => (
              <MenuItem key={option.name} value={option.name} dense>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <ContentComponent value={content} />
      </div>
    </>
  );
};

export default AdminSetting;
