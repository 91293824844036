import React, { useState, useEffect } from "react";
import {
  Slider,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import { getDocs, collection } from "@firebase/firestore";
import { db } from "../api/firebase";

const MusicPlayer = () => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(100);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    // 曲が変更された時の処理をここに書く
  }, [currentTrack]);

  const [tracks, setTracks] = useState([]);
  useEffect(() => {
    const fetchTracks = async () => {
      const querySnapshot = await getDocs(collection(db, "tracks"));
      const tracksArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTracks(tracksArray);
      // 初期トラックを設定
      if (tracksArray.length > 0) {
        setCurrentTrack(tracksArray[0]);
      }
    };

    fetchTracks();
  }, []);

  const handlePlay = () => {
    setIsPlaying(true);
    // 再生処理を実装
  };

  const handlePause = () => {
    setIsPlaying(false);
    // 一時停止処理を実装
  };

  const handleStop = () => {
    setIsPlaying(false);
    // 停止処理を実装
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    // ボリューム調整処理を実装
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    // ミュート処理を実装
  };

  const handleTrackChange = (event) => {
    const trackId = event.target.value;
    const selectedTrack = tracks.find((track) => track.id === trackId);
    setCurrentTrack(selectedTrack);
  };

  const [currentTrackId, setCurrentTrackId] = useState("");

  const handlePlayPause = (trackId) => {
    if (currentTrackId === trackId && isPlaying) {
      setIsPlaying(false);
      // 曲の一時停止処理
    } else {
      setCurrentTrackId(trackId);
      setIsPlaying(true);
      // 曲の再生処理
    }
  };


  // UIのレンダリング
  return (
    <>
      <div className="border rounded-md p-2 flex flex-col items-center shadow-md hover:shadow-xl duration-150 hover:cursor-pointer">
        <Typography variant="h6">Now Playing: {currentTrack?.name}</Typography>
        {/* 曲の選択 */}
        <FormControl fullWidth>
          <InputLabel id="track-select-label">Track</InputLabel>
          <Select
            labelId="track-select-label"
            id="track-select"
            value={currentTrack?.id || ""}
            label="Track"
            onChange={handleTrackChange}
          >
            {tracks.map((track) => (
              <MenuItem key={track.id} value={track.id}>
                {track.name} by {track.artist}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* 再生、一時停止、停止ボタン */}
        <div>
          <Button onClick={handlePlay} disabled={isPlaying}>
            <PlayArrowIcon />
          </Button>
          <Button onClick={handlePause} disabled={!isPlaying}>
            <PauseIcon />
          </Button>
          <Button onClick={handleStop}>
            <StopIcon />
          </Button>
        </div>

        {/* ボリュームコントロール */}
        <Typography>Volume</Typography>
        <Slider
          value={volume}
          onChange={handleVolumeChange}
          aria-labelledby="continuous-slider"
          style={{ width: "calc(100% - 45px)" }} // 親要素の幅から左右合わせて80px分のpaddingを取る
        />

        {/* ミュートボタン */}
        <Button onClick={toggleMute}>{isMuted ? "Unmute" : "Mute"}</Button>

        {/* 曲の選択 */}
        {/* 実際のアプリケーションでは、ここに曲を選択するためのドロップダウンメニューなどを配置します */}

        {/* 曲とアーティスト名の表示 */}
        {/* ここでは単純化のためにテキスト表示に留めますが、スクロールさせるなどの実装を加えることが可能です */}
        <div>
          <Typography variant="body1">
            Playing: {currentTrack?.name} by {currentTrack?.artist}
          </Typography>
        </div>
      </div>

      <Grid container spacing={2}>
        {tracks.map((track) => (
          <Grid item xs={12} sm={6} md={4} key={track.id}>
            <Card>
              <CardMedia
                component="img"
                image={track.coverImageUrl || "default_cover_image_url"}
                alt="Cover Image"
              />
              <CardContent>
                <Typography variant="h5">{track.name}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {track.artist}
                </Typography>
              </CardContent>
              <IconButton onClick={() => handlePlayPause(track.id)}>
                {currentTrackId === track.id && isPlaying ? (
                  <PauseIcon />
                ) : (
                  <PlayArrowIcon />
                )}
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default MusicPlayer;
