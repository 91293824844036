// https://snazzymaps.com/style/15/subtle-grayscale
export const SubtleGrayscale = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: "50",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        lightness: "40",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        hue: "#ffff00",
      },
      {
        lightness: -25,
      },
      {
        saturation: -97,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        lightness: -25,
      },
      {
        saturation: -100,
      },
    ],
  },
];

//https://snazzymaps.com/style/1243/xxxxxxxxxxx
export const InterfaceMap = [
  [
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          hue: "#ffbb00",
        },
        {
          saturation: "5",
        },
        {
          lightness: 37.599999999999994,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          hue: "#00ff6a",
        },
        {
          saturation: "-50",
        },
        {
          lightness: 11.200000000000017,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          hue: "#ffc200",
        },
        {
          saturation: "-70",
        },
        {
          lightness: 45.599999999999994,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "all",
      stylers: [
        {
          hue: "#FF0300",
        },
        {
          saturation: -100,
        },
        {
          lightness: 51.19999999999999,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "all",
      stylers: [
        {
          hue: "#FF0300",
        },
        {
          saturation: -100,
        },
        {
          lightness: 52,
        },
        {
          gamma: 1,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          hue: "#0078ff",
        },
        {
          saturation: "-59",
        },
        {
          lightness: 2.4000000000000057,
        },
        {
          gamma: 1,
        },
      ],
    },
  ],
];
export const intefaceM = [
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        hue: "#ffbb00",
      },
      {
        saturation: "5",
      },
      {
        lightness: 37.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        hue: "#00ff6a",
      },
      {
        saturation: "-50",
      },
      {
        lightness: 11.200000000000017,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        hue: "#ffc200",
      },
      {
        saturation: "-70",
      },
      {
        lightness: 45.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        hue: "#FF0300",
      },
      {
        saturation: -100,
      },
      {
        lightness: 51.19999999999999,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        hue: "#FF0300",
      },
      {
        saturation: -100,
      },
      {
        lightness: 52,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        hue: "#2962ff",
      },
      {
        saturation: "-59",
      },
      {
        lightness: 2.4000000000000057,
      },
      {
        gamma: 1,
      },
    ],
  },
];
