// AnnouncementList.js

import React from 'react';

const AnnouncementList = ({ announcements }) => {
  return (
    <div>
      <ul>
        {announcements.map((announcement, index) => (
          <li key={index}>
            <h3 className=' text-blue-600 text-2xl'>{announcement.title}</h3>
            <p className=' text-sm text-gray-300'>{announcement.explain}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AnnouncementList;
