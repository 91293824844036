import React, { useEffect, useState } from "react";
import {
  getDoc,
  doc,
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import RatingComponent from "./RatingComponent";
// redux - use
import { useNotification } from "../redux/useNotification";
import { useDocumentData } from "react-firebase-hooks/firestore";

// 買った人を評価する
// 買われた人が、買った人を評価する
// 買った人に通知
// 買われた人が自分 auth.currentUser.uid
// 買った人は　deal.buy
const DealEvalutionFromBuyer = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // 取引を取得
  const [deal, setDeal] = useState();
  useEffect(() => {
    const initGetDeal = async () => {
      getDoc(doc(db, "deal", requestId)).then((doc) => {
        setDeal({ id: doc.id, ...doc.data() });
      });
    };
    requestId && initGetDeal();
  }, []);

  // 買った人を取得
  const [buy, setBuy] = useState();
  useEffect(() => {
    const initGetBuyUser = async () => {
      getDoc(doc(db, "users", deal?.buy)).then((doc) => {
        setBuy(doc.data());
      });
    };
    deal && initGetBuyUser();
  }, [deal]);

  // 買われた人を取得 - 自分
  const [buyer] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const setRequestDataShop = () => {};
  const [requestShopLoading, setRequestShopLoading] = useState(false);
  const [error, setError] = useState("");

  // const [product, setProduct] = useState("");
  const [data, setData] = useState({
    evalute: "スムーズで気持ちの良い取引、ありがとうございました。",
    rate: 5,
  });
  const { evalute, rate } = data;
  // 値変更
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  //   if (deal) {
  //     getDoc(doc(db, "products", deal?.data().product)).then((doc) => {
  //       setProduct({ id: doc.id, ...doc.data() });
  //     });
  //   }
  // }, [deal]);

  // console.log(product)

  const handleDelete = async (e) => {
    e.preventDefault();
    setRequestShopLoading(true);
    try {
      if (deal?.status === "buyEvaluted") {
        await updateDoc(doc(db, "deal", requestId), {
          status: "finished",
          lastUpdated: buyer?.uid,
          lastUpdatedAt:serverTimestamp(),

        });
      } else {
        await updateDoc(doc(db, "deal", requestId), {
          status: "buyerEvaluted",
          lastUpdated: buyer?.uid,
          lastUpdatedAt:serverTimestamp(),

        });
      }
      // ユーザの評価
      await addDoc(collection(db, `users/${deal?.buy}/dealEvalution`), {
        product: deal?.product,
        deal: deal?.id,
        rate,
        createdAt: serverTimestamp(),
        buyer: deal?.buyer,
        evalute,
        display: true,
      });

      //   ルンル情報追加
      //   await addDoc(collection(db, "runru"), {
      //     amount: deal?.data().amount,
      //     to: deal?.data().buyer,
      //     from:"",
      //     cause: "DDP交換",
      //     createdAt: serverTimestamp(),
      //   });

      // 通知情報登録 - 買われた人が買った人を評価した・・・なので
      // 買った人に、「買われた人が買った人を評価したよ」という通知をする
      // 買われた人 buyer 買った人 deal.buy=> deal.buy
      // fromはbuy
      await addDoc(collection(db, "users", deal?.buy, "notification"), {
        createdAt: serverTimestamp(),
        content: "DDP買った人評価",
        fromAvatar: buyer?.avatar,
        fromId: buyer?.uid,
        fromName: buyer?.lastName + buyer?.firstName,
        isRecognition: false,
        target: "deal",
        targetId: requestId,
      });

      setOpen(false);
      displayNotification({
        message: "評価処理完了しました。",
        type: "success",
      });

      // window.location.reload();
    } catch (err) {
      console.log(err);
      setRequestShopLoading(false);
      setError(err);
    }
  };

  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        交換希望者を評価する
      </div>
      <form onSubmit={setRequestDataShop}>
        {/* レート */}
        <RatingComponent rate={rate} handleChange={handleChange} />
        <textarea
          style={{ resize: "none", fontSize: "16px" }}
          type="text"
          name="explain"
          className="textarea textarea-bordered w-full h-24  "
          placeholder={evalute}
          defaultValue=""
          onChange={handleChange}
        ></textarea>
        <div className="font-bold text-2xl">
          以上の内容で交換希望者を評価します、よろしいですか？
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={requestShopLoading}
          onClick={handleDelete}
        >
          {requestShopLoading ? "送信しています..." : "はい"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DealEvalutionFromBuyer;
