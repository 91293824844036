import React, { memo, useEffect, useState } from "react";
import UserCard from "../components/UserCard";
import SwitchComponent from "../components/SwitchComponent";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import RequestShopImage from "./RequestShopImage";
import { Typography } from "@mui/material";
import moment from "moment";
const DisplayRecommendShop = ({ shop }) => {
  const navigate = useNavigate();

  const handleMove = () => {
    navigate("/mapPoint/" + shop.id);
  };

  const [shopType, setShopType] = useState("");
  useEffect(() => {
    if (shop?.type === "userShop") {
      setShopType("requestShop");
    }
    if (shop?.type === "korakuen") {
      setShopType("requestShop");
    }
    if (shop?.type === "lendField") {
      setShopType("recommendedSpot");
    }
    if (shop?.type === "lendOldHouse") {
      setShopType("recommendedSpot");
    }
    if (shop?.type === "powerSpot") {
      setShopType("recommendedSpot");
    }
    if (shop?.type === "naturalFood") {
      setShopType("recommendedSpot");
    }
    if (shop?.type === "alternativeMedicine") {
      setShopType("recommendedSpot");
    }
  }, []);

  return (
    <>
      <div className=" border rounded-md shadow-md hover:shadow-2xl hover:cursor-pointer duration-150 hover:border-blue-500">
        {/* 名前とイメージ */}
        <div className="relative">
          {/* 名称 */}
          <div className="font-bold text-xl  whitespace-pre-wrap break-words line-clamp-2 absolute top-2 left-2 z-10 w-fit rounded-md bg-teal-900 text-white opacity-80 flex p-2  hover:cursor-pointer">
            {shop?.name}
          </div>
          {/* イメージ */}
          <div>
            {shopType && (
              <RequestShopImage id={shop?.id} collection={shopType} />
            )}
          </div>
          {/* 時間 */}
          <Typography
            variant="body2"
            component="span"
            sx={{
              position: "absolute",
              bottom: 6,
              left: 6,
              color: "white",
              background: "rgba(0,0,0, .3)",
              p: "5px",
              borderRadius: 2,
            }}
          >
            {moment(shop?.createdAt?.toDate()).fromNow()}
          </Typography>
          {/* タイプ */}
          <div className=" absolute bottom-3 right-3 rounded-md bg-blue-900 opacity-75 flex items-center space-x-2 py-1 px-2">
            <div className="text-white text-xs">{shop?.type}</div>
          </div>
        </div>

        {/* 編集ボタン */}
        <div
          className="flex justify-end mx-4 text-xs text-blue-700 "
          onClick={handleMove}
        >
          編集
        </div>

        {/* 許可関連 */}
        <div className="flex flex-col border hover:border-blue-500 m-3 p-3 rounded-md shadow-md hover:shadow-2xl duration-150">
          <div className="font-bold text-2xl">許可関連</div>
          <div className="flex items-center space-x-2">
            <div className="w-fit">許可</div>
            <SwitchComponent
              id={shop?.id}
              target="isPermission"
              collection="recommendedSpot"
              text="許可"
            />
            <div className="w-fit">公開</div>
            <SwitchComponent
              id={shop?.id}
              target="isPublish"
              collection="recommendedSpot"
              text="公開"
            />
          </div>
          <div className="text-xs text-gray-400">
            [公開]・[許可] 共にオンの場合、マップに載ります
          </div>
        </div>

        {/* 詳細 */}
        <div className="flex flex-col my-2 border hover:border-blue-500 m-3 p-3 rounded-md shadow-md hover:shadow-2xl duration-150">
          <div className="font-bold text-2xl">詳細</div>
          {/* 住所 */}
          <div className="flex items-center space-x-2">
            <div className="w-fit">住所</div>
            <div>{shop?.address}</div>
          </div>
          {/* URL */}
          <div className="flex items-center space-x-2">
            <div className="w-fit">URL</div>
            <div>{shop?.url}</div>
          </div>

          {/* URL */}
          <div className="flex items-center space-x-2">
            <div className="w-fit">登録者</div>
            <div className="w-fit">
              <UserCard id={shop?.creator} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(DisplayRecommendShop);
