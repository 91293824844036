import React from "react";

// MaterialUI
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { IconButton } from "@mui/material";

import AddProduct from "./AddProduct";
import EditBirthDate from "./EditBirthDate";
import EditJob from "./EditJob";
import EditFirstName from "./EditFirstName";
import EditLastName from "./EditLastName";
import DeleteColumn from "./DeleteColumn";
import CreateBase from "./CreateBase";
import HereIsThisOnMap from "./HereIsThisOnMap";
import RequestShop from "./RequestShop";
import AddOffering from "./AddOffering";
import AddRecieve from "./AddRecieve";
import AddDdpTarget from "./AddDdpTarget";
import AddDdpThing from "./AddDdpThing";
import AddDdpCombination from "./AddDdpCombination";
import DeleteDdpMatching from "./DeleteDdpMatching";
import EditDdpMatching from "./EditDdpMatching";
import DeleteDdpTarget from "./DeleteDdpTarget";
import AddOnlyDdpTarget from "./AddOnlyDdpTarget";
import DeleteRequestShop from "./DeleteRequestShos";
import DeleteUser from "./DeleteUser";
import RunruAddOrPull from "./RunruAddOrPull";
import RunruSpecialGift from "./RunruSpecialGift";
import RunruAddForAll from "./RunruAddForAll";
import RunruAddForVillagers from "./RunruAddForVillagers";
import DisplayQRCode from "./DisplayQRCode";
import ReallyBuyDDP from "./ReallyBuyDDP";
import DealCancel from "./DealCancel";
import DealAccepted from "./DealAccepted";
import DealCancelFromBuyer from "./DealCancelFromBuyer";
import DealShipped from "./DealShipped";
import DealArrived from "./DealArrived";
import DealEvalution from "./DealEvalution";
import DealEvalutionFromBuyer from "./DealEvalutionFromBuyer";
import DealReadyGo from "./DealReadyGo.js";
import AddMemberInChat from "./AddMemberInChat";
import AnswerProduct from "./AnswerProduct";
import WriteReview from "./WriteReview";
import DeleteEvalute from "./DeleteEvalute";
import DeleteReview from "./DeleteReview";
import AddGroupType from "./AddGroupType";
import MakeGroup from "./MakeGroup";
import DeleteGroup from "./DeleteGroup";
import MakeEvent from "./MakeEvent";
import AddTerm from "./AddTerm";

import CloseIcon from "@mui/icons-material/Close";
import QuitGroup from "./QuitGroup";
import AddGroup from "./AddGroup";
import PermisstionJoinGroup from "./PermittionJoinGroup";
import EditGroupTitle from "./EditGroupTitle";
import EditGroupExplain from "./EditGroupExplain";
import EditGroupType from "./EditGroupType";
import EditGroupAccept from "./EditGroupAccept";
import AddEvent from "./AddEvent";
import EditEventExplain from "./EditEventExplain";
import EditEventTitle from "./EditEventTitle";
import EditEventTime from "./EditEventTime";
import SendMail from "./SendMail";
import FirstGuide from "./FirstGuide";
import { memo } from "react";
import LatestTerm from "./LatestTemr";
import JoinEvent from "./JoinEvent";
import ExitEvent from "./ExitEvent";
import EditEventPlace from "./EditEventPlace";
import EditEventEmail from "./EditEventEmail";
import EditEventMeetingPlace from "./EditEventMeetingPlace";
import EditEventURL from "./EditEventURL";
import ReallyJumpToURL from "./ReallyJumpToURL";
import EditEventPrice from "./EditEventPrice";
import AddQuestionForEvent from "./AddQuestionForEvent";
import AddAnswerForEvent from "./AddAnswerForEvent";
import AddScheduleForEvent from "./AddScheduleForEvent";
import EditScheduleForEvent from "./EditScheduleForEvent";
import UpdateQuestionForAdmin from "./UpdateQuestionForAdmin";
import Inquiry from "./Inquiry";
import AddQuestionType from "./AddQuestionType";
import EditQuestionType from "./editQuestionType";
import DeleteQuestionType from "./deleteQuestionType";
import AddResultType from "./AddResultType";
import EditResultType from "./EditResultType";
import DeleteResultType from "./DeleteResultType";
import DisplayInquiryHistory from "./DisplayInquiryHistory";
import AddEventOverView from "./AddEventOverView";
import EditEventOverview from "./EditEventOverview";
import AddBaseChief from "./AddBaseChief";
import AddBase from "./AddBase";
import PermissionJoinBase from "./PermissionJoinBase";
import QuitBase from "./QuitBase";
import MakeBaseEvent from "./MakeBaseEvent";
import DeleteEvent from "./DeleteEvent";
import EditBaseTitle from "./EditBaseTitle";
import CreateAnnounce from "./CreateAnnounce";
import DeleteAnnounce from "./DeleteAnnounce";
import EditAnnounceTitle from "./EditAnnounceTitle";
import EditAnnouncePublishDate from "./EditAnnouncePublishDate";
import EditAnnounceContent from "./EditAnnounceContent";
import AddMovie from "./AddMovie";
import AddMovieChannel from "./AddMovieChannel";
import DeleteMovie from "./DeleteMovie";
import EditUserName from "./EditUserName";
import AddRecommendedSopt from "./AddRecommendedSopt";
import EditAddressAndLatLng from "./EditAddressAndLatLng";
import EditLat from "./EditLat";
import EditLng from "./EditLng";
import EditTel from "./EditTel";
import EditURL from "./EditURL";
import EditNickName from "./EditNickName";
import EditPostComment from "./EditPostComment";
import ReplyPostComment from "./ReplyPostComment";
import DeleteComment from "./DeleteComment";
import EditPostCommentReply from "./EditPostCommentReply";
import DeleteReply from "./DeleteReply";
import AddPost from "./AddPost";
import DisplayPostImageCarousel from "./DisplayPostImageCarousel";
import EditPost from "./EditPost";
import EditPostMessage from "./EditpostMessage";
import EditPostImage from "./EditPostImage";
import FeedPrivate from "./FeedPrivate";
import DeleteBaseChief from "./DeleteBaseChief";
import CreateSupport from "./CreateSupport.js";
import EditInformationTitle from "./EditInformationTitle.js";
import EditRecomendedPoint from "./EditRecomendedPoint.js";
import EditOnething from "./EditOnething.js";
import EditGreetingFromBase from "./EditGreetingFromBase.js";
import AddDummyGeoCOde from "./AddDummyGeoCode.js";
import AdminCompulsionDealStop from "./AdminCompulsionDealStop.js";

const ModalForm = ({ form, setOpen, open, requestId, target, radius }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const radiusStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    borderRadius: "2%",
  };

  const forms = {
    addProduct: <AddProduct setOpen={setOpen} />,
    editBirthDate: <EditBirthDate setOpen={setOpen} />,
    editJob: <EditJob setOpen={setOpen} />,
    editFirstName: <EditFirstName setOpen={setOpen} />,
    editLastName: <EditLastName setOpen={setOpen} />,
    deleteBase: (
      <DeleteColumn setOpen={setOpen} requestId={requestId} target={target} />
    ),
    createBase: <CreateBase setOpen={setOpen} />,
    addGeoCode: (
      <HereIsThisOnMap
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    addDummyGeoCode: (
      <AddDummyGeoCOde
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    requestShop: <RequestShop setOpen={setOpen} />,
    addOffering: <AddOffering setOpen={setOpen} />,
    addRecieve: <AddRecieve setOpen={setOpen} />,
    addDdpTarget: <AddDdpTarget setOpen={setOpen} />,
    addDdpThing: <AddDdpThing setOpen={setOpen} />,
    addDdpCombination: <AddDdpCombination setOpen={setOpen} />,
    deleteDdpMatching: (
      <DeleteDdpMatching setOpen={setOpen} requestId={requestId} />
    ),
    editDdpMatching: (
      <EditDdpMatching setOpen={setOpen} requestId={requestId} />
    ),
    deleteDdpTarget: (
      <DeleteDdpTarget
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    addOnlyDdpTarget: (
      <AddOnlyDdpTarget setOpen={setOpen} requestId={requestId} />
    ),
    deleteRequestShop: (
      <DeleteRequestShop setOpen={setOpen} requestId={requestId} />
    ),
    deleteUser: <DeleteUser setOpen={setOpen} requestId={requestId} />,
    runruAddOrPull: <RunruAddOrPull setOpen={setOpen} />,
    runruSpecialGift: (
      <RunruSpecialGift setOpen={setOpen} requestId={requestId} />
    ),
    runruAddForAll: <RunruAddForAll setOpen={setOpen} />,
    runruAddForVillagers: <RunruAddForVillagers setOpen={setOpen} />,
    displayQRCode: <DisplayQRCode setOpen={setOpen} />,
    reallyBuyDDP: <ReallyBuyDDP setOpen={setOpen} requestId={requestId} />,
    dealCancel: <DealCancel setOpen={setOpen} requestId={requestId} />,
    adminCompulsionDealStop: <AdminCompulsionDealStop setOpen={setOpen} requestId={requestId} />,

    dealAccepted: <DealAccepted setOpen={setOpen} requestId={requestId} />,
    dealCancelFromBuyer: (
      <DealCancelFromBuyer setOpen={setOpen} requestId={requestId} />
    ),
    dealShipped: <DealShipped setOpen={setOpen} requestId={requestId} />,
    dealArrived: <DealArrived setOpen={setOpen} requestId={requestId} />,
    dealEvalution: <DealEvalution setOpen={setOpen} requestId={requestId} />,
    dealEvalutionFromBuyer: (
      <DealEvalutionFromBuyer setOpen={setOpen} requestId={requestId} />
    ),
    dealReadyGo: <DealReadyGo setOpen={setOpen} requestId={requestId} />,
    addMemberInChat: (
      <AddMemberInChat setOpen={setOpen} requestId={requestId} />
    ),
    answerProduct: <AnswerProduct setOpen={setOpen} requestId={requestId} />,
    writeReview: <WriteReview setOpen={setOpen} requestId={requestId} />,
    deleteEvalute: <DeleteEvalute setOpen={setOpen} requestId={requestId} />,
    deleteReview: <DeleteReview setOpen={setOpen} requestId={requestId} />,
    addGroupType: <AddGroupType setOpen={setOpen} requestId={requestId} />,
    makeGroup: <MakeGroup setOpen={setOpen} requestId={requestId} />,
    deleteGroup: <DeleteGroup setOpen={setOpen} requestId={requestId} />,
    quitGroup: <QuitGroup setOpen={setOpen} requestId={requestId} />,
    addGroup: <AddGroup setOpen={setOpen} requestId={requestId} />,
    permissionJoinGroup: (
      <PermisstionJoinGroup
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    editGroupTitle: <EditGroupTitle setOpen={setOpen} target={target} />,
    editGroupExplain: <EditGroupExplain setOpen={setOpen} target={target} />,
    editGroupType: <EditGroupType setOpen={setOpen} target={target} />,
    editGroupAccept: <EditGroupAccept setOpen={setOpen} target={target} />,
    makeEvent: <MakeEvent setOpen={setOpen} target={target} />,
    addEvent: <AddEvent setOpen={setOpen} target={target} />,
    editEventExplain: (
      <EditEventExplain setOpen={setOpen} requestId={requestId} />
    ),
    editEventTitle: <EditEventTitle setOpen={setOpen} requestId={requestId} />,
    editEventTime: <EditEventTime setOpen={setOpen} requestId={requestId} />,
    sendMail: <SendMail setOpen={setOpen} />,
    forFirstLoginUser: <FirstGuide setOpen={setOpen} />,
    latestTerm: <LatestTerm setOpen={setOpen} />,
    addTerm: <AddTerm setOpen={setOpen} />,

    joinEvent: <JoinEvent setOpen={setOpen} />,
    exitEvent: <ExitEvent setOpen={setOpen} />,

    editEventPlace: <EditEventPlace setOpen={setOpen} />,
    editEventMeetingPlace: <EditEventMeetingPlace setOpen={setOpen} />,
    editEventURL: <EditEventURL setOpen={setOpen} />,
    reallyJumpToURL: <ReallyJumpToURL setOpen={setOpen} target={target} />,
    editEventPrice: <EditEventPrice setOpen={setOpen} />,
    editEventGeo: <ExitEvent setOpen={setOpen} />,
    addQuestionForEvent: <AddQuestionForEvent setOpen={setOpen} />,
    addAnswerForEvent: <AddAnswerForEvent setOpen={setOpen} target={target} />,
    editEventEmail: <EditEventEmail setOpen={setOpen} />,
    addScheduleForEvent: (
      <AddScheduleForEvent setOpen={setOpen} target={target} />
    ),
    editScheduleForEvent: (
      <EditScheduleForEvent setOpen={setOpen} target={target} />
    ),
    updateQuestionForAdmin: (
      <UpdateQuestionForAdmin setOpen={setOpen} target={target} />
    ),
    inquiry: <Inquiry setOpen={setOpen} target={target} />,
    addQuestionType: <AddQuestionType setOpen={setOpen} target={target} />,
    editQuestionType: <EditQuestionType setOpen={setOpen} target={target} />,
    deleteQuestionType: (
      <DeleteQuestionType setOpen={setOpen} target={target} />
    ),
    addResultType: <AddResultType setOpen={setOpen} target={target} />,
    editResultType: <EditResultType setOpen={setOpen} target={target} />,
    deleteResultType: <DeleteResultType setOpen={setOpen} target={target} />,
    displayInquiryHistory: (
      <DisplayInquiryHistory setOpen={setOpen} target={target} />
    ),

    addEventOverView: (
      <AddEventOverView setOpen={setOpen} requestId={requestId} />
    ),
    editEventOverview: (
      <EditEventOverview setOpen={setOpen} requestId={requestId} />
    ),
    addBaseChief: <AddBaseChief setOpen={setOpen} requestId={requestId} />,
    quitBase: <QuitBase setOpen={setOpen} requestId={requestId} />,
    addBase: <AddBase setOpen={setOpen} requestId={requestId} />,
    permissionJoinBase: (
      <PermissionJoinBase
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    makeBaseEvent: <MakeBaseEvent setOpen={setOpen} target={target} />,
    deleteEvent: <DeleteEvent setOpen={setOpen} requestId={requestId} />,
    editBaseTitle: <EditBaseTitle setOpen={setOpen} requestId={requestId} />,
    createAnnounce: <CreateAnnounce setOpen={setOpen} />,
    createSupport: <CreateSupport setOpen={setOpen} />,
    editFlexContent: <MakeBaseEvent setOpen={setOpen} target={target} />,
    deleteAnnounce: <DeleteAnnounce setOpen={setOpen} requestId={requestId} />,
    editAnnounceTitle: (
      <EditAnnounceTitle setOpen={setOpen} requestId={requestId} />
    ),
    editAnnouncePublishDate: (
      <EditAnnouncePublishDate setOpen={setOpen} requestId={requestId} />
    ),
    editAnnounceContent: (
      <EditAnnounceContent setOpen={setOpen} requestId={requestId} />
    ),
    addMovie: <AddMovie setOpen={setOpen} target={target} />,
    addMovieChannel: <AddMovieChannel setOpen={setOpen} target={target} />,
    deleteMovie: <DeleteMovie setOpen={setOpen} requestId={requestId} />,
    editUserName: <EditUserName setOpen={setOpen} requestId={requestId} />,
    addRecommendedSopt: <AddRecommendedSopt setOpen={setOpen} />,
    editAddressAndLatLng: (
      <EditAddressAndLatLng
        setOpen={setOpen}
        target={target}
        requestId={requestId}
      />
    ),

    editLat: (
      <EditLat setOpen={setOpen} target={target} requestId={requestId} />
    ),
    editLng: (
      <EditLng setOpen={setOpen} target={target} requestId={requestId} />
    ),
    editTel: (
      <EditTel setOpen={setOpen} target={target} requestId={requestId} />
    ),
    editURL: (
      <EditURL setOpen={setOpen} target={target} requestId={requestId} />
    ),
    editRecomendedPoint: (
      <EditRecomendedPoint setOpen={setOpen} target={target} requestId={requestId} />
    ),
    editOnething: (
      <EditOnething setOpen={setOpen} target={target} requestId={requestId} />
    ),


    editNickName: <EditNickName setOpen={setOpen} />,
    editPostComment: (
      <EditPostComment
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    replyPostComment: (
      <ReplyPostComment
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    deleteComment: (
      <DeleteComment setOpen={setOpen} requestId={requestId} target={target} />
    ),
    editPostCommentReply: (
      <EditPostCommentReply
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    deleteReply: (
      <DeleteReply setOpen={setOpen} requestId={requestId} target={target} />
    ),
    addPost: <AddPost setOpen={setOpen} />,
    displayPostImages: (
      <DisplayPostImageCarousel setOpen={setOpen} requestId={requestId} />
    ),
    editPost: <EditPost setOpen={setOpen} requestId={requestId} />,
    editPostMessage: (
      <EditPostMessage
        setOpen={setOpen}
        requestId={requestId}
        target={target}
      />
    ),
    editPostImage: (
      <EditPostImage setOpen={setOpen} requestId={requestId} target={target} />
    ),
    feedPrivate: <FeedPrivate setOpen={setOpen} requestId={requestId} />,
    deleteBaseChief: (
      <DeleteBaseChief setOpen={setOpen} requestId={requestId} />
    ),

    editInformationTitle: (
      <EditInformationTitle setOpen={setOpen} requestId={requestId} />
    ),

    editGreetingFromBase: (
      <EditGreetingFromBase setOpen={setOpen} target={target} requestId={requestId} />
    ),


  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClose = (event, reason) => {
    // firstLoginInfoが開かれて、backDropをクリックした場合
    const updateFirstLoginInfo = async () => {
      if (form === "forFirstLoginUser") {
        return;
      }
      if (form === "requestShop") {
        window.location.reload();
      }
      if (form === "editAddressAndLatLng") {
        window.location.reload();
      }
      if (form === "addRecommendedSopt") {
        window.location.reload();
      }
      if (reason && reason === "backdropClick") setOpen(false);
    };

    updateFirstLoginInfo();
    handleClose();
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        // onClose={()=>setOpen(false)}
        closeAfterTransition
        disableEscapeKeyDown // ESCを押しても消えない
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={onClose}
      >
        <Fade in={open}>
          <Box sx={radius ? radiusStyle : style} className="flex flex-col">
            {form !== "forFirstLoginUser" && (
              <div
                className={` ${
                  radius && "p-3"
                }  "  w-fit absolute right-5 top-2 cursor-pointer hover:cursor-pointer"`}
                onClick={onClose}
              >
                <CloseIcon />
              </div>
            )}
            {/* <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton> */}

            {forms[form]}
          </Box>
          {/* <Box sx={style}>{form}</Box> */}
        </Fade>
      </Modal>
    </>
  );
};

export default memo(ModalForm);
