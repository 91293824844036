import React, { useState, useEffect } from "react";
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
// redux - use
import { useNotification } from "../redux/useNotification";
import { useDocumentData } from "react-firebase-hooks/firestore";

// 買われた人が、この取引OKって時に出す
// 買われた人が auth.currentUser.uid
// 買った人は deal.buy

const DealAccepted = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // 取引を取得
  const [deal, setDeal] = useState();
  useEffect(() => {
    const initGetDeal = async () => {
      getDoc(doc(db, "deal", requestId)).then((doc) => {
        setDeal(doc.data());
      });
    };
    requestId && initGetDeal();
  }, []);

  // 買った人を取得
  const [buy, setBuy] = useState();
  useEffect(() => {
    const initGetBuyUser = async () => {
      getDoc(doc(db, "users", deal?.buy)).then((doc) => {
        setBuy(doc.data());
      });
    };
    deal && initGetBuyUser();
  }, [deal]);

  // console.log("deal =>",deal)
  //   console.log("buy =>",buy)

  // 買われた人を取得 - 自分
  const [buyer] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // console.log("buyer =>",buyer)

  const setRequestDataShop = () => {};
  const [requestShopLoading, setRequestShopLoading] = useState(false);
  const [error, setError] = useState("");

  const handle = async (e) => {
    e.preventDefault();
    setRequestShopLoading(true);
    try {
      await updateDoc(doc(db, "deal", requestId), {
        status: "accepted",
        lastUpdated: buyer?.uid,
        lastUpdatedAt: serverTimestamp(),
      });
      //   ルンル情報追加
      await addDoc(collection(db, "runru"), {
        amount: deal?.amount,
        from: deal?.buy,
        fromSexType: buy?.sextype || "dontAnswer",
        fromLastName: buy?.lastName || "UnKnown",
        fromFirstName: buy?.firstName || "UnKnown",
        fromBloodType: buy?.bloodtype || "UnKnown",
        fromBirthYear: buy?.birthyear ||"UnKnown" ,
        fromBirthMonth: buy?.birthmonth || "UnKnown",
        fromBirthDay: buy?.birthday || "UnKnown",
        fromPrefecture: buy?.prefecture || "UnKnown",
        to: deal?.buyer,
        toSexType: buyer?.sextype || "dontAnswer",
        toLastName: buyer?.lastName || "UnKnown",
        toFirstName: buyer?.firstName ||"UnKnown" ,
        toBloodType: buyer?.bloodtype || "UnKnown",
        toBirthYear: buyer?.birthyear || "UnKnown",
        toBirthMonth: buyer?.birthmonth || "UnKnown",
        toBirthDay: buyer?.birthday || "UnKnown",
        toPrefecture: buyer?.prefecture || "UnKnown",
        cause: "DDP交換",
        createdAt: serverTimestamp(),
        dealId:requestId || "UnKnown",
      });
      // 通知情報登録 - 買われた人が受諾を通知・・・なので
      // 買った人に「買われた人が受諾したよ」って通知する
      // 買った人 deal.buy 買われた人 auth.currentUser.uid
      // fromは買われた人
      await addDoc(collection(db, "users", deal?.buy, "notification"), {
        createdAt: serverTimestamp(),
        content: "DDP受諾",
        fromAvatar: buyer?.avatar,
        fromId: buyer?.uid,
        fromName: buyer?.lastName || "UnKnown" + buyer?.firstName || "UnKnown",
        isRecognition: false,
        target: "deal",
        targetId: requestId,
      });

      setOpen(false);
      displayNotification({
        message: "取引を受諾しました。DDP交換して下さい。",
        type: "success",
      });
    } catch (err) {
      setRequestShopLoading(false);
      setError(err);

      const errorData = {
        message: err.message,
        stack: err.stack,
      };
      addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: errorData,
        buy:buy,
        buyer:buyer,
        deal:deal
      });



      // addDoc(collection(db, "error"), {
      //   error: error,
      //   createdAt: serverTimestamp(),
      //   buyer: buyer,
      //   buy: buy,
      //   deal: deal,
      // });
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        取引受諾確認
      </div>
      <form onSubmit={handle}>
        <div className="font-bold text-2xl">
          この取引を受諾します、よろしいですか？
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={requestShopLoading}
          onClick={handle}
        >
          {requestShopLoading ? "受諾しています..." : "受諾"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DealAccepted;
