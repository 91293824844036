import React from "react";
import GetUserInfo from "./GetUserInfo";
import SwitchComponent from "./SwitchComponent";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import GetUserPrefecture from "./GetUserPrefecture";
import GetUserField from "./GetUserField";
import GetUserStatus from "./GetUserStatus";

const RecommendedSpotInfo = ({ spot }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/mapPoint/" + spot?.id);
  };

  const changeType = {
    lendField: { id: "lendField", text: "貸畑", color: "bg-amber-700" },
    lendOldHouse: { id: "lendField", text: "貸古民家", color: "bg-green-700" },
    powerSpot: {
      id: "lendField",
      text: "パワースポット",
      color: " bg-purple-700",
    },
    naturalFood: { id: "lendField", text: "自然食品", color: "bg-sky-700" },
    alternativeMedicine: {
      id: "lendField",
      text: "代替医療",
      color: "bg-red-700",
    },
  };

  return (
    <>
      <div className="grid border rounded-md p-3 mb-4 w-96 shadow-md hover:cursor-pointer hover:shadow-xl duration-150 hover:bg-white space-y-2 ">
        {/* 申請日 */}
        <div className="flex items-center justify-end">
          <p className=" text-xs text-gray-400">
            申請：{new Date(spot?.createdAt?.toDate()).toLocaleString()}
          </p>
        </div>

        {/* 名称と種類 */}
        <div className=" flex space-x-2 items-center">
          <div className="flex font-bold text-xl items-center">{spot.name}</div>
          <div
            className={` ${
              changeType[spot.type].color
            }   " rounded-full text-xs  text-center px-2 py-1 text-white "`}
          >
            {changeType[spot.type].text}
          </div>
        </div>
        {/* 住所 */}
        <div>
          <div className="text-xs text-gray-500">@{spot.address}</div>
          <div className="text-xs text-gray-500">
            @{spot.lat}:{spot.lng}
          </div>
        </div>

        {/* 許可・公開関連 */}
        <div className="flex justify-between items-center border pl-2">
          <div className="w-fit">事務局許可</div>
          <SwitchComponent
            id={spot?.id}
            target="isPermission"
            collection="recommendedSpot"
            // text="許可"
          />
        </div>
        <div className="flex justify-between items-center border pl-2">
          <div className="w-fit">Map公開</div>
          <SwitchComponent
            id={spot?.id}
            target="isPublish"
            collection="recommendedSpot"
            // text="公開"
          />
        </div>
        <div className="flex justify-between items-center border pl-2">
          <div className="w-fit">おすすめポイント表示</div>
          <SwitchComponent
            id={spot?.id}
            target="isPublishRecomendedPoint"
            collection="recommendedSpot"
            // text="公開"
          />
        </div>


        {/* 申請者とひとこと */}
        <div className="flex space-x-2">
          <GetUserInfo uid={spot?.creator} target="avatar" size={30} />
          <div className="flex flex-col ">
            <div className="flex space-x-1 items-center">
              <div
                className="font-bold hover:underline"
                onClick={() => window.open("/user/" + spot.creator)}
              >
                <GetUserInfo uid={spot?.creator} target="name" />
              </div>
              <div className=" text-xs text-gray-500">
                @<GetUserField userId={spot?.creator} target="prefecture" />
                -<GetUserStatus userId={spot?.creator} />
              </div>
            </div>
            <div className="bg-gray-200 w-auto p-2 rounded-md ">
              {spot.recomendedPoint}
            </div>
          </div>
        </div>

        {/* 詳細を見る */}
        <div
          className=" text-blue-500 text-xs hover:cursor-pointer hover:underline"
          onClick={() => window.open("/mapPoint/" + spot.id)}
        >
          詳細を見る/編集
        </div>
      </div>
    </>

    // <div className="my-2 rounded-md border shadow-lg p-2 items-center">
    //   <div className="grid md:grid-cols-3  lg:grid-cols-5 gap-2 items-center">
    //     {/* 許可・公開関連 */}
    //     <div className="flex items-center space-x-2 border px-2 rounded-md">
    //       <div className="w-fit">許可</div>
    //       <SwitchComponent
    //         id={spot?.id}
    //         target="isPermission"
    //         collection="recommendedSpot"
    //         text="許可"
    //       />
    //       <div className="w-fit">公開</div>
    //       <SwitchComponent
    //         id={spot?.id}
    //         target="isPublish"
    //         collection="recommendedSpot"
    //         text="公開"
    //       />
    //     </div>
    //     {/* お店の名前 */}
    //     <div className="flex">
    //       <div>名前：</div>
    //       <div>{spot.name}</div>
    //     </div>

    //     <div className="flex">
    //       {/* お店の種類 */}
    //       <div>種類：</div>
    //       <div>{changeType[spot.type].text}</div>
    //     </div>
    //     {/* 申請者 */}
    //     <div className="flex  items-center">
    //       <div>申請者：</div>
    //       <GetUserInfo uid={spot?.creator} target="avatar" size={30} />
    //       <GetUserInfo uid={spot?.creator} target="name" />
    //     </div>
    //     <div className="flex">
    //       <div>おすすめポイント：</div>
    //       <div>{spot.recomendedPoint}</div>
    //     </div>

    //     {/* 申請日 */}
    //     <div className="flex items-center">
    //       <div>申請日：</div>
    //       <p className=" text-xs text-gray-400">
    //         {new Date(spot?.createdAt?.toDate()).toLocaleString()}
    //       </p>
    //     </div>
    //     <div
    //       className=" text-blue-500 text-xs hover:cursor-pointer"
    //       onClick={handleNavigate}
    //     >
    //       詳細を見る
    //     </div>
    //   </div>
    // </div>
  );
};

export default RecommendedSpotInfo;
