import React, { useState,useEffect } from "react";
import { doc, updateDoc,getDoc,query,collection,getDocs,where } from "firebase/firestore";
import { db,auth } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "../context/auth";
import { useParams } from "react-router-dom";

import { addNotification } from "./addNotification";


const EditEventTitle = ({ setOpen, requestId }) => {
  const { id } = useParams()
  const {user} = useAuth()
  const [event] = useDocumentData(doc(db, "event",requestId ));
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    title: "",
    message: "",
    error: null,
    loading: false,
  });
  const { title, message, error, loading } = data;


  // 自分の情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const getUser = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && getUser();
  }, []);

  // 通知を受け取りたい人IDリスト
  const [notifiIdList, setNotifiIdLsit] = useState([]);
  useEffect(() => {
    const GetNotifiList = async () => {
      let arr = [];
      const q = query(
        collection(db, "event", id, "members"),
        where("status", "==", "getNotified")
      );
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push(doc.data().uid);
        });
        setNotifiIdLsit(arr);
      });
    };
    id && GetNotifiList();
  }, [id]);

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "event",requestId), {
        title,
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      // 通知を受け取りたい人IDリスト全員に通知
      if (notifiIdList.length) {
        notifiIdList.map((doc) => {
          addNotification(
            doc,
            userInfo?.avatar,
            userInfo?.uid,
            userInfo?.lastName + userInfo?.firstName,
            "event",
            id,
            "イベントタイトル変更"
          );
        });
      }

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
        console.log("err =>",err)
    }
  };
  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>

        <div className="mb-3">イベントタイトル変更</div>
        <TextField
          id="outlined-required"
          name="title"
          label="イベントタイトル"
          defaultValue=""
          size="small"
          placeholder={event?.title}
          className=" w-full "
          onChange={handleChange}
          required
          disabled={loading}
          // inputProps={{maxLength:18}}

        />

        <Button
          className="btn"
          name="title"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditEventTitle;
