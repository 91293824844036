import { Snackbar } from "@mui/material";
import React from "react";
import Slide from "@mui/material/Slide";

import { useSelector } from "react-redux";
import { useSnackbar } from "../redux/useSnackBar";
import ClearIcon from "@mui/icons-material/Clear";
import { SnackbarProvider } from "notistack";
export const SnackBar = () => {
  const snackbar = useSelector((state) => state.snackbar);
  const { clearSnackbar } = useSnackbar();

  const handleClose = (reason) => reason !== "clickaway" && clearSnackbar();

  function Transition(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.timeout}
        onClose={handleClose}
        // message={snackbar.message}
        // TransitionComponent={Transition}
        // action={<ClearIcon onClick={handleClose} />}
      >
        <div className=" border bg-white rounded-md shadow-md w-96 p-4">
          {snackbar.message}
        </div>

        {/* <Alert
        variant="filled"
        onClose={handleClose}
        severity={snackbar.type}
      >
      {snackbar.message} 
      </Alert> */}
      </Snackbar>
  );
};
