import React from "react";
import AddComponent from "./AddComponent";
import GroupTable from "./GroupTable";
import GroupTypeTable from "./GroupTypeTable";

const OperateGroup = () => {
  return (
    <>
      <div className="flex flex-col">
        <AddComponent value="グループタイプを登録" target="addGroupType" />
        <GroupTypeTable />
        <GroupTable />
      </div>
    </>
  );
};

export default OperateGroup;
