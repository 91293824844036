import React, { useState, useEffect } from "react";
import {
  doc,
  updateDoc,
  getDoc,
  query,
  collection,
  getDocs,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "../context/auth";
import { useParams } from "react-router-dom";

import { addNotification } from "./addNotification";
// import { request } from "http";

const EditUserName = ({ setOpen, requestId }) => {
  console.log("reqestId =>", requestId);
  const [user] = useDocumentData(doc(db, "users", requestId));
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    lastName: "",
    firstName: "",
    message: "",
    error: null,
    loading: false,
  });
  const { lastName, firstName, message, error, loading } = data;

  // 自分の情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const getUser = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    getUser();
  }, []);

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 変更処理
      await updateDoc(doc(db, "users", requestId), {
        firstName: firstName || user?.firstName,
        lastName: lastName || user?.lastName,
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">ユーザネーム変更</div>
        <TextField
          id="outlined-required"
          name="lastName"
          label="名字"
          defaultValue=""
          size="small"
          placeholder={user?.lastName}
          className=" w-full "
          onChange={handleChange}
          required
          disabled={loading}
          // inputProps={{maxLength:18}}
        />
        <div className="my-3"></div>
        <TextField
          id="outlined-required"
          name="firstName"
          label="名前"
          defaultValue=""
          size="small"
          placeholder={user?.firstName}
          className=" w-full "
          onChange={handleChange}
          required
          disabled={loading}
          // inputProps={{maxLength:18}}
        />

        <div className="my-3"></div>

        <Button
          className="btn"
          name="title"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditUserName;
