import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GoogleIcon from "@mui/icons-material/Google";

import {
  setDoc,
  doc,
  Timestamp,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../api/firebase";
import {
  GoogleAuthProvider,
  getRedirectResult,
  signInWithCredential,
  signInWithRedirect,
} from "firebase/auth";
import { StreamChat } from "stream-chat";

const DisplaySignUp = () => {
  //   popup関連
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  //   Googleサインアップ関連
  const provider = new GoogleAuthProvider();
  const clickLogin = async () => {
    // await signInWithRedirect(auth, provider);

    await signInWithRedirect(auth, provider);
  };


  useEffect(() => {
    const getRedirectGoogle = async () => {
      try {

        // localhostでログインできなくなった対策1
        // https://firebase.google.com/docs/auth/web/third-party-storage-mitigation?hl=ja
        // const googUser = auth.getAuthInstance().currentUser.get();
        // const credential = GoogleAuthProvider.credential(googUser.getAuthResponse().id_token);
        // const result = await signInWithCredential(auth,credential)
        // ==> chromeのバージョンが119だと端かれるっぽい
        // chromeのバージョン114だと正常に動くことを確認
        // ==> 20240502 根本的な解決
        // .env参照


        // console.log("auth =>",auth)
        const result = await getRedirectResult(auth);
        // console.log("auth =>", auth);
        // console.log("result =>", result);
        if (result) {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;

          const user = result.user;
          
          // const user = dummyUser.user;
          // 登録チェック
          const inUser = await getDoc(doc(db, "users", user.uid));
          if (inUser.exists()) {
            // 登録済処理
            //isloggedinを更新してhome画面へ
            console.log("登録済");

            await updateDoc(doc(db, "users", result.user.uid), {
              isOnline: true,
              isLogin: true,
              lastLogin: serverTimestamp(),
            });

            // ログイン情報追加
            await addDoc(
              collection(db, "users", result.user.uid, "loginHistory"),
              {
                uid: result.user.uid,
                loggedIn: serverTimestamp(),
                userAgent: navigator.userAgent,
              }
            );
            await addDoc(collection(db, "loginHistory"), {
              uid: result.user.uid,
              loggedIn: serverTimestamp(),
              userAgent: navigator.userAgent,
            });

            // ログイン回数カウントアップ
            let loginCount = await getDoc(
              doc(db, "users", result.user.uid)
            ).then((doc) => doc.data().loginCount);
            // loginCountがなかったり「NaN」の場合、1
            if (isNaN(loginCount) === true) {
              loginCount = 1;
            }
            await updateDoc(doc(db, "users", result.user.uid), {
              loginCount: loginCount + 1,
            });

            navigate("/home");
            console.log("登録済、isOnline更新、ホーム画面遷移");
          } else {
            // 初登録

            //名前、email,avatar,を登録してようこそ画面へ遷移

            // 名前など登録
            await setDoc(doc(db, "users", result.user.uid), {
              uid: result.user.uid,
              firstName: result._tokenResponse.firstName || "名前なし",
              lastName: result._tokenResponse.lastName || "名字なし",
              email: user.email,
              avatar: user.photoURL,
              loginCount: 1,
              avatarPath: "",
              createdAt: Timestamp.fromDate(new Date()),
              recieveMailFromSecretariat: true,
              isOnline: true,
              isLogin: true,
              isAdmin: false,
              isChief: false,
              joinBase: "",
              isVillager: false,
              isStop: false,
            });
            // await addDoc(collection(db, "runru"), {
            //   amount: 5000,
            //   cause: "初登録",
            //   createdAt: serverTimestamp(),
            //   from: "運営",
            //   to: result.user.uid,
            // });

            // chatのメンバーにも加える
            const userToConnect = {
              id: result.user.uid,
              name:
                result._tokenResponse.lastName ||
                "名無し名字" + result._tokenResponse.firstName ||
                "名無し名前",
              image: user.photoURL,
              langage: "jp",
            };
            const apiKey = process.env.REACT_APP_STREAM_API_KEY;
            const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
            // //   トークン取得
            const token = await fetch(
              process.env.REACT_APP_STREAM_GET_TOKEN_URL,
              // "https://us-central1-sekaimura-test.cloudfunctions.net/token",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  input: result.user.uid,
                }),
              }
            ).then((r) => r.json());
            await client.connectUser(userToConnect, token);

            navigate("/terms", {
              state: {
                firstName: result._tokenResponse.firstName,
                lastName: result._tokenResponse.lastName,
                email: user.email,
                uid: result.user.uid,
              },
            });
            console.log("初登録、firestore新規登録、ようこそ画面へ遷移");
          }
          // ユーザ情報登録
        }
      } catch (error) {
        const result = await getRedirectResult(auth);
        const errorData = {
          message: error.message,
          stack: error.stack,
        };
        addDoc(collection(db, "error"), {
          createdAt: serverTimestamp(),
          error: errorData,
          result: result,
        });
        console.log(error);
      }
    };
    getRedirectGoogle();
  }, []);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        sx={{ width: "110px" }}
        size="small"
      >
        サインアップ
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={clickLogin}>
          <GoogleIcon />
          Googleアカウントでサインアップ
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DisplaySignUp;
