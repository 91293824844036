import React, { useContext, useState, memo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HeaderLeftMenus from "./HeaderLeftMenus";
import HeaderRightMenus from "./HeaderRightMenus";
import HeaderDrawerMenuLists from "./HeaderDrawerMenuLists";
import HeaderDrawerMenu from "./HeaderDrawerMenu";
import { useLocation } from "react-router-dom";
import HeaderContainer from "./HeaderContainer";
import { useAuth } from "../context/auth";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function HeaderContainerMixIN({ outlet }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };
  // const location = useLocation();

  return (
    <Box sx={{ display: "flex" }}>
      {/* PC表示 */}
      <div className="hidden md:block">
        {/* ドロワー */}
        <Drawer variant="permanent" open={open}>
          {/* ドロワーヘッダー */}
          <DrawerHeader>
            {/* 開閉アイコン */}
            <IconButton className="" onClick={toggleOpen}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          {/* ドロワーメニュー */}
          <HeaderDrawerMenuLists open={open} setOpen={setOpen} />
        </Drawer>

        {/* ヘッダー */}
        <AppBar position="fixed" open={open} color="default">
          <Toolbar>
            {/* toggleボタン */}
            <div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleOpen}
                edge="start"
                sx={{
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            {/* Menu */}
            <HeaderLeftMenus open={open} />
            <HeaderRightMenus />
          </Toolbar>
        </AppBar>
      </div>

      {/* スマホ表示 */}
      <div className="md:hidden">
        <AppBar
          position="fixed"
          open={open}
          color="default"
          sx={{ height: "64px" }}
        >
          <HeaderContainer setOpen={setOpen} />
        </AppBar>
      </div>

      {/* メインコンテンツ */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 0, sm: 3 },
          background: "#faf9f7",
          height: "100%",
        }}
      >
        <DrawerHeader />
        {outlet}
      </Box>
    </Box>
  );
}
