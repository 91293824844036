import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../api/firebase";

const DisplayResidentUser = ({ user }) => {
  const { id } = useParams();

  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    // Firebaseのデータベースクエリを実行して拠点参加状態を確認
    const checkMembership = async () => {
      if (user && user.uid) {
        try {
          const q = query(
            collection(db, `base/${id}/members`),
            where("uid", "==", user.uid),
            where("isMember", "==", true)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            setIsMember(true);
          }
        } catch (error) {
          console.error("Firebaseクエリエラー:", error);
        }
      }
    };

    checkMembership();
  }, [id, user]);

  const navigate = useNavigate();

  const handleMoveUser = () => {
    navigate(`/user/${user?.uid}`);
  };

  return (
    <>
      <div
        className=" flex space-x-2 items-center border p-2 rounded-md shadow-lg hover:shadow-xl hover:cursor-pointer mb-2 group"
        onClick={handleMoveUser}
      >
        {/* 拠点に参加しているか */}
        {isMember ? (
          <div className=" text-red-500 font-bold">参加中</div>
        ) : (
          <div className=" text-blue-500 font-bold">未参加</div>
        )}
        {/* avatar */}
        <div>
          <Avatar src={user?.avatar} />
        </div>
        {/* 名前 */}
        <div className=" line-clamp-1 group-hover:underline ">{user?.lastName + user?.firstName}</div>
      </div>
    </>
  );
};

export default DisplayResidentUser;
