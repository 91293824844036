import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TextField, Button, Box, Typography } from "@mui/material";
import { auth, db } from "../api/firebase";
import { doc, setDoc, collection } from "firebase/firestore";

const DefinitionDirectionPurpose = () => {
  const [ddp, setDdp] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ddpId = uuidv4();
    const userId = auth.currentUser?.uid;
    if (userId) {
      const ddpDocRef = doc(db, "users", userId, "DDP", ddpId);
      try {
        await setDoc(ddpDocRef, { id: ddpId, content: ddp });
        alert("DDPが正常に登録されました。");
        setDdp(""); // フォームをリセット
      } catch (error) {
        console.error("DDPの登録に失敗しました:", error);
        alert("DDPの登録に失敗しました。");
      }
    } else {
      alert("ユーザーが認証されていません。");
    }
  };

  return (
    <>
      <div className="w-full h-screen bg-back">
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            "& .MuiTextField-root": { m: 1 },
            maxWidth: "500px",
            margin: "auto",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center", m: 2 }}>
            DDPの登録
          </Typography>
          <TextField
            fullWidth
            label="Definition Direction Purpose"
            variant="outlined"
            value={ddp}
            onChange={(e) => setDdp(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              登録
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default DefinitionDirectionPurpose;
