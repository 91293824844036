import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import defaultBackImg from "../data/img/defaultImage.png";
import LoaderBackImg from "./LoaderBackImg";
import Camera from "../data/svg/Camera";
import Loading from "./Loading";
import Blind from "./Blind";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "../context/auth";
import { memo } from "react";

// redux - use
import { useNotification } from "../redux/useNotification";

const EventBackImage = ({ id }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const { user } = useAuth();

  // console.log("event =>",event)
  const [eventDoc] = useDocumentData(doc(db, "event", id));

  //   背景画像コンテナ
  const [backImg, setBackImg] = useState("");
  //   セットコンテナ
  const [isLoading, setIsLoading] = useState("");
  //   背景画像Ref
  const backImgRef = useRef(null);

  // ユーザ情報
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initGetUser = async () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && initGetUser();
  }, []);

  // プロフィール画像変更処理
  useEffect(() => {
    if (backImg) {
      setIsLoading(true);
      const uploadBackImg = async () => {
        const backImgRef = ref(
          storage,
          `eventBackImage/${new Date().getTime()} - ${backImg.name}`
        );
        try {
          if (eventDoc?.backgroundPath) {
            await deleteObject(ref(storage, eventDoc?.backgroundPath));
          }
          const snap = await uploadBytes(backImgRef, backImg);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));
          await updateDoc(doc(db, "event", id), {
            background: url,
            backgroundPath: snap.ref.fullPath,
          });
          setBackImg("");
          setIsLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      };
      uploadBackImg();
    }
  }, [backImg]);

  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const initSetAuthor = async () => {
      if (eventDoc?.author === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    user && initSetAuthor();
  }, [eventDoc]);

  const checkPicture = (e) => {
    console.log("e.target.files[0].type =>", e.target.files[0].type);
    if (e.target.files[0].type !== "image/jpeg") {
      displayNotification({
        message: "画像ファイルを指定してください",
        type: "error",
      });

      return;
    }
    setBackImg(e.target.files[0]);
  };

  if (isLoading) {
    return (
      <>
        <Blind blind={true} />
        <Loading title="イベントイメージ" action="更新中" />;
      </>
    );
  }

  return (
    <>
      <div
        className={` ${
          (userInfo?.isAdmin || isAuthor) &&
          "hover:opacity-50 cursor-pointer group"
        }
         " relative flex justify-center items-center w-full "`}
      >
        {eventDoc?.background ? (
          <img
            src={eventDoc?.background || defaultBackImg}
            object-fit="cover"
            className="w-full h-[270px] lg:h-[540px] rounded-t-md"
            alt=""
            onClick={() => backImgRef.current.click()}
          />
        ) : defaultBackImg ? (
          <img
            src={defaultBackImg}
            alt="バックイメージ"
            className="w-full h-[270px] lg:h-[540px] rounded-t-md"
          />
        ) : (
          <LoaderBackImg />
        )}
        {isAuthor && (
          <div
            className="absolute top-2   sm:hidden group-hover:flex text-gray-200 sm:text-black opacity-80 hover:opacity-100 duration-300"
            onClick={() => backImgRef.current.click()}
          >
            <Camera className="" />
            <input
              ref={backImgRef}
              onChange={(e) => checkPicture(e)}
              // onChange={(e) => setBackImg(e.target.files[0])}
              type="file"
              hidden
            />
          </div>
        )}
      </div>
    </>
  );
};

export default memo(EventBackImage);
