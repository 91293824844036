import { collection, doc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db, auth } from "../api/firebase";
import getBalance from "./getBalance";
import getBalanceEx from "./getBalanceEx";

const AvailableRunru = ({ uid, from }) => {
  // 自分情報取得
  const [user] = useDocumentData(doc(db, "users", uid));

  // 自分のルンル取引取得
  const toRunru = query(collection(db, "runru"), where("to", "==", uid));
  const fromRunru = query(collection(db, "runru"), where("from", "==", uid));
  const [runru, setRunru] = useState([]);

  // 自分の取引取得
  const buyQuery = query(collection(db, "deal"), where("buy", "==", uid));
  const buyerQuery = query(collection(db, "deal"), where("buyer", "==", uid));
  const [deal, setDeal] = useState([]);

  const [balance, setBalance] = useState(0); // balanceの宣言

  useEffect(() => {
    let arr = [];
    const getRunru = async () => {
      await getDocs(toRunru).then((docs) => {
        docs.forEach((runru) => arr.push({ id: runru.id, ...runru.data() }));
      });
      await getDocs(fromRunru).then((docs) => {
        docs.forEach((runru) => arr.push({ id: runru.id, ...runru.data() }));
      });
      setRunru(arr);
    };
    if (uid) {
      getRunru();
    }
  }, [uid]);

  useEffect(() => {
    let arr = [];
    const getDeal = async () => {
      await getDocs(buyQuery).then((docs) => {
        docs.forEach((deal) => arr.push({ id: deal.id, ...deal.data() }));
      });
      await getDocs(buyerQuery).then((docs) => {
        docs.forEach((deal) => arr.push({ id: deal.id, ...deal.data() }));
      });
      setDeal(arr);
    };
    if (uid) {
      getDeal();
    }
  }, [uid]);



  useEffect(() => {
    const calculatedBalance = getBalanceEx(user, runru, deal);
    setBalance(calculatedBalance.balance);
    // setTransactions(calculatedBalance.detailedEntries);
  }, [user, runru, deal]);

  return (
    <>
      <div className="flex space-x-1">
        <div className="flex text-blue-500 text-2xl font-bold">{balance}</div>
        <div className="flex text-gray-300 text-sm items-center">runru</div>
      </div>
    </>
  );
};

export default AvailableRunru;
