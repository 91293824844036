import React, { useState, useEffect } from "react";
import { db } from "../api/firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  getDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useAuth } from "../context/auth";
import ModalForm from "./ModalForm";

const DisplayTerms = () => {
  const { user } = useAuth();

  // 許可した最新の利用規約を抜き出す
  // 許可した最新の利用規約のタイトルと、運営側の最新の利用規約のタイトルが一致いなければ
  // 最新の利用規約に許可してないということなので、 利用規約画面表示
  const [userInfo] = useCollectionData(
    query(collection(db, "users", user?.uid, "allowedTerm"),
    orderBy("createdAt", "desc"),
    limit(1)
  ));

  // 利用規約
  // 最新の利用規約取得
  // const [latestTerm, setLatestTerm] = useState();
  const termsQuery = query(
    collection(db, "terms"),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const [latestTermCollection] = useCollectionData(termsQuery);
  const latestTerm = latestTermCollection?.map((doc) => {
    return doc;
  });

  // 表示・非表示
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("latestTerm");

  useEffect(() => {
    const setDisplay = async () => {
      // console.log("latestTerm[0].title =>", latestTerm[0].title);
      // console.log("userInfo[0].title   =>", userInfo[0].title);

      if (latestTerm[0]?.title !== userInfo[0]?.title) {
        setForm("latestTerm");
        setOpen(true);
      }
      if (!userInfo) {
        setForm("latestTerm");
        setOpen(true);
      }
    };
    latestTerm && userInfo && setDisplay();
  }, [userInfo, latestTerm]);
  // console.log("latestTerm =>",latestTerm)
  return (
    <>
      {/* {latestTerm && <div>{latestTerm[0]?.title}</div>}
      {userInfo && <div>{userInfo[0]?.title}</div>} */}
      {/* <div>{userInfo[0]?.title}</div> */}
      {latestTerm?.length && (
        <ModalForm form={form} setOpen={setOpen} open={open} />
      )}
    </>
  );
};

export default DisplayTerms;
