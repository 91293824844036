import React, { useEffect, useState } from "react";
import { auth, db } from "../api/firebase";
import { getDoc, doc } from "firebase/firestore";
import  StreamChatPRO  from "../components/StreamChatPRO";
import { useLocation } from "react-router-dom";

const Imessage = () => {
  const [user, setUser] = useState("");

  const location = useLocation()

  useEffect(() => {
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
      setUser({ id: doc.id, ...doc.data() });
    });
  }, []);
  return (
    <>
      {user && <StreamChatPRO user={user} ids={location?.state}/>}
      {/* <StreamChatInMessages /> */}
      {/* <ChatArea /> */}
    </>
  );
};

export default Imessage;

// limitを使って一斉検索を制御ｓ
// https://firebase.google.com/docs/firestore/billing-example?hl=ja#see-chats
