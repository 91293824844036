import React, { useState } from "react";
import { doc, collection, query, where } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import MyProduct from "./MyProduct";

import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";

import AddComponent from "./AddComponent";

const MyProducts = () => {
  const [user] = useDocumentData(doc(db,"users",auth.currentUser.uid))

  const queryMyProducts = query(
    collection(db, "products"),
    where("uid", "==", auth.currentUser.uid)
  );
  const [realtimeMyProducts] = useCollection(queryMyProducts);
  const myProducts = realtimeMyProducts?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return (
    <>
        {/* DDP登録 ↓↓↓↓↓ */}
        <AddComponent value="DDPを登録" target="addProduct" />
        {/* 自分のDDP */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 lg:gap-5  my-1">
          {myProducts?.size === 0 && <div>登録されたDDPはまだありません</div>}
          {myProducts &&
            myProducts?.map((product, index) => {
              return (
                <MyProduct product={product} key={index} id={product.id} />
              );
            })}
        </div>
    </>
  );
};
export default MyProducts;
