const calculatedRunru = (user, runrus, deals, period) => {
  let now = new Date();
  let balance = 0;

  // 「何日前のデータか」を出力
  const DaysBetweenDate = (date) => {
    const then = date?.toDate();
    const msBetweenDates = Math.abs(then?.getTime() - now?.getTime());
    const hoursBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
    return hoursBetweenDates;
  };

  deals?.forEach((deal) => {
    if (deal.buy === user.uid && deal.status !== "canceled") {
      if (period) {
        if (DaysBetweenDate(deal.createdAt) < period) {
          balance += deal.amount;
        }
      } else {
        balance += deal.amount;
      }
    }
  });

  runrus?.forEach((deal) => {
    if (deal.buyer === user.uid && deal.status === "finished") {
      if (period) {
        if (DaysBetweenDate(deal.createdAt) < period) {
          balance += deal.amount;
        }
      } else {
        balance += deal.amount;
      }
    }
  });

  runrus?.forEach((runru) => {
    if (runru.to === user.uid) {
      if (period) {
        if (DaysBetweenDate(runru.createdAt) < period) {
          balance += runru.amount;
        }
      } else {
        balance += runru.amount;
      }
    }
  });

  runrus?.forEach((runru) => {
    if (runru.from === user.uid) {
      if (period) {
        if (DaysBetweenDate(runru.createdAt) < period) {
          balance += runru.amount;
        }
      } else {
        balance += runru.amount;
      }
    }
  });
  return balance;
};

export default calculatedRunru;
