import React from "react";
import { useEffect, useState } from "react";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";

import EditIcon from "@mui/icons-material/Edit";
import ModalForm from "./ModalForm";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import FirebaseNotification from "./FirebaseNotification";

const UserSetting = ({ id }) => {
  const [userDoc] = useDocumentData(doc(db, "users", id));

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  //   権限変更関連handle
  const [d_loding, setD_loading] = useState(false);
  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "users", id));
    await updateDoc(doc(db, "users", id), {
      [type]: !pub.data()[type],
    });
    setD_loading(false);
    // setDataLoading(true);
    // window.location.reload();
  };
  const handleEventChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  return (
    <>
      {/* <ModalForm form={form} setOpen={setOpen} open={open} target={group} /> */}

      <div className="w-full my-5 border bg-white p-4 shadow-sm rounded-md">
        <div className="font-bold text-xl">設定</div>
        <TabContext value={value}>
          {/* 左リスト */}
          {/* <Box sx={{ width: "20%" }}> */}
          <Box sx={{ width: "100%" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              // orientation="vertical"
            >
              <Tab label="通知関連" value="1" />
              <Tab label="通知許可" value="2" />
            </TabList>
          </Box>
          {/* 通知関連 */}
          <TabPanel sx={{ width: "100%" }} value="1">
            <div className="flex flex-col space-y-3">
              {/* 通知を受け取る */}
              <div className="flex space-x-2 justify-center items-center">
                <div className="flex flex-col">
                  <div className="flex-none inline-block align-middle   ">
                    事務局からのメール
                  </div>
                  <div className="text-xs text-gray-300">
                    緊急連絡等、[受け取らない]の場合もメールが届く場合があります
                  </div>
                </div>
                <div className="w-full p-2  rounded-md">
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="recieveMailFromSecretariat" // Secretariat 事務局
                    value={id}
                    onChange={handleEventChange}
                  >
                    <FormControlLabel
                      checked={userDoc?.recieveMailFromSecretariat === true}
                      value={id}
                      control={<Radio />}
                      label="受け取る"
                    />
                    <FormControlLabel
                      checked={userDoc?.recieveMailFromSecretariat === false}
                      value={id}
                      control={<Radio />}
                      label="受け取らない"
                    />
                  </RadioGroup>
                </div>

                {/* <div className="w-full p-2 border rounded-md">
                  {group?.doMakeEventsEveryOne}
                </div> */}
                <div
                  className=" rounded-full h-fit hover:shadow-lg"
                  // onClick={() => handleEdit("editGroupType")}
                >
                  {/* <EditIcon /> */}
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel sx={{ width: "100%" }} value="2">
          <FirebaseNotification />

          </TabPanel>

        </TabContext>
      </div>
    </>
  );
};

export default UserSetting;
