import React from "react";

const DealStatus = ({ status }) => {
  return (
    <>
      {status === "requesting" && (
        <div className="text-green-500 font-bold">交換希望リクエスト送信済</div>
      )}
      {status === "buyCanceled" && (
        <div className="text-red-500 font-bold">交換希望者キャンセル</div>
      )}
      {status === "buyerCanceled" && (
        <div className="text-red-500 font-bold">提供者キャンセル</div>
      )}

      {status === "accepted" && (
        <div className="text-green-500 font-bold">提供者承認済</div>
      )}
      {status === "shipping" && (
        <div className="text-blue-500 font-bold">配送中or配送準備中</div>
      )}
      {status === "arrived" && (
        <div className="text-blue-500 font-bold">商品到着</div>
      )}
      {status === "buyEvaluted" && (
        <div className="text-green-500 font-bold">交換希望者評価済</div>
      )}
      {status === "buyerEvaluted" && (
        <div className="text-green-500 font-bold">提供者評価済</div>
      )}


      {status === "finished" && (
        <div className="text-green-500 font-bold">取引終了</div>
      )}
    </>
  );
};

export default DealStatus;
