import React, { useState, useEffect, memo } from "react";
import { getDocs, collection } from "firebase/firestore";
import Rate from "./Rate";
import { db } from "../api/firebase";
import { useParams } from "react-router-dom";

const ProductRate = memo(({ productId }) => {
  const { id } = useParams();

  // レビュー取得 評価:Evalution
  const [evalutions, setEvalutions] = useState([]);
  useEffect(() => {
    let arr = [];

    if (!productId) {
      getDocs(collection(db, "products", id, "evalution")).then((docs) => {
        docs?.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setEvalutions(arr);
      });
    } else {
      getDocs(collection(db, "products", productId, "evalution")).then(
        (docs) => {
          docs?.forEach((doc) => {
            arr.push({ id: doc.id, ...doc.data() });
          });
          setEvalutions(arr);
        }
      );
    }
  }, []);
  // rate取得
  const [average, setAverage] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getRate = () => {
      const count = evalutions?.length;
      let total = 0;
      evalutions?.forEach((doc) => {
        total += doc?.rate;
      });
      setAverage(total / count);
      setLoading(false);
    };
    getRate();
  }, [evalutions]);

  if (evalutions?.length === 0) {
    return (
      <>
        <div className="text-gray-400 text-sm">評価はまだありません</div>
      </>
    );
  }

  return (
    <>
      <div className="flex">
        {average ? <Rate rate={average} /> : ""}
        {evalutions ? <div>（{evalutions?.length}）</div> : ""}
      </div>
    </>
  );
});

export default ProductRate;
