import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../api/firebase";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

const AutoComplete = ({ list }) => {

    const navigate = useNavigate()
  // offring取得
  const [offering, setOffering] = useState([]);
  const [offerLoading, setOfferLoading] = useState(true);
  useEffect(() => {
    const getOffers = async () => {
      const arr = [];
      const offers = await getDocs(collection(db, "ddp"));
      offers?.docs.map((doc) => {
        arr.push({ title: doc.data().offering });
      });
      setOffering(arr);
      setOfferLoading(false);
    };
    getOffers();
  }, [offerLoading]);


  const defaultProps = {
    options: offering,
    getOptionLabel: (option) => option.title,
  };

//   console.log(offering);

  const [value, setValue] = React.useState(null);

  const handleChange = (e) =>{

    navigate(`/ddp/${e.target.innerText}`)

    // console.log(e.target.innerText)
  }

  return (
    <Stack spacing={1} sx={{ width: 300 }}>
      <Autocomplete
        {...defaultProps}
        id="disable-clearable"
        disableClearable
        renderInput={(params) => (
          <TextField {...params} sx={{fontSize:16}} label="タグ検索" variant="standard" />
        )}
        onChange={(e) =>handleChange(e)}
      />
    </Stack>
  );
};

export default AutoComplete;
