import React, { useContext } from "react";
import { ChatContext } from "stream-chat-react";

import "./MessagingChannelPreview.css";
import { Avatar } from "@mui/material";
import Img from "../../data/img/defaultImage.png";

import GroupsIcon from "@mui/icons-material/Groups";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Badge from "@mui/material/Badge";
import ForestIcon from "@mui/icons-material/Forest";

const getAvatarGroup = (members) => {
  if (members.length === 1) {
    return (
      <Avatar
        key={Math.random()}
        src={members[0]?.user?.image || Img}
        name={members[0].user?.name}
      />
    );
  }

  if (members.length > 1) {
    return (
      <div className="flex">
        <span>
          <Avatar
            src={members[0]?.user?.image || Img}
            name={members[0].user?.name}
          />
        </span>
        <span>
          <Avatar
            src={members[1]?.user?.image || Img}
            name={members[1].user?.name}
          />
        </span>
      </div>
    );
  }

  return null;
};

const getTimeStamp = (channel) => {
  let lastHours = channel.state.last_message_at?.getHours();
  let lastMinutes = channel.state.last_message_at?.getMinutes();
  let half = "AM";

  if (lastHours === undefined || lastMinutes === undefined) {
    return "";
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12;
    half = "PM";
  }

  if (lastHours === 0) lastHours = 12;
  if (lastHours === 12) half = "PM";

  if (lastMinutes.toString().length === 1) {
    lastMinutes = `0${lastMinutes}`;
  }

  return `${lastHours}:${lastMinutes} ${half}`;
};

const getChannelName = (members) => {
  const defaultName = "セカイムラ";

  if (!members.length || members.length === 1) {
    return members[0]?.user.name || defaultName;
  }

  return `${members[0]?.user.name || defaultName}, ${
    members[1]?.user.name || defaultName
  }`;
};

const MessagingChannelPreview = (props) => {
  const { channel, latestMessage, setActiveChannel, setIsCreating } = props;

  const { channel: activeChannel, client } = useContext(ChatContext);

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== client.userID
  );
  const id = channel.data.id;

  return (
    <>
      <div
        // className={` ${
        //   channel?.state.unreadCount > 0 && " bg-blue-100"
        // }  " flex items-center justify-between mx-2 mb-1 hover:shadow-md p-2 rounded-md hover:cursor-pointer duration-200"`}
        className={
          channel?.id === activeChannel?.id
            ? "channel-preview__container selected"
            : "channel-preview__container"
        }
        onClick={() => {
          setIsCreating(false);
          setActiveChannel(channel);
        }}
      >
        <div className="mx-2">
          <Badge
            color="primary"
            variant="dot"
            invisible={!channel?.state.unreadCount}
          ></Badge>
        </div>

        {id.indexOf("groupChat-") !== -1 ? (
          <>
            <div className=" text-xs bg-blue-500 text-white rounded-full p-1">
              <GroupsIcon />
            </div>
          </>
        ) : id.indexOf("eventChat-") !== -1 ? (
          <>
            <div className=" text-xs bg-green-500 text-white rounded-full p-1">
              <EventNoteIcon />
            </div>
          </>
        ) : id.indexOf("baseChat-") !== -1 ? (
          <>
            <div className=" text-xs bg-pink-500 text-white rounded-full p-1">
              <ForestIcon />
            </div>
          </>
        ) : (
          getAvatarGroup(members)
        )}
        <div className="channel-preview__content-wrapper">
          <div className="channel-preview__content-top">
            <div className="channel-preview__content-name font-bold">
              {channel?.data.name || getChannelName(members)}
            </div>
            <div className="channel-preview__content-time">
              {getTimeStamp(channel)}
            </div>
          </div>
          <div className="channel-preview__content-message">
            {latestMessage || "メッセージ"}
          </div>
        </div>
      </div>
    </>
  );
};

export default MessagingChannelPreview;
