import React from "react";

const PendingEventAnswer = () => {
  return (
    <div className="font-bold text-2xl">
      回答待ちイベント
      {/* {pending?.length > 0 ? ":" + pending?.length : ""} */}
    </div>
  );
};

export default PendingEventAnswer;
