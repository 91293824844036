import React from "react";
import Badge from "@mui/material/Badge";

const DropdownItem = ({ title, Icon, onClick, complete, notification }) => {
  return (
    <div
      className="flex justify-between items-center w-full hover:cursor-pointer  my-1 py-2 rounded-md hover:bg-gray-100  p-2 group"
      onClick={onClick}
    >
      <div
        className={` ${
          complete ? "text-gray-800" : "text-gray-200"
        }   " flex "`}
      >
        <Badge
          color="primary"
          badgeContent={notification}
          max={999}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {Icon && (
            <Icon
              className={` ${
                complete ? "text-gray-800" : "text-gray-200"
              } " h-6 w-6  group-hover:text-blue-500 "`}
            />
          )}
        </Badge>
        <div className="ml-1 group-hover:text-blue-500 ">{title}</div>
      </div>
      {/* {notification && (
        <div className="bg-gray-200 text-blue-800 rounded-md p-2">
          {notification}
        </div>
      )} */}
    </div>
  );
};

export default DropdownItem;
