import React, { memo, useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import HotelIcon from "@mui/icons-material/Hotel";

const RadioGroupsComponent = ({
  title,
  array,
  collection,
  id,
  documentName,
}) => {
  const [product, setProduct] = useState("");
  useEffect(() => {
    getDoc(doc(db, collection, id)).then((elm) =>
      setProduct({ id: elm.id, ...elm.data() })
    );
  }, []);

  const handleUpdateValue = (e) => {
    setProduct({ ...product, [documentName]: e.target.value });

    // console.log("e ->",e.target.value)
    updateDoc(doc(db, collection, id), {
      [documentName]: e.target.value,
    });
  };
  return (
    <>
      <div className="w-fit">
        <FormControl>
          <div className="text-xl font-bold">{title}</div>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            onChange={(e) => handleUpdateValue(e)}
            className="ml-3"
          >
            {array?.map((elm) => (
              <>
                <FormControlLabel
                  value={elm.value}
                  control={<Radio />}
                  label={
                    <>
                      <div className="flex space-x-2">
                        {elm.icon}
                        <div>{elm.label}</div>
                      </div>
                    </>
                  }
                  checked={elm.value === product?.[documentName]}
                  className=" rounded-md border hover:border-blue-500 pr-5 my-1 shadow-md hover:shadow-2xl duration-150"
                />
              </>
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default memo(RadioGroupsComponent);

// arrayの形はこんな
// const shopIndust = [
//     { value: "restaurant", label: "レストラン",icon:"" },
//     { value: "restaurant", label: "レストラン",icon:"" },
//     { value: "restaurant", label: "レストラン",icon:"" },
//     { value: "restaurant", label: "レストラン",icon:"" },
//     { value: "restaurant", label: "レストラン",icon:"" },
//   ];
