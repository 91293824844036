
const getRunruHistory = (user, runrus, usersList, period) => {
  const arr = [];
  let now = new Date();

  // toデータ取得
  const pushTo = (arr, user, runru) => {
    arr.push({
      amount: Number(runru.amount),
      createdAt: runru.createdAt,
      cause: runru.cause,
      to: user.lastName + user.firstName,
      from:
        runru.from === "運営"
          ? runru.from
          : usersList[runru.from]?.lastName + usersList[runru.from]?.firstName,
    });
  };

  // fromデータ取得
  const pushFrom = (arr, user, runru) => {
    arr.push({
      amount: Number(runru.amount),
      createdAt: runru.createdAt,
      cause: runru.cause,
      to: usersList[runru.to]?.lastName + usersList[runru.to]?.firstName,
      from: user.lastName + user.firstName,
    });
  };

  // 「何日前のデータか」を出力
  const DaysBetweenDate = (date) => {
    const then = date?.toDate();
    const msBetweenDates = Math.abs(then?.getTime() - now?.getTime());
    const hoursBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
    return hoursBetweenDates;
  };

  runrus?.forEach((runru) => {
    if (runru.to === user.uid) {
      if (period) {
        if (DaysBetweenDate(runru.createdAt) < period) {
          pushTo(arr, user, runru);
        }
      } else {
        pushTo(arr, user, runru);
      }
    }
  });

  runrus?.forEach((runru) => {
    if (runru.from === user.uid) {
      if (period) {
        if (DaysBetweenDate(runru.createdAt) < period) {
          pushFrom(arr, user, runru);
        }
      } else {
        pushFrom(arr, user, runru);
      }
    }
  });

  // ソート
  arr.sort((a, b) => {
    if (a.createdAt < b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    return 0;
  });

  return arr;
};

export default getRunruHistory;
