import React, { useEffect, useState } from "react";
import {
  getDoc,
  doc,
  setDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";

// redux - use
import { useNotification } from "../redux/useNotification";
import { useParams } from "react-router-dom";

const JoinEvent = ({ setOpen }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const { id } = useParams();

  // console.log(id);
  // ユーザ取得関連
  const [event, setEvent] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // リクエスト取得
  useEffect(() => {
    const initEvent = () => {
      getDoc(doc(db, "event", id)).then((doSnap) => {
        if (doSnap.exists) {
          setEvent(doSnap.data());
        }
      });
    };
    id && initEvent();
  }, [id]);

  // const [owner, setOwner] = useState("");
  // useEffect(() => {
  //   const initSetOwner = () => {
  //     getDoc(doc(db, "users", group?.representative)).then((doc) => {
  //       setOwner({ id: doc.id, ...doc.data() });
  //     });
  //   };
  //   group && initSetOwner();
  // }, [group]);

  const [user, setUser] = useState("");
  useEffect(() => {
    const initSetUser = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        // console.log("doc =>", doc);
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    initSetUser();
  }, []);

  //   処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // eventのメンバーリストに追加　興味ある(getNotified)
      await setDoc(doc(db, "event", id, "members",auth.currentUser.uid), {
        isMember: false,
        isOwner: false,
        createdAt: serverTimestamp(),
        uid: auth.currentUser.uid,
        status: "getNotified",
      });
      // 本人の通知受け取りリストにも載せる
      await setDoc(
        doc(db, "users", auth.currentUser.uid, "getNotificationList",id),
        {
          createdAt: serverTimestamp(),
          type: "event",
          targetId: id,
          status: "getNotified",
        }
      );

      // 本人のnotificationに追加
      // await addDoc(collection(db, "users", auth.currentUser.uid, "notification"), {
      //   createdAt: serverTimestamp(),
      //   content: "グループ参加希望",
      //   fromAvatar: user?.avatar,
      //   fromId: user?.uid,
      //   fromName: user?.lastName + user?.firstName,
      //   isRecognition: false,
      //   target: "group",
      //   targetId: requestId,
      // });

      displayNotification({
        message: "イベント受け取り通知を設定しました。",
        type: "success",
      });
      setOpen(false);
      window.location.reload();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        イベント通知
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="font-bold text-2xl">イベントの通知を受け入れます</div>
        <div className="border-2 p-2 rounded-md mb-2">
          <div className="text-gray-300">イベント</div>
          <div>{event?.title}</div>
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "送信中" : "通知を受け入れる"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default JoinEvent;
