import React, { useEffect, useState } from "react";

// MaterialUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// streamChat
import { StreamChat } from "stream-chat";

// firebase
import {
  doc,
  addDoc,
  setDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

// date関連
import { Box, Divider, ListSubheader } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { format } from "date-fns";

// redux - use
import { useNotification } from "../redux/useNotification";

const AddEvent = ({ setOpen }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // 入力データコンテナ
  const [data, setData] = useState({
    title: "",
    explain: "",
    publishDate: new Date(),
    publish: true,
    price: 0,
    meetingPlace: "",
  });
  const {
    title,
    explain,
    publishDate,
    price,
    meetingPlace,
    publish,
    error,
    loading,
  } = data;

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // データ変更処理
  const handleChange = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const places = [
    { id: 1, place: "オンライン" },
    { id: 99, place: "divider" },
    { id: 2, place: "沖縄県" },
    { id: 3, place: "北海道" },
    { id: 4, place: "青森県" },
    { id: 5, place: "岩手県" },
    { id: 6, place: "宮城県" },
    { id: 7, place: "秋田県" },
    { id: 8, place: "山形県" },
    { id: 9, place: "福島県" },
    { id: 10, place: "茨城県" },
    { id: 11, place: "栃木県" },
    { id: 12, place: "群馬県" },
    { id: 13, place: "埼玉県" },
    { id: 14, place: "千葉県" },
    { id: 15, place: "東京都" },
    { id: 16, place: "神奈川県" },
    { id: 17, place: "新潟県" },
    { id: 18, place: "富山県" },
    { id: 19, place: "石川県" },
    { id: 20, place: "福井県" },
    { id: 21, place: "山梨県" },
    { id: 22, place: "長野県" },
    { id: 23, place: "岐阜県" },
    { id: 24, place: "静岡県" },
    { id: 25, place: "愛知県" },
    { id: 26, place: "三重県" },
    { id: 27, place: "滋賀県" },
    { id: 28, place: "京都府" },
    { id: 29, place: "大阪府" },
    { id: 30, place: "兵庫県" },
    { id: 31, place: "奈良県" },
    { id: 32, place: "和歌山県" },
    { id: 33, place: "鳥取県" },
    { id: 34, place: "島根県" },
    { id: 35, place: "岡山県" },
    { id: 36, place: "広島県" },
    { id: 37, place: "山口県" },
    { id: 38, place: "徳島県" },
    { id: 39, place: "香川県" },
    { id: 40, place: "愛媛県" },
    { id: 41, place: "高知県" },
    { id: 42, place: "福岡県" },
    { id: 43, place: "佐賀県" },
    { id: 44, place: "長崎県" },
    { id: 45, place: "熊本県" },
    { id: 46, place: "大分県" },
    { id: 47, place: "宮崎県" },
    { id: 48, place: "鹿児島県" },

    // { id: 3, place: "両方" },
  ];

  // 処理
  const createBase = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });
    const create = async () => {
      try {
        // eventコレクションに追加
        const docRef = await addDoc(collection(db, "event"), {
          title,
          explain,
          createdAt: serverTimestamp(),
          publish: true,
          // publishStart,
          // publishEnd,
          publishDate,
          target: "operation",
          targetId: "operation",
          publishTarget: "all",
          author: auth.currentUser.uid,
          price,
          meetingPlace,
        });
        // イベント参加メンバー追加
        await setDoc(
          doc(db, "event", docRef.id, "members", auth.currentUser.uid),
          {
            createdAt: serverTimestamp(),
            uid: auth.currentUser.uid,
            isOwner: true,
            joined: serverTimestamp(),
            status: "join",
          }
        );

        // チャット作成
        const userToConnect = {
          id: user.uid,
          name: user.lastName + user.firstName,
          image: user.avatar,
          langage: "jp",
        };
        const apiKey = process.env.REACT_APP_STREAM_API_KEY;
        const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
        //   トークン取得
        const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: user.uid,
          }),
        }).then((r) => r.json());
        await client.connectUser(userToConnect, token);
        const channel = client.channel("messaging", "eventChat-" + docRef.id, {
          name: title,
        });
        await channel.create();
        channel.addMembers([user.uid]);
        await channel.mute();

        // setData({
        //   ...data,
        //   error: null,
        // });
        // window.location.reload();
        setOpen(false);
        displayNotification({
          message: "イベント作成しました。",
        });
      } catch (err) {
        console.log(err.message);
        setData({
          ...data,
          error: err.message,
          loading: false,
        });
      }
    };
    create();
  };

  //   イベント日　関連
  const limitedDatePublishDate = new Date();
  const [publishDay, setPubilshDay] = useState(new Date());
  const publishDateChange = (e) => {
    if (!isNaN(e)) {
      setPubilshDay(e);
      const year = format(e, "yyyy");
      const month = format(e, "M");
      const day = format(e, "d");
      // console.log("yera =>",year)
      // console.log("month =>",month)
      // console.log("day =>",day)
      setData({
        ...data,
        publishDate: new Date(year, month - 1, day),
      });
      return false;
    }
  };
  return (
    <>
      <div className="mb-3">イベント作成</div>

      <form onSubmit={createBase}>
        {/* 名前 */}
        <TextField
          id="outlined-required"
          name="title"
          label="イベント名"
          defaultValue=""
          size="small"
          placeholder="〇〇を愛でるイベント"
          className=" w-full"
          onChange={handleChange}
          required
        />

        {/* イベント日 */}
        <div className="my-5"></div>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <Box sx={{ width: "100%" }}>
            <DatePicker
              label="イベント日"
              onChange={publishDateChange}
              inputFormat="yyyy年MM月dd日"
              mask="____年__月__日"
              value={publishDay}
              minDate={limitedDatePublishDate}
              //   maxDate={limitedDatePublishDate}
              leftArrowButtonText="前月"
              rightArrowButtonText="次月"
              toolbarTitle="日付選択"
              cancelText="キャンセル"
              okText="選択"
              size="small"
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>

        {/* 価格 */}
        <div className="my-4">
          <TextField
            id="outlined-required"
            name="price"
            type="number"
            value={price}
            label="参加費用(円)"
            defaultValue=""
            placeholder="0"
            size="small"
            inputProps={{
              min: 0,
              max: 999999,
            }}
            onChange={handleChange}
            required
            className="w-full my-4"
            disabled={loading}
            // disabled={loading || meetingPlace === "オンライン"}
          ></TextField>
        </div>

        {/* 集合場所 */}
        <div className="my-4"></div>
        <TextField
          id="outlined-required"
          name="meetingPlace"
          label="集合場所"
          defaultValue=""
          size="small"
          className=" w-full"
          onChange={handleChange}
          required
          disabled={loading}
          select
        >
          {places?.map((option) =>
            option.place === "divider" ? (
              <Divider>または</Divider>
            ) : (
              <MenuItem value={option.place} key={Math.random()} dense>
                {option.id} - {option.place}
              </MenuItem>
            )
          )}

          {/* {places?.map((options) => [
            <MenuItem key={Math.random()}>{options.place}</MenuItem>,
            options.isDivider && <Divider>または</Divider>,
          ])} */}

          {/* {places?.map((place) => (
            <MenuItem value={place.place} key={Math.random()}>
              {place.id} - {place.place}
            </MenuItem>
          ))} */}
        </TextField>
        {meetingPlace && (
          <div className="text-red-400 text-xs mt-2">
            イベント申請許可後、集合場所やURLなどをイベント専用ページから記載してください。
          </div>
        )}

        {/* 説明 */}
        <div className="my-3"></div>
        <textarea
          style={{ resize: "none", fontSize: "16px" }}
          type="text"
          name="explain"
          className="textarea textarea-bordered w-full h-24  "
          placeholder="イベントの説明です。後から変更もできます"
          defaultValue=""
          onChange={handleChange}
        ></textarea>
        {/* 送信ボタン */}
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
        >
          {loading ? "送信しています..." : "作成"}
        </button>
      </form>
    </>
  );
};

export default AddEvent;
