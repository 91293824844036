import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { signOut } from "firebase/auth";
// アイコン
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeIcon from "@mui/icons-material/Home";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import BuildIcon from "@mui/icons-material/Build";
import LogoutIcon from "@mui/icons-material/Logout";
import ContactMailIcon from "@mui/icons-material/ContactMail";
// MaterialUI
import { Avatar, Button, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DropdownItem from "./DropdownItem";
import LoaderHeaderPic from "./LoaderHeaderPic";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import ForestIcon from "@mui/icons-material/Forest";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import { memo } from "react";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import ApprovalPendingIcon from "./ApprovalPendingIcon";
import CreateHeaderMenu from "./CreateHeaderMenu";
import { useNotification } from "../redux/useNotification";
import { useSnackbar } from "../redux/useSnackBar";
import RegisterVillager from "./RegisterVillager";
import DisplaySignUp from "./DisplaySignUp";
import { useAuth } from "../context/auth";
import Loader1Row from "./Loader1Row";
import Notifications from "./Notifications";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import NotificationsInMessage from "./NotificationsInMessage";
import QuestionForAdminIcon from "./QuestionForAdminIcon";
import ModalForm from "./ModalForm";
import AvailableRunru from "./AvailableRunru";
import HeaderMenus from "./HeaderMenus";

const HeaderRightMenus = memo(() => {
  const { displayNotification } = useNotification();
  const { displaySnackbar } = useSnackbar();
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showRunru, setShowRunru] = useState(false);
  const [openRunruDialog, setOpenRunruDialog] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        setUserInfo(doc.data());
      });
    };
    user && getUser();
  }, [user]);

  useEffect(() => {
    setLoading(false);
  }, [userInfo?.isVillager]);

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");
  const handleEdit = (e, url) => {
    setOpen(true);
    setTarget(url);
    setForm(e);
  };

  const navigate = useNavigate();
  const handleToHome = () => {
    if (window.location.pathname !== "/home") {
      navigate("/home");
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };
  const handleToProfile = () => {
    navigate("/profile");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToAdminSetting = () => {
    navigate("/adminsetting");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToManuals = () => {
    navigate("/manuals");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToMap = () => {
    navigate("/map");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToChat = () => {
    navigate("/chat");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToMovie = () => {
    navigate("/movies");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToDdp = () => {
    navigate("/ddps");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToMyPage = () => {
    navigate(`/user/${auth.currentUser.uid}`);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToEvent = () => {
    navigate("/events");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToGroup = () => {
    navigate("/groups");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  const handleToBases = () => {
    navigate("/bases");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleToRelease = () => {
    navigate("/release");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const displayConstruction = () => {
    displayNotification({
      message: "只今、制作中です。",
    });
  };

  const handleToChatMessage = (e) => {
    navigate("/chat", { state: { otherId: "hUwrzcRjjIXCT8aoBS1vrhpbcMi2" } });
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleSignout = async () => {
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      isOnline: false,
      isLogin: false,
    });
    await signOut(auth);
    navigate("/", { replace: true });
  };

  const Menus = () => {
    return (
      <>
        <CreateHeaderMenu text="動画" icon={LiveTvIcon} complete />
        <CreateHeaderMenu text="DDP" icon={VolunteerActivismIcon} onclick={handleToDdp} complete />
        <CreateHeaderMenu text="マップ" icon={LocationOnIcon} onclick={handleToMap} complete />
        {userInfo?.isVillager && (
          <CreateHeaderMenu text="拠点" icon={ForestIcon} onclick={handleToBases} complete />
        )}
        {userInfo?.isVillager && (
          <CreateHeaderMenu text="グループ" icon={GroupsIcon} onclick={handleToGroup} complete />
        )}
        <CreateHeaderMenu text="イベント" icon={EventIcon} onclick={handleToEvent} complete />
        <CreateHeaderMenu text="リリース" icon={ViewTimelineIcon} onclick={handleToRelease} complete />
        {userInfo?.isVillager && window.location.href.indexOf("chat") === -1 && <NotificationsInMessage />}
        <Notifications />
      </>
    );
  };

  const MenusOfSmartPhone = () => {
    return (
      <div className="flex flex-col z-40 bg-white">
        <DropdownItem title="動画" Icon={LiveTvIcon} onClick={handleToMovie} complete />
        <DropdownItem title="DDP" Icon={VolunteerActivismIcon} onClick={handleToDdp} complete />
        <DropdownItem title="マップ" Icon={LocationOnIcon} onClick={handleToMap} complete />
        <DropdownItem title="拠点" Icon={ForestIcon} onClick={handleToBases} complete />
        <DropdownItem title="グループ" Icon={GroupsIcon} onClick={handleToGroup} complete />
        <DropdownItem title="マイページ" Icon={HomeIcon} onClick={handleToMyPage} complete />
        <DropdownItem title="イベント" Icon={EventIcon} onClick={handleToEvent} complete />
        <DropdownItem title="リリース" Icon={ViewTimelineIcon} onClick={handleToRelease} complete />
      </div>
    );
  };

  const UserStatusMark = ({ status }) => {
    if (status === null) {
      return (
        <div className="border border-red-500 bg-red-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
          <div className="text-xs text-red-700">Free</div>
        </div>
      );
    } else if (status === false) {
      return (
        <div className="border border-blue-500 bg-blue-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
          <div className="text-xs text-blue-700">user</div>
        </div>
      );
    } else if (status === true) {
      return (
        <div className="border border-green-500 bg-green-200 bg-opacity-75 rounded-xl px-2 py-1 w-fit">
          <div className="text-xs text-green-700">Members</div>
        </div>
      );
    } else {
      return null; // 未知のステータスの場合は何も表示しない
    }
  };

  const AvatarClickDropMenu = () => {
    return (
      <>
        {!userInfo?.isVillager && (
          <CreateHeaderMenu text="動画" icon={LiveTvIcon} onclick={handleToMovie} complete />
        )}
        {!userInfo?.isVillager && (
          <CreateHeaderMenu text="DDP" icon={VolunteerActivismIcon} onclick={handleToDdp} complete />
        )}
        {!userInfo?.isVillager && (
          <CreateHeaderMenu text="マップ" icon={LocationOnIcon} onclick={handleToMap} complete />
        )}

        <div className="dropdown dropdown-end bg-white rounded-full">
          <div
            tabIndex="0"
            className="flex h-full w-12 rounded-full sm:w-10 justify-center items-center"
          >
            {userInfo?.avatar && (
              <Avatar src={userInfo?.avatar || <LoaderHeaderPic />} />
            )}
          </div>

          <div
            tabIndex="0"
            className="dropdown-content menu p-2 border shadow-xl rounded-box w-52 bg-white"
            onClick={(e) => e.stopPropagation()} // Prevent closing dropdown on click
            onMouseDown={(e) => e.stopPropagation()} // Prevent closing dropdown on click
          >
            <div className="flex flex-col">
              <DropdownItem title="HOME" Icon={HomeIcon} onClick={handleToHome} complete />
              <DropdownItem title="プロフィール設定" Icon={PermContactCalendarIcon} onClick={handleToProfile} complete />
              <DropdownItem title="使い方マニュアル" Icon={MenuBookIcon} onClick={handleToManuals} complete />
              {userInfo?.isAdmin && (
                <DropdownItem title="管理者設定" Icon={BuildIcon} onClick={handleToAdminSetting} complete />
              )}
              {userInfo?.isAdmin && <ApprovalPendingIcon />}
              {userInfo?.isAdmin && <QuestionForAdminIcon />}
              <DropdownItem title="お問い合わせ" Icon={ContactMailIcon} onClick={() => handleEdit("inquiry")} complete />
              <DropdownItem title="ログアウト" Icon={LogoutIcon} onClick={handleSignout} complete />
              <Divider />
              <div className="mt-2">
                <div className="my-2 flex space-x-2 items-center">
                  {userInfo?.isVillager && <UserStatusMark status={userInfo?.isVillager} />}
                  {!userInfo?.isVillager && <UserStatusMark status={userInfo?.isVillager} />}
                  {!userInfo?.isVillager && <RegisterVillager />}
                </div>
                <div className="flex space-x-2 items-center border p-1 rounded-md mb-2">
                  <Avatar src={userInfo?.avatar} sizes="small" />
                  <div className="flex space-x-2 line-clamp-1">
                    <div>
                      {userInfo?.lastName}
                      {userInfo?.firstName}
                    </div>
                  </div>
                </div>
                <div
                  className="p-2 border rounded-md mt-2 cursor-pointer"
                  onClick={() => setOpenRunruDialog(true)}
                >
                  <div className=" text-xs text-gray-700">現在ルンル</div>
                  <div className="text-xs text-gray-500">クリックで表示</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog open={openRunruDialog} onClose={() => setOpenRunruDialog(false)}>
          <DialogTitle>現在ルンル</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <AvailableRunru uid={auth.currentUser.uid} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenRunruDialog(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  if (!user) return <DisplaySignUp />;
  if (loading) return <Loader1Row />;
  if (!userInfo) return <Loader1Row />;
  if (!userInfo?.isVillager) return <AvatarClickDropMenu />;

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} target={target} />
      <div className="flex items-center h-full ">
        <div className="flex justify-center items-center ">
          <div className="hidden md:flex space-x-2 mx-3 ">
            <HeaderMenus />
          </div>
          {window.location.href.indexOf("chat") === -1 && (
            <div className="md:hidden mx-2">
              <NotificationsInMessage />
            </div>
          )}
          <div className="md:hidden">
            <Notifications />
          </div>
          <div className="dropdown dropdown-end md:hidden mr-2 ">
            <div
              tabIndex="1"
              className="flex w-12 sm:w-10 justify-center items-center"
            >
              <div className=" border rounded-full p-1 ">
                <MoreVertRoundedIcon />
              </div>
            </div>
            <div
              tabIndex="1"
              className="dropdown-content bg-white menu p-2 border shadow-xl  rounded-box w-52"
            >
              <MenusOfSmartPhone />
            </div>
          </div>
          <AvatarClickDropMenu />
        </div>
      </div>
    </>
  );
});

export default memo(HeaderRightMenus);
