import React, { useEffect, useRef, useState } from "react";
import { useChannelStateContext, useChatContext } from "stream-chat-react";

import "./MessagingChannelHeader.css";

import { ChannelInfoIcon, ChannelSaveIcon } from "../../assets";

import Edit from "./Edit";

import { Avatar, AvatarGroup, Divider } from "@mui/material";
import Img from "../../data/img/defaultImage.png";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import GroupsIcon from "@mui/icons-material/Groups";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ForestIcon from "@mui/icons-material/Forest";

const getAvatarGroup = (members) => {
  if (members.length === 1) {
    return (
      <Avatar
        // image={getCleanImage(members[0])}
        key={Math.random()}
        src={members[0]?.user?.image}
        name={members[0].user?.name}
        size={40}
      />
    );
  }

  if (members.length > 1) {
    return (
      <>
        <AvatarGroup max={4} spacing="small">
          {members.map((member) => (
            <Avatar
              key={Math.random()}
              src={member?.user?.image || Img}
              name={member?.user?.name}
              shape="square"
              size={40}
            />
          ))}
        </AvatarGroup>
      </>
    );
  }

  return null;
};

// チャンネルのヘッダー
const MessagingChannelHeader = (props) => {
  const { setIsUpdateMember, isUpdateMember, direct } = props;
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();

  const [channelName, setChannelName] = useState(channel?.data.name || "");
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");

  const inputRef = useRef();

  const members = Object.values(channel.state?.members || {}).filter(
    (member) => member.user?.id !== client.user?.id
  );

  const id = channel.data.id;

  // チャンネル名変更
  const updateChannel = async (e) => {
    if (e) e.preventDefault();

    if (channelName && channelName !== channel.data.name) {
      await channel.update(
        { name: channelName },
        { text: `チャンネル名が「${channelName}」に変更されました` }
      );
    }

    setIsEditing(false);
  };

  // チャンネル名変更コンテナ
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  // チャンネル名がない場合は、ユーザ名かユーザID
  useEffect(() => {
    if (!channelName) {
      setTitle(
        members
          .map((member) => member.user?.name || member.user?.id || "名無しさん")
          .join(", ")
      );
    }
  }, [channelName, members]);

  const handleUpdateMember = async () => {
    // メンバーアップデート画面表示

    setIsUpdateMember(!isUpdateMember);
  };

  const handleCall = async () => {
    const response = await channel.createCall({
      type: "audio",
      id: channel?.cid,
    });
  };

  // ここにあると、再レンダリングが起こって日本語のタイトル入れられない
  // const EditHeader = () => (
  //   <form
  //     style={{ flex: 1 }}
  //     onSubmit={(e) => {
  //       e.preventDefault();
  //       inputRef.current.blur();
  //     }}
  //   >
  //     <input
  //       autoFocus
  //       className="channel-header__edit-input"
  //       onBlur={updateChannel}
  //       onChange={(e) => setChannelName(e.target.value)}
  //       placeholder="Type a new name for the chat"
  //       ref={inputRef}
  //       value={channelName}
  //     />
  //   </form>
  // );

  if (direct) {
    // console.log("members =>",members)
    return (
      <>
        <div className="flex space-x-1  items-center mx-1 my-1">
          {/* アバター */}
          <div>{getAvatarGroup(members)}</div>
          <div>{members[0]?.user.name}</div>
        </div>
        <Divider />
      </>
    );
  }

  if (id.indexOf("groupChat-") !== -1) {
    return (
      <>
        <div className="flex space-x-2 border-b p-5 items-center">
          <div
            id="mobile-nav-icon"
            className={`${props.theme} md:hidden text-blue-500`}
            onClick={() => props.toggleMobile()}
          >
            <ArrowCircleLeftOutlined />
            {/* <HamburgerIcon /> */}
          </div>

          {/* Icon */}
          <div className=" text-xs bg-blue-500 text-white rounded-full p-1">
            <GroupsIcon />
          </div>
          <div className="font-bold text-xl ">{channelName || title}</div>
        </div>
      </>
    );
  }

  if (id.indexOf("baseChat-") !== -1) {
    return (
      <>
        <div className="flex space-x-2 border-b p-5 items-center">
          <div
            id="mobile-nav-icon"
            className={`${props.theme} md:hidden text-blue-500`}
            onClick={() => props.toggleMobile()}
          >
            <ArrowCircleLeftOutlined />
            {/* <HamburgerIcon /> */}
          </div>

          {/* Icon */}
          <div className=" text-xs bg-pink-500 text-white rounded-full p-1">
            <ForestIcon />
          </div>
          <div className="font-bold text-xl ">{channelName || title}</div>
        </div>
      </>
    );
  }

  if (id.indexOf("eventChat-") !== -1) {
    return (
      <>
        <div className="flex space-x-2 border-b p-5 items-center">
          <div
            id="mobile-nav-icon"
            className={`${props.theme} md:hidden text-green-500`}
            onClick={() => props.toggleMobile()}
          >
            <ArrowCircleLeftOutlined />
            {/* <HamburgerIcon /> */}
          </div>

          {/* Icon */}
          <div className=" text-xs bg-green-500 text-white rounded-full p-1">
            <EventNoteIcon />
          </div>
          <div className="font-bold text-xl ">{channelName || title}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className=" w-full bg-white lg:border-b-2 flex  py-2 pl-2  items-center">
        {/* モバイル時に表示されるハンバーガーアイコン */}
        <div
          id="mobile-nav-icon"
          className={`${props.theme} md:hidden text-blue-500`}
          onClick={() => props.toggleMobile()}
        >
          <ArrowCircleLeftOutlined />
          {/* <HamburgerIcon /> */}
        </div>

        {/* インフォメーションボタン */}
        <div
          className="text-blue-500 mx-1"
          onClick={() => props.toggleMembers()}
        >
          <InfoOutlinedIcon />
        </div>

        {/* アバター　と　メンバーアップデートボタン */}
        <div className="flex space-x-1  items-center mx-1">
          {/* アバター */}
          <div>{getAvatarGroup(members)}</div>
          {/* メンバーアップデートアイコン */}
          <div className="text-blue-500" onClick={handleUpdateMember}>
            <ControlPointOutlinedIcon />
          </div>
        </div>

        {/* チャンネル名変更フラグ(isEditing)のときはエディター と　チャンネル名 */}
        <div className="flex space-x-1 mx-1">
          {/* チャンネル名変更アイコン */}
          <div className=" items-center hidden lg:block">
            {!isEditing ? (
              <ChannelInfoIcon {...{ isEditing, setIsEditing }} />
            ) : (
              <ChannelSaveIcon />
            )}
          </div>

          {/* チャンネル名 */}
          {!isEditing ? (
            <div className="font-bold text-xl  hidden lg:block">
              {channelName || title}
            </div>
          ) : (
            <div className=" w-full  hidden lg:block">
              <Edit
                inputRef={inputRef}
                updateChannel={updateChannel}
                setChannelName={setChannelName}
                channelName={channelName}
              />
            </div>
            // <EditHeader />
          )}
        </div>

        {/* 電話ボタン */}
        <div className="text-blue-500 ml-auto mr-2" onClick={handleCall}>
          {/* <CallIcon /> */}
        </div>
      </div>
      {/* モバイル表示時の　タイトル　と　タイトル変更アイコン */}
      <div className="flex lg:hidden border-b-2 space-x-1 ml-2 my-2">
        {/* チャンネル名変更フラグ(isEditing)のときはエディター と　チャンネル名 */}
        {/* チャンネル名変更アイコン */}
        <div className="">
          {channelName !== "Social Demo" &&
            (!isEditing ? (
              <ChannelInfoIcon {...{ isEditing, setIsEditing }} />
            ) : (
              <ChannelSaveIcon />
            ))}
        </div>
        {/* チャンネル名 */}
        {!isEditing ? (
          <div className="font-bold text-xl">{channelName || title}</div>
        ) : (
          <div className=" w-full">
            <Edit
              inputRef={inputRef}
              updateChannel={updateChannel}
              setChannelName={setChannelName}
              channelName={channelName}
            />
          </div>
          // <EditHeader />
        )}
      </div>
    </>
  );
};

export default React.memo(MessagingChannelHeader);
