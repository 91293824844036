import React, { useState } from "react";
import { Button, Divider } from "@mui/material";
import ModalForm from "./ModalForm";
import { useParams } from "react-router-dom";

const BuyerDealAction = ({ status }) => {
  const { id } = useParams();

  //   Modalのプロパティたち
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState(id);
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };
  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />
      {/* 受取リクエスト受諾待ち */}
      {status === "requesting" && (
        <div className="flex flex-col space-y-1">
          <Button
            variant="contained"
            onClick={(e) => handleEdit("dealAccepted", id)}
          >
            受諾する
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={(e) => handleEdit("dealCancelFromBuyer", id)}
          >
            この交換取引をキャンセルする
          </Button>
        </div>
      )}
      {/* 交換希望者キャンセル */}
      {status === "buyCanceled" && (
        <div className=" flex justify-center  w-full p-2 text-gray-500 rounded-md bg-gray-200">
          交換希望者キャンセル
        </div>
      )}
      {/* 提供者キャンセル */}
      {status === "buyerCanceled" && (
        <div className=" flex justify-center  w-full p-2 text-gray-500 rounded-md bg-gray-200">
          提供者キャンセル
        </div>
      )}
      {/* 受諾時 */}
      {status === "accepted" && (
        <div className="flex flex-col space-y-1">
          {/* 配送連絡 */}
          <Button
            variant="contained"
            onClick={(e) => handleEdit("dealShipped", id)}
          >
            配送連絡する
          </Button>
          {/* キャンセル処理 */}
          {/* <Button
            variant="outlined"
            color="error"
            onClick={(e) => handleEdit("dealCancelFromBuyer", id)}
          >
            この取引をキャンセルする
          </Button> */}
          {/* 提供準備完了 */}
          <Divider>または</Divider>
          {/* 提供準備完了 */}
          <Button
            variant="contained"
            onClick={(e) => handleEdit("dealReadyGo", id)}
          >
            提供準備完了を連絡する
          </Button>
        </div>
      )}

      {status === "shipping" && (
        <div className=" flex justify-center  w-full p-2 text-gray-500 rounded-md bg-gray-200">
          配送（提供準備）連絡済です。受取者の受け取り連絡をお待ち下さい。
        </div>
      )}
      {(status === "arrived" || status === "buyEvaluted") && (
        <Button
          variant="contained"
          onClick={(e) => handleEdit("dealEvalutionFromBuyer", id)}
        >
          交換希望者を評価する
        </Button>
      )}
      {(status === "finished" || status === "buyerEvaluted") && (
        <div className=" flex justify-center  w-full text-gray-500 rounded-md bg-gray-200">
          取引は終了しました
        </div>
      )}

      {/* 取引終了 */}
      {status === "adminCompulsionDealStop"  && (
        <div className=" flex justify-center  w-full text-gray-500 rounded-md bg-gray-200">
          交換は管理者により強制終了されました。ルンルは交換前の状態に戻ります。
        </div>
      )}


    </>
  );
};

export default BuyerDealAction;
