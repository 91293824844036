import React, { useEffect, useState } from "react";

import { XButtonBackground } from "../../assets";

import { useChannelStateContext } from "stream-chat-react";

import "./DisplayMembers.css";
import { Button, Drawer } from "@mui/material";
import { auth } from "../../api/firebase";
import { Avatar } from "@mui/material";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// redux - use
import { useNotification } from "../../redux/useNotification";

const UserResult = ({ user, channel }) => (
  <>
    {/* {console.log("user =>", user)} */}
    {/* {console.log(
      "channel.state.members[me].role=>",
      channel?.state.members[auth.currentUser.uid].role
    )} */}
    <li
      className="messaging-create-channel__user-result"
      onClick={() => console.log(user.channel_role)}
    >
      <Avatar
        src={user.user.image}
        name={user.user.name || user.user.id}
        size={40}
      />
      {user.user.online && (
        <div className="messaging-create-channel__user-result-online" />
      )}

      <div className="messaging-create-channel__user-result__details ">
        <span>{user.user.name}</span>
      </div>
    </li>
    <div>{user.role === "owner" && "管理者"}</div>
  </>
);

const DisplayMembers = ({ openMembers, toggleMembers }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const { channel } = useChannelStateContext();
  const [channelMember, setChannelMember] = useState([]);
  const [isMute, setIsMute] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // member取得
  useEffect(() => {
    const getChannelMembers = async () => {
      let sort = { created_at: -1 };
      let arr = [];
      const alreadyChannelMember = await channel.queryMembers({}, sort, {});
      alreadyChannelMember?.members?.forEach((member) => {
        arr.push(member);
      });
      setChannelMember(arr);
    };

    getChannelMembers();
  }, [channel]);

  // ミュート設定取得
  useEffect(() => {
    const init = async () => {
      await setIsMute(channel.muteStatus().muted);
    };
    init();
  }, []);

  // ミュート
  const handleMute = async () => {
    setIsLoading(!isLoading);
    isMute ? await channel.unmute() : await channel.mute();
    await setIsMute(channel.muteStatus().muted);
    window.location.reload();
  };

  // Remove
  const handleRemove = async () => {
    const state = await channel.watch();
    console.log("state?.members.length =>", state?.members.length);
    try {
      if (state?.members.length <= 2) {
        displayNotification({
          message: "1:1のチャンネルの脱退はできません。ミュートを推奨します。",
          type: "error",
        });
      } else {
        await channel.removeMembers([auth.currentUser.uid]);
        window.location.reload();
      }
    } catch (error) {
      displayNotification({
        message: "このチャンネルの、脱退権限がありません。",
        type: "error",
      });

      console.log("error =>", error);
    }
    // await channel.removeMembers([auth.currentUser.uid]);
    // channel.update({text:"hoge"})
    // await channel.stopWatching();
    // window.location.reload();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // muteモーダル
  const MuteComponent = () => {
    return (
      <>
        <Box sx={style}>
          <div className="rounded-md p-2 border text-center text-white bg-black ">
            チャンネルミュート
          </div>
          <div className="mt-2">
            このチャンネルのミュート設定を変更しますか？
          </div>
          <div className="flex mt-3">
            現在の設定：
            {isMute ? (
              <div className="text-red-500">オン</div>
            ) : (
              <div className="text-blue-500">オフ</div>
            )}
          </div>
          <div className="text-xs text-gray-500 mt-3">
            ミュート設定がオンになっていると、このチャンネルが更新されたときの通知が来なくなります
          </div>
          <div className="flex justify-around mt-2">
            <Button
              variant="outlined"
              onClick={handleMute}
              color={!isMute ? "error" : "primary"}
              disabled={isLoading}
            >
              {isMute ? "オフ" : "オン"}
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              disabled={isLoading}
            >
              キャンセル
            </Button>
          </div>
        </Box>
        ;
      </>
    );
  };

  const [openRemove, setOpenRemove] = React.useState(false);
  const handleOpenRemove = () => setOpenRemove(true);
  const handleCloseRemove = () => setOpenRemove(false);

  // removeモーダル
  const RemoveComponent = () => {
    return (
      <>
        <Box sx={style}>
          <div className="rounded-md p-2 border text-center text-white bg-black ">
            チャンネルを離れる
          </div>
          <div className="mt-2">このチャンネルを離れますか？</div>
          {/* <div className="flex mt-3">
            現在の設定：
            {isMute ? (
              <div className="text-red-500">オン</div>
            ) : (
              <div className="text-blue-500">オフ</div>
            )}
          </div> */}
          <div className="text-xs text-gray-500 mt-3">
            このチャンネルを離れると、残ったチャンネル内の誰かがメンバー追加してくれないと戻れません
          </div>
          <div className="flex justify-around mt-2">
            <Button
              variant="outlined"
              onClick={handleRemove}
              disabled={isLoading}
              color="error"
            >
              離れる
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseRemove}
              disabled={isLoading}
            >
              キャンセル
            </Button>
          </div>
        </Box>
        ;
      </>
    );
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const [targetUser, setTargetUser] = useState();
  const handleClickOpenDialog = ({ user }) => {
    setOpenDialog(true);
    setTargetUser(user);
  };
  const handleDiaogClose = () => {
    setOpenDialog(false);
  };

  const handleAddAdmin = async () => {
    await channel.assignRoles([
      { user_id: targetUser.id, channel_role: "owner" },
    ]);
  };

  const AddAdminRole = () => {
    return (
      <>
        <Box sx={style}>
          <div className="rounded-md p-2 border text-center text-white bg-black ">
            管理者権限の付与
          </div>
          <div className="mt-2">
            {targetUser?.name} さんに管理者権限を付与しますか？
          </div>
          <div className="text-xs text-gray-500 mt-3">
            管理者権限を付与された人は、メンバーの追加・削除、チャンネルの削除などができるようになります
          </div>
          <div className="flex justify-around mt-2">
            <Button
              variant="outlined"
              onClick={handleAddAdmin}
              disabled={isLoading}
              color="error"
            >
              はい
            </Button>
            <Button
              variant="outlined"
              onClick={handleDiaogClose}
              disabled={isLoading}
            >
              キャンセル
            </Button>
          </div>
        </Box>
        ;
      </>
    );
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <MuteComponent />
      </Modal>
      <Modal open={openRemove} onClose={handleCloseRemove}>
        <RemoveComponent />
      </Modal>
      <Modal open={openDialog} onClose={handleDiaogClose}>
        <AddAdminRole />
      </Modal>

      <Drawer anchor="left" open={openMembers} onClose={toggleMembers}>
        <div className="w-96 mt-20 p-2">
          {/* 閉じるボタン */}
          <div className="flex my-2 justify-end" onClick={toggleMembers}>
            <XButtonBackground />
          </div>

          <div className=" font-bold text-3xl">管理</div>

          <div className="flex flex-col space-y-2 my-2">
            {/* チャンネルを離れる */}
            <Button onClick={handleOpenRemove} variant="outlined" color="error">
              チャンネルを離れる
            </Button>

            {/* ミュート */}
            <Button onClick={() => handleOpen()} variant="outlined">
              {isMute ? "ミュートをやめる" : "このチャンネルをミュート"}
            </Button>
          </div>

          {/* メンバー一覧 */}
          <div className="font-bold text-3xl">Member</div>
          <div className="flex space-x-2">
            <div className="text-xs">メンバー：{channel.data.member_count}</div>
            <div className="text-xs">
              オンライン：{channel.state.watcher_count}
            </div>
          </div>
          {!!channelMember?.length && (
            <div>
              {channelMember.map((user, i) => (
                <>
                  <div className="messaging-create-channel__user-result">
                    <UserResult user={user} channel={channel} />
                    {channel?.state.members[auth.currentUser.uid].role ===
                      "owner" && (
                      <div onClick={() => handleClickOpenDialog(user)}>
                        管理者にする
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default React.memo(DisplayMembers);
