import React, { useEffect, useState } from "react";
import {
  getDoc,
  doc,
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import RatingComponent from "./RatingComponent";
// redux - use
import { useNotification } from "../redux/useNotification";

  // 買った人が、取引を評価する
  // 買われた人が「買った人があの取引をこう評価したよ」という通知を受け取る
  // 買った人　自分 買われた人deal?.buyer

const DealEvalution = ({ setOpen, requestId }) => {
    // redux - Notification
    const { displayNotification } = useNotification();

     // 取引を取得
  const [deal, setDeal] = useState();
  useEffect(() => {
    const initGetDeal = async () => {
      getDoc(doc(db, "deal", requestId)).then((doc) => {
        setDeal({ id: doc.id, ...doc.data() });
      });
    };
    requestId && initGetDeal();
  }, []);

  // 買われた人を取得 
  const [buyer, setBuyer] = useState();
  useEffect(() => {
    const initGetBuyUser = async () => {
      getDoc(doc(db, "users", deal?.buyer)).then((doc) => {
        setBuyer({id:doc.id,...doc.data()});
      });
    };
    deal && initGetBuyUser();
  }, [deal]);


  // 買った人を取得 - 自分
  const [buy] = useDocumentData(doc(db, "users", auth.currentUser.uid));
 

  const setRequestDataShop = () => {};
  const [requestShopLoading, setRequestShopLoading] = useState(false);
  const [error, setError] = useState("");

  const [product, setProduct] = useState("");
  const [data, setData] = useState({
    evalute: "スムーズで気持ちの良い取引、ありがとうございました。",
    rate: 5,
  });
  const { evalute, rate } = data;
  // 値変更
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };



  const handleDelete = async (e) => {
    e.preventDefault();
    setRequestShopLoading(true);
    try {
      if (deal?.status === "buyerEvaluted") {
        // 取引情報アップデート
        await updateDoc(doc(db, "deal", requestId), {
          status: "finished",
          lastUpdated: buy?.uid,
          lastUpdatedAt:serverTimestamp(),

        });
      } else {
        // 取引情報アップデート
        await updateDoc(doc(db, "deal", requestId), {
          status: "buyEvaluted",
          lastUpdated: buy?.uid,
          lastUpdatedAt:serverTimestamp(),

        });
      }
      // DDP情報の評価追加
      await addDoc(collection(db, `products/${product?.id}/evalution`), {
        rate: Number(rate),
        evalute,
        createdAt: serverTimestamp(),
        valuer: deal?.buy,
        evaluted: deal?.buyer,
        display: true,
      });

      // 通知情報登録 - 買った人が取引を評価・・・なので
      // 買われた人に、「買った人がこの取引最高だったと言ってるよ」という通知をする
      // 買われた人 buyer 買った人 deal.buy=> auth.currentUser.uid
      // fromはbuy
      await addDoc(collection(db, "users", deal?.buyer, "notification"), {
        createdAt: serverTimestamp(),
        content: "DDP取引評価",
        fromAvatar: buy?.avatar,
        fromId: buy?.uid,
        fromName: buy?.lastName + buy?.firstName,
        isRecognition: false,
        target: "deal",
        targetId:requestId,
      });


      setOpen(false);
      displayNotification({
        message: "取引を評価しました。",
        type:"success"
      });
  
    } catch (err) {
      console.log(err);
      setRequestShopLoading(false);
      setError(err);
    }
  };

  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        取引を評価する
      </div>
      <form onSubmit={setRequestDataShop}>
        {/* レート */}
        <RatingComponent rate={rate} handleChange={handleChange} />
        <textarea
          style={{ resize: "none", fontSize: "16px" }}
          type="text"
          name="evalute"
          className="textarea textarea-bordered w-full h-24  "
          placeholder={evalute}
          defaultValue=""
          onChange={handleChange}
        ></textarea>
        <div className="font-bold text-2xl">
          以上の内容でDDPを評価します、よろしいですか？
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={requestShopLoading}
          onClick={handleDelete}
        >
          {requestShopLoading ? "送信しています..." : "はい"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DealEvalution;
