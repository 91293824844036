import React, { useState } from "react";
import TextField from "@mui/material/TextField";

import {
  addDoc,
  collection,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../api/firebase";

import { useCollection } from "react-firebase-hooks/firestore";

const RunruAddForAll = ({ setOpen, requestId }) => {
  // ユーザ取得関連
  const usersRef = collection(db, "users");
  const [usersCollection] = useCollection(
    query(usersRef)
  );
  const users = usersCollection?.docs.map((user) => ({
    id: user.data().uid,
    ...user.data(),
  }));

  const [product, setProduct] = useState({
    amount: "",
    error: "",
    loading: false,
  });
  const { amount, error, loading } = product;

  // 値変更
  const handleChangeShop = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    const addRunruForVillager = async (user) => {
      try {
        // 変更処理
        const docRef = await addDoc(collection(db, "runru"), {
          to: user.uid,
          from: "運営",
          amount: Number(amount),
          cause: "ユーザ全体付与",
          createdAt: serverTimestamp(),
        });
        // window.location.reload();
        setOpen(false);
      } catch (err) {
        setProduct({
          ...product,
          error: err.message,
          loading: false,
        });
      }
    };
    users?.forEach((user) => {
      addRunruForVillager(user);
    });
  };

  //   console.log(product);
  return (
    <>
      <div>
        <div className="mb-3">ルンルユーザ全体付与</div>
        <form onSubmit={setRequestDataShop}>
          <div>ユーザ全員に配布します</div>
          <div>ユーザ人数：　{users?.length}人</div>

          {/* 価格（ルンル） */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="amount"
              type="number"
              label="価格(ルンル)"
              defaultValue=""
              placeholder="100"
              size="small"
              onChange={handleChangeShop}
              required
              className="w-full my-4"
              disabled={loading}
            ></TextField>
          </div>

          {/* 登録ボタン */}
          <div className="my-4" />
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            {loading ? "送信しています..." : "登録"}
          </button>
        </form>
        {error ? error : ""}
      </div>
    </>
  );
};

export default RunruAddForAll;
