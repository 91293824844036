import React, { useEffect, useState } from "react";

// MaterialUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// firebase
import {
  doc,
  addDoc,
  setDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

// date関連
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
// redux - use
import { useNotification } from "../redux/useNotification";

// streamChat
import { StreamChat } from "stream-chat";

// scheduleIcon
import InfoIcon from "@mui/icons-material/Info";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import DeleteIcon from "@mui/icons-material/Delete";
import HotelIcon from "@mui/icons-material/Hotel";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import CommuteIcon from "@mui/icons-material/Commute";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import DoneIcon from "@mui/icons-material/Done";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ComputerIcon from "@mui/icons-material/Computer";

const AddScheduleForEvent = ({ setOpen }) => {
  const { id } = useParams();
  const { displayNotification } = useNotification();


  const [scheduleCollectioin] = useCollectionData(
    collection(db, "event", id, "schedule")
  );

  // 入力データコンテナ
  const [data, setData] = useState({
    title: "",
    explain: "",
    icon: "",
    time: "",
  });
  const { title, explain, icon, error, loading } = data;


  // console.log("value =>", new Date(value));
  // データ変更処理
  const handleChange = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const scheduleIcon = {
    インフォメーション: { icon: InfoIcon },
    食事: { icon: RestaurantIcon },
    アクション: { icon: AccessibilityNewIcon },
    片付け: { icon: DeleteIcon },
    就寝: { icon: HotelIcon },
    移動: { icon: CommuteIcon },
    挨拶集合: { icon: EmojiPeopleIcon },
    演奏: { icon: AudiotrackIcon },
    発表: { icon: CoPresentIcon },
    解散: { icon: DoneIcon },
    動画: { icon: YouTubeIcon },
    パソコン: { icon: ComputerIcon },
    瞑想: { icon: SelfImprovementIcon },
  };

  const createScheduleIcon = () => {
    const Icon = ({ Icon }) => {
      return <>{Icon && <Icon className="h-4 w-4" />}</>;
    };
    const Label = ({ value }) => {
      return (
        <>
          <div className="flex items-center space-x-2 rounded-md">
            <Icon Icon={scheduleIcon[value].icon} />
            <span>{value}</span>
          </div>
        </>
      );
    };
    const createIconWithLabel = (value, type) => {
      return {
        label: (
          <>
            <Label value={value} />
          </>
        ),
        name: value,
        value: value,
      };
    };

    const elements = [
      createIconWithLabel("食事"),
      createIconWithLabel("アクション"),
      createIconWithLabel("片付け"),
      createIconWithLabel("就寝"),
      createIconWithLabel("移動"),
      createIconWithLabel("挨拶集合"),
      createIconWithLabel("演奏"),
      createIconWithLabel("発表"),
      createIconWithLabel("解散"),
      createIconWithLabel("動画"),
      createIconWithLabel("パソコン"),
      createIconWithLabel("瞑想"),
    ];
    return elements;
  };

  // 処理
  const createBase = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });

    const create = async () => {
      try {
        // eventコレクションに追加
        const docRef = await addDoc(collection(db, "event", id, "schedule"), {
          title,
          explain,
          icon,
          createdAt: serverTimestamp(),
          order: scheduleCollectioin.length+1,
          index: scheduleCollectioin.length+1,
          color:"primary"
        });

        displayNotification({
          message: "イベントを作成しました。",
          type: "success",
        });

        setData({
          ...data,
          error: null,
        });
        // window.location.reload();

        setOpen(false);
      } catch (err) {
        console.log(err.message);
        setData({
          ...data,
          error: err.message,
          loading: false,
        });
      }
    };
    create();
  };

  // console.log("data =>", data);
  return (
    <>
      <div className="mb-3">スケジュール追加</div>
      <form onSubmit={createBase}>
        {/* アイコン */}
        <div className="my-4"></div>
        <TextField
          id="outlined-required"
          name="icon"
          label="アイコン"
          value={icon}
          defaultValue=""
          size="small"
          className=" w-full"
          onChange={handleChange}
          required
          disabled={loading}
          select
        >
          {createScheduleIcon().map((elm) => (
            <MenuItem value={elm.value} key={Math.random()} dense>
              {elm.label}
            </MenuItem>
          ))}
        </TextField>

        {/* スケジュール名 */}
        <div className="my-4"></div>
        <TextField
          id="outlined-required"
          name="title"
          label="スケジュール名"
          defaultValue=""
          size="small"
          placeholder="例）昼食"
          className=" w-full"
          onChange={handleChange}
          required
          // inputProps={{maxLength:20}}
        />

        {/* 日時 */}
        {/* <div className="my-5"></div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              label="イベント日"
              value={value}
              disablePast
              onChange={handleChangeDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider> */}

        {/* 説明 */}
        <div className="my-3"></div>
        <textarea
          style={{ resize: "none", fontSize: "16px" }}
          type="text"
          name="explain"
          className="textarea textarea-bordered w-full h-24  "
          placeholder="イベントの説明です。後から変更もできます"
          defaultValue=""
          onChange={handleChange}
        ></textarea>

        {/* 送信ボタン */}
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
        >
          {loading ? "送信しています..." : "作成"}
        </button>
      </form>
    </>
  );
};

export default AddScheduleForEvent;
