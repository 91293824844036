import React, { useState, memo } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import DisplayVideo from "./DisplayVideo";
import DisplayEventFromGroupForAll from "./DisplayEventFromGroupForAll";

// import "../hideScrollBar.css";

function HorizontalEventMenuOfHomeTop({ contents }) {
  const [items, setItems] = useState();
  const [selected, setSelected] = useState([]);
  const [position, setPosition] = useState(0);

  //   console.log("items =>", items);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  // console.log("contents =>",contents)

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  return (
    <div className="grid grid-cols-1 ">
      <div className=" col-span-1 ">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {/* <Card
          itemId={movie?.id} // NOTE: itemId is required for track items
          title={movie?.id}
          key={movie?.id}
          onClick={handleClick(movie?.id)}
          selected={isItemSelected(movie?.id)}
          content={movie}
        /> */}

          {contents?.map((doc, idx) => (
            <div className="w-72 p-2" key={doc.id}>
              <DisplayEventFromGroupForAll event={doc} key={doc.id} />
              {/* <DisplayVideo movie={movie} /> */}
            </div>
          ))}
        </ScrollMenu>
      </div>
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <>
      <div
        className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
        onClick={() => scrollPrev()}
      >
        <div className="hidden sm:block ">
          <div>
            <KeyboardDoubleArrowLeftOutlinedIcon
              sx={{ fontSize: "25px", marginX: "5px" }}
            />
          </div>
        </div>
      </div>
    </>
    // <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
    //   Left
    // </Arrow>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <>
      <div
        className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
        onClick={() => scrollNext()}
      >
        <div className="hidden sm:block ">
          <div>
            <KeyboardDoubleArrowRightOutlinedIcon
              sx={{ fontSize: "25px", marginX: "5px" }}
            />
          </div>
        </div>
      </div>
    </>
    // <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
    //   Right
    // </Arrow>
  );
}

export default memo(HorizontalEventMenuOfHomeTop);
