import React, { useState, memo } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import "../hideScrollBar.css";
import StoreIcon from "@mui/icons-material/Store";
import MapTypeIcon from "./MapTypeIcon";
import FlareIcon from "@mui/icons-material/Flare";
import ForestIcon from "@mui/icons-material/Forest";
import { ReactComponent as LendFieldIcon } from "../data/svg/LendFieldKUWA.svg";
import { ReactComponent as LendOldHouseIcon } from "../data/svg/LendOldHouse.svg";
import { ReactComponent as PowerSpotIcon } from "../data/svg/PowerSpot.svg";
import { ReactComponent as VagetableIcon } from "../data/svg/Vagetable.svg";
import { ReactComponent as AlternativeMedicineIcon } from "../data/svg/AlternativeMedicine.svg";

function HorizontalMenuAtAddPostmedia({ photos, handleDeletePhoto }) {
  return (
    <div className="grid grid-cols-1 w-full ">
      <div className=" col-span-1  rounded-sm py-4">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {photos?.map((photo, index) => (
            <div key={index} className="flex relative w-20 h-20 mx-2">
              <img src={photo.dataURL} alt="写真" className="w-full h-full" />
              <button className="absolute right-1 top-0 z-10 text-white"  onClick={() => handleDeletePhoto(index)}>×</button>
            </div>
          ))}
        </ScrollMenu>
      </div>
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <>
      <div
        className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
        onClick={() => scrollPrev()}
      >
        <div className="hidden sm:block ">
          <div className=" text-gray-400">
            <KeyboardDoubleArrowLeftOutlinedIcon
              sx={{ fontSize: "25px", marginX: "5px" }}
            />
          </div>
        </div>
      </div>
    </>
    // <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
    //   Left
    // </Arrow>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <>
      <div
        className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
        onClick={() => scrollNext()}
      >
        <div className="hidden sm:block ">
          <div className="text-gray-400">
            <KeyboardDoubleArrowRightOutlinedIcon
              sx={{ fontSize: "25px", marginX: "5px" }}
            />
          </div>
        </div>
      </div>
    </>
    // <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
    //   Right
    // </Arrow>
  );
}

export default memo(HorizontalMenuAtAddPostmedia);
