import React from "react";

import "react-responsive-carousel/lib/styles/carousel.css";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../api/firebase";
import { useEffect } from "react";
import { useState } from "react";

import BackImage from "../data/img/defaultImage.png";
import GetCountInGroupMembers from "./GetCountInGroupMembers";
import { memo } from "react";

const DisplayGroup = memo(({ groupId, groups }) => {
  const [group, setGroup] = useState("");

  useEffect(() => {
    const q = doc(db, `group/${groupId}/`);
    getDoc(q).then((doc) => {
      setGroup({ id: doc.id, ...doc.data() });
    });
  }, []);

  const handleMove = () => {
    navigate(`/group/${groupId}`);
  };

  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex flex-col relative h-80 justify-between rounded-lg border shadow-lg hover:cursor-pointer bg-gray-50 hover:shadow-2xl hover:border-blue-500 duration-300"
        onClick={() => navigate(`/group/${groupId}`)}
      >
        {/* backImage */}
        <div className="h-1/2">
          <img
            className="h-full w-full rounded-t-lg"
            src={group?.background || BackImage}
            alt=""
          />
        </div>
        {/* mainImage */}
        <div className="absolute border-white border-4 rounded-md bg-white z-20 w-1/3 h-1/3 left-0 right-0 m-auto items-center justify-center top-0 bottom-0 ">
          <img
            className="h-full w-full rounded-t-lg"
            src={group?.mainImage || BackImage}
            alt=""
          />
        </div>
        {/* タイトルなど */}
        <div className="h-1/3 p-2">
          {/* タイトル */}
          <div className="flex  items-center justify-center">
            <div className="font-bold truncate hover:text-green-500">
              {group?.title}
            </div>
          </div>
          {/* タイプ */}
          <div className="flex text-gray-400 text-xs items-center justify-center  border rounded-md p-1 w-fit my-1">
            {group?.type}
          </div>
          <GetCountInGroupMembers id={groupId} />
        </div>
      </div>
    </>
  );
});

export default DisplayGroup;
