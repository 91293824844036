import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/auth";

// DB関連
import {
  doc,
  query,
  collection,
  getDoc,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";

// 表示関連
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

// カルーセル
import "react-responsive-carousel/lib/styles/carousel.css";
import { Avatar, Button, Divider } from "@mui/material";
import ModalForm from "./ModalForm";

// アイコン
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DisplaySignUp from "./DisplaySignUp";
import { useNavigate } from "react-router-dom";
import CarouselProductImages from "./CarouselProductImages";
import SwitchComponent from "./SwitchComponent";
import ProductQuestion from "./ProductQuestion";
import ProductQAndA from "./ProductQAndA";

import ProductRate from "./ProductRate";
import ProductCusutomerEvalutions from "./ProductCusutomerEvalutions";
import ProductWriteReview from "./ProductWriteReview";
import ProductCustomerReviews from "./ProductCustomerReviews";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";

import SNSList from "./SNSList";
import RenderUserItem from "./RenderUserItem";

const DisplayProductPC = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  // ModalForm
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const handleModalOpen = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  // プロダクト情報

  // const queryProduct = query(doc(db, "products", id));
  // const [product] = useDocument(queryProduct);

  const [product, setProduct] = useState("");
  useEffect(() => {
    const init = async () => {
      try {
        const docRef = doc(db, "products", id);
        const snapShot = await getDoc(docRef);
        if (snapShot.exists()) {
          setProduct({ id: docRef.id, ...snapShot.data() });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };

    if (id) {
      init();
    }
  }, [id]);

  // 販売者(buyer)情報
  const [buyer, setBuyer] = useState("");
  useEffect(() => {
    if (product) {
      getDoc(doc(db, "users", product?.uid)).then((data) => {
        setBuyer(data.data());
      });
    }
  }, [product]);

  // プロダクトの画像
  const queryMyShopImages = query(
    collection(db, `products/${id}/images`)
    // where("uid", "==", auth.currentUser.uid)
  );
  const [images] = useCollection(queryMyShopImages);
  const myShopImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    if (user) {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo(doc.data());
      });
    }
  }, [user]);

  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />

      {user && user?.uid === buyer?.uid && (
        <div className=" flex items-center space-x-3 border rounded-md p-2 w-fit mb-3">
          <div>掲載</div>
          <SwitchComponent
            id={product?.id}
            target="isPublish"
            collection="products"
            text="表示"
          />
        </div>
      )}

      <div className="p-2 max-w-7xl border rounded-md">
        {/* 戻るボタン？？ */}
        <div
          className="my-1 hover:cursor-pointer"
          onClick={() => navigate("/ddps")}
        >
          ←DDP広場
        </div>
        {/* タイプとレート */}
        <div className="flex text-gray-300">
          <div>
            {product?.offering}/{product?.target}
          </div>
        </div>

        <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-2">
          {/*　【左】 画像　カルーセル */}
          <CarouselProductImages productId={id} size="large" />
          {/*　【右】 タイトルなど */}
          <div className="xl:col-span-2">
            <div className="flex flex-col space-y-3">
              {/* snsList */}
              <SNSList title={product?.title} />

              {/* タイトル */}
              <div className="flex justify-between my-2 ">
                <div className="text-3xl">{product?.title}</div>
              </div>
              {/* レート  */}
              <div className="flex items-center">
                <ProductRate />
              </div>
              {/* 交換希望ボタン or サインアップ */}

              {/* サインアップしてないユーザ */}
              {!user && (
                <>
                  <div className="flex items-center">
                    <DisplaySignUp />
                  </div>
                  {/* <Button>ログイン</Button>して交換する */}
                </>
              )}
              {/* 村民じゃないユーザ */}
              {user && !userInfo?.isVillager && (
                <>
                  <div className="my-4"></div>
                  <div className="flex items-center">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.sekaimura.com/`}
                      className="text-blue-500 group-hover:underline "
                    >
                      村民登録して交換する
                    </a>
                  </div>
                </>
              )}

              {/* 村民さん =>交換できる */}
              {/* {user && userInfo?.isVillager && (
                <>
                  <div className="my-4"></div>
                  <Button
                    variant="contained"
                    className="w-fit"
                    onClick={() => handleModalOpen("reallyBuyDDP", id)}
                  >
                    交換する
                  </Button>
                </>
              )} */}
              {/* 自分のDDPの場合 */}
              {user && userInfo?.uid === product?.uid ? (
                <div></div>
              ) : (
                user &&
                userInfo?.isVillager && (
                  <>
                    <div className="my-4"></div>
                    <Button
                      variant="contained"
                      className="w-fit"
                      onClick={() => handleModalOpen("reallyBuyDDP", id)}
                    >
                      交換する
                    </Button>
                  </>
                )
              )}

              {/* 価格（ルンル） */}
              <div className="flex items-baseline">
                <div>
                  <AttachMoneyIcon />
                </div>
                <div className=" text-7xl text-blue-500">{product?.runru}</div>
                <div className="text-xs text-gray-400">runru</div>
              </div>
              {/* 配送情報 */}
              <div className="flex">
                <div className="font-bold text-xl">配送方法：</div>
                <div>{product?.delivery}</div>
              </div>

              {/* 提供者情報 */}
              <div className="font-bold text-xl">提供者情報</div>
              <RenderUserItem key={user.uid} user={buyer} highlight={""} />


              {/* <div className="flex flex-col">
                <div className="font-bold text-xl">提供者情報</div>
                <div className="flex items-center rounded-full shadow-md hover:shadow-2xl cursor-pointer space-x-3 px-4 py-2 duration-150 border hover:border-blue-500 w-fit">
                  <Avatar src={buyer?.avatar}></Avatar>
                  <div
                    className="flex flex-col"
                    onClick={() => navigate("/user/" + buyer?.uid)}
                  >
                    <div className=" break-words line-clamp-2 whitespace-pre-wrap">
                      {buyer?.lastName}
                      {buyer?.firstName}
                    </div>
                    <div className="text-xs text-gray-400">
                      {" "}
                      {isInvalidDate(new Date(buyer?.createdAt?.toDate()))
                        ? "読み込み中"
                        : formatDistanceToNow(buyer.createdAt?.toDate(), {
                            locale: ja,
                          })}
                      前に参加
                    </div>
                  </div>
                </div>
              </div> */}

              {/* DDPの説明 */}
              <div className="flex flex-col">
                <div className="font-bold text-xl">提供者によるDDPの説明</div>
                <div className=" whitespace-pre-wrap break-words">
                  {product?.explain}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* みんなの評価 */}
        {/* <div className="flex flex-col">
          <div className="font-bold text-xl">みんなの感想</div>
          <ProductCusutomerEvalutions />
        </div> */}

        {/* よく一緒に買われているDDP */}
        {/* おすすめDDP*/}
        {/* DDPギャラリー */}
        <div className="my-4"></div>
        <div className="flex flex-col">
          <div className="font-bold text-xl">提供者によるギャラリー</div>

          {myShopImages?.map((image, index) => {
            return (
              <div key={Math.random()} className="my-4">
                <img
                  src={image.imageURL}
                  alt=""
                  object-fit="cover"
                  className=" h-96"
                />
              </div>
            );
          })}
        </div>

        {/* 質問 */}
        <div className="my-4"></div>
        <div className="font-bold text-xl">質問する</div>
        <ProductQuestion />

        {/* 質問と回答 */}
        <div className="my-4"></div>
        <div className="font-bold text-xl">質問と回答</div>
        <ProductQAndA />
        {/* みんなのレビュー */}
        <ProductWriteReview />

        {/* みんなのレビュー */}
        <div className="my-4"></div>
        <div className="font-bold text-xl">みんなのレビュー</div>
        <ProductCustomerReviews />

        {/* このDDPを交換した人はこんなDDPも交換しています */}
        <div className="my-4"></div>
        <div className="font-bold text-xl">
          このDDPを受け取った人はこんなDDPも交換しています
        </div>
      </div>
    </>
  );
};

export default memo(DisplayProductPC);
