import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../api/firebase";
import MovieChannelTopImage from "./MovieChannelTopImage";
import MovieChannelCard from "./MovieChannelCard";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import Box from "@mui/material/Box";

import { Button, TextareaAutosize, Tooltip } from "@mui/material";

import ModalForm from "./ModalForm";
import DisplayMovie from "./DisplayMovie";
import SwitchComponent from "../components/SwitchComponent";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import Vimeo from "@u-wave/react-vimeo";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import StreamChatOnMovieChannel from "./StreamChatOnMovieChannel";
import DeleteIcon from "@mui/icons-material/Delete";
import RadioGroupsComponent from "./RadioGroupsComponent";
import SelectComponent from "./SelectComponent";
import DisplayVideo from "./DisplayVideo";

// YouTubeの動画IDを抽出する関数
const extractYouTubeId = (url) => {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const MovieChannel = () => {
  const { id } = useParams();
  const [channel, setChannel] = useState("");
  useEffect(() => {
    getDoc(doc(db, "movieChannel", id)).then((docu) => {
      setChannel({ id: docu.id, ...docu.data() });
    });
  }, []);

  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const init = async () => {
      if (channel?.creator === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    channel && init();
  }, [channel]);

  const [user, setUser] = useState("");
  useEffect(() => {
    const init = async () => {
      await getDoc(doc(db, "users", auth.currentUser.uid)).then((docRef) => {
        setUser({ id: docRef.id, ...docRef.data() });
      });
    };
    init();
  }, []);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const init = () => {
      if (user?.isAdmin) {
        setIsAdmin(true);
      }
    };
    user && init();
  }, [user]);

  const docRef = doc(db, "movieChannel", id);
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(null);
  // 編集フォームの変更を処理する処理
  const handleEdit = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };
  // データの編集を保存する処理
  const saveData = async (e) => {
    console.log("editedData =>", editedData);
    if (!editedData) {
      setEditMode(false);
      return;
    }

    if (editedData[e.target.name].length === 0) {
      setEditMode(false);
      return;
    }

    await updateDoc(docRef, {
      [e.target.name]: editedData[e.target.name],
    });
    window.location.reload();
  };

  // 編集モードに入る処理
  const enterEditMode = () => {
    setEditMode(true);
  };

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");
  const handleMove = (e, target) => {
    setOpen(true);
    setTarget(target);
    setForm(e);
  };

  // publishな動画を取得
  const [movies, setMovies] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      await getDocs(
        query(
          collection(db, "movie"),
          where("target", "==", "movieChannel"),
          where("targetId", "==", id),
          where("isPublish", "==", true),
          orderBy("createdAt", "desc")
        )
      ).then((docs) =>
        docs.forEach((elm) => {
          arr.push({ id: elm.id, ...elm.data() });
        })
      );
      setMovies(arr);
    };
    init();
  }, [id]);

  // publishな動画を取得
  const [moviesOrderByAsc, setMoviesOrderByAsc] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      await getDocs(
        query(
          collection(db, "movie"),
          where("target", "==", "movieChannel"),
          where("targetId", "==", id),
          where("isPublish", "==", true),
          orderBy("createdAt", "asc")
        )
      ).then((docs) =>
        docs.forEach((elm) => {
          arr.push({ id: elm.id, ...elm.data() });
        })
      );
      setMoviesOrderByAsc(arr);
    };
    init();
  }, [id]);

  const [allMovies, setAllMovies] = useState([]);
  // すべての動画を取得
  useEffect(() => {
    const init = async () => {
      const arr = [];
      await getDocs(
        query(
          collection(db, "movie"),
          where("target", "==", "movieChannel"),
          where("targetId", "==", id),
          orderBy("createdAt", "desc")
        )
      ).then((moviesCollection) => {
        moviesCollection.forEach((movie) => {
          arr.push({ id: movie.id, ...movie.data() });
        });
      });
      setAllMovies(arr);
    };
    init();
  }, [id]);

  const navigate = useNavigate();

  const moveToMovie = (id) => {
    navigate("/movie/" + id);
  };

  const [isVillager, setIsVillager] = useState(false);
  useEffect(() => {
    const init = async () => {
      if (user?.isVillager) {
        setIsVillager(true);
      }
    };
    init();
  }, [user]);

  // Modalform関連
  const [requestId, setRequestId] = useState("");
  const handleDelete = (e, mid, target) => {
    console.log("");
    setOpen(true);
    setForm(e);
    setRequestId(mid);
    setTarget(target);
  };

  const handleMovePage = (mid) => {
    navigate("/movie/" + mid);
  };

  const openRange = [
    { value: "Free", label: "フリー", icon: "" },
    { value: "forUsersOver", label: "ユーザ以上限定", icon: "" },
    { value: "forVillagers", label: "村民限定", icon: "" },
  ];

  const orderBys = [
    { value: "desc", label: "降順(登録した順)", icon: "" },
    { value: "asc", label: "昇順(古いもの順)", icon: "" },
  ];

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
        target={target}
      />

      <div className="rounded-md h-fit bg-white">
        {/* TOP画像 */}
        <div className="mb-5">
          <MovieChannelTopImage />
        </div>
        {/* TOP画像下コンテンツ */}
        {/* カード */}
        {channel && (
          <>
            <div className="">
              <MovieChannelCard channel={channel} id={id} />
            </div>
          </>
        )}
        {/* コンテンツ */}
        <div className="md:px-5 h-full rounded-md w-[calc(100%)] bg-white">
          <TabContext value={value}>
            {/* 左リスト */}

            <Box
              sx={{
                maxWidth: { xs: 375, sm: 1080 },
              }}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab label="動画" value="1" />
                <Tab label="概要" value="2" />
                <Tab label="Chat" value="3" />
                {(isAdmin || isAuthor) && <Tab label="設定" value="4" />}
                {(isAdmin || isAuthor) && (
                  <Tab
                    label="＋動画追加"
                    onClick={() => handleMove("addMovie", "movieChannel")}
                  />
                )}
              </TabList>
            </Box>

            {/* 動画 */}
            <TabPanel sx={{ width: "100%", height: "100%" }} value="1">
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {channel?.orderByForChannelPage === "desc"
                  ? movies?.map((movie) => (
                      <DisplayVideo movie={movie} key={movie.id} />
                    ))
                  : moviesOrderByAsc?.map((movie) => (
                      <DisplayVideo movie={movie} key={movie.id} />
                    ))}
              </div>
            </TabPanel>

            {/* 概要 */}
            <TabPanel sx={{ width: "100%" }} value="2">
              <div className="grid grid-cols-1">
                <div className=" col-span-1 whitespace-pre-wrap break-words h-fit">
                  {channel?.explain}
                </div>
              </div>
            </TabPanel>

            {/* Chat */}
            <TabPanel sx={{ width: "100%", minHeight: "720px" }} value="3">
              <div className=" font-bold text-3xl">コメント</div>
              {isVillager ? (
                <StreamChatOnMovieChannel user={user} id={id} />
              ) : (
                <div>セカイムラメンバーの機能です</div>
              )}
            </TabPanel>

            {/* 設定 */}
            <TabPanel sx={{ width: "100%", height: "fit-content" }} value="4">
              <div className="grid grid-cols-1">
                <table className=" p-2 w-fit">
                  <tbody>
                    {/* 表示 */}
                    <tr>
                      <td className=" bg-blue-50 p-5 font-bold">表示</td>
                      <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                        <div className="flex flex-col space-y-2">
                          <div className="flex items-center  space-x-2 ">
                            <div className="font-bold ">
                              <SwitchComponent
                                id={id}
                                target="isPublish"
                                collection="movieChannel"
                                text="表示"
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {/* タイトル */}
                    <tr>
                      <td className=" bg-blue-50 p-5 font-bold">タイトル</td>
                      <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                        <div className="flex items-center  space-x-2 ">
                          <div className="font-bold ">
                            <EditableContent
                              target={channel?.title}
                              name="title"
                              editMode={editMode}
                              user={user}
                              creator={channel?.creator}
                              handleEdit={handleEdit}
                              saveData={saveData}
                              enterEditMode={enterEditMode}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    {/* 概要 */}
                    <tr>
                      <td className=" bg-blue-50 p-5 font-bold">概要</td>
                      <td className=" p-5 shadow-md hover:shadow-2xl duration-150 w-full">
                        <div className="flex items-center  space-x-2 ">
                          <div className="font-bold ">
                            <EditableContent
                              target={channel?.explain}
                              name="explain"
                              editMode={editMode}
                              user={user}
                              creator={channel?.creator}
                              handleEdit={handleEdit}
                              saveData={saveData}
                              enterEditMode={enterEditMode}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    {/* 表示順 */}
                    <tr className="border-b-2 border-white">
                      <td className=" bg-blue-50 p-5 font-bold">表示順</td>
                      <td className=" p-5  shadow-md hover:shadow-2xl duration-150">
                        <div className="flex flex-col space-y-2 w-fit">
                          {/* 動画一覧 */}
                          <div className="flex flex-col  space-y-2 border rounded-md p-2">
                            <div className="font-bold">動画一覧ページ</div>
                            <div>
                              {channel?.orderByForMoviesPage === "desc"
                                ? "登録した順"
                                : "古いもの順"}
                            </div>
                            <div className="flex items-center  space-x-2 ">
                              <div className="font-bold ">
                                <SelectComponent
                                  title="動画一覧ページ"
                                  collection="movieChannel"
                                  id={id}
                                  documentName="orderByForMoviesPage"
                                  array={orderBys}
                                  reload
                                />
                              </div>
                            </div>
                          </div>
                          {/* チャンネルページ */}
                          <div className="flex flex-col space-y-2 border rounded-md p-2">
                            <div className="font-bold">チャンネルページ</div>
                            <div>
                              {channel?.orderByForChannelPage === "desc"
                                ? "登録した順"
                                : "古いもの順"}
                            </div>

                            <div className="flex items-center  space-x-2 ">
                              <div className="font-bold ">
                                <SelectComponent
                                  title="チャンネルページ"
                                  collection="movieChannel"
                                  id={id}
                                  documentName="orderByForChannelPage"
                                  array={orderBys}
                                  reload
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {/* 動画 */}
                    <tr>
                      <td className=" bg-blue-50 p-5 font-bold">動画</td>
                      <td className=" shadow-md hover:shadow-2xl duration-150 w-full">
                        <div className="flex items-center  space-x-2 ">
                          <div className="font-bold ">
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:sm:grid-cols-3 xl:sm:grid-cols-4 gap-2">
                              {allMovies?.map((movie, idx) => (
                                <div
                                  key={movie.id}
                                  className=" col-span-1 border-2 rounded-md p-2 shadow-md hover:shadow-2xl hover:border-blue-500 duration-150"
                                >
                                  <div className="flex flex-col space-y-2 mb-2">
                                    {/* タイトルと日付 */}
                                    <div
                                      className=" hover:cursor-pointer"
                                      onClick={() => handleMovePage(movie.id)}
                                    >
                                      <div className="grid col-span-1 break-words whitespace-pre-line line-clamp-2 font-bold text-xl">
                                        {movie.title}
                                      </div>
                                      {/* 〇〇か月前 */}
                                      <div className=" flex justify-items-end text-gray-500 text-xs hover:bg-white hover:shadow-2xl">
                                        {formatDistanceToNow(
                                          movie?.createdAt.toDate(),
                                          {
                                            locale: ja,
                                          }
                                        )}
                                        前
                                      </div>
                                    </div>

                                    {/* 表示と消去 */}
                                    <div className="flex justify-between items-center">
                                      <div className=" border rounded-md shadow-md hover:border-blue-500 cursor-pointer hover:shadow-2xl p-2 ">
                                        <div>表示・非表示</div>
                                        <SwitchComponent
                                          id={movie?.id}
                                          target="isPublish"
                                          collection="movie"
                                          text="表示"
                                        />
                                      </div>
                                      <Tooltip
                                        title="消去"
                                        placement="top"
                                        arrow
                                      >
                                        <DeleteIcon
                                          name={movie.id}
                                          className=" text-gray-500 cursor-pointer"
                                          onClick={() =>
                                            handleDelete(
                                              "deleteMovie",
                                              movie.id
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                    {/* 公開範囲 */}
                                    <div className="border shadow-md p-2 hover:border-blue-500 hover:shadow-2xl cursor-pointer duration-150 rounded-md">
                                      <RadioGroupsComponent
                                        title="公開範囲"
                                        array={openRange}
                                        collection="movie"
                                        id={movie.id}
                                        documentName="openRange"
                                      />
                                    </div>
                                  </div>
                                  {/* 動画 */}
                                  {movie.platform === "Vimeo" ? (
                                    <Vimeo
                                      className=" hover:cursor-pointer"
                                      key={Math.random()}
                                      video={movie.url}
                                      responsive
                                      controls
                                    />
                                  ) : movie.platform === "YouTube" ? (
                                    <iframe
                                      className="hover:cursor-pointer"
                                      key={Math.random()}
                                      src={`https://www.youtube.com/embed/${extractYouTubeId(
                                        movie.url
                                      )}`}
                                      title={movie.title}
                                      frameBorder="0"
                                      allow="autoplay; fullscreen; picture-in-picture"
                                      allowFullScreen
                                      style={{
                                        position: "relative", // absolute から relative に変更
                                        width: "100%", // 幅を100%に設定
                                        height: "auto", // 高さを自動に設定
                                        aspectRatio: "16/9", // アスペクト比を16:9に設定
                                        borderRadius: "4px",
                                      }}
                                    ></iframe>
                                  ) : (
                                    <div>Invalid video platform</div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </>
  );
};

export default memo(MovieChannel);

const EditableContent = (props) => {
  const {
    target,
    name,
    editMode,
    user,
    creator,
    handleEdit,
    saveData,
    enterEditMode,
  } = props;

  return (
    <>
      {editMode && (user?.isAdmin || creator === auth.currentUser.uid) ? (
        <>
          <div className="grid grid-cols-1 w-full space-x-2 items-center   ">
            <TextareaAutosize
              name={name}
              style={{
                resize: "none",
                fontSize: "16px",
                border: "1px solid gray",
                width: "100%",
              }}
              onChange={handleEdit}
              placeholder={target}
              defaultValue={target}
              className="p-1 w-full scrollbar-hide  "
              minRows={3}
              maxRows={5}
            />
            <Button
              name={name}
              onClick={saveData}
              className="w-fit"
              variant="outlined"
            >
              決定
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <div
              onClick={enterEditMode}
              className=" whitespace-pre-wrap break-words line-clamp-3"
            >
              {target}
            </div>
            <div className="h-6 w-6 cursor-pointer ">
              <ModeEditIcon onClick={enterEditMode} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
