import React from "react";

import SekaimuraMap from "../pages/SekaimuraMap";

import HeaderContainerMixIN from "../components/HeaderContainerMixIN";

const Map = () => {
  return (
    <>
      <HeaderContainerMixIN outlet={<SekaimuraMap />} />

      {/* <SekaimuraMap/> */}
    </>
  );
};

export default Map;
