import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getDocs, collection } from "firebase/firestore";
import { auth, db } from "../api/firebase";

import GetUserInfo from "./GetUserInfo";
import addError from "../components/addError";
import ModalForm from "./ModalForm";
import { memo } from "react";

const ProductQAndA = memo(() => {
  const { id } = useParams();

  // 質問関連
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    let arr = [];
    getDocs(collection(db, "products", id, "question")).then((docs) => {
      docs?.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setQuestions(arr);
    });
  }, []);

  const handleAnswer = (question) => {
    if (question.buyer === auth.currentUser.uid) {
        handleModalOpen(question)
    }
  };

  const [question,setQuestion] =useState("")
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleModalOpen = (e) => {
    setOpen(true);
    setForm("answerProduct");
    setQuestion(e.id)
};


  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        
        requestId={question}
      />

      {questions ? (
        questions?.map((question) => (
          <div
            key={Math.random()}
            className="flex flex-col border p-3 my-3  "
            onClick={() => handleAnswer(question)}
          >
            <div className="flex items-center space-x-1">
              <GetUserInfo
                uid={question.questioner}
                target="avatar"
                size={36}
              />
              <GetUserInfo uid={question.questioner} target="name" />
              <div>
                {new Date(question.createdAt?.toDate()).toLocaleString()}
              </div>
            </div>
            <div>質問：{question.question}</div>
            <div>回答：{question.answer  || "まだ回答がありません。"}</div>
          </div>
        ))
      ) : (
        <div>質問はまだありません</div>
      )}
    </>
  );
});

export default ProductQAndA;
