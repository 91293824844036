import React, { useEffect, useState } from "react";
import { doc, collection, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Grid, Tooltip } from "@mui/material";
import Loader1Row from "./Loader1Row";
import ModalForm from "./ModalForm";
import AddComponentMini from "./AddComponentMini";

const DdpTable = () => {
  const [users, loading] = useCollection(collection(db, "users"));

  //   消去で呼び出すModalのプロパティたち
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const [target, setTarget] = useState("");
  const handleEdit = (e, id, target) => {
    // console.log(e);
    // console.log(id);
    // console.log(target);
    setOpen(true);
    setForm(e);
    setRequestId(id);
    setTarget(target);
  };

  const [dataloading, setDataLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [userInfoLoading, setUserInfoLoading] = useState(false);
  const [originalRows, setOriginalRows] = useState([]);

  const [matchCollection] = useCollection(collection(db, "ddp"));
  const matchingDdp = matchCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  useEffect(() => {
    setRows(matchingDdp);
    setOriginalRows(matchingDdp);
    setDataLoading(false);
  }, [matchCollection]);

  // DDP一覧取得
  useEffect(() => {
    setOriginalRows(matchingDdp);
    setDataLoading(false);
  }, [dataloading]);

  // テーブル使用コンテナ
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // テーブル使用handle
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   権限変更関連handle
  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "ddp", id));
    // console.log(id)
    // console.log(type)
    // console.log(pub.data())
    await updateDoc(doc(db, "ddp", id), {
      [type]: !pub.data()[type],
    });
    setDataLoading(true);
    setUserInfoLoading(false);
  };
  const handleChange = async (e) => {
    setUserInfoLoading(true);
    await publishChange(e.target.value, e.target.name);
  };

  // 検索関連
  const requestSearch = (searchVal) => {
    // console.log(originalRows);
    const filteredRows = originalRows.filter((row) => {
      return (
        (row.offering + row.recieve)
          // .offering.toLowerCase()
          .includes(searchVal.target.value.toLowerCase())
      );
    });
    // console.log(filteredRows);
    setRows(filteredRows);
  };

  if (loading) {
    return <Loader1Row />;
  }
  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
        target={target}
      />

      <TextField
        id="search-bar"
        className="my-2"
        label="「提供の形（する・受ける）」検索"
        variant="standard"
        placeholder="ex)話す、あげる、聞きたい..."
        size="small"
        onChange={(searchVal) => requestSearch(searchVal)}
        sx={{ m: 1.5 }}
      ></TextField>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 360, borderRadius: 1 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            sx={{ minWidth: 650 }}
          >
            <TableHead>
              {/* ヘッダー情報 */}
              <TableRow>
                <TableCell>ステータス</TableCell>
                <TableCell>提供する形</TableCell>
                <TableCell>提供を受ける形</TableCell>
                <TableCell>対象</TableCell>
                <TableCell>説明</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  className="flex group"
                >
                  {/* ステータス */}
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row?.isPublish ? row?.isPublish : false}
                          onChange={handleChange}
                          name="isPublish"
                          disabled={userInfoLoading}
                          value={row?.id ? row?.id : false}
                        />
                      }
                      label={row?.isPublish ? "表示" : "非表示"}
                    />
                  </TableCell>
                  {/* 提供する形 */}
                  <TableCell align="left">
                    <Grid className="font-bold">{row.offering}</Grid>
                  </TableCell>
                  {/* 提供を受ける形 */}
                  <TableCell align="left">
                    <Grid className="font-bold">{row.recieve}</Grid>
                  </TableCell>

                  {/* 対象  */}
                  <TableCell align="left">
                    <Grid>
                      {row.targets?.map((target) => {
                        return (
                          <div key={Math.random()}>
                            <Tooltip title="クリックで削除">
                              <div
                                key={Math.random()}
                                className="font-bold group hover:border rounded-md p-1 hover:bg-gray-300 hover:cursor-pointer"
                                name={row.id}
                                value={target}
                                onClick={(e) =>
                                  handleEdit(
                                    "deleteDdpTarget",
                                    row.id,
                                    e.target.innerText
                                  )
                                }
                                //   onClick={(e) => alert(e.target.n)}
                                // onClick={(e) => console.log(e.target.innerText)}
                              >
                                {target}
                              </div>
                            </Tooltip>
                          </div>
                        );
                      })}
                      <AddComponentMini
                        value=""
                        target="addOnlyDdpTarget"
                        requestId={row.id}
                      />
                    </Grid>
                  </TableCell>

                  {/* 説明*/}
                  <TableCell align="left">
                    <Grid
                      className="font-bold"
                      onClick={(e) => console.log(e.target)}
                    >
                      {row.explain}
                    </Grid>
                  </TableCell>

                  {/* 消去 */}
                  <TableCell align="left">
                    <EditIcon
                      name={row.id}
                      className=" sm:invisible sm:group-hover:visible cursor-pointer"
                      onClick={() => handleEdit("editDdpMatching", row.id)}
                    />

                    <DeleteIcon
                      name={row.id}
                      className=" sm:invisible sm:group-hover:visible cursor-pointer"
                      onClick={() => handleEdit("deleteDdpMatching", row.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="ml-2 mt-2">
          {/* 掲載店舗：{publish.docs.length} / {realtimeRequestShop.docs.length} */}
        </div>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={users.docs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default DdpTable;

// テーブル検索
// https://smartdevpreneur.com/the-easiest-way-to-implement-material-ui-table-search/
