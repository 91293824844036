import axios from "axios";

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const MAX_RETRIES = 5;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getSkillDetails = async (ddp, userName) => {
  const messages = [
    { role: "system", content: "あなたはスキル解析のアシスタントです。" },
    {
      role: "user",
      content: `
    ${userName}さんのDDP: "${ddp}". このDDPをかなえるために必要なスキルについて、それぞれどんな能力を持った人から話を聞くと良いか、その理由と具体的な職種や専門家を説明してください。
    `,
    },
  ];

  for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4",
          messages: messages,
          max_tokens: 1000,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );

      const rawResponse = response.data.choices[0].message.content.trim();
      const formattedResponse = formatResponse(rawResponse, userName);

      return formattedResponse;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        const retryAfter = error.response.headers["retry-after"]
          ? parseInt(error.response.headers["retry-after"]) * 1000
          : Math.pow(2, attempt) * 2000;
        console.error(
          `Attempt ${attempt + 1} failed with status 429. Retrying in ${
            retryAfter / 1000
          } seconds...`
        );
        await sleep(retryAfter);
      } else {
        console.error("Error getting ChatGPT response:", error);
        if (error.response) {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
        } else {
          console.error("Error message:", error.message);
        }
        return "情報の取得に失敗しました。再度お試しください。";
      }
    }
  }
  return "リクエストの再試行回数が上限に達しました。後でもう一度お試しください。";
};

const formatResponse = (rawResponse, userName) => {
  const lines = rawResponse.split("\n");
  let formattedResponse = `${userName}さんのDDPはかなり興味深いものですね。\n`;

  lines.forEach((line) => {
    if (
      line.trim().startsWith("1.") ||
      line.trim().startsWith("2.") ||
      line.trim().startsWith("3.") ||
      line.trim().startsWith("4.") ||
      line.trim().startsWith("5.") ||
      line.trim().startsWith("6.") ||
      line.trim().startsWith("7.") ||
      line.trim().startsWith("8.") ||
      line.trim().startsWith("9.") ||
      line.trim().startsWith("10.") ||
      line.trim().startsWith("11.") ||
      line.trim().startsWith("12.") ||
      line.trim().startsWith("13.") ||
      line.trim().startsWith("14.")
    ) {
      formattedResponse += `\n${line.trim()}`;
    } else {
      formattedResponse += `\n${line.trim()}`;
    }
  });

  return formattedResponse.trim();
};
