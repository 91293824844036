import React from "react";

const ReallyJumpToURL = ({ target }) => {
  return (
    <>
      <div className="font-bold ">
        以下のURLに遷移します。よろしければURLをクリック/タッチしてください。
      </div>
      <a href={target} target="_blank">
        <div className=" text-sm  font-bold underline text-blue-500 truncate">
          {target}
        </div>
      </a>
      <div className="text-red-500 text-sm">
       ※ 遷移を実行して被った損害に対してセカイムラは保障できません。怪しいサイトには十分ご注意ください。
      </div>
    </>
  );
};

export default ReallyJumpToURL;
