import React, { useEffect, useState } from "react";
import {
  doc,
  collection,
  query,
  updateDoc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";

import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Divider, Grid } from "@mui/material";
import LoaderHeaderPic from "./LoaderHeaderPic";
import Loader1Row from "./Loader1Row";
import { PencilIcon } from "@heroicons/react/solid";
import ModalForm from "./ModalForm";

import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import GetUserInfo from "./GetUserInfo";

import RemoveIcon from "@mui/icons-material/Remove";
import SwitchComponent from "./SwitchComponent";
import RadioGroupOnBaseUseGeoPoint from "./RadioGroupOnBaseUseGeoPoint";
import { useNavigate } from "react-router-dom";

const BasesTable = () => {
  const [basesCount, loading] = useCollection(collection(db, "bases"));

  const [dataloading, setDataLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [d_loding, setD_loading] = useState(false);
  const [originalRows, setOriginalRows] = useState([]);

  // 表示・非表示
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e, id) => {
    // console.log(e);
    // console.log(id);
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  const queryBases = query(
    collection(db, "base"),
    orderBy("createdAt", "desc")
  );
  const [basesData] = useCollection(queryBases);
  const bases = basesData?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  useEffect(() => {
    setOriginalRows(bases);
    setRows(bases);
  }, [basesData]);

  // console.log(rows[0]?.chiefLastName)
  // bases?.forEach((doc)=>{
  //   console.log(doc?.chiefLastName)
  //   const d = getDoc(doc(db,'doc.chiefLastName'))
  //   console.log(d)
  // })

  // テーブル使用コンテナ
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // テーブル使用handle
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   権限変更関連handle
  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "base", id));
    await updateDoc(doc(db, "base", id), {
      [type]: !pub.data()[type],
    });
    setDataLoading(true);
    setD_loading(false);
  };
  const handleChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  // 検索関連
  const requestSearch = (searchVal) => {
    const filteredRows = originalRows.filter((row) => {
      return row.title
        .toLowerCase()
        .includes(searchVal.target.value.toLowerCase());
    });
    setRows(filteredRows);
  };

  // 日付のInvalidDateを防ぐ関数
  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  if (loading) {
    return <Loader1Row />;
  }

  // console.log("rows =>", rows);

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        target="base"
        requestId={requestId}
      />
      {/* <DeleteColumn display={bases} requestId={requestId} target="bases" /> */}
      {/* <HereIsThisOnMap
        display={editGeocode}
        requestId={requestId}
        target="bases"
      /> */}
      <TextField
        id="search-bar"
        className="my-2"
        label="拠点検索"
        variant="standard"
        placeholder="拠点"
        size="small"
        onChange={(searchVal) => requestSearch(searchVal)}
        sx={{ m: 1.5 }}
      ></TextField>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {rows?.map((row, index) => (
          <div key={Math.random()}>
            <div className="flex flex-col space-y-1 rounded-md border shadow-md p-2 " key={Math.random()}>
              {/* 県表示 と 拠点名 */}
              < div className="flex items-center space-x-2 ">
                <div className=" rounded-full border-2 min-w-fit border-orange-800 bg-orange-100 bg-opacity-70 shadow-md font-bold px-2 py-1">
                  <div className="flex  items-center  space-x-1 text-orange-800">
                    {row?.prefecture}
                  </div>
                </div>

                <div className="font-bold text-lg line-clamp-1">
                  {row?.title}
                </div>
              </div>

              {/* 詳細を見る */}
              <div
                className="text-xs text-blue-500 cursor-pointer"
                onClick={() => window.open("/base/" + row?.id)}
              >
                詳細を見る/変更
              </div>

              {/* 村長 */}
              <div className="flex flex-row justify-between items-center border p-2 hover:bg-gray-200 duration-150 cursor-pointer">
                {/* 村長のアバター */}
                <div className="w-fit">村長</div>
                <div className="flex flex-row items-center space-x-2">
                  {row.chief && (
                    <>
                      <div className="hidden lg:block">
                        <GetUserInfo
                          uid={row.chief}
                          target="avatar"
                          size={40}
                        />
                      </div>
                      <GetUserInfo uid={row.chief} target="name" />
                      <div
                        onClick={() => handleEdit("deleteBaseChief", row?.id)}
                      >
                        <RemoveIcon className=" text-white bg-red-400 rounded-full ml-2 hover:bg-red-500 hover:cursor-pointer" />
                      </div>
                    </>
                  )}

                  {!row.chief && (
                    <PencilIcon
                      className="w-6 h-6 hover:cursor-pointer hover:shadow-xl"
                      onClick={() => handleEdit("addBaseChief", row.id)}
                    />
                  )}
                </div>
              </div>

              {/* 拠点ページ掲載 */}
              <div className="flex justify-between items-center border pl-2 hover:bg-gray-200 duration-150 cursor-pointer">
                <div className="w-fit">拠点ページ掲載</div>
                <SwitchComponent
                  id={row?.id}
                  target="isPublish"
                  collection="base"
                />
              </div>

              {/* マップ公開 */}
              <div className="flex justify-between items-center border pl-2 hover:bg-gray-200 duration-150 cursor-pointer">
                <div className="w-fit">マップ公開</div>
                <SwitchComponent
                  id={row?.id}
                  target="releaseAddress"
                  collection="base"
                />
              </div>

              {/* マップ表示住所 */}
              <div className="flex justify-between items-center border pl-2 hover:bg-gray-200 duration-150 cursor-pointer">
                <div className="w-fit">マップ表示住所</div>
                <RadioGroupOnBaseUseGeoPoint base={row} />
              </div>

              {/* ダミー住所 */}
              <div className="flex justify-between items-center border p-2 hover:bg-gray-200 duration-150 cursor-pointer">
                <div>ダミー住所</div>
                <div>{row?.dummyAddress}</div>
              </div>
              {/* ダミー緯度・経度 */}
              <div className="flex justify-between items-center border p-2 hover:bg-gray-200 duration-150 cursor-pointer">
                <div>ダミー経度・緯度</div>
                {/* {row?.dummyLat && (
                  <div>
                    {row?.dummyLat}:{row?.dummyLng}
                  </div>
                )} */}

                <div>
                  {/* <div
                      className={`${
                        row.dummyLat && row.dummyLng ? "block" : "hidden"
                      } hover:bg-white hover:shadow-lg rounded-md `}
                    > */}
                  <div className=" hover:bg-white hover:shadow-lg rounded-md p-2">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.google.com/maps/place/${row.dummyLat},${row.dummyLng}`}
                      className="text-blue-500 group-hover:underline "
                      // onClick={() => geocodeSet(row.id)}
                      onClick={() => handleEdit("addDummyGeoCode", row.id)}
                    >
                      <div className="flex flex-col">
                        <div>経度：{row.dummyLat}</div>
                        <div>緯度：{row.dummyLng}</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              {/* 住所 */}
              <div className="flex justify-between items-center border p-2 hover:bg-gray-200 duration-150 cursor-pointer">
                <div>住所</div>
                <div>{row?.address}</div>
              </div>

              {/* 緯度・経度 */}
              <div className="flex justify-between items-center border p-2 hover:bg-gray-200 duration-150 cursor-pointer">
                <div>経度・緯度</div>
                {row?.lat && (
                  <div className="flex flex-col">
                    <div>{row?.lat}</div>
                    <div>{row?.lng}</div>
                  </div>
                )}
              </div>
            </div >
          </div>
        ))}
      </div >

      <div className="my-5">↓↓↓↓↓↓↓旧表記↓↓↓↓↓↓↓</div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 240, borderRadius: 1 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            sx={{ minWidth: 650 }}
          >
            <TableHead>
              {/* ヘッダー情報 */}
              <TableRow>
                <TableCell>掲載</TableCell>
                <TableCell>村長</TableCell>
                <TableCell>拠点名</TableCell>
                <TableCell>住所</TableCell>
                <TableCell>経度・緯度</TableCell>
                <TableCell>登録日</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  className="flex group"
                >
                  {/* 掲載 */}
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.isPublish}
                          onChange={handleChange}
                          name="isPublish"
                          disabled={d_loding}
                          value={row.id}
                        />
                      }
                      label={row.isPublish ? "掲載" : ""}
                    />
                  </TableCell>

                  {/* 村長 */}
                  <TableCell component="th" scope="row">
                    <div className="flex flex-row items-center">
                      {/* 村長のアバター */}
                      {row.chief && (
                        <div className="hidden lg:block">
                          <GetUserInfo
                            uid={row.chief}
                            target="avatar"
                            size={40}
                          />
                        </div>
                      )}
                      {/* 村長の名前 */}
                      {row.chief && (
                        <GetUserInfo uid={row.chief} target="name" />
                      )}
                      {row.chief && (
                        <>
                          <div
                            onClick={() =>
                              handleEdit("deleteBaseChief", row?.id)
                            }
                          >
                            <RemoveIcon className=" text-white bg-red-400 rounded-full ml-2 hover:bg-red-500 hover:cursor-pointer" />
                          </div>
                        </>
                      )}

                      {!row.chief && (
                        <PencilIcon
                          className="w-6 h-6 hover:cursor-pointer hover:shadow-xl"
                          onClick={() => handleEdit("addBaseChief", row.id)}
                        />
                      )}
                    </div>
                  </TableCell>

                  {/* 拠点名 */}
                  <TableCell align="left">{row.title}</TableCell>

                  {/* 住所 */}
                  <TableCell align="left">{row.address}</TableCell>

                  {/* 経度・緯度 */}
                  <TableCell align="left">
                    <Grid>
                      <div>
                        <div className="flex items-center">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://www.google.com/maps/place/${row.address}`}
                            className="text-blue-500 group-hover:underline "
                          >
                            {row.address}
                          </a>
                          <PencilIcon
                            className="w-6 h-6 hover:cursor-pointer hover:shadow-xl"
                            onClick={() => handleEdit("addGeoCode", row.id)}
                          />
                        </div>
                        <div
                          className={`${row.lat && row.lng ? "block" : "hidden"
                            } hover:bg-white hover:shadow-lg rounded-md `}
                        >
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://www.google.com/maps/place/${row.lat},${row.lng}`}
                            className="text-blue-500 group-hover:underline "
                            // onClick={() => geocodeSet(row.id)}
                            onClick={() => handleEdit("addGeoCode", row.id)}
                          >
                            <div>経度：{row.lat}</div>
                            <div>緯度：{row.lng}</div>
                          </a>
                        </div>
                      </div>
                    </Grid>
                  </TableCell>

                  {/* 登録日 */}
                  <TableCell align="left">
                    <Grid className="">
                      <div className="flex space-x-1">
                        <div className=" text-blue-500 text-xs">
                          {isInvalidDate(new Date(row.createdAt?.toDate()))
                            ? "読み込み中"
                            : formatDistanceToNow(row.createdAt?.toDate(), {
                              locale: ja,
                            })}
                          前
                        </div>
                        <div className="text-gray-300 text-xs">
                          {new Date(row.createdAt?.toDate()).toLocaleString()}
                        </div>
                      </div>
                    </Grid>
                  </TableCell>

                  {/* 消去 */}
                  <TableCell align="left">
                    <DeleteIcon
                      name={row.id}
                      className=" sm:invisible sm:group-hover:visible cursor-pointer"
                      // onClick={() => handleRequestShop(row.id)}
                      onClick={() => handleEdit("deleteBase", row.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="ml-2 mt-2">
          {/* 掲載店舗：{publish.docs.length} / {realtimeRequestShop.docs.length} */}
        </div>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={basesCount.docs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default BasesTable;

// テーブル検索
// https://smartdevpreneur.com/the-easiest-way-to-implement-material-ui-table-search/
