import React, { useEffect, useState } from "react";
import {
  doc,
  serverTimestamp,
  updateDoc,
  addDoc,
  getDoc,
  collection,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
// redux - use
import { useNotification } from "../redux/useNotification";
import { useDocumentData } from "react-firebase-hooks/firestore";

// 管理者による強制終了
// ルンルの移動は管理者画面のルンル強制移動で

const AdminCompulsionDealStop = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // 取引を取得
  const [deal, setDeal] = useState();
  useEffect(() => {
    const initGetDeal = async () => {
      getDoc(doc(db, "deal", requestId)).then((doc) => {
        setDeal(doc.data());
      });
    };
    requestId && initGetDeal();
  }, []);

  // 買った人を取得
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const setRequestDataShop = () => {};
  const [requestShopLoading, setRequestShopLoading] = useState(false);
  const [error, setError] = useState("");
  //   削除処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setRequestShopLoading(true);
    try {
      // キャンセル処理
      //   await deleteDoc(doc(db, "requestShop", requestId));
      await updateDoc(doc(db, "deal", requestId), {
        status: "adminCompulsionDealStop",
        lastUpdated: user?.uid,
        lastUpdatedAt:serverTimestamp(),

      });
      // 通知情報登録 - 買った人がキャンセル・・・なので
      // 買われた人に、「買った人がやっぱいらない」ってという通知をする
      // 買われた人 deal.buyer 買った人 deal.buy=> auth.currentUser.uid
      // fromはbuy
      // await addDoc(collection(db, "users", deal?.buyer, "notification"), {
      //   createdAt: serverTimestamp(),
      //   content: "DDP交換希望リクエストbuyキャンセル",
      //   fromAvatar: user?.avatar,
      //   fromId: user?.uid,
      //   fromName: user?.lastName + user?.firstName,
      //   isRecognition: false,
      //   target: "deal",
      //   targetId:requestId,
      // });

      setOpen(false);
      // displayNotification({
      //   message: "キャンセル処理完了しました。",
      //   type: "success",
      // });
    } catch (err) {
      setRequestShopLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        取引強制終了
      </div>
      <form onSubmit={setRequestDataShop}>
        <div className=" text-2xl">
          この取引を強制終了させます。よろしいですか？
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={requestShopLoading}
          onClick={handleDelete}
        >
          {requestShopLoading ? "キャンセルしています..." : "はい"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default AdminCompulsionDealStop;
