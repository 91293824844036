import React, { memo } from "react";
import DisplayTerms from "./DisplayTerms";
import HeaderContainer from "./HeaderContainer";
import HeaderContainerMixIN from "./HeaderContainerMixIN";

const Entrance = ({ outlet }) => {
  return (
    <>
      {/* <div className="md:hidden"> */}
      {/* <HeaderContainer outlet={outlet} /> */}
      {/* </div> */}
      <DisplayTerms />
      {/* <div className="hidden md:block"> */}
      <HeaderContainerMixIN outlet={outlet} />
      {/* </div> */}
    </>
  );
};

export default memo(Entrance);
