import React, { useState } from "react";
import Form from "./Form";
import ProgressList from "./ProgressList";

const Upload = ({targetCollection}) => {
  const [files, setFiles] = useState([]);
  return (
    <>
      <Form setFiles={setFiles}/>
      <ProgressList files={files} targetCollection={targetCollection}/>
    </>
  );
};

export default Upload;
