import React, { useState } from "react";
import SearchGroup from "./SearchGroup";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";
import ModalForm from "./ModalForm";
import DisplayAllGroups from "./DisplayAllGroups";
import { useEffect } from "react";
import { collection, where, getDocs, query } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import getParticipatingGroup from "./getParticipatingGroup";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";


const Groups = memo(() => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleModalOpen = (e) => {
    setOpen(true);
    setForm("makeGroup");
    setValue("1");
  };

  const [search, setSearch] = useState("");

  // 掲載中の全グループ取得
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    const arr = [];
    getDocs(
      query(
        collection(db, "group"),
        where("isPublish", "==", true),
        where("accept", "==", "public")
      )
    ).then((docs) => {
      docs.forEach((doc) => {
        const membersArr = [];
        getDocs(collection(db, "group", doc.id, "members")).then((members) => {
          members.forEach((member) => {
            membersArr.push(member.data().uid);
          });
        });
        arr.push({ id: doc.id, members: membersArr, ...doc.data() });
      });
      setGroups(arr);
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  // 参加済のグループ取得
  const [participatingGroups, setParticipatingGroups] = useState([]);
  useEffect(() => {
    const arr = [];
    const initParticipatedGroups = async () => {
      groups?.forEach((doc) => {
        getDocs(
          query(
            collection(db, "group", doc.id, "members"),
            where("uid", "==", auth.currentUser.uid)
          )
        ).then((docs) => {
          if (docs.size) {
            setParticipatingGroups((prev) => [...prev, { id: doc.id, ...doc }]);
          }
        });
      });
    };
    groups && initParticipatedGroups();
  }, [groups]);


  // 自分が作成したグループ
  const [myGroups, setMyGroups] = useState([]);
  useEffect(() => {
    let arr = [];
    const initParticipatedGroups = async () => {
      getDocs(
        query(
          collection(db, "group"),
          where("isPublish", "==", true),
          where("founder", "==", auth.currentUser.uid)
        )
      ).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setMyGroups(arr);
        setIsLoading(true);
      });
    };
    !isLoading && initParticipatedGroups();
  }, []);

  // 自分が作成したグループ
  const [myApplyingGroups, setMyApplyingGroups] = useState([]);
  useEffect(() => {
    let arr = [];
    const initParticipatedGroups = async () => {
      getDocs(
        query(
          collection(db, "group"),
          where("isPublish", "==", false),
          where("founder", "==", auth.currentUser.uid)
        )
      ).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setMyApplyingGroups(arr);
        setIsLoading(true);
      });
    };
    !isLoading && initParticipatedGroups();
  }, []);

  const DisplayGroupsCount = (text, num) => {
    return (
      <>
        <div className="flex items-baseline space-x-2 ">
          <div className="text-xs">{text}</div>
          <div className=" rounded-md p-1 text-xs bg-blue-400 text-white ">
            {num}
          </div>
        </div>
      </>
    );
  };

  if (!participatingGroups) return <div>読み込み中</div>;

  return (
    <>
      {/* {groups && getParticipatingGroup(groups, auth.currentUser.uid)} */}
      <ModalForm form={form} setOpen={setOpen} open={open} />
      <Tooltip title="Groupへ">
        <div
          className=" w-24 font-bold text-2xl hover:cursor-pointer"
          onClick={() => navigate("/groups")}
        >
          Group
        </div>
      </Tooltip>
      <div className="my-5"></div>
      <SearchGroup setSearch={setSearch} />
      <div className="my-5"></div>

      <div className="border rounded-md bg-white p-4">
        <TabContext value={value}>
          {/* 左リスト */}
          <Box
            sx={{
              maxWidth: { xs: 345, sm: 1080 },
            }}
          >
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                label={DisplayGroupsCount("すべてのグループ", groups?.length)}
                value="1"
                onClick={() => navigate("/groups")}
              />
              <Tab
                label={DisplayGroupsCount(
                  "参加中のグループ",
                  participatingGroups?.length
                )}
                // label={`参加中のグループ(${participatingGroups?.length})`}
                value="2"
                onClick={() => navigate("/groups")}
              />
              <Tab
                // label={`自分が作成したグループ(${myGroups?.length})`}
                label={DisplayGroupsCount(
                  "自分が作成したグループ",
                  myGroups?.length
                )}
                value="3"
                onClick={() => navigate("/groups")}
              />
              <Tab
                // label={`申請中のグループ(${myApplyingGroups?.length})`}
                label={DisplayGroupsCount(
                  "申請中のグループ",
                  myApplyingGroups?.length
                )}
                value="4"
                onClick={() => navigate("/groups")}
              />
              <Tab label="＋グループ作成" onClick={handleModalOpen} />
            </TabList>
          </Box>

          {/* すべてのグループ */}
          <TabPanel sx={{ width: "100%" }} value="1">
            <DisplayAllGroups search={search} groups={groups} />
          </TabPanel>
          {/* 参加中のグループ */}
          <TabPanel sx={{ width: "100%" }} value="2">
            <DisplayAllGroups search={search} groups={participatingGroups} />
          </TabPanel>
          <TabPanel sx={{ width: "100%" }} value="3">
            <DisplayAllGroups search={search} groups={myGroups} />
          </TabPanel>
          <TabPanel sx={{ width: "100%" }} value="4">
            <DisplayAllGroups search={search} groups={myApplyingGroups} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
});

export default memo(Groups);
