import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import {
  getDoc,
  doc,
  addDoc,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";

import HotelIcon from "@mui/icons-material/Hotel";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SpaIcon from "@mui/icons-material/Spa";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import HealingIcon from "@mui/icons-material/Healing";
import GetGeoPoint from "./GetGeoPoint";
import { LoadScript } from "@react-google-maps/api";

const EditAddressAndLatLng = ({ setOpen, target, requestId }) => {
  // ユーザ取得関連
  const [user, setUser] = useState("");
  // ユーザ取得とプロフィール選択オプション
  useEffect(() => {
    // ユーザ取得
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
      if (doSnap.exists) {
        setUser(doSnap.data());
      }
    });
  }, []);

  // console.log("target =>", target);
  // console.log("requestId =>", requestId);

  const [place, setPlace] = useState();

  // お店掲載申請コンテナ
  const [requestShopData, setRequestShopData] = useState({
    address: "",
    lat: "",
    lng: "",
    RequestShopError: "",
    RequestShopLoading: "",
  });
  const { address, lat, lng, RequestShopError, RequestShopLoading } =
    requestShopData;

  // console.log("requestShopData =>", requestShopData);

  // console.log("address =>",address)
  const changeLocationName = (event) => {
    // console.log("event =>", event.target.value);
    const newAddress = event.target.value;
    // setRequestShopData({ ...requestShopData, address: newAddress });
    // setPlace(newAddress);
    geocode(newAddress);
  };

  const geocode = (place) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: place }, (results, status) => {
      // console.log("results =>", results);
      if (status === "OK") {
        setRequestShopData({
          ...requestShopData,
          address: place,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      }
    });
  };

  // console.log("requestShopData =>", requestShopData);

  // お店掲載申請値変更
  const handleChangeShop = (e) => {
    setRequestShopData({
      ...requestShopData,
      [e.target.name]: e.target.value,
    });
  };

  // お店掲載申請処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setRequestShopData({
      ...requestShopData,
      RequestShopError: "",
      RequestShopLoading: true,
    });

    try {
      // 登録
      const docRef = await updateDoc(doc(db, target, requestId), {
        address,
        lat,
        lng,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        updator: auth.currentUser.uid,
      });
      setRequestShopData({
        ...requestShopData,
        RequestShopError: null,
      });
      await window.location.reload();
      setOpen(false);
    } catch (err) {
      setRequestShopData({
        ...requestShopData,
        RequestShopError: err.message,
        RequestShopLoading: false,
      });
    }
  };
  // お店掲載申請関連
  const [fromProvider, setFromProvider] = useState(false);

// console.log("address =>",address)
// console.log("target =>",target)

  return (
    <>
      <div className=" border rounded-md p-3 hover:cursor-pointer hover:border-blue-500 shadow-md hover:shadow-2xl">
        <div className="mb-3 text-xl font-bold ">住所変更</div>
        <form onSubmit={setRequestDataShop}>
          {/* お店の住所 */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="address"
              label="住所"
              defaultValue=""
              placeholder=""
              size="small"
              onChange={(event) => changeLocationName(event)}
              // onBlur={addressOnBlur}
              required
              className="w-full my-4"
              disabled={fromProvider}
            ></TextField>
          </div>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          >
            {/* 経度 */}
            <div className="my-4">
              <TextField
                id="outlined-required"
                label="経度"
                value={lng}
                size="small"
                className="w-full my-4"
                disabled
              ></TextField>
            </div>
            <div className="my-4">
              <TextField
                id="outlined-required"
                label="緯度"
                value={lat}
                size="small"
                onChange={changeLocationName}
                disabled
              ></TextField>
            </div>
          </LoadScript>

          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={RequestShopLoading}
          >
            {RequestShopLoading ? "送信しています..." : "変更"}
          </button>
          <div className="text-xs text-red-500 my-2">
            注）この操作を行うと、住所と経度と緯度がすべて更新されます。
          </div>
          <div className="text-xs text-red-500 my-2">
            尚、経度と緯度が異なる時は、別途変更画面から変更できます。
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddressAndLatLng;
