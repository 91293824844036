import React from "react";
import AddComponent from "./AddComponent";
import EventTable from "./EventTable";
import GroupTable from "./GroupTable";

const OperateEvent = () => {
  return (
    <>
      <div className="flex flex-col">
        <AddComponent value="運営のイベントを作成" target="addEvent" />
        <EventTable />
      </div>
    </>
  );
};

export default OperateEvent;
