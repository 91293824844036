import React from "react";

import HeaderContainerMixIN from "../components/HeaderContainerMixIN";
import DdpsContainer from "./DdpsContainer";

const Ddps = () => {
  return (
    <>
      <HeaderContainerMixIN outlet={<DdpsContainer />} />
    </>
  );
};

export default Ddps;
