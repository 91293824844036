import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import TablePagination from "@mui/material/TablePagination";
import DisplayDeal from "./DisplayDeal";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";
import { memo } from "react";

const DealsList = memo(({ uid, tab }) => {
  // deal情報取得

  // アクティブな取引関連
  const buyQuery = query(
    collection(db, "deal"),
    where("buy", "==", uid),
    where("status", "not-in", ["buyCanceled", "buyerCanceled", "finished"])
  );
  const buyerQuery = query(
    collection(db, "deal"),
    where("buyer", "==", uid),
    where("status", "not-in", ["buyCanceled", "buyerCanceled", "finished"])
  );
  const [myActiveDeals, setMyActiveDeals] = useState([]);
  useEffect(() => {
    let arr = [];
    const getMyActiveDeals = async () => {
      await getDocs(buyerQuery).then((docs) => {
        docs.forEach((deal) => arr.push({ id: deal.id, ...deal.data() }));
      });
      await getDocs(buyQuery).then((docs) => {
        docs.forEach((deal) => arr.push({ id: deal.id, ...deal.data() }));
      });

      setMyActiveDeals(
        arr.sort((a, b) => {
          if (a.lastUpdatedAt > b.lastUpdatedAt) return -1;
          if (a.lastUpdatedAt < b.lastUpdatedAt) return 1;
          return 0;
        })
      );
    };
    getMyActiveDeals();
  }, []);

  // 終了した取引関連
  const finishBuyQuery = query(
    collection(db, "deal"),
    where("buy", "==", uid),
    where("status", "in", ["buyCanceled", "buyerCanceled", "finished"])
  );
  const finishBuyerQuery = query(
    collection(db, "deal"),
    where("buyer", "==", uid),
    where("status", "in", ["buyCanceled", "buyerCanceled", "finished"])
  );
  const [myFinishedDeals, setMyFinishedDeals] = useState([]);
  useEffect(() => {
    let arr = [];
    const getMyFinishedDeals = async () => {
      await getDocs(finishBuyQuery).then((docs) => {
        docs.forEach((deal) => arr.push({ id: deal.id, ...deal.data() }));
      });
      await getDocs(finishBuyerQuery).then((docs) => {
        docs.forEach((deal) => arr.push({ id: deal.id, ...deal.data() }));
      });
      setMyFinishedDeals(
        arr.sort((a, b) => {
          if (a.lastUpdatedAt > b.lastUpdatedAt) return -1;
          if (a.lastUpdatedAt < b.lastUpdatedAt) return 1;
          return 0;
        })
      );
    };
    getMyFinishedDeals();
  }, []);

  // pagenation関連
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [pageX, setPageX] = React.useState(0);
  const [rowsPerPageX, setRowsPerPageX] = useState(20);
  const handleChangePageX = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPageX = (event) => {
    setRowsPerPageX(+event.target.value);
    setPage(0);
  };
  const [activeValue, setActiveValue] = React.useState("1");
  const handleChangeActive = (event, newValue) => {
    setActiveValue(newValue);
  };

  return (
    <>
      <TabContext value={activeValue}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TabList
            onChange={handleChangeActive}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="アクティブな取引" value="1" />
            <Tab label="終了した取引" value="2" />
          </TabList>
          {/* アクティブな取引 */}
          <TabPanel value="1" sx={{ padding: 0 }}>
            <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3   gap-2  my-1">
              {myActiveDeals?.size === 0 && <div>取引がありません。</div>}
              {(rowsPerPage > 0
                ? myActiveDeals?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : myActiveDeals
              )?.map((deal, index) => {
                return (
                  <DisplayDeal
                    deal={deal}
                    id={deal?.id}
                    tab={tab}
                    key={Math.random()}
                  />
                  // <DisplayProduct product={product} key={index} id={product.id} />
                );
              })}
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={myActiveDeals?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              showFirstButton
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TabPanel>
          {/* 終了した取引 */}
          <TabPanel value="2" sx={{ padding: 0 }}>
            <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3   gap-2  my-1">
              {myFinishedDeals?.size === 0 && <div>取引がありません。</div>}
              {(rowsPerPageX > 0
                ? myFinishedDeals?.slice(
                    pageX * rowsPerPageX,
                    pageX * rowsPerPageX + rowsPerPageX
                  )
                : myFinishedDeals
              )?.map((deal, index) => {
                return (
                  <DisplayDeal deal={deal} id={deal?.id} key={Math.random()} />
                  // <DisplayProduct product={product} key={index} id={product.id} />
                );
              })}
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={myFinishedDeals?.length || 0}
              rowsPerPage={rowsPerPageX}
              page={pageX}
              showFirstButton
              onPageChange={handleChangePageX}
              onRowsPerPageChange={handleChangeRowsPerPageX}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
});

export default memo(DealsList);
