import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// 写真
import friends from "../data/img/frends.jpg";
import smilewWork from "../data/img/smileWork.jpg";

import clickAvatar from "../data/gif/explainDDPRegister/clickAvatar.gif";
import openFormRegisterDDP from "../data/gif/explainDDPRegister/openFormRegisterDDP.gif";
import inputDDPName from "../data/gif/explainDDPRegister/inputDDPName.gif";
import inputTypeAndTarget from "../data/gif/explainDDPRegister/inputTypeAndTarget.gif";
import inputRunru from "../data/gif/explainDDPRegister/inputRunru.gif";
import selectDelivery from "../data/gif/explainDDPRegister/selectDelivery.gif";
import inpuptExplain from "../data/gif/explainDDPRegister/inpuptExplain.gif";
import all from "../data/gif/explainDDPRegister/all.gif";

import { useAuth } from "../context/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import RegisterVillager from "./RegisterVillager";

export default function HowToRegisterDDP({ setOpen }) {
  // ユーザ取得
  const { user } = useAuth();
  const [userInfo, setUserInfo] = React.useState();
  React.useEffect(() => {
    const initUser = () => {
      getDoc(doc(db, "users", user.uid)).then((doc) => {
        setUserInfo(doc.data());
      });
    };
    initUser();
  }, [user]);

  const createStep = (label, explain, img, link) => {
    return {
      label: <div className="font-bold text-2xl">{label}</div>,
      description: (
        <div className="flex flex-col space-y-2 h-full">
          <img className="rounded-md w-fit h-72" src={img} alt="" />
          <div>
            {explain}
            {link}
          </div>
        </div>
      ),
    };
  };

  const steps = [
    createStep(
      "１．プロフィールを表示",
      <ul class="list-outside list-disc px-4">
        <li>上部メニューにあるアバターをクリック</li>
        <li>開かれたメニューの中に「プロフィール設定」をクリック</li>
      </ul>,
      //   "メニューのアバターをクリックしてメニューを開き、「プロフィール設定」をクリックします。",
      clickAvatar
    ),
    createStep(
      "2.DDP登録フォームを開く",
      <ul class="list-outside list-disc px-4">
        <li>左タブリストの中から「DDP」をクリック</li>
        <li>右に現れたタブリストの中から「提供するもの」をクリック</li>
        <li>「＋DDPを登録」ボタンをクリック</li>
      </ul>,
      openFormRegisterDDP
    ),

    createStep(
      "3.DDP名を入力",
      <ul class="list-outside list-disc px-4">
        <li>DDP登録フォームに必要項目を入力</li>
        <li>DDP名を入力</li>
      </ul>,

      inputDDPName
    ),

    createStep(
      "4.DDPのタイプと対象を入力",
      <ul class="list-outside list-disc px-4">
        <li>「タイプ」と「対象」を入力</li>
        <li>「対象」は「タイプ」によって値が変わります</li>
        <li>
          登録してほしい「タイプ」等があればチャットで事務局に連絡してください
        </li>
      </ul>,
      inputTypeAndTarget
    ),

    createStep(
      "5.ルンルを入力",
      <ul class="list-outside list-disc px-4">
        <li>交換するルンルの値を入力</li>
      </ul>,
      inputRunru
    ),
    createStep(
      "6.DDPの提供方法を入力",
      <ul class="list-outside list-disc px-4">
        <li>「受け渡し方法」を選択します</li>
        <li>DDPがモノである場合、「配送」を選択</li>
        <li>DDPがモノでも、手渡しで渡したい場合などは「その他」を選択</li>
      </ul>,
      selectDelivery
    ),
    createStep(
      "6.DDPの提供方法を入力",
      <ul class="list-outside list-disc px-4">
        <li>
          口頭やオンライン上で提供できる場合は「オンラインを介して提供」を選択
        </li>
      </ul>,
      selectDelivery
    ),
    createStep(
      "7.説明を入力して登録",
      <ul class="list-outside list-disc px-4">
        <li>説明を入力</li>
        <li>「登録」ボタンをクリック</li>
        <li>完了</li>
      </ul>,
      inpuptExplain
    ),
    createStep(
        "8.登録すると一覧に表示されます",
        <ul class="list-outside list-disc px-4">
          <li>登録が終了すると一覧に表示されます</li>
        </ul>,  
      all,
    //   <RegisterVillager />
    ),
  ];

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const updateFirstLoginInfoComplete = async () => {
    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      firstLoginInfoComplete: true,
    });
    setOpen(false);
  };

  const moveToSekaimura = () => {
    window.open("https://www.sekaimura.com/", "_blank");
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <div className="w-full">{steps[activeStep].label}</div>
      </Paper>
      <Box sx={{ height: 255, maxWidth: 400, width: "100%", p: 2 }}>
        {steps[activeStep].description}
      </Box>
      <div className="my-32"></div>
      <MobileStepper
        sx={{ marginTop: "20px" }}
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            // disabled={activeStep === maxSteps - 1}
          >
            {activeStep !== maxSteps - 1 ? (
              "次へ"
            ) : (
              <div className="text-gray-300" disabled>
                次へ
              </div>
            )}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : activeStep !== maxSteps - 1 ? (
              <KeyboardArrowRight />
            ) : (
              ""
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            前へ
          </Button>
        }
      />
    </Box>
  );
}
