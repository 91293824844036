import React from "react";
import { useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db, auth, storage } from "../api/firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";

const MyPage = () => {
  const { id } = useParams();
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  return (
    <>
      <div>バックイメージ</div>
      <div>プロフィールイメージ</div>
      <div>自己紹介</div>
      <div>DDP</div>
      <div>気になる人に追加ボタン</div>
      <div>メッセンジャーを開始するボタン</div>      
      <div>アニマルロック</div>
      <div>所属拠点</div>
    </>
  );
};

export default MyPage;
