import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../api/firebase";
import RecommendedSpotInfo from "./RecommendedSpotInfo";

const RecommendedSpotList = () => {
  const [recomendedSpot, setRecomendedSpot] = useState("");

  useEffect(() => {
    const init = async () => {
      const shopsQuery = query(
        collection(db, "recommendedSpot"),
        orderBy("createdAt", "desc")
      );
      const recomendedShopList = await getDocs(shopsQuery);

      if (!recomendedShopList.empty) {
        let arr = [];
        recomendedShopList?.forEach((elm) =>
          arr.push({ id: elm.id, ...elm.data() })
        );
        setRecomendedSpot(arr);
      }
    };
    init();
  }, []);

  //   console.log("recomendedSpot =>", recomendedSpot);

  return (
    <>
      <div>
        {recomendedSpot &&
          recomendedSpot?.map((elm) => (
            <div key={Math.random()}>
              <RecommendedSpotInfo spot={elm} />
            </div>
          ))}
      </div>
    </>
  );
};

export default RecommendedSpotList;
