import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button, Divider, TextareaAutosize } from "@mui/material";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { Avatar } from "@mui/material";

// redux - use
import { useNotification } from "../redux/useNotification";
import { useCollection } from "react-firebase-hooks/firestore";

const UpdateQuestionForAdmin = ({ setOpen, target }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    content: "",
    result: "",
    error: null,
    loading: false,
  });
  const { content, result, error, loading } = data;

  // 自分の情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const getUser = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    getUser();
  }, []);

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  const [resultListCollection] = useCollection(
    collection(db, "inquiryResultType")
  );
  const resultList = resultListCollection?.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });

  // history取得
  const [inquiryHistory] = useCollection(
    collection(db, "questionForAdmin", target, "history")
  );
  const history = inquiryHistory?.docs?.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });

  history?.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  });

  // Tab関連
  const [value, setValue] = React.useState("1");
  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      // console.log("data[e.target.name] =>", data[e.target.name]);
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await addDoc(collection(db, "questionForAdmin", target, "history"), {
        createdAt: serverTimestamp(),
        updator: userInfo?.uid,
        updatorName: userInfo?.lastName + userInfo?.firstName,
        updatorAvatar: userInfo?.avatar,
        content,
      });

      await updateDoc(doc(db, "questionForAdmin", target), {
        lastUpdatedAt: serverTimestamp(),
      });

      displayNotification({
        message: "問い合わせを更新しました。",
        type: "success",
      });

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };

  const handleResultSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      // console.log("data[e.target.name] =>", data[e.target.name]);
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      await updateDoc(doc(db, "questionForAdmin", target), {
        lastUpdatedAt: serverTimestamp(),
        isResolution: true,
        result,
        respondentAt: serverTimestamp(),
        respondentAvtar: userInfo?.avatar,
        respondentId: userInfo?.uid,
        respondentName: userInfo?.lastName + userInfo?.firstName,
      });

      displayNotification({
        message: "問い合わせを解決しました。",
        type: "success",
      });

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };

  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  return (
    <>
      <div className="flex w-full">
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TabList
              onChange={handleChangeValue}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="更新・結果入力" value="1" />
              <Tab label="履歴" value="2" />
            </TabList>
            {/* DDP */}
            <TabPanel value="1" sx={{ padding: 0 }}>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="my-3 bg-green-600 p-1 text-white w-fit">
                  問い合わせ履歴追加
                </div>
                <TextareaAutosize
                  id="outlined-required"
                  name="content"
                  label="イベントへの質問"
                  defaultValue=""
                  minRows={3}
                  maxRows={5}
                  placeholder="例）チャットで直接問い合わせ内容を聞き、〇〇を△△して対処しました。"
                  className=" w-full border border-blue-500 rounded-sm p-2"
                  onChange={handleChange}
                  required
                  disabled={loading}
                />

                <Button
                  className="btn"
                  name="content"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "送信しています..." : "送信"}
                </Button>
                {error ? error : ""}
              </form>
              <Divider className="mb-2">または</Divider>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-3 bg-green-600 p-1 text-white w-fit">
                  結果を選択する
                </div>
                <TextField
                  id="demo-simple-select"
                  name="result"
                  label="結果"
                  placeholder={target?.type}
                  defaultValue=""
                  className="w-full"
                  sx={{ marginTop: 2 }}
                  size="small"
                  onChange={handleChange}
                  select
                >
                  {resultList?.map((result) => (
                    <MenuItem key={Math.random()} value={result.result} dense>
                      {result.result}
                    </MenuItem>
                  ))}
                </TextField>
                <div className="my-2"></div>
                <Button
                  variant="contained"
                  name="result"
                  onClick={handleResultSubmit}
                  disabled={loading}
                >
                  {loading ? "送信しています..." : "解決"}
                </Button>
                {error ? error : ""}
              </form>
            </TabPanel>
            {/* 履歴 */}
            <TabPanel value="2" sx={{ padding: 0 }}>
              <div className=" my-2 bg-green-600 p-1 text-white w-fit">
                履歴
              </div>
              <div className=" h-80 overflow-y-auto">
                {history?.map((elm) => (
                  <>
                    <div className="border rounded-md p-2 my-2">
                      <div className=" text-blue-500 text-xs">
                        {isInvalidDate(new Date(elm?.createdAt?.toDate()))
                          ? "読み込み中"
                          : formatDistanceToNow(elm?.createdAt?.toDate(), {
                              locale: ja,
                            })}
                      </div>

                      <div className="flex flex-col items-center justify-center">
                        <div>
                          <Avatar src={elm.updatorAvatar} sizes="small" />
                        </div>
                        <div>{elm.updatorName}</div>
                      </div>
                      <div className="border bg-blue-500 text-white rounded-md p-1">
                        {elm.content}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </TabPanel>
          </Box>
        </TabContext>
      </div>
    </>
  );
};

export default UpdateQuestionForAdmin;
