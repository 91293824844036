import React, { useEffect, useRef } from "react";
import TuiCalendar,{initializeCalendar} from "tui-calendar";
import "tui-calendar/dist/tui-calendar.css";

// MUI Iconsのインポート
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const RunruCalendar = ({ events }) => {
  const calendarRef = useRef(null);
  let calendar = null;

  useEffect(() => {
    if (calendarRef.current && !calendar) {
      calendar = new TuiCalendar(calendarRef.current, {
        defaultView: "month",
        taskView: false,
        scheduleView: true,
        useCreationPopup: false,
        useDetailPopup: true,
        isReadOnly: true,
        template: {
          monthDayname: function(dayname) {
            return '<span class="calendar-dayname">' + dayname.label + '</span>';
          }
        },
        header: {
          left: 'today prev,next',
          center: 'title',  // ここで月と年を表示
          right: 'month,week,day'
        }
      });

      calendar.createSchedules(events);

    }

    return () => {
      if (calendar) {
        calendar.destroy();
      }
    };
  }, [events,calendarRef]);

  const moveToday = () => {
    calendar.today();
  };

  const moveNext = () => {
    calendar.next();
  };

  const movePrev = () => {
    calendar.prev();
  };

  return (
    <div>
      <div className="flex  items-center mb-4">
        <ArrowBackIosIcon onClick={movePrev} className="cursor-pointer" />
        <div
          onClick={moveToday}
          className="bg-blue-500 text-white py-2 px-4 rounded-full cursor-pointer"
        >
          今日
        </div>
        <ArrowForwardIosIcon onClick={moveNext} className="cursor-pointer ml-1" />
      </div>
      <div ref={calendarRef} />

    </div>
  );
};

export default RunruCalendar;
