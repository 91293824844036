import React from "react";

const AIResponse = ({ response }) => {
  if (!response) return null;

  console.log("response =>", response);
  return (
    <div className="my-2">
        <div className=" whitespace-pre-wrap">{response}</div>
    </div>
  );
};

export default AIResponse;
