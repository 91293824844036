import React, {  useState } from "react";
import {  doc, deleteDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

const DeleteRequestShop = ({ setOpen, requestId }) => {
  const [targetRequestShop] = useDocumentData(
    doc(db, "requestShop", requestId)
  );

  const setRequestDataShop = () => {};
  const [requestShopLoading, setRequestShopLoading] = useState(false);
  const [error, setError] = useState("");
  //   削除処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setRequestShopLoading(true);
    try {
      // 削除
      await deleteDoc(doc(db, "requestShop", requestId));
      setOpen(false);
      // window.location.reload();
    } catch (err) {
      setRequestShopLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        村民さんのお店削除
      </div>
      <form onSubmit={setRequestDataShop}>
        <div className="font-bold text-2xl">
          この村民さんのお店のリクエストを削除してよろしいですか？
        </div>
        <div className="border-2 p-2 rounded-md mb-2">
          <div className="text-gray-300">村民さん</div>
          <div>{targetRequestShop?.owner}</div>
          <div className="text-gray-300">ショップ名</div>
          <div>{targetRequestShop?.name}</div>
          <div className="text-gray-300">住所</div>
          <div>{targetRequestShop?.address}</div>
          <div className="text-gray-300">電話番号</div>
          <div>{targetRequestShop?.tel}</div>
          <div className="text-gray-300">URL</div>
          <div>{targetRequestShop?.url}</div>
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={requestShopLoading}
          onClick={handleDelete}
        >
          {requestShopLoading ? "削除しています..." : "削除"}
        </button>
        {error ? error :""}
      </form>
    </>
  );
};

export default DeleteRequestShop;
