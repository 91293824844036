// StreamChatContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { auth, db } from "../api/firebase";
import { doc, getDoc } from "firebase/firestore";
export const StreamChatContext = createContext(null);

export const StreamChatProvider = ({ children }) => {
  const [chatClient, setChatClient] = useState(null);

  useEffect(() => {
    const connectChatClient = async (user) => {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        console.error("User document does not exist in Firestore");
        return;
      }
      const userData = userDocSnap.data();

      // トークン取得とユーザー接続のロジックをここに移動
      //   トークン取得
      const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: userData.uid,
        }),
      }).then((r) => r.json());

      if (!chatClient || chatClient.userID !== userData.uid) {
        const client = StreamChat.getInstance(
          process.env.REACT_APP_STREAM_API_KEY
        );
        await client.connectUser(
          {
            id: userData.uid,
            name: userData.lastName + userData.firstName,
            image: userData.avatar,
          },
          token
        );
        setChatClient(client);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) connectChatClient(user);
    });

    return () => {
      unsubscribe();
      chatClient?.disconnectUser().then(() => setChatClient(null));
    };
  }, [chatClient]);

  return (
    <StreamChatContext.Provider value={chatClient}>
      {children}
    </StreamChatContext.Provider>
  );
};

export const useStreamChatClient = () => useContext(StreamChatContext);
