import React, { useState } from "react";
import {
  doc,
  updateDoc,
  collection,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";

const EditGroupExplain = ({ setOpen, target }) => {
  const [group] = useDocumentData(doc(db, "group", target?.id));
  console.log("target =>", target);
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    explain: "",
    message: "",
    error: null,
    loading: false,
  });
  const { explain, message, error, loading } = data;

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "group", target?.id), {
        explain,
      });

      // group updates追加
      const updatesRef = collection(db, "group", target?.id, "updates");
      const updatesData = {
        createdAt: serverTimestamp(),
        title: "グループ説明変更",
        information:
          user?.lastName +
          user?.firstName +
          "がグループ[" +
          group?.title +
          "]の説明を変更しました。",
        creator: auth.currentUser.uid,
        creatorAvatar: user?.avatar,
      };

      await addDoc(updatesRef, updatesData);

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };

  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">グループの説明</div>
        <TextField
          id="outlined-required"
          name="explain"
          label="グループの説明"
          defaultValue=""
          size="small"
          placeholder={group?.explain}
          className=" w-full "
          onChange={handleChange}
          required
          disabled={loading}
        />

        <Button
          className="btn"
          name="explain"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditGroupExplain;
