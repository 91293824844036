import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, orderBy, query, getDoc, doc } from "firebase/firestore";
import { db } from "../api/firebase";

export default function ImagesOnMap({ targetCollection, shopId,noOption }) {
  const [documents, loading, error] = useCollection(
    query(
      collection(db, `${targetCollection}/${shopId}/images/`),
      orderBy("createdAt", "desc")
    )
  );
  const imagelist = documents?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const isMobile = useMediaQuery("(min-width:769px)");




  return (
    <>
      <ImageList variant="masonry" cols={isMobile ? 2 : 1} gap={8}>
        {imagelist?.map((image, idx) => (
          <ImageListItem>
            <img src={image?.imageURL} alt="" />
            <Typography
              variant="body2"
              component="span"
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                color: "white",
                background: "rgba(0,0,0, .3)",
                p: "5px",
                borderTopRightRadius: 8,
              }}
            >
              {moment(image?.createdAt?.toDate()).fromNow()}
            </Typography>
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}
