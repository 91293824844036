import React, { useState } from "react";

// MaterialUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// redux - use
import { useNotification } from "../redux/useNotification";

// firebase
import { doc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
// streamChat
import { StreamChat } from "stream-chat";

const CreateBase = ({ setOpen }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  // 拠点入力データコンテナ
  const [data, setData] = useState({
    title: "",
    prefecture: "",
    error: "",
    loading: "",
  });
  const { title, prefecture, error, loading } = data;

  const places = [
    { id: 2, place: "沖縄県" },
    { id: 3, place: "北海道" },
    { id: 4, place: "青森県" },
    { id: 5, place: "岩手県" },
    { id: 6, place: "宮城県" },
    { id: 7, place: "秋田県" },
    { id: 8, place: "山形県" },
    { id: 9, place: "福島県" },
    { id: 10, place: "茨城県" },
    { id: 11, place: "栃木県" },
    { id: 12, place: "群馬県" },
    { id: 13, place: "埼玉県" },
    { id: 14, place: "千葉県" },
    { id: 15, place: "東京都" },
    { id: 16, place: "神奈川県" },
    { id: 17, place: "新潟県" },
    { id: 18, place: "富山県" },
    { id: 19, place: "石川県" },
    { id: 20, place: "福井県" },
    { id: 21, place: "山梨県" },
    { id: 22, place: "長野県" },
    { id: 23, place: "岐阜県" },
    { id: 24, place: "静岡県" },
    { id: 25, place: "愛知県" },
    { id: 26, place: "三重県" },
    { id: 27, place: "滋賀県" },
    { id: 28, place: "京都府" },
    { id: 29, place: "大阪府" },
    { id: 30, place: "兵庫県" },
    { id: 31, place: "奈良県" },
    { id: 32, place: "和歌山県" },
    { id: 33, place: "鳥取県" },
    { id: 34, place: "島根県" },
    { id: 35, place: "岡山県" },
    { id: 36, place: "広島県" },
    { id: 37, place: "山口県" },
    { id: 38, place: "徳島県" },
    { id: 39, place: "香川県" },
    { id: 40, place: "愛媛県" },
    { id: 41, place: "高知県" },
    { id: 42, place: "福岡県" },
    { id: 43, place: "佐賀県" },
    { id: 44, place: "長崎県" },
    { id: 45, place: "熊本県" },
    { id: 46, place: "大分県" },
    { id: 47, place: "宮崎県" },
    { id: 48, place: "鹿児島県" },
    { id: 48, place: "海外" },

    // { id: 3, place: "両方" },
  ];

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // 拠点入力データ変更処理
  const handleChange = async (e) => {
    // if (e.target.name === "chief") {
    //   const user = await getUser(e.target.value);
    //   setData({
    //     ...data,
    //     chiefAvatar: user.data().avatar,
    //     uid: user.data().uid,
    //     chiefFirstName: user.data().firstName,
    //     chiefLastName: user.data().lastName,
    //     // chiefName: user.data().lastName + user.data().firstName,
    //   });
    //   return;
    // }
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  // 拠点作成処理
  const createBase = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });
    try {
      // 変更処理
      const docRef = await addDoc(collection(db, "base"), {
        title,
        prefecture,
        isPublish: false,
        doMakeEventsEveryOne:true,
        releaseAddress:false,
        creator: auth.currentUser.uid,
        createdAt: serverTimestamp(),
        lastUpdatedAt: serverTimestamp(),
      });

      // チャット作成
      const userToConnect = {
        id: user.uid,
        name: user.lastName + user.firstName,
        image: user.avatar,
        langage: "jp",
      };
      const apiKey = process.env.REACT_APP_STREAM_API_KEY;
      const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
      //   トークン取得
      const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: user.uid,
        }),
      }).then((r) => r.json());
      await client.connectUser(userToConnect, token);
      const channel = client.channel("messaging", "baseChat-" + docRef.id, {
        name: title,
      });
      await channel.create();
      channel.addMembers([user.uid]);
      // await channel.mute();

      setData({
        ...data,
        error: null,
      });
      displayNotification({
        message: "拠点作成、完了しました。",
        type: "success",
      });

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err.message);
      setData({
        ...data,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div className="mb-3">拠点作成</div>
      <form onSubmit={createBase}>
        {/* 名前 */}
        <TextField
          id="outlined-required"
          name="title"
          label="拠点名"
          defaultValue=""
          size="small"
          placeholder="〇〇県東"
          className=" w-full"
          onChange={handleChange}
          required
        />

        {/* 県 */}
        <TextField
          id="demo-simple-select"
          name="prefecture"
          label="県"
          defaultValue=""
          className="w-full"
          sx={{ marginTop: 2 }}
          size="small"
          onChange={handleChange}
          select
        >
          {places?.map((prefecture) => (
            <MenuItem
              dense
              key={prefecture.place}
              value={
                prefecture.place
                // villager.data().lastName + " " + villager.data().firstName
              }
            >
              {prefecture.place}
            </MenuItem>
          ))}
        </TextField>

        <div className="my-4" />

        {/* 村長選択 */}
        {/* <TextField
          id="demo-simple-select"
          name="chief"
          label="村長"
          defaultValue=""
          className="w-full"
          sx={{ marginTop: 2 }}
          size="small"
          onChange={handleChange}
          select
        >
          {villagers?.map((villager) => (
            <MenuItem
            dense
              key={villager.uid}
              value={
                villager.uid
                // villager.data().lastName + " " + villager.data().firstName
              }
            >
              {villager.lastName + " " + villager.firstName}
            </MenuItem>
          ))}
        </TextField> */}

        {/* 住所 */}
        {/* <div className="my-4">
          <TextField
            id="outlined-required"
            name="address"
            label="住所"
            defaultValue=""
            placeholder=""
            size="small"
            onChange={handleChange}
            required
            className="w-full my-4"
          ></TextField>
        </div> */}

        {/* 電話番号 */}
        {/* <div className="my-4">
          <TextField
            id="outlined-required"
            name="tel"
            type="number"
            label="電話番号 -(ハイフン)不要"
            defaultValue=""
            placeholder=""
            size="small"
            onChange={handleChange}
            className="w-full my-4"
          ></TextField>
        </div> */}

        {/* 送信ボタン */}
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
        >
          {loading ? "送信しています..." : "作成"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default CreateBase;
