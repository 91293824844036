import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import PlayMovie from "./PlayMovie";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../api/firebase";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import Box from "@mui/material/Box";
import SwitchComponent from "../components/SwitchComponent";
import { Button, TextareaAutosize, Tooltip } from "@mui/material";
import { useAuth } from "../context/auth";

import DisplaySignUp from "./DisplaySignUp";
import RegisterVillager from "./RegisterVillager";
import StreamChatOnMovie from "./StreamChatOnMovie";

const Movie = () => {
  const { id } = useParams();
  // ムービー情報取得
  const [movie, setMovie] = useState("");
  useEffect(() => {
    const init = () => {
      getDoc(doc(db, "movie", id)).then((docRef) => {
        setMovie({ id: docRef.id, ...docRef.data() });
      });
    };
    init();
  }, []);

  const docRef = doc(db, "movie", id);
  // 編集フォームの変更を処理する処理
  const handleEdit = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(null);
  // データの編集を保存する処理
  const saveData = async (e) => {
    console.log("editedData =>", editedData);
    if (!editedData) {
      setEditMode(false);
      return;
    }

    if (editedData[e.target.name].length === 0) {
      setEditMode(false);
      return;
    }

    await updateDoc(docRef, {
      [e.target.name]: editedData[e.target.name],
    });
    // setEditMode(false);
    window.location.reload();
  };
  // 編集モードに入る処理
  const enterEditMode = () => {
    setEditMode(true);
    // setEditedData(data);
  };

  const { user } = useAuth();

  // ユーザ情報取得
  const [userInfo, setUser] = useState("");
  useEffect(() => {
    const init = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((docRef) => {
        setUser({ id: docRef.id, ...docRef.data() });
      });
    };
    user && init();
  }, []);

  // オーサーかどうか
  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const init = () => {
      if (userInfo?.uid === movie?.creator) {
        setIsAuthor(true);
      }
    };
    user && userInfo && init();
  }, [userInfo, movie]);

  //   console.log("userInfo =>",userInfo)
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const init = () => {
      if (userInfo?.isAdmin) {
        setIsAdmin(true);
      }
    };
    userInfo && init();
  }, [userInfo]);

  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ref = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);

  const [md, setMd] = useState(false);
  useEffect(() => {
    const init = () => {
      if (width + 143 > 1024) {
        setMd(true);
      }
    };
    width > 0 && init();
  }, [width]);

  //   console.log("isAdmin =>",isAdmin)

  // console.log("movie =>", movie);

  if (!user) {
    if (
      movie?.openRange === "forVillagers" ||
      movie?.openRange === "forUsersOver"
    ) {
      return <div>閲覧権限がありません。</div>;
    }
  }

  if (user) {
    if (!userInfo?.isVillager && movie?.openRange === "forVillagers") {
      return <div>閲覧権限がありません。</div>;
    }
  }

  return (
    <>
      <div ref={ref}>
        {/* 左映像 */}
        <div className="grid grid-cols-5 gap-2">
          <div className="col-span-5 lg:col-span-4 h-fit rounded-md ">
            {/* 動画 */}
            <PlayMovie movieDoc={movie} user={user} isAuthor={isAuthor} />
            {/* チャンネル情報 */}
            <div></div>
            {/* コンテンツ */}
            <div className="md:px-5 rounded-md w-[calc(100%)] h-fit">
              <TabContext value={value}>
                {/* 左リスト */}

                <Box
                  sx={{
                    maxWidth: { xs: 375, sm: 1080 },
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                  >
                    <Tab label="概要" value="1" />
                    <Tab label="コメント" value="2" />
                    <Tab label="動画" value="3" />
                    {(isAdmin || isAuthor) && <Tab label="設定" value="4" />}
                  </TabList>
                </Box>

                {/* 概要 */}
                <TabPanel sx={{ width: "100%" }} value="1">
                  <div className="grid grid-cols-1">
                    <div className=" col-span-1 whitespace-pre-wrap break-words h-fit">
                      {movie?.explain}
                    </div>
                  </div>
                </TabPanel>

                {/* Chat */}
                <TabPanel sx={{ width: "100%", minHeight: "720px" }} value="2">
                  {!user ? (
                    <DisplaySignUp />
                  ) : userInfo?.isVillager ? (
                    <StreamChatOnMovie
                      user={userInfo}
                      id={id}
                      // isMember={isMember}
                    />
                  ) : (
                    <RegisterVillager />
                  )}
                </TabPanel>

                {/* 設定 */}
                <TabPanel
                  sx={{ width: "100%", height: "fit-content" }}
                  value="4"
                >
                  <div className="grid grid-cols-12">
                    <div className="col-span-2 flex justify-center items-center font-bold text-xl">
                      表示
                    </div>
                    <div className="col-span-10">
                      <SwitchComponent
                        id={id}
                        target="isPublish"
                        collection="movie"
                        text="表示"
                      />
                    </div>
                  </div>
                  {/* title */}
                  <EditableContent
                    target={movie?.title}
                    name="title"
                    editMode={editMode}
                    user={userInfo}
                    creator={movie?.creator}
                    handleEdit={handleEdit}
                    saveData={saveData}
                    enterEditMode={enterEditMode}
                  />
                  <div className="my-3"></div>
                  {/* 説明 */}
                  <EditableContent
                    target={movie?.explain}
                    name="explain"
                    editMode={editMode}
                    user={userInfo}
                    creator={movie?.creator}
                    handleEdit={handleEdit}
                    saveData={saveData}
                    enterEditMode={enterEditMode}
                  />
                </TabPanel>
              </TabContext>
            </div>
          </div>
          {/* 右 動画リスト */}
          <div className="hidden lg:block lg:col-span-1 h-fit border rounded-md bg-white"></div>
        </div>
      </div>
    </>
  );
};

export default Movie;

const EditableContent = (props) => {
  const {
    target,
    name,
    editMode,
    user,
    creator,
    handleEdit,
    saveData,
    enterEditMode,
  } = props;

  return (
    <>
      {editMode && (user?.isAdmin || creator === auth.currentUser.uid) ? (
        <>
          <div>{name === "explain" ? "概要" : "タイトル"}</div>

          <div className="grid grid-cols-1 w-full space-x-2 items-center border-2 p-2 rounded-md ">
            <TextareaAutosize
              name={name}
              style={{ resize: "none", fontSize: "16px" }}
              onChange={handleEdit}
              placeholder={target}
              defaultValue={target}
              className=" p-1 w-full scrollbar-hide mb-5"
              minRows={3}
              maxRows={5}
            />

            <Button
              name={name}
              onClick={saveData}
              className="w-fit"
              variant="outlined"
            >
              決定
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="grid grid-cols-12">
            <div className="col-span-2 flex justify-center items-center font-bold text-xl">
              {name === "explain" ? "概要" : name}
            </div>
            <div className="col-span-10">
              <Tooltip title="クリックで編集">
                <div
                  onClick={enterEditMode}
                  className=" border-2 p-2 rounded-md  whitespace-pre-wrap break-words line-clamp-3"
                >
                  {target}
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </>
  );
};
