import React, { useState,useEffect } from "react";
import { doc, serverTimestamp, updateDoc,getDoc,addDoc,collection } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useAuth } from "../context/auth";
// redux - use
import { useNotification } from "../redux/useNotification";
import { useDocumentData } from "react-firebase-hooks/firestore";

// 受け取り連絡
// 買った人が、買われた人に「受け取ったよ」という連絡
// ので、買った人は自分 auth.currentUser.uid

const DealArrived = ({ setOpen, requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

    // 取引を取得
    const [deal, setDeal] = useState();
    useEffect(() => {
      const initGetDeal = async () => {
        getDoc(doc(db, "deal", requestId)).then((doc) => {
          setDeal(doc.data());
        });
      };
      requestId && initGetDeal();
    }, []);
  
    // 買った人を取得
    const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  

  const setRequestDataShop = () => {};
  const [requestShopLoading, setRequestShopLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = async (e) => {
    e.preventDefault();
    setRequestShopLoading(true);
    try {
      // ステータス更新
      await updateDoc(doc(db, "deal", requestId), {
        status: "arrived",
        lastUpdated: user?.uid,
        lastUpdatedAt:serverTimestamp(),


      });

      //   ルンル情報追加
      // 9.20の西田さんとのミーティング時、
      // 受取時じゃなく、リクエスト受諾時にルンルの交換がなされる方が良いとのことで
      // こちらの機能を廃止

      // await addDoc(collection(db, "runru"), {
      //   amount: deal?.data().amount,
      //   from: deal?.data().buy,
      //   to:deal?.data().buyer,
      //   cause: "DDP交換",
      //   createdAt: serverTimestamp(),
      // });

      // 通知情報登録 - 買った人が受け取った・・・なので
      // 買われた人に、「買った人がDDP受け取ったよ」という通知をする
      // 買われた人 deal.buyer 買った人 deal.buy=> auth.currentUser.uid
      // fromはbuy
      await addDoc(collection(db, "users", deal?.buyer, "notification"), {
        createdAt: serverTimestamp(),
        content: "DDP受け取り",
        fromAvatar: user?.avatar,
        fromId: user?.uid,
        fromName: user?.lastName + user?.firstName,
        isRecognition: false,
        target: "deal",
        targetId:requestId,
      });



      setOpen(false);
      displayNotification({
        message: "受取連絡完了しました。",
        type: "success",
      });

      // window.location.reload();
    } catch (err) {
      setRequestShopLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        受け取り連絡
      </div>
      <form onSubmit={setRequestDataShop}>
        <div className="font-bold text-2xl">
          受取完了を提供者に連絡しますか？
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={requestShopLoading}
          onClick={handleDelete}
        >
          {requestShopLoading ? "送信しています..." : "はい"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DealArrived;
