import { darkScrollbar } from "@mui/material";
import {
  collection,
  getDocs,
  query,
  limit,
  where,
  doc,
  orderBy,
  onSnapshot,
  getDoc,
  startAfter,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../api/firebase";
import DisplayNotificationContent from "./DisplayNotificationContent";

import EventNoteIcon from "@mui/icons-material/EventNote";
import { styled } from "@mui/material/styles";
import GroupsIcon from "@mui/icons-material/Groups";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import Badge from "@mui/material/Badge";

const AllNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationGetCount, setNotificationsGetCount] = useState(10);
  const [getCount, setGetCount] = useState(1);
  //   const [lastDoc, setLastDoc] = useState();

  const notificationRef = collection(
    db,
    "users",
    auth.currentUser.uid,
    "notification"
  );

  const q = query(
    notificationRef,
    limit(notificationGetCount * getCount),
    orderBy("createdAt", "desc")
  );

  useEffect(() => {
    const initGetMyNotifications = async () => {
      let arr = [];

      const snapshot = await getDocs(q);
      snapshot.docs.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setNotifications(arr);
      //   setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
    };
    initGetMyNotifications();
  }, [getCount]);

  //   useEffect(()=>{
  //     const init = async() =>{
  //         let arr = []
  //         const nextRef = await getDocs(
  //             query(
  //               notificationRef,
  //               orderBy("createdAt","desc"),
  //               startAfter(lastDoc),
  //               limit(3),
  //             )
  //           );
  //           nextRef.docs.forEach((doc) => {
  //             arr.push({ id: doc.id, ...doc.data() });
  //           });
  //           setNext(arr);

  //     }
  //     init()
  //   },[lastDoc])

  const moreLoadingNotification = async () => {
    //   console.log("もっと読み込む発動！");

    setGetCount((prev) => prev + 1);

    // let arr = [];
    // const nextRef = await getDocs(
    //   query(
    //     notificationRef,
    //     orderBy("createdAt", "desc"),
    //     startAfter(lastDoc),
    //     limit(3)
    //   )
    // );
    // nextRef.docs.forEach((doc) => {
    //   arr.push({ id: doc.id, ...doc.data() });
    // });
    // setNotifications(notifications.concat(arr));
    // setLastDoc(new Date(notifications[notifications.length - 1].createdAt));
  };

  // console.log("notifications.length =>", notifications.length);
  // console.log("notificationGetCount * getCount =>", notificationGetCount * getCount);
  // console.log("lastDoc =>", new Date(lastDoc?.data().createdAt.toDate()).toLocaleString())
  //   console.log("lastDoc =>", lastDoc);
  //   console.log("next =>", next);
  //   console.log("lastDoc=>",lastDoc?.data())

  // badge関連
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 2,
      top: 45,
    },
  }));

  const AvatarAndIcon = ({ doc }) => {
    const icons = {
      event: { icon: EventNoteIcon, color: "bg-green-700" },
      group: { icon: GroupsIcon, color: "bg-blue-700" },
      deal: { icon: VolunteerActivismIcon, color: "bg-red-700" },
    };

    const Icon = ({ Icon }) => {
      return (
        <>
          {Icon && (
            <Icon
              className={` ${
                icons[doc.target]?.color
              } " text-white  rounded-full p-1 "`}
              sx={{ width: 27, height: 27 }}
            />
          )}
        </>
      );
    };
    return (
      <>
        <StyledBadge badgeContent={<Icon Icon={icons[doc.target]?.icon} />}>
          <img
            src={doc.fromAvatar}
            alt=""
            className=" h-16 w-16 rounded-full border-white"
          />
        </StyledBadge>
      </>
    );
  };

  return (
    <>
      <div className="font-bold text-xl my-4">お知らせ</div>
      <div className="flex flex-col space-y-1 overflow-y-auto mb-4">
        {notifications?.map((doc) => (
          <div key={Math.random()}>
            {/* イメージとコンテンツ */}
            <div
              className={`${
                doc.isRecognition === false && "bg-gray-200 "
              } " flex rounded-md  p-2 items-center space-x-5  hover:cursor-pointer hover:bg-gray-100 shadow-sm hover:shadow-lg duration-150 "`}
              key={Math.random()}
            >
              <AvatarAndIcon doc={doc} />

              {/* <img
                src={doc.fromAvatar}
                alt=""
                className=" h-16 w-16 rounded-full border-white"
              /> */}
              <DisplayNotificationContent
                document={doc}
                notificationsTenth={notifications}
              />
            </div>
          </div>
        ))}
      </div>
      {notifications.length === notificationGetCount * getCount && (
        <div
          className="flex justify-end hover:fon-bold hover:cursor-pointer text-blue-500 underline "
          onClick={moreLoadingNotification}
        >
          もっと読み込む
        </div>
      )}
    </>
  );
};

export default AllNotifications;
