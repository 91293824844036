import React, { useState, useEffect } from "react";
import { highlightText } from "./highlightText"; // 仮定の関数
import Img from "../data/img/logo.png";

import {
  doc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../api/firebase";
import BaseMembersAvatar from "./BaseMembersAvatar";
import { Button } from "@mui/material";

const RenderBaseItem = ({ base, highlight }) => {
  const [baseMembers, setBaseMembers] = useState("");
  const [users, setUsers] = useState([]);

  //   拠点メンバー取得
  useEffect(() => {
    // Firebaseのデータベースクエリを実行して拠点参加状態を確認
    const checkMembership = async () => {
      try {
        const q = query(
          collection(db, `base/${base?.id}/members`),
          where("isMember", "==", true)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // baseMembersを更新
          setBaseMembers(querySnapshot.docs);

          // ユーザー情報を取得するためのuidのリストを作成
          const uidList = querySnapshot.docs.map((doc) => doc.data().uid);

          // ユーザー情報を取得
          const userPromises = uidList.map(async (uid) => {
            const userDoc = await getDoc(doc(db, "users", uid));
            return userDoc.data();
          });

          // ユーザー情報をまとめて取得し、usersを更新
          const userData = await Promise.all(userPromises);
          setUsers(userData);
        }
      } catch (error) {
        console.error("Firebaseクエリエラー:", error);
      }
    };

    checkMembership();
  }, [base?.id]);

  // ユーザープロフィールページへのナビゲーション関数
  const handleNavigate = (e) => {
    e.stopPropagation(); // ボタンクリック時の親要素へのイベント伝播を停止
    window.open("/base/" + base?.id);
  };

  return (
    <div className="border rounded overflow-hidden shadow-lg  my-2">
      <div className="flex items-start p-4">
        <img
          src={base.background || Img}
          alt={base.title}
          className="w-32 h-32 object-cover flex-none rounded-md mr-4"
        />
        <div className="flex-grow mt-4">
          <h3 className="text-lg font-bold text-gray-900">
            {highlight ? highlightText(base.title, highlight) : base.title}
          </h3>
          <p className="text-sm text-gray-600">@{base.prefecture}</p>
        </div>
        <Button onClick={handleNavigate} variant="outlined" size="small">
          もっと見る
        </Button>
      </div>
      {/* <p className="px-4 pb-4 text-gray-600">{base.description}</p> */}
      <div className="flex justify-start px-4 pb-4">
        <BaseMembersAvatar members={users} />
      </div>{" "}
    </div>
  );
};

export default RenderBaseItem;
