import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../api/firebase";
import Linkify from "linkify-react";



const EditPost = ({ requestId }) => {
  // post取得
  const [post, setPost] = useState([]);
  useEffect(() => {
    const init = () => {
      getDoc(doc(db, "post", requestId)).then((elm) =>
        setPost({ id: elm.id, ...elm.data() })
      );
    };
    requestId && init();
  }, []);


    const linkifyOptions = {
    className: "text-blue-400",
    target: "blank",
  };


  return (<>
     <div className=" max-w-3xl group mx-2">
 
        {/* メッセージ部 */}
        <div className=" p-2 group-hover:border-x-blue-500 duration-150 border-x whitespace-pre-wrap break-words line-clamp-6 text-gray-600 bg-white font-fancy">
          <Linkify options={linkifyOptions}>{post.message}</Linkify>
        </div>

        {/* イメージ */}
        <div className=" group-hover:border-x-blue-500 duration-150 border-x whitespace-pre-wrap break-words line-clamp-6 bg-white">
          {/* <DisplayPostImages post={post} key={post.id} /> */}
          {/* <img src={Img} className="w-full max-h-72" alt="" /> */}
        </div>


      </div>
   
  </>)
  
  
};

export default EditPost;
