import React from "react";
import RequestShopTable from "./RequestShopsTable";
import UserShopListOnAdminSetting from "./UserShopListOnAdminSetting";

const OperateVillagersShop = () => {
  return (
    <>
      <div className="text-gray-300">村民さんのお店</div>
      <div className="w-[97%]">
        <UserShopListOnAdminSetting />

        {/* <RequestShopTable /> */}
      </div>
    </>
  );
};

export default OperateVillagersShop;
