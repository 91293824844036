import React, { useState } from "react";
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";

import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";

// MaterialUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";

const EditGroupType = ({ setOpen, target }) => {
  // job選択関連
  // const [group] = useDocumentData(doc(db, "group", target?.id));
  const [groupTypes] = useCollectionData(collection(db, "groupType"));

  const { id } = useParams();
  const [group] = useDocumentData(doc(db, "group", id));

  const [creator] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    type: "",
    message: "",
    error: null,
    loading: false,
  });
  const { type, message, error, loading } = data;

  // プロフィール変更
  const handleChange = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });

    try {
      await updateDoc(doc(db, "group", target?.id), {
        type,
      });

      // グループのupdates作成・更新
      await addDoc(collection(db, "group", id, "updates"), {
        createdAt: serverTimestamp(),
        title: "グループタイプ変更",
        information:
          creator?.lastName +
          creator?.firstName +
          "さんがグループ[" +
          group?.title +
          "]のタイプを[" +
          type +
          "]に変更しました",
        creator: auth.currentUser.uid,
        creatorAvatar: creator?.avatar,
      });

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div>グループ種類</div>
        <TextField
          id="demo-simple-select"
          name="type"
          label="グループの種類"
          placeholder={target?.type}
          defaultValue=""
          className="w-full"
          sx={{ marginTop: 2 }}
          size="small"
          onChange={handleChange}
          select
        >
          {groupTypes?.map((type) => (
            <MenuItem key={Math.random()} value={type.groupType} dense>
              {type.groupType}
            </MenuItem>
          ))}
        </TextField>
        <Button
          className="btn"
          name="job"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditGroupType;
