import { Avatar } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { useState, useEffect, memo, useCallback } from "react";
import { useMemo } from "react";
import { db } from "../api/firebase";

const GetUserInfo = memo(({ uid, target, size }) => {
  const [user, setUser] = useState("");

  // useCallback(() => {
  //   if (typeof uid === "object") {
  //     getDoc(doc(db, "users", uid[0])).then((doc) => {
  //       setUser(doc.data());
  //     });
  //   } else {
  //     getDoc(doc(db, "users", uid)).then((doc) => {
  //       setUser(doc.data());
  //     });
  //   }
  // }, [uid]);

  useEffect(() => {
    const initUser = async () => {
      if (typeof uid === "object") {
        getDoc(doc(db, "users", uid[0])).then((doc) => {
          setUser(doc.data());
        });
      } else {
        getDoc(doc(db, "users", uid)).then((doc) => {
          setUser(doc.data());
        });
      }
    };
    uid && initUser();
  }, [uid]);

  if (target === "name") {
    return (
      <div>
        {user?.lastName}
        {user?.firstName}
      </div>
    );
  }

  if (target === "last") {
    return <div>{user?.lastName}</div>;
  }

  if (target === "first") {
    return <div>{user?.firstName}</div>;
  }

  if (target === "lineName") {
    return (
      <>
        {user?.lastName}
        {user?.firstName}
      </>
    );
  }

  if (user && target === "avatar") {
    // return userAvatar;
    return <Avatar src={user?.avatar} sx={{ width: size, height: size }} />;
  }

  return <div>{user?.[target]}</div>;
});

export default memo(GetUserInfo);
