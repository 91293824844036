import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    where,
  } from "firebase/firestore";
  import React, { useEffect, useState } from "react";
  import { db } from "../api/firebase";
  import DisplayPostComment from "./DisplayPostComment";
import DisplayPostCommentsPreview from "./DisoplayPostCommentsPreview";
  
  const PostCommentsPreview = ({ post }) => {
    // コメント取得
    const [comments, setComments] = useState([]);
    useEffect(() => {
      const init = async () => {
        let arr = [];
        const q = query(
          collection(db, "comment"),
          where("postId", "==", post?.id),
          where("isPublish", "==", true),
          orderBy("updatedAt", "desc"),
          limit(1)
        );
        await getDocs(q).then((docs) =>
          docs.forEach((elm) => arr.push({ id: elm.id, ...elm.data() }))
        );
        setComments(arr);
      };
      post && init();
    }, []);
  
    // console.log("comments =>", comments);
  
    return (
      <>
        {post &&
          comments?.map((comment) => (
            <DisplayPostCommentsPreview comment={comment} key={comment.id} />
          ))}
      </>
    );
  };
  
  export default PostCommentsPreview;
  