import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import {
  collection,
  orderBy,
  query,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

// redux - use
import { useNotification } from "../redux/useNotification";

const DisplayPostImageCarousel = ({ requestId }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const [imageList, setImageList] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isAuthor, setIsAuthor] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      const arr = [];
      const snapshot = await getDocs(
        query(
          collection(db, "postImage"),
          where("id", "==", requestId),
          where("isPublish", "==", true),
          orderBy("updatedAt", "desc")
        )
      );
      snapshot.forEach((elm) => {
        arr.push({ imageId: elm.id, ...elm.data() });
      });
      setImageList(arr);
    };

    const checkAuthor = async () => {
      const postDoc = await getDoc(doc(db, "post", requestId));
      const creator = postDoc?.data()?.creator;
      setIsAuthor(creator === auth.currentUser?.uid);
    };

    if (requestId) {
      fetchImages();
      checkAuthor();
    }
  }, [requestId]);

  const handleDelete = async () => {
    const imageIdsToDelete = selectedImages.map((image) => image.imageId);
    await Promise.all(
      imageIdsToDelete.map((imageId) =>
        updateDoc(doc(db, "postImage", imageId), {
          isPublish: false,
        })
      )
    );
    await updateDoc(doc(db, "post", requestId), {
      updatedAt: serverTimestamp(),
    });
    displayNotification({
      message: "削除完了しました。",
    });
    setSelectedImages([]);
  };

  const toggleImageSelection = (image) => {
    if (isSelectedImage(image)) {
      setSelectedImages((prevSelectedImages) =>
        prevSelectedImages.filter(
          (selectedImage) => selectedImage.imageId !== image.imageId
        )
      );
    } else {
      setSelectedImages((prevSelectedImages) => [...prevSelectedImages, image]);
    }
  };

  const isSelectedImage = (image) => {
    return selectedImages.some(
      (selectedImage) => selectedImage.imageId === image.imageId
    );
  };

  const isDeleteButtonDisabled = selectedImages.length === 0;

  return (
    <>
      <Carousel
        // showArrows={true}
        autoPlay={true}
        // interval={3000}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } top-0 bottom-0 left-0 h-fit my-auto flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20 `}
              onClick={clickHandler}
            >
              <KeyboardDoubleArrowLeftIcon className="w-9 h-9 text-white font-bold" />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } top-0 bottom-0 right-0 h-fit my-auto flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <KeyboardDoubleArrowRightIcon className="w-9 h-9 text-white font-bold" />
            </div>
          );
        }}
      >
        {imageList?.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image?.url}
              alt=""
              object-fit="cover"
              key={index}
              className="w-full aspect-auto "
            />
            {isAuthor && (
              <div className="absolute top-6 right-2 z-10">
                <input
                  type="checkbox"
                  checked={isSelectedImage(image)}
                  onChange={() => toggleImageSelection(image)}
                />
              </div>
            )}
          </div>
        ))}
      </Carousel>

      {isAuthor && (
        <Button
          variant="contained"
          className="w-fit"
          onClick={handleDelete}
          disabled={isDeleteButtonDisabled}
        >
          消去する
        </Button>
      )}
    </>
  );
};

export default DisplayPostImageCarousel;
