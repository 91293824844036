import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import defaultBackImg from "../data/img/defaultImage.png";
import LoaderBackImg from "./LoaderBackImg";
import Camera from "../data/svg/Camera";
import Loading from "./Loading";
import Blind from "./Blind";
import { useDocumentData } from "react-firebase-hooks/firestore";

const RequestShopImage = ({ id,collection }) => {
  const [shop] = useDocumentData(doc(db, collection, id));
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const [isOwner, setIsOwner] = useState(false);
  useEffect(() => {
    const init = async () => {
      if (shop?.owner === auth.currentUser.uid) {
        setIsOwner(true);
      }
    };
    user && shop && init();
  }, [user]);

  //   背景画像コンテナ
  const [backImg, setBackImg] = useState("");
  //   セットコンテナ
  const [isLoading, setIsLoading] = useState("");
  //   背景画像Ref
  const backImgRef = useRef(null);

  // プロフィール画像変更処理
  useEffect(() => {
    if (backImg) {
      setIsLoading(true);

      const uploadBackImg = async () => {
        const backImgRef = ref(
          storage,
          `${collection}/${new Date().getTime()} - ${backImg.name}`
        );
        try {
          if (shop?.backgroundPath) {
            await deleteObject(ref(storage, shop?.backgroundPath));
          }

          const snap = await uploadBytes(backImgRef, backImg);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await updateDoc(doc(db, collection, id), {
            background: url,
            backgroundPath: snap.ref.fullPath,
          });
          // console.log(backImg)
          setBackImg("");
          // window.location.reload();
          setIsLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      };
      uploadBackImg();
    }
  }, [backImg]);

  if (isLoading) {
    return (
      <>
        <Blind blind={true} />
        <Loading title="イメージ" action="登録中" />;
      </>
    );
  }
  return (
    <>
      <div
        className={` ${
          (user?.isAdmin || isOwner) && "hover:opacity-50"
        } relative  flex justify-center items-center w-full h-4/5 cursor-pointer group`}
      >
        {/* <div className=" absolute bottom-0 left-o w-full h-20 bg-gradient-to-t from-white to-transparent " /> */}
        {shop?.background ? (
          <img
            src={shop?.background || defaultBackImg}
            object-fit="cover"
            className="w-full h-72 rounded-md"
            alt=""
            onClick={() => backImgRef.current.click()}
          />
        ) : defaultBackImg ? (
          <img
            src={defaultBackImg}
            alt="バックイメージ"
            className="w-full h-72 rounded-md"
          />
        ) : (
          <LoaderBackImg />
        )}
        {(user?.isAdmin || isOwner) && (
          <div
            className="absolute top-2   sm:hidden group-hover:flex text-gray-200 sm:text-black opacity-80 hover:opacity-100 duration-300"
            onClick={() => backImgRef.current.click()}
          >
            <Camera className="" />
            <input
              ref={backImgRef}
              onChange={(e) => setBackImg(e.target.files[0])}
              type="file"
              hidden
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RequestShopImage;
