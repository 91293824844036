import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React from "react";
import { useEffect, useState } from "react";
import { db, auth } from "../api/firebase";

import TablePagination from "@mui/material/TablePagination";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";
import DisplayRunruHistory from "./DisplayRunruHistory";

const ProfileRunruHistory = ({ uid }) => {
  const [toRunru, setToRunru] = useState([]);
  useEffect(() => {
    let arr = [];
    const init = () => {
      const toQuery = query(
        collection(db, "runru"),
        where("to", "==", uid),
        orderBy("createdAt", "desc")
      );
      getDocs(toQuery).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setToRunru(arr);
      });
    };
    uid && init();
  }, []);

  const [fromRunru, setFromRunru] = useState([]);
  useEffect(() => {
    const init = () => {
      let arr = [];
      const fromQuery = query(
        collection(db, "runru"),
        where("from", "==", uid),
        orderBy("createdAt", "desc")
      );
      getDocs(fromQuery).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setFromRunru(arr);
      });
    };
    uid && init();
  }, []);

  // pagenation
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [pageX, setPageX] = React.useState(0);
  const [rowsPerPageX, setRowsPerPageX] = useState(20);
  const handleChangePageX = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPageX = (event) => {
    setRowsPerPageX(+event.target.value);
    setPage(0);
  };

  const [activeValue, setActiveValue] = React.useState("1");
  const handleChangeActive = (event, newValue) => {
    setActiveValue(newValue);
  };

  return (
    <>
      <TabContext value={activeValue}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TabList
            onChange={handleChangeActive}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="受領ルンル" value="1" />
            <Tab label="送付ルンル" value="2" />
          </TabList>
          {/* 送付ルンル */}
          <TabPanel value="1" sx={{ padding: 0 }}>
            <div className="grid grid-cols-1  gap-2  my-1">
              {toRunru?.size === 0 && <div>履歴がありません。</div>}
              {(rowsPerPage > 0
                ? toRunru?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : toRunru
              )?.map((to, index) => {
                return (
                  <DisplayRunruHistory runru={to} arrow="to" key={index} />
                );
              })}
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={toRunru?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              showFirstButton
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TabPanel>
          {/* 受領ルンル */}
          <TabPanel value="2" sx={{ padding: 0 }}>
            <div className="grid grid-cols-1  gap-2  my-1">
              {fromRunru?.size === 0 && <div>履歴がありません。</div>}
              {(rowsPerPageX > 0
                ? fromRunru?.slice(
                    pageX * rowsPerPageX,
                    pageX * rowsPerPageX + rowsPerPageX
                  )
                : fromRunru
              )?.map((from, index) => {
                return <DisplayRunruHistory runru={from} arrow="from" />;
              })}
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={fromRunru?.length || 0}
              rowsPerPage={rowsPerPageX}
              page={pageX}
              showFirstButton
              onPageChange={handleChangePageX}
              onRowsPerPageChange={handleChangeRowsPerPageX}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
};

export default ProfileRunruHistory;
