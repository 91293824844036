import {
  collection,
  getDocs,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import React, { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { db } from "../api/firebase";
import DisplayEventFromGroupForAll from "./DisplayEventFromGroupForAll";

import TablePagination from "@mui/material/TablePagination";

import SearchIcon from "@mui/icons-material/Search";
import SearchBar from "./SearchBar";
import FilterPanel from "./FilterPanel";
import { Button } from "@mui/material";
import DisplayBaseList from "./DisplayBaseList";
import FilterBase from "./FilterBase";

const Bases = () => {
  const targetDate = new Date();

  // 拠点一覧取得
  const [bases, setBases] = useState([]);
  const q = query(
    collection(db, "base"),
    where("isPublish", "==", true),
    orderBy("createdAt", "desc")
  );
  useEffect(() => {
    let arr = [];
    getDocs(q).then((docs) => {
      docs?.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setBases(arr);
    });
  }, []);

  // pagenation　未開催
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // filter関連
  const [searchInput, setSearchInput] = useState("");

  const [values, setValues] = useState([
    { id: 1, checked: false, label: "北海道" },
    { id: 2, checked: false, label: "青森県" },
    { id: 3, checked: false, label: "岩手県" },
    { id: 4, checked: false, label: "宮城県" },
    { id: 5, checked: false, label: "秋田県" },
    { id: 6, checked: false, label: "山形県" },
    { id: 7, checked: false, label: "福島県" },
    { id: 8, checked: false, label: "茨城県" },
    { id: 9, checked: false, label: "栃木県" },
    { id: 10, checked: false, label: "群馬県" },
    { id: 11, checked: false, label: "埼玉県" },
    { id: 12, checked: false, label: "千葉県" },
    { id: 13, checked: false, label: "東京都" },
    { id: 14, checked: false, label: "神奈川県" },
    { id: 15, checked: false, label: "新潟県" },
    { id: 16, checked: false, label: "富山県" },
    { id: 17, checked: false, label: "石川県" },
    { id: 18, checked: false, label: "福井県" },
    { id: 19, checked: false, label: "山梨県" },
    { id: 20, checked: false, label: "長野県" },
    { id: 21, checked: false, label: "岐阜県" },
    { id: 22, checked: false, label: "静岡県" },
    { id: 23, checked: false, label: "愛知県" },
    { id: 24, checked: false, label: "三重県" },
    { id: 25, checked: false, label: "滋賀県" },
    { id: 26, checked: false, label: "京都府" },
    { id: 27, checked: false, label: "大阪府" },
    { id: 28, checked: false, label: "兵庫県" },
    { id: 29, checked: false, label: "奈良県" },
    { id: 30, checked: false, label: "和歌山県" },
    { id: 31, checked: false, label: "鳥取県" },
    { id: 32, checked: false, label: "島根県" },
    { id: 33, checked: false, label: "岡山県" },
    { id: 34, checked: false, label: "広島県" },
    { id: 35, checked: false, label: "山口県" },
    { id: 36, checked: false, label: "徳島県" },
    { id: 37, checked: false, label: "香川県" },
    { id: 38, checked: false, label: "愛媛県" },
    { id: 39, checked: false, label: "高知県" },
    { id: 40, checked: false, label: "福岡県" },
    { id: 41, checked: false, label: "佐賀県" },
    { id: 42, checked: false, label: "長崎県" },
    { id: 43, checked: false, label: "熊本県" },
    { id: 44, checked: false, label: "大分県" },
    { id: 45, checked: false, label: "宮崎県" },
    { id: 46, checked: false, label: "鹿児島県" },
    { id: 47, checked: false, label: "沖縄県" },
  ]);

  const handleChangeChecked = (id) => {
    const valuesList = values;
    const changeCheckedValues = valuesList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setValues(changeCheckedValues);
  };

  const [list, setList] = useState();
  const [resultsFound, setResultsFound] = useState(true);

  const [researchCount, setResearchCount] = useState(0);
  const researchCountIncrement = () => {
    setResearchCount((prev) => prev + 1);
  };

  // フィルタ実行
  const applyFilters = () => {
    let updatedList = bases;

    const valuesChecked = values
      ?.filter((item) => item.checked)
      .map((item) => item.label);

    if (valuesChecked.length) {
      updatedList = updatedList.filter(
        (item) => item.prefecture === valuesChecked
      );
    }

    // Search Filter
    if (searchInput) {
      updatedList = updatedList.filter(
        (item) =>
          (item.title)
            .toLowerCase()
            .search(searchInput.toLowerCase().trim()) !== -1
      );
    }

    if (updatedList.length) researchCountIncrement();
    // researchCountIncrement()
    setList(updatedList);
    // setNotHoldEvents(updatedList);
    // console.log("updatedList =>", updatedList);

    !updatedList.length ? setResultsFound(false) : setResultsFound(true);
  };

  // フィルタがある場合発動
  useEffect(() => {
    applyFilters();
    // researchCountIncrement()
  }, [values,searchInput]);

  // リスト表示
  const displayList = (list) => {
    return (
      <>
        {(rowsPerPage > 0
          ? list?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : list
        )?.map((doc) => {
          return <DisplayBaseList base={doc} key={Math.random()} />;
        })}
      </>
    );
  };

  // フィルタ解除
  const clearFilter = () => {
    setResearchCount(0);
    setSearchInput("");
    setValues([
      { id: 1, checked: false, label: "北海道" },
      { id: 2, checked: false, label: "青森県" },
      { id: 3, checked: false, label: "岩手県" },
      { id: 4, checked: false, label: "宮城県" },
      { id: 5, checked: false, label: "秋田県" },
      { id: 6, checked: false, label: "山形県" },
      { id: 7, checked: false, label: "福島県" },
      { id: 8, checked: false, label: "茨城県" },
      { id: 9, checked: false, label: "栃木県" },
      { id: 10, checked: false, label: "群馬県" },
      { id: 11, checked: false, label: "埼玉県" },
      { id: 12, checked: false, label: "千葉県" },
      { id: 13, checked: false, label: "東京都" },
      { id: 14, checked: false, label: "神奈川県" },
      { id: 15, checked: false, label: "新潟県" },
      { id: 16, checked: false, label: "富山県" },
      { id: 17, checked: false, label: "石川県" },
      { id: 18, checked: false, label: "福井県" },
      { id: 19, checked: false, label: "山梨県" },
      { id: 20, checked: false, label: "長野県" },
      { id: 21, checked: false, label: "岐阜県" },
      { id: 22, checked: false, label: "静岡県" },
      { id: 23, checked: false, label: "愛知県" },
      { id: 24, checked: false, label: "三重県" },
      { id: 25, checked: false, label: "滋賀県" },
      { id: 26, checked: false, label: "京都府" },
      { id: 27, checked: false, label: "大阪府" },
      { id: 28, checked: false, label: "兵庫県" },
      { id: 29, checked: false, label: "奈良県" },
      { id: 30, checked: false, label: "和歌山県" },
      { id: 31, checked: false, label: "鳥取県" },
      { id: 32, checked: false, label: "島根県" },
      { id: 33, checked: false, label: "岡山県" },
      { id: 34, checked: false, label: "広島県" },
      { id: 35, checked: false, label: "山口県" },
      { id: 36, checked: false, label: "徳島県" },
      { id: 37, checked: false, label: "香川県" },
      { id: 38, checked: false, label: "愛媛県" },
      { id: 39, checked: false, label: "高知県" },
      { id: 40, checked: false, label: "福岡県" },
      { id: 41, checked: false, label: "佐賀県" },
      { id: 42, checked: false, label: "長崎県" },
      { id: 43, checked: false, label: "熊本県" },
      { id: 44, checked: false, label: "大分県" },
      { id: 45, checked: false, label: "宮崎県" },
      { id: 46, checked: false, label: "鹿児島県" },
      { id: 47, checked: false, label: "沖縄県" },
    ]);
    setList("");
  };

  return (
    <>
      <div className="">
        <div className="font-bold text-xl my-4 ml-2">拠点一覧</div>

        {/* サーチボックス */}
        <div className="mb-4">
          <SearchBar
            value={searchInput}
            changeInput={(e) => setSearchInput(e.target.value)}
            placeholder="拠点名・都道府県名..."
          />
        </div>

        {/* フィルタ　 lg未満で表示 */}
        <div className="lg:hidden">
          <FilterBase values={values} changeChecked={handleChangeChecked} />
        </div>

        <div className=" grid grid-cols-7 gap-10">
          {/* lg以上で表示 */}

          {/* filter */}
          <div className="border col-span-2 rounded-md mb-4 hidden lg:block">
            <Button
              disabled={!researchCount || researchCount === 1}
              onClick={clearFilter}
            >
              フィルター解除
            </Button>
            <FilterBase values={values} changeChecked={handleChangeChecked} />
          </div>

          {/* 拠点 */}
          <div className=" col-span-7 lg:col-span-5">
            {/* 未開催イベント */}
            <div className=" border bg-white p-8 rounded-md mb-4">
              {/* <div className="font-bold mb-2">未開催イベント</div> */}
              {bases?.size === 0 && <div>拠点はありません</div>}

              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
                {/* filter前 */}
                {!researchCount && (displayList(bases))}

                {/* フィルター後 */}
                {/* フィルターかけて何もなかったら */}
                {researchCount >0 && (!list || !list.length) && (
                  <div>検索の拠点はありません。</div>
                )}
                {/* listになにかあるなら */}
                {researchCount > 0 && list?.length && ( displayList(list))}
              </div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={list?.length ? list.length : bases?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                showFirstButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Bases);

// 後学者のために reactでfilterをかける構造
// https://github.com/codefreeeze/filters-materialui-react
