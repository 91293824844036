import React, { useState } from "react";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import { addDoc, collection, doc, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { LoadScript } from "@react-google-maps/api";

import { ReactComponent as LendfieldIcon } from "../data/svg/LendFieldKUWA.svg";
import { ReactComponent as LendOldHouseIcon } from "../data/svg/LendOldHouse.svg";
import { ReactComponent as PowerSpotIcon } from "../data/svg/PowerSpot.svg";
import { ReactComponent as VagetableIcon } from "../data/svg/Vagetable.svg";
import { ReactComponent as AlternativeMedicineIcon } from "../data/svg/AlternativeMedicine.svg";
import { useLocation, useNavigate } from "react-router-dom";

const AddRecommendedSopt = ({ setOpen, requestId }) => {
  const [product, setProduct] = useState({
    name: "",
    address: "",
    type: "",
    lat: "",
    lng: "",
    tel: "",
    url: "",
    recomendedPoint: "",
    error: "",
    loading: false,
  });
  const {
    name,
    address,
    type,
    lat,
    lng,
    tel,
    url,
    recomendedPoint,
    error,
    loading,
  } = product;

  const location = useLocation();
  const navigate = useNavigate();

  // 値変更
  const handleChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  // console.log("product =>", product);

  // お店掲載申請値変更
  const handleChangeShop = (e) => {
    if (e.target.name === "type") {
      setProduct({
        ...product,
        type: shopData[e.target.value].type,
      });
      return;
    }

    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  const [place, setPlace] = useState();
  const geocode = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: place }, (results, status) => {
      // console.log("results =>", results);
      if (status === "OK") {
        setProduct({
          ...product,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      }
    });
  };

  const changeLocationName = (event) => {
    // console.log("event =>", event.target.value);
    setProduct({ ...product, address: event.target.value });
    setPlace(event.target.value);
    geocode();
  };

  const shopData = {
    貸畑: {
      type: "lendField",
      icon: LendfieldIcon,
    },
    貸古民家: {
      type: "lendOldHouse",
      icon: LendOldHouseIcon,
    },
    パワースポット: {
      type: "powerSpot",
      icon: PowerSpotIcon,
    },
    自然食品: {
      type: "naturalFood",
      icon: VagetableIcon,
    },
    代替医療: {
      type: "alternativeMedicine",
      icon: AlternativeMedicineIcon,
    },
  };

  // お店申請時に選択する職業一覧
  const storesWithLabel = () => {
    const Icon = ({ Icon }) => {
      return <>{Icon && <Icon className="h-4 w-4" />}</>;
    };

    const StoresLabel = ({ value }) => {
      return (
        <>
          <div className="flex items-center space-x-2 rounded-md">
            <Icon Icon={shopData[value].icon} />
            <span>{value}</span>
          </div>
        </>
      );
    };

    const createStores = (value) => {
      return {
        label: (
          <>
            <StoresLabel value={value} />
          </>
        ),
        name: value,
        value: value,
      };
    };
    const stores = [
      createStores("貸畑"),
      createStores("貸古民家"),
      createStores("パワースポット"),
      createStores("自然食品"),
      createStores("代替医療"),
    ];
    return stores;
  };
  // お店掲載申請関連
  const [fromProvider, setFromProvider] = useState(false);

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });
    try {
      await addDoc(collection(db, "recommendedSpot"), {
        createdAt: serverTimestamp(),
        isPublish: false,
        isPermission: false,
        address,
        lat,
        lng,
        name,
        tel,
        type,
        url,
        creator: auth.currentUser.uid,
        recomendedPoint,
      });

      setProduct({
        error: "",
        loading: false,
      });

      if (window.location.pathname === "/shopApplication") {
        navigate("/thanksForApplication");
        return;
      }

      window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      const errorData = {
        message: error.message,
        stack: error.stack,
      };

      addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: errorData,
      });

      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div className=" max-w-lg mt-5">
        <div className="mb-3">おすすめスポット登録</div>
        <form onSubmit={setRequestDataShop}>
          {/* お店の名前 */}
          <TextField
            id="outlined-required"
            name="name"
            label="お店の名前"
            defaultValue=""
            size="small"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
          ></TextField>

          {/* お店の種類 */}
          <div className="my-4">
            <TextField
              id="outlined-select-currency"
              name="type"
              label="どんなお店？"
              defaultValue=""
              className="w-full"
              size="small"
              onChange={handleChangeShop}
              select
              required
            >
              {storesWithLabel().map((option) => (
                <MenuItem key={option.value} value={option.value} dense>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* お店の住所 */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="address"
              label="住所"
              defaultValue=""
              placeholder=""
              size="small"
              onChange={(event) => changeLocationName(event)}
              // onBlur={addressOnBlur}
              required
              className="w-full my-4"
              disabled={fromProvider}
            ></TextField>
          </div>
          {/* 経度 */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              label="経度"
              value={lng}
              size="small"
              className="w-full my-4"
              disabled
            ></TextField>
          </div>
          <div className="my-4">
            <TextField
              id="outlined-required"
              label="緯度"
              value={lat}
              size="small"
              className="w-full my-4 "

              onChange={changeLocationName}
              disabled
            ></TextField>
          </div>

          {location?.pathname === "/adminsetting" && (
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            ></LoadScript>
          )}

          {/* <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          ></LoadScript> */}

          {/* お店の電話番号 */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="tel"
              type="number"
              label="電話番号 -(ハイフン)不要"
              defaultValue=""
              placeholder=""
              size="small"
              onChange={handleChangeShop}
              // required
              className="w-full my-4"
              disabled={fromProvider}
            ></TextField>
          </div>

          {/* ホームページURL */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="url"
              label="ホームページURL"
              defaultValue=""
              placeholder=""
              size="small"
              onChange={handleChangeShop}
              className="w-full my-4"
              disabled={fromProvider}
            ></TextField>
          </div>

          {/* お店のおすすめポイント */}
          {location?.pathname === "/shopApplication" && (
            <div className="my-4">
              <textarea
                style={{ resize: "none", fontSize: "16px" }}
                type="text"
                name="recomendedPoint"
                className="textarea textarea-bordered w-full h-60 p-1 bg-back"
                placeholder="例）〇〇を専門に取り扱っているお店。ご主人が気さくで本当に気持ちの良いお店です。開店時間などは直接お店に問い合わせする必要があるかもです。"
                onChange={handleChangeShop}
              ></textarea>{" "}
            </div>
          )}

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
          >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AddRecommendedSopt;
