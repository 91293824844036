import React, { useState, useEffect } from "react";
import CreateHeaderMenu from "./CreateHeaderMenu";
import Notifications from "./Notifications";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import NotificationsInMessage from "./NotificationsInMessage";
import QuestionForAdminIcon from "./QuestionForAdminIcon";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import ApprovalPendingIcon from "./ApprovalPendingIcon";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import ForestIcon from "@mui/icons-material/Forest";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  doc,
  updateDoc,
  onSnapshot,
  query,
  collection,
  limit,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useAuth } from "../context/auth";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import { useNavigate } from "react-router-dom";

const HeaderMenus = () => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState([]);

  //    遷移系
  const navigate = useNavigate(9);
  // ホームへ移動
  const handleToHome = () => {
    if (window.location.pathname !== "/home") {
      navigate("/home");
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };
  // プロフィールへ移動
  const handleToProfile = () => {
    navigate("/profile");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // 管理者画面へ移動
  const handleToAdminSetting = () => {
    navigate("/adminsetting");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // マニュアルへ移動
  const handleToManuals = () => {
    navigate("/manuals");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // マップへ移動
  const handleToMap = () => {
    navigate("/map");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // チャットへ移動
  const handleToChat = () => {
    navigate("/chat");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // 動画へ移動
  const handleToMovie = () => {
    navigate("/movies");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // DDPへ移動
  const handleToDdp = () => {
    navigate("/ddps");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    // window.location.reload();
  };
  // マイページへ移動
  const handleToMyPage = () => {
    navigate(`/mypage/${auth.currentUser.uid}`);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // イベントへ移動
  const handleToEvent = () => {
    navigate("/events");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // グループへ移動
  const handleToGroup = () => {
    navigate("/groups");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };
  // 拠点へ移動
  const handleToBases = () => {
    navigate("/bases");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  // リリーススケジュールへ移動
  const handleToRelease = () => {
    navigate("/release");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  useEffect(() => {
    const getUser = async () => {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        setUserInfo(doc.data());
      });
    };
    user && getUser();
  }, [user]);

  return (
    <>
      <CreateHeaderMenu
        text="動画"
        icon={LiveTvIcon}
        onclick={handleToMovie}
        complete
      />
      <CreateHeaderMenu
        text="DDP"
        icon={VolunteerActivismIcon}
        onclick={handleToDdp}
        complete
      />
      <CreateHeaderMenu
        text="マップ"
        icon={LocationOnIcon}
        onclick={handleToMap}
        complete
      />
      {/* <CreateHeaderMenu text="チャット" icon={MessageIcon} onclick={handleToChat}  complete/> */}
      {userInfo?.isVillager && (
        <CreateHeaderMenu
          text="拠点"
          icon={ForestIcon}
          onclick={handleToBases}
          complete
        />
      )}
      {userInfo?.isVillager && (
        <CreateHeaderMenu
          text="グループ"
          icon={GroupsIcon}
          onclick={handleToGroup}
          complete
        />
      )}
      {/* <CreateHeaderMenu
          text="マイページ"
          icon={PersonIcon}
          onclick={displayConstruction}
        /> */}
      <CreateHeaderMenu
        text="イベント"
        icon={EventIcon}
        onclick={handleToEvent}
        complete
      />
      <CreateHeaderMenu
        text="リリース"
        icon={ViewTimelineIcon}
        onclick={handleToRelease}
        complete
      />
      {userInfo?.isVillager && window.location.href.indexOf("chat") === -1 && (
        <NotificationsInMessage />
      )}

      {/* {window.location.href.indexOf("chat") === -1 && (
          <NotificationsInMessage />
        )} */}
      <Notifications />
      {/* <CreateHeaderMenu text="お知らせ" icon={NotificationsActiveIcon} onclick={displayConstruction} /> */}
    </>
  );
};

export default HeaderMenus;
