import { doc, setDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase"; // Firebaseの設定が含まれたファイルへのパス

// デバイスステータスを更新する関数
export async function updateDeviceStatus(userId, deviceId, isOnline) {
  const userDeviceRef = doc(db, "users", userId, "devices", deviceId);
  try {
    await updateDoc(
      userDeviceRef,
      {
        isOnline: isOnline,
        lastUpdated: serverTimestamp(), // 更新時刻をサーバー側のタイムスタンプで記録
      },
      // { merge: true }
    ); // ドキュメントが存在する場合はマージ（更新のみ実行）、存在しない場合は作成
    // console.log(
    //   `Device status updated: ${deviceId} is now ${
    //     isOnline ? "online" : "offline"
    //   }.`
    // );
  } catch (error) {
    console.error("Error updating device status:", error);
  }
}
