import React from "react";
import AddComponent from "./AddComponent";
import AnnounceTable from "./AnnounceTable";
import BasesTable from "./BasesTable";
import CreateBaseHandle from "./CreateBaseHandle";


const OperateAnnounce = () => {
  return (
    <>
      <div className="font-bold text-xl">お知らせ</div>
      <AddComponent value="お知らせ作成" target="createAnnounce" />
      <AnnounceTable />
    </>
  );
};

export default OperateAnnounce;
