import React, { useState, useEffect } from "react";
import { db } from "../api/firebase";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  collection,
  query,
  orderBy,
  doc,
  onSnapshot,
  serverTimestamp,
  getDoc
} from "firebase/firestore";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { updateDoc, deleteDoc } from "firebase/firestore";
import { Box, Button, Divider, Modal, TextField } from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { getSkillDetails } from "../api/chatgpt";
import AIResponse from "./AIResponse";

const DDPList = ({ userId }) => {
  const [ddps, setDdps] = useState([]);
  const [aiResponse, setAiResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState(0);
  const [userName, setUserName] = useState("");

  // ここに表示を許可するUIDのリストを定義
  const allowedUserIds = [
    "dtS8RMdSIQhSCI7a1T1Ks57dps42",
    "umadLc6LXWMgjQnNObGOKAXbKhA3",
    "Ua2x8QAhk1UEpzciTuzgi7IIRJT2",
    "JYx1cZ5etwPhPoT5iCtmkk2fGqx1",
    "gvcnpWr5QaWiOIVFF7VY6Z47xPp1"
  ];

  useEffect(() => {
    if (!userId) return;

    const fetchUserName = async () => {
      const userDoc = await getDoc(doc(db, `users/${userId}`));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserName(`${userData.lastName} ${userData.firstName}`);
      }
    };

    fetchUserName();

    const q = query(
      collection(db, `users/${userId}/ddps`),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const ddpList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt
          ? doc.data().createdAt.toDate()
          : new Date(),
      }));
      setDdps(ddpList);
    });

    return () => unsubscribe();
  }, [userId]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentDdp, setCurrentDdp] = useState(null);

  const handleOpenDeleteDialog = (ddp) => {
    setCurrentDdp(ddp);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = async () => {
    if (currentDdp) {
      await deleteDoc(doc(db, `users/${userId}/ddps`, currentDdp.id));
      setSnackbarMessage("消去完了しました");
      setOpenSnackbar(true);
      handleCloseDeleteDialog();
    }
  };

  const [openEditModal, setOpenEditModal] = useState(false);
  const [editText, setEditText] = useState("");

  const handleOpenEditModal = (ddp) => {
    setCurrentDdp(ddp);
    setEditText(ddp.ddp);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleUpdateDdp = async () => {
    if (currentDdp) {
      await updateDoc(doc(db, `users/${userId}/ddps`, currentDdp.id), {
        ddp: editText,
      });
      handleCloseEditModal();
    }
  };

  const handleRadioChange = async (event, ddpId) => {
    const newDisplayValue = event.target.value === "public";
    await updateDoc(doc(db, `users/${userId}/ddps`, ddpId), {
      display: newDisplayValue,
    });
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleDisplayChange = async (event, ddpId) => {
    const newDisplayValue = event.target.value === "public";
    await updateDoc(doc(db, `users/${userId}/ddps`, ddpId), {
      display: newDisplayValue,
      updatedAt: serverTimestamp(),
    });
    setSnackbarMessage(
      newDisplayValue ? "公開に設定しました。" : "非公開に設定しました。"
    );
    setOpenSnackbar(true);
  };

  const handleAskAI = async (ddp) => {
    const now = Date.now();
    if (now - lastRequestTime < 60000) {
      setSnackbarMessage("1分間に1回のみリクエストが可能です。");
      setOpenSnackbar(true);
      return;
    }
    setLastRequestTime(now);
    setLoading(true);
    try {
      const response = await getSkillDetails(ddp.ddp, userName); // userNameを渡す
      setAiResponse((prevResponses) => ({
        ...prevResponses,
        [ddp.id]: response,
      }));
      setSnackbarMessage("AIの応答を取得しました。");
    } catch (error) {
      setSnackbarMessage("AIの応答を取得できませんでした。");
    }
    setLoading(false);
    setOpenSnackbar(true);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="mt-2 max-w-[556px]">
      <div className="p-4 border rounded-md bg-white">
        {ddps.map((ddp, index) => (
          <>
            <div className="font-bold text-blue-500 text-xl">DDP</div>
            <Accordion key={ddp.id} defaultExpanded={index === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="flex items-center">
                  <div>作成：</div>
                  <div>
                    <Typography color="primary" style={{ marginRight: "1rem" }}>
                      {formatRelativeDate(ddp.createdAt)}
                    </Typography>
                  </div>
                </div>
              </AccordionSummary>
              <Divider></Divider>

              <AccordionDetails>
                <Typography
                  style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                >
                  {ddp.ddp}
                </Typography>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpenEditModal(ddp)}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  aria-label="delete"
                  onClick={() => handleOpenDeleteDialog(ddp)}
                >
                  <DeleteIcon />
                </IconButton>
                <RadioGroup
                  row
                  aria-label="visibility"
                  name="visibility"
                  value={ddp.display ? "public" : "private"}
                  onChange={(event) => handleDisplayChange(event, ddp.id)}
                >
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="公開"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="非公開"
                  />
                </RadioGroup>
                {/* UIDがallowedUserIdsリストに含まれている場合のみボタンとAIResponseを表示 */}
                {allowedUserIds.includes(userId) && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAskAI(ddp)}
                      disabled={loading}
                    >
                      {loading ? "DDPを解析中" : "AIに聞いてみる"}
                    </Button>
                    <AIResponse response={aiResponse[ddp.id]} />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        ))}
      </div>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">削除確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            本当に削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>キャンセル</Button>
          <Button onClick={handleDelete} autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <Typography id="edit-modal-title" variant="h6" component="h2">
            DDPを編集
          </Typography>
          <TextField
            fullWidth
            multiline
            minRows={3}
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
          />
          <div className="mt-3"></div>
          <Button onClick={handleUpdateDdp} variant="contained" color="primary">
            更新
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default DDPList;

const formatRelativeDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const difference = now.getTime() - date.getTime();
  const daysDifference = Math.floor(difference / (1000 * 3600 * 24));

  if (daysDifference === 0) {
    return "今日";
  } else if (daysDifference === 1) {
    return "昨日";
  } else if (daysDifference > 7) {
    return `${daysDifference}日前`;
  }
};
