import React, { memo, useState } from "react";
import { Tooltip } from "@mui/material";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { PencilIcon } from "@heroicons/react/solid";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import ModalForm from "./ModalForm";
import DisplayBaseCard from "./DisplayBaseCard";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SwitchComponent from "./SwitchComponent";
import LoaderName from "./LoaderName";
import SelectComponent from "./SelectComponent";

const ProfileCard = () => {
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const [affiliationbasesCollection] = useCollection(
    collection(db, "users", auth.currentUser.uid, "affiliationBase")
  );
  const affiliationbases = affiliationbasesCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const [dataloading, setDataLoading] = useState(true);
  const [d_loding, setD_loading] = useState(false);

  //   権限変更関連handle
  const publishChange = async (id, type) => {
    // const pub = await getDoc(doc(db, "users", auth.currentUser.uid));
    if (type === "sextype") {
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        [type]: id,
      });
      return;
    }

    if (type === "bloodtype") {
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        [type]: id,
      });
      return;
    }

    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      [type]: !user?.[type],
    });
    setDataLoading(true);
    setD_loading(false);
  };
  const handleChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  // フォーム関連
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  const prefectures = [
    { value: "北海道", label: "北海道" },
    { value: "青森県", label: "青森県" },
    { value: "岩手県", label: "岩手県" },
    { value: "宮城県", label: "宮城県" },
    { value: "秋田県", label: "秋田県" },
    { value: "山形県", label: "山形県" },
    { value: "福島県", label: "福島県" },
    { value: "茨城県", label: "茨城県" },
    { value: "栃木県", label: "栃木県" },
    { value: "群馬県", label: "群馬県" },
    { value: "埼玉県", label: "埼玉県" },
    { value: "千葉県", label: "千葉県" },
    { value: "東京都", label: "東京都" },
    { value: "神奈川県", label: "神奈川県" },
    { value: "新潟県", label: "新潟県" },
    { value: "富山県", label: "富山県" },
    { value: "石川県", label: "石川県" },
    { value: "福井県", label: "福井県" },
    { value: "山梨県", label: "山梨県" },
    { value: "長野県", label: "長野県" },
    { value: "岐阜県", label: "岐阜県" },
    { value: "静岡県", label: "静岡県" },
    { value: "愛知県", label: "愛知県" },
    { value: "三重県", label: "三重県" },
    { value: "滋賀県", label: "滋賀県" },
    { value: "京都府", label: "京都府" },
    { value: "大阪府", label: "大阪府" },
    { value: "兵庫県", label: "兵庫県" },
    { value: "奈良県", label: "奈良県" },
    { value: "和歌山県", label: "和歌山県" },
    { value: "鳥取県", label: "鳥取県" },
    { value: "島根県", label: "島根県" },
    { value: "岡山県", label: "岡山県" },
    { value: "広島県", label: "広島県" },
    { value: "山口県", label: "山口県" },
    { value: "徳島県", label: "徳島県" },
    { value: "香川県", label: "香川県" },
    { value: "愛媛県", label: "愛媛県" },
    { value: "高知県", label: "高知県" },
    { value: "福岡県", label: "福岡県" },
    { value: "佐賀県", label: "佐賀県" },
    { value: "長崎県", label: "長崎県" },
    { value: "熊本県", label: "熊本県" },
    { value: "大分県", label: "大分県" },
    { value: "宮崎県", label: "宮崎県" },
    { value: "鹿児島県", label: "鹿児島県" },
    { value: "沖縄県", label: "沖縄県" },
  ];

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} />
      <div className="border bg-white rounded-md shadow-sm p-4 w-fit">
        <div className=" font-bold text-xl mb-3">基本情報</div>
        <table className=" p-2 w-fit">
          <tbody>
            {/* 名前*/}
            <tr>
              <td className=" bg-blue-50 p-5 font-bold">名前</td>
              <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center  space-x-2 ">
                    <div className="font-bold ">
                      {user?.lastName || <LoaderName />}
                    </div>
                    <div className="h-6 w-6 cursor-pointer ">
                      <PencilIcon onClick={() => handleEdit("editLastName")} />
                    </div>
                  </div>
                  <div className="flex  items-center space-x-2 ">
                    <div className="font-bold ">
                      {user?.firstName || <LoaderName />}
                    </div>
                    <div className="h-6 w-6 cursor-pointer ">
                      <PencilIcon onClick={() => handleEdit("editFirstName")} />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* ニックネーム*/}
            {/* <tr>
              <td className=" bg-blue-50 p-5 font-bold">ニックネーム</td>
              <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                <div className="flex flex-col space-y-2">
                  <div className="flex items-center  space-x-2 ">
                    <div className="font-bold ">{user?.nickName || ""}</div>
                    <div className="h-6 w-6 cursor-pointer ">
                      <PencilIcon onClick={() => handleEdit("editNickName")} />
                    </div>
                  </div>
                </div>
              </td>
            </tr> */}
            {/* 誕生日*/}
            <tr>
              <td className=" bg-blue-50 p-5 font-bold">誕生日</td>
              <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                <div className="flex space-x-3 items-center">
                  <div className="font-bold">公開</div>
                  {user && (
                    <SwitchComponent
                      id={user?.uid}
                      target="displayBirthDay"
                      collection="users"
                      text="公開"
                    />
                  )}
                </div>
                <div className="flex space-x-2">
                  <div className=" font-bold cursor-pointer w-fit">
                    {user?.birthyear &&
                      user?.birthyear +
                        "/" +
                        user?.birthmonth +
                        "/" +
                        user?.birthday}
                  </div>
                  <div>
                    <PencilIcon
                      className="h-6 w-6  cursor-pointer"
                      onClick={() => handleEdit("editBirthDate")}
                    />
                  </div>
                </div>
              </td>
            </tr>
            {/* 性別 */}
            <tr>
              <td className=" bg-blue-50 p-5 font-bold">性別</td>
              <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                <div className="flex space-x-3 items-center">
                  <div className="font-bold">公開</div>

                  {user && (
                    <SwitchComponent
                      id={user?.uid}
                      target="displaySexType"
                      collection="users"
                      text="公開"
                    />
                  )}
                </div>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="sextype"
                  value={user?.uid}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    checked={user?.sextype === "男性"}
                    value="男性"
                    control={<Radio />}
                    label="男性"
                  />
                  <FormControlLabel
                    checked={user?.sextype === "女性"}
                    value="女性"
                    control={<Radio />}
                    label="女性"
                  />
                  <FormControlLabel
                    checked={user?.sextype === "dontAnswer"}
                    value="dontAnswer"
                    control={<Radio />}
                    label="回答しない"
                  />
                </RadioGroup>
              </td>
            </tr>
            {/* 血液型 */}
            <tr>
              <td className=" bg-blue-50 p-5 font-bold">血液型</td>
              <td className=" p-5  shadow-md hover:shadow-2xl duration-150">
                <div className="flex space-x-3 items-center">
                  <div className="font-bold">公開</div>

                  {user && (
                    <SwitchComponent
                      id={user?.uid}
                      target="displayBloodType"
                      collection="users"
                      text="公開"
                    />
                  )}
                </div>

                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="bloodtype"
                  value={user?.uid}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    checked={user?.bloodtype === "A"}
                    value="A"
                    control={<Radio />}
                    label="A型"
                  />
                  <FormControlLabel
                    checked={user?.bloodtype === "B"}
                    value="B"
                    control={<Radio />}
                    label="B型"
                  />
                  <FormControlLabel
                    checked={user?.bloodtype === "O"}
                    value="O"
                    control={<Radio />}
                    label="O型"
                  />
                  <FormControlLabel
                    checked={user?.bloodtype === "AB"}
                    value="AB"
                    control={<Radio />}
                    label="AB型"
                  />
                  <FormControlLabel
                    checked={user?.bloodtype === "Unknown"}
                    value="Unknown"
                    control={<Radio />}
                    label="わからない"
                  />
                </RadioGroup>
              </td>
            </tr>
            {/* 居住地 */}
            <tr>
              <td className=" bg-blue-50 p-5 font-bold">居住地</td>
              <td className=" p-5  shadow-md hover:shadow-2xl duration-150">
                <div className="flex space-x-3 items-center">
                  {/* <div className="font-bold">公開</div> */}

                  {/* {user && (
                    <SwitchComponent
                      id={user?.uid}
                      target="displayPrefecture"
                      collection="users"
                      text="公開"
                    />
                  )} */}
                </div>
                <div className="flex space-x-1">
                  <div>現在の設定：</div>
                  <div className="mb-2 font-bold ">{user?.prefecture}</div>
                </div>
                <SelectComponent
                  title="都道府県"
                  collection="users"
                  id={auth.currentUser.uid}
                  documentName="prefecture"
                  array={prefectures}
                />
              </td>
            </tr>

            {/* 職業 */}
            <tr>
              <td className=" bg-blue-50 p-5 font-bold">職業</td>
              <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                <div className="flex space-x-2">
                  <div className=" font-bold  ">{user?.job || "未登録"}</div>
                  <div
                    className="w-6 h-6 rounded-full cursor-pointer"
                    onClick={() => handleEdit("editJob")}
                  >
                    <PencilIcon />
                  </div>
                </div>
              </td>
            </tr>
            {/* 所属拠点*/}
            <tr>
              <td className=" bg-blue-50 p-5 font-bold">所属拠点</td>
              <td className=" p-5 shadow-md hover:shadow-2xl duration-150">
                {affiliationbases?.length
                  ? affiliationbases?.map((base) => (
                      <DisplayBaseCard id={base?.baseId} type={base?.type} />
                    ))
                  : "所属拠点なし"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default memo(ProfileCard);
