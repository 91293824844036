import React, { useState, useEffect, useRef } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import defaultBackImg from "../data/img/defaultBack.jpg";
import LoaderBackImg from "./LoaderBackImg";
import Camera from "../data/svg/Camera";
import Loading from "./Loading";
import Blind from "./Blind";
import { useDocumentData } from "react-firebase-hooks/firestore";

const FlexImage = ({ collection, id, target, contentId, value }) => {
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  const [flexContent] = useDocumentData(
    doc(db, collection, id, target, contentId)
  );

  //   背景画像コンテナ
  const [backImg, setBackImg] = useState("");
  //   セットコンテナ
  const [isLoading, setIsLoading] = useState("");
  //   背景画像Ref
  const backImgRef = useRef(null);

  // プロフィール画像変更処理
  useEffect(() => {
    if (backImg) {
      setIsLoading(true);

      // console.log("backImg =>",backImg)

      const uploadBackImg = async () => {
        const backImgRef = ref(
          storage,
          `background/${new Date().getTime()} -${contentId} -  ${backImg.name}`
        );
        try {
          if (flexContent?.backgroundPath) {
            await deleteObject(ref(storage, flexContent?.backgroundPath));
          }

          const snap = await uploadBytes(backImgRef, backImg);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          // console.log("snap =>",snap)

          await updateDoc(doc(db, collection, id, target, contentId), {
            background: url,
            backgroundPath: snap.ref.fullPath,
          });
          // console.log(backImg)
          setBackImg("");
          // window.location.reload();
          setIsLoading(false);
        } catch (err) {
          console.log(err.message);
        }
      };
      uploadBackImg();
    }
  }, [backImg]);

  if (isLoading) {
    return (
      <>
        <Blind blind={true} />
        <Loading title="イメージ" action="更新中" />;
      </>
    );
  }
  return (
    <>
      <div
        className={` ${
          value === "1" && " hover:opacity-50 cursor-pointer "
        }" relative  flex  items-center  group my-2"`}
      >
        {/* <div className=" absolute bottom-0 left-o w-full h-20 bg-gradient-to-t from-white to-transparent " /> */}
        {flexContent?.background ? (
          <img
            src={flexContent?.background || defaultBackImg}
            object-fit="cover"
            className={` 
            
            ${flexContent?.rounded === "角丸" ? " rounded-xl " : ""}  
            
            ${flexContent?.imageSize === "小" && " w-72 h-48 "}  
            ${flexContent?.imageSize === "中" && " w-96 h-72 "}  
            ${
              flexContent?.imageSize === "大" && " w-full h-96 max-w-[384px] "
            }  
            
            ""`}
            alt=""
            onClick={() => backImgRef.current.click()}
          />
        ) : defaultBackImg ? (
          <img
            src={defaultBackImg}
            alt="バックイメージ"
            className="w-72 h-72 rounded-t-md"
          />
        ) : (
          <LoaderBackImg />
        )}
        {user?.isAdmin && value === "1" && (
          <div
            className="absolute top-2   sm:hidden group-hover:flex text-gray-200 sm:text-black opacity-80 hover:opacity-100 duration-300"
            onClick={() => backImgRef.current.click()}
          >
            <Camera className="" />
            <input
              ref={backImgRef}
              onChange={(e) => setBackImg(e.target.files[0])}
              type="file"
              hidden
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FlexImage;
