import React from "react";
import TimeSchedule from "../components/TimeSchedule";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const Release = () => {
  const navigate = useNavigate();
  const moveHome = () => {
    navigate("/home");
  };
  return (
    <>
      <div className="m-3">
        <Button variant="contained" onClick={moveHome}>
          ホームへ戻る
        </Button>
      </div>
      <TimeSchedule />;
    </>
  );
};

export default Release;
