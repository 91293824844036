import { configureStore } from "@reduxjs/toolkit";

// slice
import { NotificationReducer } from "./notificationSlice";
import { SnackbarReducer} from "./snackbarSlice"

export const store = configureStore({
  reducer: {
    notification: NotificationReducer,
    snackbar:SnackbarReducer,
  },
});
