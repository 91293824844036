import React, { useEffect, useState } from "react";
import { useAuth } from "../context/auth";
import { useStreamChatClient } from "../context/StreamChatContext";

const Test = () => {
  const { user } = useAuth();
  const chatClient = useStreamChatClient()
  console.log("chatClient =>",chatClient)
  return(
    <>
      
    </>
  )
};

export default Test;
