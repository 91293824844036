import React, { useState } from "react";

// MaterialUI
import TextField from "@mui/material/TextField";

// firebase
import { addDoc, doc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../api/firebase";

// redux - use
import { useNotification } from "../redux/useNotification";

import { useDocumentData } from "react-firebase-hooks/firestore";

// streamChat
import { StreamChat } from "stream-chat";

const AddMovieChannel = ({ setOpen, target }) => {
  const { displayNotification } = useNotification();

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // 入力データコンテナ
  const [data, setData] = useState({
    title: "",
    explain: "",
  });
  const { title, explain, error, loading } = data;

  // データ変更処理
  const handleChange = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  // 処理
  const doRegist = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });

    const create = async () => {
      try {
        // コレクションに追加
        const docRef = await addDoc(collection(db, "movieChannel"), {
          title,
          explain,
          isPublish: false,
          type: "セカイムラ",
          creator: auth.currentUser.uid,
          createdAt: serverTimestamp(),
        });

        // チャット作成
        const userToConnect = {
          id: user.uid,
          name: user.lastName + user.firstName,
          image: user.avatar,
          langage: "jp",
        };
        const apiKey = process.env.REACT_APP_STREAM_API_KEY;
        const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
        //   トークン取得
        const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: user.uid,
          }),
        }).then((r) => r.json());
        await client.connectUser(userToConnect, token);
        const channel = client.channel("messaging", "movieChannel-" + docRef.id, {
          name: title,
        });
        await channel.create();
        channel.addMembers([user.uid]);
        await channel.mute();

        displayNotification({
          message: "チャンネルを追加しました。",
          type: "success",
        });

        setData({
          ...data,
          error: null,
        });
        // window.location.reload();

        setOpen(false);
      } catch (err) {
        const errorData = {
          message: err.message,
          stack: err.stack,
        };
        addDoc(collection(db, "error"), {
          createdAt: serverTimestamp(),
          error: errorData,
          function: "AddMovieChnanel",
          user: auth.currentUser.uid,
        });

        console.log(err.message);
        setData({
          ...data,
          error: err.message,
          loading: false,
        });
      }
    };
    create();
  };

  // console.log("data =>", data);
  return (
    <>
      <div className="mb-3">チャンネル追加</div>
      <form onSubmit={doRegist}>
        {/* 名称 */}
        <div className="my-4"></div>
        <TextField
          id="outlined-required"
          name="title"
          label="チャンネル名"
          defaultValue=""
          size="small"
          placeholder="自分で作ってみるさしすせそ(味噌)）"
          className=" w-full"
          onChange={handleChange}
          required
          // inputProps={{maxLength:20}}
        />

        {/* 説明 */}
        <div className="my-3"></div>
        <textarea
          style={{ resize: "none", fontSize: "16px" }}
          type="text"
          label="説明"
          name="explain"
          className="textarea textarea-bordered w-full h-24  "
          placeholder="自分で作れるようになるさしすせその味噌編です。"
          defaultValue=""
          onChange={handleChange}
        ></textarea>

        {/* 送信ボタン */}
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
        >
          {loading ? "送信しています..." : "追加"}
        </button>
      </form>
    </>
  );
};

export default AddMovieChannel;
