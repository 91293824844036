import * as React from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useState } from "react";
import getRate from "./getRate";

export default function Rate({ rate, evalutions }) {
  return (
    <Stack spacing={1}>
      <Rating
        name="half-rating-read"
        defaultValue={!rate ? 0 : rate}
        precision={0.5}
        readOnly
      />
    </Stack>
  );
}
