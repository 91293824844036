import React, { useEffect, useState } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";

const DisplayNotificationContent = ({
  document,
  notificationsTenth,
  handleClose,
  notificationAll,
}) => {
  const navigate = useNavigate();

  // コンテンツ取得
  const [targetContent, setTargetContent] = useState("");
  useEffect(() => {
    const getTargetContent = async () => {
      if (document && document.target && document.targetId) {
        const docRef = await getDoc(doc(db, document.target, document.targetId));
        if (docRef.exists()) {
          setTargetContent({ id: docRef.id, ...docRef.data() });
        }
      }
    };
    getTargetContent();
    // console.log("Document:", document);
    // console.log("Target Content:", targetContent);
  }, [document]);

  const updateIsRecognition = async (arr) => {
    arr?.forEach((ids) => {
      updateDoc(
        doc(db, "users", auth.currentUser.uid, "notification", ids.id),
        {
          isRecognition: true,
        }
      );
    });
  };

  const updateIsRecognitionInMessage = async (arr) => {
    arr?.forEach((ids) => {
      updateDoc(
        doc(db, "users", auth.currentUser.uid, "notificationInMessage", ids.id),
        {
          isRecognition: true,
        }
      );
    });
  };

  const notificationClick = async () => {
    if (document.target === "event") {
      navigate("/" + document.target + "/" + document.targetId);
      window.location.reload();
    }
    updateIsRecognition(notificationsTenth);
    navigate("/" + document.target + "/" + document.targetId);
    handleClose();
  };

  const insideClick = (e) => {
    updateIsRecognition(notificationsTenth);
    navigate("/user/" + document?.fromId);
    handleClose();
    e.stopPropagation();
  };

  const notificationInMessageClick = async () => {
    updateIsRecognitionInMessage(notificationAll);
    navigate("/chat", { state: { otherId: document?.fromId } });
    handleClose();
  };

  const messageClick = (e) => {
    updateIsRecognitionInMessage(notificationAll);
    navigate("/chat", { state: { otherId: document?.fromId } });
    handleClose();
    e.stopPropagation();
  };

  const messages = {
    DDP交換希望リクエスト: {
      from: "さんが",
      target: "DDP",
      message: "の交換を希望しています。",
    },
    DDP交換希望リクエストbuyキャンセル: {
      from: "さんが",
      target: "DDP",
      message: "の交換をキャンセルしました。",
    },
    DDP交換希望リクエストbuyerキャンセル: {
      from: "さんが",
      target: "DDP",
      message: "の交換希望をキャンセルしました。",
    },
    DDP配送完了: {
      from: "さんが",
      target: "DDP",
      message: "の配送を完了しました。",
    },
    DDP提供準備完了: {
      from: "さんの",
      target: "DDP",
      message: "の提供準備が完了しました。",
    },
    DDP受諾: { from: "さんが", target: "DDP", message: "を受諾しました。" },
    DDP受け取り: { from: "さんが", target: "DDP", message: "を受け取りました" },
    DDP買った人評価: {
      from: "さんが",
      target: "DDP",
      message: "の交換を評価しました。",
    },
    DDP取引評価: {
      from: "さんが",
      target: "DDP",
      message: "の交換を評価しました。",
    },
    DDP登録完了: {
      from: "さんの",
      target: "DDP",
      message: "の登録が完了しました。",
    },
    DDPに質問: { from: "さんが", target: "DDP", message: "に質問しました。" },
    グループ参加希望: {
      from: "さんが",
      target: "グループ",
      message: "の参加を希望しています。",
    },
    グループ参加許可: {
      from: "さんが",
      target: "グループ",
      message: "の参加を許可しました。",
    },
    イベント時間変更: {
      from: "さんが",
      target: "イベント",
      message: "の時間を変更しました。",
    },
    イベントタイトル変更: {
      from: "さんが",
      target: "イベント",
      message: "のタイトルを変更しました。",
    },
    イベント場所変更: {
      from: "さんが",
      target: "イベント",
      message: "の場所を変更しました。",
    },
    イベント集合方法変更: {
      from: "さんが",
      target: "イベント",
      message: "の集合方法を変更しました。",
    },
    イベントURL変更: {
      from: "さんが",
      target: "イベント",
      message: "のURLを変更しました。",
    },
    イベント費用変更: {
      from: "さんが",
      target: "イベント",
      message: "の費用を変更しました。",
    },
    イベント質問到着: {
      from: "さんが",
      target: "イベント",
      message: "に質問しました。",
    },
    メッセージ到着: {
      from: "さんから",
      target: "チャット",
      message: "にメッセージが届いています。",
    },
    拠点参加希望: {
      from: "さんが",
      target: "拠点",
      message: "の参加を希望しています。",
    },
    拠点参加許可: {
      from: "さんが",
      target: "グループ",
      message: "の参加を許可しました。",
    },
    拠点名称変更: {
      from: "さんが",
      target: "拠点",
      message: "のタイトルを変更しました。",
    },
  };
  

  const DisplayContent = () => {
    if (!document || !messages[document?.content]) {
      console.log("No document or message content found");
      return null; // documentが存在しない場合、何も表示しない
    }

    return (
      <div
        className="flex flex-col"
        onClick={
          document.content === "メッセージ到着"
            ? notificationInMessageClick
            : notificationClick
        }
      >
        <div>
          <span
            className="font-bold hover:underline hover:text-blue-500 z-50"
            onClick={
              document?.content === "メッセージ到着"
                ? messageClick
                : insideClick
            }
          >
            {document.fromName || "Unknown Sender"}
          </span>
          {messages[document?.content]?.from || ""}
          {messages[document?.content]?.target || ""}
          <span className="font-bold hover:underline hover:text-blue-500 z-50">
            {targetContent?.title || targetContent?.productName || "Unknown Target"}
          </span>
          {messages[document?.content]?.message || ""}
        </div>
        <div className="text-blue-500 text-xs">
          {document.createdAt
            ? formatDistanceToNow(document.createdAt.toDate(), {
                locale: ja,
              })
            : "Unknown Time"}
        </div>
      </div>
    );
  };

  return <DisplayContent />;
};

export default DisplayNotificationContent;
