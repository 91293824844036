import { Button } from "@mui/material";
import React from "react";

const RegisterVillager = () => {
  const moveToSekaimura = () => {
    window.open("https://www.sekaimura.com/", "_blank");
  };

  return (
    <>
      <Button
        sx={{
          width: {
            xs: 156,
            sm: 156,
            md: 144,
          },
        }}
        variant="outlined"
        onClick={moveToSekaimura}
      >
        村民登録へ
      </Button>
    </>
  );
};

export default RegisterVillager;
