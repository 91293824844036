import React, { useEffect, useState } from "react";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { auth, db } from "../api/firebase";

import {  useLocation, useNavigate } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { setDoc, doc, Timestamp, updateDoc } from "firebase/firestore";
import Loading from "./Loading";

// mui-datepicker関連
import { Box } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { format, subYears } from "date-fns";

const TermsOfService = () => {
  // const [open, setOpen] = useState(1);
  const [accessBad, setAccessBad] = useState(true);
  const [fromProvider, setFromProvider] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    bloodtype: "",
    birthyear: "",
    birthmonth: "",
    birthday: "",
    sextype: "",
    prefecture: "",
    error: null,
    loading: true,
  });

  const {
    firstname,
    lastname,
    email,
    bloodtype,
    birthyear,
    birthmonth,
    birthday,
    sextype,
    prefecture,
    error,
    loading,
  } = data;

  // datepicker関連
  const limitedDate = subYears(new Date(), 10);
  const [value, setValue] = useState(new Date(1981, 1, 1));

  useEffect(() => {
    // プロバイダ(Google)から来た場合
    const fromProvide = () => {
      setData({
        ...data,
        lastname: location.state.lastName,
        firstname: location.state.firstName,
        email: location.state.email,
        bloodtype: "UnKnown",
        birthyear: "1980",
        birthmonth: "2",
        birthday: "1",
        sextype: "dontAnswer",
        prefecture: "神奈川県",
        loading: false,
      });
    };
    location.state && fromProvide();

    // Eメールリンクから来た場合
    const fromEmailLink = async () => {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        setAccessBad(true);
      }

      signInWithEmailLink(auth, email, window.location.href).then(
        async (result) => {
          // 新規ユーザの場合
          console.log("result =>", result);
          console.log("Eメール系で飛んできた新規ユーザ");
          if (result._tokenResponse.isNewUser) {
            setData({
              ...data,
              email: email,
              bloodtype: "UnKnown",
              birthyear: "1980",
              birthmonth: "1",
              birthday: "1",
              sextype: "dontAnswer",
              prefecture: "神奈川県",
              loading: false,
            });
            setAccessBad(false);
            setFromProvider(false);
          } else {
            // 新規ユーザじゃない場合　ホームへ
            navigate("/home");
          }
        }
      );
    };
    isSignInWithEmailLink(auth, window.location.href) && fromEmailLink();
  }, []);

  const bloodType = [
    { value: "A", label: "A型" },
    { value: "B", label: "B型" },
    { value: "O", label: "O型" },
    { value: "AB", label: "AB型" },
    { value: "UnKnown", label: "わからない" },
  ];

  const prefectures = [
    { value: "北海道", label: "北海道" },
    { value: "青森県", label: "青森県" },
    { value: "岩手県", label: "岩手県" },
    { value: "宮城県", label: "宮城県" },
    { value: "秋田県", label: "秋田県" },
    { value: "山形県", label: "山形県" },
    { value: "福島県", label: "福島県" },
    { value: "茨城県", label: "茨城県" },
    { value: "栃木県", label: "栃木県" },
    { value: "群馬県", label: "群馬県" },
    { value: "埼玉県", label: "埼玉県" },
    { value: "千葉県", label: "千葉県" },
    { value: "東京都", label: "東京都" },
    { value: "神奈川県", label: "神奈川県" },
    { value: "新潟県", label: "新潟県" },
    { value: "富山県", label: "富山県" },
    { value: "石川県", label: "石川県" },
    { value: "福井県", label: "福井県" },
    { value: "山梨県", label: "山梨県" },
    { value: "長野県", label: "長野県" },
    { value: "岐阜県", label: "岐阜県" },
    { value: "静岡県", label: "静岡県" },
    { value: "愛知県", label: "愛知県" },
    { value: "三重県", label: "三重県" },
    { value: "滋賀県", label: "滋賀県" },
    { value: "京都府", label: "京都府" },
    { value: "大阪府", label: "大阪府" },
    { value: "兵庫県", label: "兵庫県" },
    { value: "奈良県", label: "奈良県" },
    { value: "和歌山県", label: "和歌山県" },
    { value: "鳥取県", label: "鳥取県" },
    { value: "島根県", label: "島根県" },
    { value: "岡山県", label: "岡山県" },
    { value: "広島県", label: "広島県" },
    { value: "山口県", label: "山口県" },
    { value: "徳島県", label: "徳島県" },
    { value: "香川県", label: "香川県" },
    { value: "愛媛県", label: "愛媛県" },
    { value: "高知県", label: "高知県" },
    { value: "福岡県", label: "福岡県" },
    { value: "佐賀県", label: "佐賀県" },
    { value: "長崎県", label: "長崎県" },
    { value: "熊本県", label: "熊本県" },
    { value: "大分県", label: "大分県" },
    { value: "宮崎県", label: "宮崎県" },
    { value: "鹿児島県", label: "鹿児島県" },
    { value: "沖縄県", label: "沖縄県" },
  ];
  const birthYear = [];
  const birthMonth = [];
  const birthDay = [];

  for (let i = 1920; i < 2030; i++) {
    birthYear.push({ value: i, label: i });
  }

  for (let i = 1; i < 13; i++) {
    birthMonth.push({ value: i, label: i });
  }

  for (let i = 1; i < 32; i++) {
    birthDay.push({ value: i, label: i });
  }

  const sex = [
    { value: "男性", label: "男性" },
    { value: "女性", label: "女性" },
    { value: "dontAnswer", label: "回答しない" },
  ];

  const handleChange = async (e) => {
    // muiのdatepickerが、name属性取れないので、
    // isNaN関数を使ってfalseで帰ってきた値にだけ
    // setData()関数を特殊に加工する
    if (!isNaN(e)) {
      // 表示用の日付をセット
      setValue(e);

      const birthyear = format(e, "yyyy");
      const birthmonth = format(e, "M");
      const birthday = format(e, "d");
      // console.log("birthyear =>",birthyear)
      // console.log("birthmonth =>",birthmonth)
      // console.log("birthday =>",birthday)
      // console.log(e)
      setData({
        ...data,
        birthyear: birthyear,
        birthmonth: birthmonth,
        birthday: birthday,
      });
      // console.table(data);
    } else {
      await setData({ ...data, [e.target.name]: e.target.value });
      // console.table(data);
    }
  };

  const BadAccess = () => {
    return (
      <>
        <div>不正なアクセスです。</div>
      </>
    );
  };

  const setUserInfo = async (e) => {
    e.preventDefault();

    // Eメールリンクで飛んできたときの処理
    if (isSignInWithEmailLink(auth, window.location.href)) {
      console.log("Eメールリンクで飛んできた1");

      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          console.log("Eメールリンクで飛んできた2");

          setDoc(doc(db, "users", result.user.uid), {
            uid: result.user.uid,
            lastname,
            firstname,
            email,
            bloodtype,
            birthyear,
            birthmonth,
            birthday,
            sextype,
            prefecture,
            createdAt: Timestamp.fromDate(new Date()),
            isOnline: true,
          });
          window.localStorage.removeItem("emailForSignIn");
          navigate("/home");
        })
        .catch((error) => {
          console.log(error);
          window.localStorage.removeItem("emailForSignIn");
        });
    }

    location.state &&
      updateDoc(doc(db, "users", location.state.uid), {
        bloodtype,
        birthyear,
        birthmonth,
        birthday,
        sextype,
        prefecture,
        firstVisit:true,
      });
    navigate("/home");
  };

  if (loading) return <Loading title="データ" action="準備中" />;
  if (location.key === "default" && accessBad) return <BadAccess />;
  return (
    <>
      <section>
        <div className="flex justify-center ">
          <div className="flex flex-col  w-96 md:w-[784px] justify-center items-center">
            <div className="font-bold text-3xl mb-3 left-0">セカイムラ</div>
            <div className="font-bold text-2xl mb-3">新規メンバー登録</div>
            <form onSubmit={setUserInfo}>
              <div className="flex flex-col space-y-5">
                {/* 姓 */}
                <div className=" ">
                  <TextField
                    id="outlined-required"
                    name="lastname"
                    label="姓"
                    size="small"
                    defaultValue={location.state ? location.state.lastName : ""}
                    placeholder=""
                    className=""
                    onChange={handleChange}
                    required
                    disabled={fromProvider}
                  />
                </div>
                {/* 名前 */}
                <div className="">
                  <TextField
                    id="outlined-required"
                    name="firstname"
                    label="名"
                    defaultValue={
                      location.state ? location.state.firstName : ""
                    }
                    size="small"
                    placeholder=""
                    onChange={handleChange}
                    required
                    disabled={fromProvider}
                  />
                </div>
                {/* EMAIL */}
                <div className="">
                  <TextField
                    id="outlined-required"
                    name="email"
                    label="email"
                    size="small"
                    defaultValue={location.state ? location.state.email : email}
                    placeholder="xxxx@xxxx"
                    onChange={handleChange}
                    required
                    disabled
                  />
                </div>
                {/* 血液型 */}
                <div className="">
                  <TextField
                    id="outlined-select-currency"
                    name="bloodtype"
                    label="血液型"
                    size="small"
                    defaultValue="UnKnown"
                    onChange={handleChange}
                    select
                    required
                  >
                    {bloodType.map((option) => (
                      <MenuItem key={option.value} value={option.value} dense>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* 誕生日 */}
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ja}
                >
                  <Box sx={{ m: 0, width: "20ch" }} size="small">
                    <DatePicker
                      label="生年月日"
                      onChange={handleChange}
                      inputFormat="yyyy年MM月dd日"
                      mask="____年__月__日"
                      value={value}
                      maxDate={limitedDate}
                      size="small"
                      leftArrowButtonText="前月"
                      rightArrowButtonText="次月"
                      toolbarTitle="日付選択"
                      cancelText="キャンセル"
                      okText="選択"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                </LocalizationProvider>

                {/* 性別 */}
                <div className="">
                  <TextField
                    id="outlined-select-currency"
                    name="sextype"
                    label="性別"
                    size="small"
                    defaultValue="dontAnswer"
                    onChange={handleChange}
                    select
                    required
                  >
                    {sex.map((option) => (
                      <MenuItem key={option.value} value={option.value} dense>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* 住所（県） */}
                <div className="">
                  <TextField
                    id="outlined-select-currency"
                    name="prefecture"
                    label="お住まい県"
                    size="small"
                    defaultValue="神奈川県"
                    onChange={handleChange}
                    select
                    required
                  >
                    {prefectures.map((option) => (
                      <MenuItem key={option.value} value={option.value} dense>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <button
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mt-5"
              >
                送信
              </button>

              {error ? error : ""}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfService;
