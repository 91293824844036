import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SlectProton = ({ handleChangeSelect, selects, title }) => {
  // console.log("selects =>", selects);
  const [value, setValue] = useState("");
  useEffect(() => {
    selects?.forEach((doc) => {
      if (doc.selected === true) {
        setValue(doc.value);
      }
    });
  }, [selects]);
  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={title}
          onChange={(e) => handleChangeSelect(e)}
          value={value}
        >
          {selects?.map((select) => (
            <MenuItem key={select.value} value={select.value}>
              {select.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SlectProton;
