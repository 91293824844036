import React, { useState} from "react";
import TextField from "@mui/material/TextField";
import { Avatar } from "@mui/material";

import {
  doc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db} from "../api/firebase";

import {
  useDocumentData,
} from "react-firebase-hooks/firestore";

const RunruSpecialGift = ({ setOpen, requestId }) => {
  // ユーザ取得関連
  const [user] = useDocumentData(doc(db, "users", requestId));
  // const [usersCollection] = useCollection(collection(db, "users"));
  // const users = usersCollection?.docs.map((user) => ({
  //   id: user.data().uid,
  //   ...user.data(),
  // }));

  // const [causes] = useDocumentData(doc(db, "env", "cause"));

  const [product, setProduct] = useState({
    to: "",
    amount: "",
    error: "",
    loading: false,
  });
  const { to,  amount,  error, loading } = product;

  // 値変更
  const handleChangeShop = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    try {
      // 変更処理
      const docRef = await addDoc(collection(db, "runru"), {
        to: requestId,
        from:"運営",
        amount: Number(amount),
        cause: "特別付与",
        createdAt: serverTimestamp(),
      });
      setProduct({
        to: "",
        amount: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  //   console.log(product);
  return (
    <>
      <div>
        <div className="mb-3">ルンル特別付与</div>
        <form onSubmit={setRequestDataShop}>
          {/* 誰が */}
          {/* <div className="my-4" />
          <TextField
            id="outlined-required"
            name="from"
            label="誰のを"
            defaultValue=""
            size="small"
            placeholder=""
            className=" w-full"
            onChange={handleChangeShop}
            required
            disabled={loading}
            select
          >
            {users?.map((user) => (
              <MenuItem value={user.uid}>
                {user.lastName} - {user.uid}
              </MenuItem>
            ))}
          </TextField> */}

          {/* 誰に */}
          {/* <div className="my-4" />
          <TextField
            id="outlined-required"
            name="to"
            label="誰に"
            defaultValue=""
            size="small"
            placeholder=""
            className=" w-full"
            onChange={handleChangeShop}
            required
            disabled={loading}
            select
          >
            {users?.map((user) => (
              <MenuItem value={user.uid}>
                {user.lastName} - {user.uid}
              </MenuItem>
            ))}
          </TextField> */}
          <div>このユーザーにルンルを付与します</div>
          <div className="flex items-center p-2 rounded-md bg-gray-200 space-x-1">
            <div>
              <Avatar src={user?.avatar} />
            </div>
            <div>
              {user?.lastName}
              {user?.firstName}
            </div>
          </div>

          {/* 価格（ルンル） */}
          <div className="my-4">
            <TextField
              id="outlined-required"
              name="amount"
              type="number"
              label="価格(ルンル)"
              defaultValue=""
              placeholder="100"
              size="small"
              onChange={handleChangeShop}
              required
              className="w-full my-4"
              disabled={loading}
            ></TextField>
          </div>

          {/* なぜ */}
          {/* <div className="my-4" />
          <TextField
            id="outlined-required"
            name="cause"
            label="要因"
            defaultValue=""
            size="small"
            placeholder=""
            className=" w-full"
            onChange={handleChangeShop}
            required
            disabled={loading}
            select
          >
            {causes?.cause.map((cause) => (
              <MenuItem value={cause}>{cause}</MenuItem>
            ))}
          </TextField> */}

          {/* 登録ボタン */}
          <div className="my-4" />
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            {loading ? "送信しています..." : "登録"}
          </button>
        </form>
        {error ? error : ""}
      </div>
    </>
  );
};

export default RunruSpecialGift;
