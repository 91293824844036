import React, { useState, useEffect, useRef, memo } from "react";
import {
  getDocs,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../api/firebase";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import DisplayPost from "./DisplayPost";
import { CircularProgress } from "@mui/material";

const TestTest = () => {
  const displayCount = 5;

  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(displayCount);
  const [fetching, setFetching] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const feedRef = useRef(null);

  // オブサーバーでキックし、次の投稿を取得
  useEffect(() => {
    const fetchNextPosts = async () => {
      if (!hasMorePosts) return;

      try {
        setFetching(true);
        setIsLoading(true);

        await new Promise((resolve) => setTimeout(resolve, 2000));

        const querySnapshots = await getDocs(
          query(
            collection(db, "post"),
            orderBy("updatedAt", "desc"),
            startAfter(posts[posts.length - 1]?.updatedAt || null),
            limit(displayCount)
          )
        );

        const newPosts = querySnapshots.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (newPosts.length === 0) {
          setHasMorePosts(false);
        } else {
          setPosts((prevPosts) => [...prevPosts, ...newPosts]);
        }

        setIsLoading(false);
        setFetching(false);
      } catch (error) {
        console.error("Error fetching next posts:", error);
        setIsLoading(false);
        setFetching(false);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !fetching) {
          if (visiblePosts >= displayCount && visiblePosts <= posts.length) {
            fetchNextPosts();
          }
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.9 }
    );

    if (feedRef.current) {
      observer.observe(feedRef.current);
    }

    return () => {
      if (feedRef.current) {
        observer.unobserve(feedRef.current);
      }
    };
  }, [visiblePosts, fetching, posts, hasMorePosts]);

  // 初回に表示する投稿を取得
  useEffect(() => {
    const fetchInitialPosts = async () => {
      try {
        const querySnapshots = await getDocs(
          query(
            collection(db, "post"),
            orderBy("updatedAt", "desc"),
            limit(displayCount)
          )
        );

        const initialPosts = querySnapshots.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(initialPosts);
        setVisiblePosts(displayCount);
        setHasMorePosts(querySnapshots.size > 0);
      } catch (error) {
        console.error("Error fetching initial posts:", error);
      }
    };

    fetchInitialPosts();
  }, []);

  // console.log("posts =>", posts);

  return (
    <>
      {posts?.map((post) => (
        <DisplayPost key={post.id} post={post} />
      ))}
      <div ref={feedRef} className="p-4  ">
        {isLoading ? (
          <>
            <div className="flex space-x-3 items-center">
              <CircularProgress />
              <p>データ読み込み中...</p>
            </div>
          </>
        ) : (
          <div style={{ height: "1px" }}></div>
        )}
      </div>
    </>
  );
};

export default memo(TestTest);
