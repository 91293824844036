import {  collection, serverTimestamp, addDoc } from "firebase/firestore";
import { db } from "../api/firebase";

const addError = async (error, user, func) => {
  await addDoc(collection(db, "error"), {
    createdAt: serverTimestamp(),
    user: user.uid,
    page: window.location.href,
    operate: "",
    error: error.message,
    function: func.name,
  });
};

export default addError
