import React, { useEffect, useState } from "react";
import { getDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../api/firebase";
// redux - use
import { useNotification } from "../redux/useNotification";

const DeleteEvent = ({ setOpen, requestId }) => {
  const [event, setEvent] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // redux - Notification
  const { displayNotification } = useNotification();

  // リクエスト取得
  useEffect(() => {
    if (requestId) {
      getDoc(doc(db, "event", requestId)).then((doSnap) => {
        if (doSnap.exists) {
            setEvent(doSnap.data());
        }
      });
    }
  }, [requestId]);
  //   削除処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // 削除
      await deleteDoc(doc(db, "event", requestId));
      setOpen(false);
      displayNotification({
        message: "グループを削除しました。",
        type: "success",
      });
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        グループ削除
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="font-bold text-2xl">このイベントを削除します。</div>
        <div className="border-2 p-2 rounded-md mb-2">
          <div className="text-gray-300">イベント</div>
          <div>{event?.title}</div>
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "削除しています..." : "削除"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DeleteEvent;
