import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../api/firebase";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";
import { tabsClasses } from "@mui/material/Tabs";
import { maxWidth } from "@mui/system";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import GlobalStyles from "@mui/material/GlobalStyles";

const Offers = () => {
  const { search } = useParams();
  const navigate = useNavigate();

  // offring取得
  const [offering, setOffering] = useState([]);
  const [offerLoading, setOfferLoading] = useState(true);
  useEffect(() => {
    const getOffers = async () => {
      const arr = [];
      const offers = await getDocs(collection(db, "ddp"));
      offers?.docs.map((doc) => {
        arr.push(doc.data().offering);
      });
      setOffering(arr);
      setOfferLoading(false);
    };

    getOffers();
  }, [offerLoading]);

  const moveDDP = (offer) => {
    // console.log(offer)
    offer === "all" ? navigate(`/ddp/`) : navigate(`/ddp/${offer}`);
  };

  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    event.target.innerText === "all"
      ? navigate(`/ddp/`)
      : navigate(`/ddp/${event.target.innerText}`);
  };

  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          Tab {
            height: 8;
          }
        `,
      },
    },
  });

  return (
    <>
      {/* <GlobalStyles styles={{ Tab: { height: 8 } }} /> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            maxWidth: { xs: 320, sm: 600, md: 900, lg: 1200, xl: 1532 },
            width: "100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {/* すべてタグ */}
            <div
              className="flex rounded-xl border bg-black text-white  hover:cursor-pointer p-1 mx-2 transform hover:scale-110 transition-transform items-center"
              onClick={() => moveDDP("all")}
              key={Math.random()}
            >
              すべて
            </div>
            {offering?.map((offer) => {
              return (
                <div
                  className={` ${
                    search === offer && "border-blue-500 bg-blue-500 text-white"
                  } rounded-xl border bg-gray-200  hover:cursor-pointer p-1 mx-2 transform hover:scale-110 transition-transform`}
                  onClick={() => moveDDP(offer)}
                  key={Math.random()}
                >
                  <Tab sx={{ p: 0, fontSize:16 }} label={offer} />
                </div>
              );
            })}
          </Tabs>
        </Box>
      </ThemeProvider>
      <div className="flex">
        {/* すべてタグ */}
        {/* <div
          className="rounded-xl border bg-black text-white  hover:cursor-pointer p-1 mx-2 transform hover:scale-110 transition-transform"
          onClick={() => moveDDP("all")}
          key={Math.random()}
        >
          すべて
        </div> */}
        {/* {offering?.map((offer) => {
          return (
            <div key={Math.random()}>
              <div
                className={` ${
                  search === offer && "border-blue-500 bg-blue-500 text-white"
                } rounded-xl border bg-gray-200  hover:cursor-pointer p-1 mx-2 transform hover:scale-110 transition-transform`}
                onClick={() => moveDDP(offer)}
                key={Math.random()}
              >
                {offer}
              </div>
            </div>
          );
        })} */}
      </div>
    </>
  );
};

export default Offers;
// function LinkTab(props) {
//   return (
//     <Tab
//       component="a"
//       onClick={(event) => {
//         event.preventDefault();
//       }}
//       {...props}
//     />
//   );
// }
