import React, { memo, useState } from "react";
import DropdownItem from "./DropdownItem";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getDocs, query, collection, where } from "firebase/firestore";
import { db } from "../api/firebase";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import CoPresentIcon from '@mui/icons-material/CoPresent';
const QuestionForAdminIcon = () => {
  // 管理者画面へ移動
  const navigate = useNavigate();
  const handleToAdminSetting = () => {
    navigate("/questionforadmin");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  //   質問数
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    let arr = [];
    const init = async () => {
      const q = query(
        collection(db, "questionForAdmin"),
        where("isResolution", "==", false)
      );
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setQuestions(arr);
      });
    };
    init();
  }, []);

  return (
    <>
      <DropdownItem
        title="問合応対"
        Icon={CoPresentIcon}
        onClick={handleToAdminSetting}
        complete
        notification={questions?.length}
      />
    </>
  );
};

export default memo(QuestionForAdminIcon);
