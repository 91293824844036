import React, { useEffect, useState } from "react";

import { getDoc, doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import Loader1Row from "./Loader1Row";

const Account = () => {
  const [user, setUser] = useState("");

  useEffect(() => {
    const getUserInfo = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
        if (doSnap.exists) {
          setUser(doSnap.data());
        }
      });
    };
    getUserInfo();
  }, []);

  if (!user) return <Loader1Row />;

  return (
    <>
      <div
        className={`${
          user.isAdmin ? "block" : "hidden"
        } " flex flex-col rounded-md bg-white border  my-2 p-2 hover:shadow-2xl hover:cursor-pointer"`}
      >
        <div>管理者のみ表示</div>
        <div>user_uid:{user.uid}</div>
        <div>auth_uid:{auth.currentUser.uid}</div>
      </div>

    </>
  );
};

export default Account;
