// Informations.js

import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import AnnouncementList from "./AnnouncementList";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { db } from "../api/firebase";

const Informations = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "informationsList"));
        const result = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(result);
        setFilteredData(result);
      } catch (error) {
        console.error("Error fetching data from Firebase:", error);
      }
    };

    fetchData();
  }, []); 

//   console.log("data =>",data)

  const handleSearch = (searchTerm) => {
    // console.log("searchTerm =>",searchTerm)
    const searchValue = searchTerm.target.value;
    // console.log("searchValue =>",searchValue)
    const filteredResult = data.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.explain.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filteredResult);
  };

  return (
    <div>
      <h1>カスタマーサポートページ</h1>
      <SearchBar data={data} changeInput={handleSearch} placeholder="検索" />
      <AnnouncementList announcements={filteredData} />
    </div>
  );
};

export default Informations;
