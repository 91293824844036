import React, { useState } from "react";
import { db } from "../api/firebase";
import {  query, collection, orderBy } from "firebase/firestore";
import {
  useCollectionData,
} from "react-firebase-hooks/firestore";
import FormComment from "./FormComment";
import DisplayMessage from "./DisplayMessage";

const DealMessages = ({ dealId }) => {
  const q = query(collection(db, `deal/${dealId}/messages`), orderBy("sentAt"));
  const [messages] = useCollectionData(q);


  // const [chat] = useDocumentData(doc(db, `chats/${dealId}`));
  const [heightChange, setHeightChange] = useState(false);


  return (
    <>
      {/* メッセージやりとり */}
      <DisplayMessage messages={messages} />

      {/* メッセージ入力フォーム */}
      <div className={`flex-none items-center mx-2`}>
        <FormComment
          id={dealId}
          collectionName="deal"
          setHeightChange={setHeightChange}
          addItems={false}
        />
      </div>
    </>
  );
};

export default DealMessages;
