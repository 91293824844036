import React, { useEffect, useRef, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
// redux - use
import { useNotification } from "../redux/useNotification";
import { Avatar, Button, IconButton, TextareaAutosize } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

const InputPostComment = ({ post }) => {
  const { displayNotification } = useNotification();

  //   User取得
  const [user, setUser] = useState("");
  useEffect(() => {
    const init = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((elm) =>
        setUser({ id: elm.id, ...elm.data() })
      );
    };
    init();
  }, []);

  // 入力データコンテナ
  const [data, setData] = useState({
    message: "",
    error: "",
    loading: false,
    errorMessage: "",
  });
  const { message, error, loading, errorMessage } = data;

  const handleChange = (e) => {
    // console.log("e.target.value =>", e.target.value);
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  //   console.log("message =>", message.length);
  //   console.log("loading =>", loading);

  const inputRef = useRef();

  // 処理
  const handleSubmit = async (e) => {
    if (!user?.isVillager) return;

    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });

    const create = async () => {
      try {
        // コレクションに追加
        const docRef = await addDoc(collection(db, "comment"), {
          post: post,
          postId: post.id,
          message,
          avatar: user?.avatar,
          name: user?.lastName + user?.firstName,
          creator: auth.currentUser.uid,
          isPublish: true,
          isPermission: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        await updateDoc(doc(db, "post", post?.id), {
          updatedAt: serverTimestamp(),
          reason: "",
        });

        inputRef.current.value = "";
        displayNotification({
          message: "投稿を完了しました。",
          type: "success",
        });

        setData({
          ...data,
          error: null,
          message: "",
        });
        // window.location.reload();
      } catch (err) {
        const errorData = {
          message: err.message,
          stack: err.stack,
        };
        console.log(err.message);
        setData({
          ...data,
          error: err.message,
          loading: false,
          errorMessage: err.message,
        });
        addDoc(collection(db, "error"), {
          createdAt: serverTimestamp(),
          error: errorData,
          component: "Feeds.js",
          creator: auth.currentUser.uid,
        });
      }
    };
    create();
  };

  return (
    <>
      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="flex space-x-2">
            <Avatar src={user?.avatar} sx={{ width: "30px", height: "30px" }} />
            <div className="flex justify-between w-full bg-gray-100 rounded-md">
              <textarea
                style={{ resize: "none", fontSize: "16px" }}
                type="text"
                name="message"
                className="flex px-3 pt-2 w-full bg-back rounded-l-md "
                placeholder="コメントする"
                onChange={handleChange}
                ref={inputRef}
              ></textarea>
              <div className="flex justify-end p-2">
                <IconButton
                  name="message"
                  onClick={handleSubmit}
                  disabled={loading || message.length === 0}
                >
                  <SendIcon
                    className={` ${
                      loading || message.length === 0
                        ? "text-gray-300"
                        : "text-blue-500"
                    } "   "`}
                  />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="text-red-500">{errorMessage ? errorMessage : ""}</div>
          {/* <Button
            className=""
            variant="contained"
            name="message"
            onClick={handleSubmit}
            disabled={loading || !message}
          >
            {loading ? "送信しています..." : "送信"}
          </Button> */}
        </form>
      </div>
    </>
  );
};

export default InputPostComment;
