import React, { useState } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { auth, db } from "../api/firebase";

// mui-datepicker関連
import { Box } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { format, subYears } from "date-fns";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useDocumentData } from "react-firebase-hooks/firestore";

const EditBirthDate = ({setOpen}) => {
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // datepicker関連
  const limitedDate = subYears(new Date(), 10);
  const [value, setValue] = useState(new Date(1980, 5, 30));

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    birthyear: "",
    birthmonth: "",
    birthday: "",
    birthDate: false,
    message: "",
    error: null,
    loading: false,
  });
  const {
    birthyear,
    birthmonth,
    birthday,
    message,
    error,
    loading,
  } = data;

  // プロフィール変更
  const handleChange = (e) => {
    // muiのdatepickerが、name属性取れないので、
    // isNaN関数を使ってfalseで帰ってきた値にだけ
    // setData()関数を特殊に加工する
    if (!isNaN(e)) {
      // 表示用の日付をセット
      setValue(e);
      const birthyear = format(e, "yyyy");
      const birthmonth = format(e, "M");
      const birthday = format(e, "d");
      setData({
        ...data,
        birthyear: birthyear,
        birthmonth: birthmonth,
        birthday: birthday,
        birthDate: true,
      });
      return false;
    }
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄か、変更がないようです" });
        // handleEditOff()
        return false;
      }

      // 誕生日変更の場合
      if (e.target.name === "birthDate") {
        await updateDoc(doc(db, "users", user?.uid), {
          birthyear: birthyear,
          birthmonth: birthmonth,
          birthday: birthday,
        });
        setData({
          birthyear: "",
          birthmonth: "",
          birthday: "",
          error: null,
          loading: false,
        });
        setOpen(false)
        // window.location.reload();
      }
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>

        <div className="mb-2">生年月日</div>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <Box sx={{  width: "100%" }}>
            <DatePicker
              label="生年月日"
              onChange={handleChange}
              inputFormat="yyyy年MM月dd日"
              mask="____年__月__日"
              value={value}
              maxDate={limitedDate}
              leftArrowButtonText="前月"
              rightArrowButtonText="次月"
              toolbarTitle="日付選択"
              cancelText="キャンセル"
              okText="選択"
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>

        <div className="text-red-500">{message ? message : ""}</div>
        <Button
          className="btn"
          name="birthDate"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditBirthDate;
