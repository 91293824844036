import React, { useState } from "react";
import { Button } from "@mui/material";
import ModalForm from "./ModalForm";
import { useParams } from "react-router-dom";

const CompulsionDealStop = ({ status }) => {
    const { id } = useParams();

    //   Modalのプロパティたち
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState("");
    const [requestId, setRequestId] = useState(id);
    const handleEdit = (e, id) => {
        setOpen(true);
        setForm(e);
        setRequestId(id);
    };


    return (
        <>
            <ModalForm
                form={form}
                setOpen={setOpen}
                open={open}
                requestId={requestId}
            />

            {/* 交換リクエスト待ち */}
            <div>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={(e) => handleEdit("adminCompulsionDealStop", id)}
                >
                    強制終了させる（ルンルは管理者画面で強制移動必須）
                </Button>
            </div>
        </>
    );
};

export default CompulsionDealStop;
