import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import { usePostalJp } from "use-postal-jp";
import { Button, TextField } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { useParams } from "react-router-dom";

// redux - use
import { useNotification } from "../redux/useNotification";

const AddAddressFromPostCode = ({ target }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const { id } = useParams();

  const [value, setValue] = useState("");
  const [address, loading, error] = usePostalJp(value, value.length >= 7);

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    postcode: "",
    addressData: "",
    messageData: "",
    errorData: null,
    loadingData: false,
  });
  const { postcode, addressData, messageData, errorData, loadingData } = data;

  const handleChange = (postcode) => {
    postcode.length >= 7 ? setValue(postcode) : setValue("");
  };

  // redux - Notification呼び出し
  const noAddressMessage = () => {
    displayNotification({
      message: "住所が不明です",
      type: "error",
    });
  };
  // redux - Notification呼び出し
  const successRegist = () => {
    displayNotification({
      message: "登録しました",
      type: "success",
    });
  };
  const noMatchPrefecture = () => {
    displayNotification({
      message: "県が異なっているようです",
      type: "error",
    });
  };


//   console.log("address =>", address);
  const hadleRegist = async (e) => {
    if (!address) {
      noAddressMessage();
    }

    e.preventDefault();
    setData({ ...data, error: null, loading: true });

    if(target.prefecture !== address?.prefecture){
        noMatchPrefecture()
        return
    }

    try {
      await updateDoc(doc(db, "base", id), {
        postcode: value,
        dummyAddress:
          address?.prefecture +
          address?.address1 +
          address?.address2 +
          address?.address3 +
          address?.address4,
      });
      successRegist()
      window.location.reload()

    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      {/* <div className="flex space-x-2  items-center">
        <div className="flex-none w-32 inline-block align-middle   ">
          住所
        </div>
        <div className="w-full p-2 border rounded-md">{target?.title}</div>
        <div
          className="border p-3 rounded-full h-fit hover:shadow-lg"
          onClick={() => handleEdit("editBaseTitle")}
        >
          <EditIcon />
        </div>
      </div> */}
      <div>
        <TextField
          id="search-bar"
          name="postcode"
          className="my-2"
          label="郵便番号"
          variant="outlined"
          placeholder="ハイフン(-)なし"
          size="small"
          onChange={(e) => handleChange(e.target.value)}
          type="number"
          inputProps={{
            maxLength: 7,
          }}
        ></TextField>

        {/* <input type="text" onChange={(e) => setValue(e.target.value)} /> */}
        {!loading && (
          <>
            <div className="my-2">
              {address?.prefecture}
              {address?.address1}
              {address?.address2}
              {address?.address3}
              {address?.address4}
            </div>
            <Button
              onClick={hadleRegist}
              disabled={value.length !== 7 || !address}
            >
              登録
            </Button>
            {/* <p>
              prefectureCode:{" "}
              {address?.prefectureCode && address?.prefectureCode}
            </p>
            <p>prefecture: {address?.prefecture && address?.prefecture}</p>
            <p>address1: {address?.address1 && address?.address1}</p>
            <p>address2: {address?.address2 && address?.address2}</p>
            <p>address3: {address?.address3 && address?.address3}</p>
            <p>address4: {address?.address4 && address?.address4}</p>
            <p>error: {error && error.message}</p> */}
          </>
        )}
      </div>
    </>
  );
};

export default AddAddressFromPostCode;
