import { Divider } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../api/firebase";
import NotificationOfHomeTopContent from "./NotificationOfHomeTopContent";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import { TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import CampaignIcon from "@mui/icons-material/Campaign";

const NotificationOfHomeTop = () => {
  const [news, setNews] = useState([]);
  const today = new Date();

  const q = query(
    collection(db, "notificationOfTop"),
    where("publishDate", ">", today),
    where("limit", "==", false),
    where("isPublish","==",true)
  );

  useEffect(() => {
    const initSetNews = async () => {
      let arr = [];
      getDocs(q).then((docs) => {
        docs?.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        // ソートをここで行う
        arr.sort((a, b) => b.createdAt - a.createdAt);
        setNews(arr);
      });
    };
    initSetNews();
  }, []);

  const [fixs, setFixs] = useState([]);
  const q_fixed = query(
    collection(db, "notificationOfTop"),
    where("limit", "==", true),
    where("isPublish","==",true)

  );

  useEffect(() => {
    const initSetFixs = async () => {
      let arr = [];
      getDocs(q_fixed).then((docs) => {
        docs?.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        // ソートをここで行う
        arr.sort((a, b) => b.createdAt - a.createdAt);
        setFixs(arr);
      });
    };
    initSetFixs();
  }, []);

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="rounded-md mx-2 bg-white">
        <TabContext value={value}>
          <Tabs
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={value}
            sx={{ margin: 0 }}
          >
            <Tab
              icon={<FiberNewIcon />}
              sx={{ padding: 0, minHeight: "50px" }}
              label="最新"
              value="1"
            />
            <Tab
              icon={<CampaignIcon />}
              sx={{ padding: 0, minHeight: "50px" }}
              label="おしらせ"
              value="2"
            />
          </Tabs>
          <TabPanel
            sx={{
              paddingX: 0,
              paddingY: 1,
              maxHeight: "300px",
              overflowY: "auto",
            }}
            value="1"
          >
            {news?.length === 0 && (
              <div className="flex w-full justify-center">
                最新ニュースはありません。
              </div>
            )}
            {news?.map((content, idx) => (
              <NotificationOfHomeTopContent
                content={content}
                key={content?.id}
              />
            ))}
          </TabPanel>
          <TabPanel
            sx={{
              paddingX: 0,
              paddingY: 1,
              maxHeight: "300px",
              overflowY: "auto",
            }}
            value="2">
            {fixs?.map((content, idx) => (
              <NotificationOfHomeTopContent
                content={content}
                key={content?.id}
              />
            ))}
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

export default NotificationOfHomeTop;
