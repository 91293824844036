import { collection, getDocs } from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import { auth, db } from "../api/firebase";
import DisplayUserCard from "./DisplayUserCard";

const Neighbors = ({ prefecture }) => {
  const regionMapping = {
    北海道: "北海道",
    青森県: "東北",
    岩手県: "東北",
    宮城県: "東北",
    秋田県: "東北",
    山形県: "東北",
    福島県: "東北",
    茨城県: "関東",
    栃木県: "関東",
    群馬県: "関東",
    埼玉県: "関東",
    千葉県: "関東",
    東京都: "関東",
    神奈川県: "関東",
    新潟県: "甲信越",
    富山県: "北陸",
    石川県: "北陸",
    福井県: "北陸",
    山梨県: "甲信越",
    長野県: "甲信越",
    岐阜県: "東海",
    静岡県: "東海",
    愛知県: "東海",
    三重県: "東海",
    滋賀県: "近畿",
    京都府: "近畿",
    大阪府: "近畿",
    兵庫県: "近畿",
    奈良県: "近畿",
    和歌山県: "近畿",
    鳥取県: "中国",
    島根県: "中国",
    岡山県: "中国",
    広島県: "中国",
    山口県: "中国",
    徳島県: "四国",
    香川県: "四国",
    愛媛県: "四国",
    高知県: "四国",
    福岡県: "九州",
    佐賀県: "九州",
    長崎県: "九州",
    熊本県: "九州",
    大分県: "九州",
    宮崎県: "九州",
    鹿児島県: "九州",
    沖縄県: "沖縄",
  };

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersArray = [];
      const currentUserUid = auth.currentUser.uid;

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (
          // userData.uid !== currentUserUid &&
          userData.isVillager !== false &&
          userData.isStop !== true &&
          userData.uid !== "YmX93fqfCQee9IdgqOBLD58rakt1" &&
          userData.uid !== "oRSoeN1rlVSiZmt6px68GyhgP6j1" &&
          userData.uid !== "Jv7j6jBNu0URpJS3bPsv2dNCTii1" &&
          userData.uid !== "tY8D6m0eJJM0jXeKg6GHe4b3BkT2"
        ) {
          usersArray.push(userData);
        }
      });

      setUsers(usersArray);
    };

    getUsers();
  }, []);

  // 県から地域を取得する関数
  const getRegionFromPrefecture = (prefecture) => {
    return regionMapping[prefecture] || "不明";
  };

  // 現在の県に基づいて地域を取得
  const region = getRegionFromPrefecture(prefecture);

  // 地域に基づいてユーザーを分類する関数
  const classifyUsers = (users, prefecture) => {
    const samePrefectureUsers = users.filter(
      (user) => user.prefecture === prefecture
    );
    const sameRegionUsers = users.filter(
      (user) =>
        getRegionFromPrefecture(user.prefecture) === region &&
        user.prefecture !== prefecture
    );

    return { samePrefectureUsers, sameRegionUsers };
  };

  const { samePrefectureUsers, sameRegionUsers } = classifyUsers(
    users,
    prefecture
  );

  return (
    <div>
      <div className="flex justify-between">
        <h2>{prefecture}に住む村民さん</h2>
        {/* <h2>{samePrefectureUsers.length}名</h2> */}
      </div>
      {samePrefectureUsers.map((user, index) => (
        <DisplayUserCard user={user} key={index} />
      ))}

      <div className="flex justify-between">
        <h2>{region}に住む村民さん</h2>
        {/* <h2>{sameRegionUsers.length}名</h2> */}
      </div>
      {sameRegionUsers.map((user, index) => (
        <DisplayUserCard user={user} key={index} />
      ))}
    </div>
  );
};

export default memo(Neighbors);
