import React, { useContext, useState, useEffect } from "react";
import { ChatContext } from "stream-chat-react";

import "./MessagingChannelPreview.css";
import { Avatar } from "@mui/material";
import Img from "../../data/img/defaultImage.png";

import GroupsIcon from "@mui/icons-material/Groups";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DirectMessageChatInNotification from "../DirectMessageChatInNotificaton";
import { getDoc, doc } from "firebase/firestore";
import { db, auth } from "../../api/firebase";
import ForestIcon from "@mui/icons-material/Forest";

const getAvatarGroup = (members) => {
  if (members.length === 1) {
    return (
      <Avatar
        key={Math.random()}
        src={members[0]?.user?.image || Img}
        name={members[0].user?.name}
      />
    );
  }

  if (members.length > 1) {
    return (
      <div className="flex">
        <span>
          <Avatar
            src={members[0]?.user?.image || Img}
            name={members[0].user?.name}
          />
        </span>
        <span>
          <Avatar
            src={members[1]?.user?.image || Img}
            name={members[1].user?.name}
          />
        </span>
      </div>
    );
  }

  return null;
};

const getTimeStamp = (channel) => {
  let lastHours = channel.state.last_message_at?.getHours();
  let lastMinutes = channel.state.last_message_at?.getMinutes();
  let half = "AM";

  if (lastHours === undefined || lastMinutes === undefined) {
    return "";
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12;
    half = "PM";
  }

  if (lastHours === 0) lastHours = 12;
  if (lastHours === 12) half = "PM";

  if (lastMinutes.toString().length === 1) {
    lastMinutes = `0${lastMinutes}`;
  }

  return `${lastHours}:${lastMinutes} ${half}`;
};

const getChannelName = (members) => {
  const defaultName = "セカイムラ";

  if (!members.length || members.length === 1) {
    return members[0]?.user.name || defaultName;
  }

  return `${members[0]?.user.name || defaultName}, ${
    members[1]?.user.name || defaultName
  }`;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MessagingChannelPreviewInNotification = (props) => {
  const {
    channel,
    latestMessage,
    handleOpenChat,
    handleCloseChat,
    openChat,
    handleClose,
  } = props;

  const { channel: activeChannel, client } = useContext(ChatContext);

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== client.userID
  );
  const id = channel.data.id;

  const [user, setUser] = useState("");
  useEffect(() => {
    const initSetUser = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
    };
    initSetUser();
  }, []);

  // ModalFormで使用
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <>
      {/* <Modal
        open={openChat}
        onClose={handleCloseChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className="flex justify-between">
              <div className="font-bold text-xl">CHAT</div>
              <div className="font-bold text-2xl" onClick={handleCloseChat}>☓</div>
            </div>
            <DirectMessageChatInNotification user={user} chatId={id} />
          </div>
        </Box>
      </Modal> */}

      <div
        // className={` ${
        //   channel?.state.unreadCount > 0 && " bg-blue-100"
        // }  " flex items-center justify-between mx-2 mb-1 hover:shadow-md p-2 rounded-md hover:cursor-pointer duration-200 w-fit"`}
        // className={` ${channel?.state.unreadCount > 0 && " bg-blue-500"}
        //   ${
        //     channel?.id === activeChannel?.id
        //       ? "channel-preview__container selected"
        //       : "channel-preview__container "
        //   } `}
        className="channel-preview__container"
        onClick={() => {
          // console.log("channel =>", channel?.id);
          // handleOpen()
          handleOpenChat(id);
          handleClose();
        }}
      >
        {/* <div className="mx-2">
          <Badge
            color="primary"
            variant="dot"
            invisible={!channel?.state.unreadCount}
          ></Badge>
        </div> */}
        {id.indexOf("groupChat-") !== -1 ? (
          <>
            <div className=" text-xs bg-blue-500 text-white rounded-full p-1">
              <GroupsIcon />
            </div>
          </>
        ) : id.indexOf("eventChat-") !== -1 ? (
          <>
            <div className=" text-xs bg-green-500 text-white rounded-full p-1">
              <EventNoteIcon />
            </div>
          </>
        ) : id.indexOf("baseChat-") !== -1 ? (
          <>
            <div className=" text-xs bg-pink-500 text-white rounded-full p-1">
              <ForestIcon />
            </div>
          </>
        ) : (
          getAvatarGroup(members)
        )}
        <div className="channel-preview__content-wrapper">
          <div className="channel-preview__content-top">
            <div className="channel-preview__content-name font-bold">
              {channel?.data.name || getChannelName(members)}
            </div>
            <div className="channel-preview__content-time">
              {getTimeStamp(channel)}
            </div>
          </div>
          <div className="channel-preview__content-message">
            {latestMessage || "メッセージ"}
          </div>
        </div>
      </div>
    </>
  );
};

export default MessagingChannelPreviewInNotification;
