import React, { useState, useRef, useEffect } from "react";
import { SearchIcon, XIcon } from "@heroicons/react/solid";

import { db, auth } from "../api/firebase";

import {
  addDoc,
  collection,
  query,
  where,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import ResearchResultUsers from "./ResearchResultUsers";

const AddMemberInChat = ({ setOpen, requestId }) => {
  // ユーザ取得関連
  //   const usersRef = collection(db, "users");
  //   const [usersCollection] = useCollection(
  //     query(usersRef)
  //   );
  //   const users = usersCollection?.docs.map((user) => ({
  //     id: user.data().uid,
  //     ...user.data(),
  //   }));

  const [product, setProduct] = useState({
    amount: "",
    error: "",
    loading: false,
  });
  const { amount, error, loading } = product;

  // // 値変更
  // const handleChangeShop = (e) => {
  //   setProduct({
  //     ...product,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    
    const addRunruForVillager = async (user) => {
      try {
        // 変更処理
        const docRef = await addDoc(collection(db, "runru"), {
          to: user.uid,
          from: "運営",
          amount: Number(amount),
          cause: "ユーザ全体付与",
          createdAt: serverTimestamp(),
        });
        // window.location.reload();
        setOpen(false);
      } catch (err) {
        setProduct({
          ...product,
          error: err.message,
          loading: false,
        });
      }
    };
    users?.forEach((user) => {
      addRunruForVillager(user);
    });
  };

  const searchRef = useRef("");
  const [input, setInput] = useState(false);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "users"),
      where("uid", "not-in", [auth.currentUser.uid])
      //   where("isVillager", "==", true)
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      setUsers(users);
    });
    return () => unsub();
  }, []);

  const searchMemberInMessage = (e) => {
    if (e.target.value) {
      setInput(true);
    } else {
      setInput(false);
    }
    messageMember(e.target.value);
  };
  //   input内のテキスト全消去
  const clearInput = () => {
    searchRef.current.value = "";
    setInput(false);
    setSearch("");
  };

  const messageMember = async (value) => {
    if (users) {
      const result = await users?.filter(
        ({ firstName, lastName }) =>
          ~firstName.indexOf(value) || ~lastName.indexOf(value)
      );

      // console.log("result.length => ", result.length);
      // console.log(result)
      if (result.length) {
        if (!searchRef.current.value) {
          setSearch("");
        } else {
          setSearch(result);
        }
      } else {
        setSearch("");
      }
    }
  };

  //   console.log(product);
  return (
    <>
      <div>
        <div className="mb-3">チャットにメンバーを追加</div>
        <form onSubmit={setRequestDataShop}>
          <div className="flex relative mt-2 items-center rounded-full bg-gray-100 p-2 w-full z-20">
            <SearchIcon className="h-6 text-gray-600" />
            <input
              className=" md:inline-flex ml-2 w-full items-center bg-transparent outline-none "
              ref={searchRef}
              type="text"
              placeholder="メンバーを検索"
              onChange={searchMemberInMessage}
            />
            <XIcon
              className={`${
                !input ? " invisible" : " visible"
              } h-6 text-gray-400 duration-150 hover:cursor-pointer hover:text-gray-600`}
              onClick={clearInput}
            />
          </div>
          {/* サーチしてヒットした人 */}
          <ResearchResultUsers search={search} />

          {/* 登録ボタン */}
          <div className="my-4" />
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
            >
            {loading ? "送信しています..." : "登録"}
          </button>
              {error ? error :""}
        </form>
      </div>
    </>
  );
};

export default AddMemberInChat;
