import React from "react";
import ContentLoader from "react-content-loader";

const LoaderName = (props) => {
  return (
    <>
   <ContentLoader 
    speed={0.5}
    width={160}
    height={288}
    className="w-full"
    backgroundColor="#d9e4d8"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="-43" y="-13" rx="0" ry="0" width="1160" height="56" />
  </ContentLoader>

    </>
  );
};

export default LoaderName;
