import { Snackbar, Alert } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNotification } from "../redux/useNotification";
import { SnackbarProvider } from "notistack";

export const Notification = () => {
  const notification = useSelector((state) => state.notification);
  const { clearNotification } = useNotification();

  const handleClose = (reason) => reason !== "clickaway" && clearNotification();

  // Mui.Alertのseverityがtyp。typeはそれぞれ、
  // error
  // warning
  // info
  // success　 、の４つ
  // https://mui.com/material-ui/react-snackbar/

  return (
    <SnackbarProvider maxSnack={3}>
      <Snackbar
        open={notification.open}
        autoHideDuration={notification.timeout}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={notification.type}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </SnackbarProvider>
  );
};
