import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";
import _debounce from "lodash.debounce";

import { XButton, XButtonBackground } from "../../assets";

import { useChannelStateContext } from "stream-chat-react";

import "./UpdateMember.css";
import { Drawer } from "@mui/material";
import { auth } from "../../api/firebase";
// redux - use
import { useNotification } from "../../redux/useNotification";

const UserResult = ({ user }) => (
  <li className="messaging-create-channel__user-result">
    <Avatar image={user.image} name={user.name || user.id} size={40} />
    {user.online && (
      <div className="messaging-create-channel__user-result-online" />
    )}
    <div className="messaging-create-channel__user-result__details">
      <span>{user.name}</span>
      <span className="messaging-create-channel__user-result__details__last-seen">
        {user.online}
      </span>
    </div>
  </li>
);

// チャンネル作成
const UpdateMember = ({
  onClose,
  setIsUpdateMember,
  isUpdateMember,
  toggleUpdateMember,
}) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const { channel } = useChannelStateContext();

  const { client, setActiveChannel } = useChatContext();

  const [focusedUser, setFocusedUser] = useState(undefined);
  const [inputText, setInputText] = useState("");
  const [resultsOpen, setResultsOpen] = useState(false);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [channelMember, setChannelMember] = useState([]);

  const inputRef = useRef();

  const clearState = () => {
    setInputText("");
    setResultsOpen(false);
    setSearchEmpty(false);
  };

  useEffect(() => {
    const clickListener = () => {
      if (resultsOpen) clearState();
    };
    document.addEventListener("click", clickListener);

    return () => document.removeEventListener("click", clickListener);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getChannelMembers = async () => {
      let sort = { created_at: -1 };
      let arr = [];
      const alreadyChannelMember = await channel.queryMembers({}, sort, {});
      alreadyChannelMember?.members?.forEach((member) => {
        arr.push(member.user);
      });
      setChannelMember(arr);
    };

    getChannelMembers();
  }, [channel]);

  // console.log("channelMember > ", channelMember);
  // 3.ユーザ検索関数
  const findUsers = async () => {
    if (searching) return;
    setSearching(true);

    try {
      const response = await client.queryUsers(
        {
          id: { $ne: client.userID },
          $and: [{ name: { $autocomplete: inputText } }],
        },
        { id: 1 },
        { limit: 30 }
      );
      if (!response.users.length) {
        setSearchEmpty(true);
      } else {
        setSearchEmpty(false);
        setUsers(response.users);
      }

      setResultsOpen(true);
    } catch (error) {
      console.log({ error });
    }

    setSearching(false);
  };

  // ２．findUsersをやや遅れで（_debounceで発動）
  const findUsersDebounce = _debounce(findUsers, 100, {
    trailing: true,
  });

  // 1.inputTextに値が入ったら・・・
  useEffect(() => {
    if (inputText) {
      findUsersDebounce();
    }
  }, [inputText]); // eslint-disable-line react-hooks/exhaustive-deps

  const combineId = (ids) => {
    const newIds = ids.sort(function (a, b) {
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      } else {
        return 0;
      }
    });
    return window.btoa(newIds.join(""));
  };

  const createChannel = async (selectedUsersIds, alreadyMembersIds) => {
    const newId = combineId([...selectedUsersIds, ...alreadyMembersIds]);
    console.log("newId =>", newId);
    // console.log("[...selectedUsersIds, ...alreadyMembersIds] =>", [
    //   ...selectedUsersIds,
    //   ...alreadyMembersIds,
    // ]);
    const conversation = client.channel(
      "messaging",
      String(Math.round(Math.random() * 10000000000)),
      // newId,
      {
        members: [...selectedUsersIds, ...alreadyMembersIds],
      }
    );
    await conversation.watch();
    setActiveChannel(conversation);
    displayNotification({
      message: "チャンネルを作成しました。",
      type: "success",
    });

    setSelectedUsers([]);
    setUsers([]);
    onClose();
  };

  // メンバー追加
  const updateMember = async () => {
    const state = await channel.watch();
    const role = await state?.membership.user.role;
    const selectedUsersIds = selectedUsers.map((u) => u.id);
    const alreadyMembersIds = state?.members.map((u) => u.user_id);

    // console.log("state?.members.length =>", state?.members);
    // console.log("alreadyMembersIds =>", alreadyMembersIds);
    // console.log("selectedUsersIds =>", selectedUsersIds);

    // 誰も選択してなかったらreturn
    if (!selectedUsersIds.length) return;

    // 既存のメンバーが2名だけだったらaddmemberじゃなくcreateChannel
    // =>２人の場合は、両者のIDを併せた文字列がIDになっている
    // ３人以上のチャットを作られて、意図的に抜けられて２人になった場合
    // 手のうちようがないが・・・
    try {
      if (state?.members.length === 2) {
        createChannel(selectedUsersIds, alreadyMembersIds);
        return;
      }
    } catch (error) {
      displayNotification({
        message: "このチャンネルの、メンバー追加権限がありません",
        type: "error",
      });

      console.log(error);
      return;
    }

    try {
      selectedUsersIds.length && selectedUsersIds.length > 1
        ? await channel.addMembers(selectedUsersIds, {
            text:
              state?.membership.user.name +
              " さんが" +
              selectedUsers[0]?.name +
              " さん達を追加しました",
          })
        : await channel.addMembers(selectedUsersIds, {
            text:
              state?.membership.user.name +
              " さんが" +
              selectedUsers[0]?.name +
              " さんを追加しました",
          });
      displayNotification({
        message: "メンバーを追加しました。",
        type: "success",
      });

      setSelectedUsers([]);
      setUsers([]);
      onClose();
    } catch (error) {
      displayNotification({
        message: "このチャンネルの、メンバー追加権限がありません",
        type: "error",
      });

      console.log(error);
    }

    // チャンネルにメンバーを追加できないのは
    // client.channel("messaging",members:["hoge","fuga"])のように
    // チャンネルIDを指定せずにチャンネル作成したから
    // https://getstream.io/chat/docs/react/creating_channels/#2.-creating-a-channel-for-a-list-of-members
    //  完全に閉鎖されたチャンネルということ

    // console.log("state >", state);

    // ○　ochajiroを追加する構文、これはうまく行った
    // await channel.addMembers(['fGACwq9wQFYrtoUwfedmlYe1IVL2'])
    // ○　ochajiroを消す構文、これはうまく行った
    // await channel.removeMembers(['fGACwq9wQFYrtoUwfedmlYe1IVL2']);

    // await channel.addMembers([selectedUsersIds])
    // await channel.addMembers(['sekaimura'], { text: 'sekaimura joined the channel.', user_id: auth.currentUser.uid });

    // const conversation = await client.channel("messaging", {
    //   members: [...selectedUsersIds, client.userID],
    // });

    // await conversation.watch();

    // setActiveChannel(conversation);
    setSelectedUsers([]);
    setUsers([]);
    onClose();
  };

  const addUser = (u) => {
    const isAlreadyChannelMember = channelMember.find(
      (user) => user.id === u.id
    );
    if (isAlreadyChannelMember) return;

    const isAlreadyAdded = selectedUsers.find((user) => user.id === u.id);
    if (isAlreadyAdded) return;

    setSelectedUsers([...selectedUsers, u]);
    setResultsOpen(false);
    setInputText("");
    inputRef.current.focus();
  };

  const removeUser = (user) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id);
    setSelectedUsers(newUsers);
    inputRef.current.focus();
  };

  // キーダウンイベント感知
  const handleKeyDown = useCallback(
    (e) => {
      // check for up(38) or down(40) key
      if (e.which === 38) {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === 0 ? users.length - 1 : prevFocused - 1;
        });
      }
      if (e.which === 40) {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === users.length - 1 ? 0 : prevFocused + 1;
        });
      }
      if (e.which === 13) {
        e.preventDefault();
        if (focusedUser !== undefined) {
          addUser(users[focusedUser]);
          return setFocusedUser(undefined);
        }
      }
    },
    [users, focusedUser] // eslint-disable-line
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    <Drawer
      anchor="right"
      open={isUpdateMember}
      onClose={toggleUpdateMember}
      // className="md:hidden"
    >
      {/* <HeaderDrawerMenu toggleOpen={toggleOpen} open={open} /> */}
      <div className="w-96 mt-20 p-2">
        <header>
          <div
            className="my-2"
            // className="close-mobile-create text-black"
            onClick={toggleUpdateMember}
          >
            <XButtonBackground />
          </div>

          <div className=" flex items-center">To: </div>
          {/* ユーザ検索と選択ユーザ表示 */}
          <div className="users-input-container border-b-2">
            {/* 選択したユーザがいるなら・・・ */}
            {!!selectedUsers?.length && (
              <div className="messaging-create-channel__users">
                {selectedUsers.map((user) => (
                  <div
                    className="messaging-create-channel__user"
                    onClick={() => removeUser(user)}
                    key={user.id}
                  >
                    <div className="messaging-create-channel__user-text">
                      {user.name}
                    </div>
                    <XButton />
                  </div>
                ))}
              </div>
            )}
            {/* ユーザ検索フォーム　ユーザ検索に文字を入れる度に発動 */}
            <form onSubmit={addUser}>
              <input
                autoFocus
                ref={inputRef}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder={!selectedUsers.length ? "ユーザ検索" : ""}
                type="text"
                className="messaging-create-channel__input"
              />
            </form>
          </div>

          {/*　メンバー追加ボタン */}
          <button
            className="border my-4 rounded-xl p-2 bg-blue-500 text-white"
            onClick={updateMember}
          >
            メンバーを追加
          </button>
        </header>

        {/* ユーザ検索に文字列が入力されているなら・・・ */}
        {inputText && (
          <main>
            <ul className="messaging-create-channel__user-results">
              {!!users?.length && !searchEmpty && (
                <div>
                  {users.map((user, i) => (
                    <div
                      className={`messaging-create-channel__user-result ${
                        focusedUser === i && "focused"
                      }`}
                      onClick={() => addUser(user)}
                      key={user.id}
                    >
                      <UserResult user={user} />
                    </div>
                  ))}
                </div>
              )}

              {/* 検索窓に文字列を入力しても、ユーザが見つからない時 */}
              {searchEmpty && (
                <div
                  onClick={() => {
                    inputRef.current.focus();
                    clearState();
                  }}
                  className="messaging-create-channel__user-result empty"
                >
                  見つからないようです。
                </div>
              )}
            </ul>
          </main>
        )}
      </div>
    </Drawer>
  );
};

export default React.memo(UpdateMember);
