import React, { useState, useEffect, useRef } from "react";
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, Divider, FormControlLabel, Switch, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { db, auth } from "../api/firebase";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import useMediaQuery from '@mui/material/useMediaQuery';

const AudioPlayer = () => {
  const releaseDate = dayjs('2024-06-20T10:00:00'); // 解禁日時を設定
  const [timeLeft, setTimeLeft] = useState(releaseDate.diff(dayjs(), 'second')); // 残り時間（秒）
  const [expanded, setExpanded] = useState(false); // アコーディオンの展開状態を管理
  const [tracks, setTracks] = useState([]); // 音楽トラックの配列
  const isMobile = useMediaQuery('(max-width:600px)');
  const [repeat, setRepeat] = useState(false); // リピート再生の状態を管理
  const [isAdmin, setIsAdmin] = useState(false);

  const specialReleaseDate = dayjs('2024-09-21T10:00:00'); // 特定のトラックの解禁日時
  const [specialTrackCountdown, setSpecialTrackCountdown] = useState(specialReleaseDate.diff(dayjs(), 'second')); // 特定トラックのカウントダウン

  // ユーザー情報の取得
  useEffect(() => {
    const fetchUserInfo = async () => {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsAdmin(userData.isAdmin);
      }
    };

    fetchUserInfo();
  }, []);

  // トラック情報の取得
  useEffect(() => {
    const fetchTracks = async () => {
      const tracksCol = collection(db, "tracks");
      const trackSnapshot = await getDocs(tracksCol);
      const tracksList = trackSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTracks(tracksList);
    };

    fetchTracks();
  }, []);

  // カウントダウンタイマーの更新
  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = dayjs();
      const diff = specialReleaseDate.diff(currentTime, 'second');
      setSpecialTrackCountdown(diff);

      if (diff <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [specialReleaseDate]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${days}日 ${hours}時間 ${minutes}分 ${secs}秒`;
  };

  // 再生中のトラックを追跡するためのステート
  const [playing, setPlaying] = useState(null);
  const audioRefs = useRef(new Map()); // 各トラックのaudio要素に対するrefを保持するためのオブジェクト

  const playTrack = (id) => {
    if (playing && playing !== id && audioRefs.current.get(playing)) {
      audioRefs.current.get(playing).pause();
      audioRefs.current.get(playing).currentTime = 0;
    }

    setPlaying(id);
    audioRefs.current.get(id).play();
  };

  const handlePlay = (id) => {
    audioRefs.current.forEach((audio, audioId) => {
      if (audioId !== id) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
    setPlaying(id);
  };

  const handleEnded = (id) => {
    if (repeat) {
      const audio = audioRefs.current.get(id);
      if (audio) {
        audio.currentTime = 0;
        audio.play();
      }
    }
  };

  // 再生中のトラックが停止された場合の処理
  useEffect(() => {
    const stopHandler = () => {
      setPlaying(null);
    };

    if (playing) {
      const audio = audioRefs.current.get(playing);
      audio.addEventListener("ended", stopHandler);
      return () => {
        audio.removeEventListener("ended", stopHandler);
      };
    }
  }, [playing]);

  // ダウンロードリンクの生成
  const getDownloadLink = (fileId) => {
    return `https://drive.google.com/uc?export=download&id=${fileId}`;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5">音楽リスト</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            <FormControlLabel
              control={
                <Switch
                  checked={repeat}
                  onChange={(e) => setRepeat(e.target.checked)}
                  color="primary"
                />
              }
              label="リピート再生"
            />
            {tracks
              .filter((track) => track.visible) // visible が true のトラックのみ表示
              .map((track) => {
                const isSpecialTrack = track.name === '秋分点から聴く7.89Hzカノン';
                const isSpecialTrackAvailable = specialTrackCountdown <= 0;

                return (
                  <Box
                    key={track.id}
                    className="border rounded-md p-2 my-2 cursor-pointer shadow-md hover:shadow-xl flex flex-col"
                    sx={{
                      width: '100%',
                      boxShadow: 1,
                      '&:hover': {
                        boxShadow: 3,
                      },
                      borderRadius: '8px',
                    }}
                  >
                    <Typography variant="body1">
                      {track.name}
                    </Typography>
                    <Box className="flex space-x-2 justify-center items-center">
                      {/* 再生ボタンは、カウントダウン終了後に有効化 */}
                      {isSpecialTrack && !isSpecialTrackAvailable ? (
                        <Box>
                          <Typography variant="body2" color="error">
                            このトラックは2024年9月21日10:00以降に再生可能です
                          </Typography>
                          <Typography variant="body2" color="error">
                            再生可能まで: {formatTime(specialTrackCountdown)}
                          </Typography>
                        </Box>
                      ) : (
                        <audio
                          ref={(el) => el && audioRefs.current.set(track.id, el)}
                          onPlay={() => handlePlay(track.id)}
                          onEnded={() => handleEnded(track.id)}
                          src={track.url}
                          controls
                          style={{ width: '100%' }}
                        />
                      )}
                      {isAdmin && (isSpecialTrackAvailable || !isSpecialTrack) && (
                        <IconButton
                          color="primary"
                          component="a"
                          href={getDownloadLink(track.fileId)} // ここにファイルIDを挿入
                          sx={{ ml: 2 }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AudioPlayer;
