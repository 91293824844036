import { Avatar, Button, Badge } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { highlightText } from "./highlightText";

import { doc, onSnapshot, collection, query, where } from "firebase/firestore";
import { db } from "../api/firebase"; // Firestoreのインスタンスをインポート
import { styled } from "@mui/material/styles";

// StyledBadgeの定義
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const RenderUserItem = ({ user, highlight }) => {
  const navigate = useNavigate();

  // ユーザープロフィールページへのナビゲーション関数
  const handleNavigate = (e) => {
    e.stopPropagation(); // ボタンクリック時の親要素へのイベント伝播を停止
    window.open("/user/" + user?.uid);
  };

  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    if (!user?.uid) return;

    // ユーザのデバイスコレクションへの参照を作成
    const devicesRef = collection(db, "users", user.uid, "devices");
    // isOnlineがtrueのデバイスを検索するクエリを作成
    const onlineQuery = query(devicesRef, where("isOnline", "==", true));

    const unsubscribe = onSnapshot(onlineQuery, (snapshot) => {
      // スナップショット内のドキュメントが存在する（isOnlineがtrueのもの）場合、ユーザをオンラインとみなす
      setIsOnline(!snapshot.empty);
    });

    // コンポーネントのアンマウント時にリスナーを解除
    return () => unsubscribe();
  }, [user?.uid]);

  return (
    <div
      className="flex items-center space-x-4 p-2 cursor-pointer hover:bg-gray-100 duration-150 rounded-md hover:shadow-xl space-y-2 shadow-md mb-2"
      style={{
        backgroundColor: isOnline ? "#e8f5e9" : "", // オンライン状態に基づいて背景色を設定
      }}
    >
      {isOnline ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <Avatar src={user?.avatar} />
        </StyledBadge>
      ) : (
        <Avatar src={user?.avatar} />
      )}
      <div className="flex-grow">
        <div className="font-semibold text-lg">
          {/* highlightText 関数の結果を JSX としてレンダリング */}
          {highlightText(`${user.lastName} ${user.firstName}`, highlight)}
        </div>
        {user?.prefecture && (
          <div className="text-gray-600">
            @{highlightText(`${user.prefecture}`, highlight)}
          </div>
        )}
      </div>
      <Button onClick={handleNavigate} variant="outlined" size="small">
        もっと見る
      </Button>
    </div>
  );
};

export default RenderUserItem;
