import React, { useEffect, useState } from "react";
import { useAuth } from "../context/auth";
import DisplaySignUp from "./DisplaySignUp";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import AddRecommendedSopt from "./AddRecommendedSopt";
import HeaderContainerMixIN from "../components/HeaderContainerMixIN";
import RequestShop from "./RequestShop";


const ShopApplication = () => {
  const { user } = useAuth();

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const init = async () => {
      const userRef = await getDoc(doc(db, "users", user?.uid));
      try {
        if (userRef?.exists()) {
          setUserInfo({ id: userRef.id, ...userRef.data() });
        }
      } catch (error) {
        console.log("error =>", error);
      }
    };
    if (user) {
      init();
    }
  }, [user]);

  if (!user) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-2xl font-bold mb-4">
          セカイムラマップのお店申請へようこそ。
        </div>
        <div className="text-lg mb-2">セカイムラマップへのお店の申請には、</div>
        <div className="text-lg mb-2">ユーザ登録（無料）が必要です。</div>
        <div className="text-lg mb-2">以下よりユーザ登録して頂き、</div>
        <div className="text-lg mb-4">
          その後お店の申請をお願い申し上げます。
        </div>

        <DisplaySignUp />
      </div>
    );
  }

  return (
    <>
          <HeaderContainerMixIN outlet={<AddRecommendedSopt /> } />

        {/* <AddRecommendedSopt /> */}
      {/* <div className="text-2xl">ShopApplication</div>; */}
    </>
  );
};

export default ShopApplication;
