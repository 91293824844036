import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../api/firebase";

const RadioGroupOnBaseUseGeoPoint = ({ base }) => {
  const [d_loding, setD_loading] = useState(false);

  const handleEventChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "base", id));
    await updateDoc(doc(db, "base", id), {
      [type]: !pub.data()[type],
    });
    setD_loading(false);
  };

  return (
    <>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="useDummyGeoPoint"
        value={base?.id}
        onChange={handleEventChange}
      >
        <FormControlLabel
          checked={base?.useDummyGeoPoint === true}
          value={base?.id}
          control={<Radio />}
          label="ダミー住所"
          disabled={base?.releaseAddress === false}
        />
        <FormControlLabel
          checked={base?.useDummyGeoPoint === false}
          value={base?.id}
          control={<Radio />}
          label="本住所"
          disabled={base?.releaseAddress === false}
        />
      </RadioGroup>
    </>
  );
};

export default RadioGroupOnBaseUseGeoPoint;
