import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../api/firebase";
import SearchDdp from "./SearchDdp";
import ProductsList from "./ProductsList";
import { useNavigate, useParams } from "react-router-dom";
import Offers from "./Offers";
import AutoComplete from "./AutoComplete";

const DDPContainer = () => {
  const { search } = useParams();
  const navigate = useNavigate();

  // offering取得処理
  const [offering, setOffering] = useState([]);
  const [offerLoading, setOfferLoading] = useState(true);
  useEffect(() => {
    const getOffers = async () => {
      const arr = [];
      const offers = await getDocs(collection(db, "ddp"));
      offers?.docs.map((doc) => {
        arr.push(doc.data().offering);
      });
      setOffering(arr);
      setOfferLoading(false);
    };

    getOffers();
  }, [offerLoading]);

  const [searchList, setSearchList] = useState([]);

  const backDDP = () => {
    navigate("/ddp");
  };

  return (
    <>
      <div className="m-2">
      {/* ロゴ */}
        <div
          className=" flex items-center hover:cursor-pointer mb-2 bg-black rounded-md w-72 md:p-3 text-white "
          onClick={backDDP}
        >
          <div className="text-lg">セカイムラ</div>
          <div> - DDP広場 - </div>
        </div>
        {/* サーチバー */}
        <SearchDdp />
        {/* 人気のタグ */}
        <div className="hidden md:block items-center p-2 my-2">
          <div>人気のタグ</div>
          <Offers />
        </div>

        <div className="md:hidden ">
          <AutoComplete list="offers" />
        </div>
      </div>

      {/* DDPリスト */}
      <div className="flex flex-col lg:flex-row lg:space-x-1 space-y-1 lg:space-y-0 my-2">
        {/* タグ検索 */}
        {/* <div className="  lg:w-3/12 xl:w-2/12 border h-full">
          <ListItems list={offering} setExternalList={setSearchList} />
        </div> */}

        {/* DDPスペース */}
        <div className="w-full  h-full">
          {/* DDPリスト */}
          {/* <ProductsList searchList={searchList} searchValue={searchValue} /> */}
          <ProductsList searchList={searchList} searchVal={search} />
        </div>
      </div>
    </>
  );
};

export default DDPContainer;
