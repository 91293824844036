import React from 'react'
import ContentLoader from "react-content-loader";

const Loader1Row = (props) => {
    return (
      <>
     <ContentLoader 
      speed={0.5}
      width={160}
      height={28}
      className="w-full"
      backgroundColor="#d9e4d8"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="-43" y="-13" rx="0" ry="0" width="1160" height="56" />
    </ContentLoader>
  
      </>
    );
  };
  
  export default Loader1Row;
  