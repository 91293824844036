import React from "react";
import { useEffect, useState } from "react";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";

import EditIcon from "@mui/icons-material/Edit";
import ModalForm from "./ModalForm";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
  collection,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import GetUserInfo from "./GetUserInfo";

const GroupSetting = ({ group }) => {
  const [groupDoc] = useDocumentData(doc(db, "group", group.id));
  const [creator] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  const [groupMembers] = useCollectionData(
    collection(db, "group", group.id, "members")
  );

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  //   権限変更関連handle
  const [d_loding, setD_loading] = useState(false);
  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "group", id));
    await updateDoc(doc(db, "group", id), {
      [type]: !pub.data()[type],
    });

    const doMake = !pub.data()[type] ? "誰でも作成可" : "創設者のみ可";
    // グループのupdates作成・更新
    await addDoc(collection(db, "group", id, "updates"), {
      createdAt: serverTimestamp(),
      title: "設定変更",
      information:
        creator?.lastName +
        creator?.firstName +
        "さんがグループ[" +
        groupDoc?.title +
        "]のイベント作成権限を[" +
        doMake +
        "]に変更しました",
      creator: auth.currentUser.uid,
      creatorAvatar: creator?.avatar,
    });

    setD_loading(false);
    // setDataLoading(true);
    // window.location.reload();
  };
  const handleEventChange = async (e) => {
    setD_loading(true);
    await publishChange(e.target.value, e.target.name);
  };

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} target={group} />

      <div className="w-full my-5">
        <TabContext value={value}>
          {/* 左リスト */}
          {/* <Box sx={{ width: "20%" }}> */}
          <Box sx={{ width: "100%" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              // orientation="vertical"
            >
              <Tab label="基本情報" value="1" />
              <Tab label="表示" value="2" />
              {/* <Tab label="情報" value="3" /> */}
            </TabList>
          </Box>
          {/* 基本情報 */}
          <TabPanel sx={{ width: "100%" }} value="1">
            <div className="flex flex-col space-y-3">
              {/* タイトル */}
              <div className="flex space-x-2  items-center">
                <div className="flex-none w-32 inline-block align-middle   ">
                  タイトル
                </div>
                <div className="w-full p-2 border rounded-md">
                  {group?.title}
                </div>
                <div
                  className="border p-3 rounded-full h-fit hover:shadow-lg"
                  onClick={() => handleEdit("editGroupTitle")}
                >
                  <EditIcon />
                </div>
              </div>
              {/* 説明 */}
              <div className="flex space-x-2 justify-center items-center">
                <div className="flex-none w-32 inline-block align-middle   ">
                  説明
                </div>
                <div className="w-full p-2 border rounded-md">
                  {group?.explain}
                </div>
                <div
                  className="border p-3 rounded-full h-fit hover:shadow-lg"
                  onClick={() => handleEdit("editGroupExplain")}
                >
                  <EditIcon />
                </div>
              </div>
              {/* タイプ */}
              <div className="flex space-x-2 justify-center items-center">
                <div className="flex-none w-32 inline-block align-middle   ">
                  タイプ
                </div>
                <div className="w-full p-2 border rounded-md">
                  {group?.type}
                </div>
                <div
                  className="border p-3 rounded-full h-fit hover:shadow-lg"
                  onClick={() => handleEdit("editGroupType")}
                >
                  <EditIcon />
                </div>
              </div>

              {/* 誰でもイベント作成できる */}
              <div className="flex space-x-2 justify-center items-center">
                <div className="flex-none w-32 inline-block align-middle   ">
                  イベント作成
                </div>
                <div className="w-full p-2  rounded-md">
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="doMakeEventsEveryOne"
                    value={group?.id}
                    onChange={handleEventChange}
                  >
                    <FormControlLabel
                      checked={groupDoc?.doMakeEventsEveryOne === true}
                      value={group?.id}
                      control={<Radio />}
                      label="誰でも作成可"
                    />
                    <FormControlLabel
                      checked={groupDoc?.doMakeEventsEveryOne === false}
                      value={group?.id}
                      control={<Radio />}
                      label="創設者のみ可"
                    />
                  </RadioGroup>

                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={group?.doMakeEventsEveryOne}
                        onChange={handleEventChange}
                        name="doMakeEventsEveryOne"
                        disabled={d_loding}
                        value={group?.id}
                      />
                    }
                    label={
                      group?.doMakeEventsEveryOne
                        ? "誰でも作成可"
                        : "創設者のみ可"
                    }
                  /> */}
                </div>

                {/* <div className="w-full p-2 border rounded-md">
                  {group?.doMakeEventsEveryOne}
                </div> */}
                <div
                  className=" rounded-full h-fit hover:shadow-lg"
                  // onClick={() => handleEdit("editGroupType")}
                >
                  {/* <EditIcon /> */}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel sx={{ width: "100%" }} value="2">
            <div className="flex space-x-2  items-center">
              <div className="flex-none w-32 inline-block align-middle   ">
                表示
              </div>
              <div className="w-full p-2 border rounded-md">
                {group?.accept}
              </div>
              <div
                className="border p-3 rounded-full h-fit hover:shadow-lg"
                onClick={() => handleEdit("editGroupAccept")}
              >
                <EditIcon />
              </div>
            </div>
          </TabPanel>
          <TabPanel sx={{ width: "100%" }} value="3">
            <div>
              代表:
              <GetUserInfo uid={groupDoc?.founder} target="name" />
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
};

export default GroupSetting;
