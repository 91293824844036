import React, { useState, memo, useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import "../hideScrollBar.css";
import StoreIcon from "@mui/icons-material/Store";
import MapTypeIcon from "./MapTypeIcon";
import FlareIcon from "@mui/icons-material/Flare";
import ForestIcon from "@mui/icons-material/Forest";
import { ReactComponent as LendFieldIcon } from "../data/svg/LendFieldKUWA.svg";
import { ReactComponent as LendOldHouseIcon } from "../data/svg/LendOldHouse.svg";
import { ReactComponent as PowerSpotIcon } from "../data/svg/PowerSpot.svg";
import { ReactComponent as VagetableIcon } from "../data/svg/Vagetable.svg";
import { ReactComponent as AlternativeMedicineIcon } from "../data/svg/AlternativeMedicine.svg";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../api/firebase";

function HorizontalMenuAtDDPs({ onClick }) {
  // offring取得
  const [offering, setOffering] = useState([]);
  useEffect(() => {
    const init = async () => {
      const arr = [];
      const offers = await getDocs(collection(db, "ddp"));
      offers?.docs.map((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setOffering(arr);
    };

    init();
  }, []);

  console.log("offering =>", offering);

  return (
    <div className="grid grid-cols-1 ">
      <div className=" col-span-1  rounded-sm py-4">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {offering &&
            offering?.map((doc) => (
              <MapTypeIcon
                icon={<FlareIcon />}
                text={doc.offering}
                onClick={() => onClick("korakuen")}
              />
            ))}
          {/* <MapTypeIcon
            icon={<LendFieldIcon />}
            text="貸田畑"
            onClick={() => onClick("lendField")}
          />
          <MapTypeIcon
            icon={<LendOldHouseIcon />}
            text="貸古民家"
            onClick={() => onClick("lendOldHouse")}
          />
          <MapTypeIcon
            icon={<PowerSpotIcon />}
            text="パワースポット"
            onClick={() => onClick("powerSpot")}
          />
          <MapTypeIcon
            icon={<VagetableIcon />}
            text="自然食品"
            onClick={() => onClick("naturalFood")}
          />
          <MapTypeIcon
            icon={<AlternativeMedicineIcon />}
            text="代替医療"
            onClick={() => onClick("alternativeMedicine")}
          />
          <MapTypeIcon
            icon={<StoreIcon />}
            text="村民さんのお店"
            onClick={() => onClick("userShop")}
          />
          <MapTypeIcon
            icon={<ForestIcon />}
            text="セカイムラ拠点"
            onClick={() => onClick("sekaimuraBase")}
          /> */}

          {/* <MapTypeIcon
            icon={<FavoriteBorderIcon />}
            text="癒す"
            onClick={() => onClick("healing")}
          />
          <MapTypeIcon
            icon={<FlatwareIcon />}
            text="食べる"
            onClick={() => onClick("restaurant")}
          />
          <MapTypeIcon
            icon={<HotelIcon />}
            text="泊まる"
            onClick={() => onClick("hotel")}
          /> */}
        </ScrollMenu>
      </div>
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <>
      <div
        className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
        onClick={() => scrollPrev()}
      >
        <div className="hidden sm:block ">
          <div className=" text-amber-700">
            <KeyboardDoubleArrowLeftOutlinedIcon
              sx={{ fontSize: "25px", marginX: "5px" }}
            />
          </div>
        </div>
      </div>
    </>
    // <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
    //   Left
    // </Arrow>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <>
      <div
        className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
        onClick={() => scrollNext()}
      >
        <div className="hidden sm:block ">
          <div className="text-amber-700">
            <KeyboardDoubleArrowRightOutlinedIcon
              sx={{ fontSize: "25px", marginX: "5px" }}
            />
          </div>
        </div>
      </div>
    </>
    // <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
    //   Right
    // </Arrow>
  );
}

export default memo(HorizontalMenuAtDDPs);
