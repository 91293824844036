import { doc, collection, query, where } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import {
  useDocument,
  useDocumentData,
  useCollection,
} from "react-firebase-hooks/firestore";
import Img from "../data/img/defaultImage.png";
import { auth, db } from "../api/firebase";
import { Avatar } from "@mui/material";

const DisplayBaseCard = ({ id, type }) => {
  // 拠点情報
  const [base] = useDocumentData(doc(db, "base", id));
  //     // Chief（村長）か否か
  //   const [isChief, setIsChief] = useState(false);
  //   useEffect(() => {
  //     const initIsChief = () => {
  //       if (base?.chief === auth.currentUser.uid) {
  //         setIsChief(true);
  //       }
  //     };
  //     base && initIsChief();
  //   }, [base]);

  // 拠点の役職
  //   const [baseDirectorCollection] = useCollection(
  //     collection(db, "users", auth.currentUser.uid, "affiliationBase")
  //   );
  //   const baseDirector = baseDirectorCollection?.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));

  return (
    <>
      <div className="flex border rounded-lg p-2 my-2 space-x-2 shadow-lg hover:cursor-pointer hover:shadow-2xl items-center">
        <Avatar src={base?.background || Img} alt="" />
        <div className="font-bold text-xl hover:text-green-500">
          {base?.title}
        </div>
        <div className="bg-gray-400 p-1 text-white rounded-sm">
          {base?.prefecture}
        </div>
        {type === "chief" && (
          <div className=" bg-yellow-500 text-white font-bold rounded-md p-1">
            村長
          </div>
        )}
        {type === "subChief" && (
          <div className=" bg-orange-800 text-white font-bold rounded-md p-1">
            副村長
          </div>
        )}

        {type === "member" && (
          <div className=" bg-green-800 text-white font-bold rounded-md p-1">
            村民
          </div>
        )}


        {/* {base?.chief === auth.currentUser.uid && <div>村長</div>}
        {base?.chief === auth.currentUser.uid && <div>村長</div>} */}
      </div>
    </>
  );
};

export default DisplayBaseCard;

// chief
// "gvcnpWr5QaWiOIVFF7VY6Z47xPp1"
// createdAt
// 2023年4月17日 22:49:47 UTC+9
// creator
// "gvcnpWr5QaWiOIVFF7VY6Z47xPp1"
// doMakeEventsEveryOne
// true
// isPublish
// false
// lastUpdatedAt
// 2023年4月17日 22:57:34 UTC+9
// prefecture
// "福井県"
// releaseAddress
// true
// title
// "神奈川西"
