import React from "react";
import Img from "../data/img/defaultImage.png";
import { Carousel } from "react-responsive-carousel";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProductRate from "./ProductRate";



const DisplayProductOver640 = ({ product, myProductImages, id }) => {
  const navigate = useNavigate();

  return (
    <>
      {/*　sm(640)以上表示 */}
      <div className="hidden sm:block">
        <div
          className="flex h-40 border rounded-md cursor-pointer hover:bg-gray-50 hover:shadow-lg"
          // aria-disabled={product.isPublish}
        >
          {/* 停止stop or 活動中active */}
          {/* {product?.isPublish && product?.isActive ? (
            ""
          ) : (
            <div className="p-1 text-red-500">停止中</div>
          )} */}
          {/* 画像 */}
          <div className="flex relative w-4/12">
            {!myProductImages?.length && (
              <img
                src={Img}
                alt=""
                object-fit="cover"
                className="w-full h-40 rounded-l-md"
              />
            )}
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              className="w-full h-28 rounded-l-md"
            >
              {myProductImages?.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image.imageURL}
                      alt=""
                      object-fit="cover"
                      className=" h-28 rounded-l-md"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          {/* タイトル、説明、レート、価格 */}
          <div
            className="flex flex-col justify-between hover:cursor-pointer p-2 space-y-1 w-8/12"
            onClick={() => navigate(`/product/${id}`)}
          >
            {/* タイトルと名前 */}
            <div className="flex flex-col">
              {/* タイトル */}
              <div className="font-bold truncate">{product?.title}</div>

              {/* 名前とアバター */}
              <div className="flex items-center space-x-1  ">
                <div className="text-xs font-sans font-bold">
                  {product?.name}
                </div>
                <div>
                  <Avatar
                    src={product?.avatar}
                    sx={{ width: 24, height: 24 }}
                  />
                </div>
              </div>
            </div>
            {/* 説明 */}
            {/* <div className=" text-gray-400 text-xs truncate whitespace-normal whitespace-pre-wrap h-3/5">
              {product?.explain}
            </div> */}
            {/* レートと価格 */}
            <div className="flex flex-col ">
              <div>
              <ProductRate productId={product?.id} />

              </div>
              <div className="flex text-blue-400 font-bold text-md items-center space-x-1">
                <div>{product?.runru}</div>
                <div className="text-xs">ルンル</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayProductOver640;
