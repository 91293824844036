import React, { memo } from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import Img from "../data/img/defaultImage.png";
import { collection, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import GetUserInfo from "./GetUserInfo";
import DealStatus from "./DealStatus";
import ProductRate from "./ProductRate";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";

const DisplayDeal = ({ deal, id, tab }) => {
  // 自分で上げた画像
  const queryDealImages = query(collection(db, `deal/${id}/images`));
  const [images] = useCollection(queryDealImages);
  const dealImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const navigate = useNavigate();
  const moveToTargetProduct = async () => {
    navigate(`/deal/${id}`, { state: { tab: 3 } });
  };

  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  // console.log("deal =>", isInvalidDate(new Date(deal?.lastUpdated?.toDate())));

  return (
    <>
      {/*スマホ表示 */}
      <div className=" sm:hidden">
        <div
          className="flex h-48 border rounded-md cursor-pointer hover:bg-gray-50 hover:shadow-lg"
          aria-disabled={deal.isPublish}
        >
          {/* 画像 */}
          <div className="flex relative">
            {!dealImages?.length && (
              <img
                src={Img}
                alt=""
                object-fit="cover"
                className="w-40 h-48 rounded-l-md"
              />
            )}
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              className="w-40 h-28 rounded-l-md"
            >
              {dealImages?.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image.imageURL}
                      alt=""
                      object-fit="cover"
                      className="w-40 h-28 rounded-l-md"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          {/* タイトル、説明、レート、価格 */}
          <div
            className="flex flex-col justify-between hover:cursor-pointer p-2 space-y-1 w-8/12"
            onClick={moveToTargetProduct}
          >
            {/* タイトルと名前 */}
            <div className="flex flex-col   justify-between">
              {/* いつ来た？ */}
              {deal?.lastUpdatedAt && (
                <div className=" text-blue-500 text-xs">
                  {isInvalidDate(new Date(deal?.lastUpdatedAt?.toDate()))
                    ? "読み込み中"
                    : formatDistanceToNow(deal?.lastUpdatedAt.toDate(), {
                        locale: ja,
                      })}
                  前
                </div>
              )}

              {/* {deal?.lastUpdated && (
                <div className="flex justify-end text-red-400">
                  更新日：
                  {
                    new Date(deal?.lastUpdated?.toDate())
                      .toISOString()
                      .split("T")[0]
                  }{" "}
                  {new Date(deal?.lastUpdated?.toDate()).toLocaleTimeString()}
                </div>
              )} */}

              {/* タイトル */}
              <div className="font-bold truncate">{deal?.productName}</div>

              {/* 名前とアバター */}
              <div className="flex items-center space-x-1  ">
                <div className="text-xs font-sans font-bold">
                  <GetUserInfo uid={deal.buyer} target="name" />
                </div>
                <div>
                  <GetUserInfo uid={deal?.buyer} target="avatar" size={24} />
                  {/* <Avatar src={<GetUserInfo uid={deal?.buy } target="avatar" />} sx={{ width: 24, height: 24 }} /> */}
                </div>
              </div>
            </div>
            {/* 説明 */}
            {/* <div className=" text-gray-400 text-xs truncate whitespace-normal whitespace-pre-wrap h-3/5">
              {deal.explain}
            </div> */}
            {/* 取引状況 */}
            <div>
              <DealStatus status={deal?.status} />
            </div>

            {/* レートと価格 */}
            <div className="flex flex-col">
              <div>
                <ProductRate productId={deal?.product} />
              </div>
              <div className="flex text-blue-400 font-bold text-md items-center space-x-1">
                <div>{deal?.amount}</div>
                <div className="text-xs">ルンル</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* それ以外表示 */}
      <div className="hidden sm:block">
        <div
          className="flex-col h-96 border rounded-md cursor-pointer hover:bg-gray-50 hover:shadow-lg"
          aria-disabled={deal.isPublish}
        >
          {/* 画像 */}
          <div className="flex relative">
            {!dealImages?.length && (
              <img
                src={Img}
                alt=""
                object-fit="cover"
                className="w-full h-56 rounded-t-md"
              />
            )}
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              className="w-full"
            >
              {dealImages?.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image.imageURL}
                      alt=""
                      object-fit="cover"
                      className="w-full h-56 rounded-t-md"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>

          <div
            className="flex flex-col h-40 justify-between hover:cursor-pointer p-2 space-y-1"
            onClick={moveToTargetProduct}
          >
            {/* タイトルと名前 */}
            <div className="flex flex-col  ">
              {/* タイトル */}
              <div className="font-bold truncate">{deal?.productName}</div>

              {/* 名前とアバター */}
              <div className="flex items-center space-x-1  ">
                <div>DDP提供：</div>
                <div>
                  <GetUserInfo uid={deal?.buyer} target="avatar" size={24} />
                  {/* <Avatar src={deal.avatar} sx={{ width: 24, height: 24 }} /> */}
                </div>
                <div className="text-xs font-sans font-bold">
                  <GetUserInfo uid={deal.buyer} target="name" />
                </div>
              </div>
              {/* 名前とアバター */}
              <div className="flex items-center space-x-1  ">
              <div>交換希望：</div>

                <div>
                  <GetUserInfo uid={deal?.buy} target="avatar" size={24} />
                  {/* <Avatar src={deal.avatar} sx={{ width: 24, height: 24 }} /> */}
                </div>
                <div className="text-xs font-sans font-bold">
                  <GetUserInfo uid={deal.buy} target="name" />
                </div>
              </div>
            </div>
            {/* 説明 */}
            <div className=" text-gray-400 text-xs truncate whitespace-pre-wrap h-3/5">
              {deal.explain}
            </div>
            {/* 取引状況 */}
            <div>
              <DealStatus status={deal?.status} />
            </div>

            {/* レートと価格 */}
            <div className="flex justify-between items-center h-1/5">
              {/* いつ来た？ */}
              {deal?.lastUpdatedAt && (
                <div className=" text-blue-500 text-xs">
                  {isInvalidDate(new Date(deal?.lastUpdatedAt?.toDate()))
                    ? "読み込み中"
                    : formatDistanceToNow(deal?.lastUpdatedAt.toDate(), {
                        locale: ja,
                      })}
                  前
                </div>
              )}

              {/* {deal?.lastUpdated && (
                <div className=" text-blue-500 text-xs">
                  {formatDistanceToNow(deal?.lastUpdated.toDate(), {
                    locale: ja,
                  })}
                  前
                </div>
              )} */}

              <div className="flex text-blue-400 font-bold text-md items-center space-x-1">
                <div>{deal?.amount}</div>
                <div className="text-xs">ルンル</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(DisplayDeal);
