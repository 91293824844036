import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { auth, db } from "../api/firebase";

import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "stream-chat-react";
import ModalForm from "./ModalForm";
import { useAuth } from "../context/auth";

import Linkify from "linkify-react";

const EventExplain = ({ id }) => {
  const { user } = useAuth();

  const linkifyOptions = {
    className: "text-blue-400",
    target: "blank",
  };

  // ModalForm用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleModalOpen = (e) => {
    setOpen(true);
    setForm(e);
  };
  const [eventDoc] = useDocumentData(doc(db, "event", id));

  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const initSetAuthor = async () => {
      if (eventDoc?.author === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    user && initSetAuthor();
  }, [eventDoc]);

  // ユーザ情報
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initGetUser = async () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && initGetUser();
  }, []);

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} requestId={id} />

      <div className="break-all leading-normal whitespace-pre-wrap p-5">
        <Linkify options={linkifyOptions}>
          {eventDoc?.explain?.replace(/[ \t]+/g, "").replace(/　/g, "")}{" "}
          {/* スペースと全角スペースを取り除く */}
        </Linkify>
      </div>

      {/* <div className="pr-5 pl-5 pt-2 pb-5 ">
        {eventDoc?.explain}
      </div> */}
    </>
  );
};

export default EventExplain;
