import React from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "@mui/material";

const Logo = () => {
  const navigate = useNavigate();
    // ホームへ移動
    const handleToHome = () => {
      if (window.location.pathname !== "/home") {
        navigate("/home");
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }
    };
  return (
    <div>
      <Tooltip title="ホームへ" arrow>
        <div
          className="hidden lg:block rounded-md font-bold hover:cursor-pointer border hover:bg-gray-100"
          onClick={handleToHome}
        >
          sekaimura
        </div>
      </Tooltip>
    </div>
  );
};

export default Logo;
