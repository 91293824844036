import React from "react";
import AddComponent from "./AddComponent";
import BasesTable from "./BasesTable";
import CreateBaseHandle from "./CreateBaseHandle";


const OperateBase = () => {
  return (
    <>
      <div>拠点操作</div>
      <AddComponent value="拠点作成" target="createBase" />
      
      <BasesTable />
    </>
  );
};

export default OperateBase;
