import React, { useState } from "react";

const questions = [
  "こんにちは！あなたの名前は何ですか？",
  "どのようなお仕事をされていますか？",
  "お仕事において、最もやりがいを感じる瞬間はありますか？",
  "趣味は何ですか？",
  "あなたにとって最高の旅行先はどこですか？",
  "最近読んだおすすめの本を教えてください。",
  "何か習得したいことはありますか？",
  "最近感動したことを教えてください。",
  "今後の目標について教えてください。",
  "最後に一言お願いします。",
];

const FindPassion = () => {
  const [chat, setChat] = useState([]);
  const [answer, setAnswer] = useState("");

  const addChat = (q, a) => {
    setChat((prevChat) => [
      ...prevChat,
      {
        question: q,
        answer: a,
      },
    ]);
  };

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleAnswerSubmit = (e) => {
    e.preventDefault();
    addChat(questions[chat.length], answer);
    setAnswer("");
    if (chat.length === questions.length - 1) {
      // 最後の質問に回答した後に ChatGPT の推論処理を実行する
      const persona = getPersona(answer);
      const passion = getPassion(answer);
      setChat((prevChat) => [
        ...prevChat,
        {
          question: "あなたはどのような人物かというと？",
          answer: persona,
        },
        {
          question: "あなたが本当に情熱を感じているものは何ですか？",
          answer: passion,
        },
      ]);
    }
  };

  const renderChat = () => {
    return chat.map((c, i) => (
      <div key={i}>
        <p>Chatbot: {c.question}</p>
        {/* <p>User: {c.answer}</p> */}
      </div>
    ));
  };

  const renderInput = () => {
    return (
      <form onSubmit={handleAnswerSubmit}>
        <input type="text" value={answer} onChange={handleAnswerChange} />
        <button type="submit">送信</button>
      </form>
    );
  };

  const handleStart = () => {
    addChat(questions[0], "");
  };

  return (
    <div>
      <button onClick={handleStart}>開始</button>
      {renderChat()}
      {chat.length < questions.length && renderInput()}
    </div>
  );
};

export default FindPassion;


const getPassion = (text) => {
    let passion = "";
    if (text.includes("好き") || text.includes("楽しい")) {
      passion = "ポジティブなこと";
    } else if (text.includes("苦手") || text.includes("つまらない")) {
      passion = "ネガティブなこと";
    } else {
      passion = "不明";
    }
    return passion;
  };
  

  const getPersona = (text) => {
    let persona = "";
    if (text.includes("私は") || text.includes("私の")) {
      persona = "自己中心的";
    } else if (text.includes("友達") || text.includes("家族")) {
      persona = "社交的";
    } else if (text.includes("勉強") || text.includes("仕事")) {
      persona = "努力家";
    } else {
      persona = "不明";
    }
    return persona;
  };
  