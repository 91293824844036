import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Button } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, orderBy, query, where, getDocs } from "firebase/firestore";
import { db, auth } from "../api/firebase";

import ModalForm from "./ModalForm";

const DisplayPostImages = ({ post }) => {
  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const init = async () => {
      if (post?.creator === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    post && init();
  }, [post]);

  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      const querySnapshot = await getDocs(
        query(
          collection(db, "postImage"),
          where("id", "==", post.id),
          where("isPublish", "==", true),
          orderBy("updatedAt", "desc")
        )
      );
      querySnapshot.forEach((elm) => {
        arr.push({ id: elm.id, ...elm.data() });
      });
      setImageList(arr);
    };
    post && init();
  }, [post]);

  // console.log("imageList =>", imageList);

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (type) => {
    setOpen(true);
    setForm(type);
  };

  useEffect(() => {
    const loadImage = async () => {
      // すべての画像の読み込みを待つPromiseの配列を作成
      const promises = imageList.map((item) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = reject;
          img.src = `${item.url}?w=248&fit=crop&auto=format`;
        });
      });

      // すべての画像の読み込みが完了するまで待つ
      try {
        await Promise.all(promises);
      } catch (error) {
        console.error("画像の読み込みエラー:", error);
      }

      // console.log("すべての画像の読み込みが完了しました");
    };

    loadImage();
  }, [imageList]);

  // console.log("imageList =>",imageList)

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={post?.id}
      />

      <div className="overflow-y-auto scrollbar-hide">
        {/* 写真１枚の場合は大きく */}
        {imageList.length === 1 ? (
          <ImageList variant="masonry" cols={1}>
            {imageList?.map((item, idx) => (
              <ImageListItem key={idx}>
                <img
                  src={`${item.url}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.name}
                  loading="lazy"
                  className="relative cursor-pointer "
                  onClick={() => handleEdit("displayPostImages")}
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <ImageList variant="masonry" cols={2} >
            {imageList?.map((item, idx) =>
              idx < 4 ? (
                <ImageListItem key={idx}>
                  <img
                    src={`${item.url}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.name}
                    loading="lazy"
                    className="relative cursor-pointer max-h-60 w-auto "
                    onClick={() => handleEdit("displayPostImages")}
                  />
                  {imageList.length > 4 && idx === 3 && (
                    <div
                      className="flex absolute top-0 bottom-0 my-auto right-0 left-0 mx-auto w-fit h-fit border-2 opacity-60 text-white text-2xl font-bold p-5 rounded-full border-white hover:bg-white hover:text-black duration-150 cursor-pointer"
                      onClick={() => handleEdit("displayPostImages")}
                    >
                      もっと見る
                    </div>
                  )}
                </ImageListItem>
              ) : null
            )}
          </ImageList>
        )}

        {/* {isAuthor && imageList.length > 4 && (
          <Button onClick={() => handleEdit("editPostImage")}>
            写真を編集
          </Button>
        )} */}
      </div>
    </>
  );
};

export default DisplayPostImages;
