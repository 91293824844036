import React, { useState } from "react";
import { TextField, MenuItem } from "@mui/material";

import { addDoc, collection, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

const AddDdpCombination = ({ setOpen }) => {
  const [env] = useDocumentData(doc(db, "ddp", "env"));

  // 提供する形コンテナ
  const [product, setProduct] = useState({
    offering: "",
    recieve: "",
    target: "",
    thing: "",
    error: "",
    loading: false,
  });
  const { offering, recieve, target, thing, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    try {
      // 変更処理
      const docRef = await addDoc(collection(db, "ddp"), {
        offering,
        recieve,
        target,
        thing,
        isPublish:true,
        createdAt: serverTimestamp(),
      });
      setProduct({
        offering: "",
        recieve: "",
        target: "",
        thing: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div className="flex flex-col space-y-2">
        <div className="mb-3">「DDPの組み合わせ」を登録</div>
        <form onSubmit={setRequestDataShop}>
          {/* 提供する形 */}
          <div className=" mb-1"></div>
          <TextField
            id="outlined-required"
            name="offering"
            label="提供する形"
            defaultValue=""
            size="small"
            placeholder="ex) 話す、あげる、パフォーマンスする"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            {env?.offering?.map((item) => (
              <MenuItem key={Math.random()} value={item} dense>
                {item}
              </MenuItem>
            ))}
          </TextField>
          {/* 提供を受ける形形 */}
          <div className="mt-3 mb-1"></div>
          <TextField
            id="outlined-required"
            name="recieve"
            label="「提供する形」の対になる「提供を受ける形」"
            defaultValue=""
            size="small"
            placeholder="ex) 知り合いたい、鑑賞したい、聞きたい、ほしい"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            {env?.recieve?.map((item) => (
              <MenuItem key={Math.random()} value={item} dense>
                {item}
              </MenuItem>
            ))}
          </TextField>

          {/* 提供を受ける形形 */}
          <div className="mt-3 mb-1"></div>
          <TextField
            id="outlined-required"
            name="target"
            label="提供する対象"
            defaultValue=""
            size="small"
            placeholder="ex) 人、全般、心"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            {env?.target?.map((item) => (
              <MenuItem key={Math.random()} value={item} dense>
                {item}
              </MenuItem>
            ))}
          </TextField>

          {/* 提供を受ける形形 */}
          <div className="mt-3 mb-1"></div>
          <TextField
            id="outlined-required"
            name="thing"
            label="提供するもの"
            defaultValue=""
            size="small"
            placeholder="ex) パン、笑える話、幸福論"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            {env?.thing?.map((item) => (
              <MenuItem key={Math.random()} value={item} dense>
                {item}
              </MenuItem>
            ))}
          </TextField>

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
            >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AddDdpCombination;
