import React from "react";

const Analyze = () => {
  return (
    <>
      <div className="font-bold text-2xl">統計情報</div>
      <div>ユーザ数</div>
      <div>村民数</div>
      <div>拠点数</div>
      <div>流通ルンル</div>
      <div>DDP成立数</div>
      <div>動画視聴数</div>
      <div>動画視聴時間</div>
      <div>スキル習得者数</div>

    </>
  );
};

export default Analyze;
