import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Avatar } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import AddComponent from "./AddComponent";
import { Navigate, useNavigate } from "react-router-dom";

// 1行ずつの内容
const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);

  // pagenation ルンル
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // pagenation取引
  const [pageD, setPageD] = React.useState(0);
  const [rowsPerPageD, setRowsPerPageD] = useState(5);
  const handleChangePageD = (event, newPage) => {
    setPageD(newPage);
  };
  const handleChangeRowsPerPageD = (event) => {
    setRowsPerPageD(+event.target.value);
    setPageD(0);
  };

  const navigate = useNavigate();

  // console.log("row =>", row);

  return (
    <>
      {/* 常に表示される値 */}
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {/* 開閉ボタン */}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {/* 名前とAvatar */}
        <TableCell component="th" scope="row">
          <div className=" flex space-x-1 items-center bg-gray-100 rounded-md py-1 px-2 w-48">
            <Avatar src={row?.avatar} />
            <div>
              {row?.lastName}
              {row?.firstName}
            </div>
          </div>
        </TableCell>
        {/* ルンル特別付与 */}
        <TableCell>
          <AddComponent
            value="付与"
            target="runruSpecialGift"
            requestId={row?.uid}
          />
        </TableCell>

        {/* 残高 */}
        <TableCell align="right">{row?.balance}</TableCell>
        <TableCell align="right">{row?.balance90}</TableCell>
        <TableCell align="right">{row?.balance180}</TableCell>
        <TableCell align="right">{row?.balance365}</TableCell>
        {/* セカイムラ登録日 */}
        <TableCell align="right">
          {new Date(row?.createdAt?.toDate()).toLocaleString()}
        </TableCell>
      </TableRow>

      {/* Collapseの内容 */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* 表題 */}
              <Typography variant="h6" gutterBottom component="div">
                履歴
              </Typography>

              {/* Collapseテーブル */}
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <Table size="small" aria-label="purchases">
                  {/* Collapseのヘッダー */}
                  <TableHead>
                    <TableRow>
                      <TableCell>日付</TableCell>
                      <TableCell>種類</TableCell>
                      <TableCell>誰から</TableCell>
                      <TableCell>誰へ</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Collapseの中身 */}
                  <TableBody>
                    {(rowsPerPage > 0
                      ? row?.history?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : row?.history
                    )?.map((historyRow) => (
                      <TableRow key={Math.random()}>
                        {/* 日付 */}
                        <TableCell component="th" scope="row">
                          {new Date(
                            historyRow.createdAt?.toDate()
                          ).toLocaleString()}
                        </TableCell>
                        {/* 種類 */}
                        <TableCell>{historyRow.cause}</TableCell>
                        {/* 誰から？ */}
                        <TableCell>{historyRow.from}</TableCell>
                        {/* 誰へ？ */}
                        <TableCell>{historyRow.to}</TableCell>
                        {/* 金額 */}
                        <TableCell align="right">{historyRow.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={row?.history?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  showFirstButton
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* 表題 */}
              <Typography variant="h6" gutterBottom component="div">
                履歴
              </Typography>

              {/* Collapseテーブル */}
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <Table size="small" aria-label="purchases">
                  {/* Collapseのヘッダー */}
                  <TableHead>
                    <TableRow>
                      <TableCell>取引Id</TableCell>
                      <TableCell>日付</TableCell>
                      <TableCell>種類</TableCell>
                      <TableCell>交換希望者</TableCell>
                      <TableCell>提供者</TableCell>
                      <TableCell align="right">額</TableCell>
                      <TableCell>DDP名</TableCell>
                      <TableCell>DDP種類</TableCell>
                      <TableCell>DDP対象</TableCell>
                      <TableCell>提供方法</TableCell>
                      <TableCell>ステータス</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Collapseの中身 */}
                  <TableBody>
                    {(rowsPerPageD > 0
                      ? row?.historyDeal?.slice(
                          pageD * rowsPerPageD,
                          pageD * rowsPerPageD + rowsPerPageD
                        )
                      : row?.historyDeal
                    )?.map((historyRow) => (
                      <TableRow key={Math.random()}>
                        {/* id */}
                        <TableCell
                          className="hover:cursor-pointer"
                          onCli
                          onClick={() =>
                            window.open(
                              "/deal/" + historyRow?.id,
                              "_blank",
                              // "rel=noopener noreferrer"
                            )
                          }
                        >
                          {historyRow.id}
                        </TableCell>
                        {/* 日付 */}
                        <TableCell component="th" scope="row">
                          {new Date(
                            historyRow.createdAt?.toDate()
                          ).toLocaleString()}
                        </TableCell>
                        {/* 種類 */}
                        <TableCell>{historyRow.cause}</TableCell>
                        {/* 売り主 */}
                        <TableCell>{historyRow.buy}</TableCell>
                        {/* 買い主 */}
                        <TableCell>{historyRow.buyer}</TableCell>
                        {/* 額 */}
                        <TableCell>{historyRow.amount}</TableCell>
                        {/* DDP名 */}
                        <TableCell>{historyRow.productName}</TableCell>
                        {/* DDP種類 */}
                        <TableCell>{historyRow.productOffering}</TableCell>
                        {/* DDP対象 */}
                        <TableCell>{historyRow.productTarget}</TableCell>
                        {/* 提供方法 */}
                        <TableCell>{historyRow.delivery}</TableCell>
                        {/* ステータス */}
                        <TableCell>{historyRow.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={row?.historyDeal?.length || 0}
                  rowsPerPage={rowsPerPageD}
                  page={pageD}
                  showFirstButton
                  onPageChange={handleChangePageD}
                  onRowsPerPageChange={handleChangeRowsPerPageD}
                />
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const RunruTableO = ({ villagers }) => {
  // テーブルページング
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 検索関数
  const [rows, setRows] = useState([villagers]);
  const [originalRows, setOriginalRows] = useState([]);
  const [dataloading, setDataLoading] = useState(true);

  // 検索関数
  // const requestSearch = (searchVal) => {
  //   const filteredRows = originalRows.filter((row) => {
  //     return (row.firstName + row.lastName)
  //       .toLowerCase()
  //       .includes(searchVal.target.value.toLowerCase());
  //   });
  //   console.log("filteredRows =>",filteredRows)
  //   setRows(filteredRows);
  // };

  const requestSearch = (searchVal) => {
    const filteredRows = originalRows.filter((row) => {
      const fullName = (
        (row.firstName || "") + (row.lastName || "")
      ).toLowerCase();
      return fullName.includes(searchVal.target.value.toLowerCase());
    });
    setRows(filteredRows);
  };

  // データセット
  useEffect(() => {
    setRows(villagers);
    setOriginalRows(villagers);
    setDataLoading(false);
  }, [villagers]);

  useEffect(() => {
    setRows(villagers);
    setOriginalRows(villagers);
    setDataLoading(false);
  }, [dataloading]);

  return (
    <>
      {/* search */}
      <TextField
        id="search-bar"
        className="my-2"
        label="ユーザ検索"
        variant="standard"
        placeholder="名前"
        size="small"
        onChange={(searchVal) => requestSearch(searchVal)}
        sx={{ m: 1.5 }}
      ></TextField>

      {/* テーブル本体 */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" size="small">
            {/* ヘッダー */}
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>名前</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">ルンル残高(runru)無制限</TableCell>
                <TableCell align="right">ルンル残高(runru)90日</TableCell>
                <TableCell align="right">ルンル残高(runru)180日</TableCell>
                <TableCell align="right">ルンル残高(runru)365日</TableCell>
                <TableCell align="right">セカイムラ登録日</TableCell>
              </TableRow>
            </TableHead>
            {/* 中身 */}
            <TableBody>
              {(rowsPerPage > 0
                ? rows?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              )?.map((row) => (
                <Row key={Math.random()} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default RunruTableO;
