import React from "react";
import ContentLoader from "react-content-loader";

const LoaderBackImg = (props) => {
  return (
    <>
      <ContentLoader
        speed={0.5}
        width={160}
        height={288}
        className="w-full h-72 rounded-t-md"
        backgroundColor="#d9e4d8"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="-43" y="-13" rx="0" ry="0" width="1414" height="300" />
      </ContentLoader>
    </>
  );
};

export default LoaderBackImg;
