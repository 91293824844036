import React from "react";

const Edit = (props) => {
  const { inputRef, updateChannel, setChannelName, channelName } = props;
  return (
    <form
      style={{ flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault();
        inputRef.current.blur();
      }}
    >
      <input
        autoFocus
        className="channel-header__edit-input"
        onBlur={updateChannel}
        onChange={(e) => setChannelName(e.target.value)}
        placeholder="新しいチャット名を入力"
        ref={inputRef}
        value={channelName}
      />
    </form>
  );
};

export default Edit;
