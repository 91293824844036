import React, { useState } from "react";
import { QRCodeSVG } from "qrcode.react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Divider } from "@mui/material";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";

const DisplayQRCode = () => {
  const [message, setMessage] = useState("クリックしてコピー");

  const copyToClipboard = async () => {
    await global.navigator.clipboard.writeText(resultText);
    setMessage("URLをコピーしました。");
  };
  var currentURL = window.location.pathname; // 現在のURLのパス部分を取得
  let path = "https://sekaimura-add3c.firebaseapp.com" + currentURL;
  const [resultText, setResultText] = useState(path);

  //   console.log(window.location.href);
  return (
    <>
      <div className="flex flex-col justify-center items-center space-y-3">
        <div className="text-xl font-bold">QRコード</div>
        <QRCodeSVG value={path} />
      </div>
      {/* <div>
        <div className="z-30">
          <Tooltip title="Copy to Clipboard" placement="top" arrow>
            <IconButton
              color="primary"
              size="small"
              onClick={() => copyToClipboard()}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </div> */}
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
      >
        <Tooltip title={message} placement="top" arrow>
          <IconButton
            color="primary"
            size="small"
            onClick={() => copyToClipboard()}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={path}
          inputProps={{ "aria-label": "search google maps" }}
          disabled
        />
      </Paper>
      {/* {message ? (
        <div className="text-gray-500 font-xs mt-4">{message}</div>
      ) : (
        <div> </div>
      )} */}

      {/* <p className="break-words whitespace-pre-wrap">{window.location.href}</p> */}
      {/* <div className=" word-break mt-4">{window.location.href}</div> */}
    </>
  );
};

export default DisplayQRCode;
