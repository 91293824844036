import React, { useState } from "react";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Avatar, Button } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";

const EditPostCommentReply = ({ setOpen, requestId, target }) => {
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    message: "",
    errorMessage: "",
    error: null,
    loading: false,
  });
  const { message, errorMessage, error, loading } = data;

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // console.log("message =>", message);
  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, errorMessage: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, errorMessage: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "replyPostComment", target?.id), {
        message,
        updatedAt: serverTimestamp(),
        updator: user,
        reqson: "EditPostCommentReply",
        component: "EditPostCommentReply.js",
      });

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
      const errorData = {
        message: err.message,
        stack: err.stack,
      };
      addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: errorData,
        component: "EditPostCommentReply.js",
      });
    }
  };

  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">返信</div>
        <textarea
          style={{ resize: "none", fontSize: "16px" }}
          type="text"
          name="message"
          className="textarea textarea-bordered w-full h-60 p-1"
          placeholder={target.message}
          onChange={handleChange}
        ></textarea>

        <Button
          className="btn"
          name="message"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {errorMessage ? (
          <div className=" text-red-500">{errorMessage}</div>
        ) : (
          ""
        )}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditPostCommentReply;
