import { doc, getDoc } from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import { db } from "../api/firebase";

const GetUserPrefecture = ({ userId }) => {
  const [prefecture, setPrefecture] = useState("");
  useEffect(() => {
    const init = async () => {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists) {
        setPrefecture(docSnap.data().prefecture);
      }
    };
    userId && init();
  }, [userId]);

  return prefecture;
};

export default memo(GetUserPrefecture);
