import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../api/firebase";
import { updateDeviceStatus } from "../utils/updateDeviceStatus";
import { getOrCreateDeviceId } from "../utils/deviceId";

// デバイスのオンライン／オフラインステータスを更新するカスタムフック
export function useUpdateDeviceStatusOnVisibilityChange() {
  useEffect(() => {
    const handleVisibilityChange = () => {
      const isOnline = document.visibilityState === "visible";
      const userId = auth.currentUser?.uid;
      const deviceId = getOrCreateDeviceId();

      if (userId && deviceId) {
        updateDeviceStatus(userId, deviceId, isOnline);
        
      }
    };

    // pagehide と pageshow イベントリスナーの追加
    const handleWindowChange = (event) => {
      const isOnline = event.type === "pageshow";
      const userId = auth.currentUser?.uid;
      const deviceId = getOrCreateDeviceId();

      if (userId && deviceId) {
        updateDeviceStatus(userId, deviceId, isOnline);
      }
    };

    window.addEventListener("pagehide", handleWindowChange);
    window.addEventListener("pageshow", handleWindowChange);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // 認証状態を監視するリスナーを設定
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        handleVisibilityChange(); // 初回とユーザーのログイン状態が変わった時に実行
      }
    });

    // コンポーネントのクリーンアップ時にイベントリスナーを解除
    return () => {
      window.removeEventListener("pagehide", handleWindowChange);
      window.removeEventListener("pageshow", handleWindowChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      unsubscribe(); // 認証状態の監視を解除
    };
  }, []);
}
