// src/utils/updateDeviceToken.js
import { db } from "../api/firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { getOrCreateDeviceId } from "./deviceId";

export async function updateDeviceToken(userId, token) {
  const deviceId = getOrCreateDeviceId();
  const deviceRef = doc(db, "users", userId, "devices", deviceId);

  await setDoc(deviceRef, {
    token: token,
    lastActive: serverTimestamp(),
    // 他の必要なデバイス情報をここに追加
  }, { merge: true });
}
