import React, { useEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/solid";
import { getDoc, doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Tooltip } from "@mui/material";

const SelfIntroduction = ({ onclick }) => {
  const [user, setUser] = useState("");

  useEffect(() => {
    // ユーザ取得
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
      if (doSnap.exists) {
        setUser(doSnap.data());
      }
    });
  }, []);

  return (
    <>
      <div className="border bg-white rounded-md p-4 mt-4 max-w-[556px] ">
        <div className=" font-bold text-xl my-3">自己紹介</div>
        <div
          className="absolute top-2 right-2"
          onClick={() => onclick("editSelfIntroduction")}
        >
          <PencilIcon className="lg:hidden group-hover:block cursor-pointer h-8 w-8  rounded-full hover:shadow-2xl hover:bg-gray-500 hover:text-white" />
        </div>

        <div className="flex flex-col">
          <Tooltip title="クリックで編集" arrow placement="top">
            <div
              className=" flex  justify-between items-center p-2  rounded-md hover:bg-gray-100  cursor-pointer border shadow-sm duration-150 hover:shadow-lg group"
              onClick={() => onclick("editSelfIntroduction")}
            >
              <p className=" whitespace-pre-wrap ">
                {user.selfIntroduction || "自己紹介"}
              </p>
              <PencilIcon className="h-6 w-6 hidden lg:group-hover:block" />
              <PencilIcon className="h-6 w-6 lg:hidden justify-end" />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default SelfIntroduction;
