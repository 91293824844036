import React, { useEffect, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Avatar, Divider, Tooltip } from "@mui/material";
import Img from "../data/img/defaultImage.png";

import { auth, db } from "../api/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import Vimeo from "@u-wave/react-vimeo";
import { useNavigate } from "react-router-dom";

import { TabList } from "@mui/lab";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";

import Box from "@mui/material/Box";
import DisplayVideo from "./DisplayVideo";
import HorizontalMenuAtMovieList from "./HorizontalMenuAtMovieList";

const ChannelPreviewOfMoviesTop = ({ channel, channelList }) => {
  // console.log("channel =>", channel);

  // チャンネルの動画取得
  const [movies, setMovies] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      await getDocs(
        query(
          collection(db, "movie"),
          where("target", "==", "movieChannel"),
          where("targetId", "==", channel?.id),
          where("isPublish", "==", true),
          orderBy("createdAt", "desc"),
          limit(10)
        )
      ).then((docs) =>
        docs.forEach((elm) => {
          arr.push({ id: elm.id, ...elm.data() });
        })
      );
      channel && setMovies(arr);
    };
    init();
  }, []);

  const [moviesOrderByAsc, setMoviesOrderByAsc] = useState([]);
  useEffect(() => {
    const init = async () => {
      let arr = [];
      await getDocs(
        query(
          collection(db, "movie"),
          where("target", "==", "movieChannel"),
          where("targetId", "==", channel?.id),
          where("isPublish", "==", true),
          orderBy("createdAt", "asc"),
          limit(10)
        )
      ).then((docs) =>
        docs.forEach((elm) => {
          arr.push({ id: elm.id, ...elm.data() });
        })
      );
      channel && setMoviesOrderByAsc(arr);
    };
    init();
  }, []);

  const navigate = useNavigate();
  const handleMove = () => {
    navigate("/movieChannel/" + channel.id);
  };

  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* チャンネルプレビュー */}
      <div className="flex flex-col bg-back">
        <div className="mb-8">
          <Divider></Divider>
        </div>

        {/* title/説明/もっと見る */}
        <div className="flex justify-between items-center p-1">
          <div className="flex flex-col">
            <div className="grid grid-cols-1 space-x-2 hover:cursor-pointer ">
              <div className=" col-span-1">
                <div className="flex space-x-1">
                  <img
                    src={channel?.background || Img}
                    alt=""
                    className="w-20 h-20 rounded-full"
                  />
                  <Tooltip title="もっと見る" placement="top" arrow>
                    <div
                      className=" font-bold text-2xl break-words whitespace-pre-wrap line-clamp-2 hover:text-blue-600 "
                      onClick={handleMove}
                    >
                      <div>{channel?.title}</div>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 mt-1">
              <div className="col-span-1 text-gray-500 text-sm break-words line-clamp-3 whitespace-pre-wrap">
                {channel?.explain}
              </div>
            </div>
          </div>
        </div>
        {/* もっと見る */}
        <div className="flex justify-end p-1" onClick={handleMove}>
          <div className="flex items-center border rounded-full h-fit w-fit px-3 py-1 hover:border-blue-500 hover:cursor-pointer group">
            <ArrowRightIcon className="group-hover:text-blue-500" />
            <div className="text-gray-500 text-xs group-hover:text-blue-500">
              もっと見る
            </div>
          </div>
        </div>

        {/* 動画リスト */}
        {channel?.orderByForMoviesPage === "desc"
          ? movies && <HorizontalMenuAtMovieList contents={movies} />
          : moviesOrderByAsc && (
              <HorizontalMenuAtMovieList contents={moviesOrderByAsc} />
            )}
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {movies?.map((movie, idx) => (
            <div key={movie.id} className=" col-span-1  rounded-md p-2 m-2">
              <DisplayVideo movie={movie} />
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default ChannelPreviewOfMoviesTop;
