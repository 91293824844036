import React, { useState } from "react";
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";

import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import IndustryMenu from "../components/IndustryMenu";

// MaterialUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const EditGroupAccept = ({ setOpen, target }) => {
  // job選択関連
  const [group] = useDocumentData(doc(db, "group", target?.id));
  const [groupAccept] = useCollectionData(collection(db, "groupAccept"));
  const [creator] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    accept: "",
    message: "",
    error: null,
    loading: false,
  });
  const { accept, message, error, loading } = data;

  // プロフィール変更
  const handleChange = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });

    try {
      await updateDoc(doc(db, "group", target?.id), {
        accept,
      });
      // グループのupdates作成・更新
      await addDoc(collection(db, "group", target?.id, "updates"), {
        createdAt: serverTimestamp(),
        title: "設定変更",
        information:
          creator?.lastName +
          creator?.firstName +
          "さんがグループ[" +
          group?.title +
          "]の表示設定を[" +
          accept +
          "]に変更しました",
        creator: auth.currentUser.uid,
        creatorAvatar: creator?.avatar,
      });

      window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log(err);
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div>グループ受け入れ種類</div>
        <TextField
          id="demo-simple-select"
          name="accept"
          label="受け入れ"
          defaultValue=""
          className="w-full"
          sx={{ marginTop: 2 }}
          size="small"
          onChange={handleChange}
          select
        >
          {groupAccept?.map((type) => (
            <MenuItem key={Math.random()} value={type.groupAccept} dense>
              <div className="flex space-x-2 items-center">
                <div>{type.groupAccept}</div>
                <div className="text-gray-400 text-xs">{type.explain}</div>
              </div>
            </MenuItem>
          ))}
        </TextField>
        <Button
          className="btn"
          name="job"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditGroupAccept;
