import React, { useState } from "react";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";

import { useCollectionData } from "react-firebase-hooks/firestore";

// MaterialUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect } from "react";

const SendMail = ({ setOpen }) => {
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    type: "",
    message: "",
    error: null,
    loading: false,
  });
  const { type, message, error, loading } = data;

  //   メール送信
  const [value, setValue] = React.useState("onlyVillager");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [target, setTarget] = useState([]);

  //   村民へ強制送信
  const queryIsVillager = query(
    collection(db, "users"),
    where("isVillager", "==", true)
  );
  //   ユーザへ強制送信
  const queryIsUser = query(
    collection(db, "users"),
    where("isVillager", "==", false)
  );

  //   通知オンの村民へ送信
  const queryIsVillagerNotificationOn = query(
    collection(db, "users"),
    where("isVillager", "==", true),
    where("recieveMailFromSecretariat", "==", true)
  );

  //   通知オンの全員へ送信
  const queryAllNotificationOn = query(
    collection(db, "users"),
    where("recieveMailFromSecretariat", "==", true)
  );

  //   全員へ送信
  const queryAll = query(collection(db, "users"));

  useEffect(() => {
    const setReceiver = async () => {
      let arr = [];
      setTarget([]);
      switch (value) {
        case "onlyVillager":
          await getDocs(queryIsVillager).then((docs) => {
            docs.forEach((doc) => {
              arr.push(doc.data().email);
            });
            setTarget(arr);
          });
          break;

        case "onlyUser":
          await getDocs(queryIsUser).then((docs) => {
            docs.forEach((doc) => {
              arr.push(doc.data().email);
            });
            setTarget(arr);
          });
          break;

        case "notificationOnVillager":
          await getDocs(queryIsVillagerNotificationOn).then((docs) => {
            docs.forEach((doc) => {
              arr.push(doc.data().email);
            });
            setTarget(arr);
          });
          break;

        case "notificationOnAll":
          await getDocs(queryAllNotificationOn).then((docs) => {
            docs.forEach((doc) => {
              arr.push(doc.data().email);
            });
            setTarget(arr);
          });
          break;

        case "all":
          await getDocs(queryAll).then((docs) => {
            docs.forEach((doc) => {
              arr.push(doc.data().email);
            });
            setTarget(arr);
          });
          break;

        default:
          break;
      }
    };
    setReceiver();
  }, [value]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });

    try {
      await updateDoc(doc(db, "group", target?.id), {
        type,
      });
      window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log(err);
    }
  };

  const subject = "【お知らせ】セカイムラ事務局です";
  const body = "こんにちわセカイムラ事務局です";

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>

        <div>メール送信</div>
        <div>{target?.length}名に送信</div>

        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="onlyVillager"
              control={<Radio />}
              label="村民のみへ送信"
            />
            <FormControlLabel
              value="onlyUser"
              control={<Radio />}
              label="ユーザのみへ送信"
            />
            <FormControlLabel
              value="notificationOnVillager"
              control={<Radio />}
              label="通知オンの村民へ送信"
            />
            <FormControlLabel
              value="notificationOnAll"
              control={<Radio />}
              label="通知オンのユーザー・村民へ送信"
            />
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="ユーザー・村民へ強制送信"
            />
          </RadioGroup>
        </FormControl>

        {/* <TextField
          id="demo-simple-select"
          name="type"
          label="グループの種類"
          placeholder={target?.type}
          defaultValue=""
          className="w-full"
          sx={{ marginTop: 2 }}
          size="small"
          onChange={handleChange}
          select
        >
          {groupTypes?.map((type) => (
            <MenuItem key={Math.random()} value={type.groupType}>
              {type.groupType}
            </MenuItem>
          ))}
        </TextField> */}
        <Button
          className="btn"
          name="job"
          //   onClick={handleSubmit}
          disabled={!target}
          variant="contained"
        >
          <a
            href={`mailto:sekaimura2022@gmail.com?bcc=${target}&subject=${
              subject || ""
            }&body=${body || ""}`}
          >
            {loading ? "送信しています..." : target?.length + " 名に送信"}
          </a>
        </Button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default SendMail;
