import React, { useEffect, useState } from "react";

import "react-activity-feed/dist/index.css";
import { auth } from "../api/firebase";
import Loader1Row from "./Loader1Row";

function ActivityFeedInGroup() {
  const apiKey = process.env.REACT_APP_STREAM_API_KEY;
  const secret = process.env.REACT_APP_STREAM_API_SECRET;
  const appId = process.env.REACT_APP_STREAM_APP_ID;

  const stream = require("getstream");

  const [token, setToken] = useState("");

  // 初期処理
  useEffect(() => {
    const initFeed = async () => {
      const feedtoken = await fetch(
        // "http://localhost:8080/feedtoken/",
        process.env.REACT_APP_STREAM_GET_FEED_TOKEN_URL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: auth.currentUser.uid,
          }),
        }
      ).then((r) => r.json());
      setToken(feedtoken);
    };

    initFeed();
  }, []);

  useEffect(() => {
    const initFeedUser = async () => {
      if (token) {
        const client = stream.connect(apiKey, token, appId);
        const user = client.feed("users", auth.currentUser.uid);
        await user.addActivity({
          actor: "SU:" + auth.currentUser.uid,
          verb: "add",
          object: "picture:10",
          foreign_id: "picture:10",
          message: "Feedに投稿できたかな？",
        });
      }
    };
    initFeedUser();
  }, [token]);

  // console.log("token =>",token)

  if (!token) {
    // return <Loader1Row />;
    return <div>読み込み中</div>;
  } else {
    return (
      <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        {/* <StreamApp apiKey={apiKey} appId={appId} token={token}>
          <div className="flex justify-end">
            <NotificationDropdown right />
          </div>
          <StatusUpdateForm
            emojiI18n={{
              search: "Type here to search...",
              categories: { recent: "Recent Emojis" },
            }}
          />
          <FlatFeed
            notify
            feedGroup="user"
            options={{
              limit: 6,
              withOwnChildren: true,
              withRecentReactions: true,
            }}
            Paginator={InfiniteScrollPaginator}
            Activity={({ activity, feedGroup, userId }) => (
              <Activity
                activity={activity}
                feedGroup={feedGroup}
                userId={userId}
                Footer={() => (
                  <>
                    <ActivityFooter
                      activity={activity}
                      feedGroup={feedGroup}
                      userId={userId}
                    />
                    <CommentField activity={activity} />
                    <CommentList
                      activityId={activity.id}
                      CommentItem={({ comment }) => (
                        <div className="wrapper">
                          <CommentItem comment={comment} />
                          <LikeButton reaction={comment} />
                        </div>
                      )}
                    />
                  </>
                )}
              />
            )}
          />
        </StreamApp> */}
      </div>
    );
  }
}
export default ActivityFeedInGroup;
