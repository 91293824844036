import { Avatar } from "@mui/material";
import React, { useState } from "react";
import Img from "../data/img/defaultImage.png";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { Button, TextareaAutosize } from "@mui/material";

const MovieChannelCard = ({ channel }) => {
  //   console.log("channel =>", channel);
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  const [creator] = useDocumentData(doc(db, "users", channel?.creator));

  const docRef = doc(db, "movieChannel", channel?.id);
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(null);
  // 編集フォームの変更を処理する処理
  const handleEdit = (e) => {
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };
  // データの編集を保存する処理
  const saveData = async (e) => {
    console.log("editedData =>", editedData);
    if (!editedData) {
      setEditMode(false);
      return;
    }

    if (editedData[e.target.name].length === 0) {
      setEditMode(false);
      return;
    }

    await updateDoc(docRef, {
      [e.target.name]: editedData[e.target.name],
    });
    // setEditMode(false);
    window.location.reload();
  };

  // 編集モードに入る処理
  const enterEditMode = () => {
    setEditMode(true);
    // setEditedData(data);
  };

  return (
    <>
      <div className=" grid grid-cols-12 bg-white mb-5 rounded-md p-5 items-center justify-start ">
        {/* avatar */}
        <div className="hidden sm:block col-span-2 m-5">
          <img
            src={channel?.type === "セカイムラ" ? Img : creator?.avatar}
            className="rounded-full border "
            alt=""
          />
        </div>

        {/* タイトル・誰・説明 */}
        <div className=" flex flex-col space-y-5 col-span-12 sm:col-span-10">
          <div className=" font-bold text-4xl  whitespace-pre-wrap break-words line-clamp-3">
            {channel?.title}
          </div>
          <div className="text-xs">@{channel?.type}</div>
          <div className=" whitespace-pre-wrap break-words line-clamp-3 text-gray-300 ">
            {channel?.explain}
          </div>
          {/* <div className="rounded-full text-white bg-black w-fit  py-2 px-5 ">
            チャンネル登録
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MovieChannelCard;

const EditableContent = (props) => {
  const {
    target,
    name,
    editMode,
    user,
    creator,
    handleEdit,
    saveData,
    enterEditMode,
  } = props;

  return (
    <>
      {editMode && (user?.isAdmin || creator?.uid === auth.currentUser.uid) ? (
        <div className="grid grid-cols-1 w-full space-x-2 items-center ">
          <TextareaAutosize
            name={name}
            style={{ resize: "none", fontSize: "16px" }}
            onChange={handleEdit}
            placeholder={target}
            defaultValue={target}
            className=" p-1 w-full scrollbar-hide"
            minRows={3}
            maxRows={5}
          />
          <Button name={name} onClick={saveData}>
            ✓
          </Button>
        </div>
      ) : (
        <div
          onClick={enterEditMode}
          className=" font-bold text-4xl  whitespace-pre-wrap break-words line-clamp-3"
        >
          {target}
        </div>
      )}
    </>
  );
};
