import { Avatar } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { db } from "../api/firebase";

const UserCard = ({ id }) => {
  const [user, setUser] = useState("");
  useEffect(() => {
    if (id) {
      const getUserInfo = async () => {
        getDoc(doc(db, "users", id)).then((doSnap) => {
          if (doSnap.exists) {
            setUser(doSnap.data());
          }
        });
      };
      getUserInfo();
    }
  }, [id]);

  return (
    <div className="flex space-x-2 p-1 rounded-md border items-center ">
      <Avatar src={user?.avatar} sx={{width:"20px", height:"20px"}} />
      <div className="text-gray-400">
        {user?.lastName} {user?.firstName}
      </div>
    </div>
  );
};

export default UserCard;
