import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import UserAccordion from "./UserAccordion";

const UserList = ({ users, handlePermissionChange, handleStopChange, excludeVillagers, showStoppedAccounts }) => {
  return (
    <div>
      <Paper style={{ marginBottom: "1rem", padding: "0.5rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography>ユーザ</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>メール</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>UID</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>村民権限</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>アカウント停止</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>登録日</Typography>
          </Grid>
        </Grid>
      </Paper>
      {users
        .filter(
          (user) =>
            (!excludeVillagers || !user.isVillager) &&
            (!showStoppedAccounts || user.isStop)
        )
        .map((user) => (
          <UserAccordion
            key={user.id}
            user={user}
            handlePermissionChange={handlePermissionChange}
            handleStopChange={handleStopChange}
          />
        ))}
    </div>
  );
};

export default UserList;
