import React, { useState, useEffect, memo } from "react";
import {
  query,
  collection,
  limit,
  where,
  orderBy,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Badge from "@mui/material/Badge";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { Divider, Tooltip } from "@mui/material";
import DisplayNotificationContent from "./DisplayNotificationContent";

// mui - Menu関連
import Menu from "@mui/material/Menu";

// mui badge関連
import EventNoteIcon from "@mui/icons-material/EventNote";
import { styled } from "@mui/material/styles";
import GroupsIcon from "@mui/icons-material/Groups";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { useNotification } from "../redux/useNotification";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const { displayNotification } = useNotification();

  const [user, setUser] = useState();
  useEffect(() => {
    const initUserInfo = async () => {
      const docRef = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (docRef.exists()) {
        setUser(docRef.data());
      }
    };
    initUserInfo();
  }, []);

  const notificationReadCount = 10;

  const q = query(
    collection(db, "users", auth.currentUser.uid, "notification"),
    orderBy("createdAt", "desc"),
    limit(notificationReadCount)
  );

  const [notificationsTenthCollection] = useCollection(q);
  const notificationsTenth = notificationsTenthCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const q_unread = query(
    collection(db, "users", auth.currentUser.uid, "notification"),
    where("isRecognition", "==", false)
  );
  const [unreadNotification] = useCollectionData(q_unread);

  const updateIsRecognition = async (arr) => {
    arr?.forEach((ids) => {
      updateDoc(
        doc(db, "users", auth.currentUser.uid, "notification", ids.id),
        {
          isRecognition: true,
        }
      );
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    updateIsRecognition(notificationsTenth);
  };

  const navigate = useNavigate();
  const moveToAllNotifications = () => {
    navigate("/allnotifications");
    handleClose();
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 2,
      top: 45,
    },
  }));

  const AvatarAndIcon = ({ doc }) => {
    const icons = {
      event: { icon: EventNoteIcon, color: "bg-green-700" },
      group: { icon: GroupsIcon, color: "bg-blue-700" },
      deal: { icon: VolunteerActivismIcon, color: "bg-red-700" },
    };

    const Icon = ({ Icon }) => {
      return (
        <>
          {Icon && (
            <Icon
              className={` ${icons[doc.target]?.color} " text-white  rounded-full p-1 "`}
              sx={{ width: 27, height: 27 }}
            />
          )}
        </>
      );
    };

    return (
      <>
        <StyledBadge badgeContent={<Icon Icon={icons[doc.target]?.icon} />}>
          {doc.fromAvatar ? (
            <img
              src={doc.fromAvatar}
              alt=""
              className="h-16 w-16 rounded-full border-white"
            />
          ) : (
            <div className="h-16 w-16 rounded-full bg-gray-300 border-white" />
          )}
        </StyledBadge>
      </>
    );
  };

  return (
    <>
      <Tooltip title="お知らせ" arrow>
        <div
          tabIndex="0"
          className="flex flex-col space-y-1 h-12 w-12  sm:w-12 justify-center items-center hover:cursor-pointer hover:text-blue-500 duration-150  rounded-md "
        >
          <Badge
            color="primary"
            badgeContent={unreadNotification?.length}
            max={99}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NotificationsActiveIcon
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
          </Badge>
          <div className="w-fit text-xs">お知らせ</div>
        </div>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ mt: "12px" }}
      >
        <div className="p-2 rounded-md w-96">
          <div className="font-bold text-xl mb-2">お知らせ</div>

          <div
            className="flex justify-end text-blue-500 underline hover:cursor-pointer hover:font-bold"
            onClick={moveToAllNotifications}
          >
            もっと見る
          </div>

          <Divider />
          <div className="flex flex-col space-y-1 h-[456px] md:h-[566px]  overflow-y-auto">
            {notificationsTenth?.map((doc) => (
              <div key={doc.id}>
                <div
                  className={`${
                    doc.isRecognition === false && "bg-gray-200"
                  } flex rounded-md p-2 items-center space-x-5 hover:cursor-pointer hover:bg-gray-100 shadow-sm hover:shadow-lg duration-150`}
                >
                  <AvatarAndIcon doc={doc} />
                  {doc ? (
                    <DisplayNotificationContent
                      document={doc}
                      notificationsTenth={notificationsTenth}
                      handleClose={handleClose}
                    />
                  ) : (
                    <div>通知内容が見つかりませんでした</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Menu>
    </>
  );
};

export default memo(Notifications);


// const messages = {
//   DDP交換希望リクエスト: {
//     from: "さんが",
//     target: "DDP",
//     message: "の交換を希望しています。",
//   },
//   DDP交換希望リクエストbuyキャンセル: {
//     from: "さんが",
//     target: "DDP",
//     message: "の交換をキャンセルしました。",
//   },
//   DDP交換希望リクエストbuyerキャンセル: {
//     from: "さんが",
//     target: "DDP",
//     message: "の交換希望をキャンセルしました。",
//   },
//   DDP配送完了: {
//     from: "さんが",
//     target: "DDP",
//     message: "の配送を完了しました。",
//   },
//   DDP提供準備完了: {
//     from: "さんの",
//     target: "DDP",
//     message: "の提供準備が完了しました。",
//   },
//   DDP受諾: { from: "さんが", target: "DDP", message: "を受諾しました。" },
//   DDP受け取り: { from: "さんが", target: "DDP", message: "を受け取りました" },
//   DDP買った人評価: {
//     from: "さんが",
//     target: "DDP",
//     message: "の交換を評価しました。",
//   },
//   DDP取引評価: {
//     from: "さんが",
//     target: "DDP",
//     message: "の交換を評価しました。",
//   },
//   DDP登録完了: {
//     from: "さんの",
//     target: "DDP",
//     message: "の登録が完了しました。",
//   },
//   DDPに質問: { from: "さんが", target: "DDP", message: "に質問しました。" },
//   グループ参加希望: {
//     from: "さんが",
//     target: "グループ",
//     message: "の参加を希望しています。",
//   },
//   グループ参加許可: {
//     from: "さんが",
//     target: "グループ",
//     message: "の参加を許可しました。",
//   },
//   イベント時間変更: {
//     from: "さんが",
//     target: "イベント",
//     message: "の時間を変更しました。",
//   },
//   イベントタイトル変更: {
//     from: "さんが",
//     target: "イベント",
//     message: "のタイトルを変更しました。",
//   },
//   イベント場所変更: {
//     from: "さんが",
//     target: "イベント",
//     message: "の場所を変更しました。",
//   },
//   イベント集合方法変更: {
//     from: "さんが",
//     target: "イベント",
//     message: "の集合方法を変更しました。",
//   },
//   イベントURL変更: {
//     from: "さんが",
//     target: "イベント",
//     message: "のURLを変更しました。",
//   },
//   イベント費用変更: {
//     from: "さんが",
//     target: "イベント",
//     message: "の費用を変更しました。",
//   },
//   イベント質問到着: {
//     from: "さんが",
//     target: "イベント",
//     message: "に質問しました。",
//   },
//   メッセージ到着: {
//     from: "さんから",
//     target: "チャット",
//     message: "にメッセージが届いています。",
//   },
//   拠点参加希望: {
//     from: "さんが",
//     target: "拠点",
//     message: "の参加を希望しています。",
//   },
//   拠点参加許可: {
//     from: "さんが",
//     target: "グループ",
//     message: "の参加を許可しました。",
//   },
//   拠点名称変更: {
//     from: "さんが",
//     target: "拠点",
//     message: "のタイトルを変更しました。",
//   },
// };
