import React, { useState } from "react";
import { organizerList, meetingPlaceList } from "./constants";
import CheckboxProton from "./CheckBoxPronton";
import FilterListToggle from "./FilterListToggle";
import SliderProton from "./SliderPronton";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";

// const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
//   color: theme.palette.text.secondary,
//   [`& .${treeItemClasses.content}`]: {
//     color: theme.palette.text.secondary,
//     borderTopRightRadius: theme.spacing(2),
//     borderBottomRightRadius: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//     fontWeight: theme.typography.fontWeightMedium,
//     '&.Mui-expanded': {
//       fontWeight: theme.typography.fontWeightRegular,
//     },
//     '&:hover': {
//       backgroundColor: theme.palette.action.hover,
//     },
//     '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
//       backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
//       color: 'var(--tree-view-color)',
//     },
//     [`& .${treeItemClasses.label}`]: {
//       fontWeight: 'inherit',
//       color: 'inherit',
//     },
//   },
//   [`& .${treeItemClasses.group}`]: {
//     marginLeft: 0,
//     [`& .${treeItemClasses.content}`]: {
//       paddingLeft: theme.spacing(2),
//     },
//   },
// }));

// function StyledTreeItem(props) {
//   const {
//     bgColor,
//     color,
//     labelIcon: LabelIcon,
//     labelInfo,
//     labelText,
//     ...other
//   } = props;

//   return (
//     <StyledTreeItemRoot
//       label={
//         <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
//           <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
//           <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
//             {labelText}
//           </Typography>
//           <Typography variant="caption" color="inherit">
//             {labelInfo}
//           </Typography>
//         </Box>
//       }
//       style={{
//         '--tree-view-color': color,
//         '--tree-view-bg-color': bgColor,
//       }}
//       {...other}
//     />
//   );
// }

// StyledTreeItem.propTypes = {
//   bgColor: PropTypes.string,
//   color: PropTypes.string,
//   labelIcon: PropTypes.elementType.isRequired,
//   labelInfo: PropTypes.string,
//   labelText: PropTypes.string.isRequired,
// };

const FilterPanel = ({
  selectedCategory,
  selectCategory,
  selectedCategoryMeetingPlace,
  selectCategoryMeetingPlace,
  //   selectedRating,
  selectedPrice,
  //   selectRating,
  //   cuisines,
  values,
  changeChecked,
  changePrice,
  clearFilter,
  researchCount,
}) => {
  // ドロワー
  const [state, setState] = useState(false);
  const toggleDrawer = () => {
    setState(!state);
  };
  const closeDrawer = () => {
    setState(false);
  };

  return (
    <>
      <div className=" p-4 hidden lg:block">
        {/* 主催 */}
        <div className="mb-2">
          <p className="mb-2 text-xl font-bold">主催</p>
          <FilterListToggle
            options={organizerList}
            value={selectedCategory}
            selectToggle={selectCategory}
          />
        </div>
        {/* 集合 */}
        <div className="mb-2">
          <p className="mb-2 text-xl font-bold">集合</p>
          <FilterListToggle
            options={meetingPlaceList}
            value={selectedCategoryMeetingPlace}
            selectToggle={selectCategoryMeetingPlace}
          />
        </div>

        {/* チェックボックス 集合 */}

        {/* チェックボックス */}
        <div className="mb-2">
        <p className="mb-2 text-xl font-bold">終了</p>
          {values?.map((value) => (
            <CheckboxProton
              key={value.id}
              values={value}
              changeChecked={changeChecked}
            />
          ))}
        </div>

        {/* 費用 */}
        <div className="mb-2">
          <p className="mb-10 text-xl font-bold">参加費</p>
          <SliderProton value={selectedPrice} changePrice={changePrice} />
        </div>

        {/* 何らかのレート */}
        {/* <div className="mb-2">
          <p className="mb-1 font-xl">Star Rating</p>
          <FilterListToggle
            options={ratingList}
            value={selectedRating}
            selectToggle={selectRating}
          />
        </div> */}
      </div>

      <div className="">
        <div className="flex justify-end lg:hidden">
          <Button onClick={toggleDrawer}>絞り込み</Button>
        </div>
        <SwipeableDrawer anchor="bottom" open={state} onClose={closeDrawer}>
          <div className=" h-[calc(100vh-160px)]">
            <div className=" flex flex-col p-4 lg:hidden">
              <Button
                disabled={!researchCount || researchCount === 1}
                onClick={clearFilter}
              >
                フィルター解除
              </Button>

              {/* 主催 */}
              <div className="mb-2">
                <p className="mb-2 text-xl font-bold">主催</p>
                <FilterListToggle
                  options={organizerList}
                  value={selectedCategory}
                  selectToggle={selectCategory}
                />
              </div>
              {/* 集合 */}
              <div className="mb-2">
                <p className="mb-2 text-xl font-bold">集合</p>
                <FilterListToggle
                  options={meetingPlaceList}
                  value={selectedCategoryMeetingPlace}
                  selectToggle={selectCategoryMeetingPlace}
                />
              </div>

              {/* 費用 */}
              <div className="mb-2">
                <p className="mb-10 text-xl font-bold">参加費</p>
                <SliderProton value={selectedPrice} changePrice={changePrice} />
              </div>
              <div className="flex justify-end ">
                <Button
                  onClick={closeDrawer}
                  variant="contained"
                  disabled={!researchCount || researchCount === 1}
                >
                  決定
                </Button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    </>
  );
};

export default FilterPanel;
