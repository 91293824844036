import React, { useState } from "react";
import { TextField } from "@mui/material";

import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db} from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

const AddGroupType = ({ setOpen, requestId }) => {


  const [product, setProduct] = useState({
    groupType:"",
    error: "",
    loading: false,
  });
  const { groupType, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  const [user] = useDocumentData(doc(db,"users",auth.currentUser.uid))

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });
    try {
      // 変更処理
      const docRef = collection(db, "groupType");
      await addDoc(docRef, {
        registered:user?.uid,
        registeredName:user?.lastName + user?.firstName,
        registeredAvatar:user?.avatar,
        isPublish:true,
        groupType,
        createdAt: serverTimestamp(),
      });

      // const docRef = await addDoc(collection(db, "ddp", match, "targets"), {
      //   [target]: {},
      // });

      setProduct({
        groupType:"",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">グループのタイプを登録</div>
        <form onSubmit={setRequestDataShop}>
          {/* 提供する対象 */}
          <TextField
            id="outlined-required"
            name="groupType"
            label="グループタイプ"
            defaultValue=""
            size="small"
            placeholder="ex)音楽、スポーツ、有名人"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
          ></TextField>

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
            >
            {loading ? "送信しています..." : "登録"}
          </button>
        {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AddGroupType;
