import React from "react";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import Img from "../data/img/defaultImage.png";
import { Avatar } from "@mui/material";
import { collection, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import ProductRate from "./ProductRate";

const DisplayProduct = ({ product, id }) => {
  // 自分で上げた画像
  const queryMyProductImages = query(collection(db, `products/${id}/images`));
  const [images] = useCollection(queryMyProductImages);
  const myProductImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const handleMove = () => {
    navigate(`/product/${id}`);
    // window.open(`/product/${id}`,'_blank')
  };

  const navigate = useNavigate();
  return (
    <>
      {/* スマホ表示　sm(640)以下表示 */}
      <div className=" md:hidden">
        <div
          className="flex h-36 md:h-48 border rounded-lg cursor-pointer shadow-lg hover:bg-gray-50 hover:shadow-lg"
          aria-disabled={product.isPublish}
        >
          {/* 画像 */}
          <div className="flex relative w-4/12">
            {!myProductImages?.length && (
              <img
                src={Img}
                alt=""
                object-fit="cover"
                className="w-full  rounded-tl-md"
              />
            )}
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              className="w-full  rounded-tl-md"
            >
              {myProductImages?.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image.imageURL}
                      alt=""
                      object-fit="cover"
                      className="w-full h-36  rounded-tl-md"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          {/* タイトル、説明、レート、価格 */}
          <div
            className="flex flex-col justify-between hover:cursor-pointer p-2 space-y-1 w-8/12"
            // onClick={() => navigate(`/product/${id}`)}
            onClick={handleMove}
          >
            {/* タイトルと名前 */}
            <div className="flex flex-col">
              {/* タイトル */}
              <div className="font-bold w-[100%] truncate ">{product.title}</div>
              {/* 名前とアバター */}
              <div className="flex items-center space-x-1  ">
                <div>
                  <Avatar src={product.avatar} sx={{ width: 24, height: 24 }} />
                </div>
                <div className="text-xs font-sans font-bold">
                  {product.name}
                </div>
              </div>
            </div>
            {/* 説明 */}
            <div className=" text-gray-400 text-xs line-clamp-3 ">
              {product.explain}
            </div>
            {/* レートと価格 */}
            <div className="flex flex-col ">
              <div>
                <ProductRate productId={product?.id} />
              </div>
              <div className="flex text-blue-400 font-bold text-md items-center space-x-1">
                <div>{product.runru}</div>
                <div className="text-xs">ルンル</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* sm(640)以上表示 */}
      <div className="hidden md:block">
        <div
          className="flex-col h-96  rounded-sm shadow-lg cursor-pointer bg-gray-50 hover:shadow-2xl "
          aria-disabled={product.isPublish}
        >
          {/* 画像 */}
          <div className="flex relative">
            {!myProductImages?.length && (
              <img
                src={Img}
                alt=""
                object-fit="cover"
                className="w-full h-56 rounded-t-md"
              />
            )}
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              className="w-full"
            >
              {myProductImages?.map((image, index) => {
                return (
                  <div key={index}>
                    <img
                      src={image.imageURL}
                      alt=""
                      object-fit="cover"
                      className="w-full h-56 rounded-t-md"
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>

          <div
            className="flex flex-col  h-40 justify-between hover:cursor-pointer p-2 space-y-1"
            onClick={() => navigate(`/product/${id}`)}
          >
            {/* タイトルと名前 */}
            <div className="flex flex-col  ">
              {/* タイトル */}
              <div className="font-bold truncate">{product.title}</div>

              {/* 名前とアバター */}
              <div className="flex items-center space-x-1  rounded-md ">
                <div>
                  <Avatar src={product.avatar} sx={{ width: 24, height: 24 }} />
                </div>
                <div className="text-xs font-sans font-bold line-clamp-4">
                  {product.name}
                </div>
              </div>
            </div>
            {/* 説明 */}
            <div className=" text-gray-400 text-xs truncate whitespace-pre-wrap h-3/5">
              {product.explain}
            </div>
            {/* レートと価格 */}
            <div className="flex justify-between items-center h-1/5">
              <div>
                {/* <Rate rate={2.5} /> */}
                <ProductRate productId={product?.id} />
              </div>
              <div className="flex text-blue-400 font-bold text-md items-center space-x-1">
                <div>{product.runru}</div>
                <div className="text-xs">ルンル</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayProduct;
