import React from "react";
import DdpTable from "../components/DdpTable";
import AddComponent from "./AddComponent";

const OperateDDP = () => {
  // const [offer] = useDocumentData(doc(db, "ddp", "env"));

  // const [matchingDDP] = useCollection(collection(db, "ddp"));
  // const ddps = matchingDDP?.docs?.map((doc) => ({
  //   id: doc.id,
  //   ...doc.data(),
  // }));
  return (
    <>
      {/* 提供する形と提供を受ける形とマッチング */}
      <div className="mb-3"></div>
      {/* 提供する関連 */}
      <div className="flex">
        <AddComponent
          value="「提供の形（する・受ける）」を登録"
          target="addOffering"
        />
      </div>
      <DdpTable />
    </>
  );
};

export default OperateDDP;
