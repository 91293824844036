import React, { useEffect, useState } from "react";
import { useAuth } from "../context/auth";
import DisplaySignUp from "./DisplaySignUp";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import AddRecommendedSopt from "./AddRecommendedSopt";
import HeaderContainerMixIN from "../components/HeaderContainerMixIN";
import RequestShop from "./RequestShop";
import {  useNavigate } from "react-router-dom";

const ThanksForApplication = () => {
  const { user } = useAuth();
  const navigate = useNavigate(); // 追加

  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const init = async () => {
      const userRef = await getDoc(doc(db, "users", user?.uid));
      try {
        if (userRef?.exists()) {
          setUserInfo({ id: userRef.id, ...userRef.data() });
        }
      } catch (error) {
        console.log("error =>", error);
      }
    };
    if (user) {
      init();
    }
    // 5秒後にHome画面へのリダイレクトを実行
    const redirectTimeout = setTimeout(() => {
      navigate("/home"); // リダイレクト先のパスを適宜変更してください
    }, 5000);

    // コンポーネントがアンマウントされる時にタイマーをクリアする
    return () => clearTimeout(redirectTimeout);
  }, [user, navigate]);

  if (!user) {
    return <div>不正アクセスです</div>;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-2xl font-bold mb-4">
          ご申請ありがとうございます。
        </div>
        <div className="text-lg mb-2">セカイムラマップへのご申請、</div>
        <div className="text-lg mb-2">誠にありがとうございます。</div>
        <div className="text-lg mb-2">頂きました情報は、</div>
        <div className="text-lg mb-2">
          セカイムラ事務局で精査させていただきます。
        </div>

        <div className="text-lg mb-4">よろしくお願い申し上げます。</div>
        <div className="text-lg mb-4">尚、このページは５秒後に自動的にホーム画面へ遷移します。</div>
      </div>
    </>
  );
};

export default ThanksForApplication;
