import React from "react";
import { Button } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

function TestPush({ userId, message }) {
  const functions = getFunctions();
  const sendNotification = () => {
    const sendPushNotification = httpsCallable(
      functions,
      "sendPushNotification"
    );

    sendPushNotification({ userId, message });
    // .then((result) => {
    //   console.log("success result =>",result)
    // })
    // .catch((error) => {
    //   console.error("通知の送信に失敗しました：", error);
    // });
  };

  return <Button onClick={sendNotification}>通知を送信</Button>;
}

export default TestPush;
