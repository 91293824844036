import React from "react";
import { query, collection, where } from "firebase/firestore";
import { db } from "../api/firebase";
import GroupTable from "./GroupTable";
import { useCollection } from "react-firebase-hooks/firestore";
import EventTable from "./EventTable";

const PendingEvents = () => {
  const q = query(collection(db, "event"), where("publish", "==", false));
  //   承認待ち
  const [pendingCollection] = useCollection(q);
  const pending = pendingCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return (
    <>
      <div className="mb-12">
        <div className="font-bold text-2xl">
          承認待ちイベント{pending?.length > 0 ? ":" + pending?.length : ""}
        </div>
        {pending?.length ? (
          <EventTable pending={pending} />
        ) : (
          <div className="text-gray-400 text-sm pl-5">
            承認待ちイベントはありません
          </div>
        )}
      </div>
    </>
  );
};

export default PendingEvents;
