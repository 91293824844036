import React from "react";
import PendingGroups from "./PendingGroups";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import PendingEvents from "./PendingEvents";
import PendingEventAnswer from "./PendingEventAnswer";

const ApprovalPending = () => {
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  if (!user?.isAdmin) {
    return <div>不正アクセスです。</div>;
  }

  return (
    <>
      <PendingGroups />
      <PendingEvents />
      <PendingEventAnswer />

    </>
  );
};

export default ApprovalPending;
