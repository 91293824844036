// highlightText.js
import React from 'react';

export function highlightText(text, highlight) {
  if (!highlight.trim()) {
    return text;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() 
      ? <span key={index} className="bg-yellow-200">{part}</span> 
      : part
  );
}
