import React, { memo } from "react";
import HeaderContainerMixIN from "./HeaderContainerMixIN";
import DDPContainer from "./DDPContainer";

const Ddp = () => {
  return (
    <>
    {/* <DDPContainer /> */}
      <HeaderContainerMixIN outlet={<DDPContainer />} />
    </>
  );
};

export default memo(Ddp);
