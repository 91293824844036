import React from "react";
import { doc, collection, query, where } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import MyShop from "./MyShop";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import AddComponent from "./AddComponent";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";

const Myshops = ({id}) => {

  const ownerId = id || auth.currentUser.uid;

  const queryPublishShop = query(
    collection(db, "requestShop"),
    where("owner", "==", ownerId),
    where("isPermission", "==", true)
  );
  const [requestShopsCollection] = useCollection(queryPublishShop);
  const requestShops = requestShopsCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const queryNotPublishShop = query(
    collection(db, "requestShop"),
    where("owner", "==", ownerId),
    where("isPermission", "==", false)
  );
  const [requestShopsCol] = useCollection(queryNotPublishShop);
  const requestShopsNotPublish = requestShopsCol?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* お店掲載申請 ↓↓↓↓↓ */}
      <TabContext value={value}>
        <Box
          sx={{
            // maxWidth: { xs: 320, sm: 480 },
            width: "100%",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="許可" value="1" />
            <Tab label="申請中" value="2" />
          </TabList>
          <TabPanel value="1">
            <AddComponent value="お店掲載申請" target="requestShop" />

            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  gap-2  my-1">
              {requestShops?.map((shop, index) => {
                return <MyShop shop={shop} key={index} requestId={shop.id} />;
              })}
            </div>
          </TabPanel>
          <TabPanel value="2">
            <AddComponent value="お店掲載申請" target="requestShop" />
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  gap-2  my-1">
              {requestShopsNotPublish?.map((shop, index) => {
                return <MyShop shop={shop} key={index} requestId={shop.id} />;
              })}
            </div>
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
};
export default Myshops;
