import { Avatar } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { auth, db } from "../api/firebase";

import { format, formatDistance } from "date-fns";
import { ja } from "date-fns/locale";
import SendIcon from "@mui/icons-material/Send";
import ModalForm from "./ModalForm";
import { useNavigate, useParams } from "react-router-dom";
// redux - use
import { useNotification } from "../redux/useNotification";
import DirectMessage from "./DirectMessage";

const DisplayGroupMember = ({ member }) => {


  // user情報取得
  const { id } = useParams();
  // redux - Notification
  const { displayNotification } = useNotification();

  const [user, setUser] = useState("");
  useEffect(() => {
    const getUserInfo = async () => {
      if (!member) return;
      getDoc(doc(db, "users", member.uid)).then((doc) => {
        setUser({ ...doc.data() });
      });
    };
    getUserInfo();
  }, [member]);

  const time = (date) => {
    if (!date) return;
    let timestamp = formatDistance(new Date(), date.toDate(), {
      locale: ja,
    });
    if (timestamp.indexOf("未満") !== -1) {
      return (timestamp = "たった今");
    } else if (
      timestamp.indexOf("か月") !== -1 ||
      timestamp.indexOf("年") !== -1
    ) {
      return (timestamp = format(date.toDate(), "yyyy年M月d日", {
        locale: ja,
      }));
    } else {
      return (timestamp = timestamp + "前");
    }
  };

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
  };

  const sendMessageClick = () => {
    displayNotification({
      message: "只今、制作中です。",
    });
  };

  const navigate = useNavigate();

  const moveToMessanger = (memberId) => {
    navigate("/chat", { state: { memberId } });
  };

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={member?.id}
        target={id}
      />

      <div className="flex flex-col items-center  w-full h-96 border rounded-xl bg-white hover:shadow-lg hover:cursor-pointer duration-500 space-y-4">
        {/* アバター */}
        <div className="mt-12">
          <Avatar src={user?.avatar} sx={{ width: 96, height: 96 }} />
        </div>
        {/* ユーザ名 */}
        <div className="font-bold text-2xl hover:text-green-500 duration-300">
          {user?.lastName + " " + user?.firstName}
        </div>
        {/* 参加日 */}
        <div className=" flex space-x-2  text-gray-300">
          <div className="text-xs">参加</div>
          <div className="text-xs">{time(member?.createdAt)}</div>
        </div>

        {/* 個人チャットを開く */}
        {member?.uid !== auth.currentUser.uid && (
          <DirectMessage memberId={member?.id} />
        )}

        {/* 個人チャットを開く */}
        {/* {member?.id !== auth.currentUser.uid && (
          <div
            className="flex rounded-md border justify-center p-2 hover:shadow-lg duration-300 text-gray-400 hover:text-white hover:bg-green-500 space-x-3"
            // onClick={sendMessageClick}
            onClick={() => moveToMessanger(member?.id)}
          >
            <div>
              <SendIcon />
            </div>
            <div>メッセージを送る</div>
          </div>
        )} */}
        {/* 代表だけが見える申請中 */}
        {member?.id !== auth.currentUser.uid &&
          member?.isApplication === true && (
            <div className="flex rounded-md border justify-center p-2 hover:shadow-lg duration-300 text-gray-400 hover:text-white hover:bg-orange-500 space-x-3">
              <div
                onClick={() => handleEdit("permissionJoinGroup", member?.id)}
              >
                申請を受け入れる
              </div>
            </div>
          )}

        {/* {user?.uid} */}
      </div>
    </>
  );
};

export default DisplayGroupMember;
