import React, { useState, useEffect } from "react";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";

const EditSelfIntroduction = ({ editSelfIntroduction }) => {
  // ユーザ取得関連
  const [user, setUser] = useState("");
  // ユーザ取得とプロフィール選択オプション
  useEffect(() => {
    // ユーザ取得
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
      if (doSnap.exists) {
        setUser(doSnap.data());
      }
    });
  }, []);
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    selfIntroduction: "",
    message: "",
    error: null,
    loading: false,
  });
  const { selfIntroduction, message, error, loading } = data;

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });

    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄か、変更がないようです" });
        // handleEditOff()
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "users", user.uid), {
        [e.target.name]: data[e.target.name],
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      window.location.reload();
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
    }
  };

  return (
    <>
      <div
        className={` ${
          editSelfIntroduction ? " visible" : " invisible"
        } flex justify-center items-center duration-150  `}
      >
        <div className=" fixed  z-30 top-0 bottom-0 my-auto h-96 w-96 shadow-2xl bg-white rounded-md p-4">
        <form onSubmit={(e) => e.preventDefault()}>

            <div>自己紹介</div>
            <textarea
              style={{ resize: "none", fontSize: "16px",background:"white" }}
              type="text"
              name="selfIntroduction"
              className="textarea textarea-bordered w-full h-60 p-1"
              placeholder={user.selfIntroduction}
              defaultValue={user.selfIntroduction}
              onChange={handleChange}
            ></textarea>
            <div className="text-red-500">{message ? message : ""}</div>
            <Button
              className="btn"
              name="selfIntroduction"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "送信しています..." : "送信"}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditSelfIntroduction;
