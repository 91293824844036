import { doc } from "firebase/firestore";
import React, { useState, useEffect, useRef } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import { Avatar } from "@mui/material";
import { memo } from "react";

const MyMessage = memo(({ msg }) => {
  // 日付がInvalidDateかどうか
  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  // const observedDiv = useRef(null);
  // const [width, setWidth] = useState();
  // const [height, setHeight] = useState();

  // const [msgWidth, setMsgWidth] = useState();
  // const [msgHeight, setMsgHeight] = useState();

  // const handleElementResized = () => {
  //   if (observedDiv.current.offsetWidth !== width) {
  //     setWidth(observedDiv.current.offsetWidth);
  //   }
  //   if (observedDiv.current.offsetHeight !== height) {
  //     setHeight(observedDiv.current.offsetHeight);
  //   }
  // };

  // useEffect(() => {
  //   if (width < 640) {
  //     setMsgWidth(width);
  //     setMsgHeight(height);
  //   } else {
  //     setMsgHeight(30 * (Math.floor(width / 600) + 1));
  //     setMsgWidth(640);
  //   }
  // }, [width, height]);

  // const resizeObserver = new ResizeObserver(handleElementResized);

  // useEffect(() => {
  //   resizeObserver.observe(observedDiv.current);
  //   return function cleanup() {
  //     resizeObserver.disconnect();
  //   };
  // }, []);

  return (
    <>
      {/* 自分のメッセージ */}
      <div key={Math.random()}>
        {/* 送信日時 */}
        {msg?.recent ? (
          ""
        ) : (
          <div className="flex justify-center text-gray-300 items-end text-xs">
            {isInvalidDate(new Date(msg.sentAt?.toDate()).toLocaleString()) ? (
              "読み込み中"
            ) : (
              <div>{new Date(msg.sentAt?.toDate()).toLocaleString()}</div>
            )}
          </div>
        )}
        {/* メッセージとavatar */}
        <div className="flex justify-end items-center space-x-1">
          {/* メッセージ内容 */}
          {/* {msg?.id} */}

          <div
            className="border py-1 px-4 rounded-md bg-blue-400 text-white
             break-words whitespace-pre-wrap max-w-sm sm:max-w-xl  xl:max-w-2xl"
          >
            {msg.text}
          </div>
          {/* <div
            // ref={observedDiv}
            className={`border py-1 px-4
            ${ msg?.position === "only"   && "rounded-tl-xl rounded-bl-xl rounded-tr-xl   rounded-br-xl"}
            ${ msg?.position === "top"    && "rounded-tl-xl rounded-bl-xl rounded-tr-xl   rounded-br-none"}
            ${ msg?.position === "middle" && "rounded-tl-xl rounded-bl-xl rounded-tr-none rounded-br-none"}
            ${ msg?.position === "end"    && "rounded-tl-xl rounded-bl-xl rounded-tr-none rounded-br-xl"}
             bg-blue-400 text-white
             break-words whitespace-pre-wrap max-w-sm sm:max-w-xl  xl:max-w-2xl `}
          >
            {msg.text}
          </div> */}
          {/* {msg?.position} */}
          {/* Avatar */}
          {/* <div className=" self-end">
            <Avatar src={user?.avatar} sx={{ width: 24, height: 24 }} />
          </div> */}
        </div>
      </div>
    </>
  );
});

export default MyMessage;
