import imageCompression from "browser-image-compression";

const getCompressImageFile = async (file) => {
  const options = {
    maxSizeMB: 0.1, //最大ファイルサイズ
    maxWidthOrHeight: 600, //最大縦横値
  };
  return await imageCompression(file, options);
};

export default getCompressImageFile;
