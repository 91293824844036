import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../api/firebase";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { Avatar, Button } from "@mui/material";
import ModalForm from "./ModalForm";

const UsersQuestionList = ({ id }) => {
  // 質問リストを取得
  const q = query(
    collection(db, "questionForAdmin"),
    where("questionerId", "==", id),
    orderBy("lastUpdatedAt", "desc")
  );

  const [questionListCollection] = useCollection(q);
  const questions = questionListCollection?.docs?.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
  questions?.sort((a, b) => {
    if (a.lastUpdatedAt > b.lastUpdatedAt) return -1;
    if (a.lastUpdatedAt < b.lastUpdatedAt) return 1;
    return 0;
  });

  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");
  const handleEdit = (e, url) => {
    // console.log("e =>", e);
    // console.log("url =>", url);
    setOpen(true);
    setTarget(url);
    setForm(e);
  };

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} target={target} />
      <div className="text-xl font-bold">お問い合わせ</div>

      <Button variant="contained" onClick={() => handleEdit("inquiry")}>
        お問い合わせ
      </Button>
      <div className="rounded-md  my-2 bg-white p-2">
        {questions?.map((doc) => (
          <div
            key={Math.random()}
            className="flex flex-col space-y-2 rounded-md border p-2 my-2 shadow-sm hover:shadow-lg duration-200 hover:cursor-pointer "
          >
            <div className="flex space-x-2 items-center">
              <div className=" text-blue-500 text-xs">
                {isInvalidDate(new Date(doc?.lastUpdatedAt?.toDate()))
                  ? "読み込み中"
                  : formatDistanceToNow(doc?.lastUpdatedAt?.toDate(), {
                      locale: ja,
                    })}
              </div>
              <div
                className={`${
                  doc.isResolution ? "bg-blue-500 " : "bg-red-500"
                } " rounded-md text-white p-1 text-xs "`}
              >
                {doc.isResolution === false ? "未解決" : "解決"}
              </div>
              <div className=" bg-gray-700 text-white w-fit px-1">
                {doc.questionType}
              </div>
            </div>
            <div className="flex space-x-2 items-center">
              <div className="flex flex-col items-center justify-center">
                <div>
                  <Avatar src={doc.questionerAvatar} sizes="small" />
                </div>
                <div>{doc.questionerName}</div>
              </div>
              <div>{doc.question}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default memo(UsersQuestionList);
