import { doc, getDoc } from "firebase/firestore";
import React, { memo, useEffect, useState } from "react";
import { db } from "../api/firebase";

const GetUserStatus = ({ userId }) => {
  const [targetField, setTargetField] = useState("");
  useEffect(() => {
    const init = async () => {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists) {
        setTargetField(docSnap.data().isVillager);
      }
    };
    userId && init();
  }, [userId]);

  return targetField ? "村民" :"ユーザ";
};

export default memo(GetUserStatus);
