import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { auth } from "../api/firebase";
import ModalForm from "./ModalForm";

const DisplayReplyPostComment = ({ reply }) => {
  //   自分の返信かどうか
  const [isMyReply, setIsMyReply] = useState(false);
  useEffect(() => {
    const init = () => {
      if (reply?.creatorId === auth.currentUser.uid) {
        setIsMyReply(true);
      }
    };
    reply && init();
  }, []);

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  //   console.log("isMyReply =>", isMyReply);
  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} target={reply} />

      <div className="flex ml-10 space-x-2 my-1">

        <div>
          <Avatar src={reply.avatar} sx={{ width: "24px", height: "24px" }} />
        </div>

        {/* 名前と内容 */}

        <div className="flex flex-col justify-start bg-gray-100 p-2 rounded-md">
          <div className="flex   space-x-3">
            <div className="font-bold text-sm">{reply.name}</div>
          </div>
          <div className="w-full text-gray-500 break-words whitespace-pre-wrap  text-sm rounded-md bg-gray-100 ">
            {reply.message}
          </div>
          <div className="flex space-x-2 mt-2">
            {/* 時間 */}
            <div className="h-fit text-blue-500 text-xs">
              -
              {formatDistanceToNow(reply?.updatedAt.toDate(), {
                locale: ja,
              })}
              前
            </div>
            {/* 編集 */}
            {isMyReply && (
              <div
                className="text-xs text-blue-500 hover:font-bold hover:cursor-pointer hover:underline"
                onClick={() => handleEdit("editPostCommentReply")}
              >
                編集
              </div>
            )}
            {/* 削除 */}
            {isMyReply && (
              <div
                className="text-xs text-blue-500 hover:font-bold hover:cursor-pointer hover:underline"
                onClick={() => handleEdit("deleteReply")}
              >
                削除
              </div>
            )}
          </div>
        </div>


      </div>
    </>
  );
};

export default DisplayReplyPostComment;
