import React, {  useState } from "react";
import { db} from "../api/firebase";
import { Button } from "@mui/material";
import { updateDoc, doc } from "firebase/firestore";

const HereIsThisOnMap = ({ setOpen, requestId, target }) => {
  const [loading, setLoading] = useState(false);
  const [latlng, setLatlng] = useState("");

  console.log("target =>",target)
  console.log("requestId =>",requestId)

  const handleSubmit = async () => {
    const [lat, lng] = latlng.split(",");
    console.log("lat =>", lat);
    console.log("lng =>", lng);
    console.log(target);
    console.log(requestId);
    try {
      await updateDoc(doc(db, target, requestId), {
        lat: Number(lat.trim()),
        lng: Number(lng.trim()),
      });
      setOpen(false);
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setLatlng(e.target.value);
  };
  return (
    <>
      <div>経度緯度入力（nnnn..., nnnn....)</div>
      <form onSubmit={(e) => e.preventDefault()}>

        <input
          type="text"
          name="latlng"
          className=" border w-full p-1"
          placeholder="例）35.37798, 139.363016"
          defaultValue=""
          onChange={handleChange}
        ></input>
        <Button className="btn" onClick={handleSubmit} disabled={loading}>
          {loading ? "送信しています..." : "送信"}
        </Button>
      </form>
    </>
  );
};
export default HereIsThisOnMap;
