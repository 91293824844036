import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";

import LiveTvIcon from "@mui/icons-material/LiveTv";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import ForestIcon from "@mui/icons-material/Forest";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PlaceIcon from "@mui/icons-material/Place";
import CampaignIcon from '@mui/icons-material/Campaign';


export default function TimeSchedule() {
  const schedule = [
    {
      time: "2022/10",
      icon: <ShoppingBasketIcon />,
      item: "DDP交換",
      explain:
        "DDPを登録します。どなたかのDDPをルンルを介して交換できます。ルンルは受け取った日から90日で使用できなくなる仕様です。ルンルはエネルギーの尺度だと思っています。毎月5000ルンルを、つまり例えば、毎月5000というエネルギーを使う、3ヶ月で15000というエネルギーを、自分は他者に対してどう使っていくのか。ルンルを媒介として自分が使用したエネルギーが見える、セカイムラ内で使われたルンルの合計は、誰かや自分のDDPが叶った尺度にもなります。ルンルはそのようなものだと思っています。",
      status: "on",
    },
    // チャット
    {
      time: "2023/01",
      icon: <ViewTimelineIcon />,
      item: "メッセンジャー",
      explain:
        "セカイムラのみんなとメッセージやりとりできます。動画や写真のやり取り、URLを載せるとリッチなをコンポーネント表示したり、グループでチャットできたりします。",
      status: "on",
    },

    // Group
    {
      time: "2023/01",
      icon: <GroupsIcon />,
      item: "グループ",
      explain:
        "グループを作成してそのグループに集うメンバーで会話したりできます。グループでのFEEDやイベントの作成は順次アップデートします。",
      status: "on",
    },

    // 通知
    {
      time: "2023/01",
      icon: <NotificationsActiveIcon />,
      item: "通知",
      explain:
        "メンバーサイト内で通知を受け取れます。スマホに通知するのは、専用アプリを作らないといけません。宿題で・・・",
      status: "on",
    },

    // イベント
    {
      time: "2023/03",
      icon: <EventIcon />,
      item: "イベント機能",
      explain:
        "イベントを作成してセカイムラメンバーにアナウンスする機能です。ワークショップやマルシェの情報、事務局からの連絡などが載る予定です。フィードの常に一番上辺りに表示される予定です。",
      status: "on",
    },
    // 拠点
    {
      time: "2023/03",
      icon: <ForestIcon />,
      item: "拠点機能",
      explain:
        "拠点をマップ上に載せたり、拠点の情報を更新したり、イベントを発信したり、各拠点対抗カレー大会を開催したり拠点からのアナウンスを司る機能です。村長さんを決めて、村長さんが発信したりする予定です。拠点に属するメンバー内でのチャットも実装します。",
      status: "on",
    },

    // お知らせ
    {
      time: "2023/04",
      icon: <CampaignIcon />,
      item: "お知らせ",
      explain:
        "セカイムラから皆さんへのお知らせを表示します。",
      status: "on",
    },

    // Map
    {
      time: "2023/05",
      icon: <PlaceIcon />,
      item: "Map",
      explain:
        "村民さんのお店を登録し、GoogleMapに模したマップ上で確認できます。ショップ専用ページがほしい？宿題で・・・",
      status: "on",
    },
    // 動画
    {
      time: "2023/05",
      icon: <LiveTvIcon />,
      item: "動画",
      explain:
        "セカイムラのコアな情報を動画形式で学べるSekaimuraTubeです。満月会などのオンラインZoomの動画などもここでチェックできるように実装する予定です。",
      status: "on",
    },

    // フィード
    {
      time: "2023/06",
      icon: <InsertCommentIcon />,
      item: "フィード",
      explain:
        "皆さんの投稿を見れるフィード画面です。いいね！に付随してルンルを贈れたり等実装できます",
      status: "on",
    },

    // 左フィード
    {
      time: "2023/06",
      icon: "",
      item: "左フィード",
      explain:
        "フィード画面の左にある欄に色々な機能を詰め込む予定です。案としては、シューマン共振再生・アニマルロックなどなどです。",
      status: "soon",
    },
    // 右フィード
    {
      time: "2023/06",
      icon: "",
      item: "右フィード",
      explain:
        "フィード画面の右側にある欄にいろいろな機能を詰め込む予定です。メンバーさんのお店の広告やら、なんでもいけます。",
      status: "soon",
    },
    // DDPマッチング
    {
      time: "2023/06~",
      icon: <ViewTimelineIcon />,
      item: "DDPマッチング",
      explain:
        "DDP登録数が増えてき頃だと思うのです。システムに皆さんのDDPを解析させて、あなたのDDPを叶えるには、メンバーのこの人のこのDDPをルンルで交換するといいです。みたいな通知が定期・不定期でくるような機能です。AIの目まぐるしい発展により、セカイムラのメンバーさんのDDPマッチングがより高精度になりそうです。",
      status: "soon",
    },


  ];
  return (
    <>
      <div className="flex justify-center border rounded-md">
        リリーススケジュール
      </div>

      <Timeline position="alternate">
        {schedule?.map((doc) => (
          <TimelineItem key={Math.random()}>
            {/* 左 */}
            <TimelineOppositeContent
              sx={{ m: "auto 0" }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {doc.time}
            </TimelineOppositeContent>
            {/* 真ん中 */}
            <TimelineSeparator>
              <TimelineConnector />
              {doc.status === "on" && (
                <TimelineDot color="primary">{doc.icon}</TimelineDot>
              )}
              {doc.status === "active" && (
                <TimelineDot color="primary" variant="outlined">
                  {doc.icon}
                </TimelineDot>
              )}
              {doc.status === "soon" && <TimelineDot>{doc.icon}</TimelineDot>}
              <TimelineConnector />
            </TimelineSeparator>
            {/* 右 */}
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                {doc.item}
              </Typography>
              <Typography>{doc.explain}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
}
