import React, { useRef, useEffect } from "react";
import DisplaySignUp from "../components/DisplaySignUp";
import TopMovie from "../data/movie/F17B01AD6AE469C83C492ACEDFA7594836AF951A.mp4";
function Welcome() {
  const videoRef = useRef(null);
  useEffect(() => {
    videoRef.current?.play();
  }, []);

  return (
    <>
      <div className="flex flex-col">
        {/* opening video */}
        <video
          className="VideoBg opacity-80"
          playsInline
          autoPlay
          muted
          loop
          ref={videoRef}
        >
          <source src={TopMovie} type="video/mp4" />
        </video>

        {/* ヘッダー */}
        <div className="flex justify-between">
          {/* ロゴ */}
          <div className=" fixed top-2 left-2 text-2xl  mix-blend-difference items-center z-20 font-bold text-white ">
            セカイムラ
          </div>

          {/* メニュー */}
          <div className="fixed top-2 right-0 sm:right-4 dropdown dropdown-end  z-20">
            <DisplaySignUp />

          </div>
        </div>

        <div className="flex flex-col  justify-center h-screen text-white w-full-[24px] topAccountLogin">
          <div className="text-4xl text-center">あなたの夢は誰かの願い</div>
          {/* <div className="text-4xl text-center">かっこいい言葉</div> */}
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center"> sekaimura member site</div>
        </div>

        {/* <div className="flex flex-col justify-center h-screen text-white w-full-[24px] topAccountLogin">
          <div className="text-4xl text-center text-white">入れられます</div>
          <div className="text-4xl text-center">何行でも行けます</div>
          <div className="text-xl text-center">小さい文字も</div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center">once again.with EARTH and ALL.</div>
        </div> */}

        {/* <div className="flex flex-col bg-back z-10 justify-center h-screen  w-full-[24px] ">
          <div className="text-4xl text-center ">突然空白も入ります</div>
          <div className="text-4xl text-center">ちなみに</div>
          <div className="text-xl text-center">裏でビデオは流れています</div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center">once again.with EARTH and ALL.</div>
        </div> */}

        {/* <div className="flex flex-col justify-center h-screen text-white w-full-[24px] topAccountLogin">
          <div className="text-4xl text-center text-white">ビデオ背景も</div>
          <div className="text-4xl text-center">いつでも復帰します</div>
          <div className="text-xl text-center">いくつも作れます</div>
          <div className=" text-center whitespace-pre"> </div>
          <div className=" text-center">once again.with EARTH and ALL.</div>
        </div> */}

        {/* <div className="flex flex-col justify-center z-10 h-96  w-full-[24px] topAccountLogin "></div> */}

        <div className="flex flex-col  z-10 h-96 bg-black opacity-60 text-white w-full-[24px] ">
          {/* <div>フッター</div> */}
          <div className="flex justify-between space-x-2 mx-3">
            <div className="border w-full max-h-80 overflow-auto">
              ＜DDPの交換＞
              当メンバーサイト(以下当サイト)では、セカイムラ通過「ルンル」を使い、DDPの交換を行います。
              ルンルはセカイムラのエネルギーを交換するシステムです。
              DDPの交換を通じ、メンバー同士が好きなこと、得意なこと、夢、願いを交換し合い、実現することを目的としています。
              まずは、プロフィール、DDPを登録することから始めましょう。
            </div>
            <div className="border w-full max-h-80 overflow-auto">
              ＜各地のコミュニティ＞
              各地域の拠点については、地域メンバーによって徐々に作り上げられていますが
              現時点で全47都道府県全県に拠点があるわけではありません。
              同じ地域のメンバーと交流を始め拠点づくりを目指していきましょう。
              また、近くにメンバーがおられない場合は、zoom交流会へご参加いただき様々な地域のメンバーと交流することから始めましょう。
            </div>
            <div className="border w-full max-h-80 overflow-auto">
              ＜利用規約＞
              当サイトの利用については、利用規約に必ず同意頂き、ご利用ください。（利用規約については、事前の通知なく変更できるものとします。将来変更された場合、利用者が変更後に利用する際には変更が適用されるものとし、利用者は変更に同意したものとみなされます。）
              個人情報について
              個人情報は、セカイムラの活動内容を的確にお知らせするために利用されます。個人情報を他の会社や組織に売買したり、譲渡することはありません。
              個人情報の開示について
              御本人の同意なく、当サイトの運営主体および機密保持契約を結んだ協力企業以外にユーザの個人情報を開示することはありません。ただし、以下の場合に、個人情報を開示することがあります。
              -　裁判所や警察等の公的機関から、法律に基づく正式な照会を受けた場合
              -　当サイトと提携関係にある、またはなりうる事業者と共同でサービス・広告を提供するために必要であると判断した場合
              -　当サイトの運営主体が、その他の合法的な目的のために、合理的理由に基づき開示することが適切であると判断した場合
              著作権について
              当サイトのサービスを利用し、情報の入力・画像のアップロード等を行うユーザは、自らが登録した情報や画像について、著作権を保有するものとします。※画像や文章等については、登録者が著作権や使用権などの必要な知的所有権を有する画像を使用することを前提とします
              当サイトのサービスを利用し、情報の入力・画像のアップロード等によって作成・登録された情報や画像は、当サイト及び当サイトと提携するサイトにおいて、著作権者の表示など当サイトが必要と判断する処置を行った上で、利用できるものとします。
              メールについて
              当サイトの判断に基づいて、当サイトの運営上の告知や、または機能の一環として、利用者の登録アドレスに対して、メールを送信いたします。
              禁止行為について
              ユーザーは、本サービスの利用について、以下の行為を禁止します。禁止行為を行った場合は、事前の告知なく該当箇所の削除やサービス利用を停止する場合があります。その場合、削除結果及び利用停止措置に関する質問・苦情は一切受け付けておりません。
              -　ユーザーが、当サイトの提供するサービス、サービスの利用（使用）、サービスへのアクセスについて、その全部あるいは一部を問わず、商業目的で利用（使用、再生、複製、複写、販売、再販売など形態のいかんを問いません）することを禁止いたします。
              -　ユーザーは、当サイトを通じて入手したいかなる情報も複製、販売、出版、公開その他いかなる方法においてもユーザー個人としての私的使用以外の使用をすることはできず、また他のメンバーまたは第三者をして同様の行為をさせてはなりません。
              -　他のユーザーの個人情報を収集したり蓄積すること、またはこれらの行為をしようとすることを禁止します。
              -　虚偽の会員情報（氏名、居住地、メールアドレスなどの個人情報）を登録したり、他人の名前その他の情報を不正利用する行為。また、登録者に許可を受けた場合であっても、その登録者の登録した会員情報（メールアドレスやパスワードなど）を利用して、当サイトを利用すること。
              -　ユーザーは、当サイトを通じて医薬品、たばこ、偽ブランド品や無断複製したCDやDVDなど権利を侵害する商品、危険ドラッグ等の法令により販売などが禁止されているもの、法定通貨の価値として100万円を超えるもの、その他、日本国の法令により売買が禁止されているものについて交換することを禁止します。
              -　虚偽又は誤解を招くような内容を含む情報等を、掲載等し又は登録する行為
              -　他人の産業財産権（特許権、商標権等）、著作権、企業秘密等の知的財産権を侵害する行為
              -　他人の信用若しくは名誉を侵害し、又は他人のプライバシー権、肖像権その他一切の権利を侵害する行為
              -　犯罪行為をすること又はこれに関与する行為
              -　本サービスの運営・提供又は他の利用者による本サービスの利用を妨害し、又はそれらに支障をきたす行為
              -　宣伝・告知・勧誘等を目的とする日記・コミュニティ・メール機能等を利用した同一趣旨の複数の発信や掲載（マルチポスト、スパムメール、チェーンメール等を含む）、及び、参加者の趣旨と異なると思われるコミュニティ等への掲載
              -　メール機能やリンク機能、掲示板機能等による無差別送信や掲載、アクセス履歴機能を利用したアクセス誘導等を目的とする無差別かつ大量の履歴の掲載
              -　通常利用の範囲を超えてサーバーに負担をかける行為、及びそれを助長するような行為
              -　法令又は公序良俗に違反する行為
              -　モザイク・下着姿を含む露出度の高いヌード、その他卑猥と判断される画像・イラスト・絵画の掲載、及びその他猥褻的な表現の掲載
              -　暴力的、グロテスクな写真、及びその他一般ユーザーが不快に感じる画像や表現の掲載
              -　１人で、複数の会員登録を行う行為
              -　一つのアカウントを複数人で利用する行為
              -　招待状を第三者へ譲渡する行為、またアカウントを第三者に利用させたり譲渡する行為
              -　その他、当サイトが、合理的な理由に基づき不適切と判断する行為
              ユーザの負担・責任について
              ユーザーは、本サービスの利用について、以下の負担と責任を負うものとします。
              -　ユーザーがサービス中に送信（発信）したコンテンツ、ユーザーによるサービスの利用、ユーザーのサービスへの接続、ユーザーの本規約違反もしくはユーザーによる第三者の権利侵害に起因または関連して生じたすべてのクレームや請求については、ユーザーの費用と責任で解決するものとします。
              -　当該クレームや請求への対応に関連して当サイト運営者に費用が発生した場合または賠償金等の支払いを行った場合については、ユーザーは当該費用および賠償金等（当サイト運営者が支払った弁護士費用を含みます）を負担するものとします。
              免責事項について
              ユーザーは、以下の当サイトへの免責事項を了解の上、利用することとします。
              -　当サイト運営者が必要と判断した場合には、ユーザーのみなさまに通知することなくいつでもサービスの内容を変更、停止または中止することができるものとします。当サイト運営者がサービスの内容を変更、停止または中止した場合にも、ユーザーに対しては一切責任を負わないものとします。
              -　ユーザーは、(1)サービスを利用したこと、または利用ができなかったこと、(2)サービスを通じて取り引きを行ったり情報を入手した商品を交換しもしくは役務を代替させるために費用を要したこと、(3)ユーザーの送信（発信）やデータへの不正アクセスや不正な改変がなされたこと、(4)サービス中の第三者による発言、送信（発信）や行為、(5)その他サービスに関連する事項、に起因または関連して生じた一切の損害について、当サイト運営者が賠償責任を負わないことに同意します。
              情報発信の管理について
              ユーザーは、以下の当サイトへの内容を了解の上、利用することとします。
              -　自己の作成したグループ／コミュニティへの書き込みについて、会員は第三者（以下、会員及び掲示板等への書き込みをした方をあわせて「利用者」といいます。）の書き込みに対してもその管理義務を負うものとし、また、当該書き込みについては、会員が表示した情報と同様の適用を受けます。
              -　グループ／コミュニティ内で紛争が起こった場合は、当該ユーザーは自己の責任で解決するものとし、当社または第三者が損害を被った場合は、当該ユーザーはこれを賠償するものとします。
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;

// 結構ハマったフルスクリーン
// https://stackoverflow.com/questions/66026657/how-can-i-make-my-background-video-full-screen-in-react

// SPAでfirebase hostingした場合、
// signInWithRedirectを使用すると
// ルートが壊されて,signInWithRedirectを叩いたところに戻ってきても
// page not foundが出る
// firebase.jsonをいじって、hosting{[rewrite：]}設定をして
// 404を回避する
// https://firebase.google.com/docs/hosting/full-config?hl=ja#glob_pattern_matching
