
const getDealHistory = (user, deals, usersList) => {
  const arr = [];
  let now = new Date();

  // console.log("deals =>",deals)
  // 自分が買ったデータ取得
  const pushTo = (arr, user, deal) => {
    arr.push({
      amount: Number(deal.amount),
      createdAt: deal.createdAt,
      cause: deal.cause,
      productName: deal.productName,
      productOffering: deal.productOffering,
      productTarget: deal.productTarget,
      delivery: deal.delivery,
      status: deal.status,
      buy: usersList[deal.buy]?.lastName + usersList[deal.buy]?.firstName,
      buyer: usersList[deal.buyer]?.lastName + usersList[deal.buyer]?.firstName,
      id:deal.id,
    });
  };

  // 自分が売ったデータ取得
  const pushFrom = (arr, user, deal) => {
    arr.push({
      amount: Number(deal.amount),
      createdAt: deal.createdAt,
      cause: deal.cause,
      productName: deal.productName,
      productOffering: deal.productOffering,
      productTarget: deal.productTarget,
      delivery: deal.delivery,
      status: deal.status,
      buy: usersList[deal.buy]?.lastName + usersList[deal.buy]?.firstName,
      buyer: usersList[deal.buyer]?.lastName + usersList[deal.buyer]?.firstName,
      id:deal.id,
    });
  };

  // 「何日前のデータか」を出力
  //   const DaysBetweenDate = (date) => {
  //     const then = date?.toDate();
  //     const msBetweenDates = Math.abs(then?.getTime() - now?.getTime());
  //     const hoursBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
  //     return hoursBetweenDates;
  //   };

  deals?.forEach((deal) => {
    if (deal.buy === user.uid) {
      pushTo(arr, user, deal);
    }
    if (deal.buyer === user.uid) {
      pushFrom(arr, user, deal);
    }
  });

  // ソート
  arr.sort((a, b) => {
    if (a.createdAt < b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    return 0;
  });

  return arr;
};

export default getDealHistory;
