import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import DisplayGroup from "./DisplayGroup";
import TablePagination from "@mui/material/TablePagination";
import { memo } from "react";
import LoaderName from "./LoaderName";
import { useAuth } from "../context/auth";
import { db, auth } from "../api/firebase";
import { useParams } from "react-router-dom";

const DisplayAllGroups = memo(({ groups }) => {
  const { search } = useParams();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [group, setGroup] = useState([]);
  const [originalProduct, setOriginalProduct] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    setGroup(groups);
    setOriginalProduct(groups);
    setDataLoading(false);
  }, [dataLoading, groups]);

  useEffect(() => {
    if (search) {
      // console.log("originalProduct =>", originalProduct);
      const filteredRows = originalProduct.filter((row) => {
        return (row.title + row.explain + row.type).includes(search);
      });
      setGroup(filteredRows);
      // window.location.reload()
    } else {
      setGroup(originalProduct);
    }
  }, [search]);

  return (
    <>
      {!search && (
        <div className="text-gray-400">グループ数：{groups?.length}</div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 lg:gap-5  my-1">
        {groups?.length === 0 && <div>まだグループはありません</div>}
        {!dataLoading === 0 && <LoaderName />}

        {(rowsPerPage > 0
          ? group?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : group
        )?.map((group, index) => {
          return (
            // <MyProduct product={product} key={index} id={product.id} />
            <DisplayGroup
              groupId={group?.id}
              key={Math.random()}
              groups={group}
            />
          );
        })}
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={groups?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        showFirstButton
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
});

export default DisplayAllGroups;
