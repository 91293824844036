import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getDoc, doc } from "firebase/firestore";
import { db, auth } from "../api/firebase";

// materialUI
import Loader1Row from "./Loader1Row";
import Upload from "./Upload";
import { Container } from "@mui/material";
import RequestShopImage from "./RequestShopImage";
import SwitchComponent from "./SwitchComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import Images from "./Images";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ModalForm from "./ModalForm";
import RadioGroupsComponent from "./RadioGroupsComponent";
import GetUserInfo from "./GetUserInfo";

const MyRequestShop = () => {
  const { id } = useParams();
  const [shop, setShop] = useState("");
  const [user, setUser] = useState("");

  const [shopType, setShopType] = useState("");

  useEffect(() => {
    // ショップ情報取得

    const getShopInfo = async () => {
      // requestShopの場合
      getDoc(doc(db, "requestShop", id)).then((doSnap) => {
        if (doSnap.exists()) {
          setShop(doSnap.data());
          setShopType("requestShop");
        }
      });
      // recommendedSpotの場合
      getDoc(doc(db, "recommendedSpot", id)).then((doSnap) => {
        if (doSnap.exists()) {
          setShop(doSnap.data());
          setShopType("recommendedSpot");
        }
      });
    };

    getShopInfo();
    // ユーザ情報取得
    const getUserInfo = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doSnap) => {
        if (doSnap.exists) {
          setUser(doSnap.data());
        }
      });
    };
    getUserInfo();
  }, []);

  const [isOwner, setIsOwner] = useState(false);

  // useEffect(() => {
  //   if (shop?.owner === auth.currentUser.uid) {
  //     setIsOwner(true);
  //   }
  // }, []);


  useEffect(() => {
    if (shop?.owner === auth.currentUser?.uid) { // auth.currentUserがnullでないことを保証
      setIsOwner(true);
    } else {
      setIsOwner(false); // オプショナル: shopが変更されたが、currentUserがownerでない場合は明示的にfalseをセット
    }
  }, [shop]); // shopが更新されるたびにこのEffectを再評価


  // console.log("shop.owner =>",shop.owner)
  // console.log("auth.currentUser.uid =>",auth.currentUser.uid)
  // console.log("isOwner =>",isOwner)

  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();

  // フォーム関連
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e) => {
    setOpen(true);
    setForm(e);
  };

  // console.log("shop?.type =>",shop?.type)
  const [target, setTarget] = useState("");
  useEffect(() => {
    const init = async () => {
      if (shop?.type === "userShop") {
        setTarget("requestShop");
      }
      if (shop?.type === "korakuen") {
        setTarget("requestShop");
      }
      if (shop?.type === "lendField") {
        setTarget("recommendedSpot");
      }
      if (shop?.type === "lendOldHouse") {
        setTarget("recommendedSpot");
      }
      if (shop?.type === "naturalFood") {
        setTarget("recommendedSpot");
      }
      if (shop?.type === "powerSpot") {
        setTarget("recommendedSpot");
      }
      if (shop?.type === "alternativeMedicine") {
        setTarget("recommendedSpot");
      }
    };
    shop && init();
  }, [shop]);

  const navigate = useNavigate();

  const handleMoveConfirmLatLng = () => {
    navigate("/confirmLngLat");
  };

  const shopIndust = [
    { value: "restaurant", label: "レストラン" },
    { value: "coffeeShop", label: "喫茶店" },
    { value: "hotel", label: "ホテル" },
    { value: "generalShop", label: "雑貨店" },
    { value: "it", label: "IT" },
  ];

  // console.log("shop =>",shop)

  // console.log("shop ->",shop)

  if (!user) return <Loader1Row />;
  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        target={target}
        requestId={id}
      />

      <div className=" max-w-[1000px] ">
        <div className="relative">
          <div className="font-bold text-2xl whitespace-pre-wrap break-words line-clamp-2 absolute z-10 rounded-md top-3 left-3 w-fit bg-teal-800 opacity-75  text- flex p-2  hover:cursor-pointer">
            <div className="text-white">{shop?.name}</div>
          </div>
          <div>
            {shopType && <RequestShopImage id={id} collection={shopType} />}
          </div>
          <TabContext value={value}>
            <div className="sticy top-0"></div>
            <div className="grid grid-cols-1">
              <div className=" col-span-1">
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab label="概要" value="1" />
                  <Tab label="写真" value="2" />
                  {(user?.isAdmin || isOwner) && <Tab label="設定" value="5" />}
                </TabList>
              </div>
            </div>

            {/* 概要 */}
            <TabPanel sx={{ width: "100%" }} value="1">
              <div className=" font-bold text-3xl">概要</div>

              <div className=" flex flex-col rounded-md bg-white border hover:border-blue-500  duration-150 my-2 p-2 hover:shadow-2xl shadow-md ">
                {shopType !== "requestShop" ? (
                  <div className="font-bold text-xl">おすすめポイント</div>
                ) : (
                  <div className="font-bold text-xl">ひとこと</div>
                )}
                {shopType !== "requestShop" && (
                  <>
                    <div className="flex flex-col space-y-1">
                      <div className="flex space-x-1 ">
                        {/* アバターと「推薦者」 */}
                        <div className="flex flex-col space-y-1 items-center">
                          <div>
                            <GetUserInfo
                              uid={shop?.creator}
                              target="avatar"
                              size={30}
                            />
                          </div>
                          <div className="text-xs text-gray-500">推薦者</div>
                        </div>
                        {/* 名前とメッセージ */}
                        <div className="flex flex-col space-y-1">
                          <div className="font-bold">
                            <GetUserInfo uid={shop?.creator} target="name" />
                          </div>
                          <div className="bg-gray-200 p-3 rounded-md w-fit">
                            {shop?.recomendedPoint}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {shopType === "requestShop" && (
                  <>
                    <div className="flex flex-col space-y-1">
                      <div className="flex space-x-1 ">
                        {/* アバターと「推薦者」 */}
                        <div className="flex flex-col space-y-1 items-center">
                          <div>
                            <GetUserInfo
                              uid={shop?.owner}
                              target="avatar"
                              size={30}
                            />
                          </div>
                          <div className="text-xs text-gray-500">オーナー</div>
                        </div>
                        {/* 名前とメッセージ */}
                        <div className="flex flex-col space-y-1">
                          <div className="font-bold">
                            <GetUserInfo uid={shop?.owner} target="name" />
                          </div>
                          <div className="bg-gray-200 p-3 rounded-md w-fit">
                            {shop?.onething}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* {shopType === "requestShop" && (
                  <>
                    <div className="flex flex-col space-y-1">
                      <div className="flex space-x-1 items-center">
                        <div>
                          <GetUserInfo
                            uid={shop?.owner}
                            target="avatar"
                            size={30}
                          />
                        </div>
                        <div className="font-bold">
                          <GetUserInfo uid={shop?.owner} target="name" />
                        </div>
                      </div>
                      <div className="bg-gray-200 p-3 rounded-md w-fit">
                        {shop?.onething}
                      </div>
                    </div>
                  </>
                )} */}
              </div>
            </TabPanel>

            {/* 写真 */}
            <TabPanel sx={{ width: "100%" }} value="2">
              <div className=" font-bold text-3xl">写真</div>
              <Container>
                {(user?.isAdmin || isOwner) && (
                  <Upload targetCollection={shopType} />
                )}
                {shop && <Images targetCollection={shopType} shopId={id} />}
                {/* <ImagesList targetCollection="requestShop" /> */}
              </Container>
            </TabPanel>

            {/* 設定 */}
            <TabPanel sx={{ width: "100%" }} value="5">
              <div className=" font-bold text-3xl">設定</div>

              {/* 管理者のみ見える */}
              <div
                className={`${
                  user.isAdmin ? "block" : "hidden"
                } " flex flex-col rounded-md bg-white border hover:border-blue-500 hover:cursor-pointer w-fit duration-150 my-2 px-4 py-2 hover:shadow-2xl shadow-md "`}
              >
                <div className="font-bold text-xl ">管理者メニュー</div>
                {/* 許可・公表 */}
                <div className="">
                  {/* 表示 */}
                  <div className="flex items-center space-x-4">
                    <div>表示</div>
                    <div>
                      <SwitchComponent
                        id={id}
                        target="isPublish"
                        collection={shopType}
                        // text="表示"
                      />
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div>許可</div>
                    {user?.isAdmin && (
                      <div>
                        <SwitchComponent
                          id={id}
                          target="isPermission"
                          collection={shopType}
                          // text="許可"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ロケーション情報 */}
              <div className=" flex flex-col rounded-md bg-white border hover:border-blue-500  duration-150 my-2 p-2 hover:shadow-2xl shadow-md ">
                <div className="font-bold text-xl">ロケーション情報</div>
                {/* 住所と経度緯度 */}
                <div className="flex items-center space-x-2">
                  <div className="flex flex-col border rounded-md hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md my-2 p-5">
                    {/* 住所 */}
                    <div className="flex space-x-2 my-2 items-center">
                      <div className="w-fit">住所</div>
                      <div className="border rounded-md py-2 px-4">
                        {shop.address}
                      </div>
                    </div>
                    {/* 緯度 */}
                    <div className="flex space-x-2 my-2 items-center">
                      <div className="w-fit">緯度(lat)</div>
                      <div className="border rounded-md py-2 px-4">
                        {shop.lat}
                      </div>
                      <div
                        className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                        onClick={() => handleEdit("editLat")}
                      >
                        <ModeEditIcon />
                      </div>
                    </div>
                    {/* 緯度 */}
                    <div className="flex space-x-2 my-2 items-center">
                      <div className="w-fit">経度(lng)</div>
                      <div className="border rounded-md py-2 px-4">
                        {shop.lng}
                      </div>
                      <div
                        className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                        onClick={() => handleEdit("editLng")}
                      >
                        <ModeEditIcon />
                      </div>
                    </div>
                    <div
                      className="flex justify-end text-blue-500 text-xs cursor-pointer"
                      // onClick={handleMoveConfirmLatLng}
                      onClick={() => window.open("/confirmLngLat/")}
                    >
                      経度と緯度の確認方法はこちら
                    </div>
                  </div>
                  {/* 住所と経度と緯度更新 */}
                  <div
                    className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                    onClick={() => handleEdit("editAddressAndLatLng")}
                  >
                    <ModeEditIcon />
                  </div>
                </div>

                {/* 電話 */}
                <div className="flex space-x-2 my-2 mx-5 items-center">
                  <div className="w-fit">電話</div>
                  <div className="border rounded-md py-2 px-4">{shop?.tel}</div>
                  <div
                    className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                    onClick={() => handleEdit("editTel")}
                  >
                    <ModeEditIcon />
                  </div>
                </div>
                {/* URL */}
                <div className="flex space-x-2 my-2 mx-5 items-center">
                  <div className="w-fit">URL</div>
                  <div className="border rounded-md py-2 px-4">{shop?.url}</div>
                  <div
                    className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                    onClick={() => handleEdit("editURL")}
                  >
                    <ModeEditIcon />
                  </div>
                </div>
              </div>

              {/* 紹介ポイント */}
              {shopType !== "requestShop" && (
                <div className="flex flex-col border rounded-md hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md my-2 bg-white p-5">
                  <div className="font-bold text-xl ">紹介ポイント</div>
                  <div className="flex items-center space-x-4">
                    <div>表示</div>
                    <div>
                      <SwitchComponent
                        id={id}
                        target="isPublishRecomendedPoint"
                        collection={shopType}
                        // text="表示"
                      />
                    </div>
                  </div>
                  <div className="flex space-x-2 my-2 items-center">
                    <div className="w-fit">紹介ポイント</div>
                    <div className="border rounded-md py-2 px-4">
                      {shop.recomendedPoint}
                    </div>
                    <div
                      className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                      onClick={() => handleEdit("editRecomendedPoint")}
                    >
                      <ModeEditIcon />
                    </div>
                  </div>
                </div>
              )}

              {/* 村民さんのお店の時、ひとことを追加できる。 */}
              {shopType === "requestShop" && (
                <div className="flex flex-col border rounded-md hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md my-2 bg-white p-5">
                  <div className="font-bold text-xl ">ひとこと</div>
                  <div className="flex items-center space-x-4">
                    <div>表示</div>
                    <div>
                      <SwitchComponent
                        id={id}
                        target="isPublishOnething"
                        collection={shopType}
                        // text="表示"
                      />
                    </div>
                  </div>
                  <div className="flex space-x-2 my-2 items-center">
                    <div className="w-fit">ひとこと</div>
                    <div className="border rounded-md py-2 px-4">
                      {shop.onething}
                    </div>
                    <div
                      className="rounded-full h-fit p-1 border hover:cursor-pointer hover:border-blue-500 shadow-md "
                      onClick={() => handleEdit("editOnething")}
                    >
                      <ModeEditIcon />
                    </div>
                  </div>
                </div>
              )}

              {/* 村民さんのお店の時、ショップの種類を選べる */}
              {shopType === "requestShop" && (
                <div className="flex flex-col border rounded-md hover:border-blue-500 hover:shadow-2xl duration-150 shadow-md my-2 bg-white p-5">
                  <RadioGroupsComponent
                    title="お店の種類"
                    array={shopIndust}
                    collection={shopType}
                    id={id}
                    documentName="shopIndust"
                  />
                  <div className="text-xs text-red-500">
                    ※ここにないお店の種類を登録したい場合は事務局までご連絡ください
                  </div>
                </div>
              )}
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </>
  );
};

export default MyRequestShop;
