import React, { useEffect, useState } from "react";
// import GoogleMapReact from "google-map-react";
import { GoogleMap,Marker,withScriptjs,withGoogleMap } from "react-google-maps";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import DisplayListOnMap from "./DisplayListOnMap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../api/firebase";

const SekaiMap = ({ apiKey }) => {
  const [center, setCenter] = useState({ lat: 35.6803997, lng: 139.7690174 });

  const options = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    fullscreenControl: false,
    disableDefaultUI: true,
    gestureHandling: "greedy",
  };

  const [shops, setShops] = useState([]);
  useEffect(() => {
    const initShops = () => {
      let arr = [];
      getDocs(collection(db, "requestShop")).then((shops) => {
        shops.forEach((shop) => {
          arr.push({ id: shop.id, ...shop.data() });
        });
        setShops(arr);
      });
    };
    initShops();
  }, []);
  // console.log("shops =>", shops);

  // マーカーコンポーネント
  const Marker = ({ text }) => (
    <div style={{ color: "red", fontWeight: "bold" }}>{text}</div>
  );

  const [selectedMarker, setSelectedMarker] = useState(null); // 選択されたマーカーのステート

  // マーカーがクリックされたときのハンドラ
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  // インフォウィンドウが閉じられたときのハンドラ
  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  if (!shops) return;
  return (
    <>
      {/* <div style={{ height: "80vh", width: "100%" }} > */}
      <div className="w-full h-[78vh]">
        {/* <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input {...getInputProps({ placeholder: "Search Places" })} />
            <div>
              {loading ? <div>Loading...</div> : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={suggestion.placeId}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete> */}
        <DisplayListOnMap />
        <GoogleMap
          bootstrapURLKeys={{ key: apiKey, libraries: ["places"] }}
          center={center}
          zoom={14}
          options={options}
        >
          <Marker
            lat={35.6895}
            lng={139.6917}
            text="東京駅"
            onClick={() =>
              handleMarkerClick({ lat: 35.6895, lng: 139.6917, name: "東京駅" })
            }
          />
          {/* 選択されたマーカーの情報を表示 */}
          {selectedMarker && (
            <div
              style={{
                position: "absolute",
                top: selectedMarker.lat,
                left: selectedMarker.lng,
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <h3>{selectedMarker.name}</h3>
              <p>緯度: {selectedMarker.lat}</p>
              <p>経度: {selectedMarker.lng}</p>
              <button onClick={handleInfoWindowClose}>閉じる</button>
            </div>
          )}

          {shops?.map((shop) => (
            <Marker
              key={shop.id}
              text="hoge"
              lat={35.38431741650718}
              lng={139.36524992435614}
              icon
              // position={{
              //   lat: 35.38431741650718,
              //   lng: 139.36524992435614,
              // }}
              onClick={() => alert(shop.address)}
            ></Marker>
          ))}
        </GoogleMap>
      </div>
    </>
  );
};

export default SekaiMap;
