import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import image1 from "../data/img/confirmLatLng1.png";
import image2 from "../data/img/confirmLatLng2.png";

const ConfirmLngLat = () => {
  const navigate = useNavigate();
  const handleMoveHome = () => {
    navigate("/home");
  };

  return (
    <>
      <div className="m-5">
        <Button onClick={handleMoveHome} variant="contained">
          ホームへ戻る
        </Button>
        <div className="font-bold text-3xl my-3 sticky top-0 bg-white ">経度と緯度の確認方法</div>
        <div className="grid grid-cols-1 gap-4 max-w-xl">
          {/* 手順１ */}
          <div className="flex flex-col space-y-3 border rounded-md hover:border-blue-500 cursor-pointer shadow-md hover:shadow-2xl p-5">
            <div className="font-bold  text-xl">手順①</div>
            <div>
              <img src={image1} alt="" />
            </div>
            <div>GoogleMapで経度・緯度を知りたいポイントを右クリックする</div>
          </div>
          {/* 手順２ */}
          <div className="flex flex-col space-y-3 border rounded-md hover:border-blue-500 cursor-pointer shadow-md hover:shadow-2xl p-5">
            <div className="font-bold  text-xl">手順②</div>
            <div className="border">
              <img src={image2} alt="" className="border" />
            </div>
            <div>一番上の数字をクリックするとコピーされます。</div>
            <div>[ 緯度(lat), 経度(lng) ]という風に並んでいます</div>
          </div>

          <div className="border rounded-md p-5"></div>
        </div>
      </div>
    </>
  );
};

export default ConfirmLngLat;
