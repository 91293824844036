import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  getDoc,
  doc,
  query,
  updateDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Navigate } from "react-router-dom";

// materialUI

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Upload from "./Upload";
import { Container } from "@mui/material";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Rate from "./Rate";
import DisplayProductSmartPhone from "./DisplayProductSmartPhone";
import DisplayProductPC from "./DisplayProductPC";
import { useAuth } from "../context/auth";

import HeaderContainerMixIN from "./HeaderContainerMixIN";

const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();

  // プロダクト情報取得
  const queryProduct = query(doc(db, "products", id));
  const [product] = useDocumentData(queryProduct);

  //   権限変更関連handle
  const [dataloading, setDataLoading] = useState(true);
  const [userInfoLoading, setUserInfoLoading] = useState(false);

  const publishChange = async (id, type) => {
    const pub = await getDoc(doc(db, "products", id));
    await updateDoc(doc(db, "products", id), {
      [type]: !pub.data()[type],
    });
    setDataLoading(true);
    setUserInfoLoading(false);
  };
  const handleChange = async (e) => {
    setUserInfoLoading(true);
    await publishChange(e.target.value, e.target.name);
  };

  if (!product) {
    <Navigate to="/home" />;
  }

  return (
    <>
      {/* 管理者のみ表示 */}
      {/* <div
        className={`${
          user?.isAdmin ? "block" : "hidden"
        } " flex flex-col rounded-md bg-white border hover:shadow-2xl hover:cursor-pointer"`}
      >
        <div>管理者のみ表示</div>
        <div>shop_uid:{product?.uid}</div>
        <div>auth_uid:{user?.uid}</div>
      </div> */}
      {/* 提供者のみ表示 */}
      <div
        className={`${
          product?.uid === user?.uid ? "block" : "hidden"
        } bg-white border rounded-md `}
      >
        <div>提供者のみ表示</div>
        {/* 掲載・非掲載スイッチ */}
        <FormControlLabel
          control={
            <Switch
              checked={product?.isPublish ? product?.isPublish : false}
              onChange={handleChange}
              name="isPublish"
              disabled={userInfoLoading}
              value={id ? id : false}
            />
          }
          label={product?.isPublish ? "掲載" : "非掲載"}
        />
      </div>
      {/* PC表示 */}
      <div className="hidden sm:block xl:mx-20">
        <HeaderContainerMixIN outlet={<DisplayProductPC />} />

        {/* {user ? (
          <HeaderContainerMixIN outlet={<DisplayProductPC />} />
        ) : (
          <NoLoginHeader outlet={<DisplayProductPC />} />
        )} */}
        {/* <DisplayProductPC /> */}
      </div>
      {/* スマホ表示 */}
      <div className="sm:hidden">
        <HeaderContainerMixIN outlet={<DisplayProductSmartPhone />} />

        {/* {user ? (
          <HeaderContainerMixIN outlet={<DisplayProductSmartPhone/>} />
        ) : (
          <NoLoginHeader outlet={<DisplayProductSmartPhone/>} />
        )} */}
      </div>

      {/* <DisplayProductSmartPhone /> */}

      {/* 写真 */}
      {user && product?.uid === user?.uid ? (
        <Container sx={{ textAlign: "center" }}>
          <Upload targetCollection="products" />
          {/* <ImagesList targetCollection="products" /> */}
        </Container>
      ) : (
        ""
      )}
    </>
  );
};

export default Product;
