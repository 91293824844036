import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import { db } from "../api/firebase";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import { Typography, useMediaQuery } from "@mui/material";
import { CircularProgress } from "@mui/material";

const UserPictures = () => {
  const { id } = useParams();
  const [userImages, setUserImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [remainingImages, setRemainingImages] = useState([]);
  const loaderRef = useRef(null);

  // 最初にデータを取得する関数
  const fetchInitialUserImages = async () => {
    try {
      const q = query(
        collection(db, "postImage"),
        where("creator", "==", id),
        where("isPublish","==",true),
        orderBy("updatedAt", "desc"),
        limit(8)
      );

      const snapshot = await getDocs(q);
      const images = snapshot.docs.map((doc) => doc.data());
      setUserImages(images);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user images:", error);
    }
  };

  // さらにデータを取得する関数
  const fetchMoreUserImages = async () => {
    try {
      const q = query(
        collection(db, "postImage"),
        where("creator", "==", id),
        where("isPublish","==",true),


        orderBy("updatedAt", "desc"),
        startAfter(userImages[userImages.length - 1]?.updatedAt),
        limit(8)
      );

      const snapshot = await getDocs(q);
      const images = snapshot.docs.map((doc) => doc.data());
      setRemainingImages(images);

      if (images.length > 0) {
        setShowLoadingMessage(true);

        setTimeout(() => {
          setUserImages((prevImages) => [...prevImages, ...images]);
          setRemainingImages([]); // 残りのデータを表示したら残りのデータを空にする
          setShowLoadingMessage(false); // Loadingメッセージを非表示にする
        }, 2000); // 2秒後にデータを表示
      }
    } catch (error) {
      console.error("Error fetching more user images:", error);
    }
  };

  // 初回データの取得
  useEffect(() => {
    fetchInitialUserImages();
  }, [id]);

  // さらにデータの取得
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !loading && remainingImages.length === 0) {
      fetchMoreUserImages();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef, loading, remainingImages]);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  }

  const isMobile = useMediaQuery("(min-width:769px)");

  return (
    <div>
      <ImageList cols={isMobile ? 3 : 1} gap={8}>
        {userImages?.map((item) => (
          <ImageListItem key={item.img}>
            <div className="relative">
              <img
                src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                alt=""
                loading="lazy"
              />
              <div className="absolute bottom-2 left-2 p-1 rounded-md bg-black opacity-60 text-white text-xs">
                {new Date(item.createdAt?.toDate()).toLocaleDateString('ja-JP', options)}
              </div>
            </div>
          </ImageListItem>
        ))}
      </ImageList>

      <div ref={loaderRef} className="p-4">
        {showLoadingMessage ? (
          <>
            <div className="flex space-x-3 items-center">
              <CircularProgress />
              <p>データ読み込み中...</p>
            </div>
          </>
        ) : (
          <div style={{ height: "1px" }}></div>
        )}
      </div>
    </div>
  );
};

export default UserPictures;
