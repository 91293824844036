import React, { useState, useEffect, memo } from "react";
import { updateDoc, doc ,onSnapshot} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import Badge from "@mui/material/Badge";
import { Divider, Tooltip } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";

// mui -  Menu関連
import Menu from "@mui/material/Menu";

// mui badth関連
import { styled } from "@mui/material/styles";

// redux - use
import { useNotification } from "../redux/useNotification";
import { useLocation, useNavigate } from "react-router-dom";
import { Chat, Channel, ChannelList } from "stream-chat-react";

// chat関連
import MessagingChannelListInNotification from "./MessagingChannelList/MessagingChannelListInNotification";
import MessagingChannelPreviewInNotification from "./MessagingChannelPreview/MessagingChannelPreviewInNotification";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DirectMessageChatInNotification from "../components/DirectMessageChatInNotificaton";
import GetUserInfo from "./GetUserInfo";
import { useStreamChatClient } from "../context/StreamChatContext";

import { Streami18n } from "stream-chat-react";
const streami18n = new Streami18n({
  language: "ja",
});

const NotificationsInMessage = () => {
  // redux - Notification
  const { displayNotification } = useNotification();

  useEffect(() => {
    let isMounted = true; // マウント状態を追跡
    return () => {
      isMounted = false; // アンマウント時にisMountedをfalseに設定
    };
  }, []);

  const location = useLocation();
  // location.pathnameからURLの最初のセグメントを取得
  const segments = location.pathname.split("/");
  // segments[1]に最初のセグメントが含まれる
  const firstSegment = segments[1];

  // Menu関連
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  // badge関連
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 2,
      top: 45,
    },
  }));

  // チャットへ移動
  const handleToChat = async () => {
    //  updateIsRecognition(unreadNotification);
    navigate("/chat");
    handleClose();
    window.location.reload();
  };

  const [unreadCount, setUnreadCount] = useState(0);

  const chatClient = useStreamChatClient();
  useEffect(() => {});

  const [chatuUser, setChatUser] = useState(null);
  // console.log("userConnect =>",userToConnect)

  //   chat channelList関連
  const filters = {
    type: "messaging",
    members: { $in: [auth.currentUser.uid] },
  };
  const options = { state: true, watch: true, presence: true };
  const sort = {
    last_message_at: -1,
    updated_at: -1,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // ModalFormで使用
  const [openChat, setOpenChat] = React.useState(false);
  const [chatId, setChatId] = useState("");
  const handleOpenChat = (id) => {
    setChatId(id);
    setOpenChat(true);
  };
  const handleCloseChat = () => setOpenChat(false);

  const DisplayNotificationNewMessageContetn = ({ id, message }) => {
    return (
      <>
        <div className="flex space-x-2 items-center whitespace-nowrap">
          <GetUserInfo uid={id} target="avatar" size={30} />
          <GetUserInfo uid={id} target="name" />
          さんから新しいメッセージ
        </div>
        {/* <div className=" line-clamp-3 mt-2 w-full p-2 ">{message}</div> */}
      </>
    );
  };


  // useEffect(() => {
  //   if (!chatClient || !chatClient.user) return;

  //   // 初期未読カウントを設定
  //   setUnreadCount(chatClient.user.total_unread_count || 0);

  //   // chatClientからのイベントをリッスンして、未読カウントを更新
  //   const handleEvent = (event) => {

  //     if (event.total_unread_count !== undefined) {
  //       setUnreadCount(event.total_unread_count);
  //       // 通知の表示条件をチェック
  //       if (
  //         event.type === "notification.message_new" &&
  //         event.message &&
  //         event.message.user.id !== auth.currentUser.uid
  //       ) {
  //         displayNotification({
  //           message: (
  //             <DisplayNotificationNewMessageContetn
  //               id={event.message.user.id}
  //             />
  //           ),
  //         });
  //       }
  //     }
  //   };

  //   // イベントリスナーを登録
  //   chatClient.on(handleEvent);

  //   // コンポーネントのアンマウント時にイベントリスナーを解除
  //   return () => {
  //     chatClient.off(handleEvent);
  //   };
  // }, [chatClient]);


  useEffect(() => {
    if (!chatClient || !chatClient.user) return;
  
    // chatClientからのイベントをリッスンして、未読カウントをFirebaseに保存
    const handleEvent = (event) => {
      if (event.total_unread_count !== undefined) {
        const userId = auth.currentUser.uid; // 現在のユーザーIDを取得
        const userDocRef = doc(db, "users", userId); // ユーザードキュメントへの参照を取得
        
        // Firestoreのドキュメントを更新
        updateDoc(userDocRef, {
          unreadCount: event.total_unread_count
        }).then(() => {
          // 成功時の処理（必要に応じて）
        }).catch((error) => {
          console.error("Error updating document: ", error);
        });
  
        // 通知の表示条件をチェック
        if (
          event.type === "notification.message_new" &&
          event.message &&
          event.message.user.id !== auth.currentUser.uid
        ) {
          displayNotification({
            message: (
              <DisplayNotificationNewMessageContetn
                id={event.message.user.id}
              />
            ),
          });
        }
      }
    };
  
    // イベントリスナーを登録
    chatClient.on(handleEvent);
  
    // コンポーネントのアンマウント時にイベントリスナーを解除
    return () => {
      chatClient.off(handleEvent);
    };
  }, [chatClient]); // chatClientが変更された時に再購読
  
  // Firestoreドキュメントのリアルタイム更新を購読して、UIの未読カウントを更新
  useEffect(() => {
    const userId = auth.currentUser.uid;
    const docRef = doc(db, "users", userId);
  
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setUnreadCount(data.unreadCount || 0); // UIの未読カウントを更新
      }
    });
  
    return () => unsubscribe();
  }, []); // コンポーネントのマウント時にのみ実行
  


  if (!chatClient) return null;

  return (
    <>
      <Modal
        open={openChat}
        onClose={handleCloseChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className="flex justify-between">
              <div className="font-bold text-xl">CHAT</div>
              <div
                className="font-bold hover:cursor-pointer text-2xl"
                onClick={handleCloseChat}
              >
                ☓閉じる
              </div>
            </div>
            {chatId && <DirectMessageChatInNotification chatId={chatId} />}
          </div>
        </Box>
      </Modal>

      {/* アイコン */}
      <Tooltip title="チャット" arrow>
        <div
          tabIndex="0"
          className="flex flex-col h-12 w-12 space-y-1  sm:w-12 justify-center items-center hover:cursor-pointer hover:text-blue-500  rounded-full duration-150 "
          onClick={handleClick}
        >
          {chatClient && (
            <>
              <Badge
                badgeContent={unreadCount}
                color="primary"
                // badgeContent={userInfoo?.unreadCount}
                // badgeContent={
                //   chatClient.user.total_unread_count > 0
                //     ? chatClient.user.total_unread_count
                //     : 0
                // }

                max={99}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                className="z-10"
                // sx={{
                //   "& .MuiBadge-badge": { fontSize: 9, height: 18, minWidth: 12 },
                // }}
              >
                <MessageIcon
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                />
              </Badge>
            </>
          )}
          <div className="w-fit text-xs">チャット</div>
        </div>
      </Tooltip>

      {/* メニュー */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        // sx={{ mt: "12px" }}
      >
        <div className=" p-2  rounded-md">
          <div className="font-bold text-xl mb-2">チャット</div>
          <div
            className="flex justify-end text-blue-500 underline hover:cursor-pointer hover:font-bold"
            onClick={handleToChat}
          >
            チャットルームへ
          </div>
          <Divider />

          <Chat
            client={chatClient}
            i18nInstance={streami18n}
            className=" mx-0 "
          >
            <div className="">
              <ChannelList
                filters={filters}
                sort={sort}
                options={options}
                List={(props) => (
                  <MessagingChannelListInNotification {...props} />
                )}
                Preview={(props) => (
                  <MessagingChannelPreviewInNotification
                    {...props}
                    openChat={openChat}
                    handleOpenChat={handleOpenChat}
                    handleCloseChat={handleCloseChat}
                    handleClose={handleClose}
                  />
                )}
              />
            </div>
          </Chat>
        </div>
      </Menu>
    </>
  );
};

export default memo(NotificationsInMessage);
