import React, { useState } from "react";
import { organizerList, meetingPlaceList } from "./constants";
import CheckboxProton from "./CheckBoxPronton";
import FilterListToggle from "./FilterListToggle";
import SliderProton from "./SliderPronton";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";


const FilterPanelOnAdminSettingUsersShop = ({
  values,
  changeChecked,
}) => {
  // ドロワー
  const [state, setState] = useState(false);
  const toggleDrawer = () => {
    setState(!state);
  };
  const closeDrawer = () => {
    setState(false);
  };

  return (
    <>
 
        {/* チェックボックス */}
        <div className="mb-2">
          {values?.map((value) => (
            <CheckboxProton
              key={value.id}
              values={value}
              changeChecked={changeChecked}
            />
          ))}
        </div>

 
    </>
  );
};

export default FilterPanelOnAdminSettingUsersShop;
