import React, { useEffect, useState } from "react";
import { Chat, Channel } from "stream-chat-react";

import "stream-chat-react/dist/css/v2/index.css";

import "../streamLayout.css";

import CustomMessage from "./CustomMessage/CustomMessage";
import MessagingInput from "./MessagingInput/MessagingInputOnDDPExchange";
import MessagingThreadHeader from "./MessagingThread/MessagingThread";

import Loader1Row from "./Loader1Row";
import { ChannelInnerOnDDPExchange } from "./ChannelInner/ChannelInnerOnDDPExchange";
import { useStreamChatClient } from "../context/StreamChatContext";

export const GiphyContext = React.createContext({});

const DealChat = ({ dealId, user }) => {

  const chatClient = useStreamChatClient();
  const [giphyState, setGiphyState] = useState(false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [theme, setTheme] = useState("dark");

  const [channel, setChannel] = useState("");
  // 初期処理
  useEffect(() => {
    const initChat = async () => {
      if (chatClient) {
        const channel = await chatClient.channel(
          "messaging",
          "ddpExchange-" + dealId
        );

        setChannel(channel);
      }
    };

    initChat();

  }, [user]);

  const giphyContextValue = { giphyState, setGiphyState };

  if (!chatClient) return <Loader1Row />;

  return (
    <Chat client={chatClient} className="w-[99%] h-96   ">
      <div className="flex  w-[99%] h-96   rounded-lg  overflow-auto border box-content ">
        {/* チャンネル本体 */}
        <div className=" w-[100%] h-96 rouded-r-xl ">
          <Channel
            Input={MessagingInput}
            channel={channel}
            maxNumberOfFiles={10}
            Message={CustomMessage}
            multipleUploads={true}
            ThreadHeader={MessagingThreadHeader}
            TypingIndicator={() => null}
          >
            <GiphyContext.Provider value={giphyContextValue}>
              <ChannelInnerOnDDPExchange theme={theme} headerNo />
            </GiphyContext.Provider>
          </Channel>
        </div>
      </div>
    </Chat>
  );
};

export default DealChat;
