import React, { useState, useEffect } from "react";
import { db } from "../api/firebase"; // Firebaseの設定済みのdbをインポート
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

import List from "@mui/material/List";
import RenderUserItem from "./RenderUserItem";
import { Divider } from "@mui/material";
import RenderBaseItem from "./RenderBaseItem";
import RenderProductsItem from "./RenderProductsItem";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

// 'users' コレクション用のフィルタ関数
function filterUsers(docData) {
  return docData.isVillager === true && docData.isStop === false;
}

// 'bases' コレクション用のフィルタ関数
function filterBases(docData) {
  return docData.isPublish === true;
  return true; // この例ではすべてのドキュメントを含めます
}

// // 'products' コレクション用のフィルタ関数
// function filterProducts(docData) {
//   return docData.isPublish === true && docData.isActive === true;
// }

// async function filterProducts(docData) {
//   if (!docData.isPublish || !docData.isActive) {
//     return false; // isActiveまたはisPublishがfalseの場合は除外
//   }

//   // 'users' コレクションから uid に対応するドキュメントを取得
//   const userDocRef = doc(db, "users", docData.uid);
//   const userDocSnapshot = await getDoc(userDocRef);
//  console.log("userDocSnapshot =>",userDocSnapshot.data().isV)
//   // isVillagerがtrueであるか確認
//   return userDocSnapshot.exists() && userDocSnapshot.data().isVillager;
// }


async function filterProducts(docData) {
  if (!docData.isPublish || !docData.isActive) {
    return false; 
  }
  try {
    const userDocRef = doc(db, "users", docData.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    return userDocSnapshot.exists() && userDocSnapshot.data().isVillager;
  } catch (error) {
    return false;
  }
}





// async function fetchSearchResults(collectionName, fields, searchText) {
//   let results = [];

//   const queries = fields.map((field) => {
//     return query(
//       collection(db, collectionName),
//       where(field, ">=", searchText),
//       where(field, "<=", searchText + "\uf8ff")
//     );
//   });

//   const querySnapshots = await Promise.all(queries.map((q) => getDocs(q)));

//   querySnapshots.forEach((snapshot) => {
//     snapshot.forEach((doc) => {
//       const docData = { ...doc.data(), id: doc.id, collection: collectionName };

//       // コレクション名に応じてフィルタ関数を実行
//       let includeDoc = false;
//       switch (collectionName) {
//         case "users":
//           includeDoc = filterUsers(docData);
//           break;
//         case "bases":
//           includeDoc = filterBases(docData);
//           break;
//         case "products":
//           includeDoc = filterProducts(docData);
//           break;
//         // 他のコレクションに対するフィルタリング条件があれば、ここに追加
//         // case "anotherCollection":
//         //   includeDoc = filterAnotherCollection(docData);
//         //   break;
//         default:
//           // デフォルトの動作（すべてのドキュメントを含めるか、特定の条件でフィルタリング）
//           includeDoc = true; // または、特定の条件でフィルタリングする関数を呼び出す
//       }
//       // フィルタリングを通過したドキュメントのみを結果に追加
//       if (includeDoc && !results.some((result) => result.id === docData.id)) {
//         results.push(docData);
//       }
//     });
//   });

//   return results;
// }

async function fetchSearchResults(collectionName, fields, searchText) {
  let results = [];

  // クエリを構築
  const queries = fields.map((field) => {
    return query(
      collection(db, collectionName),
      where(field, ">=", searchText),
      where(field, "<=", searchText + "\uf8ff")
    );
  });

  // クエリを実行して結果を取得
  const querySnapshots = await Promise.all(queries.map((q) => getDocs(q)));

  // 各スナップショットを非同期に処理
  for (const snapshot of querySnapshots) {
    for (const doc of snapshot.docs) {
      const docData = { ...doc.data(), id: doc.id, collection: collectionName };

      // コレクション名に応じたフィルタ関数を非同期に実行
      let includeDoc = false;
      if (collectionName === "users") {
        includeDoc = filterUsers(docData);
      } else if (collectionName === "bases") {
        includeDoc = filterBases(docData);
      } else if (collectionName === "products") {
        includeDoc = await filterProducts(docData); // awaitを使用して非同期関数を呼び出す
      } else {
        // 他のコレクションやデフォルトのフィルタリング処理
        includeDoc = true;
      }

      // フィルタリングを通過したドキュメントのみを結果に追加
      if (includeDoc && !results.some((result) => result.id === docData.id)) {
        results.push(docData);
      }
    }
  }

  return results;
}



function NavigationBarSearch({ isOpen, closeModal }) {
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 1000);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false); // 検索が実行されたかどうかを追跡
  const [inputError, setInputError] = useState(""); // 新しい状態を追加

  // 検索ロジック
  useEffect(() => {
    // 入力エラーをリセット
    setInputError("");

    // 入力されたテキストが1文字だけの場合にエラーメッセージを設定
    if (debouncedSearchTerm && debouncedSearchTerm.length === 1) {
      setInputError("2文字以上入力してください");
      setSearchResults([]);
      setIsSearching(false);
      setHasSearched(false);
      return;
    }

    // 入力されたテキストが1文字だけの場合にエラーメッセージを設定
    if (debouncedSearchTerm.length === 0) {
      setInputError("");
      setSearchResults([]);
      setIsSearching(false);
      setHasSearched(false);
      return;
    }

    if (debouncedSearchTerm) {
      setIsSearching(true);
      setHasSearched(false); // 検索開始時にはまだ検索は行われていない
      setSearchResults([]); // ここでリセット
      const collections = [
        { name: "users", fields: ["firstName", "lastName", "prefecture"] },
        { name: "base", fields: ["prefecture", "title"] },
        { name: "products", fields: ["name", "title", "explain","offering"] },

        {
          name: "events",
          fields: ["title", "explain"],
          additionalQuery: (query) => query.where("publishTarget", "==", "all"),
        },
        {
          name: "groups",
          fields: ["title", "explain"],
          additionalQuery: (query) =>
            query
              .where("isPublish", "==", true)
              .where("accept", "==", "public"),
        },
      ];
      const searchPromises = collections.map(
        ({ name, fields, additionalQuery }) =>
          fetchSearchResults(name, fields, debouncedSearchTerm, additionalQuery)
      );

      Promise.all(searchPromises)
        .then((resultsArray) => {
          const flattenedResults = [].concat(...resultsArray);
          setSearchResults(flattenedResults);
          setIsSearching(false);
          setHasSearched(true); // 検索が実際に行われた
        })
        .catch(() => {
          setIsSearching(false); // エラーが発生した場合も検索中状態を解除
          setHasSearched(true); // エラーがあっても検索は試みられた
        });
    } else {
      setSearchResults([]);
      setHasSearched(false); // 検索テキストが空なら検索は実行されていない
    }
  }, [debouncedSearchTerm]);

  const handleClose = () => {
    closeModal();
  };

  // console.log("debouncedSearchTerm =>", debouncedSearchTerm);

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        検索
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <TextField
          autoFocus
          margin="dense"
          id="search"
          type="text"
          fullWidth
          variant="outlined"
          placeholder="検索...(前方一致)"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            if (!e.target.value) {
              setHasSearched(false); // テキストをクリアしたら検索は未実行にリセット
              setSearchResults([]); // 検索結果をクリア
            }
          }}
          // onChange={(e) => {
          //   setSearchText(e.target.value);
          //   if (!e.target.value) setHasSearched(false); // テキストをクリアしたら検索は未実行にリセット
          // }}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1 }} />,
          }}
        />
      </DialogTitle>
      <DialogContent>
        {isSearching && <div>検索中...</div>}
        {!isSearching &&
          hasSearched &&
          searchText &&
          searchResults.length === 0 && (
            <div>表示できるものがありませんでした。</div>
          )}
        {inputError && <div style={{ color: "red" }}>{inputError}</div>}{" "}
        {/* エラーメッセージを表示 */}
        {!isSearching && !inputError && (
          <List>
            {/* 'users' コレクションのデータのみを抽出 */}
            {searchResults.filter((result) => result.collection === "users")
              .length > 0 && (
              <>
                <Divider>
                  <div className=" text-xl font-bold">村民さん</div>
                </Divider>
                {searchResults.map((result) => {
                  if (result.collection === "users") {
                    return (
                      <RenderUserItem
                        key={result.uid}
                        user={result}
                        highlight={searchText}
                      />
                    );
                  }
                  return null;
                })}
              </>
            )}

            {/* 'base' コレクションのデータのみを抽出 */}
            {searchResults.filter((result) => result.collection === "base")
              .length > 0 && (
              <>
                <Divider>
                  <div className=" text-xl font-bold">拠点</div>
                </Divider>
                {searchResults.map((result) => {
                  if (result.collection === "base") {
                    return (
                      <RenderBaseItem
                        key={Math.random()}
                        base={result}
                        highlight={searchText}
                      />
                    );
                  }
                  return null;
                })}
              </>
            )}

            {/* 'products' コレクションのデータのみを抽出 */}
            {searchResults.filter((result) => result.collection === "products")
              .length > 0 && (
              <>
                <Divider>
                  <div className=" text-xl font-bold">DDP</div>
                </Divider>
                {searchResults.map((result) => {
                  if (result.collection === "products") {
                    return (
                      <RenderProductsItem
                        key={Math.random()}
                        product={result}
                        highlight={searchText}
                      />
                    );
                  }
                  return null;
                })}
              </>
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
}

export default NavigationBarSearch;
