import React from "react";
import {  query,  collection } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { Carousel } from "react-responsive-carousel";
import Img from "../data/img/defaultImage.png";
import { db } from "../api/firebase";

const CarouselProductImages = ({ productId, size }) => {
  const queryMyProductImages = query(
    collection(db, `products/${productId}/images`)
  );
  const [images] = useCollection(queryMyProductImages);
  const myProductImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const sizes = {
    full:"h-full",
    xl2:"h-[784px]",
    xl:"h-[576px]",
    large:"h-96",
    middle: "h-48",
    small: "h-28",
  };


  return (
    <>
      {/* 画像 */}
      <div className="">
        {!myProductImages?.length && (
          <img
            src={Img}
            alt=""
            object-fit="cover"
            className="w-full  rounded-l-md"
          />
        )}
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          showStatus={false}
          showIndicators={true}
          className="w-full  rounded-l-md"
        >
          {myProductImages?.map((image, index) => {
            return (
              <div key={index}>
                <img
                  src={image.imageURL}
                  alt=""
                  object-fit="cover"
                  className={` ${sizes?.[size]}  rounded-l-md `}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default CarouselProductImages;
