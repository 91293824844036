import React from "react";
import ContentLoader from "react-content-loader";

const LoaderHeaderPic = (props) => {
  return (
    <>
  <ContentLoader 
    speed={0.5}
    width={160}
    height={160}
    viewBox="0 0 160 160"
    className="rounded-full cursor-pointer w-10 h-10  sm:w-10 sm:h-10  "

    backgroundColor="#d9e4d8"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="80" cy="80" r="84" />
  </ContentLoader>


    </>
  );
};

export default LoaderHeaderPic;
