import React, { useState } from "react";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";
import groupUpdatesUpdate from "./groupUpdatesUpdate";

// streamChat
import { StreamChat } from "stream-chat";
import { useParams } from "react-router-dom";

const EditGroupTitle = ({ setOpen, target }) => {
  const { id } = useParams();

  const [group] = useDocumentData(doc(db, "group", target?.id));
  // console.log("target =>",target)
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    title: "",
    message: "",
    error: null,
    loading: false,
  });
  const { title, message, error, loading } = data;

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "group", target?.id), {
        title,
      });

      // group updates追加
      const updatesRef = collection(db, "group", target?.id, "updates");
      const updatesData = {
        createdAt: serverTimestamp(),
        title: "グループタイトル変更",
        information: "グループタイトルが[" + title + "]に変更されました",
        creator: auth.currentUser.uid,
        creatorAvatar: user?.avatar,
      };

      await addDoc(updatesRef, updatesData);

      // チャット名称変更
      const userToConnect = {
        id: user.uid,
        name: user.lastName + user.firstName,
        image: user.avatar,
        langage: "jp",
      };
      const apiKey = process.env.REACT_APP_STREAM_API_KEY;
      const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
      //   トークン取得
      const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: user.uid,
        }),
      }).then((r) => r.json());
      await client.connectUser(userToConnect, token);
      const channel = client.channel("messaging", "groupChat-" + id);
      await channel.updatePartial({ set: { name: title } });
      // console.log("channel =>", channel);

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };

  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">グループタイトル</div>
        <TextField
          id="outlined-required"
          name="title"
          label="グループタイトル"
          defaultValue=""
          size="small"
          placeholder={group?.title}
          className=" w-full "
          onChange={handleChange}
          required
          disabled={loading}
        />

        <Button
          className="btn"
          name="title"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditGroupTitle;
