import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import DisplayGroup from "./DisplayGroup";
import TablePagination from "@mui/material/TablePagination";
import { memo } from "react";
import LoaderName from "./LoaderName";
import { useAuth } from "../context/auth";
import { db, auth } from "../api/firebase";
import { useParams } from "react-router-dom";
import DisplayEvent from "./DisplayEvents";

const DisplayGroupEvents = memo(({ targetEvents }) => {
  const { search } = useParams();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [events, setEvents] = useState([]);
  const [originalProduct, setOriginalProduct] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    setEvents(targetEvents);
    setOriginalProduct(targetEvents);
    setDataLoading(false);
  }, [dataLoading, targetEvents]);

  useEffect(() => {
    if (search) {
      // console.log("originalProduct =>", originalProduct);
      const filteredRows = originalProduct.filter((row) => {
        return (row.title + row.explain + row.type).includes(search);
      });
      setEvents(filteredRows);
      // window.location.reload()
    } else {
      setEvents(originalProduct);
    }
  }, [search]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-5  my-1 min-h-[300px]">
        {targetEvents?.length === 0 && <div>イベントはありません</div>}
        {!dataLoading === 0 && <LoaderName />}

        {(rowsPerPage > 0
          ? events?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : events
        )?.map((event, index) => {
          return (
            // <MyProduct product={product} key={index} id={product.id} />
            <DisplayEvent
              eventId={event?.id}
              key={Math.random()}
              event={event}
            />
          );
        })}
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={targetEvents?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        showFirstButton
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
});

export default DisplayGroupEvents;
