import React, {  useState } from "react";
import {  doc, deleteDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

const DeleteUser = ({ setOpen, requestId }) => {
  const setRequestDataShop = () => {};
  // ユーザ取得関連
  const [user] = useDocumentData(doc(db, "users", requestId));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  //   削除処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // 削除
      await deleteDoc(doc(db, "users", requestId));
      setOpen(false)
      // window.location.reload();
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };
  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        ユーザ削除
      </div>
      <form onSubmit={setRequestDataShop}>
        <div className="font-bold text-2xl">このユーザを削除します</div>
        <div className="border-2 p-2 rounded-md mb-2">
          <div className="text-gray-300">ユーザ</div>
          <div>{user?.lastName + user?.firstName}</div>
        </div>
        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "削除しています..." : "削除"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DeleteUser;
