import React, { useState, useCallback, useEffect } from "react";
import ChannelPreviewOfMoviesTop from "./ChannelPreviewOfMoviesTop";
import { Button, Divider } from "@mui/material";
import MovieHistoryList from "./MovieHistoryList";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import ModalForm from "./ModalForm";

const Movies = () => {
  // const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // const limit = 10;
  // 最新ムービートップ取得
  // const [moviesTop5, setMoviesTop5] = useState([]);
  // useEffect(() => {
  //   const initMovieHistory = async () => {
  //     const q = query(
  //       collection(db, "movie"),
  //       orderBy("createdAt", "desc"),
  //       limit(limit)
  //     );
  //     let arr = [];
  //     await getDocs(q).then((lists) => {
  //       lists.forEach((list) => {
  //         arr.push({ id: list.id, ...list.data() });
  //       });
  //     });
  //     setMoviesTop5(arr);
  //   };
  //   initMovieHistory();
  // }, []);

  // チャンネルリスト取得
  const [movieChannels, setMovieChannels] = useState([]);
  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "movieChannel"),
        where("isPublish", "==", true)
      );
      let arr = [];
      await getDocs(q).then((Channellists) => {
        Channellists.forEach((Channellist) => {
          arr.push({ id: Channellist.id, ...Channellist.data() });
        });
      });
      setMovieChannels(arr);
    };
    init();
  }, []);

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
  };

  return (
    <>
      <ModalForm form={form} setOpen={setOpen} open={open} />

      {movieChannels?.map((movieChannle) => (
        <ChannelPreviewOfMoviesTop channel={movieChannle} />
      ))}
      {/* {moviesTop5 && <MovieHistoryList list={moviesTop5} />} */}
      <div className="border bg-blue-100"></div>
    </>
  );
};

export default Movies;
