import React from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { FiClock } from "react-icons/fi"; // 時間アイコンのためのアイコンライブラリ

const NotificationOfHomeTopContent = ({ content }) => {
  const navigate = useNavigate();

  const handleChange = () => {
    navigate("/announce/" + content?.id);
  };

  return (
    <div
      className="flex space-x-4 items-center shadow-md hover:bg-gray-50 hover:cursor-pointer duration-200 hover:shadow-xl p-4 my-2 mx-2 border rounded-lg transition ease-in-out"
      onClick={handleChange}
    >
      {/* 時間とアイコン */}
      <div className="flex items-center  min-w-fit justify-end text-blue-600 text-xs hover:shadow-xl">
        <FiClock className="text-lg mr-1" />
        <span>
          {formatDistanceToNow(content?.createdAt.toDate(), { locale: ja })}前
        </span>
      </div>

      <div className="w-11/12 flex flex-col">
        <div className="font-semibold text-md hover:underline">
          {content?.title}
        </div>
        <div className="text-gray-500 text-sm">{content?.content}</div>
      </div>
    </div>
  );
};

export default NotificationOfHomeTopContent;
