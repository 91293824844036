import React from "react";
import { memo } from "react";
import MainContent from "../components/MainContent";

function Home() {
  return (
    <>
      <MainContent />
    </>
  );
}

export default memo(Home) ;
