import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../api/firebase";
import { Avatar, AvatarGroup } from "@mui/material";
import GetUserInfo from "./GetUserInfo";
import { memo } from "react";

const GetCountInGroupMembers = memo(({ id }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const arr = [];

    getDocs(collection(db, "group", id, "members")).then((docs) => {
      docs.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      setMembers(arr);
    });
  }, []);
  // console.log("members =>", members);
  return (
    <>
      <div className="flex items-center space-x-1">
        <div>
          {members?.length === 1 && (
            // <Avatar src={members[0]?.avatar} size={24}/>
            <GetUserInfo uid={members[0]?.uid} target="avatar" size={24} />
          )}
          {members?.length > 1 && (
            <>
              <AvatarGroup
                max={3}
                sx={{
                  "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 15 },
                }}
              >
                {members?.map((member) => (
                  <GetUserInfo
                    uid={member.uid}
                    target="avatar"
                    size={24}
                    key={Math.random()}
                  />
                ))}
              </AvatarGroup>
            </>
          )}
        </div>
        <div>({members?.length})</div>
      </div>
    </>
  );
});

export default GetCountInGroupMembers;
