import React, { memo, useState } from "react";
import Badge from "@mui/material/Badge";
import DropdownItem from "./DropdownItem";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getDocs, query, collection, where } from "firebase/firestore";
import { db } from "../api/firebase";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';


const ApprovalPendingIcon = () => {
  // 管理者画面へ移動
  const navigate = useNavigate();
  const handleToAdminSetting = () => {
    navigate("/approvalpending");
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  //   承認待ちを取得
  //   　グループの申請 makeGroup  isPublish: false,    group
  // 　　イベントの申請 makeEvent  publish: prePublish, events
  // 　　運営のイベント addEvent   publish: false,      events
  // 　　村民さんのお店
  // 　　拠点           createBase isPublish: false,    bases

  // 　イベント申請 publishTargetがallの時だけpublish:false

  //   承認待ちグループ
  const [pendingGroups, setPendingGroups] = useState([]);
  useEffect(() => {
    let arr = [];
    const init = async () => {
      const q = query(collection(db, "group"), where("isPublish", "==", false));
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setPendingGroups(arr);
      });
    };
    init();
  }, []);

  //   承認待ちイベント
  const [pendingEvents, setPendingEvents] = useState([]);
  useEffect(() => {
    let arr = [];
    const init = async () => {
      const q = query(collection(db, "event"), where("publish", "==", false));
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setPendingEvents(arr);
      });
    };
    init();
  }, []);

  //   console.log("pendingGroups.lengh =>", pendingGroups.length);
  //   console.log("pendingEvents.lengh =>", pendingEvents.length);

  const countPending = pendingGroups.length + pendingEvents.length;
  return (
    <>
      {/* <Badge
        color="primary"
        badgeContent={countPending}
        max={999}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      > */}
        <DropdownItem
          title="承認"
          Icon={ToggleOnIcon}
          onClick={handleToAdminSetting}
          complete
          notification={countPending}
        />
      {/* </Badge> */}
    </>
  );
};

export default memo(ApprovalPendingIcon);
