import {
  collection,
  getDocs,
  limit,
  orderBy,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import React, { memo, useEffect, useRef, useState } from "react";
import { db } from "../api/firebase";
import DisplayDDP from "./DisplayDDP";
import SearchBar from "./SearchBar";
import Loading from "./Loading";
import HorizontalmenuAtDDPs from "./HorizontalmenuAtDDPs";
import FilterPanelOnDDPs from "./FilterPanelOnDDPs";
import TablePagination from "@mui/material/TablePagination";

const DdpsContainer = () => {
  async function checkIfVillager(id) {
    try {
      // ドキュメントを取得
      const userDocRef = doc(db, "users", id);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        // ドキュメントが存在する場合、isVillagerフィールドをチェック
        const isVillager = userDocSnapshot.data().isVillager;
        return isVillager === true; // isVillagerがtrueならtrueを返す
      } else {
        // ドキュメントが存在しない場合
        return false;
      }
    } catch (error) {
      console.error("Error checking user:", error);
      return false;
    }
  }

  // ddpたち取得
  const [ddps, setDdps] = useState();

  // useEffect(() => {
  //   const init = async () => {
  //     const q = query(
  //       collection(db, "products"),
  //       where("isActive", "==", true),
  //       where("isPublish", "==", true),
  //       orderBy("createdAt", "desc")
  //       // limit(10)
  //     );

  //     let arr = [];

  //     const docs = await getDocs(q);

  //     for (const doc of docs.docs) {
  //       const isVillager = await checkIfVillager(doc.data().uid);
  //       if (isVillager) {
  //         arr.push({ id: doc.id, ...doc.data() });
  //       }
  //     }

  //     // await getDocs(q).then((docs) =>
  //     //   docs?.forEach((elm) => arr.push({ id: elm.id, ...elm.data() }))
  //     // );
  //     setDdps(arr);
  //   };
  //   init();
  // }, []);

  useEffect(() => {
    const init = async () => {
      const q = query(
        collection(db, "products"),
        where("isActive", "==", true),
        where("isPublish", "==", true),
        orderBy("createdAt", "desc")
      );

      const docs = await getDocs(q);
      const villagerChecks = docs.docs.map((doc) =>
        checkIfVillager(doc.data().uid).then((isVillager) =>
          isVillager ? { id: doc.id, ...doc.data() } : null
        )
      );

      const arr = (await Promise.all(villagerChecks)).filter(
        (result) => result !== null
      );
      setDdps(arr);
    };
    init();
  }, []);

  // console.log("ddps =>",ddps)

  // filter関連
  const [searchInput, setSearchInput] = useState("");
  const [list, setList] = useState();
  const [researchCount, setResearchCount] = useState(0);
  const researchCountIncrement = () => {
    setResearchCount((prev) => prev + 1);
  };
  const [resultsFound, setResultsFound] = useState(true);

  const [values, setValues] = useState([
    { id: 1, checked: false, label: "新しい順" },
    { id: 2, checked: false, label: "古い順" },
    // { id: 3, checked: false, label: "" },
    // { id: 4, checked: false, label: "非村民" },
  ]);
  const handleChangeChecked = (id) => {
    const valuesList = values;
    const changeCheckedValues = valuesList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setValues(changeCheckedValues);
  };

  const selected = [
    { id: 1, value: "desc", label: "新しい順", selected: false },
    { id: 2, value: "asc", label: "古い順", selected: false },
  ];

  const [selects, setSelects] = useState(selected);
  const handleChangeSelect = (e) => {
    const selectsList = selected;
    const changeSelects = selectsList.map((item) =>
      item.value === e.target.value
        ? { ...item, selected: !item.selected }
        : item
    );
    setSelects(changeSelects);
  };

  // フィルタ実行
  const applyFilters = async () => {
    let updatedList = ddps;

    const selected = selects.filter((item) => item.selected);

    if (selected.length) {
      if (selected[0].value === "asc") {
        updatedList = updatedList.sort((a, b) => {
          if (a.createdAt < b.createdAt) return -1;
          if (a.createdAt > b.createdAt) return 1;
          return 0;
        });
      }
      if (selected[0].value === "desc") {
        updatedList = updatedList.sort((a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        });
      }
    }

    // Search Filter
    if (searchInput) {
      // console.log("searchInput =>", searchInput);
      updatedList = updatedList.filter(
        (item) =>
          (item.name + item.title + item.target + item.offering + item.explain)
            .toLowerCase()
            .search(searchInput.toLowerCase().trim()) !== -1
      );
    }

    if (updatedList?.length) researchCountIncrement();
    setList(updatedList);

    !updatedList?.length ? setResultsFound(false) : setResultsFound(true);
  };

  // フィルタがある場合発動
  useEffect(() => {
    applyFilters();
    setPage(0);
  }, [searchInput, selects]);

  const scrollTopRef = useRef("");

  // pagenation
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(24);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    scrollTopRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    scrollTopRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  if (!ddps) return <Loading title="DDPデータ" action="取得中" />;

  return (
    <>
      <div className="h-[calc(100vh-10vh)] overflow-y-auto mt-2 ">
        {/* サーチボックス */}
        <div className="sticky top-0 bg-white z-10 p-2">
          <SearchBar
            value={searchInput}
            changeInput={(e) => setSearchInput(e.target.value)}
            placeholder="名前、タイトル、種類..."
          />
        </div>

        {/* 件数とフィルタ */}
        <div className=" sticky top-14 z-10 py-3  w-full px-2 bg-white flex space-x-2 items-center">
          <div className="relative items-center">
            {/* 件数 */}
            <div className="flex space-x-1 items-baseline">
              <div className="text-4xl font-bold">
                {researchCount === 0 && ddps?.length}
                {researchCount > 0 && (!list || !list.length) && 0}
                {researchCount > 0 && list?.length > 0 && list?.length}
              </div>
              <div>件</div>
            </div>
            {/* フィルター */}
          </div>
          <div className=" absolute right-2  z-20">
            <FilterPanelOnDDPs
              // values={values}
              // changeChecked={handleChangeChecked}
              selects={selects}
              handleChangeSelect={handleChangeSelect}
            />
          </div>
        </div>

        {/* ddp表示 */}
        <div>
          <div ref={scrollTopRef}></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 bg-white">
            {/* filter前 */}
            {!researchCount &&
              (rowsPerPage > 0
                ? ddps?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : ddps
              )?.map((elm, idx) => (
                <DisplayDDP ddp={elm} key={Math.random()} />
              ))}
            {/* フィルター後 */}
            {/* フィルターかけて何もなかったら */}
            {researchCount > 0 && (!list || !list.length) && (
              <div>検索のイベントはありません。</div>
            )}
            {/* listになにかあるなら */}
            {researchCount > 0 &&
              list?.length > 0 &&
              (rowsPerPage > 0
                ? list?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : list
              )?.map((elm, idx) => (
                <DisplayDDP ddp={elm} key={Math.random()} />
              ))}
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 24, 100]}
            component="div"
            count={researchCount > 0 ? list.length : ddps?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            showFirstButton
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        {/* list?.map((elm, idx) => (
              <div className=" col-span-1" key={Math.random()}>
                <DisplayDDP ddp={elm} key={Math.random()} />
              </div>
            ))} */}

        {/* {ddps && ddps?.map((elm, idx) => <DisplayDDP ddp={elm} key={idx} />)} */}
      </div>
    </>
  );
};

export default memo(DdpsContainer);
