import React, { useState } from "react";
import { Button } from "@mui/material";
import ModalForm from "./ModalForm";
import { useParams } from "react-router-dom";

const BuyDealAction = ({ status }) => {
  const { id } = useParams();

  //   Modalのプロパティたち
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState(id);
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };


  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />

      {/* 交換リクエスト待ち */}
      {status === "requesting" && (
        <div>
          <div className=" flex justify-center mb-2 w-full text-gray-500 rounded-md bg-gray-200">
            提供者の交換リクエスト承認待ちです
          </div>
          <Button
            variant="outlined"
            color="error"
            onClick={(e) => handleEdit("dealCancel", id)}
          >
            この交換取引をキャンセルする
          </Button>
        </div>
      )}
      {/* 交換希望者者キャンセル */}
      {status === "buyCanceled" && (
        <div className=" flex justify-center  w-full text-gray-500 rounded-md bg-gray-200">
          交換希望者キャンセル
        </div>
      )}
      {/* 提供者キャンセル */}
      {status === "buyerCanceled" && (
        <div className=" flex justify-center  w-full text-gray-500 rounded-md bg-gray-200">
          提供者キャンセル
        </div>
      )}
      {/* 提供者受諾済 */}
      {status === "accepted" && (
        <>
          <div className=" flex justify-center  w-full text-gray-500 rounded-md bg-gray-200">
            提供者承認済
          </div>
          {/* <Button
            variant="outlined"
            color="error"
            onClick={(e) => handleEdit("dealCancel", id)}
          >
            この取引をキャンセルする
          </Button> */}
        </>
      )}
      {/* 交換連絡待ち */}
      {status === "shipping" && (
        <Button
          variant="contained"
          onClick={(e) => handleEdit("dealArrived", id)}
        >
          受け取り連絡する
        </Button>
      )}
      {/* 評価待ち */}
      {(status === "arrived" || status === "buyerEvaluted") && (
        <Button
          variant="contained"
          onClick={(e) => handleEdit("dealEvalution", id)}
        >
          取引を評価する
        </Button>
      )}
      {/* 取引終了 */}
      {(status === "finished" || status === "buyEvaluted") && (
        <div className=" flex justify-center  w-full text-gray-500 rounded-md bg-gray-200">
          取引は終了しました
        </div>
      )}

      {/* 取引終了 */}
      {status === "adminCompulsionDealStop"  && (
        <div className=" flex justify-center  w-full text-gray-500 rounded-md bg-gray-200">
          交換は管理者により強制終了されました。ルンルは交換前の状態に戻ります。
        </div>
      )}


    </>
  );
};

export default BuyDealAction;
