import React, { useState } from "react";
import TextField from "@mui/material/TextField";

import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../api/firebase";

const AddRecieve = ({ setOpen }) => {
  // 提供する形コンテナ
  const [product, setProduct] = useState({
    recieve: "",
    error: "",
    loading: false,
  });
  const { recieve, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  console.log();

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });

    try {
      // 変更処理
      const docRef = await updateDoc(doc(db, "ddp", "env"), {
        recieve: arrayUnion(recieve),
      });
      setProduct({
        offering: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">「提供を受ける形」を登録</div>
        <form onSubmit={setRequestDataShop}>
          {/* DDP名 */}
          <TextField
            id="outlined-required"
            name="recieve"
            label="提供を受ける形"
            defaultValue=""
            size="small"
            placeholder="ex) 知り合いたい、鑑賞したい、聞きたい、ほしい"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
          />

          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
            >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? error : ""}
        </form>
      </div>
    </>
  );
};

export default AddRecieve;
