import React, { useState, useEffect } from "react";
import {
  doc,
  updateDoc,
  getDoc,
  query,
  collection,
  getDocs,
  where,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Divider} from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "../context/auth";
import { useParams } from "react-router-dom";

import { addNotification } from "./addNotification";

const EditEventMeetingPlace = ({ setOpen, requestId }) => {
  const { id } = useParams();
  const { user } = useAuth();
  const [event] = useDocumentData(doc(db, "event", id));
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    meetingPlace: "",
    message: "",
    error: null,
    loading: false,
  });
  const { meetingPlace, message, error, loading } = data;

  // 自分の情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const getUser = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && getUser();
  }, []);

  // 通知を受け取りたい人IDリスト
  const [notifiIdList, setNotifiIdLsit] = useState([]);
  useEffect(() => {
    const GetNotifiList = async () => {
      let arr = [];
      const q = query(
        collection(db, "event", id, "members"),
        where("status", "==", "getNotified")
      );
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push(doc.data().uid);
        });
        setNotifiIdLsit(arr);
      });
    };
    id && GetNotifiList();
  }, [id]);

  // console.log("data =>",data)
  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };

  // const places = [
  //   { id: 1, place: "現地" },
  //   { id: 2, place: "オンライン" },
  //   // { id: 3, place: "両方" },
  // ];

  const places = [
    { id: 1, place: "オンライン" },
    { id: 99, place: "divider" },
    { id: 2, place: "沖縄県" },
    { id: 3, place: "北海道" },
    { id: 4, place: "青森県" },
    { id: 5, place: "岩手県" },
    { id: 6, place: "宮城県" },
    { id: 7, place: "秋田県" },
    { id: 8, place: "山形県" },
    { id: 9, place: "福島県" },
    { id: 10, place: "茨城県" },
    { id: 11, place: "栃木県" },
    { id: 12, place: "群馬県" },
    { id: 13, place: "埼玉県" },
    { id: 14, place: "千葉県" },
    { id: 15, place: "東京都" },
    { id: 16, place: "神奈川県" },
    { id: 17, place: "新潟県" },
    { id: 18, place: "富山県" },
    { id: 19, place: "石川県" },
    { id: 20, place: "福井県" },
    { id: 21, place: "山梨県" },
    { id: 22, place: "長野県" },
    { id: 23, place: "岐阜県" },
    { id: 24, place: "静岡県" },
    { id: 25, place: "愛知県" },
    { id: 26, place: "三重県" },
    { id: 27, place: "滋賀県" },
    { id: 28, place: "京都府" },
    { id: 29, place: "大阪府" },
    { id: 30, place: "兵庫県" },
    { id: 31, place: "奈良県" },
    { id: 32, place: "和歌山県" },
    { id: 33, place: "鳥取県" },
    { id: 34, place: "島根県" },
    { id: 35, place: "岡山県" },
    { id: 36, place: "広島県" },
    { id: 37, place: "山口県" },
    { id: 38, place: "徳島県" },
    { id: 39, place: "香川県" },
    { id: 40, place: "愛媛県" },
    { id: 41, place: "高知県" },
    { id: 42, place: "福岡県" },
    { id: 43, place: "佐賀県" },
    { id: 44, place: "長崎県" },
    { id: 45, place: "熊本県" },
    { id: 46, place: "大分県" },
    { id: 47, place: "宮崎県" },
    { id: 48, place: "鹿児島県" },
  ]
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "event", id), {
        meetingPlace,
      });

      // if (meetingPlace === "オンライン") {
      //   await updateDoc(doc(db, "event", id), {
      //     price: 0,
      //   });
      // }
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      // 通知を受け取りたい人IDリスト全員に通知
      if (notifiIdList.length) {
        notifiIdList.map((doc) => {
          addNotification(
            doc,
            userInfo?.avatar,
            userInfo?.uid,
            userInfo?.lastName + userInfo?.firstName,
            "event",
            id,
            "イベント集合方法変更"
          );
        });
      }

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };
  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">集合</div>
        <TextField
          id="outlined-required"
          name="meetingPlace"
          label="集合場所"
          defaultValue=""
          size="small"
          className=" w-full"
          onChange={handleChange}
          required
          disabled={loading}
          select
        >
          {places?.map((option) =>
            option.place === "divider" ? (
              <Divider>または</Divider>
            ) : (
              <MenuItem value={option.place} key={Math.random()} dense>
                {option.id} - {option.place}
              </MenuItem>
            )
          )}


          {/* {places?.map((place) => (
            <MenuItem value={place.place} key={Math.random()}>
              {place.place}
            </MenuItem>
          ))} */}
        </TextField>

        <Button
          className="btn"
          name="meetingPlace"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
        {/* {meetingPlace === "オンライン" && (
          <div className="text-red-500 text-sm">
            オンライン開催のイベントではイベント参加費用の設定はできません。オンラインで「送信」すると、イベント参加費用が０円に設定されます。
          </div>
        )} */}
      </form>
    </>
  );
};

export default EditEventMeetingPlace;
