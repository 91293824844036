import { Avatar } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import InputPostComment from "./InputPostComment";
import PostComments from "./PostComments";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { ja } from "date-fns/locale";
import Linkify from "linkify-react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, auth, storage } from "../api/firebase";
import DisplayPostImages from "./DisplayPostImages";
import DotClickMenuAtPost from "./DotClickMenuAtPost";
import ModalForm from "./ModalForm";

import InputMediaOnFeed from "./InputMediaOnFeed";

import { Button } from "@mui/material";

import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
// firebase
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

// redux - use
import { useNotification } from "../redux/useNotification";
import PostReactions from "./PostReactions";
import PostCommentsCount from "./PostCommentsCount";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PostReactionsCount from "./PostReactionsCount";
import LikeAction from "./LikeAction";

const DisplayPostOnDDP = ({ post }) => {
  const linkifyOptions = {
    className: "text-blue-400",
    target: "blank",
  };

  const { id } = useParams();

  const { displayNotification } = useNotification();

  // 投稿者情報
  const [postor, setPostor] = useState("");
  useEffect(() => {
    getDoc(doc(db, "users", post.creator)).then((elm) => {
      setPostor({ id: elm.id, ...elm.data() });
    });
  }, []);

  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const init = async () => {
      if (post?.creator === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    post && init();
  }, []);

  const [user, setUser] = useState("");

  // useEffect を使用してユーザー情報を取得
  useEffect(() => {
    // Firebase からユーザー情報を取得する関数を定義
    const fetchUserInfo = async () => {
      try {
        // auth.currentUser.uid を使用してユーザー情報を取得
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          // ユーザー情報が存在する場合、state にセット
          setUser({ id: userDoc.id, ...userDoc.data() });
        } else {
          // ユーザー情報が存在しない場合、エラー処理を行うか、適切なデフォルト値をセットできます
          console.error("ユーザー情報が見つかりませんでした");
        }
      } catch (error) {
        console.error("ユーザー情報の取得中にエラーが発生しました", error);
      }
    };

    // useEffect 内で fetchUserInfo 関数を呼び出す
    fetchUserInfo();
  }, []); // 空の配列を依存リストとして渡すことで、コンポーネントがマウントされたときに一度だけ実行されます

  // ModalFormで使用
  const [openModal, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const handleEdit = (e, id) => {
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  const [photos, setPhotos] = useState([]);

  // 入力データコンテナ
  const [data, setData] = useState({
    message: "",
    error: "",
    loading: false,
  });
  const { error, loading } = data;

  const [openComments, setOpenComments] = useState(false);

  // 処理
  const doRegist = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });

    try {
      // コレクションに追加
      const docRef = doc(db, "post", post?.id);
      const docData = {
        updatedAt: serverTimestamp(),
      };
      await updateDoc(docRef, docData);

      if (photos) {
        await Promise.all(
          photos.map(async (file) => {
            const ImageRef = ref(
              storage,
              `feed/${post?.id} - ${file.file.name}`
            );
            const snap = await uploadBytes(ImageRef, file.file);
            const url = await getDownloadURL(ref(storage, snap.ref.fullPath));
            await addDoc(collection(db, "postImage"), {
              url,
              path: snap.ref.fullPath,
              id: post.id,
              isPublish: true,
              creator: auth.currentUser.uid,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              type: "post",
            });
          })
        );
      }

      setPhotos([]);
      displayNotification({
        message: "投稿しました。",
        type: "success",
      });

      setData({
        ...data,
        error: null,
      });

      setOpen(false);

      window.location.reload();
    } catch (err) {
      console.log(err.message);
      const errorData = {
        message: err.message,
        stack: err.stack,
      };
      addDoc(collection(db, "error"), {
        createdAt: serverTimestamp(),
        error: errorData,
        component: "AddPost.js",
      });

      setData({
        ...data,
        error: err.message,
        loading: false,
      });
    }
  };

  const navigate = useNavigate();
  const handleMoveToUser = () => {
    navigate("/user/" + postor?.id);
  };

  const [showFullMessage, setShowFullMessage] = useState(true);

  const location = useLocation();
  const showMoreLink = !location.pathname.includes("/post");

  // useEffectを使用して改行コードの数を数え、6行を超えたらshowFullMessageをtrueに設定
  useEffect(() => {
    const newlineCount = (post.message.match(/\n/g) || []).length;
    // console.log("post newline", post?.id, newlineCount);
    if (newlineCount > 5) {
      setShowFullMessage(false);
    }
  }, [post.message, post.id]);

  // handleToMove関数を定義して詳細ページにリダイレクト
  const handleToMove = () => {
    navigate(`/post/${post.id}`);
  };

  // console.log("postor =>",postor)


  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={openModal}
        requestId={requestId}
        target={post}
      />

      {/* <div className="border rounded-md shadow-md p-5 my-3 hover:cursor-pointer hover:shadow-2xl hover:border-blue-500 duration-150"> */}
      <div
        className=" max-w-3xl group  my-5"
        style={{ filter: user?.isVillager ? "none" : "blur(4px)" }} // すりガラス状に表示
      >
        {/* 名前とアバターと通報など */}
        <div className="flex justify-between border-x border-t rounded-t-md p-3 hover:cursor-pointer  group-hover:border-t-blue-500 group-hover:border-x-blue-500 duration-150 bg-white">
          <div className="flex space-x-2 items-center">
            <Avatar src={postor?.avatar} />
            <div>
              {/* 名前 */}
              <div
                className="font-bold whitespace-pre-wrap break-words line-clamp-1 cursor-pointer hover:underline"
                onClick={handleMoveToUser}
              >
                {postor?.lastName + postor?.firstName}
              </div>
              {/* 投稿時間 */}
              <div className=" text-gray-300 text-xs">
                {formatDistanceToNow(post?.updatedAt.toDate(), {
                  locale: ja,
                })}
                前
              </div>
            </div>
          </div>
          <div className="p-2">
            {isAuthor && <DotClickMenuAtPost post={post} />}
            {/* <MoreHorizIcon /> */}
          </div>
        </div>

        {/* メッセージ部 */}
        <div
          className={`relative p-2 group-hover:border-x-blue-500 duration-150 border-x whitespace-pre-wrap break-words ${
            showMoreLink ? "line-clamp-6" : ""
          } text-gray-600 bg-white font-fancy`}
        >
          <Linkify options={linkifyOptions}>{post.message}</Linkify>

          {/* {isAuthor && (
            <div
              className="text-xs w-fit text-blue-500 hover:font-bold hover:cursor-pointer hover:underline  absolute right-2 top-2"
              onClick={() => handleEdit("editPostMessage")}
            >
              編集
            </div>
          )} */}
        </div>
        {/* もっと読む */}
        <div className="relative">
          {!showFullMessage && showMoreLink && (
            <>
              <div className="border-r border-l bg-white flex justify-end pr-4 group-hover:border-r-blue-500 group-hover:border-l-blue-500">
                <span
                  className=" w-fit h-3 text-gray-500 hover:font-bold hover:cursor-pointer hover:underline  text-sm "
                  onClick={user?.isVillager && handleToMove}
                >
                  もっと読む
                </span>
              </div>
            </>
          )}
        </div>

        {/* イメージ */}
        <div className=" group-hover:border-x-blue-500 duration-150 border-x whitespace-pre-wrap break-words line-clamp-6 bg-white ">
          <DisplayPostImages post={post} key={Math.random()} />
          {/* {isAuthor && (
            <div className="flex space-x-2 p-2 border-b">
              <InputMediaOnFeed photos={photos} setPhotos={setPhotos} />
            </div>
          )}
          {isAuthor && photos?.length > 0 && (
            <div className="p-2">
              <Button
                variant="contained"
                className="w-full"
                disabled={loading || photos?.length === 0}
                onClick={doRegist}
              >
                {loading ? "投稿しています..." : "投稿する"}
              </Button>
            </div>
          )} */}

          {/* <img src={Img} className="w-full max-h-72" alt="" /> */}
        </div>

        {/* リアクション部 */}
        <div className=" p-2 group-hover:border-x-blue-500 duration-150 border-x bg-white   justify-between grid grid-cols-2 gap-2">
          <LikeAction post={post} />
          {/* <PostReactions post={post} /> */}
          <PostCommentsCount post={post} id={id} />
        </div>

        {/* コメント部 */}
        {id && (
          <div className=" group-hover:border-x-blue-500 duration-150 border-x whitespace-pre-wrap break-words line-clamp-6 bg-white">
            <PostComments post={post} />
          </div>
        )}

        {/* コメントインプット部 */}
        <div className=" p-5 group-hover:border-x-blue-500 border-t group-hover:border-b-blue-500 rounded-b-md duration-150 border-x border-b  whitespace-pre-wrap break-words line-clamp-6 bg-white ">
          <InputPostComment post={post} />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default memo(DisplayPostOnDDP);

// 後学者へ
// テキストにリンクが含まれていたときにHyperLink化する方法だよ
// https://ouni.jp/blog/react-autolink/
