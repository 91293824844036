import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { Button, TextareaAutosize } from "@mui/material";
import { TextField } from "@mui/material";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { addNotification } from "./addNotification";

const AddQuestionForEvent = ({ setOpen }) => {
  const { id } = useParams();
  const [event] = useDocumentData(doc(db, "event", id));
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    question: "",
    message: "",
    error: null,
    loading: false,
  });
  const { question, message, error, loading } = data;

  // 質問者情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
      setUserInfo({ ...doc.data() });
    });
  }, []);

  // console.log("userInfo =>",userInfo)
  // console.log("event =>", event);

  // プロフィール変更
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 処理
      await addDoc(collection(db, "event", id, "question"), {
        question,
        createdAt: serverTimestamp(),
        questioner: auth.currentUser.uid,
        questionerAvatar: userInfo?.avatar,
        questionerName: userInfo?.lastName + userInfo?.firstName,
        respondent: "",
        lastUpdated: serverTimestamp(),
      });

      if (event?.target !== "operation") {
        addDoc(collection(db, "users", event?.author, "notification"), {
          createdAt: serverTimestamp(),
          content: "イベント質問到着",
          fromAvatar: userInfo?.avatar,
          fromId: userInfo?.uid,
          fromName: userInfo?.lastName + userInfo?.firstName,
          isRecognition: false,
          target: "event",
          targetId: id,
        });
      } else {
        addDoc(collection(db, "questionForAdmin"), {
          createdAt: serverTimestamp(),
          lastUpdatedAt:serverTimestamp(),
          isResolution: false,
          questionerAvatar: userInfo?.avatar,
          questionerId: userInfo?.uid,
          questionerName: userInfo?.lastName + userInfo?.firstName,
          question,
          questionType:"イベントの質問",
          respondentAt: "",
          respondentAvtar: "",
          respondentId: "",
          respondentName: "",
          target: "event",
          targetId: id,
        });
      }

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });
      window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };
  // const handeDeleteTarget = (e) => {
  //   console.log(e.target.innerText);
  // };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">イベントへの質問</div>
        <TextareaAutosize
          id="outlined-required"
          name="question"
          label="イベントへの質問"
          defaultValue=""
          minRows={3}
          maxRows={5}
          placeholder="例）当日の持ち物は何ですか？"
          className=" w-full border border-blue-500 rounded-sm p-2"
          onChange={handleChange}
          required
          disabled={loading}
        />

        <Button
          className="btn"
          name="question"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
        <div className="text-red-500 text-sm">
          ※ この質問を送信すると取消・編集ができません。
        </div>
        <div className="text-red-500 text-sm">
          ※ セカイムラメンバー以外の方も閲覧します。
        </div>
        <div className="text-red-500 text-sm">
          ※
          この質問を送信する事による損害にセカイムラは保証できません。ご注意ください。
        </div>
      </form>
    </>
  );
};

export default AddQuestionForEvent;
