import React from "react";
import { Avatar, Tooltip } from "@mui/material";
import Blind from "./Blind";

const ResearchResultUsers = ({ search, addChat }) => {
  return (
    <>
      <Blind blind={search} />
      {search && (
        <div
          className="fixed z-10 bg-white border-2 rounded-md w-96"
          key={Math.random()}
        >
          <div>ヒットしたメンバー</div>
          {search?.map((user) => (
            <Tooltip
              title={user.oneThing ? user.oneThing : "よろしくね ♫"}
              arrow
              placement="right-start"
            >
              <div
                className="flex h-10 px-2 my-4  items-center cursor-pointer"
                onClick={() => addChat(user)}
              >
                <div className="p-2 hover:bg-gray-200 w-full rounded-md">
                  <div className="flex items-center space-x-2  ">
                    {/* avatar */}
                    <Avatar src={user.avatar} />
                    {/* 名前 */}
                    <div className="wf-roundedmplus1c font-bold">
                      {user.lastName} {user.firstName}
                    </div>
                    {/* オンラインかどうか */}
                    {/* <div
                      className={`${
                        user.isLogin
                          ? user.isOnline
                            ? "text-green-500"
                            : "text-orange-500"
                          : "text-red-500"
                      }   absolute right-3`}
                    >
                      ●
                    </div> */}
                  </div>
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
      )}
    </>
  );
};

export default ResearchResultUsers;
