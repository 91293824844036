import React, { memo, useEffect, useState } from "react";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/auth";
import { Button } from "@mui/material";

import ModalForm from "./ModalForm";
import DisplaySignUp from "./DisplaySignUp";
import RegisterVillager from "./RegisterVillager";
// redux - use
import { useNotification } from "../redux/useNotification";

const ProductWriteReview = () => {
  const { id } = useParams();
  const { user } = useAuth();
  // redux - Notification
  const { displayNotification } = useNotification();

  // ユーザ情報取得
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initUser = () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) =>
        setUserInfo(doc.data())
      );
    };
    user && initUser();
  }, [user]);

  // ModalForm
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [requestId, setRequestId] = useState("");
  const handleModalOpen = (e, id) => {
    if(!buyed){
      displayNotification({
        message: "このDDPを交換した人のみ記載できます。",
        type:"info"
      });
      return
    }      
    setOpen(true);
    setForm(e);
    setRequestId(id);
  };

  //   自分が買ったかどうか
  const [buyed, setBuyed] = useState();
  useEffect(() => {
    const initBuyed = async () => {
      let arr = [];
      const q = query(
        collection(db, "deal"),
        where("product", "==", id),
        where("buy", "==", user?.uid),
      );
      await getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setBuyed(arr);
      });
    };
    user && initBuyed();
  }, [id, user]);

  if (!user)
    return (
      <>
        <div className="flex items-center">
          <DisplaySignUp />
          してレビューを書く
        </div>
      </>
    );
  if (!userInfo?.isVillager)
    return (
      <>
        <div>レビューをするなら・・・</div>
        <RegisterVillager />
      </>
    );

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={requestId}
      />
      {}
      <div className="my-4"></div>
      <div className="font-bold text-xl">レビュー</div>
      <Button onClick={(e) => handleModalOpen("writeReview")}>
        レビューを書く
      </Button>
    </>
  );
};

export default memo(ProductWriteReview);
