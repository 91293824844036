import React, { useEffect, useState } from "react";
import {
  doc,
  updateDoc,
  serverTimestamp,
  getDocs,
  collection,
  where,
  query,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";

import { addNotification } from "./addNotification";

import { useDocumentData } from "react-firebase-hooks/firestore";

// MaterilUI
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/auth";

const EditEventTime = ({ setOpen, requestId }) => {
  const { id } = useParams();
  const { user } = useAuth();

  const [event] = useDocumentData(doc(db, "event", requestId));
  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    publishDate: "",
    message: "",
    error: null,
    loading: false,
  });
  const { publishDate, message, error, loading } = data;

  const [value, setValue] = React.useState(dayjs());

  const handleChange = (newValue) => {
    setValue(newValue);
    setData({ ...data, publishDate: value, message: "" });
  };

  // 自分の情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const getUser = async () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && getUser();
  }, []);

  // 通知を受け取りたい人IDリスト
  const [notifiIdList, setNotifiIdLsit] = useState([]);
  useEffect(() => {
    const GetNotifiList = async () => {
      let arr = [];
      const q = query(
        collection(db, "event", id, "members"),
        where("status", "==", "getNotified")
      );
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          arr.push(doc.data().uid);
        });
        setNotifiIdLsit(arr);
      });
    };
    id && GetNotifiList();
  }, [id]);

  // 通知を受け取りたい人の詳細リスト
  // const [notifiList, setNotifiList] = useState([]);
  // useEffect(() => {
  //   const getNotifiLisst = () => {
  //     let arr = [];
  //     notifiIdList?.forEach((id) => {
  //       getDoc(doc(db, "users", id)).then((userRef) => {
  //         arr.push(userRef.data());
  //       });
  //     });
  //     setNotifiList(arr);
  //   };
  //   notifiIdList?.length && getNotifiLisst();
  // }, [notifiIdList]);

  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄なようです" });
        return false;
      }

      // 変更処理
      await updateDoc(doc(db, "event", requestId), {
        publishDate: new Date(value),
      });
      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      // 通知を受け取りたい人IDリスト全員に通知
      if (notifiIdList.length) {
        notifiIdList.map((doc) => {
          addNotification(
            doc,
            userInfo?.avatar,
            userInfo?.uid,
            userInfo?.lastName + userInfo?.firstName,
            "event",
            id,
            "イベント時間変更"
          );
        });
      }

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
      console.log("err =>", err);
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">イベント日変更</div>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DateTimePicker
              label="イベント日"
              value={value}
              disablePast
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>

        {/* <TextField
          id="outlined-required"
          name="publishDate"
          label="イベント日"
          defaultValue=""
          size="small"
          placeholder={event?.publishDate}
          className=" w-full "
          onChange={handleChange}
          required
          disabled={loading}
        /> */}

        <Button
          className="btn"
          name="publishDate"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {message ? <div className=" text-red-500">{message}</div> : ""}
        {error ? error : ""}
      </form>
    </>
  );
};

export default EditEventTime;
