import { createContext, memo, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../api/firebase";
import Loading from "../components/Loading";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Modalプロバイダ
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    content: "",
  });

  const toggleModal = (isOpen, title, content) => {
    setModal({
      isOpen: isOpen,
      title: title,
      content: content,
    });
  };

  const [snackState, setSnackState] = useState({
    isOpen: false, //boolean型で表示、非表示を切り替える
    type: "", //success, infoなどの背景色を変えるもの
    message: "", //中に入れるメッセージ内容
  });
  const toggleSnack = (isOpen, type, message) => {
    setSnackState({
      isOpen: isOpen,
      type: type,
      message: message,
    });
  };




  
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading title="データ" action="読み込み中" />;
  }

  const value = {
    user,
    modal,
    setModal,
    snackState,
    toggleSnack,
    toggleModal,
  };

  return <AuthContext.Provider {...{ value }}>{children}</AuthContext.Provider>;
};

export default memo(AuthProvider);
