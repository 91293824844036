import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../api/firebase";
import RunruCalendar from "./RunruCalender";

const RunruHistory = ({ uid }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const toQuery = query(
        collection(db, "runru"),
        where("to", "==", uid),
        orderBy("createdAt", "desc")
      );
      const fromQuery = query(
        collection(db, "runru"),
        where("from", "==", uid),
        orderBy("createdAt", "desc")
      );

      const [toDocs, fromDocs] = await Promise.all([
        getDocs(toQuery),
        getDocs(fromQuery),
      ]);

      const tempEvents = [];

      toDocs.forEach((doc) => {
        const data = doc.data();
        const startDate = data.createdAt.toDate();
        const endDate = new Date(startDate.getTime()); // 同じ日を設定

        tempEvents.push({
          id: doc.id,
          calendarId: "1",
          title: `+${data.amount}`,
          category: "time",
          start: startDate,
          end: endDate,
          backgroundColor: '#007BFF', // 正しいプロパティ名を使用
          color: '#FFFFFF' // 文字色を白に設定
        });
      });

      fromDocs.forEach((doc) => {
        const data = doc.data();
        tempEvents.push({
          id: doc.id,
          calendarId: "2",
          title: `-${data.amount}`,
          category: "time",
          start: data.createdAt.toDate(),
          end: data.createdAt.toDate(),
          backgroundColor: '#000000', // 正しいプロパティ名を使用
          color: '#FFFFFF' // 文字色を白に設定
        });
      });

      setEvents(tempEvents);
    };

    uid && fetchData();
  }, [uid]);

  return <RunruCalendar events={events} />;
};

export default RunruHistory;
