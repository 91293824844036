import React, { useState } from "react";
import {
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import {  Button } from "@mui/material";
import {  useDocumentData } from "react-firebase-hooks/firestore";

const EditLastName = ({ setOpen }) => {
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));

  // プロフィール変更値格納コンテナ
  const [data, setData] = useState({
    lastName: "",
    message: "",
    error: null,
    loading: false,
  });
  const { lastName, message, error, loading } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, message: "" });
  };
  // プロフィール変更処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    try {
      // 空欄or変更がない時
      if (!data[e.target.name]) {
        setData({ ...data, message: "空欄か、変更がないようです" });
        // handleEditOff()
        return false;
      }
      // 変更処理 "users"
      const updateUsesLastName = async () => {
        await updateDoc(doc(db, "users", user.uid), {
          [e.target.name]: data[e.target.name],
        });
      };

      // 変更処理 "bases"            chiefName => chiefLastName, chiefFirstName
      // const updateBaseChiefLastName = async () => {
      //   const queryBases = query(
      //     collection(db, "bases"),
      //     where("uid", "==", auth.currentUser.uid)
      //   );
      //   const docs = await getDocs(queryBases);
      //   if (docs?.size) {
      //     docs.forEach((document) => {
      //       const docRef = doc(db, "bases", document.id);
      //       updateDoc(docRef, {
      //         chiefLastName: data[e.target.name],
      //       });
      //     });
      //   }
      // };

      // 変更処理 "chats"            [names.auth.currentUser.uid]: =>
      // 変更処理 "chats/messages"   name => firstName, lastName
      // 変更処理 "posts"            firstName,LastName
      // 変更処理 "products"         name => firstName, lastName
      // 変更処理 "products/images"  name => firstName, lastName
      // 変更処理 "requestShop"      owner => ownerFirstName, ownerLastName

      // 変更処理 "users"      
      await updateUsesLastName()
      // 変更処理 "bases"            chiefName => chiefLastName, chiefFirstName
      // await updateBaseChiefLastName();

      setData({
        [e.target.name]: "",
        error: null,
        loading: false,
      });

      // window.location.reload();
      setOpen(false);
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>

        <div>姓</div>
        <input
          type="text"
          name="lastName"
          className=" border w-full p-1"
          placeholder={user?.lastName}
          defaultValue={user?.lastName}
          onChange={handleChange}
        ></input>
        <div
          className={`${message ? "visible" : "invisible"}
                    text-red-500`}
        >
          {message}
        </div>
        <Button
          className="btn"
          name="lastName"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "送信しています..." : "送信"}
        </Button>
        {error ? error : ""}

      </form>
    </>
  );
};

export default EditLastName;
