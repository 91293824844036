import React, { useState, useEffect } from "react";
import {
  getDocs,
  collection,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import { db } from "../api/firebase";
import Avatar from "@mui/material/Avatar";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import DealHistory from "./DealHistory";
import RunrusHistory from "./RunrusHistory";
import BalanceDisplay from "./BalanceDisplay"; // BalanceDisplayをインポート
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";

const UserAccordion = ({ user, handlePermissionChange, handleStopChange }) => {
  const [expanded, setExpanded] = useState(false);
  const [deals, setDeals] = useState([]);
  const [runru, setRunru] = useState([]); // runruの状態を追加
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState("");

  const handleAccordionChange = async () => {
    setExpanded((prev) => !prev);
    if (!deals.length && !runru.length) {
      // dealsとrunruのデータがまだロードされていない場合のみデータを取得
      const buyerQuery = query(
        collection(db, "deal"),
        where("buyer", "==", user.id),
        orderBy("createdAt", "desc")
      );
      const buyQuery = query(
        collection(db, "deal"),
        where("buy", "==", user.id),
        orderBy("createdAt", "desc")
      );
      const runruQuery = query(
        collection(db, "runru"),
        where("to", "==", user.id),
        orderBy("createdAt", "desc")
      );

      const [buyerDocs, buyDocs, runruDocs] = await Promise.all([
        getDocs(buyerQuery),
        getDocs(buyQuery),
        getDocs(runruQuery),
      ]);

      const buyerDeals = buyerDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const buyDeals = buyDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const runruTransactions = runruDocs.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const combinedDeals = [...buyerDeals, ...buyDeals];
      const uniqueDeals = combinedDeals.filter(
        (deal, index, self) => index === self.findIndex((d) => d.id === deal.id)
      );

      setDeals(uniqueDeals);
      setRunru(runruTransactions); // runruのデータを状態に設定
    }
  };

  const handleUIDClick = (e, uid) => {
    e.stopPropagation();
    window.open(`/user/${uid}`, "_blank");
  };

  const handleCopyEmail = (e, email) => {
    e.stopPropagation();
    navigator.clipboard.writeText(email).then(() => {
      setCopiedEmail(email);
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        style={{ marginBottom: "1rem" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <Avatar alt={user.firstName} src={user.avatar} className="mr-2" />
              <Typography>{user.lastName} {user.firstName}</Typography>
            </Grid>
            <Grid item xs={3}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" color="textSecondary">
                  <a
                    href={`mailto:${user.email}?subject=セカイムラ事務局です。&body=${user.lastName} ${user.firstName}様、%0A%0Aいつもセカイムラサイトをご利用いただき、誠にありがとうございます。%0A%0Aセカイムラサイト事務局です。`}
                    className="text-blue-500"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {user.email}
                  </a>
                </Typography>
                <IconButton onClick={(e) => handleCopyEmail(e, user.email)}>
                  <ContentCopyIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                color="primary"
                onClick={(e) => handleUIDClick(e, user.uid)}
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onMouseEnter={(e) => (e.target.style.color = 'blue')}
                onMouseLeave={(e) => (e.target.style.color = 'inherit')}
              >
                {user.uid}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={user.isVillager}
                    onChange={() => handlePermissionChange(user.id, user.isVillager)}
                    color="primary"
                  />
                }
                label="村民権限"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={user.isStop}
                    onChange={() => handleStopChange(user.id, user.isStop)}
                    color="secondary"
                  />
                }
                label="アカウント停止"
              />
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body2" color="textSecondary">
                {new Date(user.createdAt?.seconds * 1000).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <strong>自己紹介:</strong> {user.selfIntroduction}
          </Typography>
          <DealHistory deals={deals} />
          <RunrusHistory uid={user.uid} />
          <BalanceDisplay user={user} runru={runru} deals={deals} /> {/* BalanceDisplayを追加 */}
        </AccordionDetails>
      </Accordion>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={`「${copiedEmail}」をコピーしました。`}
      />
    </>
  );
};

export default UserAccordion;
