import React, { useState, useEffect } from "react";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db, auth } from "../api/firebase";

//  mui timeline
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

// timeline icons
import RestaurantIcon from "@mui/icons-material/Restaurant";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import DeleteIcon from "@mui/icons-material/Delete";
import HotelIcon from "@mui/icons-material/Hotel";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import CommuteIcon from "@mui/icons-material/Commute";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import DoneIcon from "@mui/icons-material/Done";
import ComputerIcon from "@mui/icons-material/Computer";
import YouTubeIcon from "@mui/icons-material/YouTube";

import EditIcon from "@mui/icons-material/Edit";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuth } from "../context/auth";
import ModalForm from "./ModalForm";

const DraggableScheduleListForEvet = ({ list }) => {
  const { id } = useParams();
  const { user } = useAuth();

  // イベントの作成者かどうか
  const [eventDoc] = useDocumentData(doc(db, "event", id));
  const [isAuthor, setIsAuthor] = useState(false);
  useEffect(() => {
    const initSetAuthor = async () => {
      if (eventDoc?.author === auth.currentUser.uid) {
        setIsAuthor(true);
      }
    };
    user && initSetAuthor();
  }, [eventDoc, user]);

  // ユーザ情報
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    const initGetUser = async () => {
      getDoc(doc(db, "users", user?.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && initGetUser();
  }, [user]);

  const [rows, setRows] = useState(list);
  const reorder = (startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((row, index) => (row.order = index));
    return result;
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const update = reorder(source.index, destination.index);
    update?.forEach((document) => {
      updateDoc(doc(db, "event", id, "schedule", document.id), {
        order: document.order,
        index: document.index,
      });
    });
    setRows(update);
  };

  const scheduleIcon = {
    食事: <RestaurantIcon />,
    アクション: <AccessibilityNewIcon />,
    片付け: <DeleteIcon />,
    就寝: <HotelIcon />,
    移動: <CommuteIcon />,
    挨拶集合: <EmojiPeopleIcon />,
    演奏: <AudiotrackIcon />,
    発表: <CoPresentIcon />,
    解散: <DoneIcon />,
    動画: <YouTubeIcon />,
    パソコン: <ComputerIcon />,
    瞑想: <SelfImprovementIcon />,
  };

  // ModalFormで使用
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState("");
  const [target, setTarget] = useState("");
  const handleEdit = (e, url) => {
    // console.log("e =>", e);
    // console.log("url =>", url);
    setOpen(true);
    setTarget(url);
    setForm(e);
  };

  if (userInfo?.isAdmin || isAuthor)
    return (
      <>
        <ModalForm
          form={form}
          setOpen={setOpen}
          open={open}
          requestId={id}
          target={target}
        />

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"dndTableBody"}>
            {(provided) => (
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
                position="right"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {rows.map((row, index) => (
                  <Draggable
                    draggableId={row.title}
                    index={index}
                    key={row.title}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TimelineItem key={Math.random()}>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color={row.color || "primary"}>
                              {scheduleIcon[row.icon]}
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>

                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <div className="font-bold text-2xl flex items-center ">
                              {row.title}
                              {(userInfo?.isAdmin || isAuthor) && (
                                <EditIcon
                                  className="text-xs hover:cursor-pointer hover:bg-blue-500 duration-200 text-white bg-gray-400 rounded-full p-1"
                                  onClick={() => handleEdit("editScheduleForEvent",row.id)}
                                />
                              )}
                            </div>

                            <div className="text-xs md:text-sm">
                              {row.explain}
                            </div>
                          </TimelineContent>
                        </TimelineItem>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Timeline>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );

  return (
    <>
      <ModalForm
        form={form}
        setOpen={setOpen}
        open={open}
        requestId={id}
        target={target}
      />

      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
        position="right"
      >
        {rows.map((row, index) => (
          <TimelineItem key={Math.random()}>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color={row.color || "primary"}>
                {scheduleIcon[row.icon]}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <div className="font-bold text-2xl flex items-center ">
                {row.title}
              </div>

              <div className="text-xs md:text-sm">{row.explain}</div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};

export default DraggableScheduleListForEvet;

// 後学者へ　draggableCompnent
// https://amateur-engineer.com/mui-table-react-beautiful-dnd/
