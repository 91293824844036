import React from "react";
import TestPush from "./TestPush";

const TestNotification = () => {
  return (
    <>
      <div>TestNotification</div>
      <TestPush userId="gvcnpWr5QaWiOIVFF7VY6Z47xPp1" message="江頭2:50" />
    </>
  );
};

export default TestNotification;
