import React, { memo, useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";

import AddComponent from "./AddComponent";
import {
  useCollectionData,
  useCollection,
} from "react-firebase-hooks/firestore";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../api/firebase";
import DisplayRecommendShop from "./DisplayRecommendShop";
import LendFields from "./LendFields";
import RecommendedShops from "./RecommendedShops";
import RecommendedSpotList from "./RecommendedSpotList";

const OperateRecommendedSopt = () => {
  // TAB用
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <AddComponent value="おすすめスポット登録" target="addRecommendedSopt" />
      <div className=" sticky top-0  rounded-md w-[calc(100%)]">

      <RecommendedSpotList />



        <TabContext value={value}>
          {/* 左リスト */}
          <div className=" grid grid-cols-1 ">
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab label="貸畑" value="1" />
              <Tab label="貸古民家" value="2" />
              <Tab label="パワースポット" value="3" />
              <Tab label="自然食品" value="4" />
              <Tab label="代替医療" value="5" />
            </TabList>
          </div>
          <div>
            <TabPanel sx={{ width: "100%" }} value="1">
              <RecommendedShops type="lendField" />
            </TabPanel>

            {/* 貸古民家 */}
            <TabPanel sx={{ width: "100%" }} value="2">
              <RecommendedShops type="lendOldHouse" />
            </TabPanel>

            {/* パワースポット */}
            <TabPanel sx={{ width: "100%", height: "100vh" }} value="3">
              <RecommendedShops type="powerSpot" />
            </TabPanel>

            {/* 自然食品 */}
            <TabPanel sx={{ width: "100%" }} value="4">
            <RecommendedShops type="naturalFood" />

            </TabPanel>

            {/* 代替医療 */}
            <TabPanel sx={{ width: "100%" }} value="5">
            <RecommendedShops type="alternativeMedicine" />

            </TabPanel>
          </div>
        </TabContext>
      </div>
    </>
  );
};

export default memo(OperateRecommendedSopt);
