import React, { useState } from "react";
import { TextField } from "@mui/material";

import {
  arrayUnion,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";

const AddOnlyDdpTarget = ({ setOpen, requestId }) => {
  const [matchDocumentData] = useDocumentData(doc(db, "ddp", requestId));
  //   const matchingMenu = matchCollection?.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));

  // 提供する形コンテナ
  const [product, setProduct] = useState({
    target: "",
    error: "",
    loading: false,
  });
  const { target, error, loading } = product;

  // 値変更
  const handleChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  // 登録処理
  const setRequestDataShop = async (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      error: "",
      loading: true,
    });
    try {
      // 変更処理
      const docRef = doc(db, "ddp", requestId);
      await updateDoc(docRef, {
        targets: arrayUnion(target),
        updatedAt: serverTimestamp(),
      });

      setProduct({
        match: "",
        target: "",
        error: "",
        loading: false,
      });
      // window.location.reload();
      setOpen(false);
    } catch (err) {
      console.log(err);
      setProduct({
        ...product,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div>
        <div className="mb-3">「提供する対象」を登録</div>
        <form onSubmit={setRequestDataShop}>
          {/* マッチング */}
          {matchDocumentData?.offering} - {matchDocumentData?.recieve}
          {/* <TextField
            id="outlined-required"
            name="id"
            label="提供する対象"
            defaultValue=""
            size="small"
            placeholder="ex) 人、全般、心"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
            select
          >
            {matchingMenu?.map((item) => (
              <MenuItem key={Math.random()} value={item.id}>
                {item.offering} - {item.recieve}
              </MenuItem>
            ))}
          </TextField> */}
          <div className="my-4"></div>
          {/* 提供する対象 */}
          <TextField
            id="outlined-required"
            name="target"
            label="提供する対象"
            defaultValue=""
            size="small"
            placeholder="ex) 人、全般、心"
            className=" w-full "
            onChange={handleChange}
            required
            disabled={loading}
          ></TextField>
          {/* 登録ボタン */}
          <button
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="inline-block px-6 py-2.5 mt-2 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            disabled={loading}
            >
            {loading ? "送信しています..." : "登録"}
          </button>
          {error ? error :""}
        </form>
      </div>
    </>
  );
};

export default AddOnlyDdpTarget;
