import React, { useState, useEffect } from 'react';
import { ImageList, ImageListItem } from '@mui/material';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from "../api/firebase";

const ImageRenderer = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [cachedImages, setCachedImages] = useState([]);

// console.log("images =>",images)

  useEffect(() => {
    const fetchImages = async () => {
      const imagePromises = images.map(async (image) => {
        const cachedImage = localStorage.getItem(image.url);
        if (cachedImage) {
          return { ...image, url: cachedImage };
        } else {
          const imageRef = ref(storage, image.url);
          const url = await getDownloadURL(imageRef);
          localStorage.setItem(image.url, url);
          return { ...image, url };
        }
      });

      const fetchedImages = await Promise.all(imagePromises);
      setCachedImages(fetchedImages);
    };

    fetchImages();
  }, [images]);

  if (!cachedImages || cachedImages.length === 0) return null;

  const handleImageClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ImageList variant="quilted" cols={4} gap={8}>
        {cachedImages.map((image, index) => (
          <ImageListItem
            key={index}
            cols={index % 3 === 0 ? 2 : 1}
            rows={index % 3 === 0 ? 2 : 1}
            onClick={() => handleImageClick(index)}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={image.url}
              alt={`Image ${index + 1}`}
              loading="lazy"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={handleClose}
          slides={cachedImages.map(image => ({ src: image.url }))}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </>
  );
};

export default ImageRenderer;
