import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Avatar, useMediaQuery } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../api/firebase";
import DisplayMyShopOnMap from "./DisplayMyShopOnMap";

const RequestShopDrawer = ({ value, handleClose, shop }) => {
  const isMobile = useMediaQuery("(min-width:769px)");
  return (
    <div className=" overflow-y-auto scrollbar-hide">
      <Drawer
        open={value}
        anchor={!isMobile ? "bottom" : "right"}
        onClose={handleClose}
      >
        <div className=" md:w-96 h-[72vh] md:h-full max-h-[72vh]  scrollbar-hide ">
          <DisplayMyShopOnMap shop={shop} />
        </div>
      </Drawer>
    </div>
  );
};

export default RequestShopDrawer;
