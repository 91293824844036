import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { addDoc, collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { auth, db } from "../api/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";

export default function AddFlexContentButton({
  target,
  secondTarget,
  id,
  secondId,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

// contentの件数を取得　並び替えに使用する
const [FlexContextCollction] = useCollectionData(
    collection(db,target,id,"content")
)
  // 見出し追加
  const handleAddHandLine = () => {
    addDoc(collection(db, target, id,"content"),{
        contentType:"handline",
        content:"見出し",
        fontBold:false,
        fontSize:false,
        isPublish:true,
        creator:auth.currentUser.uid,
        createdAt:serverTimestamp(),
        lastUpdatedAt:serverTimestamp(),
        order: FlexContextCollction.length+1,
        index: FlexContextCollction.length+1,

    });
  };

  // テキスト追加
  const handleAddText = () => {
    addDoc(collection(db, target, id,"content"),{
        contentType:"textarea",
        content:"テキスト",
        isPublish:true,
        creator:auth.currentUser.uid,
        createdAt:serverTimestamp(),
        lastUpdatedAt:serverTimestamp(),
        order: FlexContextCollction.length+1,
        index: FlexContextCollction.length+1,

    });
  };

  // イメージ追加
  const handleAddImage = () => {
    addDoc(collection(db, target, id,"content"),{
        contentType:"image",
        // content:"テキスト",
        isPublish:true,
        creator:auth.currentUser.uid,
        createdAt:serverTimestamp(),
        lastUpdatedAt:serverTimestamp(),
        originalWidth:"",
        originalHeight:"",
        order: FlexContextCollction.length+1,
        index: FlexContextCollction.length+1,

    });
  };



  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="">
          <MoreHorizIcon
            onClick={handleClick}
            fontSize="large"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            className="border m-2 p-2 rounded-full shadow-lg hover:cursor-pointer hove:shadow-2xl"
          ></MoreHorizIcon>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <MenuItem onClick={handleAddHandLine}>
          <ListItemIcon>
            <ViewStreamIcon fontSize="small" />
          </ListItemIcon>
          見出し
        </MenuItem>
        <MenuItem onClick={handleAddText}>
          <ListItemIcon>
            <FormatAlignLeftIcon fontSize="small" />
          </ListItemIcon>
          テキスト
        </MenuItem>
        <MenuItem onClick={handleAddImage}>
          <ListItemIcon>
            <InsertPhotoIcon fontSize="small" />
          </ListItemIcon>
          画像
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
