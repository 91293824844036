import React, { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../api/firebase";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DealHistory = ({ deals }) => {
  const [users, setUsers] = useState({});

  useEffect(() => {
    const fetchUser = async (uid) => {
      const userDoc = await getDoc(doc(db, "users", uid));
      return userDoc.exists() ? userDoc.data() : null;
    };

    const fetchUsersData = async () => {
      const userPromises = [];
      const userMap = {};

      deals.forEach((deal) => {
        if (!users[deal.buyer]) {
          userPromises.push(fetchUser(deal.buyer).then((data) => (userMap[deal.buyer] = data)));
        }
        if (!users[deal.buy]) {
          userPromises.push(fetchUser(deal.buy).then((data) => (userMap[deal.buy] = data)));
        }
      });

      await Promise.all(userPromises);
      setUsers((prevUsers) => ({ ...prevUsers, ...userMap }));
    };

    fetchUsersData();
  }, [deals]);

  const handleClick = (dealId) => {
    window.open(`/deal/${dealId}`, '_blank');
  };

  const truncateName = (name) => {
    return name.length > 10 ? name.substring(0, 10) + '...' : name;
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月`;
  };

  const renderDeals = () => {
    let currentMonth = '';
    let monthCount = 0;

    return deals.map((deal, index) => {
      const dealDate = deal.createdAt.toDate();
      const dealMonth = formatDate(dealDate);

      const isDifferentMonth = currentMonth !== dealMonth;
      if (isDifferentMonth) {
        monthCount = 0;
        for (let i = index; i < deals.length; i++) {
          if (formatDate(deals[i].createdAt.toDate()) === dealMonth) {
            monthCount++;
          } else {
            break;
          }
        }
      }

      currentMonth = dealMonth;

      const monthHeader = isDifferentMonth ? (
        <Typography variant="h6" key={dealMonth} style={{ marginTop: '1rem' }}>
          {dealMonth} ({monthCount} 件)
        </Typography>
      ) : null;

      return (
        <React.Fragment key={deal.id}>
          {monthHeader}
          <Paper
            elevation={3}
            style={{
              marginBottom: "1rem",
              padding: "0.5rem",
              cursor: "pointer",
              border: "1px solid #ccc"
            }}
            onClick={() => handleClick(deal.id)}
            onMouseEnter={(e) => e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)"}
            onMouseLeave={(e) => e.currentTarget.style.boxShadow = "none"}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2}>
                <Typography variant="body2">
                  <strong>日時:</strong> {dealDate.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  <strong>理由:</strong> {deal.cause}
                </Typography>
                <Typography variant="body2">
                  <strong>商品名:</strong> {deal.productName}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">
                  <strong>状況:</strong> {deal.status}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {users[deal.buy] ? (
                  <Box display="flex" alignItems="center">
                    <Avatar src={users[deal.buy].avatar} alt={users[deal.buy].firstName} style={{ marginRight: "4px" }} />
                    <Box>
                      <Typography variant="body2">
                        {truncateName(`${users[deal.buy].lastName} ${users[deal.buy].firstName}`)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        buy
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <CircularProgress size={20} />
                )}
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  {deal.status === 'buyCanceled' || deal.status === 'buyerCanceled' ? (
                    <CancelIcon color="error" />
                  ) : (
                    <ArrowForwardIcon color="primary" />
                  )}
                  <Typography variant="body2">
                    {deal.status === 'buyCanceled' || deal.status === 'buyerCanceled' ? deal.status : deal.amount}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                {users[deal.buyer] ? (
                  <Box display="flex" alignItems="center">
                    <Avatar src={users[deal.buyer].avatar} alt={users[deal.buyer].firstName} style={{ marginRight: "4px" }} />
                    <Box>
                      <Typography variant="body2">
                        {truncateName(`${users[deal.buyer].lastName} ${users[deal.buyer].firstName}`)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        buyer
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <CircularProgress size={20} />
                )}
              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            <strong>取引履歴:</strong> ({deals.length} 件)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {deals.length ? (
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {renderDeals()}
            </ul>
          ) : (
            <Typography variant="body2">
              まだ取引がありません
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DealHistory;
