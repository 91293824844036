import React, { useEffect, useState } from "react";

// MaterialUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

// firebase
import {
  doc,
  addDoc,
  setDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../api/firebase";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

// streamChat
import { StreamChat } from "stream-chat";
// redux - use
import { useNotification } from "../redux/useNotification";

const MakeGroup = ({ setOpen }) => {
  // redux - Notification
  const { displayNotification } = useNotification();

  const [groupTypes] = useCollectionData(collection(db, "groupType"));
  const [groupAccept] = useCollectionData(collection(db, "groupAccept"));

  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  // データコンテナ
  const [data, setData] = useState({
    title: "",
    explain: "",
    type: "",
    accept: "",
  });
  const { title, explain, type, accept, error, loading } = data;

  // グループデータ変更処理
  const handleChange = async (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  // グループ作成処理
  const createBase = async (e) => {
    e.preventDefault();
    setData({
      ...data,
      error: "",
      loading: true,
    });
    try {
      // 作成
      const docRef = await addDoc(collection(db, "group"), {
        title,
        explain,
        type,
        accept, //         accept 受入 -->[表示]に変更
        representative: auth.currentUser.uid, // representative 代表
        founder: auth.currentUser.uid, //        founder 創設
        mainImage: "",
        mainImagePath: "",
        backImage: "",
        backImagePath: "",
        isPublish: false,
        doMakeEventsEveryOne: false,
        createdAt: serverTimestamp(),
      });

      // メンバー追加
      await setDoc(
        doc(db, "group", docRef.id, "members", auth.currentUser.uid),
        {
          createdAt: serverTimestamp(),
          uid: auth.currentUser.uid,
          isOwner: true,
          isMember: true,
        }
      );

      // チャット作成
      const userToConnect = {
        id: user.uid,
        name: user.lastName + user.firstName,
        image: user.avatar,
        langage: "jp",
      };
      const apiKey = process.env.REACT_APP_STREAM_API_KEY;
      const client = StreamChat.getInstance(apiKey, { timeout: 6000 });
      //   トークン取得
      const token = await fetch(process.env.REACT_APP_STREAM_GET_TOKEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          input: user.uid,
        }),
      }).then((r) => r.json());
      await client.connectUser(userToConnect, token);
      const channel = client.channel("messaging", "groupChat-" + docRef.id, {
        name: title,
      });
      await channel.create();
      channel.addMembers([user.uid]);
      await channel.mute();

      // グループのupdates作成・更新
      await addDoc(collection(db, "group", docRef.id, "updates"), {
        createdAt: serverTimestamp(),
        title: "グループ作成",
        information:
          user?.lastName + user?.firstName + "さんがグループを作成しました。",
        creator: auth.currentUser.uid,
        creatorAvatar: user?.avatar,
      });

      // 運営に承認待ちを通知

      setData({
        ...data,
        error: null,
      });
      setOpen(false);
      displayNotification({
        message: "グループ申請受け付けました",
        type: "success",
      });
    } catch (err) {
      console.log(err.message);
      setData({
        ...data,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <>
      <div className="mb-3">グループ作成</div>
      <form onSubmit={createBase}>
        {/* 名前 */}
        <TextField
          id="outlined-required"
          name="title"
          label="グループ名"
          defaultValue=""
          size="small"
          placeholder="〇〇好き、集まれ！"
          className=" w-full"
          onChange={handleChange}
          required
        />

        {/* タイプ*/}
        <TextField
          id="demo-simple-select"
          name="type"
          label="グループの種類"
          defaultValue=""
          className="w-full"
          sx={{ marginTop: 2 }}
          size="small"
          onChange={handleChange}
          required
          select
        >
          {groupTypes?.map((type) => (
            <MenuItem key={Math.random()} value={type.groupType} dense>
              {type.groupType}
            </MenuItem>
          ))}
        </TextField>

        {/* 表示*/}
        <TextField
          id="demo-simple-select"
          name="accept"
          label="表示"
          defaultValue=""
          className="w-full"
          sx={{ marginTop: 2 }}
          size="small"
          onChange={handleChange}
          required
          select
        >
          {groupAccept?.map((type) => (
            <MenuItem key={Math.random()} value={type.groupAccept} dense>
              <div className="flex space-x-2 items-center">
                <div>{type.groupAccept}</div>
                <div className="text-gray-400 text-xs">{type.explain}</div>
              </div>
            </MenuItem>
          ))}
        </TextField>

        {/* 説明 */}
        <div className="my-3"></div>
        <textarea
          style={{ resize: "none", fontSize: "16px" }}
          type="text"
          name="explain"
          className="textarea textarea-bordered w-full h-24  "
          placeholder="グループの説明を入力。後から変更も可能です。"
          defaultValue=""
          onChange={handleChange}
          required
        ></textarea>

        {/* 送信ボタン */}

        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={loading}
        >
          {loading ? "送信しています..." : "申請"}
        </button>
      </form>
    </>
  );
};

export default MakeGroup;
