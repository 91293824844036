import React, { useState } from "react";
import {
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
  serverTimestamp,
  collection,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../api/firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";
import GetUserInfo from "./GetUserInfo";

const DeleteBaseChief = ({ setOpen, requestId }) => {
  // console.log("requestId=>", requestId);
  const [target] = useDocumentData(doc(db, "base", requestId));

  const setRequestDataShop = () => {};
  const [requestShopLoading, setRequestShopLoading] = useState(false);
  const [error, setError] = useState("");
  //   削除処理
  const handleDelete = async (e) => {
    e.preventDefault();
    setRequestShopLoading(true);
    try {
      // baseコンポーネントのcheifを削除
      await updateDoc(doc(db, "base", requestId), {
        chief: "",
        updatedAt: serverTimestamp(),
        updator: auth.currentUser.uid,
        updateReason: "deleteBaseChief",
      });

      //   base membersのisChiefをfalse
      await updateDoc(doc(db, "base", requestId, "members", target?.chief), {
        isChief: false,
        updatedAt: serverTimestamp(),
        updator: auth.currentUser.uid,
        updateReason: "deleteBaseChief",
      });

      //   usersコンポーネントのaffiliationBaseを削除
      const q = query(
        collection(db, "users", target?.chief, "affiliationBase"),
        where("baseId", "==", requestId)
      );

      const docRef = await getDocs(q);
      docRef.forEach((elm) => {
        deleteDoc(doc(db, "users", target?.chief, "affiliationBase", elm.id));
      });

      setOpen(false);
      // window.location.reload();
    } catch (err) {
      setRequestShopLoading(false);
      setError(err);
      console.log("err =>", err);
    }
  };

  // console.log("target =>", target);

  return (
    <>
      <div className="mb-3 bg-blue-500 text-white p-1 rounded-full text-center">
        村長削除
      </div>
      <form>
        <div className="font-bold text-2xl mb-4">
          この拠点から以下の村長を削除します。
        </div>
        {/* 拠点情報 */}
        <div className="p-2 rounded-md shadow-lg border mb-3">
          <div className="flex  items-center">
            <div className="font-bold text-xl">拠点名：</div>
            <div>{target?.title}</div>
          </div>
          <div className="flex  items-center">
            <div className="font-bold text-xl">拠点県：</div>
            <div>{target?.prefecture}</div>
          </div>
        </div>

        <div>村長</div>
        <div className="flex items-center space-x-2 p-2 rounded-md shadow-lg border mb-3">
          <GetUserInfo uid={target?.chief} target="avatar" size={40} />
          <GetUserInfo uid={target?.chief} target="name" />
        </div>

        <button
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          disabled={requestShopLoading}
          onClick={handleDelete}
        >
          {requestShopLoading ? "削除しています..." : "削除"}
        </button>
        {error ? error : ""}
      </form>
    </>
  );
};

export default DeleteBaseChief;
