import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { Chat, Channel, ChannelList } from "stream-chat-react";
import { useChecklist } from "../ChecklistTasks";

import "stream-chat-react/dist/css/v2/index.css";

import "../streamLayout.css";

import CustomMessage from "./CustomMessage/CustomMessage";
import MessagingInput from "./MessagingInput/MessageInputOnDirectMessage";
import MessagingThreadHeader from "./MessagingThread/MessagingThread";

import { ChannelInnerOnDirectMessage } from "./ChannelInner/ChannelInnerOnDirectMessage";
import { db, auth } from "../api/firebase";
import Loader1Row from "./Loader1Row";
import { collection, query, where, updateDoc, doc } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useStreamChatClient } from "../context/StreamChatContext";

const urlParams = new URLSearchParams(window.location.search);
const targetOrigin =
  urlParams.get("target_origin") || process.env.REACT_APP_TARGET_ORIGIN;

export const GiphyContext = React.createContext({});
const DirectMessageChatPRO = ({ user, memberId }) => {
  const chatClient = useStreamChatClient();
  const [giphyState, setGiphyState] = useState(false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [theme, setTheme] = useState("dark");

  useChecklist(chatClient, targetOrigin);

  const combineId = (id) => {
    return id > auth.currentUser.uid
      ? id + auth.currentUser.uid
      : auth.currentUser.uid + id;
  };

  // 自分のNotificationInMessageをすべて消す
  const q_unread = query(
    collection(db, "users", auth.currentUser.uid, "notificationInMessage"),
    where("isRecognition", "==", false)
  );
  const [unreadNotificationCllection] = useCollection(q_unread);
  const unreadNotification = unreadNotificationCllection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  // 受け取ったnotificationのisRecognitionをtrue(既読)にする関数
  const updateIsRecognition = async (arr) => {
    arr?.map((ids) => {
      updateDoc(
        doc(db, "users", auth.currentUser.uid, "notificationInMessage", ids.id),
        {
          isRecognition: true,
        }
      );
    });
  };
  useEffect(() => {
    updateIsRecognition(unreadNotification);
  }, []);

  const [channel, setChannel] = useState("");
  // 初期処理
  useEffect(() => {
    const initChat = async () => {
      if (chatClient) {
        console.log("chatClient =>",chatClient)
        const channel = await chatClient.channel(
          "messaging",
          combineId(memberId),
          {
            members: [user.id, memberId],
          }
        );
        setChannel(channel);
      }
    };

    initChat();
  }, []);

  // モバイルモード
  useEffect(() => {
    const mobileChannelList = document.querySelector("#mobile-channel-list");
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add("show");
      // document.body.style.overflow = "hidden";
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove("show");
      // document.body.style.overflow = "auto";
    }
  }, [isMobileNavVisible]);

  // 高さ設定
  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    setAppHeight();

    window.addEventListener("resize", setAppHeight);
    return () => window.removeEventListener("resize", setAppHeight);
  }, []);

  const giphyContextValue = { giphyState, setGiphyState };

  if (!chatClient) return <Loader1Row />;

  return (
    <Chat client={chatClient} className="w-[99%] h-96   ">
      <div className="flex  w-[99%] h-96   rounded-lg  overflow-auto border box-content ">
        {/* チャンネル本体 */}
        <div className=" w-[100%] h-96 rouded-r-xl ">
          <Channel
            Input={MessagingInput}
            channel={channel}
            maxNumberOfFiles={10}
            Message={CustomMessage}
            multipleUploads={true}
            ThreadHeader={MessagingThreadHeader}
            TypingIndicator={() => null}
          >
            <GiphyContext.Provider value={giphyContextValue}>
              <ChannelInnerOnDirectMessage theme={theme} headerNo />
            </GiphyContext.Provider>
          </Channel>
        </div>
      </div>
    </Chat>
  );
};

export default DirectMessageChatPRO;
