import { db } from "../api/firebase";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";

const addDocument = (collectionName, documentObj, id) => {
  const docRef = doc(collection(db, collectionName), id);
  return setDoc(docRef, {
    ...documentObj,
    createdAt: serverTimestamp(),
  });
};

export default addDocument;
