/**
 * テキスト内のハッシュタグやURLをリンクにする
 * @param comment
 * @returns
 */
export const textToLink = (comment) => {
  let linkedComment = "";

  const urlPattern = /(https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)/gm;

  // 短縮URLを置換する正規表現パターン
  const shortUrlPattern =
    /([a-z0-9]|\.)+\.(com|net|jp|org|io|info|me|ma|gl|ly|gd|cc|nu|tt).[a-zA-Z0-9]+/gm;

  // URLを置換する
  linkedComment = comment.replace(urlPattern, "$& ");

  // 短縮URLを置換する
  linkedComment = linkedComment.replace(shortUrlPattern, "$& ");

  // 正規表現で#を抽出
  // const regexp_hash = /#+([a-zA-Z0-9亜-熙ぁ-んァ-ヶー-龥朗-鶴.\-_]+)/g;
  // linkedComment = linkedComment.replace(
  //   regexp_hash,
  //   '<a href="/search?q=$1&type=hash">#$1</a>'
  // );

  // 正規表現で@を抽出
  // const regexp_at = /@+([a-zA-Z0-9亜-熙ぁ-んァ-ヶー-龥朗-鶴.\-_]+)/g;
  // linkedComment = linkedComment.replace(
  //   regexp_at,
  //   '<a href="/search?q=$1&type=at">@$1</a>'
  // );

  return linkedComment;
};

//   import React, { useState } from "react";
//   import { textToLink } from "./utility";
//   import TextField from "@mui/material/TextField";

//   export default function App() {
//     const [comment, setComment] = useState<string>("");
//     return (
//       <>
//         <TextField
//           label="コメント"
//           value={comment}
//           rows={5}
//           fullWidth
//           multiline
//           onChange={(e) => setComment(e.target.value)}
//         />
//         <br />
//         <br />
//         ↓ハッシュタグやURLなどをリンクに変換
//         <div
//           dangerouslySetInnerHTML={{
//             __html: textToLink(comment)
//           }}
//           style={{
//             whiteSpace: "pre-wrap",
//             lineHeight: "176%",
//             fontSize: ".9rem"
//           }}
//         />
//       </>
//     );
//   }

// 後学者のために
// https://qiita.com/icchi_h/items/a438f2f33aaf15e74059
