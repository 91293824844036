import React from "react";

import HeaderContainerMixIN from "../components/HeaderContainerMixIN";
import MovieChannel from "./MovieChannel";

const MovieChannelContainer = () => {
  return (
    <>
      <HeaderContainerMixIN outlet={<MovieChannel /> } />

    </>
  );
};

export default MovieChannelContainer;
