import React from "react";
import { ToggleButton } from "@mui/material";
import { ToggleButtonGroup } from "@mui/material";


const FilterListToggle = ({ options, value, selectToggle }) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={selectToggle}
      sx={{width:"auto"}}
      // className={classes.root}
    >
      {options.map(({ label, id, value }) => (
        <ToggleButton
          key={id}
          value={value}
>
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default FilterListToggle;
