import React from "react";

import { $getSelection, $isRangeSelection } from "lexical";
import { useEffect } from "react";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { $setBlocksType } from "@lexical/selection";
import { HeadingNode, $createHeadingNode } from "@lexical/rich-text";
import "../LexicalCss.css";

import { createNode } from "@lexical/rich-text";
import { createHeadingNode } from "@lexical/rich-text";
const theme = {
  heading: {
    h1: "lexicalEditor-h1",
    h2: "lexicalEditor-h2",
    h3: "lexicalEditor-h3",
  },
};

function onError(error) {
  console.error(error);
}

const onChange = (e) => {
  // console.log("e =>", e);
};

const AutoFocusPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.focus();
  }, [editor]);

  return null;
};

const HeaderPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const onClick = (tag) => {
    console.log("tag =>",tag)
    console.log("editor =>",editor)
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createHeadingNode(tag));
      }
    });
  };
  return (
    <div>
      {["h1", "h2", "h3"].map((tag) => (
        <button
          onClick={() => {
            onClick(tag);
          }}
          key={tag}
          className="border p-1 rounded-md my-1"
        >
          {tag.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

const Lexical = () => {
  const initialConfig = {
    namespace: "MyEditor",
    theme,
    onError,
    nodes: [HeadingNode],
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <HeaderPlugin />
      <RichTextPlugin
        contentEditable={
          <ContentEditable className="border rounded-md p-5 mb-5" />
        }
        placeholder={
          <div className="text-gray-300 top-16 left-5 absolute">
            テキストを入力・・・
          </div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      {/* <AutoFocusPlugin /> */}
      <OnChangePlugin onChange={onChange} />
      <HistoryPlugin />
    </LexicalComposer>
  );
};

export default Lexical;
