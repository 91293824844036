import React, { useState, useEffect } from 'react'
import Neighbors from "./Neighbors";
import SetPrefecture from "./SetPrefecture";
import { useAuth } from '../context/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../api/firebase';

const DisplayNeighbors = () => {
  const { user } = useAuth();

  // ユーザ情報取得
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    const getInitUser = () => {
      getDoc(doc(db, "users", auth.currentUser.uid)).then((doc) => {
        setUserInfo({ id: doc.id, ...doc.data() });
      });
    };
    user && getInitUser();
  }, []);

  if (!userInfo?.isVillager) return null

  return (
    <>
      <div className="text-xl font-bold">近隣の村民さん</div>
      <div className='bg-white p-2 rounded-md'>
        {userInfo?.prefecture ? (
          <>
            <Neighbors prefecture={userInfo?.prefecture} />
            {/* <div className="w-full  overflow-y-auto p-2 h-screen"></div> */}
          </>
        ) : (<>
          <div>
            <div>居住地を選択してください。</div>
            <SetPrefecture />
          </div>
        </>)
        }
      </div>
    </>

  )
}

export default DisplayNeighbors