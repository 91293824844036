import React, { useEffect, useState } from "react";
import { StreamChat } from "stream-chat";
import { Chat, Channel, ChannelList } from "stream-chat-react";
import { useChecklist } from "../ChecklistTasks";

import "stream-chat-react/dist/css/v2/index.css";

import "../streamLayout.css";

import CustomMessage from "./CustomMessage/CustomMessage";
import MessagingInput from "./MessagingInput/MessageInputOnGroup";
import MessagingThreadHeader from "./MessagingThread/MessagingThread";

import { ChannelInnerOnGroup } from "./ChannelInner/ChannelInnerOnGroup";
import { useStreamChatClient } from "../context/StreamChatContext";

const urlParams = new URLSearchParams(window.location.search);
const targetOrigin =
  urlParams.get("target_origin") || process.env.REACT_APP_TARGET_ORIGIN;

export const GiphyContext = React.createContext({});

const StreamChatOnGroup = ({ user, group, isMember }) => {

  // const [chatClient, setChatClient] = useState(null);
  const chatClient = useStreamChatClient();
  const [giphyState, setGiphyState] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [openMembers, setOpenMembers] = useState(false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  const [theme, setTheme] = useState("dark");

  useChecklist(chatClient, targetOrigin);

  const [channel, setChannel] = useState("");
  // 初期処理
  useEffect(() => {
    const initChat = async () => {
      if (chatClient) {
        //  グループチャット表示
        const channel = await chatClient.channel(
          "messaging",
          "groupChat-" + group?.id,
          {}
        );
        setChannel(channel);
      }
    };

    initChat();
  }, []);

  // モバイルモード
  useEffect(() => {
    const mobileChannelList = document.querySelector("#mobile-channel-list");
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add("show");
      // document.body.style.overflow = "hidden";
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove("show");
      // document.body.style.overflow = "auto";
    }
  }, [isMobileNavVisible]);

  // 高さ設定
  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    setAppHeight();

    window.addEventListener("resize", setAppHeight);
    return () => window.removeEventListener("resize", setAppHeight);
  }, []);

  const giphyContextValue = { giphyState, setGiphyState };

  if (!chatClient) return null;

  return (
    <Chat client={chatClient} className="w-[99%] h-[calc(100vh-120px)] mx-0  ">
      <div className="flex  w-[99%] h-[calc(100vh-165px)] sm:h-[calc(100vh-120px)]  rounded-lg  overflow-auto border box-content ">
        {/* チャンネル本体 */}
        <div className=" w-[100%] rouded-r-xl h-[calc(100vh-165px)] sm:h-[calc(100vh-120px)]">
          <Channel
            Input={MessagingInput}
            channel={channel}
            maxNumberOfFiles={10}
            Message={CustomMessage}
            multipleUploads={true}
            ThreadHeader={MessagingThreadHeader}
            TypingIndicator={() => null}
          >
            <GiphyContext.Provider value={giphyContextValue}>
              <ChannelInnerOnGroup theme={theme} headerNo />
            </GiphyContext.Provider>
          </Channel>
        </div>
      </div>
    </Chat>
  );
};

export default StreamChatOnGroup;
