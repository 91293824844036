import React, { useState } from "react";
import { Drawer, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderLeftMenus from "./HeaderLeftMenus";
import HeaderRightMenus from "./HeaderRightMenus";
import HeaderDrawerMenu from "./HeaderDrawerMenu";

const HeaderContainer = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };



  return (
    <>
      <div>
        {/* ドロワー */}
        <Drawer
          anchor="left"
          open={open}
          onClose={toggleOpen}
          // className="md:hidden"
        > 
          <HeaderDrawerMenu toggleOpen={toggleOpen} open={open} setOpen={setOpen} />
        </Drawer>

        {/* ヘッダー */}
        <Toolbar sx={{ background: "#faf9f7", minHeight:"64px" }} className=" md:hidden">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleOpen}
            edge="start"
            sx={{
              display: { xs: "display", sm: "block" },
            }}
          >
            <MenuIcon />
          </IconButton>

          <HeaderLeftMenus />
          <HeaderRightMenus />
        </Toolbar>
      </div>

      <div>{/* <HeaderContainer /> */}</div>

      {/* メインコンテンツ */}
      {/* {outlet} */}
    </>
  );
};

export default HeaderContainer;
