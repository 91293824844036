import React from 'react';
import { Slider } from '@mui/material';


const SliderProton = ({ value, changePrice }) => {

  return (
    <div className="px-5">
      <Slider
        value={value}
        onChange={changePrice}
        valueLabelDisplay='on'
        min={0}
        max={99999}
        step={100}
      />
    </div>
  );
};

export default SliderProton;