import React, {useEffect, useState, memo } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import "../hideScrollBar.css";
import StoreIcon from '@mui/icons-material/Store';
import MapTypeIcon from "./MapTypeIcon";
import FlareIcon from '@mui/icons-material/Flare';
import ForestIcon from '@mui/icons-material/Forest';
import { ReactComponent as LendFieldIcon} from "../data/svg/LendFieldKUWA.svg"
import { ReactComponent as LendOldHouseIcon} from "../data/svg/LendOldHouse.svg"
import { ReactComponent as PowerSpotIcon} from "../data/svg/PowerSpot.svg"
import { ReactComponent as VagetableIcon} from "../data/svg/Vagetable.svg"
import { ReactComponent as AlternativeMedicineIcon} from "../data/svg/AlternativeMedicine.svg"

function HorizontalMenuAtMap({ onClick,type }) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // ウィンドウの幅が変更されたらstateを更新
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // コンポーネントがアンマウントされるときにイベントリスナーを削除
      window.removeEventListener("resize", handleResize);
    };
  }, []);

//  console.log("windowWidth =>",windowWidth)


  return (
    <div className="grid grid-cols-1 bg-back   ">
      <div className={`${windowWidth < 600 && "mt-9 "}  " col-span-1 rounded-sm "`}>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        <MapTypeIcon icon={<FlareIcon />} text="レイビレッジ" onClick={() => onClick("korakuen")}  type={type === "korakuen"}/>
        <MapTypeIcon icon={<LendFieldIcon  />} text="貸田畑" onClick={() => onClick("lendField")} type={type === "lendField"} />
        <MapTypeIcon icon={<LendOldHouseIcon />} text="貸古民家" onClick={() => onClick("lendOldHouse")} type={type === "lendOldHouse"} />
        <MapTypeIcon icon={<PowerSpotIcon />} text="パワースポット" onClick={() => onClick("powerSpot")} type={type === "powerSpot"} />
        <MapTypeIcon icon={<VagetableIcon />} text="自然食品" onClick={() => onClick("naturalFood")} type={type === "naturalFood"} />
        <MapTypeIcon icon={<AlternativeMedicineIcon />} text="代替医療" onClick={() => onClick("alternativeMedicine")} type={type === "alternativeMedicine"} />
        <MapTypeIcon icon={<StoreIcon />} text="村民さんのお店" onClick={() => onClick("userShop")} type={type === "userShop"} />
        <MapTypeIcon icon={<ForestIcon />} text="セカイムラ拠点" onClick={() => onClick("sekaimuraBase")} type={type ==="sekaimuraBase"}  />
        </ScrollMenu>
      </div>
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <>
      <div
        className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
        onClick={() => scrollPrev()}
      >
        <div className="hidden sm:block ">
          <div className=" text-amber-700">
            <KeyboardDoubleArrowLeftOutlinedIcon
              sx={{ fontSize: "25px", marginX: "5px" }}
            />
          </div>
        </div>
      </div>
    </>
    // <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
    //   Left
    // </Arrow>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <>
      <div
        className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-md"
        onClick={() => scrollNext()}
      >
        <div className="hidden sm:block ">
          <div className="text-amber-700">
            <KeyboardDoubleArrowRightOutlinedIcon
              sx={{ fontSize: "25px", marginX: "5px" }}
            />
          </div>
        </div>
      </div>
    </>
    // <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
    //   Right
    // </Arrow>
  );
}

export default memo(HorizontalMenuAtMap);
