import React from "react";
import { highlightText } from "./highlightText"; // 仮定の関数

import { db } from "../api/firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, query } from "firebase/firestore";

import Img from "../data/img/defaultImage.png";
import ProductRate from "./ProductRate";
import { Avatar } from "@mui/material";
import { Carousel } from "react-responsive-carousel";

const RenderProductsItem = ({ product, highlight }) => {
  const queryMyProductImages = query(
    collection(db, `products/${product.id}/images`)
  );
  const [images] = useCollection(queryMyProductImages);
  const myProductImages = images?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const handleMove = () => {
    window.open(`/product/${product.id}`, "_blank");
  };

  return (
    <div className=" my-2 rounded-md">
      <div
        className="flex h-36 md:h-48 border rounded-lg cursor-pointer shadow-lg hover:bg-gray-50 hover:shadow-lg"
        aria-disabled={product.isPublish}
      >
        {/* 画像 */}
        <div className="flex relative w-4/12">
          {!myProductImages?.length && (
            <img
              src={Img}
              alt=""
              object-fit="cover"
              className="w-full  rounded-tl-md"
            />
          )}
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            showStatus={false}
            showIndicators={true}
            className="w-full  rounded-tl-md"
          >
            {myProductImages?.map((image, index) => {
              return (
                <div key={index}>
                  <img
                    src={image.imageURL}
                    alt=""
                    object-fit="cover"
                    className="w-full h-36  rounded-tl-md rounded-bl-md"
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
 {/* タイトル、説明、レート、価格 */}
 <div
        className="flex flex-col justify-between hover:cursor-pointer p-2 space-y-1 w-8/12"
        onClick={handleMove}
      >
        {/* タイトルと名前 */}
        <div className="flex flex-col">
          {/* タイトル */}
          <div className="font-bold w-[100%] truncate ">
            {highlight ? highlightText(product.title, highlight) : product.title}
          </div>
          {/* 名前とアバター */}
          <div className="flex items-center space-x-1">
            <div>
              <Avatar src={product.avatar} sx={{ width: 24, height: 24 }} />
            </div>
            <div className="text-xs font-sans font-bold">
              {highlight ? highlightText(product.name, highlight) : product.name}
            </div>
          </div>
        </div>
        {/* 説明 */}
        <div className="text-gray-400 text-xs line-clamp-3">
          {highlight ? highlightText(product.explain, highlight) : product.explain}
        </div>
          {/* レートと価格 */}
          <div className="flex flex-col ">
            <div>
              <ProductRate productId={product?.id} />
            </div>
            <div className="flex text-blue-400 font-bold text-md items-center space-x-1">
              <div>{product.runru}</div>
              <div className="text-xs">ルンル</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderProductsItem;
