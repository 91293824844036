import React from "react";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { doc, collection } from "firebase/firestore";
import { db, auth } from "../api/firebase";
import calculatedRunru from "./calculateRunru";

const DistributedRunru = () => {
  const [user] = useDocumentData(doc(db, "users", auth.currentUser.uid));
  const [runruCollection] = useCollection(collection(db, "runru"));
  const runrus = runruCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const [dealCollection] = useCollection(collection(db, "deal"));
  const deals = dealCollection?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const balance = calculatedRunru(user, runrus, deals);

  return (
    <>
      <div className="flex space-x-1">
        <div className="flex text-blue-500 text-2xl font-bold">{balance}</div>
        <div className="flex text-gray-300 text-sm items-center">runru</div>
      </div>
    </>
  );
};

export default DistributedRunru;
