import { Avatar } from "@mui/material";
import React, { memo } from "react";

const TheOtherMessage = memo(({ msg }) => {
  // 日付がInvalidDateかどうか
  const isInvalidDate = (date) => {
    let d = new Date(date);
    return Number.isNaN(d.getTime());
  };

  // const [user, setUser] = useState("")
  // const user = useCallback(async()=>{
  //   const dc = getDoc(doc(db,"users",msg?.sender))
  //  console.log(dc)
  // },[])



  return (
    // 相手のメッセージ
    <div>
      {/* 送信日時 */}
      <div className="flex justify-center text-gray-300 items-end ">
      {msg?.recent ? (
          ""
        ) : (
          <div className="flex justify-center text-gray-300 items-end text-xs">
            {isInvalidDate(new Date(msg.sentAt?.toDate()).toLocaleString()) ? (
              "読み込み中"
            ) : (
              <div>{new Date(msg.sentAt?.toDate()).toLocaleString()}</div>
            )}
          </div>
        )}
      </div>

      <div className="flex justify-start items-center space-x-2">
        {/* avatar */}
        <div className=" self-end">
          {/* {userAvatar} */}
          {/* <UserAvatar uid={msg?.sender}/> */}
          {/* <UserAvatar /> */}
          <Avatar src={msg?.avatar} sx={{ width: 24, height: 24 }} />
          {/* <Avatar src={user?.avatar} sx={{ width: 24, height: 24 }} />
          <DisplayAvatarMiddle uid={msg?.sender} />          
          <Avatar scr={msg?.avatar} /> */}
          {/* <GetUserInfo uid={msg?.sender} target="avatar" size={24} /> */}
          {/* => uid,target,sizeというpropsをTheOthermessageコンポーネント(親)から受け取っている。GetUserInfoコンポーネント(子) */}
          {/* <Avatar src={user?.avatar} sx={{ width: 24, height: 24 }} /> */}
        </div>
        {/* message */}
        <div className="border py-1 px-4 bg-green-400 text-white
             break-words whitespace-pre-wrap max-w-sm sm:max-w-xl  xl:max-w-2xl rounded-md">
        {msg.text}

        </div>
        {/* <div
            // ref={observedDiv}
            className={`border py-1 px-4
            ${ msg?.position === "only"   && "rounded-tl-xl rounded-bl-xl rounded-tr-xl   rounded-br-xl"}
            ${ msg?.position === "top"    && "rounded-tl-xl rounded-bl-none rounded-tr-xl   rounded-br-xl"}
            ${ msg?.position === "middle" && "rounded-tl-none rounded-bl-xl rounded-tr-xl rounded-br-none"}
            ${ msg?.position === "end"    && "rounded-tl-none rounded-bl-xl rounded-tr-none rounded-br-xl"}
             bg-green-400 text-white
             break-words whitespace-pre-wrap max-w-sm sm:max-w-xl  xl:max-w-2xl `}
          >
          {msg.text}
        </div> */}
      </div>
    </div>
  );
});

export default TheOtherMessage;
