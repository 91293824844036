import React, { useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const SearchDdp = () => {
  const navigate = useNavigate();
  const inputRef = useRef()
  const [searchVal, setSearchVal] = useState("");

  const searchDDpetc = (e) => {
    setSearchVal(e.target.value);
  };

  const handleSearch = () => {
    navigate(`/ddp/${searchVal}`);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchVal) {
        handleSearch();
        setSearchVal("")
        inputRef.current.value = ""

      }
    }
  };

  return (
    <div className="flex  items-center rounded-full bg-white border p-2 w-full h-8">
      <SearchIcon className="h-4 text-gray-600" onClick={handleSearch} />
      <input
        style={{ fontSize: "16px" }}
        className=" md:inline-flex ml-2 w-full items-center bg-transparent outline-none "
        type="text"
        ref={inputRef}
        placeholder="DDP検索"
        onChange={(searchValue) => searchDDpetc(searchValue)}
        onKeyPress={onKeyPress}
      />
    </div>
  );
};

export default SearchDdp;
